import { CopyIcon, CheckIcon } from "@chakra-ui/icons";
import { useClipboard, Button, HStack, Box } from "@chakra-ui/react";
import { getShortContractAddress } from "@syla/shared/helpers/assets/getShortContractAddress";
import { intersection } from "lodash";
import React from "react";
import create from "zustand";
import { RegularText } from "./MainTransactionTableRow";

export function AddressDisplay({ addresses }: { addresses: string[] }) {
  const { onCopy, hasCopied } = useClipboard(addresses.join(", "));
  const multiple = addresses.length > 1;

  const { selectedAddresses } = useSameAddressStore();

  return (
    <Button
      variant="ghost"
      role="group"
      onClick={(event) => {
        event.stopPropagation();
        onCopy();
      }}
      height={"fit-content"}
      px={1}
      py={0}
      w="fit-content"
      alignItems="flex-start"
      onMouseEnter={() => {
        useSameAddressStore.setState({
          selectedAddresses: addresses,
        });
      }}
      onMouseLeave={() => {
        useSameAddressStore.setState({
          selectedAddresses: [],
        });
      }}
      backgroundColor={
        intersection(addresses, selectedAddresses).length
          ? "gray.200"
          : undefined
      }
      _hover={{ backgroundColor: "gray.200" }}
    >
      <HStack spacing={1} w="100%">
        <RegularText>
          {getShortContractAddress(addresses[0])}
          {multiple ? ", ..." : ""}
        </RegularText>
        <Box
          _groupHover={{
            visibility: "visible",
          }}
          visibility="hidden"
        >
          {!hasCopied && <CopyIcon boxSize={3} />}
          {hasCopied && <CheckIcon boxSize={3} />}
        </Box>
      </HStack>
    </Button>
  );
}

// use a common store to highlight all addresses that match the current mouse over
export type SameAddressStore = {
  selectedAddresses: string[];
};

const useSameAddressStore = create<SameAddressStore>((set) => ({
  selectedAddresses: [],
}));
