import { GridProps, Grid } from "@chakra-ui/react";
import React from "react";

export const DocumentContainer = ({ children, ...props }: GridProps) => (
  <Grid
    templateColumns="1fr auto auto"
    gap={3}
    mx={"20px"}
    my="10px"
    alignItems={"center"}
    alignContent={"space-between"}
    {...props}
  >
    {children}
  </Grid>
);
