import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { format } from "date-fns";

import { WalletResponse } from "../../../../../types/wallet/wallet";

export const WalletDetails = ({
  walletResponse,
}: {
  walletResponse: WalletResponse;
}): JSX.Element => {
  const walletCreatedAt = walletResponse.createdAt
    ? format(walletResponse.createdAt, "dd/MM/yyyy")
    : "";

  if (walletResponse.allowSync) {
    return (
      <Flex direction="column">
        <Flex direction="row" mb="20px">
          <Flex direction="column" w="40%" minW="100px" maxW="100px">
            <ContentText color="black.700" fontWeight="bold">
              Added
            </ContentText>
            {/*<ContentText color="black.700" fontWeight="bold">*/}
            {/*  API Key*/}
            {/*</ContentText>*/}
            {/*<ContentText color="black.700" fontWeight="bold">*/}
            {/*  Secret Key*/}
            {/*</ContentText>*/}
          </Flex>
          <Flex direction="column" w="60%">
            <ContentText color="black.600">
              {walletResponse.createdAt
                ? format(walletResponse.createdAt, "dd/MM/yyyy")
                : ""}
            </ContentText>
            {/*<ContentText color="black.600">{walletResponse.apiKey}</ContentText>*/}
            {/*{walletResponse.importMethod === WalletImportMethod.CSV ? (*/}
            {/*  <ContentText color="black.600">*/}
            {/*    {walletResponse.secretKey}*/}
            {/*  </ContentText>*/}
            {/*) : (*/}
            {/*  <MdOutlineLock size="1.1rem" color="#666" />*/}
            {/*)}*/}
          </Flex>
        </Flex>
      </Flex>
    );
  }

  if (walletResponse.addresses && walletResponse.addresses.length > 0) {
    return (
      <Flex direction="column">
        <Flex direction="row" mb="20px">
          <Flex direction="column" w="40%" minW="100px" maxW="100px">
            <ContentText color="black.700" fontWeight="bold">
              Added
            </ContentText>
            <ContentText color="black.700" fontWeight="bold">
              Addresses
            </ContentText>
          </Flex>
          <Flex direction="column" w="60%">
            <ContentText color="black.600">{walletCreatedAt}</ContentText>
            {/*{walletResponse.addresses?.map((address) => (*/}
            {/*  <ContentText key={address} color="black.600">*/}
            {/*    {address}*/}
            {/*  </ContentText>*/}
            {/*))}*/}
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex direction="column">
      <Flex direction="row" mb="20px">
        <Flex direction="column" w="40%" minW="100px" maxW="100px">
          <ContentText color="black.700" fontWeight="bold">
            Added
          </ContentText>
        </Flex>
        <Flex direction="column" w="60%">
          <ContentText color="black.600">{walletCreatedAt}</ContentText>
        </Flex>
      </Flex>
    </Flex>
  );
};

const RedBtn = styled(Button)`
  border-color: #ff3600;
  color: #ff3600;
  font-size: 0.75rem;
  font-weight: 600;
  height: 30px;
  background-color: transparent;
  border-width: 1px;
  padding: 0 10px;
  &:hover {
    background-color: transparent;
  }
  &:focus {
    border-color: #ff3600;
  }
  &:active {
    border-color: #ff3600;
    background-color: #ffd7cc;
  }
`;

const ContentText = styled(Text)`
  font-size: 0.75rem;
  margin-bottom: 5px;
`;
