import { COINBASE_CLIENT_ID } from "@syla/shared/types/helpers/constants";

const COINBASE_REQUESTED_SCOPES = [
  "wallet:accounts:read",
  "wallet:transactions:read",
  "offline_access", // enable to return refresh token on oauth response
];

export const getCoinBaseAuthoriseUrl = ({
  walletId,
  accountId,
}: {
  walletId: string;
  accountId: string;
}) =>
  getCoinbaseAuthoriseUrlInternal({
    redirectUrl: getCoinBaseRedirectUri(),
    stateProps: {
      walletId,
      accountId,
    },
  });

const getCoinbaseAuthoriseUrlInternal = ({
  redirectUrl,
  stateProps,
}: {
  redirectUrl: string;
  stateProps: Record<string, string>;
}) =>
  `https://login.coinbase.com/oauth2/auth?response_type=code` +
  `&scope=${encodeURIComponent(COINBASE_REQUESTED_SCOPES.join(","))}` +
  `&client_id=${COINBASE_CLIENT_ID}` +
  `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
  `&state=${encodeURIComponent(new URLSearchParams(stateProps).toString())}`;

export const getCoinBaseRedirectUri = () =>
  `${window.location.origin}/data-sources/coinbase-callback`;
