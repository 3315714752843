export enum SCREEN_SIZE {
  "2XS" = "401px",
  XS = "501px",
  SM = "576px",
  MD = "768px",
  LGContainer = "772px",
  LG = "992px",
  XL = "1400px",
  XXL = "2000px",
}

export enum QueryKey {
  WalletsData = "wallets-data",
}
