import React, { createContext, useContext } from "react";
import {
  SelectedTxProps,
  useSelectedList,
  SelectedTxInputProps,
} from "../../../../hooks/useSelectedList";

const SelectedTxContext = createContext<SelectedTxProps>(undefined as any);

export const useSelectedTxContext = () => useContext(SelectedTxContext);

export const SelectedTxContextProvider = ({
  children,
  ...props
}: React.PropsWithChildren<SelectedTxInputProps>) => {
  const selectedTxProps = useSelectedList(props);

  return (
    <SelectedTxContext.Provider value={selectedTxProps}>
      {children}
    </SelectedTxContext.Provider>
  );
};
