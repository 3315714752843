import {
  parseDecimal,
  parseOptional,
} from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { PopulatedAssetHolding } from "@syla/shared/types/models/AssetHoldingBase";

export const parseAssetHolding = (
  holding: TransportType<PopulatedAssetHolding<string>>
): PopulatedAssetHolding<string> => ({
  ...holding,
  balance: parseDecimal(holding.balance),
  cost: parseOptional(holding.cost, parseDecimal),
  marketValue: parseOptional(holding.marketValue, parseDecimal),
});
