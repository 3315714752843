import { Flex, Divider as RowDivider, Box } from "@chakra-ui/react";
import { permissionFor } from "@syla/shared/types/helpers/permissions";
import React, { useContext } from "react";
import {
  UserTaxOutcomes,
  UserTransactions,
  UserRemainingParcels,
} from "../../../constants/SupportCsvDocument";
import { SylaSampleUrls } from "../../../constants/SylaSampleUrls";
import { canUnlockPlanFeature } from "../../../helper/canUnlockPlanFeature";
import { TaxReportsContext } from "../../../pages/TaxReports";
import { useGlobalVariablesStore } from "../../../store/globalVariablesStore";
import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { DocumentContainer } from "./DocumentContainer";
import { SupportDocumentRow } from "./SupportDocumentRow";

export const SupportDocumentSummary = (): JSX.Element => {
  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );
  const { isLoading, subscriptions, txCount, account } =
    useContext(TaxReportsContext);

  const canPurchase = canUnlockPlanFeature(account);

  return (
    <CardContentContainer>
      <ContentHeadingContainer>
        <Flex direction="row" alignItems="center">
          <TaxReportHeading>Supporting Documents</TaxReportHeading>
        </Flex>
      </ContentHeadingContainer>
      <RowDivider mt="20px" />

      <Box overflowX={"auto"}>
        <DocumentContainer>
          <SupportDocumentRow
            isLoading={isLoading}
            canPurchase={canPurchase}
            canDownload={
              permissionFor({
                action: UserTaxOutcomes.permission,
                subscriptions,
                transactionCount: txCount,
                account: account,
              }).hasPermission
            }
            csvDocument={UserTaxOutcomes}
            sampleUrl={SylaSampleUrls.TaxOutcomes}
          />
          <SupportDocumentRow
            isLoading={isLoading}
            canPurchase={canPurchase}
            canDownload={
              permissionFor({
                action: UserTransactions.permission,
                subscriptions,
                transactionCount: txCount,
                account: account,
              }).hasPermission
            }
            csvDocument={UserTransactions}
            sampleUrl={SylaSampleUrls.Transactions}
          />
          {selectedFinancialYear && (
            <SupportDocumentRow
              isLoading={isLoading}
              canPurchase={canPurchase}
              canDownload={
                permissionFor({
                  action: UserRemainingParcels.permission,
                  subscriptions,
                  transactionCount: txCount,
                  account: account,
                }).hasPermission
              }
              csvDocument={UserRemainingParcels}
              sampleUrl={SylaSampleUrls.CostBaseRemainingAssets}
            />
          )}
        </DocumentContainer>
      </Box>
    </CardContentContainer>
  );
};
