import {
  VStack,
  FormControl,
  Input,
  FormErrorMessage,
  Text,
  FormLabel,
  FormHelperText,
  StackProps,
} from "@chakra-ui/react";
import {
  AccountSubType,
  AccountType,
} from "@syla/shared/types/models/AccountBase";
import React, { useContext, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { ModalContext } from "../../../contexts/ModalContext";
import { useSwitchAccount } from "../../../routers/useSwitchAccount";
import { useMutationCreateAccount } from "../../../store/useMutationCreateAccount";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { SingleSelectBox } from "../../atoms/singleSelectBox";
import { ContactSupportLink } from "../../molecules/ContactSupportLink";
import {
  ACCOUNT_TYPE_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
} from "../../molecules/forms/accountOptions";

export function CreateAccountForm(props: StackProps): React.ReactElement {
  const { closeModal: closeForm } = useContext(ModalContext);
  const switchAccount = useSwitchAccount();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateAccountFormData>({
    defaultValues: {
      accountType: AccountType.Individual,
      accountSubType: AccountSubType.Investor,
    },
  });

  const {
    mutateAsync: createAccount,
    isLoading: createAccountInProgress,
    error: createAccountError,
  } = useMutationCreateAccount();

  const onSubmit = useCallback(
    async (data: CreateAccountFormData) => {
      const { accountId } = await createAccount({
        accountType: data.accountType,
        accountSubType: data.accountSubType,
        nickname: data.nickname,
        contactEmail: data.contactEmail,
        fullName: data.fullName,
      });
      switchAccount({
        _id: accountId,
        fullName: data.fullName,
        nickname: data.nickname,
      });
      closeForm();
    },
    [closeForm, createAccount, switchAccount]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...props}>
      <FormControl
        isRequired
        isDisabled={createAccountInProgress}
        isInvalid={errors.accountType !== undefined}
      >
        <FormLabel>Account Type</FormLabel>
        <Controller
          control={control}
          name="accountType"
          render={({ field: { onChange, value } }) => (
            <SingleSelectBox
              options={ACCOUNT_TYPE_OPTIONS}
              selectedOption={value}
              placeholder="Select an account type"
              onChangeSelection={(selection) => onChange(selection)}
              selectBtnProps={{ height: "40px" }}
            />
          )}
        />
      </FormControl>
      <FormControl
        isRequired
        isDisabled={createAccountInProgress}
        isInvalid={errors.accountSubType !== undefined}
      >
        <FormLabel>Activity Type</FormLabel>
        <Controller
          control={control}
          name="accountSubType"
          render={({ field: { onChange, value } }) => (
            <SingleSelectBox
              options={ACTIVITY_TYPE_OPTIONS}
              selectedOption={value}
              placeholder="Select an activity type"
              onChangeSelection={(selection) => onChange(selection)}
              selectBtnProps={{ height: "40px" }}
            />
          )}
        />
      </FormControl>
      <FormControl isRequired isDisabled={createAccountInProgress}>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          autoFocus
          {...register("fullName", {
            required: true,
          })}
          bgColor="white.0"
        />
        {errors.fullName && (
          <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>
        )}
      </FormControl>
      <FormControl isDisabled={createAccountInProgress}>
        <FormLabel>Nickname</FormLabel>
        <Input type="text" {...register("nickname", {})} bgColor="white.0" />
        {errors.contactEmail ? (
          <FormErrorMessage>{errors.contactEmail.message}</FormErrorMessage>
        ) : (
          <FormHelperText>
            {
              "A nickname or reference that you can use for this account. It’s only viewable by you."
            }
          </FormHelperText>
        )}
      </FormControl>
      {createAccountError && (
        <Text w="100%">
          Unexpected Error. If issues persist{" "}
          <ContactSupportLink
            supportPrompt={
              "I'm having trouble creating a new account. Can you help?"
            }
          />
        </Text>
      )}
      <VStack w="100%">
        <ButtonVariant
          content="Create Account"
          color="red"
          disabled={createAccountInProgress}
          isLoading={createAccountInProgress}
          onClick={() => {}}
          spam="spam"
          type="submit"
        />
      </VStack>
    </Form>
  );
}

interface CreateAccountFormData {
  accountType: AccountType;
  accountSubType: AccountSubType;
  fullName?: string;
  nickname?: string;
  contactEmail?: string;
}

const Form = (props: StackProps) => (
  <VStack as="form" w="100%" spacing={6} {...props}>
    {props.children}
  </VStack>
);
