import { Account } from "../types/Account";
import { canPurchaseSubscription, canUpgradeSubscription } from "./permissions";

export function canUnlockPlanFeature(
  account: Pick<Account, "subscriptions" | "permissions"> | undefined
) {
  const canPurchase = canPurchaseSubscription({
    account,
    subscriptions: account?.subscriptions,
  });
  const canUpgrade = canUpgradeSubscription({
    subscriptions: account?.subscriptions,
  });
  return canPurchase || canUpgrade;
}
