import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { SpanStatusType } from "@sentry/tracing";
import React, { useState } from "react";
import { downloadTaxReportForFinancialYear } from "../../../api/pdf/downloadTaxReportForFinancialYear";
import reportPdf from "../../../images/icons/reportPdf.svg";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { FinancialYearOption } from "../../../types/user/financialYearOption";
import { DocumentRow } from "./DocumentRow";

interface TaxReportRowProps {
  selectedFinancialYear: FinancialYearOption | undefined;
  isLoading?: boolean;
  canDownload: boolean;
  canPurchase: boolean;
  sampleUrl?: string;
}

export const TaxReportRow = ({
  selectedFinancialYear,
  isLoading,
  canDownload,
  sampleUrl,
  canPurchase,
}: TaxReportRowProps): JSX.Element => {
  const toast = useToast();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // get the years from the selected financial year
  const startYear = selectedFinancialYear?.startDate.getFullYear();
  const endYear = selectedFinancialYear?.endDate.getFullYear();

  const [downloading, setDownloading] = useState(false);

  // handle download click
  const handleDownload = async () => {
    if (!selectedFinancialYear) {
      toast({
        title: "Financial year not selected",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }

    // instrument
    const transaction = Sentry.startTransaction({
      name: "/tax-reports/downloadTaxReportForFinancialYear",
      data: { financialYearValue: selectedFinancialYear.value },
    });

    const span = transaction.startChild({
      op: "task",
      description: `downloadTaxReportForFinancialYear`,
    });

    try {
      setDownloading(true);
      // Set transaction on scope to associate with errors and get included span instrumentation
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      await downloadTaxReportForFinancialYear(
        accountId,
        selectedFinancialYear.value
      );
      span.setStatus("ok" as SpanStatusType);
    } catch (error: any) {
      span.setStatus("unknown_error" as SpanStatusType);

      toast({
        title: "Error downloading tax report",
        description: "Please contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    span.finish();
    transaction.finish();

    setDownloading(false);
  };

  return (
    <DocumentRow
      isLoading={isLoading}
      downloading={downloading}
      canDownload={canDownload}
      image={reportPdf}
      sampleUrl={sampleUrl}
      documentName={`${startYear} - ${endYear} Crypto Tax Report`}
      handleDownload={handleDownload}
      canPurchase={canPurchase}
    />
  );
};
