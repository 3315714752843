import { addHours } from "date-fns";
import { PlanBase } from "../models/PlanBase";
import { SubscriptionBase } from "../models/SubscriptionBase";
import { WithId } from "./Id";
import { ReplaceProps } from "./ReplaceProps";

export const SUBSCRIPTION_END_GRACE_PERIOD_HOURS = 48;

export type SubscriptionWithPlan = ReplaceProps<
  SubscriptionBase<any>,
  "plan",
  {
    plan: WithId<PlanBase<any>>;
  }
>;

export function hasValidPlanWith({
  cond,
  subscriptions,
}: {
  subscriptions: SubscriptionWithPlan[];
  cond: (props: {
    plan: PlanBase<any>;
    customTransactionLimit?: number;
  }) => boolean;
}): boolean {
  return getValidSubscriptions({
    subscriptions: subscriptions,
  }).some((s) =>
    cond({
      plan: s.plan,
      customTransactionLimit: s.customTransactionLimit,
    })
  );
}

export function hasValidPlanWithCapacity({
  transactionCount,
  subscriptions,
}: {
  subscriptions: SubscriptionWithPlan[];
  transactionCount: number;
}): boolean {
  return getValidSubscriptions({
    subscriptions: subscriptions,
  }).some((subscription) => {
    const limit =
      subscription.customTransactionLimit ?? subscription.plan.transactionLimit;
    return limit === -1 || transactionCount <= limit;
  });
}

export function getValidSubscriptions<TSub extends SubscriptionBase<any>>({
  subscriptions,
}: {
  subscriptions: TSub[];
}) {
  const now = new Date();

  return subscriptions.filter(
    (subscription) =>
      subscription.status == "valid" &&
      now >= subscription.begins &&
      now <= addHours(subscription.ends, SUBSCRIPTION_END_GRACE_PERIOD_HOURS)
  );
}

export type SubscriptionStatus = {
  expired: boolean;
  cancelled: boolean;
  valid: boolean;
  expiryDate: Date;
  renewalDate: Date;
};

export function getSubscriptionStatus(
  subscription: Pick<SubscriptionBase<any>, "status" | "isCancelled" | "ends">
): SubscriptionStatus {
  const now = new Date();
  const expired =
    addHours(subscription.ends, SUBSCRIPTION_END_GRACE_PERIOD_HOURS) < now;
  return {
    expired,
    cancelled: !!subscription.isCancelled,
    valid: subscription.status == "valid" && !expired,
    expiryDate: addHours(
      subscription.ends,
      SUBSCRIPTION_END_GRACE_PERIOD_HOURS
    ),
    renewalDate: subscription.ends, // end date minus grace period
  };
}

export const planHasCapacity = ({
  plan: { transactionLimit },
  transactionCount,
}: {
  plan: PlanBase<any>;
  transactionCount: number;
}) => transactionLimit >= transactionCount || transactionLimit === -1;

/**
 * Get current subscription. Stripe based first or manual otherwise.
 * @param subscriptions
 */
export const getCurrentSubscription = <
  TSub extends Pick<SubscriptionBase<any>, "current" | "stripeSubscriptionId">
>(
  subscriptions: TSub[]
): TSub | undefined =>
  subscriptions.find((s) => s.current && s.stripeSubscriptionId) ??
  subscriptions.find((s) => s.current);
