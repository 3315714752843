import { SettingsIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import React from "react";

export const AdvancedOptionsContainer = ({
  isDisabled,
  children,
}: {
  isDisabled?: boolean;
  children: React.ReactNode;
}) => (
  <Accordion allowToggle={true}>
    <AccordionItem isDisabled={isDisabled}>
      <AccordionButton>
        <SettingsIcon mr="10px" />
        <Text>Advanced</Text>
      </AccordionButton>
      <AccordionPanel>{children}</AccordionPanel>
    </AccordionItem>
  </Accordion>
);
