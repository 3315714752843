import { Flex, Spacer, Text, useDisclosure } from "@chakra-ui/react";
import { DataSourceType } from "@syla/shared/types/models/DataSourceBase";
import React, { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../../contexts/UserContext";
import { DataSourceInputType } from "../../../../../helper/dataSource/DataSourceInputType";
import { buildFirstDataSourceLink } from "../../../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import {
  DataSource,
  AddDataSourceForm,
} from "../../../../../types/dataSource/dataSource";
import { ButtonVariant } from "../../../../atoms/ButtonVariant";
import { ImportError } from "../../../../atoms/ImportError";
import { Form } from "../../../../molecules/forms/FormComponents";
import { AdvancedOptions } from "../../addDataSourceDrawer/AdvancedOptions";
import { useImportContext } from "../../ImportContext";
import { ImportDataSource } from "../../ImportDataSource";
import { SkipForNowBtns } from "../SkipForNowBtns";
import { InstructionBox } from "../../../../molecules/overlayBoxes/InstructionBox";
import { Device } from "../../../../../types/user/device";

export const AddFirstDataSource = ({
  source,
  instructions,
}: {
  source: DataSource;
  instructions: string;
}) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const {
    state: {
      importComplete,
      importInProgress,
      importError,
      importReady,
      importType,
    },
    actions: { beginImport },
  } = useImportContext();

  const { finishSignUpFlow, userDevice } = useContext(UserContext);
  const history = useHistory();

  // hook form
  const customNameInitial = source.name;
  const formMethods = useForm<AddDataSourceForm>({
    defaultValues: {
      customName: customNameInitial,
      storedFiles: [],
    },
  });

  const { watch, handleSubmit } = formMethods;

  const nameRequired = source.type == DataSourceType.Custom;

  // Instruction Box state
  const { isOpen: showInstruction, onToggle: onToggleInstruction } =
    useDisclosure({ defaultIsOpen: false });

  return (
    <Flex direction="column">
      {!importComplete && (
        <Flex direction="row" justifyContent="space-between">
          <SkipForNowBtns isDisabled={importInProgress} />
          {userDevice !== Device.Desktop && (
            <Text
              onClick={() => onToggleInstruction()}
              fontSize="0.875rem"
              textDecoration="underline"
              fontWeight="500"
              color="red.500"
              cursor="pointer"
            >
              {showInstruction ? "Hide instructions" : "View instructions"}
            </Text>
          )}
        </Flex>
      )}

      {userDevice !== Device.Desktop && showInstruction && (
        <InstructionBox instructions={instructions} />
      )}

      <Flex
        direction="column"
        bg="white.0"
        borderWidth="1px"
        borderColor="white.500"
        py="20px"
      >
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(beginImport)} noValidate>
            <ImportDataSource
              selectedDataSource={source}
              nameRequired={nameRequired}
            />
            <Spacer mb="50px" />
            <ImportError
              error={importError}
              customFormat={importType == DataSourceInputType.Custom}
            ></ImportError>
            <Flex
              direction="column"
              w="100%"
              p="16px 24px"
              bottom="0px"
              bgColor="white.0"
            >
              {!importInProgress && !importComplete && (
                <AdvancedOptions
                  captureName={!nameRequired}
                  disabled={importInProgress}
                />
              )}
              {importComplete ? (
                <Flex justifyContent="center">
                  <ButtonVariant
                    content="Let's go"
                    spam="share"
                    color="red"
                    onClick={() => {
                      finishSignUpFlow();
                      history.replace("/");
                    }}
                  />
                </Flex>
              ) : (
                <Flex justifyContent="space-between" mt="8px">
                  <ButtonVariant
                    content="Back"
                    disabled={importInProgress}
                    spam="share"
                    outlineType="outlineGray"
                    onClick={() =>
                      history.push(buildFirstDataSourceLink({ accountId }))
                    }
                  />
                  <ButtonVariant
                    content={importInProgress ? "Syncing..." : "Secure Sync"}
                    isDisabled={
                      importInProgress ||
                      !importReady ||
                      // disable submit when no files selected
                      (importType != DataSourceInputType.Api &&
                        !watch("storedFiles")?.length)
                    }
                    spam="share"
                    color="red"
                    leftIcon="lock"
                    onClick={() => {}}
                    type="submit"
                  />
                </Flex>
              )}
            </Flex>
          </Form>
        </FormProvider>
      </Flex>
    </Flex>
  );
};
