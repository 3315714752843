import { Flex, Button } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../../../contexts/UserContext";

export const SkipForNowBtns = ({ isDisabled }: { isDisabled?: boolean }) => {
  const history = useHistory();
  const { finishSignUpFlow } = useContext(UserContext);

  return (
    <Flex mb="20px" alignItems="center">
      {/* Feedback: hide the help button */}
      {/* <Button
        color="black.600"
        fontWeight="500"
        fontSize="1rem"
        isDisabled={isSubmitting}
        mr="30px"
        variant="link"
        _focus={{ borderWidth: "0", bgColor: "transparent" }}
        _active={{ borderWidth: "0", bgColor: "transparent" }}
      >
        Help
      </Button> */}
      <Button
        color="red.500"
        fontWeight="500"
        fontSize="1rem"
        isDisabled={isDisabled}
        onClick={() => {
          finishSignUpFlow();
          history.replace("/");
        }}
        variant="link"
        _focus={{ borderWidth: "0", bgColor: "transparent" }}
        _active={{ borderWidth: "0", bgColor: "transparent" }}
      >
        Skip for now
      </Button>
    </Flex>
  );
};
