import { groupBy, map } from "lodash";
import { Decimal, sumWithDecimal } from "@syla/shared/decimal";
import { Ledger } from "../../types/ledger";
import { Asset } from "../../types/asset/asset";

export interface BalanceDetail {
  asset: Asset;
  amount: Decimal;
  balance?: Decimal;
  walletBalance?: Decimal;
}

export const getBalanceDetailsFromLedgers = (
  ledgers: Ledger[]
): BalanceDetail[] => {
  const groupedLedgers = groupBy(ledgers, (to) => to.asset._id);

  // Loop over each asset
  return map(groupedLedgers, (assetLedgers) => ({
    asset: assetLedgers[0].asset,
    amount: sumWithDecimal(assetLedgers, "amount"),
    ...assetLedgers.at(-1)?.balances,
  }));
};
