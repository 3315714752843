import { Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, memo } from "react";
import { isBrowser } from "react-device-detect";
import { useQueryClient } from "react-query";
import { changePassword } from "../api/user/changePassword";
import {
  PageContainer,
  PageHeadingContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { errorToastOptions } from "../components/molecules/errorToastOptions";
import { NameForm } from "../components/molecules/forms/settingForms/NameForm";
import { UserPasswordForm } from "../components/molecules/forms/settingForms/UserPasswordForm";
import { SettingRow } from "../components/organisms/setting/SettingRow";
import { ModalContext } from "../contexts/ModalContext";
import { UserContext } from "../contexts/UserContext";
import { isProduction } from "../helper/environment";
import { queryClient } from "../store/transactionsStore";
import { useMutationUpdateUser } from "../store/useMutationUpdateUser";
import {
  useQueryGetUser,
  USER_DETAILS_QUERY_KEY,
} from "../store/useQueryGetUser";
import {
  SectionContainer,
  SectionHeading,
  SettingLabel,
  Btn,
} from "./accountSettings/AccountSettingsCommon";
import { DeleteUserDialog } from "./userSettings/DeleteUserDialog";

export const UserSettings = () => {
  const { userDetails } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);

  // fetch user details
  const {
    isLoading: userDetailsIsLoading,
    data: user,
    refetch: refetchUserDetails,
    isStale: userDetailsStale,
  } = useQueryGetUser();

  const {
    mutateAsync: updateUser,
    isLoading: updateUserIsLoading,
    error: updateUserError,
  } = useMutationUpdateUser();

  const toast = useToast();

  useEffect(() => {
    if (updateUserError) {
      toast({
        ...errorToastOptions,
        position: "top",
        title: "Problem saving",
        description:
          "Please try again later or contact support if problems persist",
      });
    }
  }, [toast, updateUserError]);

  if (userDetailsIsLoading)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center" my="40px">
          <Spinner />
        </Flex>
      </PageContainer>
    );

  if (!user)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex alignItems="center" direction="column" my="40px">
          <Text textAlign="center">
            There was an error fetching user data, try again?
          </Text>
          <Button
            colorScheme="blue"
            onClick={() => {
              refetchUserDetails();
            }}
            size="sm"
            variant="link"
            w="100px"
          >
            Refresh
          </Button>
        </Flex>
      </PageContainer>
    );

  const ChangePasswordForm = memo(() => {
    const { closeModal } = useContext(ModalContext);
    const queryClient = useQueryClient();

    return (
      <UserPasswordForm
        currentPassword
        confirmPassword
        onPasswordChangeRequest={async ({ currentPassword, newPassword }) => {
          await changePassword({
            currentPassword: currentPassword!,
            newPassword,
          });
          queryClient.invalidateQueries([...USER_DETAILS_QUERY_KEY]);
          closeModal();
        }}
      />
    );
  });

  return (
    <PageContainer isBrowser={isBrowser}>
      <PageHeadingContainer>
        <PageHeading>User Profile</PageHeading>
      </PageHeadingContainer>
      <Flex
        direction={isBrowser ? { base: "column", xl: "row" } : "column"}
        justifyContent={
          isBrowser ? { base: "center", xl: "space-between" } : "center"
        }
      >
        {/* ---------------------- LHS ---------------------- */}
        <Flex
          direction="column"
          w={isBrowser ? { base: "100%", xl: "48%" } : "100%"}
          maxW={isBrowser ? { base: "600px", xl: "800px" } : "600px"}
        >
          {/* ---------------------- User Settings ---------------------- */}
          <SectionContainer direction="column" minW="300px">
            <SectionHeading>User Settings</SectionHeading>
            <SettingRow
              label="Email"
              currentValue={userDetails?.email ?? ""}
              action={() => {}}
              type="none"
            />
            <SettingRow
              label="Password"
              currentValue="******"
              action={() =>
                openModal({
                  type: "component",
                  heading: "Change Password",
                  component: <ChangePasswordForm />,
                })
              }
              type="edit"
            />
            <SettingRow
              label="Name"
              currentValue={user.fullName}
              action={() =>
                openModal({
                  type: "component",
                  heading: "Display Name",
                  contents: [
                    "Set your display name. People you share account access with can see this.",
                  ],
                  component: (
                    <NameForm
                      currentValue={user.fullName || ""}
                      isRequired={false}
                      onSave={(name) =>
                        updateUser({
                          fullName: name,
                        })
                      }
                    />
                  ),
                })
              }
              type="edit"
            />

            {/* ---------------------- Support Access ---------------------- */}
            <Flex direction="row" mt="30px" justifyContent="space-between">
              <Flex direction="column" mb="20px" w="70%">
                <SettingLabel mb="20px">Support Access</SettingLabel>
                <Text fontSize="0.875rem" color="black.500">
                  Allow the Syla support team to access your account in order to
                  assist with support requests.
                </Text>
              </Flex>
              <Btn
                justifySelf="end"
                onClick={() =>
                  updateUser({
                    supportAccess: !user?.supportAccess,
                  }).then(() => {
                    toast({
                      position: "top",
                      duration: 1000,
                      ...(!user?.supportAccess
                        ? {
                            status: "success",
                            title: "Support Access Granted",
                          }
                        : {
                            status: "warning",
                            title: "Support Access Revoked",
                          }),
                    });
                  })
                }
                isLoading={updateUserIsLoading || userDetailsStale}
              >
                {user?.supportAccess ? "Disable Support" : "Enable Support"}
              </Btn>
            </Flex>

            {/* ---------------------- Delete User ---------------------- */}
            {!isProduction() && (
              <Flex direction="row" mt="30px" justifyContent="space-between">
                <Flex direction="column" mb="20px" w="70%">
                  <SettingLabel mb="20px">Delete User</SettingLabel>
                  <Text fontSize="0.875rem" color="black.500">
                    This deletes your user, including any account data and
                    purchases for accounts belonging only to you.
                  </Text>
                </Flex>
                <Btn
                  justifySelf="end"
                  onClick={() => {
                    openModal({
                      type: "component",
                      icon: "delete",
                      heading: "Are you sure you want to delete your user?",
                      component: <DeleteUserDialog />,
                      hideCloseButton: true,
                    });
                  }}
                >
                  Delete User
                </Btn>
              </Flex>
            )}
          </SectionContainer>
        </Flex>
      </Flex>
    </PageContainer>
  );
};
