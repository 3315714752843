import { useDisclosure } from "@chakra-ui/react";
import React, { useRef } from "react";
import { isBrowser } from "react-device-detect";

import { ButtonVariant } from "../components/atoms/ButtonVariant";
import {
  PageContainer,
  PageHeadingContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import {
  PageHeadingTagContainer,
  PageHeadingTag,
} from "../components/molecules/PageHeadingTag";
import { AddTransactionDrawer } from "../components/organisms/transaction/addTransaction/AddTransactionDrawer";
import { SelectedTxContextProvider } from "../components/organisms/transaction/mainTransactionTable/SelectedTxContext";
import { TransactionsView } from "../components/organisms/transaction/mainTransactionTable/TransactionsView";
import { useTransactionsStore } from "../store/transactionsStore";
import { useQueryGetAccountTransactionCount } from "../store/useQueryGetAccountTransactionCount";

export const Transactions = () => {
  // Add transaction drawer
  const {
    isOpen: isAddTransactionDrawerOpen,
    onOpen: onOpenAddTransactionDrawer,
    onClose: onCloseAddTransactionDrawer,
  } = useDisclosure();
  const addTransactionBtn = useRef();
  const { data: txCount } = useQueryGetAccountTransactionCount();
  const groupListTotal = useTransactionsStore((state) => state.groupListTotal);
  const filtering = useTransactionsStore((state) => state.filtering);

  const availableTxCount = filtering ? groupListTotal : txCount;

  return (
    <PageContainer isBrowser={isBrowser}>
      {/* ------------ Page heading ---------------- */}
      <PageHeadingContainer>
        <PageHeadingTagContainer>
          <PageHeading>Transactions</PageHeading>
          {txCount != null && (
            <PageHeadingTag>
              {filtering && groupListTotal != null
                ? `${groupListTotal.toLocaleString()} of `
                : ""}
              {txCount.toLocaleString()}
            </PageHeadingTag>
          )}
        </PageHeadingTagContainer>
        <ButtonVariant
          ref={addTransactionBtn}
          content="Add Transaction"
          leftIcon="add"
          color="red"
          onClick={onOpenAddTransactionDrawer}
        />
      </PageHeadingContainer>

      {/* ------------ Main transaction table ---------------- */}
      <SelectedTxContextProvider availableTransactionCount={availableTxCount}>
        <TransactionsView />
      </SelectedTxContextProvider>

      {/* ------------ Add transaction drawer ---------------- */}
      {isAddTransactionDrawerOpen && (
        <AddTransactionDrawer
          isAddTransactionDrawerOpen={isAddTransactionDrawerOpen}
          onCloseAddTransactionDrawer={onCloseAddTransactionDrawer}
        />
      )}
    </PageContainer>
  );
};
