import {
  ChevronDownIcon,
  CheckIcon,
  IconProps,
  AddIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Text,
  HStack,
  VStack,
  Spinner,
  Link,
  useDisclosure,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Collapse,
  ComponentWithAs,
  Flex,
  StackProps,
} from "@chakra-ui/react";
import {
  getAccountInitials,
  getAccountShortName,
} from "@syla/shared/types/models/AccountAccessBase";
import {
  GetAccountsResponseBody,
  AccountAccessDetails,
} from "@syla/shared/types/responses/GetAccountsResponse";
import React, { useState, useCallback } from "react";
import { isBrowser } from "react-device-detect";
import { NavLink, useLocation } from "react-router-dom";
import { useUserContext } from "../../../contexts/UserContext";
import { getPageUrl } from "../../../helper/getFullPageUrl";
import { useFuseSearch } from "../../../hooks/useFuseSearch";
import { ReactComponent as AccountsIcon } from "../../../images/icons/navbar/accounts.svg";
import { buildAccountsLink } from "../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryGetAccounts } from "../../../store/useQueryGetAccounts";
import { Device } from "../../../types/user/device";
import { SkeletonRect } from "../../atoms/Skeletons";
import { SearchBar } from "../../molecules/forms/FormComponents";

import { useCreateAccountForm } from "./UseCreateAccountForm";

export function AccountNav() {
  const { userDevice } = useUserContext();
  const isMobileView = userDevice === Device.Mobile || !isBrowser;

  return isMobileView ? CollapseAccountNav() : OverlayAccountNav();
}

export function OverlayAccountNav() {
  const { data: accountsResponse } = useQueryGetAccounts();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const currentAccount = accountsResponse?.accounts[accountId];

  return (
    <Popover autoFocus placement="bottom" offset={[68, 0]}>
      <PopoverTrigger>
        <Box as="button" w="100%" px="0.5rem" py="0.5rem">
          <CurrentAccount
            currentAccount={currentAccount}
            ArrowIcon={ChevronDownIcon}
          />
        </Box>
      </PopoverTrigger>
      {accountsResponse && currentAccount && (
        <PopoverContent
          border="1px"
          borderColor="gray.300"
          borderRadius="0.5rem"
          backgroundColor="gray.300"
          overflow="hidden"
          boxShadow="xl"
        >
          <AccountSwitcher
            accounts={accountsResponse.accounts}
            currentAccountId={currentAccount._id}
          />
        </PopoverContent>
      )}
    </Popover>
  );
}

export function CollapseAccountNav() {
  const { data: accountsResponse } = useQueryGetAccounts();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const currentAccount = accountsResponse?.accounts[accountId];

  const { isOpen, onToggle } = useDisclosure();
  const ArrowIcon = isOpen ? ChevronUpIcon : ChevronDownIcon;

  return (
    <VStack position="relative" w="100%" alignItems="flex-start" spacing={0}>
      <Box onClick={onToggle} w="100%" px="0.5rem" py="0.5rem">
        <CurrentAccount currentAccount={currentAccount} ArrowIcon={ArrowIcon} />
      </Box>
      <Collapse in={isOpen} style={{ width: "100%" }}>
        <AccountSwitcher
          accounts={accountsResponse?.accounts}
          currentAccountId={accountId}
        />
      </Collapse>
    </VStack>
  );
}

function CurrentAccount({
  currentAccount,
  ArrowIcon,
}: {
  currentAccount: AccountAccessDetails<string> | undefined;
  ArrowIcon: ComponentWithAs<"svg", IconProps>;
}) {
  return (
    <HStack
      w="100%"
      textAlign="left"
      alignItems={"center"}
      p="0.5rem"
      _hover={{ bg: "gray.700" }}
      role="group"
      cursor="pointer"
      rounded="md"
      borderColor="gray.700"
      borderWidth="2px"
    >
      <SkeletonRect isLoaded={!!currentAccount} w="100%">
        <VStack w="100%">
          <HStack w="100%">
            <Flex
              flexShrink={0}
              h="2.25rem"
              w="2.25rem"
              rounded="md"
              align="center"
              justify="center"
              backgroundColor="gray.200"
              _groupHover={{ bg: "gray.100" }}
            >
              <Text
                fontSize="xs"
                fontWeight="bold"
                color="gray.700"
                _groupHover={{ color: "gray.600" }}
              >
                {currentAccount ? getAccountInitials(currentAccount) : ".."}
              </Text>
            </Flex>
            <Text
              fontSize="xs"
              color="gray.100"
              noOfLines={2}
              wordBreak="break-word"
              w="100%"
              textOverflow="ellipsis"
              _groupHover={{ color: "gray.25" }}
            >
              {currentAccount ? getAccountShortName(currentAccount) : "..."}
            </Text>
            <ArrowIcon
              flexShrink={0}
              color="gray.100"
              boxSize="1.5rem"
              _groupHover={{ color: "gray.25" }}
            />
          </HStack>
        </VStack>
      </SkeletonRect>
    </HStack>
  );
}

function AccountSwitcher({
  accounts,
  currentAccountId,
  ...rest
}: {
  accounts: GetAccountsResponseBody<string>["accounts"] | undefined;
  currentAccountId: string;
} & StackProps) {
  const currentAccount = accounts?.[currentAccountId];
  const [searchValue, setSearchValue] = useState("");

  // filter account list
  const otherAccounts = Object.values(accounts ?? []);
  //   .filter(
  //   (account) => account._id != currentAccountId
  // )
  const filteredAccounts = useFuseSearch(
    otherAccounts,
    ["nickname", "contactEmail", "fullName"],
    searchValue
  ).slice(0, 10); // top 10

  const openCreateAccountForm = useCreateAccountForm();

  // determine account switch urls
  const location = useLocation();

  const getAccountUrl = useCallback(
    (switchAccountId) => {
      const currentLocation = getPageUrl(location, {
        path: true,
        search: true,
        hash: true,
      });

      const newLocation = currentLocation.replace(
        currentAccountId,
        switchAccountId
      );

      return newLocation;
    },
    [currentAccountId, location]
  );

  if (!currentAccount) return <Spinner />;

  return (
    <VStack background="white" {...rest} spacing={0}>
      <Box
        px="1rem"
        py="0.5rem"
        w="100%"
        background="gray.25"
        boxShadow="base"
        alignItems="flex-start"
      >
        <Text noOfLines={2}>{getAccountShortName(currentAccount)}</Text>
      </Box>
      <VStack alignItems="flex-start" w="100%" pb="0.5rem" px="1rem">
        <Box pt="1rem" w="100%">
          <SearchBar
            placeholder={"Search accounts"}
            value={searchValue}
            onChange={(v) => setSearchValue(v.target.value)}
          />
        </Box>
        <Box>
          <Text fontSize="xs" color="gray.600">
            most recent
          </Text>
        </Box>
        {filteredAccounts.map(({ _id, contactEmail, nickname, fullName }) => (
          <NavLink
            key={_id}
            to={getAccountUrl(_id)}
            style={{ display: "inline-block", width: "100%" }}
          >
            <HStack w="100%" alignItems="center" role="group">
              <Flex
                flexShrink={0}
                h="2.25rem"
                w="2.25rem"
                rounded="md"
                align="center"
                justify="center"
                backgroundColor="gray.200"
                _groupHover={{ bg: "gray.300" }}
              >
                <Text
                  fontSize="xs"
                  fontWeight="bold"
                  color="gray.700"
                  _groupHover={{ color: "gray.900" }}
                >
                  {getAccountInitials({ fullName })}
                </Text>
              </Flex>
              <Text
                w="100%"
                fontSize="sm"
                fontWeight="md"
                noOfLines={2}
                color="gray.700"
                _groupHover={{ color: "gray.900" }}
              >
                {getAccountShortName({ nickname, fullName })}
              </Text>
              {_id == currentAccountId && (
                <CheckIcon w={3.5} color="primary.500" />
              )}
            </HStack>
          </NavLink>
        ))}
        <Link
          color="primary.500"
          style={{ textDecoration: "none" }}
          _hover={{ color: "primary.700", textDecoration: "none" }}
          onClick={openCreateAccountForm}
          role="group"
        >
          <HStack w="100%" alignItems="center">
            <Flex
              w="2rem"
              h="2rem"
              p="0.5rem"
              textColor="primary.500"
              _groupHover={{ textColor: "primary.700" }}
              align="center"
              justify="center"
            >
              <AddIcon w="100%" h="100%" />
            </Flex>
            <Text fontSize="sm" fontWeight="md">
              add new account
            </Text>
          </HStack>
        </Link>
      </VStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        background="gray.100"
        borderTop="1px"
        borderColor="gray.300"
        py="0.5rem"
        px="1rem"
      >
        <NavLink to={buildAccountsLink({ accountId: currentAccountId })}>
          <HStack alignItems="center" role="group">
            <Box
              w="2rem"
              h="2rem"
              p="0.5rem"
              rounded="md"
              backgroundColor="primary.500"
              textColor="white.0"
              _groupHover={{ bg: "primary.700" }}
              boxShadow="xs"
            >
              <AccountsIcon />
            </Box>
            <Text color="gray.700" _groupHover={{ color: "gray.900" }}>
              Accounts
            </Text>
          </HStack>
        </NavLink>
      </VStack>
    </VStack>
  );
}
