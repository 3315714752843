import { GridItem, VStack, Text, ButtonGroup } from "@chakra-ui/react";
import { getCurrentSubscription } from "@syla/shared/types/helpers/subscriptions";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import { StripeBillingPortalAction } from "@syla/shared/types/requests/CreateStripeBillingPortalRequest";
import React, { Fragment, useState } from "react";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { useRemoveBillingOwnerDialog } from "../../components/organisms/accounts/useRemoveBillingOwnerDialog";
import { captureRequestError } from "../../helper/captureRequestError";
import { getFullPageUrl } from "../../helper/getFullPageUrl";
import { getUserDetails } from "../../helper/getUserDetails";
import { hasAccountPermission } from "../../helper/permissions";
import { redirectTo } from "../../helper/RedirectTo";
import { useReportError } from "../../helpers/useReportError";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useMutationCreateStripeBillingPortalSession } from "../../store/useMutationCreateStripeBillingPortalSession";
import { Account } from "../../types/Account";
import { Subscription } from "../../types/plans/subscription";
import { ValueAndButtons } from "./SubscriptionStatus";

export function BillingOwnerDetails({
  subscriptions,
  account,
  isRedirecting,
  setIsRedirecting,
}: {
  subscriptions: Subscription[];
  account: Account;
  isRedirecting: boolean;
  setIsRedirecting: (value: boolean) => void;
}) {
  const currentSub = getCurrentSubscription(subscriptions);

  const { mutateAsync: createStripeBillingPortalSession } =
    useMutationCreateStripeBillingPortalSession();

  const [isLoading, setIsLoading] = useState(false);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const reportError = useReportError();

  const removeBillingOwnerPrompt = useRemoveBillingOwnerDialog({ accountId });

  return (
    <Fragment>
      <GridItem>
        <VStack alignItems="flex-start">
          <Text fontWeight="bold">Billing account owner</Text>
          <Text>
            The person responsible for paying the subscription on this account
          </Text>
        </VStack>
      </GridItem>
      {/* Billing Account Owner */}
      <GridItem>
        <ValueAndButtons>
          <Text>
            {!currentSub || !currentSub.billingOwner
              ? "None"
              : currentSub.billingAccountAccess
              ? "You"
              : getUserDetails(currentSub.billingOwner)}
          </Text>
          <ButtonGroup>
            {/* Manage Button */}
            {currentSub &&
              currentSub.stripeSubscriptionId &&
              currentSub.billingAccountAccess && (
                <ButtonVariant
                  content="Manage billing"
                  color="default"
                  outlineType="outlineBlack"
                  rightIcon="external"
                  disabled={isRedirecting}
                  isLoading={isLoading}
                  onClick={async () => {
                    try {
                      setIsRedirecting(true);
                      setIsLoading(true);
                      const response = await createStripeBillingPortalSession({
                        accountId,
                        action: StripeBillingPortalAction.Renew,
                        stripeSubscriptionId: currentSub!.stripeSubscriptionId!,
                        returnUrl: getFullPageUrl(location),
                      });
                      redirectTo(response.portalUrl);
                    } catch (error) {
                      setIsRedirecting(false);
                      setIsLoading(false);
                      captureRequestError(error);
                      reportError(error);
                    }
                  }}
                />
              )}

            {/* Remove billing owner */}
            {currentSub &&
              !currentSub.billingAccountAccess &&
              currentSub.billingOwner &&
              hasAccountPermission(account, AccountPermission.ChangeAccess) && (
                <ButtonVariant
                  content="Remove Billing Owner"
                  color="darkRed"
                  disabled={isRedirecting}
                  isLoading={isLoading}
                  onClick={() =>
                    removeBillingOwnerPrompt(currentSub!.billingOwner!._id)
                  }
                />
              )}
          </ButtonGroup>
        </ValueAndButtons>
      </GridItem>
    </Fragment>
  );
}
