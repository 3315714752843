import { RegisterRequestBody } from "@syla/shared/types/requests/RegisterRequest";
import axios from "axios";

export const register = async ({
  email,
  password,
  accountType,
  accountSubType,
}: RegisterRequestBody): Promise<void> => {
  await axios.post("/users", {
    email,
    password,
    accountType,
    accountSubType,
  });
};
