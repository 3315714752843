import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "antd/dist/antd.css";
import "core-js/actual";

import "focus-visible/dist/focus-visible";
import { get_HEAP_APP_ID } from "@syla/shared/types/helpers/constants";
import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClientProvider } from "react-query";
import { IntercomProvider } from "react-use-intercom";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { App } from "./App";
import { ModalContextProvider } from "./contexts/ModalContext";
import { UserContextProvider } from "./contexts/UserContext";
import { environmentName, isProduction } from "./helper/environment";
import { loadHeap } from "./loadHeap";
import { queryClient } from "./store/transactionsStore";
import { theme } from "./theme";

const history = createBrowserHistory();

// init Sentry
Sentry.init({
  dsn: "https://0847a69b68e041d4b83361b767ab08a0@o1160668.ingest.sentry.io/6245235",
  environment: environmentName(),
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: isProduction() ? 0.5 : 1.0,

  // configure replays
  replaysSessionSampleRate: isProduction() ? 0.1 : 0,
  replaysOnErrorSampleRate: isProduction() ? 1 : 0,

  // filter errors to scripts on syla domain
  allowUrls: [/^https?:\/\/app(-staging)?\.syla\.com\.au/],

  beforeSend: (event) => {
    const { headers } = event.request ?? {};

    // ignore bots (HeadlessChrome)
    if (headers && /HeadlessChrome/i.test(headers["User-Agent"])) {
      return null;
    }

    return event;
  },
});

// load Heap for user behaviour tracking
loadHeap(get_HEAP_APP_ID(isProduction()));

const gtmParams = { id: isProduction() ? "GTM-MHSM4HD9" : "GTM-DUMMY" };

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <UserContextProvider>
          <ModalContextProvider>
            <IntercomProvider appId="bjre73d4" autoBoot={true}>
              <GoogleOAuthProvider clientId="854712582346-uhpmud4mr4s58elvp1hu7l1c04ob8mq3.apps.googleusercontent.com">
                <GTMProvider state={gtmParams}>
                  <App history={history} />
                </GTMProvider>
              </GoogleOAuthProvider>
            </IntercomProvider>
          </ModalContextProvider>
        </UserContextProvider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
