import axios from "axios";

export const importDataSources = async ({
  accountId,
  dataSourceId,
  files,
}: {
  accountId: string;
  dataSourceId: string;
  files: File[];
}) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  await axios.post(
    `/accounts/${accountId}/wallets/import?dataSourceId=${encodeURIComponent(
      dataSourceId
    )}`,
    formData
  );
};
