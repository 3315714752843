export const buttonTheme = {
  baseStyle: {
    _hover: {
      bgColor: "gray.100",
    },
    _active: {
      bgColor: "gray.200",
    },
  },
  variants: {
    outline: {
      boxShadow: "base",
      bgColor: "white",
      _active: {
        border: "1.5px #3F3F46 solid",
      },
      _focus: {
        border: "1.5px #3F3F46 solid",
      },
    },
  },
};
