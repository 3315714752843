import {
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
  useToast,
  Box,
} from "@chakra-ui/react";
import { ParcelMethod } from "@syla/shared/types/models/AccountBase";
import { isEqual } from "lodash";
import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { ModalContext } from "../../../../contexts/ModalContext";
import { getParcelMethodOptions } from "../../../../helper/user/getParcelMethodOptions";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { checkStatusAndDoPendingWork } from "../../../../store/pendingWork";
import { useMutationUpdateAccount } from "../../../../store/useMutationUpdateAccount";
import { TaxSettings } from "../../../../types/account/taxSettings";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { ModalContent } from "../../../atoms/ModalContent";
import { SingleThumbnailSelectBox } from "../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import { Form, Label } from "../FormComponents";

interface ParcelMethodSettingHookForm {
  parcelMethods: ParcelMethod[];
}

const SELECTOR_WIDTH = "17rem";

export const ParcelMethodForm = ({
  currentTaxSettings,
}: {
  currentTaxSettings: Pick<TaxSettings, "parcelMethods">;
}): JSX.Element => {
  const [inputError, setInputError] = useState<string | undefined>();
  const { closeModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(accountId);

  const toast = useToast();

  const parcelMethods = currentTaxSettings.parcelMethods;
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ParcelMethodSettingHookForm>({
    defaultValues: { parcelMethods: parcelMethods },
  });

  const onSubmit = async (data: ParcelMethodSettingHookForm) => {
    const { parcelMethods: newParcelMethods } = data;
    setInputError(undefined);

    if (isEqual(newParcelMethods, parcelMethods)) {
      closeModal();
      return;
    }

    try {
      await updateAccount({
        taxSettings: {
          parcelMethods: newParcelMethods,
        },
      });
      checkStatusAndDoPendingWork();
      closeModal();
    } catch (error: any) {
      toast({
        title: "Error Updating User Settings",
        description: error?.response?.data || "Unexpected error.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      setInputError("Unexpected error occurred, please try again later");
    }
  };

  return (
    <Box w="100%">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex
          direction="row"
          justifyContent="space-between"
          borderWidth="0 0 1px 0"
          w="100%"
        >
          <Text color="black.600" fontSize="0.75rem">
            Financial year
          </Text>
          <Text color="black.600" fontSize="0.75rem">
            Parcel Method
          </Text>
        </Flex>
        <ModalContent direction="column" w="100%" overflowX="hidden">
          {parcelMethods.map((financialYear, index) => (
            <FormControl
              isDisabled={isSubmitting}
              isInvalid={errors.parcelMethods !== undefined}
              isRequired
              key={financialYear.startYear}
            >
              <Flex
                direction="row"
                justifyContent="space-between"
                borderWidth="0 0 1px 0"
                alignItems="center"
                py="10px"
              >
                <Label
                  content={`1 July ${financialYear.startYear} - 30 June ${financialYear.endYear}`}
                />
                <Controller
                  control={control}
                  name={`parcelMethods.${index}.method`}
                  render={({ field: { onChange, value } }) => (
                    <SingleThumbnailSelectBox
                      placeholder="Select a method"
                      options={getParcelMethodOptions()}
                      selectedOption={value}
                      onChangeSelection={(selection) => {
                        // prevent deselection and changing of selections during submission
                        if (selection && !isSubmitting) onChange(selection);
                      }}
                      defaultValue={financialYear.method}
                      description
                      selectBtnProps={{ height: "40px", width: SELECTOR_WIDTH }}
                      contentProps={{
                        maxWidth: SELECTOR_WIDTH,
                      }}
                    />
                  )}
                />
              </Flex>
            </FormControl>
          ))}
        </ModalContent>
        {inputError && (
          <FormControl isInvalid={inputError !== undefined}>
            <FormErrorMessage>{inputError}</FormErrorMessage>
          </FormControl>
        )}
        <ButtonVariant
          content="Save changes"
          outlineType="solid"
          color="red"
          onClick={() => undefined}
          isLoading={isSubmitting}
          type="submit"
          spam="spam"
          mt="15px"
          mb="10px"
        />
      </Form>
    </Box>
  );
};
