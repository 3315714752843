import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { LaunchAffiliateAccountDashboardResponseBody } from "@syla/shared/types/responses/LaunchAffiliateAccountDashboardResponse";
import axios from "axios";

export const manageAffiliateAccount =
  async (): Promise<LaunchAffiliateAccountDashboardResponseBody> => {
    const { data } = await axios.post<
      TransportType<LaunchAffiliateAccountDashboardResponseBody>
    >("/users/launch-affiliate-account-dashboard");

    return data;
  };
