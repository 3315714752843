import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, Text, Heading, Box, BoxProps, VStack } from "@chakra-ui/react";
import { ImportWalletCsvResponseStatusCodes } from "@syla/shared/types/responses/ImportWalletCsvResponse";
import Axios, { AxiosError } from "axios";
import React from "react";
import ReactMarkdown from "react-markdown";
import { getSyncWalletErrorProps } from "../../helper/wallet/getSyncWalletErrorProps";
import { WindowOpenFailedError } from "../organisms/dataSource/AuthoriseModule";
import { ImportAssistButton } from "./ImportAssistButton";

export const ErrorBox = ({
  children,
  error,
  ...props
}: {
  error: Error | undefined;
  children: React.ReactNode;
} & BoxProps) => (
  <Box
    mt="10px"
    px="30px"
    py="10px"
    mb="20px"
    bgColor="yellow.100"
    borderWidth="1px"
    borderColor="white.500"
    borderRadius="10px"
    /* Animation props */
    visibility={error ? "visible" : "hidden"}
    opacity={error ? 1 : 0}
    height={error ? "auto" : "0"}
    transition="opacity 300ms"
    {...props}
  >
    {children}
  </Box>
);

export const ImportError = ({
  error,
  customFormat,
}: {
  error: Error | undefined;
  customFormat?: boolean;
}): JSX.Element | null => {
  console.log({ error });

  let errorType: "unknown" | "invalidFile" | "blockedWindow" = "unknown";
  if (
    (error as AxiosError)?.response?.status ==
    ImportWalletCsvResponseStatusCodes.InvalidFormat
  ) {
    errorType = "invalidFile";
  }

  if (error instanceof WindowOpenFailedError) {
    errorType = "blockedWindow";
  }

  const { errorTitle, errorContent } = getSyncWalletErrorProps(error);

  return (
    <ErrorBox error={error}>
      {errorType == "invalidFile" ? (
        <>
          <Heading textAlign="left" size="sm" mb="10px" ml="auto" mr="auto">
            {"Oops, Syla couldn't understand your file"}
          </Heading>
          <>
            {(Axios.isAxiosError(error) &&
              error.response?.data &&
              error.response?.data != "InvalidFormat" && (
                <>
                  <ReactMarkdown linkTarget="_blank">
                    {(error as AxiosError)!.response!.data}
                  </ReactMarkdown>
                </>
              )) || (
              <Text>The transactions you uploaded couldn’t be imported.</Text>
            )}
          </>
          {customFormat && (
            <Text mt="10px">
              {"Please read the "}
              <Link
                fontWeight="bold"
                href="https://help.syla.com.au/en/articles/8281571-custom-csv-template-import-advanced"
                isExternal={true}
              >
                Docs <ExternalLinkIcon mx="2px" />
              </Link>{" "}
              and double check your file.
            </Text>
          )}
          <Text mt="10px" mb="10px">
            Don’t worry though, our support team is here to help you, just click
            below.
          </Text>
          <VStack w="100%" alignItems="flex-start">
            <ImportAssistButton />
          </VStack>
        </>
      ) : errorType == "blockedWindow" ? (
        <>
          <Heading size="sm" mb="10px">
            Window Blocked
          </Heading>
          <Text>
            The Connect window has been blocked from opening. You can try it
            again, but if problems persist please disable any ad-blocker to
            continue.
          </Text>
        </>
      ) : (
        // sync error format
        <>
          <Heading size="sm" mb="10px">
            {errorTitle}
          </Heading>
          <Text>{errorContent}</Text>
        </>
      )}
    </ErrorBox>
  );
};
