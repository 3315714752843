import { Flex, Text } from "@chakra-ui/react";
import React, { useContext, ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../../contexts/UserContext";
import { FormHeading } from "../../../molecules/forms/FormComponents";

export const AddDataSourceForm = ({ children }: { children: ReactNode }) => {
  const { userDetails } = useContext(UserContext);
  const history = useHistory();

  // redirect back to sign in page if the user is here without an access token, means they haven't registered yet
  useEffect(() => {
    if (!userDetails?.accessToken) history.push("/sign-in");
  }, [history, userDetails?.accessToken]);

  return (
    <Flex
      direction="column"
      w="100%"
      maxW="600px"
      bgColor="white.100"
      m="auto 6px"
      pb="50px"
    >
      <FormHeading> Add your first data source </FormHeading>
      <Text color="black.600" my="25px" fontSize="fontSize.normal">
        Let&#39;s add your data source / exchange so you can begin reviewing
        transactions and generating reports with Syla
      </Text>
      {children}
    </Flex>
  );
};
