// eslint-disable-next-line
import { Decimal } from "@syla/shared/decimal";
import { Transaction } from "../../types/transaction";

export const calculateTransactionTotals = (
  transactions: Transaction[]
): {
  totalFiatValue: string;
  totalFees: string;
  totalCost: string;
  totalGain: string;
} => {
  const totalFiatValue = Decimal.from("0");
  const totalFees = Decimal.from("0");
  const totalCost = Decimal.from("0");
  const totalGain = Decimal.from("0");

  // TODO
  // events.forEach(({ inLedgers, outLedgers, altInLedgers, altOutLedgers }) => {
  //   inLedgers.forEach((inLedger) => {
  //     totalFiatValue = totalFiatValue.plus(inLedger.marketValue ?? "0");
  //   });

  //   outLedgers.forEach((outLedger) => {
  //     totalGain = totalGain.plus(outLedger.profitLoss ?? "0");

  //     const cost = outLedger.matchedParcels.reduce(
  //       (total, parcel) => total.plus(parcel.cost ?? "0"),
  //       Decimal.from("0")
  //     );

  //     totalCost = totalCost.plus(cost);
  //   });

  //   altInLedgers.forEach((altInLedger) => {
  //     totalFees = totalFees.plus(altInLedger.marketValue ?? "0");
  //   });
  //   altOutLedgers.forEach((altOutLedger) => {
  //     totalGain = totalGain.plus(altOutLedger.profitLoss ?? "0");
  //     totalFees = totalFees.minus(altOutLedger.marketValue ?? "0");
  //   });
  // });

  return {
    totalFiatValue: totalFiatValue.toFixed(2),
    totalFees: totalFees.toFixed(2),
    totalCost: totalCost.toFixed(2),
    totalGain: totalGain.toFixed(2),
  };
};
