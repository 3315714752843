import { ArrowForwardIcon } from "@chakra-ui/icons";
import { GridItem, Text, Icon, Grid } from "@chakra-ui/react";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { uniqBy, uniqueId } from "lodash";
import React, { useMemo, useEffect, Fragment } from "react";
import {
  useGetWalletOptions,
  formatWalletOption,
} from "../../../../../../hooks/wallet/useGetWalletOptions";
import { useCurrentAccountStore } from "../../../../../../store/currentAccountStore";
import { useTransactionsStore } from "../../../../../../store/transactionsStore";
import { OrderFilterSetting } from "../../../../../../types/order/orderQuery";
import { SingleThumbnailSelectBox } from "../../../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import {
  borderColor,
  warningBorderColor,
  ArrowGridItem,
  DeleteButtonGridItem,
  ApplyAllButton,
  setOperation,
  ChangeWalletOperationEntry,
  operationDefs,
  OperationTitle,
} from "../common";

export function WalletBulkEdit({
  filters,
  operations,
  selectedIds,
  setOperations,
}: {
  operations: ChangeWalletOperationEntry[];
  setOperations: React.Dispatch<
    React.SetStateAction<ChangeWalletOperationEntry[]>
  >;
  selectedIds: string[];
  filters: OrderFilterSetting;
}) {
  const groups = useTransactionsStore((s) => s.groups);
  const selectedWalletOptions = useMemo(() => {
    return selectedIds.length
      ? uniqBy(
          withoutNullable(
            selectedIds.flatMap((gid) =>
              groups?.[gid].data.transactions.flatMap((t) => ({
                wallet: t.wallet,
                dataSource: t.dataSource,
              }))
            )
          ),
          ({ wallet }) => wallet._id
        ).map(({ wallet, dataSource }) =>
          formatWalletOption({
            _id: wallet._id,
            customName: wallet.customName,
            dataSource,
          })
        )
      : undefined;
  }, [groups, selectedIds]);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const walletOptions = useGetWalletOptions(accountId)?.data;
  const filteredWalletOptions = useMemo(() => {
    return filters.dataSource.length
      ? walletOptions?.filter((walletOption) =>
          filters.dataSource.includes(walletOption.value)
        )
      : undefined;
  }, [filters.dataSource, walletOptions]);

  // prepopulate operations based on selected types or filters
  useEffect(() => {
    if (selectedWalletOptions) {
      setOperations(
        selectedWalletOptions.map((option) => ({
          id: uniqueId(),
          type: "wallet",
          from: option.value,
        }))
      );
    } else if (filteredWalletOptions) {
      setOperations(
        filteredWalletOptions.map((option) => ({
          id: uniqueId(),
          type: "wallet",
          from: option.value,
        }))
      );
    } else {
      setOperations([{ id: uniqueId(), type: "wallet" }]);
    }
  }, [filteredWalletOptions, selectedWalletOptions, setOperations]);

  return (
    <Grid
      w="100%"
      templateColumns="1fr auto 1fr auto auto"
      alignItems="center"
      justifyItems="flex-start"
      gap={4}
    >
      <Fragment>
        {/* Type Header */}
        <GridItem colSpan={5} justifySelf="flex-start">
          <OperationTitle>{operationDefs["wallet"].label}</OperationTitle>
        </GridItem>
        {/* Operations */}
        {operations.map((op) => (
          <Fragment key={op.id}>
            <WalletOperation
              key={op.id}
              operation={op}
              fromWalletOptions={
                selectedWalletOptions ??
                filteredWalletOptions ??
                walletOptions ??
                []
              }
              fromOptionsLoading={!selectedWalletOptions && !walletOptions}
              toWalletOptions={walletOptions ?? []}
              toOptionsLoading={!walletOptions}
              setOperation={setOperation(op, setOperations)}
            />
            {/* Apply all */}
            <GridItem>
              <ApplyAllButton
                operation={op}
                operations={operations}
                setOperations={setOperations}
              />
            </GridItem>
            {/* Delete */}
            <DeleteButtonGridItem
              operation={op}
              operations={operations}
              setOperations={setOperations}
            />
          </Fragment>
        ))}
      </Fragment>
    </Grid>
  );
}

export function WalletOperation({
  operation,
  setOperation,
  fromWalletOptions,
  fromOptionsLoading,
  toWalletOptions,
  toOptionsLoading,
}: {
  operation: ChangeWalletOperationEntry;
  setOperation: React.Dispatch<
    React.SetStateAction<ChangeWalletOperationEntry>
  >;
  fromWalletOptions: SelectOptionType[];
  fromOptionsLoading: boolean;
  toWalletOptions: SelectOptionType[];
  toOptionsLoading: boolean;
}): JSX.Element {
  return (
    <>
      <GridItem w="100%">
        <SingleThumbnailSelectBox
          w="100%"
          placeholder="Select Data Source"
          searchEnable
          selectedOption={operation.from ?? ""}
          onChangeSelection={(selection) => {
            setOperation((operation) => ({
              ...operation,
              from: selection,
            }));
          }}
          options={fromWalletOptions}
          maxOptionsInView={4}
          allowDeSelected={false}
          selectBtnProps={{
            minWidth: "180px",
            borderColor: operation.from ? borderColor : warningBorderColor,
            width: "100%",
          }}
          isLoading={fromOptionsLoading}
          autoSelectOnly
        />
      </GridItem>
      <ArrowGridItem>
        <Text>
          <Icon as={ArrowForwardIcon} />
        </Text>
      </ArrowGridItem>
      <GridItem w="100%">
        <SingleThumbnailSelectBox
          w="100%"
          placeholder="Select Data Source"
          searchEnable
          selectedOption={operation.to ?? ""}
          onChangeSelection={(selection) => {
            setOperation((operation) => ({
              ...operation,
              to: selection,
            }));
          }}
          options={toWalletOptions
            // don't show the same value
            .filter(({ value }) => value != operation.from)}
          maxOptionsInView={4}
          allowDeSelected={false}
          selectBtnProps={{
            minWidth: "180px",
            borderColor: operation.to ? borderColor : warningBorderColor,
            width: "100%",
          }}
          isLoading={toOptionsLoading}
        />
      </GridItem>
    </>
  );
}

export function validateWalletOperation(op: ChangeWalletOperationEntry) {
  return op.from && op.to;
}
