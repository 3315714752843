import { SCREEN_SIZE } from "../../constants/common";

export const breakpoints = {
  base: "0px",
  "2xs": SCREEN_SIZE["2XS"], // small mobile
  xs: SCREEN_SIZE.XS, // mobile
  sm: SCREEN_SIZE.SM, // small tablet
  md: SCREEN_SIZE.MD, // tablet
  lg: SCREEN_SIZE.LG, // pc
  xl: SCREEN_SIZE.XL, // large pc
  "2xl": SCREEN_SIZE.XXL,
};
