import { Image } from "@chakra-ui/react";
import React, { useContext } from "react";

import { ModalContext } from "../../../contexts/ModalContext";
import {
  IconExtractorType,
  iconExtractor,
} from "../../../helper/iconExtractor";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { ModalContainer } from "../../atoms/Containers";
import { ModalHeading } from "../../atoms/Headings";
import { ModalText } from "../../atoms/Texts";

interface ActionBoxProps {
  icon: IconExtractorType;
  heading: string;
  contents?: string[];
  topBtnContent: string;
  bottomBtnContent: string;
  topBtnOnClick: () => void;
  bottomBtnOnClick: () => void;
  hideBottomButton?: boolean;
}

export const ActionBox = ({
  icon,
  heading,
  contents,
  topBtnContent,
  bottomBtnContent,
  topBtnOnClick,
  bottomBtnOnClick,
  hideBottomButton,
}: ActionBoxProps) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalContainer>
      <Image src={iconExtractor(icon)} alt={icon} w="60px" />
      <ModalHeading>{heading}</ModalHeading>
      {contents &&
        contents.map((content) => (
          <ModalText key={content} mb="20px">
            {content}
          </ModalText>
        ))}
      <ButtonVariant
        content={topBtnContent}
        outlineType="solid"
        color="red"
        spam="spam"
        onClick={() => {
          closeModal();
          topBtnOnClick();
        }}
        mb="10px"
      />
      {!hideBottomButton && (
        <ButtonVariant
          content={bottomBtnContent}
          outlineType="outlineGray"
          spam="spam"
          onClick={() => {
            closeModal();
            bottomBtnOnClick();
          }}
        />
      )}
    </ModalContainer>
  );
};
