import { useMutation } from "react-query";
import { deleteAccount } from "../api/account/deleteAccount";
import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";

export const useMutationDeleteAccount = (accountId: string) =>
  useMutation(() => deleteAccount(accountId), {
    mutationKey: ["accounts", accountId],
    onSettled: () => {
      queryClient.invalidateQueries(["accounts", accountId]);
      queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
    },
  });
