import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import { CUSTOM_NAME_MAX_LENGTH } from "@syla/shared/types/requests/AddWalletRequest";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { StyledInput } from "../../molecules/forms/FormComponents";

export const DataSourceNameInput = ({
  isRequired,
  isDisabled,
  registerProps,
  error,
}: {
  isRequired?: boolean;
  isDisabled?: boolean;
  registerProps: UseFormRegisterReturn;
  error: string | undefined;
}) => {
  return (
    <FormControl
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={error != null}
    >
      <FormLabel>Name</FormLabel>
      <StyledInput
        placeholder="Enter data source name here"
        {...registerProps}
        maxLength={CUSTOM_NAME_MAX_LENGTH}
      />
      {error && <FormErrorMessage mb="15px">{error}</FormErrorMessage>}
    </FormControl>
  );
};
