import { Flex, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

import type { Decimal } from "@syla/shared/decimal";
import React from "react";
import { useValueHoldings } from "../../../../../store/useValueHoldings";
import { AssetHolding } from "../../../../../types/wallet/wallet";
import { StdVStack } from "../../../../atoms/Containers";
import { sortHoldings } from "../../sortHoldings";
import { HoldingTableRow } from "./HoldingTableRow";
import { rightAlignContent } from "./styles";

export const HoldingTable = ({
  holdings,
  totalHoldingValue,
}: {
  holdings: AssetHolding[];
  totalHoldingValue: Decimal | undefined;
}): JSX.Element => {
  const { isLoading: marketValueLoading } = useValueHoldings();

  const holdingsSorted = sortHoldings(holdings);

  return (
    <Flex direction="column" flexShrink={0}>
      <Flex direction="row">
        <THeadingCell borderRadius="10px 0 0 0">
          <THeadingText>Asset</THeadingText>
        </THeadingCell>
        <THeadingCell>
          <THeadingText>Balance</THeadingText>
        </THeadingCell>
        <TRightAlignHeadingCell>
          <THeadingText>Allocation</THeadingText>
        </TRightAlignHeadingCell>
        <TRightAlignHeadingCell>
          <THeadingText>Holding</THeadingText>
        </TRightAlignHeadingCell>
        <TooltipBlank justifyContent="flex-end" borderRadius="0 10px 0 0" />
      </Flex>
      <Flex direction="column" overflowY="auto" h="200px">
        {holdingsSorted.length > 0 &&
          holdingsSorted.map((holding, index) => (
            <HoldingTableRow
              key={holding.asset._id}
              holding={holding}
              totalHoldingValue={totalHoldingValue}
              lastRow={index + 1 === holdingsSorted.length}
              marketValueLoading={marketValueLoading}
            />
          ))}
        {holdingsSorted.length == 0 && (
          <StdVStack alignItems="center">
            <Text mt="0.5rem">No assets currently held</Text>
          </StdVStack>
        )}
      </Flex>
    </Flex>
  );
};

const THeadingCell = styled(Flex)`
  background-color: #fff;
  width: 24%;
  padding: 8px 20px;
  margin-bottom: 2px;
  align-items: center;
  min-width: 115px;
`;

const TooltipBlank = styled(Flex)`
  background-color: #fff;
  width: 4%;
  padding: 8px 20px;
  margin-bottom: 2px;
  align-items: center;
  min-width: 20px;
`;

const THeadingText = styled(Text)`
  color: #4d4d4d;
  font-weight: 700;
  font-size: 0.75rem;
`;

const TRightAlignHeadingCell = styled(THeadingCell)`
  ${rightAlignContent}
`;
