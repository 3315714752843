import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { AccountRouteParams } from "../helper/accountRouteParams";
import { useSetTitle } from "../hooks/useSetTitle";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { checkStatusAndDoPendingWork } from "../store/pendingWork";
import { resetTransactionStore } from "../store/transactionsStore";
import { useQueryGetAccounts } from "../store/useQueryGetAccounts";
import { useSwitchAccount } from "./useSwitchAccount";

/**
 * Manage route related changes
 *
 * @constructor
 */
export function AccountRouteHelper() {
  useEffect(() => {
    console.debug("AccountRouteHelper mount");
  }, []);

  const setTitle = useSetTitle();
  const switchAccount = useSwitchAccount();

  const { accountId: routeAccountId } = useParams<AccountRouteParams>();
  const { shortName, accountId, setAccountProps } = useCurrentAccountStore(
    useShallow((state) => ({
      accountId: state.accountId,
      setAccountProps: state.setAccountProps,
      setAccountId: state.setAccountId,
      shortName: state.shortName,
    }))
  );

  const { data: accountsResponse } = useQueryGetAccounts();

  // update account props when accountId or accountsResponse changes
  useEffect(() => {
    if (accountsResponse && accountsResponse.accounts[accountId]) {
      setAccountProps(accountsResponse.accounts[accountId]);
    }
  }, [accountId, accountsResponse, setAccountProps]);

  // switch account if we don't have access
  useEffect(() => {
    if (
      accountsResponse &&
      routeAccountId &&
      !accountsResponse.accounts[routeAccountId]
    ) {
      const newAccount = Object.values(accountsResponse.accounts).at(0);
      if (!newAccount) console.error("No accounts for user!");
      else {
        switchAccount(newAccount);
      }
    }
  }, [accountsResponse, routeAccountId, switchAccount]);

  // reset caches when accountId changes
  useEffect(() => {
    resetTransactionStore({ initAccountId: accountId });
    checkStatusAndDoPendingWork(); // check for pending work
  }, [accountId]);

  // set title with account info
  useEffect(() => {
    setTitle(shortName);
  }, [setTitle, shortName]);

  // clear title on dismount
  useEffect(() => {
    return () => setTitle("");
  }, [setTitle]);

  return <></>;
}
