import {
  AccountSubType,
  AccountType,
} from "@syla/shared/types/models/AccountBase";
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";

interface ISignUpContext {
  tabIndex: number;
  goToNextTab: () => void;
  goToPreviousTab: () => void;
  accountType: AccountType | undefined;
  setAccountType: Dispatch<SetStateAction<AccountType | undefined>>;
  accountSubType: AccountSubType | undefined;
  setAccountSubType: Dispatch<SetStateAction<AccountSubType | undefined>>;
}

const InitialSignUpContext = {
  tabIndex: 0,
  goToNextTab: () => {},
  goToPreviousTab: () => {},
  accountType: undefined,
  setAccountType: () => {},
  accountSubType: undefined,
  setAccountSubType: () => {},
};

export const SignUpContext =
  createContext<ISignUpContext>(InitialSignUpContext);

export const SignUpContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [accountType, setAccountType] = useState<AccountType | undefined>();
  const [accountSubType, setAccountSubType] = useState<
    AccountSubType | undefined
  >();

  const goToNextTab = () => setTabIndex((oldTab) => oldTab + 1);
  const goToPreviousTab = () => setTabIndex((oldTab) => oldTab - 1);

  return (
    <SignUpContext.Provider
      value={{
        tabIndex,
        goToNextTab,
        goToPreviousTab,
        accountType,
        setAccountType,
        accountSubType,
        setAccountSubType,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
