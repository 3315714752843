"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.returnParcelMethodForFinancialYear = exports.returnParcelMethodForDate = exports.formatFinancialYear = exports.getCurrentFinancialYear = exports.adjustFinancialYear = exports.getLastFinancialYear = exports.getFinancialYearForDate = exports.returnFinancialYearStartDate = exports.returnFinancialEndYear = exports.isCurrentFinancialYear = exports.financialYearToDateRange = exports.financialYearRegex = void 0;
const date_fns_1 = require("date-fns");
const lodash_1 = require("lodash");
const constants_1 = require("../types/helpers/constants");
/**
 * Regex for a financial year string of the format `FY20XX/XX`
 */
exports.financialYearRegex = /^FY20[0-9][0-9]\/[0-9][0-9]$/;
/**
 * Converts a financial year string to an array of date objects, the first is the start of the date range, the second is the end of the date range
 *
 * @author Aidan Kinzett
 * @param financialYear String of the format `FY20XX/XX`
 */
const financialYearToDateRange = (financialYear) => {
    // check that the financial year is valid
    if (!exports.financialYearRegex.test(financialYear)) {
        throw Error("Invalid financial year, expecting string of format `FY20XX/XX`");
    }
    // remove the FY from the front of the string and split the string into an array of years
    const [startYear, endYear] = financialYear.replace("FY", "").split("/");
    const startDate = new Date(`${startYear}/07/01`); // start of july
    const endDate = new Date(`20${endYear}/07/01`); // start of july the following year, since endDate is exclusive
    return [startDate, endDate];
};
exports.financialYearToDateRange = financialYearToDateRange;
// to check `FY2021/22` is current financial year or not
const isCurrentFinancialYear = (financialYear) => {
    const [startDate, endDate] = (0, exports.financialYearToDateRange)(financialYear);
    const today = (0, date_fns_1.startOfDay)(new Date());
    return today >= startDate && today <= endDate;
};
exports.isCurrentFinancialYear = isCurrentFinancialYear;
/**
 * This function returns the financial ending year for a given date
 * @param date The date to return the year the financial year ends on
 * @returns Returns the financial end year that the date lands on
 */
function returnFinancialEndYear(date) {
    // If the date is before July 1st, return the current year as the financial year
    if (date.getMonth() < 6) {
        return date.getFullYear();
    }
    // Otherwise add 1 to the financial year, because the financial year ends on the following year
    return date.getFullYear() + 1;
}
exports.returnFinancialEndYear = returnFinancialEndYear;
/**
 * This function calculates the start date of the financial year for the year that the provided date lands on.
 *
 * @param date The date to retrieve the financial year start date for
 * @returns Returns the financial year start date for a given date
 */
const returnFinancialYearStartDate = (date) => {
    const financialYearDate = new Date(date.getTime());
    // If the date is before July, set its year to be the year before
    if (financialYearDate.getMonth() < 6) {
        financialYearDate.setFullYear(financialYearDate.getFullYear() - 1);
    }
    // Set these values so the returned date is on the 1st of July, 00:00:00
    financialYearDate.setMonth(6);
    financialYearDate.setDate(1);
    financialYearDate.setHours(0, 0, 0, 0);
    return financialYearDate;
};
exports.returnFinancialYearStartDate = returnFinancialYearStartDate;
const getFinancialYearForDate = (date) => (0, exports.formatFinancialYear)((0, exports.returnFinancialYearStartDate)(date).getFullYear());
exports.getFinancialYearForDate = getFinancialYearForDate;
const getLastFinancialYear = () => (0, exports.getFinancialYearForDate)((0, date_fns_1.addYears)(new Date(), -1));
exports.getLastFinancialYear = getLastFinancialYear;
/**
 * Return the financial year value +- `inc` years from the given year
 *
 * @param financialYear
 * @param inc
 */
const adjustFinancialYear = ({ financialYear, inc, }) => {
    const [startDate] = (0, exports.financialYearToDateRange)(financialYear);
    return (0, exports.getFinancialYearForDate)((0, date_fns_1.addYears)(startDate, inc));
};
exports.adjustFinancialYear = adjustFinancialYear;
const getCurrentFinancialYear = () => (0, exports.getFinancialYearForDate)(new Date());
exports.getCurrentFinancialYear = getCurrentFinancialYear;
const formatFinancialYear = (financialYear) => 
// e.g. FY2004/05
`FY${financialYear}/${(0, lodash_1.padStart)(((financialYear + 1) % 100).toString(), 2, "0")}`;
exports.formatFinancialYear = formatFinancialYear;
/**
 * This function simply finds a parcel method strategy for a given financial year, if none is found it returns FIFO as the default
 *
 * @param date The financial year to find the parcel strategy setting for
 * @param parcelMethods All the parcel strategy settings for all years for the user
 * @param userDefault
 * @returns Returns the found parcel strategy
 */
const returnParcelMethodForDate = ({ date = new Date(), parcelMethods, defaultParcelMethod, } = {}) => {
    var _a, _b;
    const yearSettings = parcelMethods === null || parcelMethods === void 0 ? void 0 : parcelMethods.find((method) => method.endYear === returnFinancialEndYear(date).toString());
    return (_b = (_a = yearSettings === null || yearSettings === void 0 ? void 0 : yearSettings.method) !== null && _a !== void 0 ? _a : defaultParcelMethod) !== null && _b !== void 0 ? _b : constants_1.DEFAULT_PARCEL_METHOD;
};
exports.returnParcelMethodForDate = returnParcelMethodForDate;
const returnParcelMethodForFinancialYear = ({ financialYear, parcelMethods, defaultParcelMethod, }) => {
    return (0, exports.returnParcelMethodForDate)({
        date: financialYear.startDate,
        parcelMethods,
        defaultParcelMethod,
    });
};
exports.returnParcelMethodForFinancialYear = returnParcelMethodForFinancialYear;
