import { useQuery } from "react-query";
import { modelTaxYearResult } from "../api/user/modelTaxYearResult";
import { useCurrentAccountStore } from "./currentAccountStore";
import { getAccountTransactionsCacheKey } from "./transactionsStore";

export const useQueryModelTaxResult = ({
  financialYearValue,
  enabled = true,
}: {
  financialYearValue: string | undefined;
  enabled?: boolean;
}) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  return useQuery(
    [
      ...getAccountTransactionsCacheKey(accountId),
      financialYearValue,
      "modelTaxResult",
    ],
    () =>
      modelTaxYearResult({
        accountId,
        financialYearValue: financialYearValue!,
      }),
    { enabled: !!financialYearValue && enabled }
  );
};
