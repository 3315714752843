import { Text, Flex, Tag, FlexProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useContext } from "react";
import ReactMarkdown from "react-markdown";
import { SCREEN_SIZE } from "../../../constants/common";
import { UserContext } from "../../../contexts/UserContext";
import { Device } from "../../../types/user/device";

interface InstructionBoxProps extends FlexProps {
  instructions: string;
  isFloat?: boolean;
}

export const InstructionBox = ({
  instructions,
  isFloat,
}: InstructionBoxProps) => {
  const { userDevice } = useContext(UserContext);
  return (
    <Flex
      direction="column"
      css={
        userDevice === Device.Desktop
          ? {
              background: "white.0",
              borderRadius: "5px",
              height: "90%",
              width: "100%",
              ...(isFloat
                ? {
                    position: "absolute",
                    right: "500px",
                    top: "30px",
                    maxWidth: "375px",
                  }
                : { paddingLeft: "10px" }),
            }
          : {}
      }
    >
      <InstructionsMarkdownRenderer>
        {instructions}
      </InstructionsMarkdownRenderer>
    </Flex>
  );
};

const InstructionHeader = styled(Text)`
  "@media (max-width: ${SCREEN_SIZE.LG})": {
    font-size: 1rem;
  },
  font-size: 1.3rem;
`;

const InstructionList = styled(Flex)`
  font-size: 0.9rem;

  /* make the links red and bold */
  a {
    color: #ff3600;
    font-weight: bold;
  }
`;

const ListComponent = ({ ordered, children, depth, ...props }: any) => {
  const { userDevice } = useContext(UserContext);

  return depth == 0 ? (
    <InstructionList
      direction="column"
      p={userDevice == Device.Desktop ? "20px 30px" : "20px 10px"}
      left={0}
      right={0}
      bottom={0}
      top={0}
      overflowY="auto"
    >
      {ordered ? (
        <ol {...props}>{children}</ol>
      ) : (
        <ul {...props}>{children}</ul>
      )}
    </InstructionList>
  ) : ordered ? (
    <ol style={{ paddingTop: "20px" }} {...props}>
      {children}
    </ol>
  ) : (
    <ul style={{ paddingTop: "20px" }} {...props}>
      {children}
    </ul>
  );
};
const InstructionsMarkdownRenderer = ({ children }: { children: string }) => {
  const { userDevice } = useContext(UserContext);

  return (
    <ReactMarkdown
      linkTarget="_blank"
      components={{
        h2: ({ children, ...props }) => (
          <InstructionHeader
            {...props}
            fontWeight="bold"
            color="black.600"
            p={userDevice == Device.Desktop ? "30px" : "20px 0px"}
            borderWidth="0 0 2px 0"
            borderColor="red.75"
          >
            {children}
          </InstructionHeader>
        ),
        ol: (props) => ListComponent({ ...props, ordered: false }),
        ul: (props) => ListComponent({ ...props, ordered: true }),
        li: ({ ordered, index, children, ...props }) => (
          <Flex key={index} pb="20px">
            <Tag
              mr="20px"
              bgColor={ordered ? "red.100" : "red.400"}
              color="red.500"
              borderRadius="full"
              h="20px"
              size={ordered ? "md" : "sm"}
            >
              {ordered ? index + 1 : ""}
            </Tag>
            <span key={index} {...props}>
              {children}
            </span>
          </Flex>
        ),
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
