import { isFeeLedger } from "@syla/shared/types/models/LedgerBase";
import { uniq } from "lodash";
import { Ledger } from "../../types/ledger";
import { Transaction } from "../../types/transaction";

export const splitTransactionLedgers = <TTransaction extends Transaction>(
  transactions: TTransaction[]
): {
  inLedgers: TTransaction["ledgers"];
  outLedgers: TTransaction["ledgers"];
  allLedgers: TTransaction["ledgers"];
  feeLedgers: TTransaction["ledgers"];
  allInLedgers: TTransaction["ledgers"];
  allOutLedgers: TTransaction["ledgers"];
  allInAssets: TTransaction["ledgers"][0]["asset"][];
  allOutAssets: TTransaction["ledgers"][0]["asset"][];
  inSources: string[];
  outSources: string[];
} => {
  const getSourceName = (transaction: TTransaction) =>
    transaction.wallet.customName || transaction.dataSource.name;

  const allInLedgers = transactions.flatMap((t) =>
    t.ledgers.filter(isInLedger)
  );
  const allOutLedgers = transactions.flatMap((t) =>
    t.ledgers.filter((l) => !isInLedger(l))
  );

  return {
    allLedgers: transactions.flatMap((t) => t.ledgers),
    inLedgers: transactions.flatMap((t) =>
      t.ledgers.filter((l) => isMainInLedger(l))
    ),
    outLedgers: transactions.flatMap((t) =>
      t.ledgers.filter((l) => isMainOutLedger(l))
    ),
    feeLedgers: transactions.flatMap((t) =>
      t.ledgers.filter((l) => isFeeLedger(l))
    ),
    inSources: uniq(
      transactions.filter((t) => t.ledgers.find(isInLedger)).map(getSourceName)
    ),
    outSources: uniq(
      transactions
        .filter((t) => t.ledgers.find((l) => !isInLedger(l)))
        .map(getSourceName)
    ),
    allInLedgers,
    allOutLedgers,
    allInAssets: allInLedgers.map((l) => l.asset),
    allOutAssets: allOutLedgers.map((l) => l.asset),
  };
};

function isInLedger(ledger: Ledger) {
  return ledger.amount.gt("0");
}

function isMainInLedger(ledger: Ledger): boolean {
  return !isFeeLedger(ledger) && isInLedger(ledger);
}

function isMainOutLedger(ledger: Ledger): boolean {
  return !isFeeLedger(ledger) && !isInLedger(ledger);
}
