/**
 * Input is a multipart component.
 * To set the theme correctly, you must override each variant individually.
 * You can't use the baseStyle, as it will just get overwritten by the
 * more specific styling in each variant.
 */

export const inputTheme = {
  variants: {
    outline: {
      field: {
        _hover: {
          borderColor: "gray.400",
        },
        _focus: {
          borderColor: "gray.700",
          boxShadow: "0 0 0 1px rgba(209, 209, 214, 0.6)", // gray.300
        },
      },
    },
  },
};
