import { InviteAccountMemberRequestBody } from "@syla/shared/types/requests/InviteAccountMemberRequest";
import axios from "axios";

export type InviteAccountMemberProps = InviteAccountMemberRequestBody;

export const inviteAccountMember = async (
  accountId: string,
  props: InviteAccountMemberProps
): Promise<void> => {
  await axios.post(`/accounts/${accountId}/access/invite`, props);
};
