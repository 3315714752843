import { Flex, Spinner } from "@chakra-ui/react";
import { DEFAULT_PARCEL_METHOD } from "@syla/shared/types/helpers/constants";
import { permissionFor } from "@syla/shared/types/helpers/permissions";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import { ParcelMethod } from "@syla/shared/types/models/AccountBase";
import React, { useContext, useMemo, useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useForm, FormProvider } from "react-hook-form";
import { PageContainer } from "../../components/atoms/Containers";
import { ParcelMethodForm } from "../../components/molecules/forms/settingForms/ParcelMethodForm";
import { AccountSubtypeTypeEditable } from "../../components/organisms/accounts/AccountSubtypeTypeEditable";
import { AccountTypeEditable } from "../../components/organisms/accounts/AccountTypeEditable";
import {
  SettingRow,
  SettingsButton,
} from "../../components/organisms/setting/SettingRow";
import { ModalContext } from "../../contexts/ModalContext";
import { hasAccountPermission } from "../../helper/permissions";
import { useGetAccountFinancialYearsData } from "../../store/actions/userFinancialYear";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useQueryGetAccount } from "../../store/useQueryGetAccount";
import { Account } from "../../types/Account";
import { SectionContainer, SectionHeading } from "./AccountSettingsCommon";

export function AccountTaxSettings() {
  const { openModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  const canChangeTaxSettings = hasAccountPermission(
    account,
    AccountPermission.ChangeTaxSettings
  );

  const {
    data: userFinancialYearsData,
    isLoading: isLoadingFinancialYearsData,
  } = useGetAccountFinancialYearsData();

  const defaultParcelMethod =
    account?.taxSettings.defaultParcelMethod ?? DEFAULT_PARCEL_METHOD;

  const allUsersParcelMethods: ParcelMethod[] = useMemo(
    () =>
      (userFinancialYearsData ?? []).map((financialYear): ParcelMethod => {
        const parcelMethod = account?.taxSettings.parcelMethods.find(
          (method) =>
            method.startYear ===
            financialYear.startDate.getUTCFullYear().toString()
        );

        return {
          startYear: financialYear.startDate.getUTCFullYear().toString(),
          endYear: financialYear.endDate.getUTCFullYear().toString(),
          method: parcelMethod?.method ?? defaultParcelMethod,
        };
      }),
    [
      defaultParcelMethod,
      account?.taxSettings.parcelMethods,
      userFinancialYearsData,
    ]
  );

  const parcelPermission = useMemo(
    () =>
      permissionFor({
        action: "feat-tax-ltfo",
        subscriptions: account?.subscriptions ?? [],
        transactionCount: 0,
        account: account,
      }).hasPermission,
    [account]
  );

  const formProps = useForm<Partial<Account>>({
    defaultValues: account,
  });
  const { reset } = formProps;

  useEffect(() => {
    reset(account);
  }, [account, reset]);

  // TODO: implement error toast for fetching user details

  if (accountIsLoading || isLoadingFinancialYearsData || !account)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center" my="40px">
          <Spinner />
        </Flex>
      </PageContainer>
    );

  return (
    <SectionContainer direction="column" minW="300px">
      <SectionHeading>Tax Settings</SectionHeading>
      <FormProvider {...formProps}>
        <SettingRow
          label="Account Type"
          component={<AccountTypeEditable account={account} />}
        />
        <SettingRow
          label="Activity Type"
          component={<AccountSubtypeTypeEditable account={account} />}
        />
      </FormProvider>
      <SettingRow
        label="Home Country"
        currentValue={account.taxSettings.homeCountry}
        action={() => undefined}
      />
      <SettingRow
        label="Base Currency"
        currentValue={account.taxSettings.baseCurrency.name}
        action={() => undefined}
      />
      <SettingRow
        label="Tax Year"
        currentValue={account.taxSettings.taxYear}
        action={() => undefined}
      />
      {/*
            <SettingRow
              label="GST Registered"
              currentValue={
                account.taxSettings.gstRegistered ? "Yes" : "No"
              }
              action={async (event: ChangeEvent<HTMLInputElement>) => {
                try {
                  await updateUser({
                    taxSettings: {
                      homeCountry: account.taxSettings.homeCountry,
                      baseCurrency: account.taxSettings.baseCurrency,
                      taxYear: account.taxSettings.taxYear,
                      gstRegistered: event.currentTarget.checked,
                      parcelMethods: account.taxSettings.parcelMethods,
                      timeZone: account.taxSettings.timeZone,
                    },
                  });
                  queryClient.invalidateQueries("user-details");
                } catch (error) {
                  // TODO: implement error toast here
                  console.log(error);
                }
              }}
              type="toggle"
            />
            */}
      <SettingRow
        label="Parcel Method"
        component={
          <SettingsButton
            isDisabled={!(canChangeTaxSettings && parcelPermission)}
            onClick={() =>
              openModal({
                size: "lg",
                type: "component",
                heading: "Parcel Method",
                component: (
                  <ParcelMethodForm
                    currentTaxSettings={{
                      ...account.taxSettings,
                      parcelMethods: allUsersParcelMethods,
                    }}
                  />
                ),
              })
            }
          >
            Edit
          </SettingsButton>
        }
        tip="Apply different tax strategies for matching disposals to acquisitions.
        Use our Lowest-Tax First-Out (LTFO) algorithm for the best tax outcome.
        Available on Tax Saving and Private Wealth plans."
      />
      {/*
            <SettingRow
              label="Time Zone"
              currentValue={account.taxSettings.timeZone}
              action={() =>
                openModal({
                  type: "component",
                  heading: "Time Zone",
                  component: (
                    <TimeZoneForm
                      currentTaxSettings={account.taxSettings}
                    />
                  ),
                })
              }
              type="edit"
              tip="Your selected timezone will be used for all calculations and reports"
            />
            */}
    </SectionContainer>
  );
}
