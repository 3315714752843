import { parseOptional, parseDate } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import {
  GetAccountsResponseBody,
  AccountAccessDetails,
} from "@syla/shared/types/responses/GetAccountsResponse";
import axios from "axios";
import { parseSubscription } from "../plans/parseSubscription";

export const getAccounts = async (): Promise<
  GetAccountsResponseBody<string>
> => {
  console.debug("getAccounts");
  const { data }: { data: TransportType<GetAccountsResponseBody<string>> } =
    await axios.get(`/accounts`);

  const responseData: GetAccountsResponseBody<string> = {
    accounts: Object.fromEntries(
      Object.entries(data.accounts).map<[string, AccountAccessDetails<string>]>(
        ([accountId, account]) => [
          accountId,
          {
            ...account,
            subscriptions: account.subscriptions.map(parseSubscription),
            createdAt: parseOptional(account.createdAt, parseDate),
            updatedAt: parseOptional(account.updatedAt, parseDate),
          },
        ]
      )
    ),
  };

  return responseData;
};
