/**
 * Contract a string longer than maxLength by eliding the middle.
 * e.g. "abc..hij"
 *
 * @param text
 * @param maxLength Maximum number of characters
 */
export const contractString = (text: string, maxLength: number) =>
  text.length <= maxLength
    ? text
    : `${text.slice(0, maxLength / 2 - 1)}..${text.slice(
        -(maxLength / 2 - 1)
      )}`;
