import { useState, useEffect, Dispatch, SetStateAction } from "react";

/**
 * Store state in browser local storage.
 *
 * If local storage is not enabled or supported,
 * data will only be stored ephemerally in memory instead.
 *
 * @param key
 * @param defaultValue
 */
export const useLocalStorage = <T>(
  key: string,
  defaultValue: T
): [T | undefined, Dispatch<SetStateAction<T | undefined>>] => {
  let savedValue: undefined | T;
  try {
    savedValue = getStorageValue<T>(key, defaultValue);
  } catch (error) {
    if (error instanceof LocalStorageError) {
      // no saved value
    } else {
      throw error;
    }
  }

  const [value, setValue] = useState<T | undefined>(savedValue);

  useEffect(() => {
    try {
      setStorageValue(key, value);
    } catch (error) {
      if (error instanceof LocalStorageError) {
        // save nothing
      } else {
        throw error;
      }
    }
  }, [key, value]);

  return [value, setValue];
};

function getStorageValue<T>(key: string, defaultValue: T) {
  // getting stored value
  let value: string | null;
  try {
    value = localStorage.getItem(key);
  } catch (error) {
    // if localStorage not enabled/supported
    throw new LocalStorageError();
  }

  if (value === "undefined") return undefined;
  return value ? (JSON.parse(value) as T) : defaultValue;
}

function setStorageValue<T>(key: string, value: T) {
  const jsonValue = JSON.stringify(value);
  try {
    localStorage.setItem(key, jsonValue);
  } catch (error) {
    // if localStorage not enabled/supported
    throw new LocalStorageError();
  }
}

// thrown if localStorage doesn't work on this browser
class LocalStorageError extends Error {
  constructor() {
    super();
    this.name = "LocalStorageError";
  }
}
