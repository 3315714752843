import React, { useContext, useCallback } from "react";
import { ModalContext } from "../../../contexts/ModalContext";
import { CreateAccountForm } from "./CreateAccountForm";

export const useCreateAccountForm = () => {
  // add new accounts modal
  const { openModal } = useContext(ModalContext);
  const openCreateAccountForm = useCallback(() => {
    openModal({
      type: "component",
      heading: "Create new account",
      component: <CreateAccountForm />,
    });
  }, [openModal]);
  return openCreateAccountForm;
};
