import axios from "axios";

export const doPendingWork = async (accountId: string): Promise<void> => {
  try {
    await axios.post(`/accounts/${accountId}/status/doPendingWork`);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status == 409)
      throw new AlreadyInProgressError();

    throw error;
  }
};

export class AlreadyInProgressError extends Error {}
