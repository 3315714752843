import { DeleteGroupsRequestBody } from "@syla/shared/types/requests/DeleteGroupsRequest";
import axios from "axios";
import { OrderFilterSetting } from "../../types/order/orderQuery";
import { filterToFilterParams } from "./getGroups";

export const deleteOrders = async ({
  accountId,
  orders,
  filter,
}: {
  accountId: string;
  orders?: string[];
  filter?: OrderFilterSetting;
}): Promise<number> => {
  const data: DeleteGroupsRequestBody<string> = {
    ids: orders,
    filter: filter ? filterToFilterParams(filter) : undefined,
  };
  return axios.delete(`/accounts/${accountId}/orders`, {
    data,
  });
};
