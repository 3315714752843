"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSourceIdType = exports.MetadataKey = exports.includeNeedsReviewItem = exports.NeedsReviewType = exports.TransactionType = exports.MAX_TRANSACTION_DESCRIPTION_LENGTH = void 0;
exports.MAX_TRANSACTION_DESCRIPTION_LENGTH = 1024;
var TransactionType;
(function (TransactionType) {
    TransactionType["Trade"] = "Trade";
    TransactionType["Mint"] = "Mint";
    TransactionType["Receive"] = "Receive";
    TransactionType["Send"] = "Send";
    TransactionType["Rebase"] = "Rebase";
    TransactionType["Wrap"] = "Wrap";
    TransactionType["AddLiquidity"] = "Add Liquidity";
    TransactionType["RemoveLiquidity"] = "Remove Liquidity";
    TransactionType["Transaction"] = "Transaction";
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
var NeedsReviewType;
(function (NeedsReviewType) {
    NeedsReviewType["MissingAcquisitionParcel"] = "MissingAcquisitionParcel";
    NeedsReviewType["MissingData"] = "MissingData";
    NeedsReviewType["MissingMarketValue"] = "MissingMarketValue";
    NeedsReviewType["MissingAssetCode"] = "MissingAssetCode";
    NeedsReviewType["UnclassifiedLedgers"] = "UnclassifiedLedgers";
    NeedsReviewType["WithdrawalInclusiveOfFee"] = "WithdrawalInclusiveOfFee";
    NeedsReviewType["UnmatchedTransfer"] = "UnmatchedTransfer";
    NeedsReviewType["IncompleteRebase"] = "IncompleteRebase";
})(NeedsReviewType = exports.NeedsReviewType || (exports.NeedsReviewType = {}));
/** Determine whether a given item should be actioned or not */
function includeNeedsReviewItem({ type, }) {
    // exclude these for now since user cannot action them
    return ![
        NeedsReviewType.MissingData,
        NeedsReviewType.WithdrawalInclusiveOfFee,
    ].includes(type);
}
exports.includeNeedsReviewItem = includeNeedsReviewItem;
var MetadataKey;
(function (MetadataKey) {
    MetadataKey["OrderSide"] = "Order Side";
    MetadataKey["BankAccount"] = "Bank Account";
    MetadataKey["Contract"] = "Contract";
    MetadataKey["ContractAddress"] = "Contract Address";
    MetadataKey["ContractMethod"] = "Contract Method";
    MetadataKey["ContractMethodSignature"] = "Contract Method Signature";
    MetadataKey["ContractMethodId"] = "Contract Method Id";
    MetadataKey["LinkedDataSource"] = "Linked DataSource";
    // Temporary data storage, used for deriving pnl from
    // derivatives contracts. Should not be stored in DB.
    MetadataKey["PositionAmount"] = "Position Amount";
    MetadataKey["PositionValue"] = "Position Value";
    MetadataKey["PositionContract"] = "Position Contract";
    MetadataKey["PositionSettledIn"] = "Position Settled In";
})(MetadataKey = exports.MetadataKey || (exports.MetadataKey = {}));
var TransactionSourceIdType;
(function (TransactionSourceIdType) {
    TransactionSourceIdType["EventId"] = "EventId";
    TransactionSourceIdType["OrderId"] = "OrderId";
    TransactionSourceIdType["TransactionHash"] = "TransactionHash";
    TransactionSourceIdType["BlockHash"] = "BlockHash";
    TransactionSourceIdType["TransferId"] = "TransferId";
    TransactionSourceIdType["TradeId"] = "TradeId";
    TransactionSourceIdType["SentFromAddress"] = "SentFromAddress";
    TransactionSourceIdType["SentToAddress"] = "SentToAddress";
    TransactionSourceIdType["ReceivedFromAddress"] = "ReceivedFromAddress";
    TransactionSourceIdType["ReceivedAtAddress"] = "ReceivedAtAddress";
    TransactionSourceIdType["DepositId"] = "DepositId";
    TransactionSourceIdType["WithdrawalId"] = "WithdrawalId";
    TransactionSourceIdType["CorrelationId"] = "CorrelationId";
})(TransactionSourceIdType = exports.TransactionSourceIdType || (exports.TransactionSourceIdType = {}));
