import { GetAccountTransactionTypesResponse } from "@syla/shared/types/responses/GetAccountTransactionTypesResponse";
import axios from "axios";

import { TransportType } from "@syla/shared/types/helpers/TransportType";

export const getAccountTransactionTypes = async (
  accountId: string
): Promise<TransportType<GetAccountTransactionTypesResponse>["types"]> => {
  const {
    data: { types },
  } = await axios.get<TransportType<GetAccountTransactionTypesResponse>>(
    `/accounts/${accountId}/transactions/types`
  );
  return types;
};
