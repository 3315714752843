import { Flex, Text, Spacer, Spinner } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { DataSourceType } from "@syla/shared/types/models/DataSourceBase";
import { getWalletName } from "@syla/shared/types/models/WalletBase";
import axios from "axios";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getDirtyValues } from "../../../../../helper/forms/getDirtyValues";
import {
  importWalletCsv,
  updateWallet,
} from "../../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";

import { EditDataSourceForm } from "../../../../../types/dataSource/dataSource";
import { WalletResponse } from "../../../../../types/wallet/wallet";
import { ButtonVariant } from "../../../../atoms/ButtonVariant";
import { FileImportTab } from "../../../../atoms/FileImportTab";
import { DrawerHeading } from "../../../../atoms/Headings";
import { ImageWithMissingSrc } from "../../../../atoms/ImageWithMissingSrc";
import { ImportError } from "../../../../atoms/ImportError";
import { Form } from "../../../../molecules/forms/FormComponents";
import { DataSourceNameInput } from "../../DataSourceNameInput";
import { getDefaultEditValues } from "../GetDefaultEditValues";

interface EditCustomProps {
  wallet: WalletResponse;
  onClose: () => void;
  fileImport: boolean;
}

export const EditFileDataSource = ({
  wallet,
  onClose: onCloseDrawer,
  fileImport,
}: EditCustomProps): JSX.Element => {
  // state for importing
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState<Error | undefined>();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // get everything from form context
  const {
    handleSubmit,
    formState: { isSubmitting, dirtyFields, errors },
    reset,
    register,
  } = useFormContext<EditDataSourceForm>();

  // submit handler
  const onSubmit = async (data: EditDataSourceForm) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    if (!Object.keys(dirtyValues).length) {
      // nothing to save
      onCloseDrawer();
      return;
    }

    const { storedFiles, customName } = dirtyValues;

    console.debug("editFileDataSource.onSubmit", {
      data,
      dirtyFields,
      dirtyValues,
    });

    setImportError(undefined);

    try {
      // import new csv file if one was passed
      if (storedFiles && storedFiles.length > 0) {
        setIsImporting(true);
        await importWalletCsv(accountId, wallet._id, storedFiles as File[]);
      }

      const updatedWallet = await updateWallet(accountId, wallet._id, {
        customName: dirtyFields.customName ? customName : undefined,
      });

      onCloseDrawer();

      // invalidate wallets query and reset the hook form with the new updated wallet props if the wallet was updated
      reset(getDefaultEditValues({ wallet: updatedWallet }));
    } catch (error: any) {
      if (!axios.isAxiosError(error)) Sentry.captureException(error);
      setImportError(error);
    } finally {
      // set is importing false on success and on error
      setIsImporting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
      <DrawerHeading>
        {fileImport ? "Import Files" : "Edit Data Source"}
      </DrawerHeading>
      {/* --------------- Form --------------- */}
      <Flex direction="column" h="calc(100% - 70px)">
        <Flex justifyContent="center" alignItems="center" my="30px">
          <ImageWithMissingSrc
            src={wallet.dataSource.image}
            alt=""
            mr="10px"
            h="40px"
            borderRadius="20px"
          />
          <Text fontSize="1.1rem" fontWeight="bold">
            {getWalletName({ wallet, dataSource: wallet.dataSource })}
          </Text>
        </Flex>
        <Flex direction="column" p="15px" overflowY="auto">
          <Text
            textAlign="center"
            fontSize="0.875rem"
            color="black.700"
            mb="20px"
            pb="10px"
            borderWidth="0 0 2px 0"
            borderColor="red.500"
          >
            {fileImport ? "File Import" : "Data Source"}
          </Text>
          {fileImport ? (
            fileImportArea(isImporting)
          ) : (
            <DataSourceNameInput
              isDisabled={isSubmitting}
              registerProps={{
                ...register("customName", {
                  required: "Data source name is required",
                }),
              }}
              error={errors.customName?.message}
            />
          )}
        </Flex>
        {/* ---------------------- Import Error ----------------------- */}
        <Spacer />
        <ImportError
          error={importError}
          customFormat={wallet.dataSource.type == DataSourceType.Custom}
        ></ImportError>

        {/* --------------- Sticky Buttons --------------- */}
        <Flex
          direction="column"
          w="100%"
          p="16px 24px"
          position="sticky"
          bgColor="white.0"
          bottom="0px"
        >
          <ButtonVariant
            content={fileImport ? "Import" : "Save"}
            disabled={isSubmitting}
            outlineType="solid"
            color="red"
            spam="spam"
            onClick={() => {}}
            mb="10px"
            type="submit"
          />
          <ButtonVariant
            content="Close"
            disabled={isSubmitting}
            spam="spam"
            outlineType="outlineGray"
            onClick={onCloseDrawer}
          />
        </Flex>
      </Flex>
    </Form>
  );
};

const fileImportArea = (isImporting: boolean) => {
  // show spinner when importing, else show the file drop zone
  if (isImporting) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        h="100%"
        m="10px 25px"
      >
        <Spinner
          color="red.500"
          emptyColor="red.200"
          my="10px"
          thickness="5px"
          size="xl"
        />
      </Flex>
    );
  }
  return <FileImportTab />;
};
