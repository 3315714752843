import {
  DataSourceSortOption,
  DataSourceSortOptionName,
} from "../../types/dataSource/dataSource";

export const getDataSourceSortOptions = () =>
  Object.values(DataSourceSortOption).map((value) => ({
    value,
    label: DataSourceSortOptionName[value],
  }));
