import { useCallback, useContext } from "react";
import { useReportError } from "../../../helpers/useReportError";
import { ModalContext } from "../../../contexts/ModalContext";
import { captureRequestError } from "../../../helper/captureRequestError";
import {
  useMutationUpdateAccountAccess,
  SUBSCRIPTION_UPDATE_DELAY,
} from "../../../store/useMutationUpdateAccountAccess";

export function useRemoveBillingOwnerDialog({ accountId }) {
  const reportError = useReportError();

  // update access
  const { mutateAsync: updateAccountAccess } =
    useMutationUpdateAccountAccess(accountId);

  const { openModal, setBtn2Loading, closeModal } = useContext(ModalContext);

  const removeBillingOwnerPrompt = useCallback(
    (billingOwnerId: string) => {
      openModal({
        type: "confirmation",
        icon: "delete",
        heading: "Remove Billing Owner?",
        contents: [
          "This will terminate any active subscriptions and you may lose access to your tax reports.",
        ],
        btn2Content: "Remove",
        btn2Action: async () => {
          setBtn2Loading(true);
          try {
            await updateAccountAccess({
              ops: [
                {
                  action: "delete",
                  userId: billingOwnerId,
                },
              ],
            });
            // close modal after update delay
            setTimeout(closeModal, SUBSCRIPTION_UPDATE_DELAY);
          } catch (error) {
            captureRequestError(error);
            reportError(error);
            setBtn2Loading(false);
          }
        },
      });
    },
    [closeModal, openModal, reportError, setBtn2Loading, updateAccountAccess]
  );

  return removeBillingOwnerPrompt;
}
