import { getWalletName } from "@syla/shared/types/models/WalletBase";
import React, { useMemo } from "react";
import { Flex, Text, HStack, VStack } from "@chakra-ui/react";

import { NumberStyler } from "../../../../helper/NumberStyler";
import { Route, useNavigator } from "../../../../routers/navigator";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useValueHoldings } from "../../../../store/useValueHoldings";
import { StdVStack } from "../../../atoms/Containers";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";
import { RenderIcons } from "../../../atoms/RenderIcons";
import { sortAssetHoldings } from "../../../../helper/wallet/sortAssetHoldings";
import { SkeletonRect } from "../../../atoms/Skeletons";
import { getDataSourceStatusProps } from "../getDataSourceStatusProps";
import { LastSyncedTime } from "../LastSyncedTime";
import { DUMMY_HOLDINGS } from "../dummyData";
import { getWalletHoldingsValue } from "../mainDataSourceTable/getWalletHoldingsValue";
import { DataSourceWalletResponse } from "./DataSourceTable";

export const DataSourceTableRow = ({
  walletResponse,
  isLoading,
}: {
  walletResponse: DataSourceWalletResponse;
  isLoading?: boolean;
}): JSX.Element => {
  const navigate = useNavigator();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const statusProps = getDataSourceStatusProps(walletResponse);
  const { data: holdingsValue, isLoading: marketValueLoading } =
    useValueHoldings();
  const walletValue = useMemo(
    () =>
      getWalletHoldingsValue({
        walletWithHoldings: walletResponse,
        holdingsValue,
      }),
    [holdingsValue, walletResponse]
  );

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      borderWidth="0 0 1px 0"
      borderColor="white.500"
      // h="70px"
      px="16px"
      py="8px"
    >
      {/* ------------ Wallet column -----------------*/}
      <Flex w="30%" alignItems="center" minW="250px">
        <ImageWithMissingSrc
          src={walletResponse.dataSource.image}
          alt={walletResponse.dataSource.name}
          mr="10px"
          w="40px"
          minW="40px"
          isLoading={isLoading}
          noPaddingIcon
        />
        <StdVStack>
          {/* Name and status icon */}
          <SkeletonRect isLoaded={!isLoading}>
            <HStack spacing={0}>
              <Text mr="10px">
                {getWalletName({
                  wallet: walletResponse,
                  dataSource: walletResponse.dataSource,
                })}
              </Text>
              {statusProps.icon}
            </HStack>
          </SkeletonRect>
          {/* Status and last import time */}
          <SkeletonRect isLoaded={!isLoading}>
            <VStack alignItems="flex-start" spacing={0}>
              <Text color={statusProps.color} pr="5px" fontSize="0.75rem">
                {statusProps.status}
              </Text>
              <LastSyncedTime
                walletResponse={walletResponse}
                lastUpdated={statusProps.lastUpdated}
                whiteSpace="nowrap"
                fontSize="0.75rem"
              />
            </VStack>
          </SkeletonRect>
        </StdVStack>
      </Flex>

      {/* ------------ Transaction Number column -----------------*/}
      <Flex w="20%" minW="150px">
        <SkeletonRect isLoaded={!isLoading}>
          <Text
            whiteSpace="nowrap"
            color="red.500"
            fontWeight="500"
            cursor="pointer"
            onClick={() => {
              // navigate to the transactions screen with the walletId as a location prop
              navigate({
                route: Route.Transactions,
                accountId,
                state: {
                  filter: {
                    dataSource: [walletResponse._id],
                  },
                },
              });
            }}
          >
            {walletResponse.transactionCount}
          </Text>
        </SkeletonRect>
      </Flex>
      {/* ------------ Token icons column -----------------*/}
      <Flex w="30%" minW="200px" alignItems="center">
        <RenderIcons
          assets={sortAssetHoldings(
            walletResponse.holdings ?? DUMMY_HOLDINGS
          ).map((holding) => holding.asset)}
          qty={3}
          isLoading={isLoading || !walletResponse.holdings}
        />
      </Flex>
      {/* ------------ Market Value column -----------------*/}
      <Flex w="20%" minW="150px" justifyContent="flex-end" pr="30px">
        <SkeletonRect isLoaded={!(isLoading || marketValueLoading)}>
          <NumberStyler
            num={walletValue}
            unit="currency"
            negativeColored
            mr="15px"
          />
        </SkeletonRect>
      </Flex>
    </Flex>
  );
};
