import { Flex, Heading, Text } from "@chakra-ui/react";
import {
  WalletImportMethod,
  SyncState,
} from "@syla/shared/types/models/WalletBase";
import React, { useMemo, useCallback, useState, Dispatch } from "react";
import { useFormContext } from "react-hook-form";
import { refreshWallets } from "../../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import {
  DataSource,
  EditDataSourceForm,
} from "../../../../../types/dataSource/dataSource";
import { Wallet } from "../../../../../types/wallet/wallet";
import { ErrorBox } from "../../../../atoms/ImportError";
import { SyncApiParamInputs } from "../../addDataSourceDrawer/SyncApiParamInputs";
import { AuthoriseModule } from "../../AuthoriseModule";
import { DataSourceNameInput } from "../../DataSourceNameInput";

interface EditApiTabProps {
  dataSource: DataSource;
  wallet: Wallet;
  setError: Dispatch<Error | undefined>;
}

export const EditApiTab = ({
  dataSource,
  wallet,
  setError,
}: EditApiTabProps) => {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext<EditDataSourceForm>();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const oAuth = useMemo(
    () => ["oauth", "vezgo"].includes(dataSource.syncAuthType || ""),
    [dataSource]
  );
  const [authorised, setAuthorised] = useState(wallet.authorised);

  const [authInProgress, setAuthInProgress] = useState<boolean>();

  const handleAuthorisedChange = useCallback(
    (value: boolean | undefined) => {
      setAuthorised(value);
      if (value) refreshWallets(accountId);
    },
    [accountId]
  );

  return (
    <Flex direction="column">
      {/* Data Source Name */}
      <DataSourceNameInput
        isDisabled={isSubmitting}
        error={errors.customName?.message}
        registerProps={{
          ...register("customName", {
            required: "Data source name is required",
          }),
        }}
      />

      {/* Auth error section */}
      {!authorised &&
        wallet.syncState != SyncState.InProgress &&
        wallet.importMethod == WalletImportMethod.API && (
          <ErrorBox
            error={
              !authorised && wallet.importMethod == WalletImportMethod.API
                ? new Error()
                : undefined
            }
          >
            <Heading textAlign="center" size="sm" mb="10px">
              Authorisation Expired
            </Heading>
            <Text textAlign="center">
              {oAuth
                ? "Your authorisation has expired or been revoked. Please Connect again below to reauthorise."
                : "Your API keys are no-longer working. Please update to a working API key below."}
            </Text>
          </ErrorBox>
        )}
      {/* Connect button */}
      {wallet.importMethod == WalletImportMethod.API &&
        oAuth &&
        !authorised && (
          <AuthoriseModule
            authorised={authorised}
            setAuthorised={handleAuthorisedChange}
            authInProgress={authInProgress}
            setAuthInProgress={setAuthInProgress}
            dataSource={dataSource}
            walletId={wallet._id}
            setError={setError}
            reconnectId={wallet.vezgoAccountId}
          />
        )}
      {/* API Key params */}
      {!oAuth && dataSource.syncImportParams && (
        <SyncApiParamInputs
          dataSource={dataSource}
          validate={wallet.importMethod == WalletImportMethod.API}
          isEdit
        />
      )}
    </Flex>
  );
};
