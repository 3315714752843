import { Text, TextProps } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { FormContainer } from "../../atoms/Containers";
import { InvalidPage } from "../../atoms/InvalidPage";
import { ContactSupportLink } from "../ContactSupportLink";
import { SylaFormHeader } from "../forms/SylaFormHeader";

export type EmailVerificationNavState = {
  email: string;
};

export const EmailVerification = (): JSX.Element => {
  const { state } = useLocation<EmailVerificationNavState | undefined>();

  const supportMessage = `Hi,
I didn't receive my email verification for ${state?.email}.

Can you please help?`;

  return state?.email ? (
    <FormContainer>
      <SylaFormHeader heading={"First, let's verify your email"} />
      <Text>We just sent a verification email to {state.email}.</Text>
      <Text {...actionStyleProps} mt="10px">
        Please check your Inbox (and Spam folder!) and click the link to get
        started.
      </Text>
      <Text mt="30px">
        {"Didn't receive your email? "}
        <ContactSupportLink supportPrompt={supportMessage} />
      </Text>
    </FormContainer>
  ) : (
    <InvalidPage />
  );
};

const actionStyleProps: TextProps = {
  fontWeight: "black",
};
