"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decimalStringProps = void 0;
const decimal_1 = require("./decimal");
// these props are used as mongoose getter/setter props
exports.decimalStringProps = {
    type: String,
    get: (s) => s != null && s !== "" ? decimal_1.Decimal.from(s) : undefined,
    set: (d) => (d ? d.toString() : null),
};
