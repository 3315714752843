import { ArrowForwardIcon } from "@chakra-ui/icons";
import { GridItem, Text, Icon, Grid } from "@chakra-ui/react";
import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import { uniq, uniqueId } from "lodash";
import React, { Fragment, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { getAccountLedgerTypes } from "../../../../../../api/transactions/getAccountLedgerTypes";
import { getAccountTransactionTypes } from "../../../../../../api/transactions/getAccountTransactionTypes";
import { getOptionForLedgerType } from "../../../../../../helper/transaction/getLedgerType";
import { useCurrentAccountStore } from "../../../../../../store/currentAccountStore";
import {
  getAccountTransactionsCacheKey,
  useTransactionsStore,
} from "../../../../../../store/transactionsStore";
import { OrderFilterSetting } from "../../../../../../types/order/orderQuery";
import { SingleThumbnailSelectBox } from "../../../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import {
  borderColor,
  warningBorderColor,
  ArrowGridItem,
  DeleteButtonGridItem,
  ApplyAllButton,
  setOperation,
  SwitchLedgerOperationEntry,
  operationDefs,
  OperationTitle,
} from "../common";

export function LedgerBulkEdit({
  filters,
  operations,
  selectedIds,
  setOperations,
}: {
  operations: SwitchLedgerOperationEntry[];
  setOperations: React.Dispatch<
    React.SetStateAction<SwitchLedgerOperationEntry[]>
  >;
  selectedIds: string[];
  filters: OrderFilterSetting;
}) {
  const groups = useTransactionsStore((s) => s.groups);

  const selectedLedgerTypes = useMemo(
    () =>
      selectedIds.length
        ? uniq(
            withoutNullable(
              selectedIds.flatMap((gid) =>
                groups?.[gid].data.transactions.flatMap((t) =>
                  t.ledgers.flatMap((l) => l.type)
                )
              )
            )
          )
        : undefined,
    [groups, selectedIds]
  );

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: accountLedgerTypes, isLoading: accountLedgerTypesLoading } =
    useQuery(
      [...getAccountTransactionsCacheKey(accountId), "account-ledger-types"],
      () => getAccountLedgerTypes(accountId),
      {
        enabled: !selectedLedgerTypes,
      }
    );

  const filteredAccountLedgerTypes = useMemo(
    () =>
      filters.ledgerType.length
        ? accountLedgerTypes?.filter((lt) => filters.ledgerType.includes(lt))
        : undefined,
    [accountLedgerTypes, filters.ledgerType]
  );

  // prepopulate operations based on selected types or filters
  useEffect(() => {
    if (selectedLedgerTypes) {
      setOperations(
        selectedLedgerTypes.map((ledgerType) => ({
          id: uniqueId(),
          type: "ledger",
          from: ledgerType,
        }))
      );
    } else if (filteredAccountLedgerTypes) {
      setOperations(
        filteredAccountLedgerTypes.map((ledgerType) => ({
          id: uniqueId(),
          type: "ledger",
          from: ledgerType,
        }))
      );
    } else {
      setOperations([{ id: uniqueId(), type: "ledger" }]);
    }
  }, [filteredAccountLedgerTypes, selectedLedgerTypes, setOperations]);

  return (
    <Grid
      w="100%"
      templateColumns="1fr auto 1fr auto auto"
      alignItems="center"
      justifyItems="flex-start"
      gap={4}
    >
      <Fragment>
        {/* Type Header */}
        <GridItem colSpan={5} justifySelf="flex-start">
          <OperationTitle>{operationDefs["ledger"].label}</OperationTitle>
        </GridItem>
        {/* Operations */}
        {operations.map((op) => (
          <Fragment key={op.id}>
            <LedgerOperation
              key={op.id}
              operation={op}
              fromLedgerTypes={
                selectedLedgerTypes ??
                filteredAccountLedgerTypes ??
                accountLedgerTypes ??
                []
              }
              fromLedgerTypesLoading={accountLedgerTypesLoading}
              setOperation={setOperation(op, setOperations)}
            />
            {/* Apply all */}
            <GridItem>
              <ApplyAllButton
                operation={op}
                operations={operations}
                setOperations={setOperations}
              />
            </GridItem>
            {/* Delete */}
            <DeleteButtonGridItem
              operation={op}
              operations={operations}
              setOperations={setOperations}
            />
          </Fragment>
        ))}
      </Fragment>
    </Grid>
  );
}

export function LedgerOperation({
  operation,
  setOperation,
  fromLedgerTypes,
  fromLedgerTypesLoading,
}: {
  operation: SwitchLedgerOperationEntry;
  setOperation: React.Dispatch<
    React.SetStateAction<SwitchLedgerOperationEntry>
  >;
  fromLedgerTypes: LedgerType[];
  fromLedgerTypesLoading?: boolean;
}): JSX.Element {
  return (
    <>
      <GridItem w="100%">
        <SingleThumbnailSelectBox
          placeholder="Select Type"
          searchEnable
          selectedOption={operation.from ?? ""}
          onChangeSelection={(selection) => {
            setOperation((operation) => ({
              ...operation,
              from: selection,
            }));
          }}
          options={fromLedgerTypes.map(getOptionForLedgerType)}
          maxOptionsInView={4}
          allowDeSelected={false}
          selectBtnProps={{
            minWidth: "180px",
            width: "100%",
            borderColor: operation.from ? borderColor : warningBorderColor,
          }}
          isLoading={fromLedgerTypesLoading}
          autoSelectOnly
        />
      </GridItem>
      <ArrowGridItem>
        <Text>
          <Icon as={ArrowForwardIcon} />
        </Text>
      </ArrowGridItem>
      <GridItem w="100%">
        <SingleThumbnailSelectBox
          placeholder="Select Type"
          searchEnable
          selectedOption={operation.to ?? ""}
          onChangeSelection={(selection) => {
            setOperation((operation) => ({
              ...operation,
              to: selection,
            }));
          }}
          options={Object.values(LedgerType)
            // don't show the same value
            .filter((type) => type != operation.from)
            .map(getOptionForLedgerType)}
          maxOptionsInView={4}
          allowDeSelected={false}
          selectBtnProps={{
            minWidth: "180px",
            width: "100%",
            borderColor: operation.to ? borderColor : warningBorderColor,
          }}
        />
      </GridItem>
    </>
  );
}

export function validateLedgerOperation(op: SwitchLedgerOperationEntry) {
  return op.from && op.to;
}
