import { Flex, Switch, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AddDataSourceForm } from "../../../../types/dataSource/dataSource";
import { StyledDatePicker } from "../../../atoms/DatePicker";
import { InfoToolTip } from "../../../atoms/InfoToolTip";
import { StyledFormLabel } from "../../../molecules/forms/FormComponents";

export const SyncFromDateInput = () => {
  const { control, resetField, watch } = useFormContext<AddDataSourceForm>();

  const { isOpen: showApiSyncInput, onToggle: onToggleApiSyncInput } =
    useDisclosure({
      defaultIsOpen: !!watch("fromDate"),
      onClose() {
        resetField("fromDate");
      },
    });

  return (
    <>
      <Flex direction="row" alignItems="center" mb="15px">
        <Switch
          size="sm"
          colorScheme="red"
          onChange={() => onToggleApiSyncInput()}
          isChecked={showApiSyncInput}
        />
        <Text color="black.600" fontSize="0.875rem" mx="10px">
          Sync from a particular date
        </Text>
        <InfoToolTip
          label="Specify the earliest date you want to start importing transactions from. Otherwise, Syla will import all available transactions"
          wrap
        />
      </Flex>

      {showApiSyncInput && (
        <Flex direction="column">
          <StyledFormLabel content="API Sync From" />
          <Controller
            control={control}
            name="fromDate"
            render={({ field: { onChange, value } }) => (
              <StyledDatePicker
                value={value}
                onChange={(date) => onChange(date)}
              />
            )}
          />
        </Flex>
      )}
    </>
  );
};
