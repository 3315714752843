export type CreateStripeBillingPortalRequestBody = {
  returnUrl: string;
} & (
  | {
      action: StripeBillingPortalAction.Upgrade;
      financialYear?: string;
      selectedPlanId: string;
      stripeSubscriptionId: string;
    }
  | {
      action: StripeBillingPortalAction.Renew;
      stripeSubscriptionId: string;
    }
  | {
      action: StripeBillingPortalAction.Cancel;
      stripeSubscriptionId: string;
    }
  | {
      action: StripeBillingPortalAction.UpdatePaymentMethod;
    }
);

export enum StripeBillingPortalAction {
  Upgrade = "upgrade",
  Renew = "renew",
  Cancel = "cancel",
  UpdatePaymentMethod = "updatePaymentMethod",
}
