import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import { MetadataKey } from "@syla/shared/types/models/TransactionBase";
import { OrderResponseItem } from "@syla/shared/types/responses/OrderResponseItem";
import { startCase } from "lodash";
import { useMemo } from "react";

export function useGetTxHint(orderResponse: OrderResponseItem<string>) {
  return useMemo(() => {
    const smartContractCall = withoutNullable(
      orderResponse.transactions.flatMap(
        (tx) =>
          tx.metadata?.find((meta) => meta.name == MetadataKey.ContractMethod)
            ?.value
      )
    ).at(0);

    if (smartContractCall && !/^0x/i.test(smartContractCall)) {
      return startCase(smartContractCall);
    }
  }, [orderResponse.transactions]);
}
