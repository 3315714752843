import { Flex, Divider, Center } from "@chakra-ui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as AccountsIcon } from "../../../images/icons/navbar/accounts.svg";

import { ReactComponent as DashboardIcon } from "../../../images/icons/navbar/dashboard.svg";
import { ReactComponent as DataSourcesIcon } from "../../../images/icons/navbar/dataSources.svg";
import { ReactComponent as ReferAndEarnIcon } from "../../../images/icons/navbar/referAndEarn.svg";
import { ReactComponent as SettingIcon } from "../../../images/icons/navbar/setting.svg";
import { ReactComponent as TaxReportIcon } from "../../../images/icons/navbar/taxReports.svg";
import { ReactComponent as TransactionsIcon } from "../../../images/icons/navbar/transactions.svg";
import { ReactComponent as UserIcon } from "../../../images/icons/navbar/userSettings.svg";
import { ReactComponent as BalancesIcon } from "../../../images/icons/navbar/coins.svg";

import {
  buildDashboardLink,
  buildDataSourcesLink,
  buildTransactionsLink,
  buildTaxReportsLink,
  buildAccountSettingsLink,
  buildAccountsLink,
  buildReferralsLink,
  buildUserSettingsLink,
  buildBalancesLink,
} from "../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { AccountNav } from "./AccountNav";

import { NavLink } from "./NavLink";

export const NavbarLinks = ({ onClose }: { onClose: () => void }) => {
  const { pathname } = useLocation();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const dashboardLink = buildDashboardLink({ accountId });
  const dataSourcesLink = buildDataSourcesLink({ accountId });
  const transactionsLink = buildTransactionsLink({ accountId });
  const balancesLink = buildBalancesLink({ accountId });
  const taxReportsLink = buildTaxReportsLink({ accountId });
  const accountSettingsLink = buildAccountSettingsLink({ accountId });
  const accountsLink = buildAccountsLink({ accountId });
  const userSettingsLink = buildUserSettingsLink({ accountId });
  const referAndEarnLink = buildReferralsLink({ accountId });

  return (
    <Flex direction="column">
      <AccountNav />
      <NavLink
        to={dashboardLink}
        icon={<DashboardIcon />}
        active={pathname === dashboardLink}
        onClose={onClose}
      >
        Dashboard
      </NavLink>
      <NavLink
        to={dataSourcesLink}
        icon={<DataSourcesIcon />}
        active={pathname === dataSourcesLink}
        onClose={onClose}
      >
        Data Sources
      </NavLink>
      <NavLink
        to={transactionsLink}
        icon={<TransactionsIcon />}
        active={pathname === transactionsLink}
        onClose={onClose}
      >
        Transactions
      </NavLink>
      <NavLink
        to={balancesLink}
        icon={<BalancesIcon />}
        active={pathname === balancesLink}
        onClose={onClose}
      >
        Balances
      </NavLink>
      <NavLink
        to={taxReportsLink}
        icon={<TaxReportIcon />}
        active={pathname === taxReportsLink}
        onClose={onClose}
      >
        Tax Reports
      </NavLink>
      <NavLink
        to={accountSettingsLink}
        icon={<SettingIcon />}
        active={pathname.startsWith(accountSettingsLink)}
        onClose={onClose}
      >
        Settings
      </NavLink>
      <Center px="1rem">
        <Divider />
      </Center>
      <NavLink
        to={accountsLink}
        icon={<AccountsIcon />}
        active={pathname === accountsLink}
        onClose={onClose}
      >
        Accounts
      </NavLink>
      <NavLink
        to={userSettingsLink}
        icon={<UserIcon />}
        active={pathname === userSettingsLink}
        onClose={onClose}
      >
        User Profile
      </NavLink>
      <NavLink
        to={referAndEarnLink}
        icon={<ReferAndEarnIcon />}
        active={pathname === referAndEarnLink}
        onClose={onClose}
      >
        Refer & Earn
      </NavLink>
    </Flex>
  );
};
