import React from "react";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { SkeletonRect } from "../../atoms/Skeletons";
import { TooltipWrapper } from "../../atoms/TooltipWrapper";

export const BILLING_OWNER_REQUIRED_MESSAGE =
  "Please contact the billing owner to upgrade the account and unlock this feature";

export function ActionOrPurchaseButton({
  canPurchase,
  hasActionPermission,
  blockAction,
  blockedText,
  isLoading,
  handleAction,
  handlePurchase,
  inProgress,
  actionText,
}: {
  canPurchase: boolean;
  hasActionPermission: boolean;
  blockAction: boolean;
  blockedText: string;
  isLoading: boolean | undefined;
  handleAction: () => void;
  handlePurchase: () => void;
  inProgress: boolean | undefined;
  actionText?: string;
}) {
  return (
    <TooltipWrapper
      tooltip={
        blockAction
          ? blockedText
          : !hasActionPermission && !canPurchase
          ? BILLING_OWNER_REQUIRED_MESSAGE
          : undefined
      }
    >
      <SkeletonRect isLoaded={!isLoading}>
        <ButtonVariant
          content={
            hasActionPermission
              ? actionText ?? "Download"
              : canPurchase
              ? "Unlock"
              : "Locked"
          }
          outlineType="outlineBlack"
          disabled={blockAction || (!hasActionPermission && !canPurchase)}
          onClick={hasActionPermission ? handleAction : handlePurchase}
          isLoading={inProgress}
          cursor={
            blockAction || (!hasActionPermission && !canPurchase)
              ? "no-drop"
              : undefined
          }
          fontWeight="normal"
          fontSize="0.875rem"
          h="30px"
        />
      </SkeletonRect>
    </TooltipWrapper>
  );
}
