import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const editModeFirstColSize = "250px";

export const LedgerTableHeaders = ({
  editMode,
}: {
  editMode: boolean;
}): JSX.Element => (
  <Flex
    borderWidth="1px 0 1px 0"
    w="100%"
    m="0 auto"
    backgroundColor="black.200"
  >
    <THeaderCell minW={editMode ? editModeFirstColSize : "auto"}>
      <THeaderText>Classification</THeaderText>
    </THeaderCell>
    <THeaderCell>
      <THeaderText>Change</THeaderText>
    </THeaderCell>
    <THeaderCell>
      <THeaderText>Asset</THeaderText>
    </THeaderCell>
    <THeaderCell justifyContent="flex-end">
      <THeaderText>Market value</THeaderText>
    </THeaderCell>
    {/* Delete Action */}
    <Flex w="7%" mx="10px" px="10px" />
  </Flex>
);

const THeaderCell = styled(Flex)`
  width: 25%;
  font-size: 0.75rem;
  padding: 5px 10px 5px 25px;
`;

const THeaderText = styled(Text)`
  text-align: center;
`;
