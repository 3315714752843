import React, { useMemo } from "react";
import {
  DataSourceType,
  ImportParamBase,
} from "@syla/shared/types/models/DataSourceBase";
import { sortBy } from "lodash";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useFormContext, Controller } from "react-hook-form";
import {
  AddDataSourceForm,
  DataSource,
} from "../../../../types/dataSource/dataSource";
import { ParamInput } from "../ParamInput";
import { useQueryDataSources } from "../../../../store/useQueryDataSources";
import { StyledFormLabel } from "../../../molecules/forms/FormComponents";
import { SingleThumbnailSelectBox } from "../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";

export const SyncApiParamInputs = ({
  dataSource,
  validate,
  isEdit,
}: {
  validate: boolean;
  dataSource: Pick<DataSource, "syncImportParams" | "networks" | "type">;
  isEdit?: boolean;
}) => {
  // hook form context
  const {
    register,
    control,
    formState: { errors, isSubmitting },
  } = useFormContext();

  const importParams = useMemo(
    () => ({
      key: null,
      secret: null,
      passphrase: null,
    }),
    []
  );

  const sortedParams = useMemo(() => {
    if (!dataSource.syncImportParams) throw new Error("Missing params");
    return sortBy(
      Object.entries(dataSource.syncImportParams).filter(
        ([key]) => key in importParams
      ) as [keyof typeof importParams, ImportParamBase][],
      ([, { order }]) => order
    );
  }, [importParams, dataSource.syncImportParams]);

  const mapParamProp: (
    param: keyof typeof importParams
  ) => keyof AddDataSourceForm = (param) => {
    switch (param) {
      case "key":
        return "apiKey";
      case "secret":
        return "secretKey";
      case "passphrase":
        return "passphrase";
    }
  };

  const { data: dataSources, isLoading } = useQueryDataSources();

  const networkDropdownOptions = useMemo(() => {
    if (dataSources && dataSource.networks?.length) {
      return dataSources
        .filter((ds) =>
          dataSource.networks!.map((network) => network._id).includes(ds._id)
        )
        .map((ds) => ({
          label: ds.name,
          value: ds._id!,
          icon: ds.image,
        }));
    }
    return [];
  }, [dataSource.networks, dataSources]);

  return (
    <>
      {sortedParams.map(([param, { text }]) =>
        ParamInput({
          text,
          isDisabled: isSubmitting,
          error: errors[mapParamProp(param)],
          register: register(mapParamProp(param), {
            required: validate ? `${text} is required` : undefined,
          }),
          showLock: param != "key",
        })
      )}
      {!isEdit && dataSource.type == DataSourceType.Wallet && (
        <>
          <FormControl
            mb="10px"
            isRequired
            isDisabled={isSubmitting}
            isInvalid={!!errors.network}
          >
            <StyledFormLabel content="Network" />
            <Controller
              control={control}
              rules={{ required: validate && "Network is required" }}
              name="network"
              render={({ field: { onChange, value } }) => (
                <SingleThumbnailSelectBox
                  options={networkDropdownOptions}
                  isLoading={isLoading}
                  searchEnable
                  selectedOption={value}
                  placeholder="Select a network"
                  onChangeSelection={(selection) => onChange(selection)}
                  selectBtnProps={{ height: "40px", width: "100%" }}
                  optionContainerProps={{ overflowY: "auto" }}
                  contentProps={{ width: "150%", maxHeight: "34vh" }}
                  autoSelectOnly
                />
              )}
            />
            {validate && errors.network && (
              <FormErrorMessage>{errors.network.message}</FormErrorMessage>
            )}
          </FormControl>
        </>
      )}
    </>
  );
};
