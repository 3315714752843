import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

import australia from "../../../images/icons/australia.svg";
import bullseye from "../../../images/icons/bullseye.svg";
import taxOptimisation from "../../../images/icons/taxOptimisation.svg";
import { InfoContainer } from "../../atoms/Containers";

const BlackText = styled.span`
  color: #333333;
  margin: 0 5px;
  font-weight: 500;
  font-size: 1.3rem;
`;
const GrayText = styled(Text)`
  color: #999999;
  font-size: 1.3rem;
`;

export const SignUpInfo = () => (
  <InfoContainer>
    <Flex my="20px">
      <Image src={australia} alt="" w="70px" mx="30px" />
      <Flex>
        <GrayText>
          Designed by
          <BlackText>Australian</BlackText>
          crypto tax professionals.
        </GrayText>
      </Flex>
    </Flex>
    <Flex my="20px">
      <Image src={bullseye} alt="" w="70px" mx="30px" />
      <Flex>
        <GrayText>
          <BlackText>Accurate</BlackText>
          tax classifications that meet ATO requirements.
        </GrayText>
      </Flex>
    </Flex>
    <Flex my="20px">
      <Image src={taxOptimisation} alt="" w="70px" mx="30px" />
      <Flex>
        <GrayText>
          Exclusive
          <BlackText>tax optimisation</BlackText>
          so you pay the lowest tax possible.
        </GrayText>
      </Flex>
    </Flex>
  </InfoContainer>
);
