import {
  DataSourceBase,
  DataSourceType as CommonDataSourceType,
} from "@syla/shared/types/models/DataSourceBase";

export const DataSourceType = CommonDataSourceType;
export type DataSourceTypeFilter = "all" | CommonDataSourceType;

export type DataSource = DataSourceBase<string>;

export interface AddDataSourceForm {
  apiKey: string;
  secretKey?: string;
  passphrase?: string;
  customName?: string;
  objectAddresses: { address: string }[];
  fromDate: Date | undefined;
  storedFiles: File[];
  network?: string;
}

export interface EditDataSourceForm {
  apiKey: string;
  secretKey: string;
  passphrase: string;
  customName: string;
  storedFiles: File[];
}

export enum FromDateOption {
  Today = "Today",
  FromBeginning = "From Beginning (default)",
  CustomDate = "Custom Date",
}

export enum DataSourceSortOption {
  DateAdded = "DateAdded",
  NameAscending = "NameAscending",
  NameDescending = "NameDescending",
  MostTransactions = "MostTransactions",
  HighestValue = "HighestValue",
}

export const DataSourceSortOptionName: Record<DataSourceSortOption, string> = {
  DateAdded: "Date Added",
  HighestValue: "Highest Value",
  MostTransactions: "Most Transactions",
  NameAscending: "Name A-Z",
  NameDescending: "Name Z-A",
};
