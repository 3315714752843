import { Image, ImageProps, HStack, Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import Logo from "../../../images/Icon.svg";
import { FormHeading } from "./FormComponents";

export function SylaFormHeader({
  heading,
  subText,
}: {
  heading: string;
  subText?: string;
}): JSX.Element {
  return (
    <>
      <HStack>
        <SylaLogo />
        <FormHeading>{heading}</FormHeading>
      </HStack>
      <SylaFormSubHeader>{subText}</SylaFormSubHeader>
    </>
  );
}

export function SylaLogo(props: ImageProps) {
  return <Image src={Logo} alt="Syla Logo" {...props} />;
}

export function SylaFormSubHeader(props: BoxProps) {
  return <Box mb="30px" {...props} />;
}
