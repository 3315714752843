import { useMutation } from "react-query";
import { bulkEdit, BulkEditRequestProps } from "../api/transactions/bulkEdit";
import {
  invalidateTransactionQueryCache,
  refreshGroupList,
} from "./actions/groupList";
import { useTransactionsStore } from "./transactionsStore";

export const useMutationBulkEdit = (accountId: string) =>
  useMutation(
    (props: Omit<BulkEditRequestProps, "accountId">) =>
      bulkEdit({ accountId, ...props }),
    {
      // set calculating property while working
      onMutate: () => {
        useTransactionsStore.setState((state) => {
          if (!state.updating) state.updating = true;
        });
      },
      onError: () => {
        // clear calculating property on error
        useTransactionsStore.setState((state) => {
          if (state.updating) state.updating = false;
        });
      },
      onSuccess: () => {
        // update group list and refresh any queries
        invalidateTransactionQueryCache(accountId);
        refreshGroupList();
      },
    }
  );
