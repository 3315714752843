import { CheckIcon, RepeatIcon } from "@chakra-ui/icons";
import { Tooltip, Tag, TagLeftIcon, TagLabel, Hide } from "@chakra-ui/react";
import React from "react";
import { useTransactionsStore } from "../../store/transactionsStore";

export const UpdatingStatus = () => {
  const updating = useTransactionsStore((state) => state.updating);
  const syncing = useTransactionsStore((state) => state.syncing);

  const show = updating || syncing;

  const updatingTooltip =
    "Syla is updating the transaction data with your recent changes. Tax Reports and some statistics will be unavailable during this time";

  return (
    <Tooltip label={show ? updatingTooltip : undefined}>
      <Tag backgroundColor="inherit" p="0px">
        <TagLeftIcon boxSize="12px" as={show ? RepeatIcon : CheckIcon} />
        <Hide below="sm">
          <TagLabel>{show ? "Calculating..." : "Up to date"}</TagLabel>
        </Hide>
      </Tag>
    </Tooltip>
  );
};
