import React, { useEffect } from "react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  BoxProps,
  ButtonProps,
  FlexProps,
  HStack,
  VStack,
  Text,
  Icon,
  PopoverContentProps,
} from "@chakra-ui/react";
import { Selector } from "../../../molecules/selector/Selector";
import { ImageWithMissingSrc } from "../../ImageWithMissingSrc";

interface SingleThumbnailSelectBoxProps<MultiSelect extends boolean | undefined>
  extends BoxProps {
  // Must define
  options: SelectOptionType[];
  selectedOption: MultiSelect extends true ? string[] : string;
  onChangeSelection: (
    selectedOptions: MultiSelect extends true ? string[] : string
  ) => void;
  // Optional features
  multiSelect?: MultiSelect;
  disabled?: boolean;
  doubleRows?: boolean;
  searchEnable?: boolean;
  placeholder?: string;
  /* Determines the height of the options container based on the options to view at one time without scrolling */
  maxOptionsInView?: number;
  /* Total max options to show without searching. Useful for a large list of options that is slow to render */
  maxOptionsToDisplay?: number;
  description?: boolean;
  selectionType?: "name" | "code";
  selectedIndication?: boolean;
  exclusive?: boolean;
  allowDeSelected?: boolean;
  // Styling
  optionHeight?: number;
  // Custom props
  selectBtnProps?: ButtonProps;
  contentProps?: PopoverContentProps;
  optionContainerProps?: FlexProps;
  isLoading?: boolean;
  /* Automatically select the first item if only one option */
  autoSelectOnly?: boolean;
}

export const SingleThumbnailSelectBox = <
  MultiSelect extends boolean | undefined = false
>({
  options,
  selectedOption,
  onChangeSelection,
  disabled = false,
  isLoading,
  searchEnable = false,
  maxOptionsToDisplay,
  placeholder = "Please select ...",
  selectBtnProps,
  contentProps,
  allowDeSelected = true,
  autoSelectOnly,
  multiSelect,
}: SingleThumbnailSelectBoxProps<MultiSelect>): JSX.Element => {
  // show loading indicator if there is a current selection that is waiting for elements to load
  const currentSelectionLoading = !!selectedOption && isLoading;

  const currentOptions = options.filter((o) =>
    multiSelect ? selectedOption.includes(o.value) : selectedOption == o.value
  );

  const anySelected = multiSelect ? selectedOption.length : !!selectedOption;

  // clear selection if it's no longer viable
  useEffect(() => {
    if (anySelected && !currentOptions.length && !isLoading) {
      if (multiSelect) {
        onChangeSelection([] as any);
      } else {
        onChangeSelection("" as any);
      }
    }
  }, [
    anySelected,
    currentOptions,
    isLoading,
    multiSelect,
    onChangeSelection,
    selectedOption,
  ]);

  return (
    <Selector
      renderOptionContent={({
        isSelected,
        option: { icon, label, description },
      }) => (
        <Item
          name={label}
          icon={icon}
          hasCheck={isSelected}
          description={description}
        />
      )}
      SelectorContent={
        <Item
          name={
            currentSelectionLoading
              ? "Loading.."
              : multiSelect
              ? placeholder
              : currentOptions[0]?.label ?? placeholder
          }
          icon={multiSelect ? undefined : currentOptions[0]?.icon}
          hasCheck={true}
          hideCheck={true}
        />
      }
      options={options.map((o) => ({ key: o.value, value: o }))}
      maxOptionsToDisplay={maxOptionsToDisplay}
      selectedOptions={
        multiSelect
          ? (selectedOption as string[]).map((o) => ({ key: o }))
          : (selectedOption as string)
          ? [{ key: selectedOption as string }]
          : []
      }
      onChangeSelection={(options) => {
        onChangeSelection(
          (multiSelect
            ? (options.map((o) => o.key) as string[])
            : options[0]?.key ?? "") as any
        );
      }}
      allowDeselect={allowDeSelected}
      searchSettings={
        searchEnable ? { searchKeys: ["label", "description"] } : undefined
      }
      buttonProps={selectBtnProps}
      isDisabled={disabled}
      isLoading={isLoading}
      isBusy={currentSelectionLoading}
      contentProps={contentProps}
      autoSelectOnly={autoSelectOnly}
    />
  );
};

const Item = ({
  name,
  description,
  icon,
  hasCheck,
  hideCheck,
}: {
  name: string;
  description?: string;
  icon?: string | undefined;
  hasCheck: boolean;
  hideCheck?: boolean;
}) => {
  return (
    <HStack
      w="100%"
      position="relative"
      bg="transparent"
      py="0.25rem"
      justify="space-between"
      overflow="hidden"
    >
      {/* Icon, Text, and Description */}
      <HStack>
        <ImageWithMissingSrc src={icon} alt="" h="30px" />
        {/* Text and Description */}
        <VStack w="100%" alignItems="flex-start" spacing={1}>
          <Text
            color="gray.700"
            fontSize="sm"
            fontWeight={description ? "bold" : "normal"}
            noOfLines={1}
            wordBreak="break-all"
          >
            {name}
          </Text>
          {description && (
            <Text
              color="gray.500"
              fontSize="sm"
              // noOfLines={1}
              // wordBreak="break-all"
            >
              {description}
            </Text>
          )}
        </VStack>
      </HStack>
      {/* Checked indicator */}
      {!hideCheck && (
        <Icon
          as={CheckIcon}
          color="primary.500"
          visibility={hasCheck ? "visible" : "hidden"}
        />
      )}
    </HStack>
  );
};
