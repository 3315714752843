import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useContext } from "react";

import { ModalContext } from "../../contexts/ModalContext";
import { IconExtractorType } from "../../helper/iconExtractor";
import type { IModalInfo } from "../../react-app-env";

import { ActionBox } from "./overlayBoxes/ActionBox";
import { AlertBox } from "./overlayBoxes/AlertBox";
import { ComponentBox } from "./overlayBoxes/ComponentBox";
import { ConfirmationBox } from "./overlayBoxes/ConfirmationBox";

const RenderModal = ({ modalInfo }: { modalInfo: IModalInfo }) => {
  if (modalInfo.type === "alert") {
    return (
      <AlertBox
        icon={modalInfo.icon as IconExtractorType}
        heading={modalInfo.heading}
        contents={modalInfo.contents}
      />
    );
  }
  if (modalInfo.type === "action") {
    return (
      <ActionBox
        icon={modalInfo.icon as IconExtractorType}
        heading={modalInfo.heading}
        contents={modalInfo.contents}
        topBtnContent={modalInfo.btn1Content ?? "Action"}
        bottomBtnContent={modalInfo.btn2Content ?? "Cancel"}
        topBtnOnClick={modalInfo.btn1Action ?? (() => undefined)}
        bottomBtnOnClick={modalInfo.btn2Action ?? (() => undefined)}
        hideBottomButton={modalInfo.hideCloseButton ?? false}
      />
    );
  }
  if (modalInfo.type === "confirmation") {
    return (
      <ConfirmationBox
        icon={modalInfo.icon as IconExtractorType}
        heading={modalInfo.heading}
        contents={modalInfo.contents}
        RHSBtnContent={modalInfo.btn2Content ?? "missing"}
        RHSBtnOnClick={modalInfo.btn2Action ?? (() => undefined)}
        RHSBtnLoading={modalInfo.btn2Loading ?? false}
        confirmation={modalInfo.confirmation}
      />
    );
  }
  if (modalInfo.type === "component") {
    return (
      <ComponentBox
        heading={modalInfo.heading}
        contents={modalInfo.contents}
        component={modalInfo.component}
        hideBottomButton={modalInfo.hideCloseButton}
      />
    );
  }
  return <h1>No content was found</h1>;
};

export const GlobalModal = () => {
  const { modalIsOpen, modalInfo, closeModal } = useContext(ModalContext);

  const closeOnOverlayClick =
    !modalInfo?.preventClose &&
    modalInfo?.type !== "component" &&
    !modalInfo?.btn1Loading &&
    !modalInfo?.btn2Loading &&
    !modalInfo?.dontCloseOnOverlayClick;

  return (
    <Modal
      isOpen={modalIsOpen}
      onClose={() => {
        modalInfo?.onClose && modalInfo.onClose();
        closeModal();
      }}
      isCentered
      closeOnOverlayClick={closeOnOverlayClick}
      size={modalInfo?.size}
    >
      <ModalOverlay />
      <ModalContent>
        {!modalInfo?.hideCloseButton && (
          <ModalCloseButton isDisabled={modalInfo?.preventClose} />
        )}
        {modalInfo && <RenderModal modalInfo={modalInfo} />}
      </ModalContent>
    </Modal>
  );
};
