import { ParcelMethodType } from "@syla/shared/types/models/AccountBase";
import FIFO from "../../images/icons/FIFO.svg";
import HIFO from "../../images/icons/HIFO.svg";
import LIFO from "../../images/icons/LIFO.svg";
import LTFO from "../../images/icons/LTFO.svg";

export const getParcelMethodOptions = () => [
  {
    value: ParcelMethodType.LTFO,
    label: "Lowest Tax First Out (LTFO)",
    icon: LTFO,
    description:
      "Will intelligently select the parcel that will produce the lowest tax outcome to be disposed.",
  },
  {
    value: ParcelMethodType.FIFO,
    label: "First In First Out (FIFO)",
    icon: FIFO,
    description:
      "The oldest asset acquired is the first asset that is disposed.",
  },
  {
    value: ParcelMethodType.LIFO,
    label: "Last In First Out (LIFO)",
    icon: LIFO,
    description:
      "The most recent asset acquired will be the first to disposed.",
  },
  {
    value: ParcelMethodType.HIFO,
    label: "Highest In First Out (HIFO)",
    icon: HIFO,
    description:
      "The asset with the highest cost basis will be the first to be disposed.",
  },
];
