import { parseDecimal } from "@syla/shared/types/helpers/parsing";
import axios from "axios";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { ValueHoldingsResponse } from "../../types/wallet/wallet";

export const valueHoldings = async (
  accountId: string
): Promise<ValueHoldingsResponse> => {
  const { data }: { data: TransportType<ValueHoldingsResponse> } =
    await axios.get(`/accounts/${accountId}/wallets/value`);

  // convert api returned Decimal string to Decimal
  return {
    accountHoldings: data.accountHoldings.map((holding) => ({
      ...holding,
      marketValue: parseDecimal(holding.marketValue),
    })),
    walletHoldings: data.walletHoldings.map((holding) => ({
      ...holding,
      marketValue: parseDecimal(holding.marketValue),
    })),
  };
};
