import React from "react";
import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "../components/atoms/Containers";
import { SignUpForm } from "../components/molecules/forms/SignUpForm";
import { SignUpInfo } from "../components/molecules/overlayBoxes/SignUpInfo";

export const SignUp = () => (
  <SignInParentContainer>
    {/* Feedback: remove the confirmation alert when navigating away while halfway through signing up */}
    {/* <Prompt
      when={accountType !== undefined || accountSubType !== undefined}
      message="You haven't finished signing up, are you sure you want to leave?"
    /> */}
    <SignInLHSContainer>
      <SignUpInfo />
    </SignInLHSContainer>
    <SignInRHSContainer>
      {/*
        {tabIndex === 0 && <AccountTypeForm />}
        {tabIndex === 1 && <AccountSubTypeForm />}
        {tabIndex === 2 && <SignUpForm />}
      */}
      <SignUpForm />
    </SignInRHSContainer>
  </SignInParentContainer>
);
