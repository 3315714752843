import { LoginResponse } from "@syla/shared/types/responses/LoginResponse";
import axios from "axios";

export const login = async (
  email: string,
  password: string
): Promise<LoginResponse> => {
  const { data } = await axios.post("/users/login", {
    email,
    password,
  });
  return data;
};
