import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { ModalContext } from "../../../../contexts/ModalContext";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { Form, GeneralInput } from "../FormComponents";

interface NameSettingsForm {
  name: string;
}

export const NameForm = ({
  currentValue,
  isRequired,
  onSave,
}: {
  currentValue: string;
  isRequired: boolean;
  onSave: (newValue: string) => Promise<any>;
}) => {
  const [inputError, setInputError] = useState<string | undefined>();
  const { closeModal } = useContext(ModalContext);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<NameSettingsForm>();

  const onSubmit = async (data: NameSettingsForm) => {
    const { name } = data;
    setInputError(undefined);

    if (name === currentValue) {
      closeModal();
      return;
    }

    try {
      await onSave(name);
      closeModal();
    } catch (error) {
      setInputError("Unexpected error occurred, please try again later");
    }
  };

  return (
    <Flex direction="column" w="100%">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl
          isInvalid={inputError !== undefined}
          isRequired={isRequired}
        >
          <GeneralInput
            isRequired={isRequired}
            isSubmitting={isSubmitting}
            label="Display Name"
            type="text"
            placeholder="Type your name"
            {...register("name", { required: isRequired })}
            defaultValue={currentValue}
          />

          {inputError && <FormErrorMessage>{inputError}</FormErrorMessage>}
        </FormControl>

        <ButtonVariant
          content="Save changes"
          outlineType="solid"
          color="red"
          onClick={() => undefined}
          isLoading={isSubmitting}
          type="submit"
          spam="spam"
          mt="15px"
          mb="10px"
        />
      </Form>
    </Flex>
  );
};
