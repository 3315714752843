import { useQuery } from "react-query";
import { getSubscriptions } from "../api/plans/getSubscriptions";
import { useCurrentAccountStore } from "./currentAccountStore";

export const useQuerySubscriptions = () => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  return useQuery([...getSubscriptionQueryKey(accountId)], () =>
    getSubscriptions(accountId)
  );
};

export function getSubscriptionQueryKey(accountId: string) {
  return ["accounts", accountId, "subscriptions"];
}
