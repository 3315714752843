import {
  Flex,
  Text,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  MenuItemProps,
  Hide,
  Button,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { isBrowser } from "react-device-detect";
import { CgProfile } from "react-icons/cg";
import { FaCaretDown } from "react-icons/fa";
import { FiUser, FiLogOut } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { UserContext } from "../../contexts/UserContext";
import { isProduction } from "../../helper/environment";

import { buildUserSettingsLink } from "../../routers/routePaths";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { TOP_NAV_ZINDEX } from "./navbar/Navbar";

import { UpdatingStatus } from "./UpdatingStatus";

export const AccountBar = () => {
  const { logout } = useContext(UserContext);
  const { accountId, shortName: accountShortName } = useCurrentAccountStore(
    useShallow((state) => ({
      accountId: state.accountId,
      shortName: state.shortName,
    }))
  );

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="center"
      shrink={0}
      gap="20px"
      px={isBrowser ? { base: "20px", xs: "30px" } : "20px"}
      py="0.25rem"
      zIndex={TOP_NAV_ZINDEX}
      bgColor={isProduction() ? "white.500" : "yellow.200"}
      shadow="sm"
      borderBottom="1px"
      borderColor="gray.200"
    >
      <HStack alignItems="center" flexShrink={0} spacing={4}>
        <UpdatingStatus />
        <Text noOfLines={1}>{accountShortName}</Text>
      </HStack>
      <Menu>
        <MenuButton as={Button} size="sm" background="transparent">
          <HStack alignItems="center">
            <Box>
              <CgProfile fontSize="1.2rem" />
            </Box>
            <Hide below="sm">
              <Box>
                <FaCaretDown />
              </Box>
            </Hide>
          </HStack>
        </MenuButton>
        <MenuList p="0" m="10px 10px 0 0">
          <NavLink to={buildUserSettingsLink({ accountId })}>
            <AccountBarMenuItem icon={<FiUser fontSize="1.2rem" />}>
              User Profile
            </AccountBarMenuItem>
          </NavLink>
          <AccountBarMenuItem
            onClick={() => logout()}
            icon={<FiLogOut fontSize="1.2rem" />}
          >
            Logout
          </AccountBarMenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

const AccountBarMenuItem = (props: MenuItemProps) => (
  <MenuItem
    _focus={{ backgroundColor: "white.0" }}
    _hover={{ backgroundColor: "white.500" }}
    p="10px 0 10px 20px"
    color="black.700"
    fontSize="0.85rem"
    {...props}
  ></MenuItem>
);
