import { AssetType, AssetBase } from "../../types/models/AssetBase";
import { getShortContractAddress } from "./getShortContractAddress";

export const getAssetLongName = (
  {
    name,
    code,
    address,
    tokenId,
    type,
  }: Pick<AssetBase<never>, "type" | "name" | "code" | "address" | "tokenId">,
  removeSuffix = true
) => {
  // fall back name
  name = name.trim() || code.trim() || address?.trim() || "<unknown>";

  // remove code suffix in long name from CC
  // e.g. Bitcoin (BTC)
  if (removeSuffix) {
    name = name.replace(` (${code})`, "");
  }

  // add contract address
  name =
    address && name != address
      ? `${name} (${getShortContractAddress(address)})`
      : name;

  // add tokenId
  name = type == AssetType.NFT && tokenId ? `${name} #${tokenId}` : name;

  return name;
};
