import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const TRow = styled(Flex)`
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
`;

export const TCell = styled(Flex)`
  background-color: #fff;
  padding: 0 0 0 25px;
  font-size: 0.875rem;
  width: 25%;
  color: #4d4d4d;
  align-items: center;
`;
