import "./App.css";
import { History } from "history";
import React, { useContext, useMemo, useEffect } from "react";
import { Router, useLocation } from "react-router-dom";

import { UserContext } from "./contexts/UserContext";
import { IntercomIntegration } from "./helper/intercom/IntercomIntegration";
import { Authenticated } from "./routers/Authenticated";
import { Unauthenticated, UNAUTHED_PATHS } from "./routers/Unauthenticated";

const RouteByAuth = () => {
  const location = useLocation();
  const { loggedIn, logout, firstLogin } = useContext(UserContext);

  // handle transition from logged-in state to logged-out routes
  // exception is when firstLogin has been set (we have just authenticated)
  const shouldLogout = useMemo(
    () =>
      !firstLogin &&
      Object.values(UNAUTHED_PATHS).includes(location.pathname.toLowerCase()),
    [firstLogin, location.pathname]
  );

  useEffect(() => {
    if (loggedIn && shouldLogout) {
      console.debug("logging out");
      logout();
    }
  }, [shouldLogout, loggedIn, logout]);

  if (loggedIn) {
    // console.log("Route authed", { location: location });
    return <Authenticated />;
  }
  // console.log("Route unauthed", { location: location });
  return <Unauthenticated />;
};

export const App = ({ history }: { history: History }): JSX.Element => {
  return (
    <Router history={history}>
      <IntercomIntegration />
      <RouteByAuth />
    </Router>
  );
};
