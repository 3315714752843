import axios from "axios";

export const downloadBglXml = async (
  accountId: string,
  financialYearValue: string
): Promise<void> => {
  const { data, headers } = await axios.get(
    `/accounts/${accountId}/get-epi-xml`,
    {
      params: { financialYearValue },
      responseType: "blob",
    }
  );

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(data);
  link.setAttribute(
    "download",
    headers["content-disposition"]?.match(/filename="(.*)"/)?.at(1) ??
      `Epi Data ${financialYearValue.replace("/", "-")}.xml`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};
