import { Flex, Text, Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { BalanceDetail } from "../../../../helper/ledger/aggregateBalanceInLedgers";
import { NumberStyler } from "../../../../helper/NumberStyler";
import { useTransactionsStore } from "../../../../store/transactionsStore";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";
import { SkeletonRect } from "../../../atoms/Skeletons";

const getCellWidth = (divideByFour?: boolean) =>
  divideByFour ? "25%" : "33.33%";

export const BalanceTableRow = ({
  balanceDetail,
  showChange,
}: {
  balanceDetail: BalanceDetail;
  showChange?: boolean;
}): JSX.Element => {
  const isInvalid = useTransactionsStore((state) => state.refreshing);

  const cellWidth = getCellWidth(showChange);
  return (
    <TRow>
      {/* ----------------------------- Asset -----------------------------  */}
      <TCell width={cellWidth}>
        <ImageWithMissingSrc
          src={balanceDetail.asset.image}
          alt=""
          w="30px"
          mr="10px"
        />
        <Tooltip label={getAssetLongName(balanceDetail.asset)}>
          <Text noOfLines={1}>{getAssetShortName(balanceDetail.asset)}</Text>
        </Tooltip>
      </TCell>
      {/* ----------------------------- Change -----------------------------  */}
      {showChange && (
        <TCell width={cellWidth}>
          <NumberStyler num={balanceDetail.amount} colored />
        </TCell>
      )}
      {/* ----------------------------- Location Balance -----------------------------  */}
      <TCell width={cellWidth} justifyContent="left">
        <SkeletonRect isLoaded={!isInvalid}>
          <NumberStyler
            num={balanceDetail.walletBalance}
            assetInfo={balanceDetail.asset}
          />
        </SkeletonRect>
      </TCell>
      {/* ----------------------------- Porfolio Balance -----------------------------  */}
      <TCell width={cellWidth} justifyContent="left">
        <SkeletonRect isLoaded={!isInvalid}>
          <NumberStyler
            num={balanceDetail.balance}
            assetInfo={balanceDetail.asset}
          />
        </SkeletonRect>
      </TCell>
    </TRow>
  );
};

export const BalanceTableHeaders = ({
  showChange,
}: {
  showChange?: boolean;
}): JSX.Element => {
  const cellWidth = getCellWidth(showChange);
  return (
    <Flex
      borderWidth="1px 0 1px 0"
      w="100%"
      m="0 auto"
      backgroundColor="black.200"
    >
      <THeaderCell width={cellWidth}>
        <THeaderText>Asset</THeaderText>
      </THeaderCell>
      {showChange && (
        <THeaderCell width={cellWidth}>
          <THeaderText>Change</THeaderText>
        </THeaderCell>
      )}
      <THeaderCell width={cellWidth} justifyContent="left">
        <THeaderText>Location Balance</THeaderText>
      </THeaderCell>
      <THeaderCell width={cellWidth} justifyContent="left">
        <THeaderText>Portfolio Balance</THeaderText>
      </THeaderCell>
    </Flex>
  );
};

const THeaderCell = styled(Flex)`
  font-size: 0.75rem;
  padding: 5px 0 5px 25px;
`;

const THeaderText = styled(Text)`
  text-align: center;
`;

const TRow = styled(Flex)`
  border-bottom: 1px solid #f5f5f5;
  padding: 10px 0;
  width: 100%;
  margin: 0 auto;
  align-items: center;
`;

const TCell = styled(Flex)`
  background-color: #fff;
  padding: 0 0 0 25px;
  font-size: 0.875rem;
  color: #4d4d4d;
  align-items: center;
`;
