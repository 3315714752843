import { Flex, Text, useToast, Stack, StackProps } from "@chakra-ui/react";
import React, { useContext } from "react";
import { ModalContext } from "../../../../contexts/ModalContext";
import { deleteGroups } from "../../../../store/actions/deleteGroups";
import { deleteMatchingGroups } from "../../../../store/actions/groupList";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { OrderFilterSetting } from "../../../../types/order/orderQuery";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { BulkEdit } from "./bulkEdit/BulkEdit";
import { useSelectedTxContext } from "./SelectedTxContext";

export type SelectedTransactionsProps = {
  filterSetting: OrderFilterSetting;
};

export const SelectedTransactionsActions = ({
  filterSetting,
  ...stackProps
}: SelectedTransactionsProps & StackProps) => {
  const { openModal, setBtn2Loading, closeModal } = useContext(ModalContext);
  const toast = useToast();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const {
    selectedCount,
    setAllSelected,
    selectedIds,
    allSelected,
    clearSelected,
  } = useSelectedTxContext();

  return (
    <Stack
      direction="row"
      alignItems="center"
      m="0 20px 10px 20px"
      {...stackProps}
    >
      <Flex>
        <Text fontSize="0.875rem" fontWeight="700" color="red.500" mr="3px">
          {selectedCount?.toLocaleString()}{" "}
        </Text>
        <Text fontSize="0.875rem" color="black.600" mr="20px">
          transactions selected
        </Text>
      </Flex>
      <ButtonVariant
        content="Select All"
        outlineType="outlineRed"
        onClick={() => {
          setAllSelected(true);
        }}
        height="28px"
        fontSize="0.75rem"
        borderWidth="1px !important"
      />
      {/* Bulk Edit */}
      <ButtonVariant
        content="Edit"
        outlineType="outlineRed"
        onClick={() =>
          openModal({
            heading: "Edit Transactions",
            type: "component",
            component: (
              <BulkEdit
                filters={filterSetting}
                selectedCount={selectedCount}
                selectedIds={selectedIds}
                clearSelected={clearSelected}
              />
            ),
            size: "2xl",
          })
        }
        height="28px"
        fontSize="0.75rem"
        borderWidth="1px !important"
      />
      {/* <ButtonVariant
        content="Link transactions"
        outlineType="outlineRed"
        onClick={() =>
          openModal({
            type: "confirmation",
            icon: "research",
            heading: "Are you sure you want to link these transactions?",
            btn2Content: "Link",
            // eslint-disable-next-line no-alert
            btn2Action: async () => {
              setBtn2Loading(true);
              try {
                await linkOrders(selectedTransactions);
                await queryClient.invalidateQueries("orders");

                closeModal();
                clearSelection();
              } catch (error: any) {
                toast({
                  title: "Error Linking Transactions",
                  description: error?.response?.data || "Unexpected error.",
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                  position: "top-right",
                });
                setBtn2Loading(false);
              }
            },
          })
        }
        height="28px"
        fontSize="0.75rem"
        borderWidth="1px !important"
        mr="10px"
      /> */}
      {/* -------------------------- Delete Selected ---------------------- */}
      {!allSelected && (
        <ButtonVariant
          content="Delete"
          outlineType="outlineRed"
          onClick={() =>
            openModal({
              type: "confirmation",
              icon: "delete",
              heading: "Are you sure you want to delete these transactions?",
              contents: [
                "You will have to add them back in manually later if you require these transactions again",
              ],
              btn2Content: "Delete",
              btn2Action: async () => {
                setBtn2Loading(true);
                try {
                  await deleteGroups({
                    accountId,
                    groupIds: selectedIds,
                  });
                  closeModal();
                  clearSelected();
                } catch (error: any) {
                  toast({
                    title: "Error deleting transactions",
                    description: error?.response?.data || "Unexpected error.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                  });
                  setBtn2Loading(false);
                  closeModal();
                }
              },
            })
          }
          height="28px"
          fontSize="0.75rem"
          borderWidth="1px !important"
        />
      )}
      {/* -------------------------- Delete All ---------------------- */}
      {allSelected && (
        <ButtonVariant
          content="Delete"
          outlineType="outlineRed"
          onClick={() =>
            openModal({
              type: "confirmation",
              icon: "delete",
              heading: `Are you sure you want to delete all ${selectedCount?.toLocaleString()} transactions?`,
              contents: [
                "This will delete matching transactions across all pages. " +
                  "You will have to re-import them again if you require them later.",
              ],
              btn2Content: "Delete",
              btn2Action: async () => {
                setBtn2Loading(true);
                try {
                  await deleteMatchingGroups();
                  closeModal();
                } catch (error: any) {
                  toast({
                    title: "Error deleting transactions",
                    description: error?.response?.data || "Unexpected error.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: "top-right",
                  });
                  setBtn2Loading(false);
                  closeModal();
                }
              },
            })
          }
          height="28px"
          fontSize="0.75rem"
          borderWidth="1px !important"
        />
      )}

      <ButtonVariant
        content="Cancel"
        outlineType="outlineRed"
        onClick={clearSelected}
        height="28px"
        fontSize="0.75rem"
        borderWidth="1px !important"
      />
    </Stack>
  );
};
