/* eslint-disable no-alert */
import { useDisclosure } from "@chakra-ui/react";
import React, { useRef } from "react";
import { isBrowser } from "react-device-detect";

import { useLocation } from "react-router-dom";
import { PageContainer } from "../components/atoms/Containers";
import { AddDataSourceDrawer } from "../components/organisms/dataSource/addDataSourceDrawer/AddDataSourceDrawer";
import { MainDataSourceTable } from "../components/organisms/dataSource/mainDataSourceTable/MainDataSourceTable";

// state will exist if the user clicked on one of the Add Data Source
// boxes on an empty account.
export type DataSourceHistoryState = {
  openSidebar?: boolean;
};

export const DataSources = (): React.ReactElement => {
  const { state } = useLocation<DataSourceHistoryState>();

  const openSidebar = state?.openSidebar;

  // addDataSource state
  const {
    isOpen: isDrawerOpen,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure({ defaultIsOpen: !!openSidebar });

  // addDataSourceDrawer ref
  const addDataSourceBtn = useRef();

  return (
    <PageContainer isBrowser={isBrowser}>
      {/* ------------------- DataSource Body -------------------*/}
      {/* ------------------- Main Data Source Table -------------------*/}
      <MainDataSourceTable
        addDataSourceButtonRef={addDataSourceBtn}
        openDrawer={onOpenDrawer}
      />
      {/* ------------------- Drawer Panel -------------------*/}
      <AddDataSourceDrawer isOpen={isDrawerOpen} onClose={onCloseDrawer} />
    </PageContainer>
  );
};
