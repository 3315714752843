import {
  Text,
  VStack,
  HStack,
  useBreakpointValue,
  StackProps,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, createContext } from "react";
import {
  PageContainer,
  PageHeadingContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { SingleSelectBox } from "../components/atoms/singleSelectBox";
import { TaxReportsWarning } from "../components/molecules/TaxReportsWarning";
import { BglXmlSummary } from "../components/organisms/taxReport/BglXmlSummary";
import { SupportDocumentSummary } from "../components/organisms/taxReport/SupportDocumentSummary";
import { IncomeSummary } from "../components/organisms/taxReport/IncomeSummary";
import { TaxReportSummary } from "../components/organisms/taxReport/TaxReportSummary";
import { TransactionSummary } from "../components/organisms/taxReport/TransactionSummary";
import {
  setSelectedFinancialYear,
  setDefaultFinancialYear,
  useGetAccountFinancialYearsData,
} from "../store/actions/userFinancialYear";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { useGlobalVariablesStore } from "../store/globalVariablesStore";
import { useTransactionsStore } from "../store/transactionsStore";
import { useQueryGetAccount } from "../store/useQueryGetAccount";
import { useQueryGetAccountTransactionCount } from "../store/useQueryGetAccountTransactionCount";
import { useQuerySubscriptions } from "../store/useQuerySubscriptions";
import { Account } from "../types/Account";
import { Subscription } from "../types/plans/subscription";
import { UpsellCard } from "./UpsellCard";

const CARD_SPACING = 4;

export const TaxReports = () => {
  const { data: userFinancialYearsData } = useGetAccountFinancialYearsData();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );

  useEffect(() => {
    setDefaultFinancialYear(selectedFinancialYear, userFinancialYearsData);
  }, [selectedFinancialYear, userFinancialYearsData]);

  const isCalculating = useTransactionsStore((state) => state.updating);

  const { data: getSubscriptionsResponse, isLoading: subscriptionsLoading } =
    useQuerySubscriptions();
  const subscriptions = getSubscriptionsResponse?.subscriptions ?? [];

  const { data: txCount, isLoading: txCountLoading } =
    useQueryGetAccountTransactionCount();

  const isLoading = subscriptionsLoading || txCountLoading || accountIsLoading;

  const multiColumn = useBreakpointValue({
    base: false,
    "2xs": false,
    xs: false,
    md: false,
    lg: true,
  });

  return (
    <PageContainer>
      <PageHeadingContainer>
        <PageHeading>Tax Reports</PageHeading>
      </PageHeadingContainer>

      <Flex direction="column" pb={"30px"}>
        {isCalculating && <TaxReportsWarning mb="30px" />}

        {/* Main content container */}
        <VStack alignItems={"flex-start"} spacing={CARD_SPACING}>
          {/* Financial Year Selector */}
          <VStack>
            <Text
              fontSize="1.125rem"
              fontWeight="bold"
              color="black.700"
              mb="5px"
            >
              Financial Year
            </Text>
            <SingleSelectBox
              options={userFinancialYearsData ?? []}
              onChangeSelection={(selection) =>
                setSelectedFinancialYear(
                  userFinancialYearsData?.find(
                    (financialYear) => financialYear.value === selection
                  )
                )
              }
              selectedOption={selectedFinancialYear?.value}
              placeholder="Loading..."
              optionContainerProps={{
                right: "0",
                top: "45px",
                zIndex: 3,
              }}
              selectBtnProps={{
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }}
            />
          </VStack>
          {/* Cards container */}
          <HStack
            alignItems={"flex-start"}
            alignContent={"flex-start"}
            w={"100%"}
            spacing={CARD_SPACING}
          >
            <TaxReportsContext.Provider
              value={{
                account: account,
                isCalculating,
                isLoading,
                txCount,
                subscriptions,
                selectedFinancialYear: selectedFinancialYear?.value,
              }}
            >
              {/* Desktop layout - 2 column */}
              {multiColumn ? (
                <>
                  <CardVStack flexBasis={"50%"}>
                    <IncomeSummary
                      selectedFinancialYear={selectedFinancialYear}
                      taxableIncome={true}
                    />
                    <IncomeSummary
                      selectedFinancialYear={selectedFinancialYear}
                      taxableIncome={false}
                    />
                    <TransactionSummary
                      selectedFinancialYear={selectedFinancialYear}
                    />
                  </CardVStack>
                  <CardVStack flexBasis={"40%"}>
                    <UpsellCard />

                    {/* ------------------------------ Tax Report Summary ------------------------------  */}
                    <TaxReportSummary />
                    {/* ------------------------------ Support Documents ------------------------------  */}
                    <SupportDocumentSummary />
                    {/* ------------------------------ Premium Integrations ------------------------------  */}
                    <BglXmlSummary />
                  </CardVStack>
                </>
              ) : (
                <>
                  {/* Mobile layout - 1 column */}
                  <CardVStack w={"100%"}>
                    <UpsellCard />
                    <IncomeSummary
                      selectedFinancialYear={selectedFinancialYear}
                      taxableIncome={true}
                    />
                    <IncomeSummary
                      selectedFinancialYear={selectedFinancialYear}
                      taxableIncome={false}
                    />
                    <TaxReportSummary />
                    <SupportDocumentSummary />
                    <BglXmlSummary />
                    <TransactionSummary
                      selectedFinancialYear={selectedFinancialYear}
                    />
                  </CardVStack>
                </>
              )}
            </TaxReportsContext.Provider>
          </HStack>
        </VStack>
      </Flex>
    </PageContainer>
  );
};

const CardVStack = ({ children, ...props }: StackProps) => (
  <VStack alignItems={"flex-start"} spacing={CARD_SPACING} {...props}>
    {children}
  </VStack>
);

export const TaxReportsContext = createContext<TaxReportsContextState>({
  isCalculating: false,
  isLoading: false,
  subscriptions: [],
  txCount: undefined,
  account: undefined,
  selectedFinancialYear: undefined,
});

export type TaxReportsContextState = {
  isCalculating: boolean;
  isLoading: boolean;
  subscriptions: Subscription[];
  txCount: number | undefined;
  account: Account | undefined;
  selectedFinancialYear: string | undefined;
};
