import { throwError } from "@syla/shared/types/helpers/errors";
import Axios from "axios";
import { WritableDraft } from "immer/dist/types/types-external";
import {
  UpdateTransaction,
  updateTransaction as updateTransactionRequest,
} from "../../api/transactions/updateTransaction";
import { UnexpectedErrorString } from "../../components/atoms/UnexpectedError";
import { captureRequestError } from "../../helper/captureRequestError";
import { Transaction } from "../../types/transaction";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import {
  useTransactionsStore,
  TransactionStoreState,
} from "../transactionsStore";
import {
  refreshGroupList,
  updateTransactionInQueryCache,
  getGroupByTransactionId,
  invalidateTransactionQueryCache,
} from "./groupList";

export const updateTransaction = async ({
  accountId,
  id,
  updates: transactionUpdates,
}: UpdateTransaction) => {
  const originalTransaction = getTransactionState(
    useTransactionsStore.getState(),
    id
  ).data;

  // // optimistic updates
  // useTransactionsStore.setState((state) => {
  //   const transactionState = getTransactionState(state, id);
  //
  //   transactionState.updating = true;
  //   const transaction = transactionState.data;
  //
  //   if (transactionUpdates.description)
  //     transaction.description = transactionUpdates.description;
  //
  //   transactionUpdates.updateLedgers?.forEach((updateLedger) => {
  //     const ledger =
  //       transaction.ledgers.find((l) => l._id == updateLedger._id) ??
  //       throwError(new Error("missing ledger"));
  //
  //     if (updateLedger.type) ledger.type = updateLedger.type;
  //     if (updateLedger.amount) {
  //       const newAmount = Decimal.from(updateLedger.amount);
  //       // fix sign
  //       ledger.amount = isReceiveType(ledger) ? newAmount : newAmount.negated();
  //     }
  //
  //     if (updateLedger.marketValue)
  //       ledger.marketValue = Decimal.from(updateLedger.marketValue);
  //
  //     if (updateLedger.assetId) {
  //       ledger.asset =
  //         state.assets?.[updateLedger.assetId] ??
  //         throwError(
  //           new Error(`Asset with id ${updateLedger.assetId} not found`)
  //         );
  //     }
  //   });
  //   setTransactionInGroups(state, transaction, true);
  // });
  //

  // persist updates
  try {
    useTransactionsStore.setState((state) => {
      const transactionState = getTransactionState(state, id);
      transactionState.updating = true;
    });
    const updatedTransaction = await updateTransactionRequest({
      accountId,
      id,
      updates: transactionUpdates,
    });

    useTransactionsStore.setState((state) => {
      const transactionState = getTransactionState(state, id);
      // update new state
      transactionState.data = updatedTransaction;
      transactionState.updating = false;
      transactionState.error = undefined;
      setTransactionInGroups(state, updatedTransaction, false);
    });
    updateTransactionInQueryCache(id);
    invalidateTransactionQueryCache(accountId);

    refreshGroupList();

    checkStatusAndDoPendingWork();
  } catch (error) {
    captureRequestError(error);

    try {
      useTransactionsStore.setState((state) => {
        const transactionState = getTransactionState(state, id);
        transactionState.updating = false;
        transactionState.error = Axios.isAxiosError(error)
          ? error.response?.data
          : UnexpectedErrorString;

        // rollback updates
        transactionState.data = originalTransaction;
      });
    } catch (error) {
      captureRequestError(error);
    }

    // refresh status from server in case of partial updates
    refreshGroupList();
  }
};

const setTransactionInGroups = (
  state: Pick<
    WritableDraft<TransactionStoreState>,
    "transactionToGroup" | "groups"
  >,
  transaction: WritableDraft<Transaction>,
  updating: boolean
) => {
  const transactionId = transaction._id;

  const { groupState } = getGroupByTransactionId(state, transactionId);

  const group = groupState.data;

  // update transaction
  for (let index = 0; index < group.transactions.length; index++) {
    if (group.transactions[index]._id == transactionId) {
      group.transactions[index] = transaction;
      break;
    }
  }

  groupState.updating = updating;
};

function getTransactionState(
  state: Pick<
    WritableDraft<TransactionStoreState> | TransactionStoreState,
    "transactions"
  >,
  id: string
) {
  return (
    state.transactions?.[id] ??
    throwError(new Error(`Transaction ${id} not tracked`))
  );
}
