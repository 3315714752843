"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDecimal = exports.parseDate = exports.parseOptional = void 0;
const decimal_1 = require("../../decimal");
function parseOptional(value, parser, treatedAsNull = undefined) {
    if (value != null && (!treatedAsNull || !treatedAsNull(value)))
        return parser(value);
}
exports.parseOptional = parseOptional;
const parseDate = (val) => {
    const date = new Date(val);
    if (isNaN(date.valueOf()))
        throw new Error(`Invalid date when casting value '${val}'`);
    return date;
};
exports.parseDate = parseDate;
const parseDecimal = (val) => decimal_1.Decimal.from(val);
exports.parseDecimal = parseDecimal;
