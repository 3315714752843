import React, { createContext, useState, ReactNode, useCallback } from "react";

import type { IModalInfo } from "../react-app-env";

interface IModalContext {
  modalIsOpen: boolean;
  modalInfo: IModalInfo | undefined;
  openModal: (modalInfo: IModalInfo) => void;
  closeModal: () => void;
  setBtn1Loading: (newState: boolean) => void;
  setBtn2Loading: (newState: boolean) => void;
}

const InitialModalContext: IModalContext = {
  modalIsOpen: false,
  modalInfo: { type: "alert", heading: "Invalid Action", contents: [] },
  openModal: () => {},
  closeModal: () => {},
  setBtn1Loading: () => {},
  setBtn2Loading: () => {},
};

export const ModalContext = createContext<IModalContext>(InitialModalContext);

export const ModalContextProvider = ({ children }: { children: ReactNode }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState<IModalInfo>();

  const openModal = useCallback(
    ({
      type,
      icon,
      heading,
      contents,
      btn1Content,
      btn2Content,
      btn1Action,
      btn2Action,
      btn1Loading,
      btn2Loading,
      component,
      hideCloseButton,
      dontCloseOnOverlayClick,
      size,
      confirmation,
    }: IModalInfo) => {
      setModalInfo({
        type,
        icon,
        heading,
        contents,
        btn1Content,
        btn2Content,
        btn1Action,
        btn2Action,
        btn1Loading,
        btn2Loading,
        component,
        hideCloseButton,
        dontCloseOnOverlayClick,
        size,
        confirmation,
      });
      setModalIsOpen(true);
    },
    []
  );

  const closeModal = useCallback(() => {
    setModalIsOpen(false);
    setModalInfo(undefined);
  }, []);

  const setBtn1Loading = useCallback((newBtn1Loading: boolean) => {
    setModalInfo((oldModalInfo) =>
      oldModalInfo
        ? {
            ...oldModalInfo,
            btn1Loading: newBtn1Loading,
          }
        : undefined
    );
  }, []);

  const setBtn2Loading = useCallback((newBtn2Loading: boolean) => {
    setModalInfo((oldModalInfo) =>
      oldModalInfo
        ? {
            ...oldModalInfo,
            btn2Loading: newBtn2Loading,
          }
        : undefined
    );
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalIsOpen,
        modalInfo,
        openModal,
        closeModal,
        setBtn1Loading,
        setBtn2Loading,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
