import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Address } from "@syla/shared/types/models/AccountBase";
import React, { useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { ModalContext } from "../../../../contexts/ModalContext";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useMutationUpdateAccount } from "../../../../store/useMutationUpdateAccount";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { SingleSelectBox } from "../../../atoms/singleSelectBox";
import { Form, Label, GeneralInput } from "../FormComponents";

interface AddressSettingHookForm {
  address: Address;
}

export const AddressForm = ({
  currentAddress,
}: {
  currentAddress: Address;
}) => {
  const [inputError, setInputError] = useState<string | undefined>();
  const { closeModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(accountId);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<AddressSettingHookForm>({
    defaultValues: {
      address: {
        street: currentAddress.street,
        suburb: currentAddress.suburb,
        postcode: currentAddress.postcode,
        state: currentAddress.state,
      },
    },
  });

  const onSubmit = async (data: AddressSettingHookForm) => {
    const { address } = data;
    setInputError(undefined);

    if (
      address.street === currentAddress.street &&
      address.suite === currentAddress.suite &&
      address.suburb === currentAddress.suburb &&
      address.state === currentAddress.state &&
      address.postcode === currentAddress.postcode
    ) {
      closeModal();
      return;
    }

    try {
      await updateAccount({
        address: {
          street: address.street,
          suite: address.suite,
          suburb: address.suburb,
          state: address.state,
          postcode: address.postcode,
        },
      });
      closeModal();
    } catch (error) {
      setInputError("Unexpected error occurred, please try again later");
    }
  };

  return (
    <Flex direction="column" w="100%">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <GeneralInput
          label="Street address"
          type="text"
          {...register("address.street", {
            required: "Street address is missing",
          })}
          placeholder="Start typing your street address in here..."
          defaultValue={currentAddress.street}
          isSubmitting={isSubmitting}
          fieldError={errors.address?.street}
          isRequired
        />

        <GeneralInput
          label="apartment/suite #"
          type="text"
          {...register("address.suite")}
          placeholder="Start typing your apt / suite in here..."
          defaultValue={currentAddress.suite}
          isSubmitting={isSubmitting}
        />

        <GeneralInput
          label="City/suburb"
          placeholder="Start typing your city / suburb in here..."
          type="text"
          {...register("address.suburb", {
            required: "City / suburb is missing",
          })}
          defaultValue={currentAddress.suburb}
          isSubmitting={isSubmitting}
          isRequired
          fieldError={errors.address?.suburb}
        />

        <Flex justifyContent="space-between">
          <FormControl
            w="49%"
            isDisabled={isSubmitting}
            isInvalid={errors.address !== undefined}
            isRequired
          >
            <Label content="STATE" />
            <Controller
              control={control}
              name="address.state"
              rules={{ required: "State is missing" }}
              render={({ field: { onChange, value } }) => (
                <SingleSelectBox
                  options={[
                    { value: "ACT", label: "ACT" },
                    { value: "NSW", label: "NSW" },
                    { value: "NT", label: "NT" },
                    { value: "QLD", label: "QLD" },
                    { value: "SA", label: "SA" },
                    { value: "TAS", label: "TAS" },
                    { value: "VIC", label: "VIC" },
                    { value: "WA", label: "WA" },
                  ]}
                  selectedOption={value}
                  placeholder="State"
                  onChangeSelection={(selection) => onChange(selection)}
                  required={errors.address?.state !== undefined}
                  selectBtnProps={{ height: "40px" }}
                />
              )}
            />
            {errors.address?.state && (
              <FormErrorMessage>
                {errors.address?.state?.message}
              </FormErrorMessage>
            )}
          </FormControl>
          <Flex w="49%">
            <GeneralInput
              label="postcode"
              placeholder="Enter postcode"
              type="text"
              {...register("address.postcode", {
                required: "Postcode is missing",
              })}
              defaultValue={currentAddress.postcode}
              isSubmitting={isSubmitting}
              isRequired
              fieldError={errors.address?.postcode}
            />
          </Flex>
        </Flex>
        {inputError && (
          <FormControl isInvalid={inputError !== undefined}>
            <FormErrorMessage>{inputError}</FormErrorMessage>
          </FormControl>
        )}
        <ButtonVariant
          content="Save changes"
          outlineType="solid"
          color="red"
          onClick={() => undefined}
          isLoading={isSubmitting}
          type="submit"
          spam="spam"
          mt="15px"
          mb="10px"
        />
      </Form>
    </Flex>
  );
};
