import React from "react";
import { Flex, Text, Spinner } from "@chakra-ui/react";

export const LoadingIndicator = ({ content }: { content: string }) => (
  <Flex justifyContent="center" alignItems="center" h="100%" maxH="500px">
    <Text
      mr="20px"
      fontSize="1.1rem"
      fontWeight="bold"
      textTransform="capitalize"
    >
      {content}
    </Text>
    <Spinner />
  </Flex>
);
