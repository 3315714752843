import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { RefreshTokenResponseBody } from "@syla/shared/types/responses/RefreshTokenResponse";
import axios from "axios";

export const refreshUrl = "/users/refresh";

export const refresh = async (
  refreshToken: string
): Promise<TransportType<RefreshTokenResponseBody>> => {
  const { data } = await axios.post(refreshUrl, {
    refreshToken,
  });
  return data;
};
