import {
  Flex,
  Button,
  Text,
  Spacer,
  Stack,
  Switch,
  useDisclosure,
  Box,
  useOutsideClick,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { FinancialYear } from "@syla/shared/types/models/FinancialYear";
import {
  LedgerType,
  isReceiveType,
} from "@syla/shared/types/models/LedgerBase";
import {
  TransactionTagType,
  TransactionType,
} from "@syla/shared/types/models/TransactionBase";
import { StatusOption } from "@syla/shared/types/requests/GetGroupsRequest";
import { addDays, format, addMinutes, addMilliseconds } from "date-fns";
import { intersection, difference, isArray, orderBy } from "lodash";
import { RangeValue } from "rc-picker/lib/interface";
import React, {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import { FaCaretDown } from "react-icons/fa";
import { useQuery } from "react-query";
import { getAccountTransactionTypes } from "../../../../api/transactions/getAccountTransactionTypes";
import { getTransactionIconAndText } from "../../../../helper/transaction/getTransactionIconAndText";
import { useGetWalletOptions } from "../../../../hooks/wallet/useGetWalletOptions";
import { useGetAccountFinancialYearsData } from "../../../../store/actions/userFinancialYear";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import {
  useTransactionsStore,
  getAccountTransactionsCacheKey,
} from "../../../../store/transactionsStore";
import { useQueryAccountAssetOptions } from "../../../../store/useQueryAccountAssetOptions";
import { OrderFilterSetting } from "../../../../types/order/orderQuery";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { DatePicker, rangePickerStyles } from "../../../atoms/DatePicker";
import { sylaSuggestionsText } from "../../../atoms/NeedsReview";
import { SingleSelectBox } from "../../../atoms/singleSelectBox";
import { HEADER_Z_INDEX } from "../../../atoms/StickyThead";
import { MultiThumbnailSelectBox } from "../../../atoms/thumbnailSelectBoxVariant/multiThumbnailSelectBox/MultiThumbnailSelectBox";
import { ImportFilterSelector } from "../ImportFilterSelector";
import { maxAssetsInView } from "../ledgerInputConstants";
import { defaultOrderFilterState } from "./TransactionsView";

const { RangePicker } = DatePicker;

interface FilterSectionProps {
  filterSetting: OrderFilterSetting;
  setFilterSetting: Dispatch<SetStateAction<OrderFilterSetting>>;
}

const TYPE_FILTER_ORDER: TransactionTagType[] = [
  LedgerType.Deposit,
  LedgerType.Withdrawal,
  LedgerType.TransferIn,
  LedgerType.TransferOut,
  TransactionType.Trade,
  TransactionType.Wrap,
  TransactionType.Mint,
  TransactionType.Rebase,
  TransactionType.RemoveLiquidity,
  TransactionType.AddLiquidity,
];

export const FilterSection = ({
  filterSetting,
  setFilterSetting,
}: FilterSectionProps): JSX.Element => {
  const dateFilter = filterSetting.date;

  const {
    isOpen: isDateFilterBtnOpen,
    onToggle: onDateFilterBtnToggle,
    onClose: onDataFilterBtnClose,
  } = useDisclosure({ defaultIsOpen: false });

  // Custom component date filter button ref
  const dateFilterBtnRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dateFilterBtnRef,
    handler: () => onDataFilterBtnClose(),
  });

  const formatDateSelection = useMemo(() => {
    const dateRange = filterSetting.date?.range;
    if (dateRange && dateRange[0] && dateRange[1]) {
      return `${format(dateRange[0], "d MMM yyyy")} - ${format(
        dateRange[1],
        "d MMM yyyy"
      )}`;
    }
    return "Date";
  }, [filterSetting.date?.range]);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const walletOptions = useGetWalletOptions(accountId)?.data;

  // query for this user's assets
  const { data: userAssetsData } = useQueryAccountAssetOptions(accountId);

  const { data: userFinancialYearsData } = useGetAccountFinancialYearsData();

  // ledger types
  const { data: accounTransactionTypes } = useQuery(
    [...getAccountTransactionsCacheKey(accountId), "account-transaction-types"],
    () => getAccountTransactionTypes(accountId)
  );

  const ledgerTypeOptions = useMemo(
    () =>
      accounTransactionTypes &&
      orderBy(accounTransactionTypes, [
        // sort according to TYPE_FILTER_ORDER
        ({ type }) =>
          TYPE_FILTER_ORDER.includes(type)
            ? TYPE_FILTER_ORDER.indexOf(type)
            : 99,
        // then put Receive types before Send and other
        ({ type }) => (isReceiveType({ type: type as LedgerType }) ? 0 : 1),
      ]).map<SelectOptionType>((t) => {
        const { icon, text } = getTransactionIconAndText(t);
        return {
          value: t.type,
          label: text,
          icon,
        };
      }),
    [accounTransactionTypes]
  );

  useEffect(() => {
    if (!ledgerTypeOptions) return;
    const validLedgerTypes = ledgerTypeOptions.map(
      (lt) => lt.value as TransactionTagType
    );

    if (
      filterSetting.ledgerType.length &&
      difference(filterSetting.ledgerType, validLedgerTypes).length
    )
      setFilterSetting({
        ...filterSetting,
        ledgerType: intersection(filterSetting.ledgerType, validLedgerTypes),
      });
  }, [filterSetting, ledgerTypeOptions, setFilterSetting]);

  const filtersActive = useMemo(() => {
    const appliedFilters = Object.values(filterSetting).filter((filterValue) =>
      isArray(filterValue) ? filterValue.length : filterValue
    );
    return !!appliedFilters.length;
  }, [filterSetting]);
  useEffect(() => {
    useTransactionsStore.setState((state) => {
      state.filtering = filtersActive;
    });
  }, [filtersActive]);

  // mobile filter by toggle
  const [showFilters, setShowFilters] = useState(false);
  const showFilterSwitch = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    xs: true,
    "2xs": true,
  });

  return (
    <Stack
      direction={showFilterSwitch ? "column" : "row"}
      w="100%"
      alignItems={showFilterSwitch ? "left" : "center"}
      p="0 15px 15px 15px"
      bgColor="white.0"
      wrap="wrap"
      gridGap="5px"
    >
      <Stack direction="row" justifyContent="space-between">
        <Text
          mr="20px"
          color="black.550"
          fontSize="0.875rem"
          whiteSpace="nowrap"
        >
          Filter by:
        </Text>
        {showFilterSwitch && (
          <Switch
            size="md"
            colorScheme="red"
            onChange={() => setShowFilters(!showFilters)}
            padding="2px 5px 0px 10px"
          />
        )}
      </Stack>
      {(!showFilterSwitch || showFilters) && (
        <>
          {/*/!* -------------------------- Event ---------------------- *!/*/}
          {/*<MultiThumbnailSelectBox*/}
          {/*  selectedOptions={filterSetting.transactionType}*/}
          {/*  onChangeSelection={(selection) =>*/}
          {/*    setFilterSetting((oldState) => ({*/}
          {/*      ...oldState,*/}
          {/*      transactionType: selection as TransactionType[],*/}
          {/*    }))*/}
          {/*  }*/}
          {/*  options={getTransactionTypeOptions}*/}
          {/*  placeholder="Event"*/}
          {/*  mr="10px"*/}
          {/*  selectBtnProps={{ height: "30px" }}*/}
          {/*  optionContainerProps={{*/}
          {/*    width: "200px",*/}
          {/*    top: "30px",*/}
          {/*    zIndex: HEADER_Z_INDEX + 1,*/}
          {/*  }}*/}
          {/*/>*/}
          {/* -------------------------- TYPE ---------------------- */}
          <MultiThumbnailSelectBox
            selectedOptions={filterSetting.ledgerType}
            onChangeSelection={(selection) =>
              setFilterSetting((oldState) => ({
                ...oldState,
                ledgerType: selection as LedgerType[],
              }))
            }
            options={ledgerTypeOptions ?? []}
            maxOptionsInView={10}
            searchEnable
            placeholder="Type"
            mr="10px"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "300px",
              top: "30px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />
          {/* -------------------------- Data Source ---------------------- */}
          <MultiThumbnailSelectBox
            searchEnable
            selectedOptions={filterSetting.dataSource}
            onChangeSelection={(selection) =>
              setFilterSetting((oldState) => ({
                ...oldState,
                dataSource: selection,
              }))
            }
            options={walletOptions || []}
            maxOptionsInView={5}
            placeholder="Data Source"
            mr="10px"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "300px",
              top: "30px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />
          {/* -------------------------- Asset ---------------------- */}
          <MultiThumbnailSelectBox
            doubleRows
            selectedOptions={filterSetting.asset}
            onChangeSelection={(selection) =>
              setFilterSetting((oldState) => ({
                ...oldState,
                asset: selection,
              }))
            }
            options={userAssetsData ?? []}
            maxOptionsInView={maxAssetsInView}
            searchEnable
            placeholder="Asset"
            mr="10px"
            mb="8px"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "450px",
              top: "30px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />
          {/* -------------------------- Import ---------------------- */}
          <ImportFilterSelector
            selectedItems={filterSetting.imports}
            setSelectedItems={(values) =>
              setFilterSetting((state) => ({ ...state, imports: values }))
            }
            buttonProps={{ height: "30px" }}
            smallScreen={showFilterSwitch}
          />
          {/* -------------------------- Status ---------------------- */}
          <MultiThumbnailSelectBox
            showIcon={false}
            selectedOptions={filterSetting.status}
            onChangeSelection={(selection: any) =>
              setFilterSetting((oldState) => ({
                ...oldState,
                status: selection,
              }))
            }
            options={[
              {
                label: sylaSuggestionsText,
                value: StatusOption.NeedsReview,
              },
            ]}
            placeholder="Status"
            selectBtnProps={{ height: "30px" }}
            optionContainerProps={{
              width: "200px",
              top: "40px",
              zIndex: HEADER_Z_INDEX + 1,
            }}
          />

          {/* -------------------------- Date ---------------------- */}

          <Box position="relative" ref={dateFilterBtnRef} ml="10px">
            <Button
              h="30px"
              bgColor={dateFilter ? "white.500" : "white.0"}
              borderColor={dateFilter ? "black.1000" : "gray.200"}
              borderWidth="1px"
              _hover={{
                bgColor: "white.500",
              }}
              _active={{
                bgColor: "black.200",
              }}
              onClick={() => onDateFilterBtnToggle()}
              w="100%"
            >
              <Stack direction="row" justifyContent="space-between" w="100%">
                <Text
                  fontWeight="500"
                  fontSize="0.875rem"
                  color="black.550"
                  mr="10px"
                >
                  {formatDateSelection}
                </Text>
                <FaCaretDown color="#808080" />
              </Stack>
            </Button>
            {isDateFilterBtnOpen && (
              <Flex
                position="absolute"
                zIndex={HEADER_Z_INDEX + 1}
                bgColor="white.0"
                w={showFilterSwitch ? "100%" : "500px"}
                direction="column"
                borderWidth="1px"
                borderColor="white.500"
                top="40px"
                left={showFilterSwitch ? "0px" : "-400px"}
                borderRadius="5px"
                boxShadow=" 0px 4px 30px rgba(0, 0, 0, 0.08)"
                py="5px"
              >
                <Flex
                  h="45px"
                  alignItems="center"
                  px="15px"
                  justifyContent="space-between"
                >
                  <Text color="black.700" fontSize="0.875rem">
                    Financial Year
                  </Text>
                  <SingleSelectBox
                    allowUnSelect
                    options={userFinancialYearsData ?? []}
                    selectedOption={dateFilter?.financialYear?.value}
                    onChangeSelection={(selection) => {
                      setFilterSetting((state) => ({
                        ...state,
                        date: selection
                          ? {
                              financialYear: new FinancialYear({
                                value: selection,
                              }),
                            }
                          : undefined,
                      }));
                    }}
                    placeholder="Select a financial year..."
                    w="255px"
                    selectBtnProps={{
                      height: "30px",
                      borderColor: "white.500",
                    }}
                    optionContainerProps={{ top: "40px", color: "#4d4d4d" }}
                    selectBtnTextProps={{ fontWeight: "normal" }}
                  />
                </Flex>
                <Flex
                  h="45px"
                  alignItems="center"
                  px="15px"
                  justifyContent="space-between"
                >
                  <Text color="black.700" fontSize="0.875rem">
                    Date Range
                  </Text>
                  <div id="datePickerDiv">
                    <Global styles={rangePickerStyles} />
                    <RangePicker
                      value={
                        dateFilter?.financialYear
                          ? selectedDateRangeToFilterDateRange([
                              dateFilter.financialYear.startDate,
                              dateFilter.financialYear.nextYearStartDate,
                            ])
                          : dateFilter?.range
                      }
                      onChange={(date) => {
                        // ant date picker's issue
                        // first time it will use current date time of hours/minutes
                        if (date) {
                          date[0]?.setUTCHours(0, 0, 0, 0);
                          date[1]?.setUTCHours(0, 0, 0, 0);
                        }

                        setFilterSetting((oldState) => ({
                          ...oldState,
                          date: date ? { range: date } : undefined,
                        }));
                      }}
                      format="dd MMM yyyy"
                      getPopupContainer={() =>
                        document.getElementById("datePickerDiv") as HTMLElement
                      }
                      suffixIcon
                      style={{
                        borderRadius: "5px",
                        borderColor: "#f5f5f5 !important",
                      }}
                    />
                  </div>
                </Flex>
              </Flex>
            )}
          </Box>
        </>
      )}
      <Spacer />
      {/* -------------------------- Clear Filters ---------------------- */}
      {filtersActive && (
        <ButtonVariant
          content="Clear Filters"
          outlineType="ghost"
          onClick={() => {
            setFilterSetting(defaultOrderFilterState);
          }}
          fontSize="0.875rem"
          h="28px"
          fontWeight="normal"
        />
      )}
    </Stack>
  );
};

/** Convert api range with exclusive end date to datepicker range */
const selectedDateRangeToFilterDateRange = (
  dateRange: RangeValue<Date>
): RangeValue<Date> =>
  dateRange && dateRange[0] && dateRange[1]
    ? [
        shiftTimeFromBrisbane(dateRange[0]),
        shiftTimeFromBrisbane(addDays(addMilliseconds(dateRange[1], 1), -1)),
      ]
    : null;

const shiftTimeFromBrisbane = (date: Date): Date => addMinutes(date, 600); // +10:00 is 600 minutes
