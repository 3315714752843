export const SylaSampleUrls = {
  TaxReportIndividual:
    "https://s3.ap-southeast-2.amazonaws.com/static.syla.com.au/document/Syla%20-%20Individual%20Tax%20Report%20FY2021-22%20-%20SAMPLE%20ONLY.pdf",
  TaxReportSMSF:
    "https://s3.ap-southeast-2.amazonaws.com/static.syla.com.au/document/Syla%20-%20SMSF%20Tax%20Report%20FY2021-22%20-%20SAMPLE%20ONLY.pdf",
  Transactions:
    "https://s3.ap-southeast-2.amazonaws.com/static.syla.com.au/document/Syla%20-%20Transactions%202022%20FY%20-%20SAMPLE%20ONLY.csv",
  TaxOutcomes:
    "https://s3.ap-southeast-2.amazonaws.com/static.syla.com.au/document/Syla%20-%20Tax%20Outcomes%202022%20FY%20-%20SAMPLE%20ONLY.csv",
  CostBaseRemainingAssets:
    "https://s3.ap-southeast-2.amazonaws.com/static.syla.com.au/document/Syla%20-%20Cost%20Base%20of%20Remaining%20Assets%20-%20SAMPLE%20ONLY.csv",
};
