import { DataSourceBase } from "../models/DataSourceBase";
import { parseDate, parseOptional } from "./parsing";
import { TransportType } from "./TransportType";
import { WithModelIds } from "./WithModelIds";

export function parseDataSource(
  dataSource: TransportType<WithModelIds<DataSourceBase<string>>>
): WithModelIds<DataSourceBase<string>> {
  return {
    ...dataSource,
    createdAt: parseOptional(dataSource.createdAt, parseDate),
    updatedAt: parseOptional(dataSource.updatedAt, parseDate),
  };
}
