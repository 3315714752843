import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Text,
  Link,
  HStack,
  Grid,
  Box,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  useToast,
  Heading,
  HeadingProps,
  StackProps,
} from "@chakra-ui/react";
import { Decimal, sumWithDecimal } from "@syla/shared/decimal";
import { financialYearToDateRange } from "@syla/shared/helpers/financialYear";
import { getCurrentSubscription } from "@syla/shared/types/helpers/subscriptions";
import { WithModelIds } from "@syla/shared/types/helpers/WithModelIds";
import {
  AccountSubType,
  AccountType,
  AccountTypeCGTDiscount,
} from "@syla/shared/types/models/AccountBase";
import { PlanBase } from "@syla/shared/types/models/PlanBase";
import { TaxYearResultBase } from "@syla/shared/types/models/TaxYearResultBase";
import React, { Fragment, useCallback, useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import {
  ButtonVariant,
  ButtonVariantProps,
} from "../components/atoms/ButtonVariant";
import { StdVStack } from "../components/atoms/Containers";
import { TooltipWrapper } from "../components/atoms/TooltipWrapper";
import {
  ActionCard,
  CARD_FULL_WIDTH,
} from "../components/molecules/ActionCard";
import { errorToastOptions } from "../components/molecules/errorToastOptions";
import { Feature } from "../components/molecules/PlanCard";
import { BILLING_OWNER_REQUIRED_MESSAGE } from "../components/organisms/taxReport/ActionOrPurchaseButton";
import { getFullPageUrl } from "../helper/getFullPageUrl";
import { canUnlockPlanFeature } from "../helper/canUnlockPlanFeature";
import { NumberStyler } from "../helper/NumberStyler";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { useGlobalVariablesStore } from "../store/globalVariablesStore";
import { Account } from "../types/Account";
import { Subscription } from "../types/plans/subscription";
import { stripePurchaseOrUpgrade } from "./stripePurchaseOrUpgrade";
import { useGetAccountPlanProps } from "./useGetAccountPlanProps";

declare const heap: any;

type Plan = WithModelIds<PlanBase<any>>;

export const UpsellCard = (): JSX.Element | null => {
  /* determine upsell card to show */

  const {
    currentPlan,
    recommendedPlan,
    isLoading: planPropsLoading,
    capExceeded,
    upgradeCost,
    premiumEntity,
    tradingStock,
    totalGainDifference,
    yearlySavings,
    ltfoSaving,
    validPlans,
    account,
  } = useGetAccountPlanProps();

  const budgetPlan = validPlans.at(0);

  if (planPropsLoading || !account) {
    return <CalculatingCard />;
  } else if (!recommendedPlan || !upgradeCost) {
    return null;
  } else if (capExceeded) {
    // exceeded transaction limit
    return (
      <TransactionCapCard
        recommendedPlan={recommendedPlan}
        currentPlan={currentPlan}
        account={account}
      />
    );
  } else if (premiumEntity) {
    return (
      <PremiumEntityPlanCard
        recommendedPlan={recommendedPlan}
        currentPlan={currentPlan}
        account={account}
      />
    ); // private wealth
  } else if (tradingStock) {
    return (
      <TradingStockPlanCard
        recommendedPlan={recommendedPlan}
        currentPlan={currentPlan}
        account={account}
      />
    );
  } else if (ltfoSaving && totalGainDifference && yearlySavings && budgetPlan) {
    return (
      <TaxSavingCard
        recommendedPlan={recommendedPlan}
        budgetPlan={budgetPlan}
        currentPlan={currentPlan}
        yearlySavings={yearlySavings}
        account={account}
      />
    );
  } else if (recommendedPlan && !currentPlan?.assuranceFeatures && budgetPlan) {
    return (
      <AssuranceFeaturesCard
        recommendedPlan={recommendedPlan}
        budgetPlan={budgetPlan}
        currentPlan={currentPlan}
        account={account}
      />
    );
  } else {
    return null;
  }
};

type PlanAccount = Pick<
  Account,
  "accountType" | "accountSubType" | "role" | "permissions" | "subscriptions"
>;

const TaxSavingCard = ({
  yearlySavings,
  recommendedPlan,
  budgetPlan,
  currentPlan,
  account,
}: {
  yearlySavings: TaxYearResultBase<string>[];
  recommendedPlan: Plan;
  budgetPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) => {
  // send analytics
  useEffect(() => {
    heap.track("Tax Reports - View - Upsell", {
      type: "taxSaving",
      plan: recommendedPlan.name,
    });
  }, [recommendedPlan.name]);

  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );

  const totalGain = sumWithDecimal(yearlySavings, "gain");

  return (
    <UpsellCardBase
      heading={
        <VStack alignItems="flex-start">
          <Text fontSize="xl" fontWeight="bold">
            Reduce your taxable income by
          </Text>
          <CardHeading color="green.600">
            {formatAsCurrency(totalGain)}
          </CardHeading>
        </VStack>
      }
      content={
        <>
          {/* View Calculation expander */}
          <Accordion w={"100%"} allowToggle={true}>
            <AccordionItem style={{ borderStyle: "none" }}>
              <AccordionPanel px={0}>
                <VStack spacing={6} w={"100%"} alignItems={"flex-start"}>
                  {/* Savings by financial year */}
                  <VStack alignItems={"flex-start"} w={"100%"}>
                    <Text fontSize={"medium"} fontWeight={"bold"}>
                      Savings by financial year
                    </Text>
                    <Grid templateColumns={"1fr 1fr"} w={"100%"} gap={1}>
                      {yearlySavings
                        .filter(({ gain }) => !gain.isZero())
                        .map(({ financialYear, gain }) => (
                          <Fragment key={financialYear}>
                            <Box>
                              <Text>{formatFinancialYear(financialYear)}</Text>
                            </Box>
                            <NumberStyler
                              num={gain}
                              unit="currency"
                              negativeColored
                              textAlign={"right"}
                            />
                          </Fragment>
                        ))}
                      <Divider />
                      <Divider />
                      <Box>
                        <Text fontWeight={"bold"}>Total Saving</Text>
                      </Box>
                      <NumberStyler
                        num={totalGain}
                        unit="currency"
                        negativeColored
                        textAlign={"right"}
                        fontWeight={"bold"}
                      />
                    </Grid>
                  </VStack>

                  {/* Explanation */}
                  <VStack
                    spacing={2}
                    alignItems={"flex-start"}
                    w={"100%"}
                    color={"gray.600"}
                  >
                    <Text>
                      The saving is the total amount you can{" "}
                      <b>reduce your capital gains</b> and/or{" "}
                      <b>increase your capital losses</b> by over the financial
                      years from 1 July{" "}
                      {selectedFinancialYear?.startDate.getFullYear()} to
                      present.
                    </Text>
                    <Text>
                      Reducing your capital gains will reduce your taxable
                      income. The amount your tax is reduced by will depend on
                      your complete tax situation, including your current tax
                      bracket.
                    </Text>
                    <Text>
                      If you later edit your transactions or apply a different
                      parcel-matching method, the saving amount will change.
                    </Text>
                  </VStack>
                </VStack>
              </AccordionPanel>

              {/* Expander button container */}
              <VStack position={"relative"} w={"100%"} mt={"1rem"} mb={"3rem"}>
                <Box h="1px" bgColor="black.200" w={CARD_FULL_WIDTH} />
                <VStack
                  alignItems={"center"}
                  position={"absolute"}
                  top={"-1.5rem"}
                >
                  <Box bgColor={"white.0"}>
                    <AccordionButton
                      w={"53px"}
                      alignSelf={"center"}
                      border={"1px solid gray"}
                      borderRadius={"5px"}
                      data-event={`upsell-info-toggle`}
                    >
                      <AccordionIcon />
                    </AccordionButton>
                  </Box>
                  <Text w={"100%"} align={"center"} color={"gray.500"}>
                    more information
                  </Text>
                </VStack>
              </VStack>
            </AccordionItem>
          </Accordion>

          {/* Std plan description */}
          <StdPlanText
            recommendedPlan={recommendedPlan}
            currentPlan={currentPlan}
            account={account}
            subtext={`Reduce your taxable income by ${formatAsCurrency(
              totalGain
            )}`}
          />
          {/* Learn More */}
          <HStack whiteSpace={"nowrap"} color={"red.600"}>
            <Link
              href="https://help.syla.com.au/en/articles/8458954-tax-saving-calculation"
              isExternal={true}
            >
              <Text>learn more</Text>
            </Link>

            <ExternalLinkIcon />
          </HStack>
        </>
      }
      actions={
        <UnlockPlanOrBudget
          pt={"1rem"}
          plan={recommendedPlan}
          budgetPlan={budgetPlan}
          currentPlan={currentPlan}
          account={account}
        />
      }
    />
  );
};

const TransactionCapCard = ({
  recommendedPlan,
  currentPlan,
  account,
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) => {
  // send analytics
  useEffect(() => {
    heap.track("Tax Reports - View - Upsell", {
      type: "transactionCapExceeded",
      plan: recommendedPlan.name,
    });
  }, [recommendedPlan.name]);

  const { unlockPlanAction, isWorking } = useUnlockPlanAction({
    subscriptions: account.subscriptions,
  });
  return (
    <UpsellCardBase
      heading={
        <CardHeading>
          {currentPlan
            ? `Increase to 40,000 transactions`
            : `Get your tax report`}
        </CardHeading>
      }
      content={
        <StdPlanText
          recommendedPlan={recommendedPlan}
          currentPlan={currentPlan}
          account={account}
          subtext={`Get an increased transaction count to cover all the transactions that are currently in your account.`}
        />
      }
      actions={
        <>
          <UnlockPlanButton
            planName={recommendedPlan.name}
            onClick={() => unlockPlanAction(recommendedPlan)}
            isLoading={isWorking}
            account={account}
          />
        </>
      }
    />
  );
};

const PremiumEntityPlanCard = ({
  recommendedPlan,
  currentPlan,
  account,
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) => {
  // send analytics
  useEffect(() => {
    heap.track("Tax Reports - View - Upsell", {
      type: account.accountType,
      plan: recommendedPlan.name,
    });
  }, [account.accountType, recommendedPlan.name]);

  const { unlockPlanAction, isWorking } = useUnlockPlanAction({
    subscriptions: account.subscriptions,
  });
  return (
    <UpsellCardBase
      heading={
        <CardHeading>
          {currentPlan
            ? `Unlock ${account.accountType} account`
            : "Get your tax report"}
        </CardHeading>
      }
      content={
        <StdPlanText
          recommendedPlan={recommendedPlan}
          currentPlan={currentPlan}
          account={account}
          subtext={`Download a crypto tax report for your ${account.accountType} entity.`}
        />
      }
      actions={
        <StdVStack alignItems="center">
          <UnlockPlanButton
            planName={recommendedPlan.name}
            onClick={() => unlockPlanAction(recommendedPlan)}
            isLoading={isWorking}
            account={account}
          />
        </StdVStack>
      }
    />
  );
};

const TradingStockPlanCard = ({
  recommendedPlan,
  currentPlan,
  account,
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) => {
  // send analytics
  useEffect(() => {
    heap.track("Tax Reports - View - Upsell", {
      type: "traderPlan",
      plan: recommendedPlan.name,
    });
  }, [recommendedPlan.name]);

  const { unlockPlanAction, isWorking } = useUnlockPlanAction({
    subscriptions: account.subscriptions,
  });
  return (
    <UpsellCardBase
      heading={
        <CardHeading>
          {currentPlan ? `Unlock Trading Stock` : "Get your tax report"}
        </CardHeading>
      }
      content={
        <StdPlanText
          recommendedPlan={recommendedPlan}
          currentPlan={currentPlan}
          account={account}
          subtext={`Download a crypto tax report with support for Trading Stock.`}
        />
      }
      actions={
        <>
          <UnlockPlanButton
            planName={recommendedPlan.name}
            onClick={() => unlockPlanAction(recommendedPlan)}
            isLoading={isWorking}
            account={account}
          />
        </>
      }
    />
  );
};

const AssuranceFeaturesCard = ({
  recommendedPlan,
  budgetPlan,
  currentPlan,
  account,
}: {
  recommendedPlan: Plan;
  budgetPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) => {
  // send analytics
  useEffect(() => {
    heap.track("Tax Reports - View - Upsell", {
      type: "default",
      plan: recommendedPlan.name,
    });
  }, [recommendedPlan.name]);

  return (
    <UpsellCardBase
      heading={
        <CardHeading>
          {currentPlan ? `Get supporting documents` : "Get your tax report"}
        </CardHeading>
      }
      content={
        <StdPlanText
          recommendedPlan={recommendedPlan}
          currentPlan={currentPlan}
          account={account}
          subtext={`Download supporting documents to keep you safe in an ATO audit or review.`}
        />
      }
      actions={
        <UnlockPlanOrBudget
          pt={"1rem"}
          plan={recommendedPlan}
          budgetPlan={budgetPlan}
          currentPlan={currentPlan}
          account={account}
        />
      }
    />
  );
};

const CalculatingCard = () => {
  return (
    <ActionCard
      heading={"Calculating recommendations..."}
      actions={<UnlockButton content={"Calculating"} isLoading={true} />}
      content={
        <>
          <Text>
            We’re calculating some recommendations for your account that may
            help you save tax, it won’t take long.
          </Text>
        </>
      }
    />
  );
};

function UpsellCardBase({
  actions,
  content,
  heading,
}: {
  heading: JSX.Element;
  content: JSX.Element;
  actions: JSX.Element;
}) {
  return (
    <ActionCard
      css={`
        border-radius: 5px;
        border-width: 1px;
        border-color: #f5f5f5;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      `}
      bgColor={"white.0"}
      content={
        <VStack alignItems={"flex-start"} spacing={6} w={"100%"}>
          {heading}
          {content}
          {actions}
        </VStack>
      }
    />
  );
}

function UnlockPlanOrBudget({
  plan,
  budgetPlan,
  account,
  currentPlan,
  ...props
}: {
  plan: Plan;
  budgetPlan: Plan;
  account: PlanAccount;
  currentPlan: Plan | undefined;
} & StackProps): JSX.Element {
  const { unlockPlanAction, isWorking } = useUnlockPlanAction({
    subscriptions: account.subscriptions,
  });
  const [planClick, setPlanClick] = useState(false);
  const [budgetClick, setBudgetClick] = useState(false);

  const canUnlock = canUnlockPlanFeature(account);

  useEffect(() => {
    // reset state if we had an error
    if (!isWorking) {
      setBudgetClick(false);
      setPlanClick(false);
    }
  }, [isWorking]);

  return (
    <VStack w={"100%"} divider={<OrDivider />} {...props}>
      <UnlockPlanButton
        planName={plan.name}
        onClick={() => {
          setPlanClick(true);
          unlockPlanAction(plan);
        }}
        isLoading={planClick}
        isDisabled={isWorking}
        account={account}
      />
      {!currentPlan && (
        <HStack justify={"space-between"} alignItems={"center"} w={"100%"}>
          <Text>You can still unlock the Budget plan for $59.</Text>
          <TooltipWrapper
            tooltip={!canUnlock ? BILLING_OWNER_REQUIRED_MESSAGE : undefined}
          >
            <ButtonVariant
              content={"Unlock Budget"}
              onClick={() => {
                setBudgetClick(true);
                unlockPlanAction(budgetPlan);
              }}
              isLoading={budgetClick}
              disabled={!canUnlock || isWorking}
              data-event={`purchase-start-${budgetPlan.name}`}
            />
          </TooltipWrapper>
        </HStack>
      )}
    </VStack>
  );
}

function UnlockPlanButton({
  planName,
  account,
  onClick,
  isDisabled,
  ...props
}: {
  planName: string;
  account: PlanAccount;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
} & Omit<ButtonVariantProps, "content">) {
  const canUnlock = canUnlockPlanFeature(account);

  return (
    <TooltipWrapper
      tooltip={!canUnlock ? BILLING_OWNER_REQUIRED_MESSAGE : undefined}
    >
      <UnlockButton
        content={`Unlock ${planName}`}
        onClick={onClick}
        data-event={`purchase-start-${planName}`}
        isDisabled={!canUnlock || isDisabled}
        {...props}
      />
    </TooltipWrapper>
  );
}

function UnlockButton({
  content,
  onClick,
  isLoading,
  isDisabled,
  ...props
}: {
  content: string;
} & ButtonVariantProps) {
  return (
    <ButtonVariant
      leftIcon={"bolt"}
      content={content}
      color="red"
      onClick={onClick}
      isLoading={isLoading}
      spam={"spam"}
      minW={"fit-content"}
      textTransform={"none"}
      disabled={isDisabled}
      {...props}
    />
  );
}

function useUnlockPlanAction({
  subscriptions,
}: {
  subscriptions:
    | Pick<Subscription, "_id" | "stripeSubscriptionId" | "current">[]
    | undefined;
}) {
  const errorToast = useToast(errorToastOptions);
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const [isWorking, setIsWorking] = useState(false);

  const currentSubscription = getCurrentSubscription(subscriptions ?? []);

  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );
  const unlockPlanAction = useCallback(
    async (plan: Plan) => {
      try {
        setIsWorking(true);
        await stripePurchaseOrUpgrade({
          accountId,
          successUrl: getFullPageUrl(location),
          financialYear: selectedFinancialYear?.value,
          stripeSubscriptionId: currentSubscription?.stripeSubscriptionId,
          plan,
        });
      } catch (error) {
        errorToast({
          title: "Error unlocking plan",
          description: `An unexpected error occurred. Please try again later.`,
        });
        setIsWorking(false);
        return;
      }
    },
    [
      accountId,
      currentSubscription?.stripeSubscriptionId,
      errorToast,
      selectedFinancialYear?.value,
    ]
  );

  return {
    isWorking,
    unlockPlanAction,
  };
}

function OrDivider() {
  return (
    <Box width={CARD_FULL_WIDTH} my="1rem">
      <Box h="1px" bgColor="black.200" />
      <VStack alignItems={"center"} w={"100%"} position={"relative"}>
        <Text
          position={"absolute"}
          top={"-0.7rem"}
          bgColor={"white.0"}
          px={"0.2rem"}
        >
          or
        </Text>
      </VStack>
    </Box>
  );
}

function StdPlanText({
  recommendedPlan,
  currentPlan,
  subtext,
  account,
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  subtext: string;
  account: PlanAccount;
}) {
  return (
    <>
      <VStack alignItems={"flex-start"} spacing={0.5}>
        <Text fontWeight={"bold"}>Unlock {recommendedPlan.name} plan</Text>
        <Text>{subtext}</Text>
      </VStack>

      <ValueProps
        recommendedPlan={recommendedPlan}
        currentPlan={currentPlan}
        account={account}
      />
    </>
  );
}

function CardHeading({ children, ...props }: HeadingProps) {
  return (
    <Heading fontSize="3xl" {...props}>
      {children}
    </Heading>
  );
}

function ValueProps({
  recommendedPlan,
  currentPlan,
  account,
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}) {
  return (
    <Grid templateColumns={"1rem auto"} alignItems={"flex-start"} gap={3}>
      {getValueProps({ recommendedPlan, currentPlan, account: account })
        .filter((prop) => prop.included)
        .map(({ text, subText }) => (
          <Fragment key={text}>
            <Box mt={"0.2rem"}>
              <Text color={"red.500"}>
                <FaCheckCircle />
              </Text>
            </Box>
            <VStack alignItems={"flex-start"} spacing={0.5}>
              <Text fontWeight={"bold"}>{text}</Text>
              {subText && <Text>{subText.replace(/\.$/, "")}.</Text>}
            </VStack>
          </Fragment>
        ))}
    </Grid>
  );
}

const formatAsCurrency = (value: Decimal) =>
  value.format({
    currencyCode: "AUD",
    separateThousands: true,
    fixedPointDigits: 0,
  }).text;

const formatFinancialYear = (financialYear) => {
  const [startDate, endDate] = financialYearToDateRange(financialYear);

  return `${startDate.getFullYear()}-${endDate.getFullYear()} FY`;
};

function getValueProps({
  recommendedPlan,
  currentPlan,
  account: { accountType, accountSubType },
}: {
  recommendedPlan: Plan;
  currentPlan: Plan | undefined;
  account: PlanAccount;
}): Feature[] {
  const formattedTxLimit = Decimal.from(
    recommendedPlan.transactionLimit.toString()
  ).format({
    separateThousands: true,
  }).text;

  const isTrader = accountSubType == AccountSubType.Trader;
  return [
    {
      text: `${
        recommendedPlan.transactionLimit == -1 ? "Unlimited" : formattedTxLimit
      } transactions`,
      included:
        !currentPlan ||
        recommendedPlan.transactionLimit > currentPlan.transactionLimit ||
        recommendedPlan.transactionLimit == -1,
    },
    {
      text: "Crypto tax report",
      subText: "Comprehensive tax report for completing your tax return.",
      included: !currentPlan && accountType != AccountType.SMSF,
    },

    {
      text: "Tax assurance reports",
      subText: "Additional reports to keep you safe in an ATO audit or review.",
      included:
        !currentPlan?.assuranceFeatures && recommendedPlan.assuranceFeatures,
    },
    {
      text: "Tax saving",
      subText: "Apply LTFO to reduce your capital gains",
      included:
        !currentPlan?.taxOptimisationFeatures &&
        recommendedPlan.taxOptimisationFeatures &&
        !isTrader &&
        AccountTypeCGTDiscount[accountType],
    },
    {
      text: "Tax saving",
      subText:
        "Apply tax optimisation to your Closing Stock Valuation to reduce your Net Profit or Loss",
      included:
        !currentPlan?.taxOptimisationFeatures &&
        recommendedPlan.taxOptimisationFeatures &&
        isTrader &&
        AccountTypeCGTDiscount[accountType],
    },
    {
      text: "Trading Stock",
      subText: "Custom tax report for Traders",
      included:
        !currentPlan?.premiumFeatures &&
        recommendedPlan.premiumFeatures &&
        isTrader,
    },
    {
      text: "SMSF account type",
      included:
        !currentPlan?.premiumFeatures &&
        recommendedPlan.premiumFeatures &&
        accountType == AccountType.SMSF,
    },
    {
      text: "SMSF crypto tax report",
      included:
        !currentPlan?.premiumFeatures &&
        recommendedPlan.premiumFeatures &&
        accountType == AccountType.SMSF,
    },
    {
      text: "Sync to BGL",
      included:
        !currentPlan?.premiumFeatures &&
        recommendedPlan.premiumFeatures &&
        accountType == AccountType.SMSF,
    },
  ];
}
