"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoundingMode = exports.greaterOf = exports.lesserOf = exports.Decimal = exports.OVERFLOW_ERROR = void 0;
const decimal_js_1 = require("decimal.js");
const formatting_1 = require("../helpers/formatting");
const SIGNIFICANT_DIGITS = 32;
const config = {
    precision: SIGNIFICANT_DIGITS,
    rounding: 4,
    minE: -64,
    maxE: 64,
    defaults: true,
};
decimal_js_1.Decimal.set(config);
exports.OVERFLOW_ERROR = `Decimal overflow: make sure Decimal range is inside 1e${config.maxE}`;
class Decimal {
    constructor(decimal, context) {
        Decimal.validate(decimal, context);
        this._value = decimal;
    }
    static from(value) {
        const rawDecimal = new decimal_js_1.Decimal(value).toSignificantDigits();
        const instance = new Decimal(rawDecimal, `Invalid initial Decimal with value ${value}`);
        return instance;
    }
    static min(...n) {
        return new Decimal(decimal_js_1.Decimal.min(...n.map((n) => Decimal._unwrap(n))), `min ${n.join(", ")}`);
    }
    sign() {
        return this._value.s;
    }
    toString() {
        return this._value.toString();
    }
    toNumber() {
        return this._value.toNumber();
    }
    toJSON() {
        return this._value.toJSON();
    }
    isNaN() {
        return this._value.isNaN();
    }
    isFinite() {
        return this._value.isFinite();
    }
    abs() {
        return new Decimal(this._value.abs(), `Decimal ${this._value} abs()`);
    }
    plus(value) {
        const result = new Decimal(this._value.plus(Decimal._unwrap(value)), `${this._value} plus ${value}`);
        return result;
    }
    minus(value) {
        const result = new Decimal(this._value.minus(Decimal._unwrap(value)), `${this._value} minus ${value}`);
        return result;
    }
    times(value) {
        const result = new Decimal(this._value.times(Decimal._unwrap(value)), `${this._value} times ${value}`);
        return result;
    }
    div(value) {
        const result = new Decimal(this._value.div(Decimal._unwrap(value)), `${this._value} div ${value}`);
        return result;
    }
    mod(value) {
        const result = new Decimal(this._value.mod(Decimal._unwrap(value)), `${this._value} mod ${value}`);
        return result;
    }
    negated() {
        const result = new Decimal(this._value.neg(), `${this._value} negated`);
        return result;
    }
    eq(value) {
        return this._value.eq(Decimal._unwrap(value));
    }
    gt(value) {
        return this._value.gt(Decimal._unwrap(value));
    }
    gte(value) {
        return this._value.gte(Decimal._unwrap(value));
    }
    lt(value) {
        return this._value.lt(Decimal._unwrap(value));
    }
    lte(value) {
        return this._value.lte(Decimal._unwrap(value));
    }
    isPositive() {
        return this._value.isPositive();
    }
    isNonZeroPositive() {
        return this._value.isPositive() && !this._value.isZero();
    }
    isZeroOrPositive() {
        // -0 will be included
        return this._value.isPositive() || this._value.isZero();
    }
    isNegative() {
        return this._value.isNegative();
    }
    isNonZeroNegative() {
        return !this._value.isPositive() && !this._value.isZero();
    }
    isZeroOrNegative() {
        // 0 will be included
        return this._value.isNegative() || this._value.isZero();
    }
    isZero() {
        return this._value.isZero();
    }
    /**
     * Used for sorting functions.
     *
     * @param value Value to compare to
     * @returns
     * 1 if the value of this Decimal is greater than `value`,
     * -1 if the value of this Decimal is less than `value`
     * 0 if this Decimal and `value` are the same
     */
    comparedTo(value) {
        return this._value.comparedTo(Decimal._unwrap(value));
    }
    toDecimalPlaces(value, mode) {
        return new Decimal(this._value.toDP(value, mode !== null && mode !== void 0 ? mode : RoundingMode.ROUND_HALF_UP), `${this._value} toDecimalPlaces(${value})`);
    }
    toFixed(decimalPlaces) {
        return this._value.toFixed(decimalPlaces);
    }
    format({ fixedPointDigits, currencyCode, showPositiveSign, hideNegativeSign, indicateNonZero, round, smartRound, separateThousands, } = {}) {
        var _a, _b, _c;
        const currencySymbol = currencyCode == "AUD" ? "$" : "";
        // sign is determined above
        let value = this._value.abs();
        // add rounding
        value = round
            ? value.toDecimalPlaces(round.decimalPlaces, (_a = round.mode) !== null && _a !== void 0 ? _a : decimal_js_1.Decimal.rounding)
            : value;
        // add smart rounding
        if (smartRound) {
            let significantDigits = 4;
            let decimalPlaces = 4;
            if (smartRound !== true) {
                significantDigits =
                    (_b = smartRound.minSignificantDigits) !== null && _b !== void 0 ? _b : significantDigits;
                decimalPlaces = (_c = smartRound.maxDecimalPrecision) !== null && _c !== void 0 ? _c : decimalPlaces;
            }
            value = value.gte("1")
                ? value.toDecimalPlaces(decimalPlaces)
                : value.toSignificantDigits(significantDigits);
        }
        let numberValue = fixedPointDigits != null
            ? value.toFixed(fixedPointDigits)
            : value.toFixed(); // format without scientific notation
        const showApproximate = indicateNonZero && !this.isZero() && Decimal.from(numberValue).isZero();
        const sign = showApproximate
            ? "≈ "
            : showPositiveSign && this.isNonZeroPositive()
                ? "+"
                : !hideNegativeSign && this.isNonZeroNegative()
                    ? "-"
                    : "";
        // apply separateThousands
        numberValue = separateThousands
            ? (0, formatting_1.formatStringWithCommas)(numberValue)
            : numberValue;
        return {
            text: `${sign}${currencySymbol}${numberValue}`,
            isSummarised: showApproximate,
        };
    }
    static _unwrap(value) {
        return value instanceof Decimal ? value._value : value;
    }
}
exports.Decimal = Decimal;
Decimal.validate = (decimal, context) => {
    if (decimal.isNaN()) {
        throw new Error(`NaN result from ${context}`);
    }
    if (!decimal.isFinite()) {
        throw new Error(`${exports.OVERFLOW_ERROR}. Context: ${context}`);
    }
};
(() => {
    Decimal.ZERO = Decimal.from("0");
    Decimal.ONE = Decimal.from("1");
    Decimal.TWO = Decimal.from("2");
    Decimal.TEN = Decimal.from("10");
})();
const lesserOf = (a, b) => (a.lt(b) ? a : b);
exports.lesserOf = lesserOf;
const greaterOf = (a, b) => (a.gt(b) ? a : b);
exports.greaterOf = greaterOf;
/* https://mikemcl.github.io/decimal.js/#modes */
var RoundingMode;
(function (RoundingMode) {
    RoundingMode[RoundingMode["ROUND_UP"] = 0] = "ROUND_UP";
    RoundingMode[RoundingMode["ROUND_DOWN"] = 1] = "ROUND_DOWN";
    RoundingMode[RoundingMode["ROUND_CEIL"] = 2] = "ROUND_CEIL";
    RoundingMode[RoundingMode["ROUND_FLOOR"] = 3] = "ROUND_FLOOR";
    RoundingMode[RoundingMode["ROUND_HALF_UP"] = 4] = "ROUND_HALF_UP";
    RoundingMode[RoundingMode["ROUND_HALF_DOWN"] = 5] = "ROUND_HALF_DOWN";
    RoundingMode[RoundingMode["ROUND_HALF_EVEN"] = 6] = "ROUND_HALF_EVEN";
    RoundingMode[RoundingMode["ROUND_HALF_CEIL"] = 7] = "ROUND_HALF_CEIL";
    RoundingMode[RoundingMode["ROUND_HALF_FLOOR"] = 8] = "ROUND_HALF_FLOOR";
})(RoundingMode = exports.RoundingMode || (exports.RoundingMode = {}));
