import {
  getRateLimitErrorMessage,
  SyncWalletErrorDetails,
  SyncWalletLimitType,
  SyncWalletAuthErrorReason,
  SyncWalletErrorDetailCode,
} from "@syla/shared/types/models/WalletBase";
import { UnexpectedErrorString } from "../../components/atoms/UnexpectedError";
import { Wallet } from "../../types/wallet/wallet";

export function getSyncErrorProps(
  wallet: Pick<Wallet, "dataSource">,
  details: SyncWalletErrorDetails
): {
  title: string;
  message?: string;
} {
  const oAuth = wallet.dataSource.syncAuthType == "oauth";

  switch (details.code) {
    case SyncWalletErrorDetailCode.LIMIT_EXCEEDED:
      return {
        title: "Too many transactions",
        message:
          details.limitType == SyncWalletLimitType.DATA_SOURCE
            ? "The transaction limit for this data source has been exceeded. Please check that you have specified the correct wallet address"
            : "We couldn't import your transactions for this data source as they would exceed our global limits",
      };
    case SyncWalletErrorDetailCode.INVALID_ADDRESS:
      return {
        title: "Invalid Wallet Address",
        message:
          "The address you've entered for this data source is not valid for this blockchain. Double-check the address",
      };
    case SyncWalletErrorDetailCode.INVALID_AUTH: {
      const title = "We couldn't access your data";

      switch (details.subReason) {
        case SyncWalletAuthErrorReason.InsufficientPermission:
          return {
            title,
            message: `The access provided is insufficient to get your transaction data. Double-check you have allowed the required permissions.`,
          };
        case SyncWalletAuthErrorReason.ExcessivePermission:
          return {
            title,
            message: `Your ${
              oAuth ? "access" : "API Key"
            } has not been accepted as it gave excessive permission to trade or transfer funds on your account. 
            For the security of your assets, please ${
              oAuth
                ? "reconnect this data source with the correct permission level"
                : "delete your API Key, then generate a new API key with read-only permission"
            }.`,
          };
        case SyncWalletAuthErrorReason.Expired:
          return {
            title,
            message: `Your ${
              oAuth ? "access" : "API Key"
            } has expired. Please fix your access and try again`,
          };
        case SyncWalletAuthErrorReason.InvalidKey:
        default:
          return {
            title,
            message: `Your access has expired or been revoked. ${
              oAuth
                ? "You will need to fix your access by reconnecting this data source"
                : "Check your API keys and fix your access for this data source"
            }`,
          };
      }
    }
    case SyncWalletErrorDetailCode.RATE_LIMIT:
      return getRateLimitErrorMessage({
        vendor: details.vendor,
        createdMs: details.createdMs,
        timespanMs: details.retryAfterMs,
      });
    case SyncWalletErrorDetailCode.OUTAGE:
      return {
        title: "Temporary service disruption",
        message: `We're aware of an issue affecting this integration and are actively working to resolve it. 
          Please try again in a few hours. We apologise for the inconvenience and thank you for your patience.`,
      };
    case SyncWalletErrorDetailCode.CUSTOM:
      return {
        title: details.title,
        message: details.message,
      };

    case "UNKNOWN":
    default:
      return {
        title: UnexpectedErrorString,
      };
  }
}
