import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigator, Route } from "../../routers/navigator";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { ButtonVariant } from "./ButtonVariant";

export const EmptyDataMessage = ({ content }: { content: string }) => {
  const navigate = useNavigator();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      direction="column"
      h="300px"
    >
      <Text textAlign="center" fontSize="0.9rem" color="black.800">
        This looks quite empty, doesn&#39;t it?
      </Text>
      <Text textAlign="center" fontSize="0.9rem" color="black.600" mb="20px">
        {content}
      </Text>
      <ButtonVariant
        content="Add Data Source"
        outlineType="outlineRed"
        onClick={() => {
          navigate({
            route: Route.DataSources,
            accountId,
            state: { openSidebar: true },
          });
        }}
      />
    </Flex>
  );
};
