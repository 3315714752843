import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import Group from "../../images/icons/Transactions/Group.svg";
import Receive from "../../images/icons/Transactions/Receive.svg";
import Send from "../../images/icons/Transactions/Send.svg";
import Rebase from "../../images/icons/Transactions/Rebase.svg";
import Trade from "../../images/icons/Transactions/Trade.svg";
import AddLiquidity from "../../images/icons/Transactions/Add Liquidity.svg";
import RemoveLiquidity from "../../images/icons/Transactions/Remove Liquidity.svg";
import Wrap from "../../images/icons/Transactions/Wrap.svg";
import Mint from "../../images/icons/Transactions/transactionIncrease/mint.svg";
import Transaction from "../../images/icons/Transactions/Transaction.svg";

export interface TransactionTypeOption extends SelectOptionType {
  icon: string;
}

export const transactionTypeOptions: Record<
  TransactionType,
  TransactionTypeOption
> = {
  [TransactionType.Trade]: {
    value: TransactionType.Trade,
    label: TransactionType.Trade,
    icon: Trade,
    description: "Exchange any asset for any another asset",
  },
  [TransactionType.Rebase]: {
    value: TransactionType.Rebase,
    label: TransactionType.Rebase,
    icon: Rebase,
    description: "Change the ticker name or quantity of an asset",
  },
  Mint: {
    value: TransactionType.Mint,
    label: TransactionType.Mint,
    icon: Mint,
    description: "Mint a new asset",
  },
  [TransactionType.Wrap]: {
    value: TransactionType.Wrap,
    label: TransactionType.Wrap,
    icon: Wrap,
    description: "Transfer asset between blockchains",
  },
  [TransactionType.Receive]: {
    value: TransactionType.Receive,
    label: TransactionType.Receive,
    icon: Receive,
    description: "Receive an asset",
  },
  [TransactionType.AddLiquidity]: {
    value: TransactionType.AddLiquidity,
    label: TransactionType.AddLiquidity,
    icon: AddLiquidity,
    description: "Add to a liquidity pool",
  },
  [TransactionType.Send]: {
    value: TransactionType.Send,
    label: TransactionType.Send,
    icon: Send,
    description: "Send an asset",
  },
  [TransactionType.RemoveLiquidity]: {
    value: TransactionType.RemoveLiquidity,
    label: TransactionType.RemoveLiquidity,
    icon: RemoveLiquidity,
    description: "Withdraw from a liquidity pool",
  },
  [TransactionType.Transaction]: {
    value: TransactionType.Transaction,
    label: TransactionType.Transaction,
    icon: Transaction,
    description: "Any type of transaction",
  },
};

export const getTransactionTypeOptions = Object.values(transactionTypeOptions);

export const getTransactionTypeIcon = (
  type: TransactionType | "group"
): string => {
  switch (type) {
    case "group":
      return Group;
    default:
      return transactionTypeOptions[type].icon;
  }
};
