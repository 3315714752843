"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseLedger = void 0;
const decimal_1 = require("../../decimal");
const parsing_1 = require("./parsing");
function parseLedger(ledger) {
    var _a;
    return Object.assign(Object.assign({}, ledger), { amount: decimal_1.Decimal.from(ledger.amount), marketValue: (0, parsing_1.parseOptional)(ledger.marketValue, parsing_1.parseDecimal), customMarketValue: ledger.customMarketValue
            ? Object.assign(Object.assign({}, ledger.customMarketValue), { value: decimal_1.Decimal.from(ledger.customMarketValue.value) }) : undefined, balances: ledger.balances
            ? {
                balance: decimal_1.Decimal.from(ledger.balances.balance),
                walletBalance: decimal_1.Decimal.from(ledger.balances.walletBalance),
            }
            : undefined, taxOutcomes: (_a = ledger.taxOutcomes) === null || _a === void 0 ? void 0 : _a.map((taxOutcomes) => parseTaxOutcomes(taxOutcomes)) });
}
exports.parseLedger = parseLedger;
function parseTaxOutcomes(taxOutcome) {
    return Object.assign(Object.assign({}, taxOutcome), { gain: (0, parsing_1.parseDecimal)(taxOutcome.gain), acquisitionDate: (0, parsing_1.parseOptional)(taxOutcome.acquisitionDate, parsing_1.parseDate), amount: (0, parsing_1.parseOptional)(taxOutcome.amount, parsing_1.parseDecimal), cost: (0, parsing_1.parseOptional)(taxOutcome.cost, parsing_1.parseDecimal), proceeds: (0, parsing_1.parseOptional)(taxOutcome.proceeds, parsing_1.parseDecimal) });
}
