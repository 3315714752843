import { Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { getDataSource } from "../api/dataSource/getDataSource";
import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "../components/atoms/Containers";
import { InstructionBox } from "../components/molecules/overlayBoxes/InstructionBox";
import { AddDataSourceForm } from "../components/organisms/dataSource/addFirstDataSourceForm/AddDataSourceForm";
import { AddFirstDataSource } from "../components/organisms/dataSource/addFirstDataSourceForm/addFirstDataSource/AddFirstDataSource";
import {
  ImportContextProvider,
  useImportContext,
} from "../components/organisms/dataSource/ImportContext";
import { getDataSourceInstruction } from "../helper/dataSource/getDataSourceInstructions";

export const FirstDataSourceChild = () => {
  return (
    <SignInParentContainer>
      <ImportContextProvider>
        <FirstDataSourceChildContent />
      </ImportContextProvider>
    </SignInParentContainer>
  );
};

const FirstDataSourceChildContent = () => {
  // get the data source from the url param
  const { sourceId } = useParams<{ sourceId: string }>();

  const {
    state: { dataSource, importType, importStatus },
    actions: { setDataSource },
  } = useImportContext();

  // query for the data source data
  const { data: loadedDataSource, isLoading: dataSourceIsLoading } = useQuery(
    ["data-source", sourceId],
    () => getDataSource(sourceId)
  );

  useEffect(() => {
    setDataSource(loadedDataSource);
  }, [loadedDataSource, setDataSource]);

  const instructions =
    (dataSource &&
      getDataSourceInstruction({
        dataSource: dataSource,
        inputType: importType,
        importStatus,
      })) ??
    "";

  return (
    <>
      <SignInLHSContainer>
        <InstructionBox instructions={instructions} />
      </SignInLHSContainer>
      <SignInRHSContainer>
        <AddDataSourceForm>
          {dataSource && !dataSourceIsLoading ? (
            <>
              <AddFirstDataSource
                source={dataSource}
                instructions={instructions}
              />
            </>
          ) : (
            <Spinner alignSelf="center" my="10px" />
          )}
        </AddDataSourceForm>
      </SignInRHSContainer>
    </>
  );
};
