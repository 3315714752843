import React from "react";
import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "../components/atoms/Containers";
import { SignUpInfo } from "../components/molecules/overlayBoxes/SignUpInfo";
import { AddDataSourceForm as AddFirstDataSourceForm } from "../components/organisms/dataSource/addFirstDataSourceForm/AddDataSourceForm";
import { SelectFirstDataSource } from "../components/organisms/dataSource/addFirstDataSourceForm/selectFirstDataSource/SelectFirstDataSource";

export const FirstDataSource = () => (
  <SignInParentContainer>
    <SignInLHSContainer>
      <SignUpInfo />
    </SignInLHSContainer>
    <SignInRHSContainer>
      <AddFirstDataSourceForm>
        <SelectFirstDataSource />
      </AddFirstDataSourceForm>
    </SignInRHSContainer>
  </SignInParentContainer>
);
