import { Permission } from "@syla/shared/types/helpers/permissions";

export interface SupportCsvDocument {
  name: string;
  description: string;
  url: string;
  permission: Permission;
}

export const UserTaxOutcomes: SupportCsvDocument = {
  name: "Tax Outcomes",
  description: "downloadTaxOutcomesForFinancialYear",
  url: "download-tax-outcomes-csv",
  permission: "get-tax-outcome-report",
};

export const UserTransactions: SupportCsvDocument = {
  name: "Transactions",
  description: "downloadTransactionsForFinancialYear",
  url: "download-transactions-csv",
  permission: "get-transaction-report",
};

export const UserRemainingParcels: SupportCsvDocument = {
  name: "Cost of Remaining Assets",
  description: "downloadRemainingParcelsForFinancialYear",
  url: "download-remaining-parcels-csv",
  permission: "get-remaining-parcels-report",
};
