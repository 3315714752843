import {
  WalletImportMethod,
  SyncState,
  FileImportState,
} from "@syla/shared/types/models/WalletBase";
import { differenceInDays } from "date-fns";
import React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { MdError } from "react-icons/md";
import { Wallet } from "../../../types/wallet/wallet";

export const getDataSourceStatusProps = ({
  importMethod,
  lastSynced,
  lastUploaded,
  syncState,
  fileImportState,
}: Pick<
  Wallet,
  | "authorised"
  | "importMethod"
  | "lastSynced"
  | "lastUploaded"
  | "syncState"
  | "fileImportState"
  | "dataSource"
>) => {
  const recentSync = lastSynced && differenceInDays(new Date(), lastSynced) < 1;

  if (importMethod == WalletImportMethod.API) {
    // not syncing
    if (!syncState) {
      return {
        status: "SYNC",
        color: "green.700",
        lastUpdated: lastSynced,
        icon: recentSync ? <HiCheckCircle color="#0BC196" /> : undefined,
      };
    }
    switch (syncState) {
      case SyncState.InProgress:
      case SyncState.Init:
        return {
          status: "SYNC in progress",
          color: "orange.500",
          lastUpdated: lastSynced,
          icon: recentSync ? <HiCheckCircle color="#0BC196" /> : undefined,
        };
      case SyncState.Error:
        // if (!authorised) {
        //   return {
        //     status: "AUTH ERROR",
        //     color: "red.500",
        //     lastUpdated: lastSynced,
        //     icon: <MdError color="#df3030" />,
        //   };
        // }

        return {
          status: "SYNC ERROR",
          color: "red.500",
          lastUpdated: lastSynced,
          icon: <MdError color="#df3030" />,
        };
    }
  } else {
    if (!fileImportState) {
      return {
        status: "CSV",
        color: "blue.500",
        lastUpdated: lastUploaded,
      };
    }
    switch (fileImportState) {
      case FileImportState.InProgress:
      case FileImportState.Init:
        return {
          status: "FILE IMPORT in progress",
          color: "orange.500",
          lastUpdated: lastUploaded,
        };
      case FileImportState.Error:
        return {
          status: "FILE IMPORT FAILED",
          color: "red.500",
          lastUpdated: lastUploaded,
          icon: <MdError color="#df3030" />,
        };
    }
  }
};
