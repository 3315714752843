import { extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./components/button";
import { inputTheme } from "./components/input";
import { tableTheme } from "./components/table";
import { tabsTheme } from "./components/tabs";
import { breakpoints } from "./foundations/breakpoints";
import { colors } from "./foundations/colors";
import { shadows } from "./foundations/shadows";
import { typography } from "./foundations/typography";
import { styles } from "./styles";

// Follow the structure recommended by Chakra UI
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src
export const theme = extendTheme({
  breakpoints,
  colors: colors,
  shadows: shadows,
  ...typography,
  components: {
    Button: buttonTheme,
    Input: inputTheme,
    Table: tableTheme,
    Tabs: tabsTheme,
  },
  styles: styles,
});
