import React, { useContext } from "react";
import {
  Tooltip,
  Image,
  useDisclosure,
  PlacementWithLogical,
} from "@chakra-ui/react";
import { Device } from "../../types/user/device";

import { iconExtractor } from "../../helper/iconExtractor";
import { UserContext } from "../../contexts/UserContext";

export const InfoToolTip = ({
  label,
  wrap,
  placement = "top",
  size,
}: {
  label: string;
  wrap?: boolean;
  placement?: PlacementWithLogical;
  size?: string;
}) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();
  const { userDevice } = useContext(UserContext);

  return (
    <Tooltip
      hasArrow
      label={label}
      placement={placement}
      fontSize="0.875rem"
      whiteSpace={wrap ? "normal" : "nowrap"}
      isOpen={isOpen}
      width="auto"
    >
      <Image
        src={iconExtractor("info")}
        alt="Tooltip"
        onMouseEnter={() => onOpen()}
        onMouseLeave={() => onClose()}
        onClick={() => {
          if (userDevice === Device.Mobile) {
            onToggle();
          }
        }}
        htmlHeight={size}
        htmlWidth={size}
      />
    </Tooltip>
  );
};
