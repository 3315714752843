import { useMutation } from "react-query";
import {
  updateAccountInvites,
  UpdateAccountInvites,
} from "../api/account/updateAccountInvites";
import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";

export const useMutationUpdateAccountInvites = (accountId: string) =>
  useMutation(
    (props: UpdateAccountInvites) => updateAccountInvites(accountId, props),
    {
      mutationKey: [...ACCOUNT_META_QUERY_KEY, accountId],
      onSettled: () => {
        queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
      },
    }
  );
