import { without } from "lodash";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { FileDropZone } from "./FileDropZone";

export const FileImportTab = (): JSX.Element => {
  // hook form context
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name="storedFiles"
      render={({ field: { onChange, value } }) => (
        <FileDropZone
          maxH="400px"
          overflowY="auto"
          storedFiles={value}
          onAddStoreFiles={(acceptedFiles) => {
            onChange([...value, ...acceptedFiles]);
          }}
          onRemoveStoreFiles={(index) => onChange(without(value, value[index]))}
        />
      )}
    />
  );
};
