import axios from "axios";
import {
  VerifyUserResponse,
  VerifyUserResponseStatusCodes,
} from "@syla/shared/types/responses/VerifyUserResponse";

export enum VerifyUserErrorReason {
  UnknownError,
  OtpExpired,
  RegistrationNotFound,
}

export class VerifyUserError extends Error {
  constructor(public errorReason: VerifyUserErrorReason) {
    super();
  }
}

export const verify = async (token: string): Promise<VerifyUserResponse> => {
  try {
    const { data } = await axios.post("/users/verify", {
      token,
    });
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      throw new VerifyUserError(VerifyUserErrorReason.UnknownError);
    }

    // handle known errors
    if (error.response.status == VerifyUserResponseStatusCodes.OtpExpired) {
      throw new VerifyUserError(VerifyUserErrorReason.OtpExpired);
    }
    if (
      error.response.status ==
      VerifyUserResponseStatusCodes.RegistrationNotFound
    ) {
      throw new VerifyUserError(VerifyUserErrorReason.RegistrationNotFound);
    }

    // default for other error
    throw new VerifyUserError(VerifyUserErrorReason.UnknownError);
  }
};
