import { Flex, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import React from "react";
import { isDesktop } from "react-device-detect";
import { BiSearchAlt2 } from "react-icons/bi";

export function SelectorSearch({
  searchValue,
  setSearchValue,
  focusRef,
  autoFocus = isDesktop,
}: {
  searchValue: string;
  setSearchValue: React.Dispatch<string>;
  focusRef?: React.MutableRefObject<any>;
  autoFocus?: boolean;
}) {
  return (
    <Flex
      p="5px 10px"
      w="100%"
      h="50px"
      borderWidth="0 0 1px 0"
      borderColor="white.500"
      bgColor="white.0"
    >
      <InputGroup bgColor="white.0">
        <InputLeftElement pointerEvents="none">
          <BiSearchAlt2 color="#999" />
        </InputLeftElement>
        <Input
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search ..."
          color="black.700"
          _placeholder={{ color: "black.550" }}
          borderColor="black.200"
          h="40px"
          _focus={{ borderColor: "#000" }}
          autoFocus={autoFocus}
          ref={focusRef}
        />
      </InputGroup>
    </Flex>
  );
}
