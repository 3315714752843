import { Flex, Divider as RowDivider, Text } from "@chakra-ui/react";
import { permissionFor } from "@syla/shared/types/helpers/permissions";
import { AccountType } from "@syla/shared/types/models/AccountBase";
import React, { useContext } from "react";
import { canUnlockPlanFeature } from "../../../helper/canUnlockPlanFeature";
import { TaxReportsContext } from "../../../pages/TaxReports";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryGetAccount } from "../../../store/useQueryGetAccount";
import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { useGlobalVariablesStore } from "../../../store/globalVariablesStore";
import { SylaSampleUrls } from "../../../constants/SylaSampleUrls";
import { DocumentContainer } from "./DocumentContainer";
import { TaxReportRow } from "./TaxReportRow";

export const TaxReportSummary = (): JSX.Element => {
  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );
  const { isLoading, subscriptions, txCount } = useContext(TaxReportsContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  // fetch user details
  const { data: account } = useQueryGetAccount(accountId);

  const { hasPermission } = permissionFor({
    action: "get-tax-report",
    subscriptions,
    transactionCount: txCount,
    account,
  });

  const canPurchase = canUnlockPlanFeature(account);

  return (
    <CardContentContainer>
      <ContentHeadingContainer>
        <Flex direction="row" alignItems="center">
          <TaxReportHeading>Final Crypto Tax Report</TaxReportHeading>
        </Flex>
      </ContentHeadingContainer>
      <Text color="black.600" fontSize="0.875rem" m="20px">
        Download your Crypto Tax Report to complete your tax return
      </Text>
      <RowDivider mt="20px" />

      <DocumentContainer>
        <TaxReportRow
          selectedFinancialYear={selectedFinancialYear}
          isLoading={isLoading}
          canDownload={hasPermission}
          canPurchase={canPurchase}
          sampleUrl={
            account?.accountType === AccountType.SMSF
              ? SylaSampleUrls.TaxReportSMSF
              : SylaSampleUrls.TaxReportIndividual
          }
        />
      </DocumentContainer>
    </CardContentContainer>
  );
};
