import { Text } from "@chakra-ui/react";
import React from "react";
import { SylaFormHeader } from "../molecules/forms/SylaFormHeader";
import { FormContainer } from "./Containers";

export const InvalidPage = (): JSX.Element => (
  <FormContainer>
    <SylaFormHeader heading={"Invalid"} />
    <Text>This page is no-longer valid.</Text>
  </FormContainer>
);
