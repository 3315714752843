import { minutesToMilliseconds } from "date-fns";
import { useQuery } from "react-query";
import { getAccounts } from "../api/account/getAccounts";

export const ACCOUNTS_META_STALE_TIME = minutesToMilliseconds(5);

export const ACCOUNT_META_QUERY_KEY = ["account-meta"];
export const useQueryGetAccounts = () =>
  useQuery(ACCOUNT_META_QUERY_KEY, () => getAccounts(), {
    staleTime: ACCOUNTS_META_STALE_TIME,
  });
