import { ExternalLinkIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  VStack,
  Text,
  Box,
  Table,
  Tr,
  Th,
  Tbody,
  Spinner,
  Td,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Menu,
  HStack,
  Link,
} from "@chakra-ui/react";
import { formatDate } from "@syla/shared/helpers/formatting";
import {
  getCurrentSubscription,
  getSubscriptionStatus,
} from "@syla/shared/types/helpers/subscriptions";
import {
  AccountAccessRoleName,
  AccountPermission,
} from "@syla/shared/types/models/AccountAccessBase";
import { ActivityTypeName } from "@syla/shared/types/models/AccountBase";
import { AccountAccessDetails } from "@syla/shared/types/responses/GetAccountsResponse";
import { formatDistanceToNow } from "date-fns";
import { sortBy } from "lodash";
import React, { useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useForm, FormProvider } from "react-hook-form";
import { HiDotsVertical } from "react-icons/hi";
import { Link as RouterLink } from "react-router-dom";
import { ButtonVariant } from "../components/atoms/ButtonVariant";
import {
  PageContainer,
  PageHeadingContainer,
  StdVStack,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { StickyThead, HEADER_Z_INDEX } from "../components/atoms/StickyThead";
import { tableFrameProps } from "../components/atoms/tableFrameProps";
import { TooltipWrapper } from "../components/atoms/TooltipWrapper";
import {
  PageHeadingTagContainer,
  PageHeadingTag,
} from "../components/molecules/PageHeadingTag";
import { AccountNameEditable } from "../components/organisms/accounts/AccountNameEditable";
import { useDeleteAccountDialog } from "../components/organisms/accounts/useDeleteAccountDialog";
import { useRemoveAccountDialog } from "../components/organisms/accounts/useRemoveAccountDialog";
import { useCreateAccountForm } from "../components/organisms/navbar/UseCreateAccountForm";
import { NicknameEditable } from "../components/organisms/accounts/NicknameEditable";
import { getUserDetails } from "../helper/getUserDetails";
import { hasAccountPermission } from "../helper/permissions";
import { ReactComponent as CornerUpRight } from "../images/icons/cornerUpRight.svg";
import {
  buildAccountSettingsLink,
  buildDataSourcesLink,
} from "../routers/routePaths";
import { useQueryGetAccounts } from "../store/useQueryGetAccounts";
import { AccountSettingsPanel } from "./AccountSettings";

const HELP_ARTICLE =
  "https://help.syla.com.au/en/articles/9092794-managing-multiple-accounts";

export const Accounts = () => {
  const openCreateAccountForm = useCreateAccountForm();
  const { data: accountsResponse } = useQueryGetAccounts();

  return (
    <PageContainer>
      <PageHeadingContainer>
        <StdVStack spacing={6}>
          <StdVStack>
            <PageHeadingTagContainer>
              <PageHeading>Accounts</PageHeading>
              {accountsResponse != null && (
                <PageHeadingTag>
                  {Object.values(
                    accountsResponse.accounts
                  ).length.toLocaleString()}
                </PageHeadingTag>
              )}
            </PageHeadingTagContainer>
            <Text color="gray.800">
              Manage all the accounts you have access to
            </Text>
          </StdVStack>
          <Link
            href={HELP_ARTICLE}
            fontWeight="bold"
            color="primary.500"
            isExternal
          >
            Guide to managing accounts <ExternalLinkIcon />
          </Link>
        </StdVStack>

        <Box>
          <ButtonVariant
            content="Create account"
            color="red"
            leftIcon="add"
            ml={isBrowser ? { base: "0", xs: "20px" } : "0"}
            onClick={openCreateAccountForm}
          />
        </Box>
      </PageHeadingContainer>

      <VStack alignItems={"flex-start"} minHeight="50vh" overflowY="scroll">
        <Box width="100%" overflowX="auto" {...tableFrameProps}>
          <AccountsTable />
        </Box>
      </VStack>
    </PageContainer>
  );
};

function AccountsTable() {
  const { data: accountsResponse } = useQueryGetAccounts();

  if (!accountsResponse) return <Spinner />;

  const accounts = Object.values(accountsResponse.accounts);

  return (
    <Table>
      <StickyThead>
        <Tr>
          <Th>Open</Th>
          <Th>Account Name</Th>
          <Th>Nickname</Th>
          <Th>Account</Th>
          <Th>Activity</Th>
          <Th>Role</Th>
          <Th>Members</Th>
          <Th>Subscription</Th>
          <Th>Renewal Date</Th>
          <Th>Billing Owner</Th>
          <Th></Th>
        </Tr>
      </StickyThead>
      <Tbody>
        {sortBy(accounts, (account) => account._id).map((account) => (
          <AccountsRow key={account._id} account={account} />
        ))}
      </Tbody>
    </Table>
  );
}

function AccountsRow({ account }: { account: AccountAccessDetails<string> }) {
  const { openDeleteAccountDialog } = useDeleteAccountDialog({ account });
  const { openRemoveAccountDialog } = useRemoveAccountDialog({ account });

  const subscription = getCurrentSubscription(account.subscriptions);
  const subscriptionStatus =
    subscription && getSubscriptionStatus(subscription);

  const formProps = useForm<{
    nickname: string;
    fullName: string;
  }>({
    defaultValues: account,
  });

  const { reset } = formProps;

  // reset form on data updates
  useEffect(() => {
    reset(account);
  }, [account, reset]);

  return (
    <FormProvider {...formProps}>
      <Tr key={account._id}>
        {/* Open */}
        <Td align="center">
          <IconButton
            as={RouterLink}
            to={buildDataSourcesLink({ accountId: account._id })}
            size="sm"
            borderWidth={1}
            icon={<CornerUpRight />}
            aria-label="Open"
          />
        </Td>

        {/* Account name */}
        <Td minWidth="12rem">
          <AccountNameEditable
            account={account}
            zIndex={HEADER_Z_INDEX - 1}
          ></AccountNameEditable>
        </Td>

        {/* Nickname */}
        <Td minWidth="10rem">
          <NicknameEditable account={account} zIndex={HEADER_Z_INDEX - 1} />
        </Td>
        {/* Account Type */}
        <Td>{account.accountType}</Td>

        {/* Activity Type */}
        <Td>{ActivityTypeName[account.accountSubType]}</Td>

        {/* Role */}
        <Td>{AccountAccessRoleName[account.role]}</Td>

        {/* Members */}
        <Td>
          <HStack
            as={RouterLink}
            to={buildAccountSettingsLink({
              accountId: account._id,
              panel: AccountSettingsPanel.Members,
            })}
          >
            <Text>
              {account.access.length == 1
                ? `Just you`
                : `You and ${account.access.length - 1} others`}
            </Text>
            <ExternalLinkIcon />
          </HStack>
        </Td>

        {/* Subscription */}
        <Td>
          <HStack
            as={RouterLink}
            to={buildAccountSettingsLink({
              accountId: account._id,
              panel: AccountSettingsPanel.Subscription,
            })}
          >
            <Text>{subscription ? subscription.plan.name : "None"}</Text>
            <ExternalLinkIcon />
          </HStack>
        </Td>

        {/* Renewal Date */}
        <Td>
          <VStack alignItems="flex-start">
            {!subscription && <Text>N/A</Text>}
            {subscriptionStatus &&
              (subscriptionStatus.valid && !subscriptionStatus.cancelled ? (
                <Text noOfLines={3}>
                  Expires{" "}
                  <TooltipWrapper
                    tooltip={formatDate(subscriptionStatus.expiryDate)}
                  >
                    {formatDistanceToNow(subscriptionStatus.expiryDate, {
                      addSuffix: true,
                    })}
                  </TooltipWrapper>
                </Text>
              ) : (
                <>
                  <Text>
                    {subscriptionStatus.cancelled
                      ? "Cancelled"
                      : subscriptionStatus.expired
                      ? "Expired"
                      : "Expired (Action Needed)"}
                  </Text>
                  <TooltipWrapper
                    tooltip={formatDate(subscriptionStatus.expiryDate)}
                  >
                    <Text>
                      {subscriptionStatus.cancelled && "Expires "}
                      {formatDistanceToNow(subscriptionStatus.expiryDate, {
                        addSuffix: true,
                      })}
                    </Text>
                  </TooltipWrapper>
                </>
              ))}
          </VStack>
        </Td>

        {/* Billing Owner */}
        <Td>
          {!subscription && <Text>N/A</Text>}
          {subscription &&
            (subscription.billingAccountAccess ? (
              <Text>You</Text>
            ) : (
              <Text>
                {subscription.billingOwner
                  ? getUserDetails(subscription.billingOwner)
                  : "Unknown"}
              </Text>
            ))}
        </Td>

        {/* Overflow Menu */}
        <Td>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HiDotsVertical />}
              backgroundColor="transparent"
            />
            <MenuList p="0">
              {!account.onlyOwner && (
                <MenuItem
                  textColor="red.600"
                  onClick={() => {
                    openRemoveAccountDialog();
                  }}
                  icon={<DeleteIcon />}
                >
                  Remove
                </MenuItem>
              )}
              {hasAccountPermission(
                account,
                AccountPermission.DeleteAccount
              ) && (
                <MenuItem
                  textColor="red.600"
                  onClick={() => {
                    openDeleteAccountDialog();
                  }}
                  icon={<DeleteIcon />}
                >
                  Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Td>
      </Tr>
    </FormProvider>
  );
}
