"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractString = void 0;
/**
 * Contract a string longer than maxLength by eliding the middle.
 * e.g. "abc..hij"
 *
 * @param text
 * @param maxLength Maximum number of characters
 */
const contractString = (text, maxLength) => text.length <= maxLength
    ? text
    : `${text.slice(0, maxLength / 2 - 1)}..${text.slice(-(maxLength / 2 - 1))}`;
exports.contractString = contractString;
