import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetReferralsResponseBody } from "@syla/shared/types/responses/GetReferralsResponse";
import axios from "axios";
import { useQuery } from "react-query";

export const REFERRALS_QUERY_KEY = "referrals";

export const useQueryGetReferrals = () =>
  useQuery([REFERRALS_QUERY_KEY], getReferrals);

export const getReferrals = async (): Promise<
  GetReferralsResponseBody<string>
> => {
  const { data } = await axios.get<
    TransportType<GetReferralsResponseBody<string>>
  >("/users/referrals");

  return data;
};
