import {
  SyncState,
  FileImportState,
} from "@syla/shared/types/models/WalletBase";
import { Wallet } from "../types/wallet/wallet";

export function walletStateProps(wallet: Wallet | undefined) {
  const syncInProgress =
    wallet?.syncState == SyncState.Init ||
    wallet?.syncState == SyncState.InProgress;

  const fileImportInProgress =
    wallet?.fileImportState == FileImportState.Init ||
    wallet?.fileImportState == FileImportState.InProgress;

  const walletPropsLoading = !wallet || syncInProgress || fileImportInProgress;

  return {
    syncInProgress,
    fileImportInProgress,
    txCountLoading: walletPropsLoading || wallet.transactionCount == null,
    holdingsLoading: walletPropsLoading || wallet?.holdings == null,
  };
}
