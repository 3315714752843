import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../../contexts/ModalContext";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useMutationUpdateAccount } from "../../../../store/useMutationUpdateAccount";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { Form, GeneralInput } from "../FormComponents";

// TODO: support different country codes

interface ContactNumberSettingHookForm {
  contactNumber: string;
}

export const ContactNumberForm = ({
  currentCountryCode,
  currentNumber,
}: {
  currentCountryCode: string;
  currentNumber: string;
}) => {
  const [inputError, setInputError] = useState<string | undefined>();
  const { closeModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(accountId);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ContactNumberSettingHookForm>();

  const onSubmit = async (data: ContactNumberSettingHookForm) => {
    const { contactNumber } = data;
    setInputError(undefined);

    if (contactNumber === currentNumber) {
      closeModal();
      return;
    }

    try {
      // if contact number is an empty string, set country code to empty string too
      await updateAccount({
        contactNumber: {
          countryCode: contactNumber ? currentCountryCode || "+61" : "",
          number: contactNumber,
        },
      });
      closeModal();
    } catch (error) {
      setInputError("Unexpected error occurred, please try again later");
    }
  };

  return (
    <Flex direction="column" w="100%">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <GeneralInput
          isSubmitting={isSubmitting}
          type="text"
          leftAddon={currentCountryCode || "+61"}
          placeholder="e.g. 412 345 678"
          {...register("contactNumber")}
          defaultValue={currentNumber}
          fieldError={errors.contactNumber}
        />

        {inputError && (
          <FormControl isInvalid={inputError !== undefined}>
            <FormErrorMessage>{inputError}</FormErrorMessage>
          </FormControl>
        )}
        <ButtonVariant
          content="Save changes"
          outlineType="solid"
          color="red"
          onClick={() => undefined}
          isLoading={isSubmitting}
          type="submit"
          spam="spam"
          mt="15px"
          mb="10px"
        />
      </Form>
    </Flex>
  );
};
