import { Box, Button } from "@chakra-ui/react";
import { permissionFor } from "@syla/shared/types/helpers/permissions";
import React, { useState } from "react";
import { MdFileDownload } from "react-icons/md";
import { downloadHoldingStatementCsv } from "../../../api/user/downloadHoldingStatementCsv";
import { useReportError } from "../../../helpers/useReportError";
import { Account } from "../../../types/Account";
import { TooltipWrapper } from "../../atoms/TooltipWrapper";

export function DownloadHoldingStatement({
  account,
  byAsset,
  withMarketValue,
  balancesDate,
}: {
  account: Account | undefined;
  byAsset: boolean;
  withMarketValue: boolean;
  balancesDate: Date | undefined;
}) {
  const reportError = useReportError();
  const [isDownloading, setIsDownloading] = useState(false);

  const { hasPermission } = permissionFor({
    action: "get-holding-statement",
    transactionCount: undefined,
    subscriptions: account ? account.subscriptions : [],
    account,
  });

  return (
    <Box>
      <TooltipWrapper
        tooltip={
          hasPermission
            ? "Download Holding Statement CSV format"
            : "Holding Statements are only available on an Assurance or higher plan"
        }
      >
        <Button
          variant="outline"
          leftIcon={<MdFileDownload />}
          isDisabled={!hasPermission || !account}
          isLoading={isDownloading}
          onClick={(event) => {
            event.stopPropagation();
            setIsDownloading(true);
            downloadHoldingStatementCsv({
              accountId: account!._id,
              byAsset,
              marketValue: withMarketValue,
              balanceDate: balancesDate,
            })
              .catch((error) => {
                console.error("downloadHoldingStatementCsv error", error);
                // don't use actual error since response is a blob
                reportError(new Error());
              })
              .finally(() => setIsDownloading(false));
          }}
        >
          Download Holding Statement
        </Button>
      </TooltipWrapper>
    </Box>
  );
}
