import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { CreateReferralResponseBody } from "@syla/shared/types/responses/CreateReferralResponse";
import axios from "axios";

export const createReferral = async (): Promise<CreateReferralResponseBody> => {
  const { data } = await axios.post<TransportType<CreateReferralResponseBody>>(
    "/users/create-referral"
  );
  return data;
};
