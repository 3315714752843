import {
  get_COINJAR_CLIENT_ID,
  COINJAR_USE_SANDBOX,
} from "@syla/shared/types/helpers/constants";
import { isProduction } from "../helper/environment";

const COINJAR_REQUESTED_SCOPES = ["public", "reports"];

export const getCoinJarAuthoriseUrl = ({
  walletId,
  accountId,
}: {
  walletId: string;
  accountId: string;
}) =>
  getCoinJarAuthoriseUrlInternal({
    redirectUrl: getCoinJarRedirectUri(),
    stateProps: {
      walletId,
      accountId,
    },
  });

const getCoinJarAuthoriseUrlInternal = ({
  redirectUrl,
  stateProps,
}: {
  redirectUrl: string;
  stateProps: Record<string, string>;
}) =>
  `${getCoinJarAuthHost(isProduction())}/oauth/authorize` +
  `?response_type=code` +
  `&scope=${encodeURIComponent(COINJAR_REQUESTED_SCOPES.join(" "))}` +
  `&client_id=${get_COINJAR_CLIENT_ID(isProduction())}` +
  `&redirect_uri=${encodeURIComponent(redirectUrl)}` +
  `&state=${encodeURIComponent(new URLSearchParams(stateProps).toString())}`;

const getCoinJarAuthHost = (isProduction: boolean) =>
  isProduction || !COINJAR_USE_SANDBOX
    ? "https://secure.coinjar.com"
    : "https://secure.coinjar-sandbox.com";

export const getCoinJarRedirectUri = () =>
  `${window.location.origin}/data-sources/coinjar-callback`;

// other CoinJar props also set at
// packages/express-app/src/helpers/datasources/coinJar/coinJarConstants.ts
// packages/shared/types/helpers/constants.ts
