import { Link, Text, Box, HStack } from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const NavLink = ({
  children,
  to,
  icon,
  active,
  onClose,
}: {
  children: string | React.ReactNode;
  to: string;
  icon: React.ReactNode;
  active: boolean;
  onClose: () => void;
}) => {
  return (
    <Box p="0.5rem">
      <Link
        to={to}
        as={RouterLink}
        onClick={onClose}
        style={{ textDecoration: "none" }}
        _hover={{ textDecoration: "none" }}
      >
        <HStack
          backgroundColor={active ? "gray.700" : "transparent"}
          role="group"
          _hover={{ bg: "gray.700" }}
          rounded="md"
          w="100%"
          p="0.5rem"
          alignItems="center"
          spacing="0.75rem"
        >
          <Box
            color={active ? "primary.500" : "gray.300"}
            _groupHover={{ color: active ? "primary.500" : "gray.25" }}
            boxSize="1.5rem"
          >
            {icon}
          </Box>
          <Text
            fontSize="md"
            color={active ? "gray.25" : "gray.300"}
            _groupHover={{ color: "gray.25" }}
          >
            {children}
          </Text>
        </HStack>
      </Link>
    </Box>
  );
};
