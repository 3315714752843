import {
  useToast,
  HStack,
  GridItem,
  Text,
  ButtonGroup,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  VStack,
  Link,
  StackProps,
} from "@chakra-ui/react";
import { formatDate } from "@syla/shared/helpers/formatting";
import {
  getSubscriptionStatus,
  getCurrentSubscription,
} from "@syla/shared/types/helpers/subscriptions";
import { StripeBillingPortalAction } from "@syla/shared/types/requests/CreateStripeBillingPortalRequest";
import React, { Fragment, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { errorToastOptions } from "../../components/molecules/errorToastOptions";
import { captureRequestError } from "../../helper/captureRequestError";
import { getFullPageUrl } from "../../helper/getFullPageUrl";
import {
  canPurchaseSubscription,
  canUpgradeSubscription,
} from "../../helper/permissions";
import { redirectTo } from "../../helper/RedirectTo";
import { useNavigator, Route } from "../../routers/navigator";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useMutationCreateStripeBillingPortalSession } from "../../store/useMutationCreateStripeBillingPortalSession";
import { Account } from "../../types/Account";
import { Subscription } from "../../types/plans/subscription";

export const SubscriptionStatus = ({
  subscriptions,
  account,
  isRedirecting,
  setIsRedirecting,
}: {
  subscriptions: Subscription[];
  account: Account;
  isRedirecting: boolean;
  setIsRedirecting: (value: boolean) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigator();
  const errorToast = useToast(errorToastOptions);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { mutateAsync: createStripeBillingPortalSession, isLoading } =
    useMutationCreateStripeBillingPortalSession();

  const [loadingState, setLoadingState] = useState({
    cancel: false,
    renew: false,
  });

  const currentSub = getCurrentSubscription(subscriptions);
  const currentSubStatus = currentSub && getSubscriptionStatus(currentSub);

  const planDateHeader =
    (currentSubStatus && currentSubStatus.cancelled) ||
    currentSubStatus?.expired
      ? "Plan expiry date"
      : "Renewal Date";

  const planDate = currentSubStatus
    ? formatDate(
        currentSubStatus.valid && !currentSubStatus.cancelled
          ? currentSubStatus.renewalDate
          : currentSubStatus.expiryDate
      )
    : undefined;

  const showRenew =
    currentSub &&
    currentSub.billingAccountAccess &&
    currentSub.stripeSubscriptionId &&
    (currentSubStatus?.expired || currentSubStatus?.cancelled);

  const renewAction = useCallback(async () => {
    try {
      setIsRedirecting(true);
      setLoadingState({ ...loadingState, renew: true });
      const response = await createStripeBillingPortalSession({
        accountId,
        action: StripeBillingPortalAction.Renew,
        stripeSubscriptionId: currentSub!.stripeSubscriptionId!,
        returnUrl: getFullPageUrl(location),
      });
      redirectTo(response.portalUrl);
    } catch (error) {
      setIsRedirecting(false);
      setLoadingState({ ...loadingState, renew: false });
      captureRequestError(error);
      errorToast({
        title: "Error cancelling plan",
        description: "An unexpected error occurred. Please try again later.",
      });
    }
  }, [
    accountId,
    createStripeBillingPortalSession,
    currentSub,
    errorToast,
    loadingState,
    location,
    setIsRedirecting,
  ]);

  const CancelledPlanAlert = useCallback(
    () => (
      <PlanAlert
        title={<>Your subscription has been cancelled</>}
        description={
          <>
            The current subscription will expire on <b>{planDate}</b>. Please
            renew your plan to retain access to your account
          </>
        }
        action={<>Renew your subscription now</>}
        onClickAction={renewAction}
        isDisabled={isRedirecting}
      />
    ),
    [isRedirecting, planDate, renewAction]
  );

  const manageBillingAction = useCallback(async () => {
    try {
      setIsRedirecting(true);
      const response = await createStripeBillingPortalSession({
        accountId,
        action: StripeBillingPortalAction.Renew,
        stripeSubscriptionId: currentSub!.stripeSubscriptionId!,
        returnUrl: getFullPageUrl(location),
      });
      redirectTo(response.portalUrl);
    } catch (error) {
      setIsRedirecting(false);
      captureRequestError(error);
      reportError(error);
    }
  }, [
    accountId,
    createStripeBillingPortalSession,
    currentSub,
    location,
    setIsRedirecting,
  ]);

  const BillingIssueAlert = useCallback(
    () => (
      <PlanAlert
        title={`There's an issue with the billing on your account`}
        description={
          <>
            Please update your billing details to retain access to your account
          </>
        }
        action={<>Update billing details</>}
        onClickAction={manageBillingAction}
        isDisabled={isRedirecting}
      />
    ),
    [isRedirecting, manageBillingAction]
  );

  const billingIssue =
    currentSub?.billingAccountAccess &&
    currentSubStatus &&
    !currentSubStatus.expired &&
    !currentSubStatus.valid;

  return (
    <Fragment>
      {billingIssue && (
        <GridItem colSpan={2}>
          <BillingIssueAlert />
        </GridItem>
      )}
      {/* Alerts */}
      {!billingIssue && showRenew && (
        <GridItem colSpan={2}>
          <CancelledPlanAlert />
        </GridItem>
      )}

      {/* Current Plan */}
      <GridItem>
        <Text fontWeight="bold">Current plan</Text>
      </GridItem>

      {/* Current plan content */}
      <GridItem>
        <ValueAndButtons>
          <Text>
            {!currentSub
              ? "Free"
              : `${currentSub.plan.name}${
                  currentSubStatus?.cancelled
                    ? " (Cancelled)"
                    : currentSubStatus?.expired
                    ? " (Expired)"
                    : !currentSubStatus?.valid
                    ? " (Action needed)"
                    : ""
                }`}
          </Text>
          <ButtonGroup>
            {/* Purchase button */}
            {canPurchaseSubscription({ account, subscriptions }) && (
              <ButtonVariant
                content={"Purchase"}
                color="red"
                disabled={isRedirecting}
                onClick={() =>
                  navigate({
                    route: Route.Plans,
                    accountId,
                    state: {
                      referringUrl: getFullPageUrl(location),
                    },
                  })
                }
              />
            )}

            {/* Upgrade Button */}
            {currentSubStatus &&
              (currentSubStatus.valid || currentSubStatus.expired) &&
              canUpgradeSubscription({ subscriptions }) && (
                <ButtonVariant
                  content="Upgrade"
                  color={currentSubStatus.cancelled ? "default" : "red"}
                  outlineType={
                    currentSubStatus.cancelled ? "outlineBlack" : "solid"
                  }
                  disabled={isRedirecting}
                  onClick={() =>
                    navigate({
                      route: Route.Plans,
                      accountId,
                      state: {
                        referringUrl: getFullPageUrl(location),
                      },
                    })
                  }
                />
              )}
          </ButtonGroup>
        </ValueAndButtons>
      </GridItem>

      {currentSubStatus && (
        <>
          <GridItem>
            <VStack alignItems="flex-start">
              <Text fontWeight="bold">{planDateHeader}</Text>
              <Text>
                Your subscription will automatically renew on this date for
                another 12-month period
              </Text>
            </VStack>
          </GridItem>

          {/* Current plan content */}
          <GridItem>
            <ValueAndButtons>
              <Text>{planDate}</Text>
              <ButtonGroup>
                {/* Cancel Button */}
                {currentSub &&
                  currentSubStatus &&
                  currentSub.billingAccountAccess &&
                  currentSub.stripeSubscriptionId &&
                  !currentSubStatus.cancelled && (
                    <ButtonVariant
                      content="Cancel subscription"
                      outlineType="ghost"
                      rightIcon="external"
                      color="red"
                      disabled={isRedirecting}
                      isLoading={loadingState.cancel}
                      onClick={async () => {
                        try {
                          setIsRedirecting(true);
                          setLoadingState({ ...loadingState, cancel: true });
                          const response =
                            await createStripeBillingPortalSession({
                              accountId,
                              action: StripeBillingPortalAction.Cancel,
                              stripeSubscriptionId:
                                currentSub!.stripeSubscriptionId!,
                              returnUrl: getFullPageUrl(location),
                            });
                          redirectTo(response.portalUrl);
                        } catch (error) {
                          setIsRedirecting(false);
                          setLoadingState({ ...loadingState, cancel: false });
                          captureRequestError(error);
                          errorToast({
                            title: "Error cancelling plan",
                            description:
                              "An unexpected error occurred. Please try again later.",
                          });
                        }
                      }}
                    ></ButtonVariant>
                  )}

                {/* Renew Button */}
                {showRenew && (
                  <ButtonVariant
                    content="Renew"
                    rightIcon="external"
                    color="red"
                    disabled={isRedirecting}
                    isLoading={loadingState.renew}
                    onClick={renewAction}
                  ></ButtonVariant>
                )}
              </ButtonGroup>
            </ValueAndButtons>
          </GridItem>
        </>
      )}
    </Fragment>
  );
};

export const ValueAndButtons = (props: StackProps) => (
  <HStack spacing={8} {...props} />
);

function PlanAlert({
  action,
  description,
  onClickAction,
  title,
  isDisabled,
}: {
  title: React.ReactNode;
  description: React.ReactNode;
  action: React.ReactNode;
  onClickAction: () => Promise<void>;
  isDisabled?: boolean;
}) {
  return (
    <Alert
      status="warning"
      flexDirection="row"
      alignItems="flex-start"
      variant="top-accent"
    >
      <AlertIcon />
      <VStack alignItems="flex-start">
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>{description}</AlertDescription>

        <Link onClick={!isDisabled ? onClickAction : undefined}>
          <AlertTitle>{action} →</AlertTitle>
        </Link>
      </VStack>
    </Alert>
  );
}
