import { Box, Text, FormLabel } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const ModalText = styled(Text)`
  font-size: 0.9rem;
  color: #666666;
  text-align: center;
`;

export const PrimaryText = styled(Text)`
  font-size: 0.9rem;
  color: #666;
  font-size: 0.875rem;
`;

export const Label = styled(FormLabel)`
  font-size: 0.875rem;
  color: #4d4d4d;
  margin-right: 5px;
`;

export const Divider = styled(Box)`
  background-color: #ccc;
  width: 1px;
  height: 18px;
`;
