import { useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { SpanStatusType } from "@sentry/tracing";
import { formatDate } from "@syla/shared/helpers/formatting";
import React, { useState } from "react";
import { downloadSupportCsv } from "../../../api/user/downloadSupportCsv";
import reportCsv from "../../../images/icons/reportCsv.svg";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useGlobalVariablesStore } from "../../../store/globalVariablesStore";
import {
  SupportCsvDocument,
  UserRemainingParcels,
} from "../../../constants/SupportCsvDocument";
import { DocumentRow } from "./DocumentRow";

interface SupportDocumentRowProps {
  isLoading: boolean;
  canDownload: boolean;
  csvDocument: SupportCsvDocument;
  canPurchase: boolean;
  sampleUrl: string;
}

export const SupportDocumentRow = ({
  isLoading,
  canDownload,
  canPurchase,
  csvDocument,
  sampleUrl,
}: SupportDocumentRowProps): JSX.Element => {
  const toast = useToast();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );

  // get the years from the selected financial year
  const startYear = selectedFinancialYear?.startDate.getFullYear();
  const endYear = selectedFinancialYear?.endDate.getFullYear();

  const [downloading, setDownloading] = useState(false);

  // handle download click
  const handleDownload = async () => {
    if (!selectedFinancialYear) {
      toast({
        title: "Financial year not selected",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }

    // instrument
    const transaction = Sentry.startTransaction({
      name: `/tax-reports/${csvDocument.description}`,
      data: { financialYearValue: selectedFinancialYear.value },
    });

    const span = transaction.startChild({
      op: "task",
      description: csvDocument.description,
    });

    try {
      setDownloading(true);
      // Set transaction on scope to associate with errors and get included span instrumentation
      Sentry.getCurrentHub().configureScope((scope) =>
        scope.setSpan(transaction)
      );
      await downloadSupportCsv({
        accountId,
        financialYearValue: selectedFinancialYear.value,
        csvDocument,
      });
      span.setStatus("ok" as SpanStatusType);
    } catch (error: any) {
      span.setStatus("unknown_error" as SpanStatusType);

      toast({
        title: `Error downloading ${csvDocument.name}`,
        description: "Please contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    span.finish();
    transaction.finish();

    setDownloading(false);
  };

  const yearEndDate = selectedFinancialYear?.endDate;

  return (
    <DocumentRow
      isLoading={isLoading}
      downloading={downloading}
      canDownload={canDownload}
      canPurchase={canPurchase}
      image={reportCsv}
      sampleUrl={sampleUrl}
      documentName={
        csvDocument == UserRemainingParcels
          ? `${yearEndDate && formatDate(yearEndDate)} ${csvDocument.name}`
          : `${startYear} - ${endYear} ${csvDocument.name}`
      }
      handleDownload={handleDownload}
    />
  );
};
