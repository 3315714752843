import { Stack, VStack } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import React, { useContext, useEffect } from "react";
import { isBrowser } from "react-device-detect";
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";

import { GlobalModal } from "../components/molecules/GlobalModal";
import { AccountBar } from "../components/organisms/AccountBar";
import { Navbar } from "../components/organisms/navbar/Navbar";
import { UserContext, useUserContext } from "../contexts/UserContext";
import { AccountRouteParams } from "../helper/accountRouteParams";
import { Accounts } from "../pages/Accounts";
import { AccountSettings } from "../pages/AccountSettings";
import { Affiliate } from "../pages/Affiliate";
import { Balances } from "../pages/Balances";
import { Dashboard } from "../pages/Dashboard";
import { DataSources } from "../pages/DataSources";
import { FirstDataSource } from "../pages/FirstDataSource";
import { FirstDataSourceChild } from "../pages/FirstDataSourceChild";
import { FirstDataSourceCustom } from "../pages/FirstDataSourceCustom";
import { Plans } from "../pages/Plans";
import { TaxReports } from "../pages/TaxReports";
import { Transactions } from "../pages/Transactions";
import { UserSettings } from "../pages/UserSettings";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { stopPollingPendingWork, pollPendingWork } from "../store/pendingWork";
import { resetTransactionStore, queryClient } from "../store/transactionsStore";
import { OauthCallback } from "../pages/OauthCallback";
import { AccountRouteHelper } from "./AccountRouteHelper";
import { renderRoutedComponentWithFallback } from "./renderRoutedComponentWithFallback";
import { buildDashboardLink, buildFirstDataSourceLink } from "./routePaths";
import { LoggedOutPageContainer } from "./Unauthenticated";

export const Authenticated = (): JSX.Element => {
  const { authenticated, firstLogin, userDetails } = useContext(UserContext);

  useEffect(() => {
    if (authenticated) console.debug("Authenticated");
  }, [authenticated]);

  // stop polling on logout and clear caches
  useEffect(() => {
    return () => {
      stopPollingPendingWork();
      resetTransactionStore();
      queryClient.clear();
    };
  }, []);

  // if not !authenticated then some queries that hit authenticated routes will fail
  // so delay loading these routes till auth is in place
  // return temp element to prevent default route from running
  if (!authenticated) return <></>;

  return (
    <Switch>
      <SentryRoute
        exact
        path="/data-sources/coinjar-callback" // no need for account id in route since it comes in redirect url params
        render={renderRoutedComponentWithFallback(() => (
          <OauthCallback dataSourceCode={DataSourceCode.CoinJar} />
        ))}
      ></SentryRoute>
      <SentryRoute
        exact
        path="/data-sources/coinbase-callback"
        render={renderRoutedComponentWithFallback(() => (
          <OauthCallback dataSourceCode={DataSourceCode.Coinbase} />
        ))}
      ></SentryRoute>
      <SentryRoute path="/:accountId">
        <AccountRouter />
      </SentryRoute>
      <SentryRoute path="*">
        <Redirect
          to={
            firstLogin
              ? buildFirstDataSourceLink({
                  accountId: userDetails?.defaultAccountId ?? "",
                })
              : buildDashboardLink({
                  accountId: userDetails?.defaultAccountId ?? "",
                })
          }
        />
      </SentryRoute>
    </Switch>
  );
};

const LoggedInPageContainer = ({
  children,
}: {
  children?: React.ReactNode;
}) => (
  <Stack
    h="100%"
    w="100%"
    spacing={0}
    direction={isBrowser ? { base: "column", xs: "row" } : "column"}
  >
    <GlobalModal />

    <Navbar />

    <VStack
      flexGrow={1}
      min-width={0}
      overflow="hidden"
      height="100%"
      spacing={0}
    >
      <AccountBar />
      <VStack
        bgColor="gray.25"
        overflowY="auto"
        spacing={0}
        alignItems="left"
        flexGrow={1}
        width="100%"
      >
        {children}
      </VStack>
    </VStack>
  </Stack>
);

const SentryRoute = Sentry.withSentryRouting(Route);

const AccountRouter = () => {
  const { path } = useRouteMatch();
  const setAccountId = useCurrentAccountStore(
    ({ setAccountId }) => setAccountId
  );

  const { authenticated } = useUserContext();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { accountId: routeAccountId } = useParams<AccountRouteParams>();

  // set account id based off route
  useEffect(() => {
    if (routeAccountId) {
      setAccountId(routeAccountId);
    }
  }, [routeAccountId, setAccountId]);

  // poll pending work once we have accountId
  useEffect(() => {
    if (accountId) {
      pollPendingWork();
    }
  }, [accountId, authenticated]);

  // don't render the rest till we have an accountId
  if (!accountId) {
    return <></>;
  }

  return (
    <>
      <AccountRouteHelper />
      <Switch>
        <SentryRoute
          exact
          path={`${path}/dashboard`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Dashboard />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/data-sources`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <DataSources />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/transactions`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Transactions />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/tax-reports`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <TaxReports />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/account-settings`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <AccountSettings />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          path={`${path}/account-settings/:panel`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <AccountSettings />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/user-profile`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <UserSettings />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/plans`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Plans />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/affiliate`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Affiliate />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/accounts`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Accounts />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          exact
          path={`${path}/balances`}
          render={renderRoutedComponentWithFallback(() => (
            <LoggedInPageContainer>
              <Balances />
            </LoggedInPageContainer>
          ))}
        ></SentryRoute>
        <SentryRoute
          path={`${path}/first-custom-data-source/:customSourceName`}
        >
          <LoggedOutPageContainer>
            <FirstDataSourceCustom />
          </LoggedOutPageContainer>
        </SentryRoute>
        <SentryRoute path={`${path}/first-custom-data-source/`}>
          <LoggedOutPageContainer>
            <FirstDataSourceCustom />
          </LoggedOutPageContainer>
        </SentryRoute>
        <SentryRoute path={`${path}/first-data-source/:sourceId`}>
          <LoggedOutPageContainer>
            <FirstDataSourceChild />
          </LoggedOutPageContainer>
        </SentryRoute>

        <SentryRoute exact path={`${path}/first-data-source`}>
          <LoggedOutPageContainer>
            <FirstDataSource />
          </LoggedOutPageContainer>
        </SentryRoute>
        <SentryRoute path="*">
          <Redirect to={buildDashboardLink({ accountId })} />
        </SentryRoute>
      </Switch>
    </>
  );
};
