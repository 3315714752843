import {
  FormControl,
  Input,
  FormErrorMessage,
  Text,
  Button,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { forgotPassword } from "../../../api/authentication/forgotPassword";
import { useNavigator, Route } from "../../../routers/navigator";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { FormContainer } from "../../atoms/Containers";
import { UnexpectedError } from "../../atoms/UnexpectedError";
import { Label, Form } from "../forms/FormComponents";
import { SylaFormHeader } from "../forms/SylaFormHeader";

export type ForgotPasswordNavState = {
  email?: string;
};

export const ForgotPasswordForm = (): JSX.Element => {
  // prefill email if possible
  const location = useLocation<ForgotPasswordNavState | undefined>();

  // set up form
  interface ForgotPasswordFormData {
    email: string;
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordFormData>({
    defaultValues: { email: location.state?.email },
  });

  const navigate = useNavigator();
  const history = useHistory();

  const [error, setError] = useState<boolean>();

  const onSubmit = async ({ email }: ForgotPasswordFormData) => {
    try {
      // throw new Error();
      await forgotPassword(email);
      setError(false);
      navigate({
        route: Route.ForgotPasswordSent,
        state: { email },
      });
    } catch (error) {
      setError(true);
    }
  };

  return (
    <FormContainer>
      <SylaFormHeader heading={"Reset your password"} subText={"to Syla"} />
      <Text mb="20px">
        Enter the email address associated with your account and we’ll send you
        an email to reset your password.
      </Text>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl
          isDisabled={isSubmitting}
          isInvalid={errors?.email !== undefined}
          mb="20px"
        >
          <Label content="Email address" />
          <Input
            type="email"
            placeholder="Type your email"
            {...register("email", {
              required: "Email is missing",
              validate: (email?: string) =>
                isEmail(email || "") ? undefined : "Invalid email address",
            })}
            bgColor="white.0"
          />
          {errors.email && (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <ButtonVariant
          content="Continue"
          color="red"
          onClick={() => {}}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          spam="spam"
          type="submit"
        />
        {error && (
          <FormControl isInvalid={true}>
            <FormErrorMessage>{UnexpectedError()}</FormErrorMessage>
          </FormControl>
        )}
        <Text mt="40px">
          <Button
            color="red.500"
            disabled={isSubmitting}
            onClick={() => history.push("/sign-in")}
            variant="link"
          >
            Return to log in
          </Button>
        </Text>
      </Form>
    </FormContainer>
  );
};
