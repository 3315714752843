import { Id } from "@syla/shared/types/helpers/Id";
import { AssetValueService } from "@syla/shared/helpers/assets/AssetValueService";
import { minutesToMilliseconds } from "date-fns";
import { sortBy, uniqBy } from "lodash";
import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { valueAssets } from "../api/asset/valueAssets";

type ReturnType = AssetValueService<string> | undefined;

export const useValueAssets = (
  props:
    | { baseAsset: Id<string>; assets: Id<string>[]; valueDate?: Date }
    | undefined,
  options?: Omit<UseQueryOptions<ReturnType>, "queryKey" | "queryFn">
) => {
  const requestProps: typeof props = props && {
    ...props,
    baseAsset: { _id: props.baseAsset._id },

    // optimise caching
    assets: sortBy(
      uniqBy(
        props.assets.map((asset) => ({
          _id: asset._id,
        })),
        (asset) => asset._id
      ),
      (asset) => asset._id
    ),
  };

  return useQuery<ReturnType>(
    ["assets", "valuations", requestProps],
    async () =>
      requestProps && new AssetValueService(await valueAssets(requestProps)),
    {
      staleTime: minutesToMilliseconds(30), // refresh valuations after
      ...options,
    }
  );
};
