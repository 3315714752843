import { WarningIcon, CloseIcon } from "@chakra-ui/icons";
import {
  UseToastOptions,
  RenderProps,
  HStack,
  VStack,
  IconButton,
  Box,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { getSyncWalletErrorProps } from "./getSyncWalletErrorProps";

const errorColor = "red.500";
const infoColor = "blue.500";

export function buildSyncErrorToastProps(
  error: any,
  walletName?: string
): UseToastOptions {
  const { messageTitle, isInfo, errorTitle, errorContent } =
    getSyncWalletErrorProps(error);

  return {
    status: "error",
    position: "bottom",
    duration: null,
    render(props: RenderProps): React.ReactNode {
      const bgColor = isInfo ? infoColor : errorColor;
      return (
        <HStack
          p="16px"
          borderRadius="16px"
          fontSize="sm"
          bgColor={bgColor}
          textColor="white"
          h="100%"
          alignItems="stretch"
          spacing={4}
        >
          <VStack alignItems="baseline" pt="12px">
            <Box display="inline-block">
              <WarningIcon fontSize="lg" />
            </Box>
          </VStack>
          <VStack alignItems="center" textAlign="center">
            <Text color="white" fontWeight="bold" fontSize="lg">
              {messageTitle}
            </Text>
            <VStack fontSize="md">
              <Text fontWeight="bold">{errorTitle}</Text>
              {errorContent}
            </VStack>
          </VStack>
          <VStack alignItems="center">
            <IconButton
              bgColor={bgColor}
              aria-label="Close"
              icon={<CloseIcon />}
              onClick={() => props.onClose()}
            />
          </VStack>
        </HStack>
      );
    },
  };
}
