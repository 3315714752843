import { Tabs, TabList, TabPanels, TabPanel, Tab } from "@chakra-ui/react";
import React from "react";
import { isBrowser } from "react-device-detect";
import { useParams, Link as ReactRouterLink } from "react-router-dom";
import {
  PageHeadingContainer,
  PageContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { SCREEN_SIZE } from "../constants/common";
import { buildAccountSettingsLink } from "../routers/routePaths";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { AccountGeneralSettings } from "./accountSettings/AccountGeneralSettings";
import { AccountMembersSettings } from "./accountSettings/AccountMembersSettings";
import { AccountOtherSettings } from "./accountSettings/AccountOtherSettings";
import { AccountSubscriptionSettings } from "./accountSettings/AccountSubscriptionSettings";
import { AccountTaxSettings } from "./accountSettings/AccountTaxSettings";

export enum AccountSettingsPanel {
  Tax = "tax",
  General = "general",
  Subscription = "subscription",
  Members = "members",
  Other = "other",
}

const PANEL_TABS: AccountSettingsPanel[] = [
  AccountSettingsPanel.Tax,
  AccountSettingsPanel.General,
  AccountSettingsPanel.Subscription,
  AccountSettingsPanel.Members,
  AccountSettingsPanel.Other,
];

export function AccountSettings() {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { panel } = useParams<{ panel?: AccountSettingsPanel }>();

  const matchingIndex = PANEL_TABS.indexOf(panel || AccountSettingsPanel.Tax);
  const tabIndex = matchingIndex > -1 ? matchingIndex : 0;

  return (
    <PageContainer isBrowser={isBrowser}>
      <PageHeadingContainer>
        <PageHeading>Settings</PageHeading>
      </PageHeadingContainer>
      <Tabs isLazy index={tabIndex}>
        <TabList
          width="100%"
          sx={{
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Tab
            as={ReactRouterLink}
            to={buildAccountSettingsLink({
              accountId,
              panel: AccountSettingsPanel.Tax,
            })}
          >
            Tax
          </Tab>

          <Tab
            as={ReactRouterLink}
            to={buildAccountSettingsLink({
              accountId,
              panel: AccountSettingsPanel.General,
            })}
          >
            General
          </Tab>

          <Tab
            as={ReactRouterLink}
            to={buildAccountSettingsLink({
              accountId,
              panel: AccountSettingsPanel.Subscription,
            })}
          >
            Subscription
          </Tab>

          <Tab
            as={ReactRouterLink}
            to={buildAccountSettingsLink({
              accountId,
              panel: AccountSettingsPanel.Members,
            })}
          >
            Members
          </Tab>

          <Tab
            as={ReactRouterLink}
            to={buildAccountSettingsLink({
              accountId,
              panel: AccountSettingsPanel.Other,
            })}
          >
            Other
          </Tab>
        </TabList>

        <TabPanels maxWidth={SCREEN_SIZE.LG} overflowY="auto">
          <TabPanel px={0} pt="2rem">
            <AccountTaxSettings />
          </TabPanel>

          <TabPanel px={0} pt="2rem">
            <AccountGeneralSettings />
          </TabPanel>

          <TabPanel px={0} pt="2rem">
            <AccountSubscriptionSettings />
          </TabPanel>

          <TabPanel px={0} pt="2rem">
            <AccountMembersSettings />
          </TabPanel>

          <TabPanel px={0} pt="2rem">
            <AccountOtherSettings />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  );
}
