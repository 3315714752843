import { Link, Spinner, Text } from "@chakra-ui/react";
import { throwError } from "@syla/shared/types/helpers/errors";
import React, { useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router";
import {
  VerifyUserError,
  VerifyUserErrorReason,
} from "../../../api/authentication/verify";

import { UserContext } from "../../../contexts/UserContext";
import { Route, useNavigator } from "../../../routers/navigator";
import { FormContainer } from "../../atoms/Containers";
import { UnexpectedError } from "../../atoms/UnexpectedError";
import { SylaFormHeader } from "../forms/SylaFormHeader";

export const CompleteVerificationComponent = (): JSX.Element => {
  // get the login function from the user context
  const { verify } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const navigate = useNavigator();

  const [errorReason, setErrorReason] = useState<VerifyUserErrorReason>();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const skipOnboarding = searchParams.get("skip-onboarding");

  const completeVerification = useMutation(
    (token: string | null) =>
      verify(token ?? throwError(new Error("Token not present"))),
    {
      onSuccess: ({ defaultAccountId }) => {
        navigate({
          route: skipOnboarding ? Route.Home : Route.FirstLogin,
          accountId: defaultAccountId,
          replace: true,
        });
      },
      onError: (error) => {
        if (error instanceof VerifyUserError) {
          setErrorReason(error.errorReason);
        } else {
          setErrorReason(VerifyUserErrorReason.UnknownError);
        }
      },
    }
  );
  useEffect(() => {
    // only run once
    if (completeVerification.isIdle) {
      completeVerification.mutate(token);
    }
  }, [completeVerification, token]);

  const errorColor = "red.500";
  return (
    <FormContainer>
      <SylaFormHeader
        heading={"Completing Verification"}
        subText={"of your new Syla Account"}
      />

      {completeVerification.isLoading && (
        <Spinner
          color="red.500"
          emptyColor="red.200"
          my="10px"
          thickness="5px"
          size="lg"
        />
      )}
      {errorReason == VerifyUserErrorReason.RegistrationNotFound && (
        <Text color={errorColor}>
          This verification link has expired.
          <Text>
            <Link
              fontWeight="bold"
              href="/sign-up"
              onClick={() => history.replace("/sign-up")}
            >
              Please sign up again.
            </Link>
          </Text>
        </Text>
      )}
      {errorReason == VerifyUserErrorReason.OtpExpired && (
        <Text color={errorColor}>
          {
            "This link has expired. We've sent another link to the email address you signed up with."
          }
        </Text>
      )}
      {errorReason == VerifyUserErrorReason.UnknownError && UnexpectedError()}
    </FormContainer>
  );
};
