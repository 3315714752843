import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";
import { getCoinJarAuthoriseUrl } from "./getCoinJarAuthoriseUrl";
import { getCoinBaseAuthoriseUrl } from "./getCoinBaseAuthoriseUrl";

export const getAuthoriseUrl = ({
  dataSourceCode,
  accountId,
  walletId,
}: {
  dataSourceCode: DataSourceCode | undefined;
  accountId: string;
  walletId: string;
}) =>
  dataSourceCode == DataSourceCode.CoinJar
    ? getCoinJarAuthoriseUrl({ walletId, accountId })
    : dataSourceCode == DataSourceCode.Coinbase
    ? getCoinBaseAuthoriseUrl({ walletId, accountId })
    : undefined;
