import { TransactionEventResponseItem } from "../responses/TransactionEventResponseItem";
import { TransportType } from "./TransportType";
import { parseDataSource } from "./parseDataSource";
import { parseWalletResponse } from "./parseWallet";
import { parseLedger } from "./parseLedgers";

export function parseTransactionResponseItem(
  transaction: TransportType<TransactionEventResponseItem<string>>
): TransactionEventResponseItem<string> {
  return {
    ...transaction,
    date: new Date(transaction.date),
    ledgers: transaction.ledgers.map((l) => parseLedger(l)),
    wallet: parseWalletResponse(transaction.wallet),
    dataSource: parseDataSource(transaction.dataSource),
  };
}
