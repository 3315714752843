import { getCurrentSubscription } from "@syla/shared/types/helpers/subscriptions";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import { Account } from "../types/Account";
import { Subscription } from "../types/plans/subscription";

export type AccountWithPermissions = Pick<Account, "permissions">;

export function hasAccountPermission(
  account: AccountWithPermissions | undefined,
  permission: AccountPermission
) {
  return (account && account.permissions.some((p) => p == permission)) || false;
}

export function canUpgradeSubscription({
  subscriptions,
}: {
  subscriptions:
    | Pick<
        Subscription,
        "billingAccountAccess" | "current" | "stripeSubscriptionId"
      >[]
    | undefined;
}) {
  const currentSub = subscriptions && getCurrentSubscription(subscriptions);
  return (currentSub && currentSub.billingAccountAccess) || false;
}
export function canPurchaseSubscription({
  account,
  subscriptions,
}: {
  account: AccountWithPermissions | undefined;
  subscriptions: Subscription[] | undefined;
}) {
  const currentSub = subscriptions && getCurrentSubscription(subscriptions);

  return (
    (account &&
      !currentSub &&
      hasAccountPermission(account, AccountPermission.PurchaseSubscription)) ||
    false
  );
}
