import { CheckIcon } from "@chakra-ui/icons";
import { HStack, VStack, Icon, Text, Tag, Box } from "@chakra-ui/react";
import { ImportType } from "@syla/shared/types/models/ImportBase";
import { getWalletName } from "@syla/shared/types/models/WalletBase";
import { ImportFilter } from "@syla/shared/types/requests/GetGroupsRequest";
import { format } from "date-fns";
import { groupBy, sumBy, orderBy } from "lodash";
import React, { useMemo, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import { useGetWallets } from "../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryDataSources } from "../../../store/useQueryDataSources";
import { useQueryGetAccountImports } from "../../../store/useQueryGetAccountImports";
import { DataSource } from "../../../types/dataSource/dataSource";
import { Wallet } from "../../../types/wallet/wallet";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { TooltipWrapper } from "../../atoms/TooltipWrapper";
import {
  Selector,
  KeyedOption,
  SelectorProps,
} from "../../molecules/selector/Selector";

export type ImportItem = {
  _id: string;
  type: ImportType;
  date: Date;
  fileName?: string;
  dataSource: DataSource | undefined;
  wallet: Wallet | undefined;
  transactionCount: number;
};

export function ImportFilterSelector({
  selectedItems,
  setSelectedItems,
  buttonProps,
  smallScreen,
}: {
  selectedItems: ImportFilter<string>[];
  setSelectedItems: (values: ImportFilter<string>[]) => void;
  buttonProps?: SelectorProps<never, never>["buttonProps"];
  smallScreen: boolean | undefined;
}) {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const [fetchOptions, setFetchOptions] = useState(false);

  // populate imports filter
  const { data: importsResponse, isStale: importsStale } =
    useQueryGetAccountImports(accountId, { enabled: fetchOptions });
  const { imports } = importsResponse ?? {};

  const { data: dataSources, isStale: dataSourcesStale } = useQueryDataSources({
    enabled: fetchOptions,
  });

  const { data: walletData, isStale: walletsStale } = useGetWallets(
    accountId,
    {},
    {
      enabled: fetchOptions,
    }
  );

  const importItems = useMemo(
    () =>
      dataSources &&
      imports &&
      walletData &&
      imports.map(
        ({
          _id,
          transactionCount,
          walletId,
          import: { dataSource: dataSourceId, fileName, type, date },
        }) => {
          // manual imports are combined across data sources
          const dataSource =
            type == ImportType.manual
              ? undefined
              : dataSources?.find((ds) => ds._id == dataSourceId);

          const wallet = walletData.wallets.find(
            (wallet) => wallet._id == walletId
          );

          const option: ImportItem = {
            _id,
            type,
            date,
            fileName,
            transactionCount,
            wallet,
            dataSource,
          };

          return option;
        }
      ),
    [dataSources, imports, walletData]
  );

  const importOptions = useMemo(
    () =>
      importItems &&
      Object.entries(
        groupBy(
          // order items by date desc
          orderBy(importItems, (o) => o.date, "desc"),
          // create key
          (item) =>
            // group manual imports together
            item.type == ImportType.manual ? item.type : item._id
        )
      ).map<KeyedOption<ImportItem, string>>(([key, values]) => ({
        key,
        value: {
          ...values[0],
          transactionCount: sumBy(values, (v) => v.transactionCount),
        },
      })),
    [importItems]
  );

  const isLoading =
    !importOptions || importsStale || dataSourcesStale || walletsStale;

  return (
    <Selector
      multiSelect
      allowDeselect
      searchSettings={{
        searchKeys: [
          "type",
          "fileName",
          "dataSource.name",
          "wallet.customName",
        ],
      }}
      renderOptionContent={({ isSelected, option }) => (
        <Item importItem={option} isSelected={isSelected} />
      )}
      SelectorContent={
        <Text fontWeight="500" fontSize="0.875rem" color="black.550">
          Import History
        </Text>
      }
      options={importOptions}
      selectedOptions={selectedItems.map((i) => ({
        key: i._id ?? i.type,
      }))}
      onChangeSelection={(options) => {
        setSelectedItems(
          options.map(({ value: { type, _id } }) =>
            type == ImportType.manual ? { type: type } : { _id: _id }
          )
        );
      }}
      isLoading={isLoading}
      setFetchOptions={setFetchOptions}
      buttonProps={{
        height: "50px",
        ...buttonProps,
      }}
      expandIcon={<FaCaretDown color="#808080" />}
      selectedIndicator={true}
      contentProps={{ maxW: smallScreen ? "80vw" : "500px" }}
    />
  );
}

const Item = ({
  importItem,
  isSelected,
}: {
  importItem: ImportItem;
  isSelected: boolean;
}) => {
  const { type, fileName, date, dataSource, transactionCount, wallet } =
    importItem;

  const icon = dataSource?.image;
  const title =
    type == ImportType.file
      ? `${fileName}`
      : type == ImportType.sync
      ? `${
          wallet && dataSource
            ? getWalletName({ wallet, dataSource })
            : "Unknown"
        } Sync`
      : "Manually Added";

  return (
    <TooltipWrapper tooltip={title} tooltipProps={{ openDelay: 1000 }} w="100%">
      <HStack
        w="100%"
        position="relative"
        bg="transparent"
        justify="space-between"
        overflow="hidden"
      >
        <HStack>
          <ImageWithMissingSrc
            src={icon}
            noPaddingIcon
            alt=""
            h="30px"
            mr="10px"
          />
          <VStack w="100%" alignItems="flex-start" spacing={0.5}>
            <Text
              color="gray.700"
              fontSize="sm"
              noOfLines={1}
              wordBreak="break-all"
            >
              {title}
            </Text>
            <Text color="gray.500" fontSize="xs">
              {format(date, "d MMMM yyyy h:mm a")}
            </Text>
          </VStack>
          <Box>
            <Tag>{transactionCount.toLocaleString()}</Tag>
          </Box>
        </HStack>
        <Icon
          as={CheckIcon}
          color="primary.500"
          visibility={!isSelected ? "hidden" : "visible"}
        />
      </HStack>
    </TooltipWrapper>
  );
};
