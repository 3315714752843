import { OrderResponseItem } from "../responses/OrderResponseItem";
import { parseOptional, parseDecimal } from "./parsing";
import { parseOrder } from "./parseOrder";
import { parseTransactionResponseItem } from "./parseTransactionResponseItem";
import { TransportType } from "./TransportType";

export function parseOrderResponseItem(
  data: TransportType<OrderResponseItem<string>>
): OrderResponseItem<string> | undefined {
  if (!data.order || !data.transactions || !data.transactions.length) {
    console.error(`missing properties for order response item`, data);
    return undefined;
  }

  return {
    order: parseOrder(data.order),
    transactions: data.transactions.map((t) => parseTransactionResponseItem(t)),
    taxOutcome: parseOptional(data.taxOutcome, parseDecimal),
  };
}
