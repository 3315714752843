import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { SyncWalletSuccessResponse } from "@syla/shared/types/responses/SyncWalletResponse";
import axios from "axios";

export const reSyncWallet = async (accountId: string, walletIds: string[]) => {
  await axios.post<TransportType<SyncWalletSuccessResponse<string>>>(
    `/accounts/${accountId}/wallets/re-sync`,
    {
      ids: walletIds,
    }
  );
};
