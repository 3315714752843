import { format } from "date-fns";

export function formatDateTime(
  date: Date,
  { subSeconds }: { subSeconds?: boolean } = { subSeconds: false }
) {
  return `${formatDate(date)} ${formatTime(date, { subSeconds })}`;
}

export function formatDate(date: Date) {
  return format(date, "dd MMM yyyy");
}

export function formatTime(
  date: Date,
  { subSeconds }: { subSeconds?: boolean } = { subSeconds: false }
) {
  return format(date, "HH':'mm':'ss" + (subSeconds ? "'.'SSS" : ""));
}
