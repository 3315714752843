import Joi from "joi";
import { PopulatedWallet } from "../models/WalletBase";

export type SyncWalletResponse<TId> =
  | SyncWalletSuccessResponse<TId>
  | SyncWalletErrorResponse<TId>;

export type SyncWalletSuccessResponse<TId> = {
  success: true;
  walletState?: WalletStateMap<TId>;
};
export enum SyncWalletResponseStatusCode {
  SUCCESS = 200,
  BAD_REQUEST = 400,
  SYNC_ERROR = 400,
  OUTAGE = 502,
  UNKNOWN_ERROR = 500,
}

export type SyncWalletErrorResponse<TId> = {
  success: false;
  error: SyncWalletResponseErrorDetails;
  walletState?: WalletStateMap<TId>;
};

export type SyncWalletResponseErrorDetails =
  | {
      code: SyncWalletResponseErrorCode.BAD_REQUEST;
      message: string;
    }
  | {
      code: SyncWalletResponseErrorCode.SYNC_ERROR;
    };

export type WalletState<TId> = Pick<
  PopulatedWallet<TId>,
  | "_id"
  | "syncState"
  | "syncError"
  | "fileImportState"
  | "dataSource"
  | "customName"
>;
export type WalletStateMap<TId> = Record<string, WalletState<TId>>;

export enum SyncWalletResponseErrorCode {
  BAD_REQUEST = "BAD_REQUEST",
  SYNC_ERROR = "SYNC_ERROR",
}

// validation
const syncWalletErrorResponseSchema = Joi.object({
  success: Joi.boolean().required().valid(false),
  error: Joi.object({
    code: Joi.string()
      .allow(...Object.values(SyncWalletResponseErrorCode))
      .required(),
    title: Joi.string(),
    message: Joi.string(),
  }).required(),
  walletState: Joi.object(),
});

export const isSyncWalletErrorResponse = (
  value: unknown
): value is SyncWalletErrorResponse<any> => {
  return !syncWalletErrorResponseSchema.validate(value).error;
};
