import { List, ListItem, ListIcon } from "@chakra-ui/react";
import React from "react";
import {
  needsReviewListProps,
  needsReviewIconProps,
} from "../../../atoms/NeedsReview";

export function TransactionsNeedsReviewList({
  items,
}: {
  items: JSX.Element[];
}) {
  return (
    <List {...needsReviewListProps}>
      {Object.entries(items).map(([idx, needsReviewItem]) => (
        <ListItem key={idx}>
          <ListIcon {...needsReviewIconProps} />
          {needsReviewItem}
        </ListItem>
      ))}
    </List>
  );
}
