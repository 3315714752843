/**
 * Returns a new `array` with nullable values removed.
 * Includes types helper.
 *
 * @param array Array of items
 * @returns Array of items with `null` and `undefined` removed.
 */
export const withoutNullable = <TElement>(
  array: TElement[]
): NonNullable<TElement>[] =>
  array.filter((v) => v != null) as NonNullable<TElement>[];
