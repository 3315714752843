import { useQuery } from "react-query";
import { getAccount } from "../api/account/getAccount";
import {
  ACCOUNT_META_QUERY_KEY,
  ACCOUNTS_META_STALE_TIME,
} from "./useQueryGetAccounts";

export const useQueryGetAccount = (accountId: string) =>
  useQuery(
    [...ACCOUNT_META_QUERY_KEY, accountId],
    () => getAccount(accountId),
    { staleTime: ACCOUNTS_META_STALE_TIME }
  );
