/* common styling */
import { Box, BoxProps, VStack, HStack, Heading, Text } from "@chakra-ui/react";
import React from "react";

const CARD_PADDING = "20px";
// full width, ignoring container padding
export const CARD_FULL_WIDTH = `calc(100% + ${CARD_PADDING} * 2)`;

const CardDivider = () => (
  <Box width={CARD_FULL_WIDTH} my="15px" h="1px" bgColor="black.300" />
);
export const ActionCard = ({
  heading,
  subheading,
  content,
  actions,
  leftIcon,
  ...rest
}: {
  heading?: React.ReactNode;
  subheading?: React.ReactNode;
  actions?: React.ReactNode;
  content: React.ReactNode;
  leftIcon?: React.ReactNode;
} & BoxProps) => (
  <Box
    w={"100%"}
    p={CARD_PADDING}
    shadow="md"
    borderWidth="1px"
    borderRadius="10px"
    // use position relative and overflow hidden so that card accent gets rendered properly
    position={"relative"}
    overflow={"hidden"}
    {...rest}
  >
    {/* Card Accent */}
    <Box
      position={"absolute"}
      top={"0px"}
      width="100%"
      mx={`-${CARD_PADDING}`}
      mb="20px"
      h="6px"
      bgColor="red.500"
    />
    {/* Card Sections */}
    <VStack spacing={5} divider={<CardDivider />}>
      {/* Heading */}
      {heading && (
        <HStack w={"100%"} spacing={3} alignItems="flex-start">
          {/* Icon */}
          {leftIcon}
          {/* Heading and Subheading */}
          <VStack alignItems="flex-start">
            <Heading fontSize="2xl">{heading}</Heading>
            <Text>{subheading}</Text>
          </VStack>
        </HStack>
      )}
      {/* Content */}
      <VStack w={"100%"} spacing={3} alignItems="flex-start">
        {content}
      </VStack>
      {/* Actions */}
      {/* Use gap to set spacing between items on both axes (when overflow) */}
      {actions && (
        <HStack
          w={"100%"}
          justify="flex-end"
          wrap={"wrap"}
          css={{ gap: "0.75rem" }}
        >
          {actions}
        </HStack>
      )}
    </VStack>
  </Box>
);
