import {
  Text,
  Divider,
  Thead,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Decimal } from "@syla/shared/decimal";
import { getPermissionDeniedText } from "@syla/shared/types/helpers/permissions";
import { AccountSubType } from "@syla/shared/types/models/AccountBase";
import React from "react";
import { MdLockOutline } from "react-icons/md";
import { useLocation } from "react-router-dom";

import { NumberStyler } from "../../../helper/NumberStyler";
import { getFullPageUrl } from "../../../helper/getFullPageUrl";
import { useNavigator, Route } from "../../../routers/navigator";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryGetAccount } from "../../../store/useQueryGetAccount";
import { FinancialYearOption } from "../../../types/user/financialYearOption";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { SkeletonRect } from "../../atoms/Skeletons";
import { useTaxReportSummaryQuery } from "./useTaxReportSummaryQuery";

interface IncomeSummaryProps {
  taxableIncome: boolean;
  selectedFinancialYear?: FinancialYearOption;
}

const Z_Index_Lock_Screen = 2;
const Z_Index_Table_Body = 1;

export const IncomeSummary = ({
  taxableIncome,
  selectedFinancialYear,
}: IncomeSummaryProps) => {
  const location = useLocation();
  const navigate = useNavigator();

  const {
    data: taxReportSummary,
    isLoading,
    isStale,
  } = useTaxReportSummaryQuery(selectedFinancialYear);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: account, isLoading: userDetailsIsLoading } =
    useQueryGetAccount(accountId);
  const showLoading =
    isLoading || isStale || !selectedFinancialYear || userDetailsIsLoading;

  const filter =
    !showLoading && taxReportSummary?.summaryDeniedReason ? "blur(5px)" : "";

  const blurText = getPermissionDeniedText(
    taxReportSummary?.summaryDeniedReason
  );
  const isTrader = account?.accountSubType == AccountSubType.Trader;

  const entries: {
    label: string;
    value: Decimal | undefined;
    show: boolean;
    totalRow?: boolean;
  }[] = [
    {
      label: "Net Capital Gain / Loss",
      value: taxReportSummary?.summary?.capitalGainOrLoss,
      show: taxableIncome && !isTrader,
    },
    {
      label: "Net Foreign Currency Gain / Loss",
      value: taxReportSummary?.summary?.foreignCurrencyGainOrLoss,
      show: taxableIncome,
    },
    {
      label: "Sales of Trading Stock",
      value: taxReportSummary?.summary?.salesOfTradingStock,
      show: taxableIncome && isTrader,
    },
    {
      label: "Cost of Sales",
      value: taxReportSummary?.summary?.costOfSales.negated(),
      show: taxableIncome && isTrader,
    },
    {
      label: "Other Income",
      value: taxReportSummary?.summary?.otherIncome,
      show: taxableIncome,
    },
    {
      label: "Other Expenses",
      value: taxReportSummary?.summary?.otherExpenses.negated(),
      show: taxableIncome,
    },

    // non-taxable
    {
      label: "Net Exempt Gain / Loss",
      value: taxReportSummary?.summary?.exemptGainOrLoss,
      show: !taxableIncome && !isTrader,
    },
    {
      label: "Non-Assessable Income",
      value: taxReportSummary?.summary?.nonAssessableIncome,
      show: !taxableIncome,
    },
    {
      label: "Non-Deductible Expenses",
      value: taxReportSummary?.summary?.nonDeductibleExpenses.negated(),
      show: !taxableIncome,
    },

    // totals
    {
      label: "Taxable Income Estimate",
      value: taxReportSummary?.summary?.total,
      show: taxableIncome,
      totalRow: true,
    },
    {
      label: "Non-Taxable Income Estimate",
      value: taxReportSummary?.summary?.netNonTaxableIncome,
      show: !taxableIncome,
      totalRow: true,
    },
  ];

  return (
    <CardContentContainer overflowY={"hidden"}>
      <ContentHeadingContainer>
        <TaxReportHeading>
          {taxableIncome
            ? "Summary of Taxable Income"
            : "Summary of Non-Taxable Income"}
        </TaxReportHeading>
      </ContentHeadingContainer>
      {taxableIncome && (
        <Text color="black.600" fontSize="0.875rem" m="20px">
          <Text fontWeight="bold">Important:</Text> Do not use this estimate for
          completing your tax return. You must download your Crypto Tax Report,
          which contains detailed tax calculations that can be used to complete
          your tax return.
        </Text>
      )}
      <Divider mt="20px" />

      {/* <Flex justifyContent="center" alignItems="center" h="60%">
      <EmptyDataMessage content="Add a data source to review your taxable income summary" />
        </Flex> */}
      <Table position="relative">
        <Thead>
          <Tr>
            <Th color="black.600" fontSize="0.75rem" fontWeight="500">
              Value
            </Th>
            <Th
              color="black.600"
              fontSize="0.75rem"
              fontWeight="500"
              textAlign={"right"}
            >
              <Text>Amount</Text>
            </Th>
          </Tr>
        </Thead>

        {!showLoading && taxReportSummary?.summaryDeniedReason && (
          <LockScreen>
            <Box>
              <LockWrapper>
                <MdLockOutline color="#ff3600" fontSize="2.5rem" />
              </LockWrapper>
              <BlurTextWrapper>{blurText}</BlurTextWrapper>
              <ViewPlanBtnWrapper>
                <ButtonVariant
                  content="View Plans"
                  outlineType="solid"
                  onClick={() =>
                    navigate({
                      route: Route.Plans,
                      accountId,
                      state: {
                        referringUrl: getFullPageUrl(location),
                      },
                    })
                  }
                />
              </ViewPlanBtnWrapper>
            </Box>
          </LockScreen>
        )}
        <Tbody filter={filter} zIndex={Z_Index_Table_Body}>
          {entries
            .filter((entry) => entry.show)
            .map(({ label, value, totalRow }, index) => (
              <Tr key={index}>
                <Td
                  fontSize="0.875rem"
                  color="black.900"
                  fontWeight={totalRow ? "bold" : undefined}
                >
                  {label}
                </Td>
                <Td>
                  <SkeletonRect isLoaded={!showLoading}>
                    {taxReportSummary?.summary ? (
                      <NumberStyler
                        num={value}
                        unit="currency"
                        fontSize="0.875rem"
                        negativeColored
                        textAlign={"right"}
                        fontWeight={totalRow ? "bold" : undefined}
                      />
                    ) : (
                      <Text
                        fontSize="0.875rem"
                        color="black.800"
                        textAlign="right"
                      >
                        $XX,XXX.XX
                      </Text>
                    )}
                  </SkeletonRect>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </CardContentContainer>
  );
};

const LockScreen = styled.div`
  z-index: ${Z_Index_Lock_Screen};
  position: absolute;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LockWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const BlurTextWrapper = styled.div`
  font-size: 0.875rem;
  margin: 2rem 30%;
`;

const ViewPlanBtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem;
`;
