import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { HiPlusSm, HiMinusSm } from "react-icons/hi";

export const TransactionAccordionItem = ({
  title,
  children,
  optional,
}: {
  title: string;
  children: ReactNode;
  optional?: boolean;
}) => (
  <AccordionItem>
    {({ isExpanded }) => (
      <>
        <AccordionButton
          px="24px"
          bgColor="white.100"
          borderWidth="0 0 1px 0"
          borderColor="black.200"
          mb="10px"
          tabIndex={0}
        >
          <AccordionLabel>
            {title}
            {optional && <OptionLabel as="span">(optional)</OptionLabel>}
          </AccordionLabel>

          {isExpanded ? <HiMinusSm color="#999" /> : <HiPlusSm color="#999" />}
        </AccordionButton>

        <AccordionPanel p="10px 20px">{children}</AccordionPanel>
      </>
    )}
  </AccordionItem>
);

const AccordionLabel = styled(Text)`
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  color: #4d4d4d;
  text-align: left;
`;
const OptionLabel = styled(Text)`
  text-transform: capitalize;
  font-size: 0.875rem;
  color: #999;
  margin-left: 5px;
`;
