"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTransactionResponseItem = void 0;
const parseDataSource_1 = require("./parseDataSource");
const parseWallet_1 = require("./parseWallet");
const parseLedgers_1 = require("./parseLedgers");
function parseTransactionResponseItem(transaction) {
    return Object.assign(Object.assign({}, transaction), { date: new Date(transaction.date), ledgers: transaction.ledgers.map((l) => (0, parseLedgers_1.parseLedger)(l)), wallet: (0, parseWallet_1.parseWalletResponse)(transaction.wallet), dataSource: (0, parseDataSource_1.parseDataSource)(transaction.dataSource) });
}
exports.parseTransactionResponseItem = parseTransactionResponseItem;
