import { GetWalletsRequestQuery } from "@syla/shared/types/requests/GetWalletsRequest";
import axios from "axios";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { parseAssetHolding } from "../../helper/wallet/parseAssetHolding";
import { GetWalletsResponse } from "../../types/wallet/wallet";
import { parseWallet } from "../../helper/wallet/parseWallet";

export const getWallets = async (
  accountId: string,
  props: GetWalletsRequestQuery = {}
): Promise<GetWalletsResponse> => {
  const { data }: { data: TransportType<GetWalletsResponse> } = await axios.get(
    `accounts/${accountId}/wallets`,
    {
      params: props,
    }
  );

  // convert api returned Decimal string to Decimal
  return {
    accountHoldings: data.accountHoldings.map(parseAssetHolding),
    wallets: data.wallets
      .filter((wallet) => {
        if (!wallet.dataSource)
          console.error(`Missing datasource for wallet ${wallet._id}`);
        return !!wallet.dataSource;
      })
      .map(parseWallet),
    missingHoldings: data.missingHoldings.map(parseAssetHolding),
  };
};
