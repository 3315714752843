import { Decimal } from "../../decimal";

export function parseOptional<TOut, TIn>(
  value: TIn | null | undefined,
  parser: Parser<TOut, TIn>,
  treatedAsNull: ((value: TIn) => boolean) | undefined = undefined
): TOut | undefined {
  if (value != null && (!treatedAsNull || !treatedAsNull(value)))
    return parser(value);
}

export const parseDate: Parser<Date, string | number> = (val) => {
  const date = new Date(val);
  if (isNaN(date.valueOf()))
    throw new Error(`Invalid date when casting value '${val}'`);
  return date;
};
export const parseDecimal: Parser<Decimal> = (val) => Decimal.from(val);

export type Parser<TOut, TIn = string> = (value: TIn) => TOut;
