import {
  REFERRAL_CODE_SEARCH_PARAM,
  REFERRAL_CODE_COOKIE,
} from "@syla/shared/types/helpers/constants";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

export function useGetReferralCode() {
  const location = useLocation();

  const referralCodeCookieValue = Cookies.get(REFERRAL_CODE_COOKIE);
  if (referralCodeCookieValue) return referralCodeCookieValue;

  const referralCodeParam = new URLSearchParams(location.search).get(
    REFERRAL_CODE_SEARCH_PARAM
  );
  if (!referralCodeParam) return null;

  return referralCodeParam;
}
