import { objectRemoveKey } from "@syla/shared/helpers/objectRemoveKey";
import { useMutation } from "react-query";
import {
  createStripeBillingPortalSession,
  CreateStripeBillingPortalProps,
} from "../api/plans/createStripeBillingPortalSession";

export const useMutationCreateStripeBillingPortalSession = () =>
  useMutation(
    ["create-stripe-billing-portal-session"],
    (props: CreateStripeBillingPortalProps & { accountId: string }) =>
      createStripeBillingPortalSession(
        props.accountId,
        objectRemoveKey(props, "accountId") as CreateStripeBillingPortalProps
      )
  );
