import { Flex, useDisclosure } from "@chakra-ui/react";
import { min } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";

import { useLocalStorage } from "../../../../hooks/localStorage/useLocalStorage";
import { buildTaxReportsLink } from "../../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useTransactionsStore } from "../../../../store/transactionsStore";
import {
  OrderFilterSetting,
  OrderSortOption,
} from "../../../../types/order/orderQuery";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { HEADER_Z_INDEX } from "../../../atoms/StickyThead";
import { tableFrameProps } from "../../../atoms/tableFrameProps";
import { TransactionDrawer } from "../transactionDrawer/TransactionDrawer";
import { OrderOverview } from "../transactionDrawer/orderOverview/OrderOverview";
import { TransactionOverview } from "../transactionDrawer/transactionOverview/TransactionOverview";

import {
  resetGroupList,
  updateGroupList,
} from "../../../../store/actions/groupList";
import { FilterSection } from "./FilterSection";
import { MainTransactionsTable } from "./MainTransactionsTable";
import { PaginationBtns } from "./PaginationBtns";
import { SearchSection, PageSizeOptions } from "./SearchSection";
import { useSelectedTxContext } from "./SelectedTxContext";

// this state may exist if navigated from another page that
// requires pre-selecting some filter options
export type MainTransactionTableHistoryState = {
  filter?: Partial<OrderFilterSetting>;
};

export const defaultOrderFilterState = {
  transactionType: [],
  ledgerType: [],
  dataSource: [],
  asset: [],
  status: [],
  searchQuery: "",
  date: undefined,
  imports: [],
};
export const TransactionsView = (): JSX.Element => {
  // wallet id will exist if user navigated from the data source page
  const { state } = useLocation<MainTransactionTableHistoryState | undefined>();

  // ------------------- Drawers -------------------
  const {
    isOpen: isOpenTransactionDrawer,
    onOpen: onOpenTransactionDrawer,
    onClose: onCloseTransactionDrawer,
  } = useDisclosure();

  // ------------------- Order and Transaction state -------------------
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();
  const [selectedTransaction, setSelectedTransaction] = useState<
    string | undefined
  >();
  const [canDefaultCloseDrawer, setCanDefaultCloseDrawer] = useState(true);
  useEffect(() => {
    if (isOpenTransactionDrawer) return;
    setSelectedTransaction(undefined);
    setSelectedGroupId(undefined);
  }, [isOpenTransactionDrawer]);

  // ------------------- Orders data query -------------------
  // Filter setting
  const [filterSetting, setFilterSetting] = useState<OrderFilterSetting>({
    ...defaultOrderFilterState,
    ...state?.filter,
  });

  // ------------------- Order useQuery and filter -------------------
  const [sortOption, setSortOption] = useLocalStorage<
    OrderSortOption | undefined
  >("transactionsSortBy", undefined);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const defaultPageSize = PageSizeOptions[0];
  const [savedPageSize, setPageSize] = useLocalStorage(
    "pageSize",
    defaultPageSize
  );
  let pageSize = savedPageSize ?? defaultPageSize;
  // is some other value than supported
  if (!PageSizeOptions.includes(pageSize)) {
    setPageSize(defaultPageSize);
    pageSize = defaultPageSize;
  }

  const groups = useTransactionsStore((state) => state.groups);
  const groupListTotal = useTransactionsStore((state) => state.groupListTotal);

  // console.log({ groupList, groups });

  // const [ordersSearchQuery, setOrdersSearchQuery] = useState("");
  const { onSelect, selectedIds, clearSelected, allSelected } =
    useSelectedTxContext();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // reset current page index back to the first page and invalidate the orders query when filter settings changes
  useEffect(() => {
    if (!allSelected) clearSelected();
    if (accountId) {
      updateGroupList({
        sort: sortOption,
        filter: filterSetting,
        pageParam: currentPageIndex,
        limit: pageSize,
        accountId,
      });
    }

    // clean up state on unmount
    return resetGroupList;
  }, [
    accountId,
    currentPageIndex,
    filterSetting,
    sortOption,
    pageSize,
    clearSelected,
    allSelected,
  ]);

  // cap current page number if less than max
  useEffect(() => {
    if (groupListTotal == null) return;

    const maxPageIndex = Math.floor(groupListTotal / pageSize);
    setCurrentPageIndex(min([currentPageIndex, maxPageIndex]) ?? 0);
  }, [groupListTotal, pageSize, currentPageIndex]);

  // ------------------- Select Transaction -------------------

  // function that decide which overview is getting rendered, order or transaction
  const overview = useMemo(() => {
    if (!selectedGroupId || !groups) {
      return undefined;
    }

    const selectedGroup = groups[selectedGroupId]?.data;
    if (!selectedGroup) return undefined;

    // Render Transaction Overview if a transaction is selected
    if (selectedTransaction) {
      return (
        selectedTransaction && (
          <TransactionOverview
            transactionId={selectedTransaction}
            onClickBack={() => {
              setSelectedTransaction(undefined);
              if (selectedGroup.transactions.length === 1) {
                onCloseTransactionDrawer();
              }
            }}
            setCanDefaultCloseDrawer={setCanDefaultCloseDrawer}
          />
        )
      );
    }
    // Render Transaction Overview if an order containing only 1 transaction is selected
    if (selectedGroup.transactions.length === 1) {
      setSelectedTransaction(selectedGroup.transactions[0]._id);
      return (
        selectedTransaction && (
          <TransactionOverview
            transactionId={selectedTransaction}
            onClickBack={() => {
              setSelectedTransaction(undefined);
              setSelectedGroupId(undefined);
              onCloseTransactionDrawer();
            }}
            setCanDefaultCloseDrawer={setCanDefaultCloseDrawer}
          />
        )
      );
    }

    // Render OrderOverview
    return (
      <OrderOverview
        groupId={selectedGroupId}
        onClickBack={() => {
          setSelectedGroupId(undefined);
          setSelectedTransaction(undefined);
          onCloseTransactionDrawer();
        }}
        onClickTransaction={(transaction) =>
          setSelectedTransaction(transaction._id)
        }
      />
    );
  }, [groups, onCloseTransactionDrawer, selectedGroupId, selectedTransaction]);

  const anySelected = allSelected || selectedIds.length > 0;
  const onClickOrder = useCallback(
    (id) => {
      if (anySelected) onSelect(id);
      else {
        setSelectedGroupId(id);
        onOpenTransactionDrawer();
      }
    },
    [anySelected, onSelect, onOpenTransactionDrawer]
  );

  const transactionList = useMemo(
    () => (
      <MainTransactionsTable
        onClickOrder={onClickOrder}
        filters={filterSetting}
      />
    ),
    [filterSetting, onClickOrder]
  );

  return (
    <Flex direction="column">
      <Flex
        {...tableFrameProps}
        direction="column"
        w="100%"
        mb="20px"
        minH="200px"
        minWidth="max-content"
      >
        {/* ----------------- Search Bar Section ------------------ */}
        <SearchSection
          orderSortOption={sortOption}
          setOrderSortOption={(selection) => setSortOption(selection)}
          ordersSearchQuery={filterSetting.searchQuery}
          onChangeOrderSearchQuery={(searchContent) =>
            setFilterSetting((oldState) => ({
              ...oldState,
              searchQuery: searchContent,
            }))
          }
          pageSizeOption={pageSize}
          setPageSizeOption={(newPageSize) => setPageSize(newPageSize)}
          zIndex={HEADER_Z_INDEX + 1}
        />
        {/* -------------------- Filter Section ------------------- */}
        <FilterSection
          filterSetting={filterSetting}
          setFilterSetting={setFilterSetting}
        />
        {/* -------------------- Table Body --------------------- */}
        <Flex id="tx-table-container" direction="column">
          {transactionList}
        </Flex>
      </Flex>

      {/* -------------------- Pagination ----------------------- */}
      {(groupListTotal ?? 0) > 0 && (
        <Flex justifyContent="flex-end" mb="20px">
          <PaginationBtns
            itemPerPage={pageSize}
            totalNumberOfItems={groupListTotal ?? 0}
            currentPageIndex={currentPageIndex}
            onClickPage={(page) => setCurrentPageIndex(page)}
          />
        </Flex>
      )}
      {/* -------------------- Tax Report Button ----------------------- */}
      <Flex justifyContent="flex-end" mb="20px">
        <Link to={buildTaxReportsLink({ accountId })}>
          <ButtonVariant
            content="Generate Tax Report"
            rightIcon="next"
            outlineType="solid"
            color="darkGray"
            spam="auto"
            mb="20px"
            float="right"
          />
        </Link>
      </Flex>
      {/* -------------------- Transaction Drawer ----------------------- */}
      <TransactionDrawer
        btnRef={undefined}
        isOpen={isOpenTransactionDrawer}
        onClose={onCloseTransactionDrawer}
        canDefaultClose={canDefaultCloseDrawer}
      >
        {overview}
      </TransactionDrawer>
    </Flex>
  );
};
