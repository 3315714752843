import { useQuery } from "react-query";
import { getAccountImports } from "../api/transactions/getAccountImports";
import { getAccountTransactionsCacheKey } from "./transactionsStore";

export const useQueryGetAccountImports = (
  accountId: string,
  { enabled }: { enabled?: boolean } = {}
) =>
  useQuery(
    [...getAccountTransactionsCacheKey(accountId), "imports"],
    () => getAccountImports(accountId),
    {
      enabled,
    }
  );
