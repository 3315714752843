import axios from "axios";

import {
  SyncState,
  FileImportState,
} from "@syla/shared/types/models/WalletBase";
import { Wallet } from "../../types/wallet/wallet";

export interface UpdateWalletBody {
  apiKey?: string;
  secretKey?: string;
  passphrase?: string;
  customName?: string;
  vezgoAccountId?: string;
  syncState?: SyncState;
  fileImportState?: FileImportState | null;
  fromDate?: Date;
}

export const updateWallet = async (
  accountId: string,
  walletId: string,
  newProps: UpdateWalletBody
): Promise<Wallet> => {
  const { data } = await axios.patch(
    `/accounts/${accountId}/wallets/${walletId}`,
    newProps
  );
  return data;
};
