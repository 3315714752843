import { Decimal, sumWithDecimal } from "@syla/shared/decimal";
import React, { useMemo } from "react";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";

import { NumberStyler } from "../../../helper/NumberStyler";
import { useValueHoldings } from "../../../store/useValueHoldings";
import { useHoldingStats } from "../../../store/useHoldingStats";
import { AssetHolding } from "../../../types/wallet/wallet";
import { getAccountHoldingsValue } from "../dataSource/mainDataSourceTable/getAccountHoldingsValue";
import { StatBox } from "./StatBox";

export const StatBoxes = ({
  assetHoldings,
  isLoading,
}: {
  assetHoldings: AssetHolding[] | undefined;
  isLoading: boolean;
}) => {
  const { data: holdingsValue, isLoading: marketValueLoading } =
    useValueHoldings();

  const showFullWidth = useBreakpointValue({
    base: true,
    "2xs": true,
    xs: true,
    sm: true,
    md: false,
    lg: false,
  });

  const marketValue = useMemo(
    () => getAccountHoldingsValue({ holdingsValue, assetHoldings }),
    [assetHoldings, holdingsValue]
  );

  const cost =
    (assetHoldings && sumWithDecimal(assetHoldings, "cost")) || undefined;

  const { roi, roiInfinity } = useHoldingStats({
    marketValue,
    cost,
  });

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      flexWrap="nowrap"
      flexDirection={showFullWidth ? "column" : "row"}
    >
      <StatBox
        label="Total Cost"
        isLoading={isLoading}
        toolTip="The total cost of the assets you hold. This includes your domestic currency, foreign currencies, crypto and any other assets."
        content={
          <NumberStyler
            num={cost ?? Decimal.from("1000")}
            unit="currency"
            fontWeight="bold"
            fontSize="1.5rem"
            negativeColored
            width="fit-content"
          />
        }
        mr="15px"
      />
      <StatBox
        label="Total Holdings"
        isLoading={isLoading || marketValueLoading}
        toolTip="The current market value of the assets you hold. This includes your domestic currency, foreign currencies, crypto and any other assets."
        content={
          <NumberStyler
            num={marketValue}
            unit="currency"
            fontWeight="bold"
            fontSize="1.5rem"
            negativeColored
          />
        }
        mr="15px"
      />
      <StatBox
        label="ROI"
        isLoading={isLoading || marketValueLoading}
        toolTip="The return on investment. This is measured as the increase in market value from cost. (Total Holdings - Total Cost) / Total Cost."
        content={
          roiInfinity ? (
            <Text fontSize="1.5rem" fontWeight="bold">
              &gt; 999.99%
            </Text>
          ) : (
            <NumberStyler
              num={roi}
              unit="percentage"
              fontWeight="bold"
              fontSize="1.5rem"
              colored
            />
          )
        }
      />
      {/*
        <StatBox
          label="Capital In"
          isLoading={isLoading}
          toolTip="The net amount of capital that you have invested or returned from your crypto activity."
          content={
            <NumberStyler
              num="0"
              unit="currency"
              fontWeight="bold"
              fontSize="1.5rem"
            />
          }
        />
      </Flex>
      */}
    </Flex>
  );
};
