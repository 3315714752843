import { AccountSubType, AccountType } from "../models/AccountBase";
import { PlanBase } from "../models/PlanBase";
import {
  hasValidPlanWithCapacity,
  hasValidPlanWith,
  SubscriptionWithPlan,
} from "./subscriptions";

export type Permission = ReadAction | WriteAction | Feature;

export type ReadAction =
  | "get-tax-summary"
  | "get-tax-report"
  | "get-tax-outcome-report"
  | "get-transaction-report"
  | "get-remaining-parcels-report"
  | "get-bgl-sync-xml"
  | "get-holding-statement";

export type WriteAction = "write-bgl-sync";

export type Feature = "feat-tax-ltfo" | "feat-tax-hifo" | "feat-tax-lifo";

export const permissionDeniedText: {
  [Property in keyof PermissionDeniedReason]: string;
} = {
  noValidPlan:
    "You need to upgrade to a paid plan to view your tax summary and to download your Crypto Tax Report.",
  transactionCap:
    "You have more transactions than your current plan supports. You must upgrade your plan to view your tax summary and to download your Crypto Tax Report.",
};

type Account =
  | { accountType: AccountType; accountSubType: AccountSubType }
  | undefined;
type Plan = PlanBase<any>;

export type PermissionProps = {
  action: Permission;
  subscriptions: SubscriptionWithPlan[];
  transactionCount: number | undefined;
  account: Account;
};

export function permissionFor(props: PermissionProps): {
  hasPermission: boolean;
  deniedReason?: PermissionDeniedReason;
} {
  let deniedReason: PermissionDeniedReason;

  const capExceeded =
    props.transactionCount === undefined ||
    !hasValidPlanWithCapacity({
      subscriptions: props.subscriptions,
      transactionCount: props.transactionCount,
    });

  // console.debug({
  //   transactionCount: props.transactionCount,
  //   capExceeded,
  //   subscriptions: props.subscriptions,
  //   validSubscriptions,
  // });

  switch (props.action) {
    case "get-tax-report":
    case "get-tax-summary":
      deniedReason = {
        transactionCap: capExceeded,
        noValidPlan: !hasValidPlanWith({
          subscriptions: props.subscriptions,
          cond: ({ plan }) =>
            premiumAccountTypeRequirement({ account: props.account, plan }),
        }),
      };
      break;
    case "get-tax-outcome-report":
    case "get-transaction-report":
    case "get-remaining-parcels-report":
      deniedReason = {
        transactionCap: capExceeded,
        noValidPlan: !hasValidPlanWith({
          subscriptions: props.subscriptions,
          cond: ({ plan }) =>
            !!plan.assuranceFeatures &&
            premiumAccountTypeRequirement({ account: props.account, plan }),
        }),
      };
      break;
    case "write-bgl-sync":
    case "get-bgl-sync-xml":
      deniedReason = {
        transactionCap: capExceeded,
        noValidPlan: !hasValidPlanWith({
          subscriptions: props.subscriptions,
          cond: ({ plan }) => !!plan.premiumFeatures,
        }),
      };
      break;
    case "feat-tax-ltfo":
    case "feat-tax-hifo":
    case "feat-tax-lifo":
      deniedReason = {
        noValidPlan: !hasValidPlanWith({
          subscriptions: props.subscriptions,
          cond: ({ plan }) =>
            !!plan.taxOptimisationFeatures &&
            premiumAccountTypeRequirement({ account: props.account, plan }),
        }),
      };
      break;
    case "get-holding-statement":
      deniedReason = {
        noValidPlan: !hasValidPlanWith({
          subscriptions: props.subscriptions,
          cond: ({ plan }) => !!plan.assuranceFeatures,
        }),
      };
      break;
  }

  const result = {
    hasPermission: !Object.values(deniedReason).some(Boolean),
    deniedReason,
  };
  console.debug("Permission result", result);
  return result;
}

export function getPermissionDeniedText(
  reason: PermissionDeniedReason | undefined
) {
  return !reason
    ? ""
    : reason.noValidPlan
    ? permissionDeniedText.noValidPlan
    : reason.transactionCap
    ? permissionDeniedText.transactionCap
    : "";
}

export interface PermissionDeniedReason {
  noValidPlan?: boolean;
  transactionCap?: boolean;
  noBillingAccess?: boolean;
}

export const premiumAccountTypeRequirement = ({
  plan,
  account,
}: {
  account: Account;
  plan: Plan;
}) =>
  !!account &&
  ((!premiumAccountType(account.accountType) &&
    !premiumAccountSubType(account.accountSubType)) ||
    !!plan.premiumFeatures);

export const premiumAccountType = (accountType: AccountType) =>
  [AccountType.SMSF, AccountType.Company, AccountType.Trust].includes(
    accountType
  );

export const premiumAccountSubType = (accountSubType: AccountSubType) =>
  [AccountSubType.Trader].includes(accountSubType);
