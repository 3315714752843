import { LoginResponse } from "./LoginResponse";

export type VerifyAccountInviteErrorResponse = {
  status: "error";
  code: VerifyAccountInviteErrorCode;
};

export type VerifyAccountInviteSuccessResponse = {
  status: "success";
  newUser?: boolean;
  auth: LoginResponse;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type VerifyAccountInviteResponse =
  | VerifyAccountInviteErrorResponse
  | VerifyAccountInviteSuccessResponse;

export enum VerifyAccountInviteErrorCode {
  Expired = "Expired",
  Invalid = "Invalid",
  PasswordRequired = "PasswordRequired",
}
