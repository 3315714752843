import {
  VerifyAccountInviteErrorResponse,
  VerifyAccountInviteSuccessResponse,
} from "@syla/shared/types/responses/VerifyAccountInviteResponse";
import axios from "axios";

export class VerifyAccountInviteError extends Error {
  constructor(public response: VerifyAccountInviteErrorResponse) {
    super();
  }
}

export const verifyAccountInvite = async ({
  token,
  password,
}: {
  token: string;
  password?: string;
}): Promise<VerifyAccountInviteSuccessResponse> => {
  try {
    const { data } = await axios.post<VerifyAccountInviteSuccessResponse>(
      "/accounts/accept-invite",
      {
        token,
        password,
      }
    );
    return data;
  } catch (error: any) {
    if (error?.response?.data?.status != "error") {
      throw error;
    }

    throw new VerifyAccountInviteError(
      error.response.data as VerifyAccountInviteErrorResponse
    );
  }
};
