/**
 * The border styling of an option block depends on its position and the number of row displaying in the select input
 * @param index index of the option
 * @param listLength length of the options list
 * @param doubleRows determine if options are displayed in single or double
 * @returns the border width styling for a option block
 */

export const returnBorderColor = (
  index: number,
  listLength: number,
  doubleRows: boolean
) => {
  // When having 2 rows
  if (doubleRows) {
    if (index % 2 === 0) {
      // When having 2 rows, option is on LHS, and on last row
      if (
        (index + 1 === listLength && listLength % 2 === 1) ||
        (index === listLength && listLength % 2 === 0)
      ) {
        // return "0 1px 0 0";
        return "#fff #e5e5e5 #fff #fff";
      }
      // When having 2 rows and option is on LHS
      return "#fff #e5e5e5 #e5e5e5 #fff";
    }
    // When having 2 rows, option on RHS, and on last row
    if (index + 1 === listLength) {
      return "#fff";
    }
    // When having 2 rows and option is on RHS
    return "#fff #fff #e5e5e5 #fff";
  }
  // When options only have 1 row, and on the last row
  if (index + 1 === listLength) {
    return "#fff";
  }
  // When options only have 1 row
  return "#fff #fff #e5e5e5 #fff";
};
