import {
  FormControl,
  Input,
  FormErrorMessage,
  Text,
  Button,
} from "@chakra-ui/react";
import { validatePasswordInput } from "@syla/shared/helpers/validatePassword";
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import {
  ResetPasswordError,
  ResetPasswordErrorReason,
} from "../../../api/authentication/resetPassword";
import { UserContext } from "../../../contexts/UserContext";
import { useNavigator, Route } from "../../../routers/navigator";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { FormContainer } from "../../atoms/Containers";
import { PasswordRequirements } from "../../atoms/PasswordRequirements";
import { UnexpectedError } from "../../atoms/UnexpectedError";
import { Label, Form } from "../forms/FormComponents";
import { SylaFormHeader } from "../forms/SylaFormHeader";

interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

export const ResetPasswordForm = (): JSX.Element => {
  const { resetPassword } = useContext(UserContext);

  const location = useLocation();
  const navigate = useNavigator();
  const token = new URLSearchParams(location.search).get("token");

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<ResetPasswordFormData>();

  const [errorReason, setErrorReason] = useState<
    ResetPasswordErrorReason | undefined
  >();
  const [badRequestError, setBadRequestError] = useState<string | undefined>();

  const onSubmit = async ({ password }: ResetPasswordFormData) => {
    try {
      const { defaultAccountId } = await resetPassword(token, password);
      navigate({
        route: Route.Home,
        accountId: defaultAccountId,
        replace: true,
      });
    } catch (error) {
      if (error instanceof ResetPasswordError) {
        setErrorReason(error.errorReason);
        if (error.errorReason == ResetPasswordErrorReason.BadRequest)
          setBadRequestError(error.errorString);
      } else {
        setErrorReason(ResetPasswordErrorReason.UnknownError);
        setBadRequestError(undefined);
      }
    }
  };
  const errorColor = "red.500";
  return (
    <FormContainer>
      <SylaFormHeader heading={"Set your new password"} subText={"to Syla"} />

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl
          isDisabled={isSubmitting}
          isInvalid={errors?.password !== undefined}
          mb="30px"
        >
          <Label content="New password" />
          <Input
            type="password"
            placeholder="Type your new password"
            {...register("password", {
              required: "Password is missing",
              validate: validatePasswordInput,
            })}
            bgColor="white.0"
          />
          {errors.password && (
            <>
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              {PasswordRequirements()}
            </>
          )}
        </FormControl>
        <FormControl
          isDisabled={isSubmitting}
          isInvalid={errors?.confirmPassword !== undefined}
          mb="30px"
        >
          <Label content="Confirm your password" />
          <Input
            type="password"
            placeholder="Type your new password again"
            {...register("confirmPassword", {
              required: "Password confirmation is missing",
              validate: (confirmPassword?: string) =>
                confirmPassword === getValues("password")
                  ? undefined
                  : "Password does not match",
            })}
            bgColor="white.0"
          />
          {errors.confirmPassword && (
            <FormErrorMessage>
              {errors.confirmPassword.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <ButtonVariant
          content="Continue"
          color="red"
          onClick={() => {}}
          disabled={isSubmitting}
          isLoading={isSubmitting}
          spam="spam"
          type="submit"
          mb="20px"
        />
        {/* Render conditional error states */}
        {errorReason == ResetPasswordErrorReason.Invalid && (
          <Text color={errorColor}>
            {"This reset link is no-longer valid. Please "}
            <Button
              color="red.500"
              disabled={false}
              onClick={() =>
                navigate({
                  route: Route.ForgotPassword,
                })
              }
              variant="link"
            >
              try again.
            </Button>
          </Text>
        )}
        {errorReason == ResetPasswordErrorReason.BadRequest && (
          <Text color={errorColor}>{badRequestError}</Text>
        )}
        {errorReason == ResetPasswordErrorReason.UnknownError &&
          UnexpectedError()}
      </Form>
    </FormContainer>
  );
};
