import { max } from "lodash";
import { Decimal, sumDecimalWithFunction } from "../decimal";
import { PopulatedAssetHolding } from "../types/models/AssetHoldingBase";
import {
  SyncState,
  getWalletName,
  WalletImportMethod,
} from "../types/models/WalletBase";
import { DataSourceType } from "../types/models/DataSourceBase";
import { GetWalletsResponse } from "../types/responses/GetWalletsResponse";
import { AssetValueService } from "./assets/AssetValueService";

export type HoldingDetails<TId> = {
  wallet: {
    id: TId | string;
    name: string;
    dataSource?: string; // name
    address?: string;
    image?: string;
    updatedAt?: Date;
    marketValue?: Decimal;
    canSync?: boolean;
    syncState?: SyncState;
  };
  holding?: PopulatedAssetHolding<TId>;
};

export const getHoldingsTableData = <TId extends { toString: () => string }>({
  walletHoldings,
  valueService,
  byAsset,
}: {
  walletHoldings: GetWalletsResponse<TId>;
  valueService?: AssetValueService<TId>;
  byAsset: boolean;
}): HoldingDetails<TId>[] =>
  walletHoldings.wallets
    // wallet holdings
    .flatMap((wallet) => {
      const walletDetails: HoldingDetails<TId>["wallet"] = {
        id: wallet._id,
        name: getWalletName({ wallet, dataSource: wallet.dataSource }),
        dataSource: wallet.dataSource.name,
        canSync: wallet.importMethod == WalletImportMethod.API,
        syncState: wallet.syncState,
        address:
          wallet.dataSource.type == DataSourceType.Blockchain ||
          wallet.dataSource.type == DataSourceType.Wallet
            ? wallet.apiKey
            : undefined,
        image: wallet.dataSource.image,
        updatedAt: max([wallet.lastSynced, wallet.lastUploaded]),
        marketValue:
          valueService &&
          wallet.holdings &&
          sumDecimalWithFunction(
            wallet.holdings.map((holding) =>
              valueService.getValue({
                asset: holding.asset,
                amount: holding.balance,
              })
            ),
            (value: Decimal | undefined) => value
          ),
      };

      if (!wallet.holdings || !wallet.holdings.length)
        return {
          wallet: walletDetails,
          holding: undefined,
        } as HoldingDetails<TId>;

      return wallet.holdings.flatMap((holding) => ({
        wallet: walletDetails,
        holding: {
          ...holding,
          marketValue: valueService?.getValue({
            asset: holding.asset,
            amount: holding.balance,
          }),
        },
      }));
    })
    // missing holdings
    .concat(
      walletHoldings.missingHoldings?.length
        ? walletHoldings.missingHoldings.flatMap((holding) => ({
            wallet: {
              id: "missing",
              name: "Missing Balances",
              image: "/cloud.png",
              updatedAt: max(
                walletHoldings.wallets.map((wallet) =>
                  max([wallet.lastSynced, wallet.lastUploaded])
                )
              ),
              marketValue:
                valueService &&
                sumDecimalWithFunction(
                  walletHoldings.missingHoldings.map((holding) =>
                    valueService.getValue({
                      asset: holding.asset,
                      amount: holding.balance,
                    })
                  ),
                  (value) => value?.abs()
                ),
            },
            holding: {
              ...holding,
              marketValue: valueService?.getValue({
                asset: holding.asset,
                amount: holding.balance,
              }),
            },
          }))
        : []
    )
    // account holdings (total portfolio)
    .concat(
      byAsset
        ? []
        : walletHoldings.accountHoldings.length
        ? walletHoldings.accountHoldings.flatMap((holding) => ({
            wallet: {
              id: "total",
              name: "Total Portfolio",
              image: "/syla-custom.png",
              updatedAt: max(
                walletHoldings.wallets.map((wallet) =>
                  max([wallet.lastSynced, wallet.lastUploaded])
                )
              ),
              marketValue:
                valueService &&
                sumDecimalWithFunction(
                  walletHoldings.accountHoldings.map((holding) =>
                    valueService.getValue({
                      asset: holding.asset,
                      amount: holding.balance,
                    })
                  ),
                  (value) => value
                ),
            },
            holding: {
              ...holding,
              marketValue: valueService?.getValue({
                asset: holding.asset,
                amount: holding.balance,
              }),
            },
          }))
        : [
            {
              wallet: {
                id: "0",
                name: "Total Portfolio",
                image: "/android-chrome-192x192.png",
              },
              holding: undefined,
            } as HoldingDetails<TId>,
          ]
    )
    .filter((holding) => !byAsset || !!holding.holding);

export const aggregateWalletHoldingAssets = <TId>(
  walletHoldingsResponse: GetWalletsResponse<TId>
) =>
  walletHoldingsResponse.wallets
    .flatMap((wallet) => wallet.holdings?.map((h) => h.asset) ?? [])
    .concat(
      walletHoldingsResponse.accountHoldings.map((holding) => holding.asset)
    )
    .concat(
      walletHoldingsResponse.missingHoldings.map((holding) => holding.asset)
    );
