import { parseDecimal } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { ModelTaxResultsResponse } from "@syla/shared/types/responses/ModelTaxResultsResponse";
import axios from "axios";

export const modelTaxYearResult = async ({
  accountId,
  financialYearValue,
}: {
  accountId: string;
  financialYearValue: string;
}): Promise<ModelTaxResultsResponse<string>> => {
  const { data }: { data: TransportType<ModelTaxResultsResponse<string>> } =
    await axios.post(`/accounts/${accountId}/model-tax-year-result`, {
      financialYearValue,
    });

  return (
    data && {
      taxResultSavings: data.taxResultSavings.map((taxYearDiff) => ({
        ...taxYearDiff,
        gain: parseDecimal(taxYearDiff.gain),
      })),
    }
  );
};
