import { permissionFor } from "@syla/shared/types/helpers/permissions";
import React from "react";
import { Flex, Divider, Box } from "@chakra-ui/react";
import { useHistory, Link } from "react-router-dom";
import {
  canPurchaseSubscription,
  canUpgradeSubscription,
} from "../../../helper/permissions";
import { buildTaxReportsLink } from "../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useQueryGetAccount } from "../../../store/useQueryGetAccount";
import { useQueryGetAccountTransactionCount } from "../../../store/useQueryGetAccountTransactionCount";
import { useQuerySubscriptions } from "../../../store/useQuerySubscriptions";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { ContentContainer } from "../../atoms/Containers";
import { EmptyDataMessage } from "../../atoms/EmptyDataMessage";
import { SectionHeading } from "../../atoms/Headings";
import { useGetAccountFinancialYearsData } from "../../../store/actions/userFinancialYear";
import { DocumentContainer } from "./DocumentContainer";
import { TaxReportRow } from "./TaxReportRow";

export const TaxReportsTable = () => {
  const history = useHistory();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const {
    data: userFinancialYearsData,
    isLoading: isLoadingFinancialYearsData,
    isStale: userFinancialYearsIsStale,
  } = useGetAccountFinancialYearsData();

  const { data: getSubscriptionsResponse, isLoading: subscriptionsLoading } =
    useQuerySubscriptions();
  const subscriptions = getSubscriptionsResponse?.subscriptions ?? [];

  const {
    data: txCount,
    isLoading: txCountLoading,
    isStale: txCountStale,
  } = useQueryGetAccountTransactionCount();

  // fetch user details
  const { data: account, isLoading: accountLoading } =
    useQueryGetAccount(accountId);

  // loading state for all the queries
  const isLoading =
    isLoadingFinancialYearsData ||
    subscriptionsLoading ||
    txCountLoading ||
    txCountStale ||
    userFinancialYearsIsStale ||
    accountLoading;

  const { hasPermission: canDownload } = permissionFor({
    action: "get-tax-report",
    subscriptions,
    transactionCount: txCount ?? 0,
    account: account,
  });

  const canPurchase =
    canPurchaseSubscription({ account, subscriptions }) ||
    canUpgradeSubscription({ subscriptions });

  return (
    <ContentContainer h="fit-content" maxW="500px">
      <Flex
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        w="100%"
      >
        <SectionHeading>Tax Reports</SectionHeading>

        <Link to={buildTaxReportsLink({ accountId })}>
          <ButtonVariant
            content="View All"
            disabled={isLoadingFinancialYearsData}
            outlineType="ghost"
          />
        </Link>
      </Flex>
      <Divider />

      <Box w="100%" overflowX="auto">
        <DocumentContainer minW="280px">
          {
            // display empty message if user has no financial years (no imported transactions)
            !userFinancialYearsData?.length ? (
              <Flex
                justifyContent="center"
                alignItems="center"
                h="60%"
                w="90%"
                m="auto"
              >
                <EmptyDataMessage content="Add a data source to see your tax reports" />
              </Flex>
            ) : (
              <>
                {userFinancialYearsData.map((financialYear) => (
                  <TaxReportRow
                    key={financialYear.value}
                    selectedFinancialYear={financialYear}
                    canDownload={canDownload}
                    canPurchase={canPurchase}
                    isLoading={isLoading}
                  />
                ))}
              </>
            )
          }
        </DocumentContainer>
      </Box>
    </ContentContainer>
  );
};
