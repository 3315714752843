import { Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const PageHeading = styled(Text)`
  font-size: 1.625rem;
  font-weight: bold;
  color: black.800;
  font-family: GintoNormal;
  @media (min-width: 501px) {
    font-size: 2.7rem;
  }
`;

export const SectionHeading = styled(Text)`
  font-size: 1.4rem;
  font-weight: bold;
  color: #333333;
  padding: 20px 0 10px 20px;
  font-family: GintoNormal;
`;

export const ModalHeading = styled(Text)`
  font-size: 1.6rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  font-family: GintoNormal;
`;

export const ModalFormHeading = styled(Text)`
  font-size: 1.125rem;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin-bottom: 20px;
  font-family: GintoNormal;
`;

export const DrawerHeading = styled(Text)`
  font-size: 1.625rem;
  color: #333333;
  padding: 20px 0 10px 20px;
  font-family: GintoNormal;
  /* margin-bottom: 30px; */
`;

export const TaxReportHeading = styled(Text)`
  font-size: 1.125rem;
  font-weight: bold;
  color: #333;
  font-family: GintoNormal;
`;
