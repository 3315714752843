import { FinancialYear } from "@syla/shared/types/models/FinancialYear";
import {
  StatusOption,
  ImportFilter,
} from "@syla/shared/types/requests/GetGroupsRequest";
import {
  TransactionType,
  TransactionTagType,
} from "@syla/shared/types/models/TransactionBase";
import { RangeValue } from "rc-picker/lib/interface";

export interface OrderFilterSetting {
  transactionType: TransactionType[];
  ledgerType: TransactionTagType[];
  dataSource: string[];
  asset: string[];
  status: StatusOption[];
  searchQuery: string;
  date?:
    | { financialYear: FinancialYear; range?: never }
    | { financialYear?: never; range: RangeValue<Date> };
  imports: ImportFilter<string>[];
}

export enum OrderSortOption {
  DateAscending = "Oldest to Newest",
  DateDescending = "Newest to Oldest",
  // GainsAscending = "Gains Ascending",
  // GainsDescending = "Gains Descending",
}
