import * as Sentry from "@sentry/browser";
import axios from "axios";

export function captureRequestError(error) {
  Sentry.withScope((scope) => {
    if (axios.isAxiosError(error)) {
      scope.setExtra("body", error.request.body);
    }

    Sentry.captureException(error);
  });
}
