import { ModelBase } from "./ModelBase";

export enum AssetType {
  Currency = "currency",
  Custom = "custom",
  Token = "token",
  NFT = "nft",
}

export interface AssetBase<TId> extends ModelBase<TId> {
  code: string;
  name: string;
  address?: string;
  tokenId?: string;
  image?: string;
  type: AssetType;

  marketCapRank?: number;
  cryptoCompareId?: string;
}
