import { secondsToMilliseconds } from "date-fns";
import { useMutation } from "react-query";
import {
  updateAccountAccess,
  UpdateAccountAccess,
} from "../api/account/updateAccountAccess";
import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";
import { getSubscriptionQueryKey } from "./useQuerySubscriptions";

export const SUBSCRIPTION_UPDATE_DELAY = secondsToMilliseconds(5);

export const useMutationUpdateAccountAccess = (accountId: string) =>
  useMutation(
    (props: UpdateAccountAccess) => updateAccountAccess(accountId, props),
    {
      mutationKey: [...ACCOUNT_META_QUERY_KEY, accountId],
      onSettled: () => {
        queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
        // subscription may also be cancelled.
        // Invalidate after some timeout to allow stripe events to come through
        setTimeout(() => {
          queryClient.invalidateQueries([
            ...getSubscriptionQueryKey(accountId),
          ]);
        }, SUBSCRIPTION_UPDATE_DELAY);
      },
    }
  );
