import { Flex, Text, Switch, Button, Box, ButtonProps } from "@chakra-ui/react";
import React, { ChangeEvent } from "react";

import { InfoToolTip } from "../../atoms/InfoToolTip";

interface SettingRowProps {
  label: string;
  currentValue?: string;
  type?: "edit" | "toggle" | "remove" | "copy" | "none";
  labelWidth?: string;
  tip?: string;
  action?: ((event: ChangeEvent<HTMLInputElement>) => void) | (() => void);
  btnText?: string;
  disabled?: boolean;
  component?: React.ReactNode;
}

const RenderBtn = ({
  type,
  value,
  action,
}: {
  type: "edit" | "toggle" | "remove" | "copy" | "none";
  value: string;
  action: ((event: ChangeEvent<HTMLInputElement>) => void) | (() => void);
}) => {
  if (type === "edit") {
    return (
      <SettingsButton onClick={action as () => void}>
        {value ? "Edit" : "Add"}
      </SettingsButton>
    );
  }
  if (type === "remove") {
    return (
      <SettingsButton onClick={action as () => void} w="80px">
        {value ? "Remove" : "Invite"}
      </SettingsButton>
    );
  }
  if (type === "toggle") {
    return (
      <Switch
        size="sm"
        px="10px"
        isChecked={value === "Yes"}
        onChange={action}
        colorScheme="red"
      />
    );
  }
  if (type === "copy") {
    return (
      <SettingsButton onClick={action as () => void}>{value}</SettingsButton>
    );
  }
  return null;
};

const labelColor = "black.800";
const settingColor = "gray.800";

export const SettingRow = ({
  label,
  currentValue,
  type = "none",
  labelWidth = "170px",
  tip,
  action,
  btnText,
  disabled,
  component,
}: SettingRowProps) => (
  <Flex direction="row" alignItems="center" w="100%" mb="10px">
    {/* --------------------- Label --------------------- */}
    <Flex w={labelWidth} mr={labelWidth && "30px"}>
      <Text fontSize="1rem" color={labelColor} mr="15px" whiteSpace="nowrap">
        {label}
      </Text>
      {tip && <InfoToolTip label={tip} wrap />}
    </Flex>
    {component ? (
      <Box fontSize="1rem" color={settingColor}>
        {component}
      </Box>
    ) : (
      <>
        {/* --------------------- Current Value --------------------- */}
        <Flex overflow="auto" textOverflow="ellipsis">
          <Text fontSize="1rem" color={settingColor} noOfLines={1} pr="15px">
            {currentValue || "N/A"}
          </Text>
        </Flex>
        {/* --------------------- Button --------------------- */}
        <Flex>
          <RenderBtn
            type={disabled ? "none" : type}
            value={btnText ?? currentValue!}
            action={action!}
          />
        </Flex>
      </>
    )}
  </Flex>
);

export const SettingsButton = (props: ButtonProps) => (
  <Button
    fontSize="0.75rem"
    fontWeight="400"
    color="black.550"
    bgColor="white.0"
    borderColor="black.200"
    borderWidth="1px"
    w="50px"
    h="30px"
    _hover={{
      bgColor: "white.500",
    }}
    {...props}
  />
);
