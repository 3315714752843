"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentSubscription = exports.planHasCapacity = exports.getSubscriptionStatus = exports.getValidSubscriptions = exports.hasValidPlanWithCapacity = exports.hasValidPlanWith = exports.SUBSCRIPTION_END_GRACE_PERIOD_HOURS = void 0;
const date_fns_1 = require("date-fns");
exports.SUBSCRIPTION_END_GRACE_PERIOD_HOURS = 48;
function hasValidPlanWith({ cond, subscriptions, }) {
    return getValidSubscriptions({
        subscriptions: subscriptions,
    }).some((s) => cond({
        plan: s.plan,
        customTransactionLimit: s.customTransactionLimit,
    }));
}
exports.hasValidPlanWith = hasValidPlanWith;
function hasValidPlanWithCapacity({ transactionCount, subscriptions, }) {
    return getValidSubscriptions({
        subscriptions: subscriptions,
    }).some((subscription) => {
        var _a;
        const limit = (_a = subscription.customTransactionLimit) !== null && _a !== void 0 ? _a : subscription.plan.transactionLimit;
        return limit === -1 || transactionCount <= limit;
    });
}
exports.hasValidPlanWithCapacity = hasValidPlanWithCapacity;
function getValidSubscriptions({ subscriptions, }) {
    const now = new Date();
    return subscriptions.filter((subscription) => subscription.status == "valid" &&
        now >= subscription.begins &&
        now <= (0, date_fns_1.addHours)(subscription.ends, exports.SUBSCRIPTION_END_GRACE_PERIOD_HOURS));
}
exports.getValidSubscriptions = getValidSubscriptions;
function getSubscriptionStatus(subscription) {
    const now = new Date();
    const expired = (0, date_fns_1.addHours)(subscription.ends, exports.SUBSCRIPTION_END_GRACE_PERIOD_HOURS) < now;
    return {
        expired,
        cancelled: !!subscription.isCancelled,
        valid: subscription.status == "valid" && !expired,
        expiryDate: (0, date_fns_1.addHours)(subscription.ends, exports.SUBSCRIPTION_END_GRACE_PERIOD_HOURS),
        renewalDate: subscription.ends, // end date minus grace period
    };
}
exports.getSubscriptionStatus = getSubscriptionStatus;
const planHasCapacity = ({ plan: { transactionLimit }, transactionCount, }) => transactionLimit >= transactionCount || transactionLimit === -1;
exports.planHasCapacity = planHasCapacity;
/**
 * Get current subscription. Stripe based first or manual otherwise.
 * @param subscriptions
 */
const getCurrentSubscription = (subscriptions) => {
    var _a;
    return (_a = subscriptions.find((s) => s.current && s.stripeSubscriptionId)) !== null && _a !== void 0 ? _a : subscriptions.find((s) => s.current);
};
exports.getCurrentSubscription = getCurrentSubscription;
