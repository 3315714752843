import { getAccountShortName } from "@syla/shared/types/models/AccountAccessBase";
import { useContext } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { useReportError } from "../../../helpers/useReportError";
import { useMutationUpdateAccountAccess } from "../../../store/useMutationUpdateAccountAccess";
import { ModalContext } from "../../../contexts/ModalContext";
import { Account } from "../../../types/Account";

export function useRemoveAccountDialog({ account }: { account: Account }) {
  const { openModal, setBtn2Loading, closeModal } = useContext(ModalContext);
  const { userDetails } = useUserContext();
  const { mutateAsync: updateAccountAccess } = useMutationUpdateAccountAccess(
    account._id
  );

  // error reporting
  const reportError = useReportError();

  return {
    openRemoveAccountDialog: () =>
      openModal({
        type: "confirmation",
        icon: "delete",
        heading: `Remove yourself?`,
        contents: [
          `You’re about to remove yourself from the "${getAccountShortName(
            account
          )}" account`,
          "Warning - You’ll only be able to regain access again by asking the account owner.",
        ],
        btn2Content: "Remove",
        btn2Action: async () => {
          try {
            setBtn2Loading(true);
            await updateAccountAccess({
              ops: [
                {
                  action: "delete",
                  userId: userDetails?.id ?? "",
                },
              ],
            });
            closeModal();
            setBtn2Loading(false);
          } catch (error) {
            closeModal();
            reportError(error);
            console.log(error);
          }
        },
      }),
  };
}
