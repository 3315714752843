import { DeleteIcon } from "@chakra-ui/icons";
import {
  Tr,
  Td,
  VStack,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  AccountAccessRoleName,
  AccountPermission,
  roleHasPermission,
  AccountAccessRole,
  getAccountShortName,
} from "@syla/shared/types/models/AccountAccessBase";
import { AccountAccessWithUserPopulated } from "@syla/shared/types/responses/GetAccountsResponse";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HiDotsVertical } from "react-icons/hi";
import { useRemoveBillingOwnerDialog } from "../../../components/organisms/accounts/useRemoveBillingOwnerDialog";
import { ModalContext } from "../../../contexts/ModalContext";
import { useUserContext } from "../../../contexts/UserContext";
import { hasAccountPermission } from "../../../helper/permissions";
import { useReportError } from "../../../helpers/useReportError";
import { IModalInfo } from "../../../react-app-env";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useMutationUpdateAccountAccess } from "../../../store/useMutationUpdateAccountAccess";
import { Account } from "../../../types/Account";
import { AccountAccessRoleSelector } from "../AccountAccessRoleSelector";

export function AccountAccessRow({
  account,
  accountAccess,
}: {
  account: Account;
  accountAccess: AccountAccessWithUserPopulated<string>;
}) {
  const { userDetails } = useUserContext();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const shortName = getAccountShortName(account);

  // editing our own access
  const ownAccess = accountAccess.user._id == userDetails?.id;

  // update access
  const { mutateAsync: updateAccountAccess } =
    useMutationUpdateAccountAccess(accountId);

  const reportError = useReportError();

  // remove access
  const { openModal, closeModal, setBtn2Loading } = useContext(ModalContext);
  const openRemoveAccessDialog = useCallback(() => {
    openModal({
      type: "confirmation",
      icon: "delete",
      heading: ownAccess
        ? `Remove yourself?`
        : `Remove ${accountAccess.user.email}?`,
      contents: ownAccess
        ? [
            `You’re about to remove yourself from the "${shortName}" account.`,
            `Warning - You’ll only be able to regain access again by asking the account owner.`,
          ]
        : [
            `This will remove "${accountAccess.user.email}" so they no longer have access to the "${shortName}" account.`,
          ],
      btn2Content: "Remove",
      btn2Action: async () => {
        setBtn2Loading(true);
        try {
          await updateAccountAccess({
            ops: [
              {
                action: "delete",
                userId: accountAccess.user._id!,
              },
            ],
          });
          closeModal();
        } catch (error) {
          reportError(error);
        } finally {
          setBtn2Loading(false);
        }
      },
    });
  }, [
    accountAccess.user._id,
    accountAccess.user.email,
    closeModal,
    openModal,
    ownAccess,
    reportError,
    setBtn2Loading,
    shortName,
    updateAccountAccess,
  ]);

  // remove billing owner
  const openRemoveBillingOwnerDialog = useRemoveBillingOwnerDialog({
    accountId,
  });

  // form state
  const formMethods = useForm<AccountAccessWithUserPopulated<string>>({
    defaultValues: accountAccess,
  });
  const { watch, setValue, reset } = formMethods;

  // reset form on data changes
  useEffect(() => {
    reset(accountAccess);
  }, [accountAccess, reset, account]);

  const [isSaving, setIsSaving] = useState(false);

  const canSaveRole = useCallback(
    async (newRole) => {
      const permissionChanged = (permission: AccountPermission) =>
        roleHasPermission(accountAccess.role, permission) &&
        !roleHasPermission(newRole, permission);

      // detect downgrades
      if (
        ownAccess &&
        (permissionChanged(AccountPermission.ChangeOwnership) ||
          permissionChanged(AccountPermission.ChangeAccess))
      )
        return await downgradeConfirmationPrompt({
          openModal,
          accountAccess,
          closeModal,
        });

      return true;
    },
    [accountAccess, closeModal, openModal, ownAccess]
  );

  const hasPermissionToRemoveMember =
    ownAccess ||
    (accountAccess.role != AccountAccessRole.Owner &&
      hasAccountPermission(account, AccountPermission.ChangeAccess)) ||
    (accountAccess.role == AccountAccessRole.Owner &&
      hasAccountPermission(account, AccountPermission.ChangeOwnership));

  const isBillingOwner = account.subscriptions.some(
    (sub) => sub.billingOwner?._id == accountAccess.user._id
  );

  return (
    <Tr key={accountAccess._id}>
      {/* Account Member */}
      <Td>
        <VStack w="100%" alignItems="flex-start">
          {accountAccess.user.fullName && (
            <Text>{accountAccess.user.fullName}</Text>
          )}
          <Text>
            {accountAccess.user.email}
            {ownAccess ? " (You)" : ""}
          </Text>
        </VStack>
      </Td>

      {/* Billing Owner */}
      <Td>
        <Text>{isBillingOwner ? "Yes" : ""}</Text>
      </Td>

      {/* Role */}
      <Td width="15rem">
        {/* Check permissions */}
        {(accountAccess.role != AccountAccessRole.Owner &&
          hasAccountPermission(account, AccountPermission.ChangeAccess)) ||
        (accountAccess.role == AccountAccessRole.Owner &&
          hasAccountPermission(account, AccountPermission.ChangeOwnership)) ? (
          <AccountAccessRoleSelector
            currentRole={watch("role")}
            onSelected={async (role) => {
              if (!(await canSaveRole(role))) {
                return;
              }
              setValue("role", role);

              try {
                setIsSaving(true);
                await updateAccountAccess({
                  ops: [
                    {
                      userId: accountAccess.user._id!,
                      action: "set",
                      role,
                    },
                  ],
                });
              } catch (error) {
                reportError(error);
              } finally {
                setIsSaving(false);
              }
            }}
            isLoading={isSaving}
            width="100%"
          />
        ) : (
          // otherwise just display
          <Text>{AccountAccessRoleName[watch("role")]}</Text>
        )}
      </Td>

      {/* Invite Status */}
      <Td>Accepted</Td>

      {/* Menu */}
      <Td textAlign="right" width="5rem">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HiDotsVertical />}
            backgroundColor="transparent"
            isDisabled={!hasPermissionToRemoveMember}
          />
          <MenuList p="0">
            {hasPermissionToRemoveMember && (
              <MenuItem
                textColor="red.600"
                onClick={() =>
                  isBillingOwner
                    ? openRemoveBillingOwnerDialog(accountAccess.user._id!)
                    : openRemoveAccessDialog()
                }
                icon={<DeleteIcon />}
              >
                Remove
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}

function downgradeConfirmationPrompt({
  openModal,
  accountAccess,
  closeModal,
}: {
  openModal: (modalInfo: IModalInfo) => void;
  accountAccess: AccountAccessWithUserPopulated<any>;
  closeModal: () => void;
}) {
  return new Promise<boolean>((resolve) => {
    openModal({
      type: "confirmation",
      heading: `Remove "${AccountAccessRoleName[accountAccess.role]}" role?`,
      contents: [
        `Downgrading your permission level will reduce your ability to manage this account.`,
      ],
      btn2Content: "Understood",
      btn2Action: () => {
        closeModal();
        resolve(true);
      },
      onClose: () => {
        resolve(false);
      },
    });
  });
}
