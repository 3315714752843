import { Flex, Spinner, useClipboard, Divider } from "@chakra-ui/react";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import React, { useContext, useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { useForm, FormProvider } from "react-hook-form";
import { PageContainer } from "../../components/atoms/Containers";
import { AddressForm } from "../../components/molecules/forms/settingForms/AddressForm";
import { ContactNumberForm } from "../../components/molecules/forms/settingForms/ContactNumberForm";
import { AccountAbnEditable } from "../../components/organisms/accounts/AccountAbnEditable";
import { AccountEmailEditable } from "../../components/organisms/accounts/AccountEmailEditable";
import { AccountNameEditable } from "../../components/organisms/accounts/AccountNameEditable";
import { NicknameEditable } from "../../components/organisms/accounts/NicknameEditable";
import { SettingRow } from "../../components/organisms/setting/SettingRow";
import { ModalContext } from "../../contexts/ModalContext";
import { hasAccountPermission } from "../../helper/permissions";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useQueryGetAccount } from "../../store/useQueryGetAccount";
import { Account } from "../../types/Account";
import { SectionContainer, SectionHeading } from "./AccountSettingsCommon";

export function AccountGeneralSettings() {
  const { openModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  const { onCopy: onCopyBglId, hasCopied: hasCopiedBglId } = useClipboard(
    account?._id ?? ""
  );

  const canChangeAccountDetails = hasAccountPermission(
    account,
    AccountPermission.ChangeAccountDetails
  );

  const formProps = useForm<Partial<Account>>({
    defaultValues: account,
  });
  const { reset } = formProps;

  useEffect(() => {
    reset(account);
  }, [account, reset]);

  if (accountIsLoading || !account)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center" my="40px">
          <Spinner />
        </Flex>
      </PageContainer>
    );

  return (
    <FormProvider {...formProps}>
      <SectionContainer direction="column" minW="300px">
        <SectionHeading>General Settings</SectionHeading>
        <SettingRow
          label="Account Name"
          tip="The Acount Name also be be displayed as the Legal Name on your tax report"
          component={<AccountNameEditable account={account} />}
        />
        <SettingRow
          label="Account Email"
          tip="This email will be displayed on the Crypto Tax Report PDF"
          component={<AccountEmailEditable account={account} />}
        />
        <SettingRow
          label="ABN"
          tip="Displayed on the Crypto Tax Report PDF"
          component={<AccountAbnEditable account={account} />}
        />
        <SettingRow
          label="Contact Number"
          currentValue={
            account.contactNumber.number
              ? `${
                  account.contactNumber.countryCode
                    ? `(${account.contactNumber.countryCode})`
                    : ""
                } ${account.contactNumber.number}`
              : ""
          }
          action={() =>
            openModal({
              type: "component",
              heading: "Contact Number",
              contents: [
                "Contact number is optional and only required if you want it displayed on your tax report",
              ],
              component: (
                <ContactNumberForm
                  currentCountryCode={account.contactNumber.countryCode || ""}
                  currentNumber={account.contactNumber.number || ""}
                />
              ),
            })
          }
          type={canChangeAccountDetails ? "edit" : "none"}
        />
        <SettingRow
          label="Address"
          currentValue={
            account.address.street &&
            account.address.suburb &&
            account.address.postcode &&
            account.address.state
              ? `${
                  account.address?.suite ? `${account.address.suite}, ` : ""
                } ${account.address.street}, ${account.address.suburb} (${
                  account.address.postcode
                }), ${account.address.state}`
              : ""
          }
          action={() =>
            openModal({
              type: "component",
              heading: "Address",
              contents: [
                "Address is optional and only required if you want it displayed on your tax report",
              ],
              component: <AddressForm currentAddress={account.address} />,
            })
          }
          type={canChangeAccountDetails ? "edit" : "none"}
        />
        {/* ---------------------- BGL Account ID ---------------------- */}
        <SettingRow
          label="BGL Account ID"
          currentValue={account._id}
          btnText={hasCopiedBglId ? "Copied" : "Copy"}
          action={onCopyBglId}
          type="copy"
        />
        <Divider mb="1rem" mt="0.5rem" />

        <SettingRow
          label="Nickname"
          tip="A nickname or reference that you can use for this account. It’s only viewable by you."
          component={<NicknameEditable account={account} />}
        />
      </SectionContainer>
    </FormProvider>
  );
}
