import * as Sentry from "@sentry/react";
import { LoginWithGoogleRequestBody } from "@syla/shared/types/requests/LoginWithGoogleRequest";
import { RegisterRequestBody } from "@syla/shared/types/requests/RegisterRequest";
import axios, { AxiosRequestHeaders } from "axios";
import jwtDecode from "jwt-decode";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useMediaQuery } from "@chakra-ui/react";
import { SCREEN_SIZE } from "../constants/common";
import { login as loginRequest } from "../api/authentication/login";
import { verify as verifyRequest } from "../api/authentication/verify";
import { loginWithGoogle as loginWithGoogleRequest } from "../api/authentication/loginWithGoogle";
import { resetPassword as resetPasswordRequest } from "../api/authentication/resetPassword";

import {
  refresh as refreshRequest,
  refreshUrl,
} from "../api/authentication/refresh";
import { register as registerRequest } from "../api/authentication/register";
import { captureRequestError } from "../helper/captureRequestError";
import { useLocalStorage } from "../hooks/localStorage/useLocalStorage";
import { prefetchAssets } from "../store/useQueryAssets";
import { Device } from "../types/user/device";

declare const heap: any;

// set axios base url
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

interface UserDetails {
  accessToken: string;
  refreshToken: string;
  email: string;
  id: string;
  defaultAccountId: string;
}

interface IUserContext {
  authenticated: boolean;
  loggedIn: boolean;
  firstLogin: boolean;
  login: (
    email: string,
    password: string
  ) => Promise<{ defaultAccountId: string }>;
  logout: () => void;
  register: (props: RegisterRequestBody) => Promise<void>;
  verify: (token: string) => Promise<{ defaultAccountId: string }>;
  loginWithGoogle: (
    params: LoginWithGoogleRequestBody
  ) => Promise<{ newUser: boolean; defaultAccountId: string }>;
  resetPassword: (
    token: string | null,
    newPassword: string
  ) => Promise<{ defaultAccountId: string }>;
  finishSignUpFlow: () => void;
  userDetails: UserDetails | undefined;
  userDevice: Device;
  setUserDetails: (props: UserDetails) => void;
  setFirstLogin: (props: boolean) => void;
}

const InitialUserContext: IUserContext = {
  authenticated: false,
  loggedIn: false,
  firstLogin: false,
  login: (async () => {}) as any,
  logout: () => {},
  register: (async () => {}) as any,
  verify: (async () => {}) as any,
  resetPassword: (async () => {}) as any,
  loginWithGoogle: async () => ({ newUser: false } as any),
  finishSignUpFlow: () => {},
  userDetails: undefined,
  userDevice: Device.Other,
  setUserDetails: (props) => {},
  setFirstLogin: (props: boolean) => {},
};

export const UserContext = createContext(InitialUserContext);

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const authStorageKey = "auth";
  const [userDetails, setUserDetails] = useLocalStorage<
    UserDetails | undefined
  >(authStorageKey, undefined);
  const [authenticated, setAuthenticated] = useState(false);

  // state for knowing whether if this is a first time sign up flow
  const [firstLogin, setFirstLogin] = useState(false);
  const finishSignUpFlow = () => setFirstLogin(false);

  const loggedIn = !!userDetails;

  // logout function to clear local storage and local context state
  const logout = useCallback(() => {
    setUserDetails(undefined);
  }, [setUserDetails]);

  // setup axios interceptors
  // manage `authenticated` state
  useEffect(() => {
    let requestInterceptor: number;
    let responseInterceptor: number;

    if (userDetails?.refreshToken) {
      console.log("Updating axios refresh token logic");
      responseInterceptor = axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          // if we get a 401, we can try to refresh our auth token and try again
          if (
            error.response?.status === 401 &&
            error.config.url != refreshUrl // prevent refresh loops if we get a 401 back from refresh process
          ) {
            try {
              console.log("getting new refresh token");
              const { token, refreshToken: refresh } = await refreshRequest(
                userDetails.refreshToken
              );

              setUserDetails({
                ...userDetails,
                refreshToken: refresh,
                accessToken: token,
              });
              const response = await axios(error.config);
              setAuthenticated(true);
              return response;
            } catch (innerError: any) {
              // if we still have a 401 - log out
              if (innerError?.response?.status === 401) {
                setAuthenticated(false);
                logout();
              } else throw innerError;
            }
          }

          // just throw the error if not 401
          throw error;
        }
      );
    }

    if (userDetails?.accessToken) {
      console.log("Updating axios Bearer token");
      requestInterceptor = axios.interceptors.request.use(
        (config) => {
          (
            config.headers as AxiosRequestHeaders
          ).Authorization = `Bearer ${userDetails?.accessToken}`;
          return config;
        },
        (error) => {
          throw error;
        }
      );
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }

    return () => {
      // eject the interceptor when this useEffect runs again
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [userDetails, logout, setUserDetails]);

  // proactively refresh the user's access token if it has expired
  useEffect(() => {
    (async () => {
      if (!userDetails?.accessToken) return;
      if (!userDetails?.refreshToken) return;

      const { exp } = jwtDecode<{ exp: number }>(userDetails.accessToken);

      if (Date.now() >= exp * 1000) {
        setAuthenticated(false);
        // try to refresh token
        try {
          const { token: newAccessToken, refreshToken: newRefreshToken } =
            await refreshRequest(userDetails.refreshToken);

          setUserDetails({
            ...userDetails,
            refreshToken: newRefreshToken,
            accessToken: newAccessToken,
          });
        } catch (error) {
          logout();
        }
      }
    })();
  }, [userDetails, logout, setUserDetails]);

  // prefetch the asset list so user doesn't have to wait later
  useEffect(() => {
    if (authenticated) {
      prefetchAssets().catch(captureRequestError);
    }
  }, [authenticated]);

  // update user details in Sentry
  useEffect(() => {
    Sentry.setUser(
      userDetails?.id ? { id: userDetails.id, email: userDetails.email } : null
    );
    // heap integration
    if (userDetails?.id) {
      heap.identify(userDetails.id);
      heap.addUserProperties({ email: userDetails.email });
    } else {
      heap.resetIdentity();
    }
  }, [userDetails?.id, userDetails?.email]);

  const [userDevice, setUserDevice] = useState<Device>(Device.Other);

  const [isMobile, isDesktop] = useMediaQuery([
    `(max-width: ${SCREEN_SIZE.XS})`,
    `(min-width: ${SCREEN_SIZE.LG})`,
  ]);

  useEffect(() => {
    setUserDevice(
      isMobile ? Device.Mobile : isDesktop ? Device.Desktop : Device.Other
    );
  }, [isMobile, isDesktop]);

  // login function
  const login = useCallback(
    async (loginEmail: string, password: string) => {
      const {
        token,
        refreshToken: refresh,
        userId,
        email,
        accountId,
      } = await loginRequest(loginEmail, password);
      setUserDetails({
        accessToken: token,
        refreshToken: refresh,
        email,
        id: userId,
        defaultAccountId: accountId,
      });

      return { defaultAccountId: accountId };
    },
    [setUserDetails]
  );

  // register function
  const register = useCallback(async (props: RegisterRequestBody) => {
    await registerRequest(props);
  }, []);

  // verify function
  const verify = useCallback(
    async (token: string) => {
      // console.log("Doing verify");
      // set the first time sign up state to true so that the app doesn't move to the authenticated router just yet
      setFirstLogin(true);

      const {
        token: accessToken,
        refreshToken,
        userId,
        email,
        accountId,
      } = await verifyRequest(token);
      setUserDetails({
        accessToken,
        refreshToken,
        email,
        id: userId,
        defaultAccountId: accountId,
      });
      return { defaultAccountId: accountId };
    },
    [setUserDetails]
  );

  // verify function
  const resetPassword = useCallback(
    async (token: string | null, newPassword: string) => {
      // workaround to allow log in via this flow
      setFirstLogin(true);

      const {
        token: accessToken,
        refreshToken,
        userId,
        email,
        accountId,
      } = await resetPasswordRequest(token, newPassword);

      setUserDetails({
        accessToken,
        refreshToken,
        email,
        id: userId,
        defaultAccountId: accountId,
      });

      return { defaultAccountId: accountId };
    },
    [setUserDetails]
  );

  // verify function
  const loginWithGoogle = useCallback(
    async (params: LoginWithGoogleRequestBody) => {
      const {
        token: accessToken,
        refreshToken,
        userId,
        email,
        newUser,
        accountId,
      } = await loginWithGoogleRequest(params);

      if (newUser) {
        setFirstLogin(true);
      }

      setUserDetails({
        accessToken,
        refreshToken,
        email,
        id: userId,
        defaultAccountId: accountId,
      });

      return { newUser, defaultAccountId: accountId };
    },
    [setUserDetails]
  );

  const userContextValue: IUserContext = {
    authenticated,
    loggedIn,
    firstLogin,
    login,
    logout,
    register,
    verify,
    resetPassword,
    finishSignUpFlow,
    loginWithGoogle,
    userDetails,
    userDevice,
    setUserDetails,
    setFirstLogin,
  };

  // console.debug(userContextValue);

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};
