import {
  SyncWalletErrorDetailCode,
  getWalletName,
} from "@syla/shared/types/models/WalletBase";
import {
  isSyncWalletErrorResponse,
  SyncWalletResponseErrorCode,
} from "@syla/shared/types/responses/SyncWalletResponse";
import axios from "axios";
import React from "react";
import ReactMarkdown from "react-markdown";
import { getSyncErrorProps } from "./getSyncErrorProps";

export function getSyncWalletErrorProps(error: unknown) {
  // default props
  let messageTitle = `Couldn't sync some Data Sources`;
  let errorTitle = "Couldn't sync";
  let errorMessage: string | undefined =
    "An unexpected error has occurred -- please try again later. If issues persist, contact support.";

  let isInfo = false;

  // get error details
  if (
    axios.isAxiosError(error) &&
    error.response?.data &&
    isSyncWalletErrorResponse(error.response.data)
  ) {
    const { error: errorDetails, walletState } = error.response.data;

    const walletsWithError = Object.values(walletState ?? {}).filter(
      (w) => w.syncError
    );

    // report sync error
    if (
      errorDetails.code == SyncWalletResponseErrorCode.SYNC_ERROR &&
      walletsWithError.length
    ) {
      // report first wallet for now
      const [wallet] = walletsWithError;
      const syncError = wallet.syncError!;

      const props = getSyncErrorProps(wallet, syncError);
      if (walletsWithError.length == 1) {
        messageTitle = `Couldn't sync ${getWalletName({
          wallet,
          dataSource: wallet.dataSource,
        })}`;
      }
      errorTitle = props.title;
      errorMessage = props.message;

      isInfo = [
        SyncWalletErrorDetailCode.RATE_LIMIT,
        SyncWalletErrorDetailCode.CUSTOM,
      ].includes(syncError.code);
    }
  }
  // or use response text
  else if (
    axios.isAxiosError(error) &&
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500 &&
    error.response.data
  ) {
    errorMessage = error.response.data;
  }

  return {
    isInfo,
    messageTitle,
    errorTitle,
    errorContent: errorMessage ? (
      <ReactMarkdown linkTarget="_blank">{errorMessage}</ReactMarkdown>
    ) : null,
  };
}
