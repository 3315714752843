import { Drawer, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ITransactionDrawer {
  btnRef: any;
  isOpen: boolean;
  onClose: () => void;
  canDefaultClose: boolean;
  children: ReactNode;
}

export const TransactionDrawer = ({
  btnRef,
  isOpen,
  onClose,
  canDefaultClose,
  children,
}: ITransactionDrawer) => (
  <Drawer
    isOpen={isOpen}
    placement="right"
    onClose={onClose}
    finalFocusRef={btnRef}
    size="xl"
    closeOnOverlayClick={canDefaultClose}
    closeOnEsc={canDefaultClose}
  >
    <DrawerOverlay />
    <DrawerContent overflowY="auto">{children}</DrawerContent>
  </Drawer>
);
