import { FileImportState } from "@syla/shared/types/models/WalletBase";
import { AddWalletRequestBody } from "@syla/shared/types/requests/AddWalletRequest";
import { GetWalletsRequestQuery } from "@syla/shared/types/requests/GetWalletsRequest";
import { useQuery } from "react-query";
import { addWallet as addWalletRequest } from "../../api/wallet/addWallet";
import { deleteWallet as deleteWalletRequest } from "../../api/wallet/deleteWallet";
import { getWallets } from "../../api/wallet/getWallets";
import { importDataSources as importDataSourcesRequest } from "../../api/wallet/importDataSources";
import { importWalletCsv as importWalletCsvRequest } from "../../api/wallet/importWalletCsv";

import {
  UpdateWalletBody,
  updateWallet as updateWalletRequest,
} from "../../api/wallet/updateWallet";

import { Wallet, GetWalletsResponse } from "../../types/wallet/wallet";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import {
  queryClient,
  getAccountTransactionsCacheKey,
} from "../transactionsStore";
import { getAllAssetsCacheKey } from "../useQueryAssets";
import { updateCachedWalletState } from "./updateCachedWalletState";

export const addWallet = async (
  accountId: string,
  wallet: AddWalletRequestBody
): Promise<Wallet> => {
  try {
    return await addWalletRequest(accountId, wallet);
  } finally {
    queryClient.invalidateQueries([...getWalletsQueryCacheKey(accountId)]);
  }
};

export const updateWallet = async (
  accountId: string,
  walletId: string,
  newProps: UpdateWalletBody
): Promise<Wallet> => {
  try {
    return await updateWalletRequest(accountId, walletId, newProps);
  } finally {
    // wallet props such as customName are updated in transaction data as well
    queryClient.invalidateQueries([
      ...getAccountTransactionsCacheKey(accountId),
    ]);
  }
};

export const deleteWallet = async (
  accountId: string,
  walletId: string
): Promise<void> => {
  try {
    const result = await deleteWalletRequest(accountId, walletId);
    checkStatusAndDoPendingWork();
    return result;
  } finally {
    queryClient.invalidateQueries([
      ...getAccountTransactionsCacheKey(accountId),
    ]);
  }
};

export const refreshWallets = (accountId: string) => {
  queryClient.invalidateQueries([...getWalletsQueryCacheKey(accountId)]);
};

export const importWalletCsv = async (
  accountId: string,
  walletId: string,
  files: File[]
) => {
  try {
    updateCachedWalletState(accountId, [walletId], {
      fileImportState: FileImportState.InProgress,
    });
    await importWalletCsvRequest(accountId, walletId, files);
    checkStatusAndDoPendingWork();
  } finally {
    queryClient.invalidateQueries([
      ...getAccountTransactionsCacheKey(accountId),
    ]);
    queryClient.invalidateQueries(getAllAssetsCacheKey());
  }
};

export const importDataSources = async (params: {
  accountId: string;
  dataSourceId: string;
  files: File[];
}) => {
  try {
    await importDataSourcesRequest(params);
    checkStatusAndDoPendingWork();
  } finally {
    queryClient.invalidateQueries([
      ...getAccountTransactionsCacheKey(params.accountId),
    ]);
  }
};

export const useGetWallets = <TSelectData = GetWalletsResponse>(
  accountId: string,
  params: GetWalletsRequestQuery = {},
  {
    select,
    enabled,
  }: {
    select?: (data: GetWalletsResponse) => TSelectData;
    enabled?: boolean;
  } = {}
) =>
  useQuery<GetWalletsResponse, unknown, TSelectData>(
    [...getWalletsQueryCacheKey(accountId, params)],
    () => getWallets(accountId, params),
    {
      select,
      enabled,
    }
  );

export const getWalletsQueryCacheKey = (
  accountId: string | undefined,
  params: GetWalletsRequestQuery = {}
) => [...getAccountTransactionsCacheKey(accountId), "wallets", params];
