/* eslint-disable react/jsx-filename-extension */

import {
  Button,
  useTheme,
  ButtonProps,
  Text,
  WithCSSVar,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { HiOutlinePencil, HiArrowRight } from "react-icons/hi";
import {
  MdShare,
  MdAdd,
  MdLoop,
  MdLockOutline,
  MdTune,
  MdOutlineDeleteOutline,
  MdCheckCircle,
} from "react-icons/md";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { ReactComponent as SylaBolt } from "../../images/icons/sylaBolt.svg";

// omit removes the specified fields from the ButtonProps type
type ChakraButton = Omit<
  ButtonProps,
  "leftIcon" | "rightIcon" | "color" | "textTransform"
>;

export interface ButtonVariantProps extends ChakraButton {
  content: string;
  outlineType?:
    | "outlineRed"
    | "outlineGray"
    | "outlineLightGray"
    | "outlineBlack"
    | "solid"
    | "ghost";
  color?:
    | "red"
    | "darkRed"
    | "selectedRed"
    | "white"
    | "gray"
    | "lightGray"
    | "darkGray"
    | "default"
    | "green";
  leftIcon?:
    | "share"
    | "add"
    | "sync"
    | "lock"
    | "google"
    | "null"
    | "trash"
    | "external"
    | "bolt";
  rightIcon?: "edit" | "next" | "filter" | "check" | "external" | "null";
  spam?: "auto" | "spam" | "share";
  ref?: any;
  textTransform?: "capitalize" | "uppercase" | "none";
  cursor?: ButtonProps["cursor"];
}

const colors = (theme: WithCSSVar<any>) => ({
  red: css`
    color: #fff;
    background-color: #ff3600;
    border-color: #ff3600;
    &:hover {
      background-color: #e03100;
    }
  `,
  darkRed: css`
    color: #fff;
    background-color: #df3030;
    &:hover {
      background-color: #cc2929;
    }
  `,
  selectedRed: css`
    background-color: #fceded;
    color: #ff3600;
    &:hover {
      background-color: #ffaf99;
    }
  `,
  gray: css`
    color: #333;
    border-color: #4d4d4d;
    background-color: #e5e5e5;
  `,
  lightGray: css`
    color: #1a1a1a;
    background-color: #f5f5f5;
    &:hover {
      background-color: #e5e5e5;
    }
  `,
  darkGray: css`
    color: #000;
    background-color: #e5e5e5;
    &:hover {
      background-color: #fceded;
    }
  `,
  white: css`
    color: #808080;
    background-color: #fbfbfb;
  `,
  blue: css`
    color: #089170;
    background-color: #ebfdfd;
    &:hover {
      color: #ebfdfd;
      background-color: #089170;
    }
  `,
  green: css`
    color: #089170;
    background-color: #ebfdfd;
    &:hover {
      color: #ebfdfd;
      background-color: #089170;
    }
  `,
  default: css``,
});

const btnType = {
  solid: css`
    border: none;
  `,
  outlineRed: css`
    border: 2px #ff3600 solid;
    color: #ff3600;
    background-color: #fff;
    &:hover {
      background-color: #ff3600;
      color: #fff;
    }
  `,
  outlineBlack: css`
    border: 2px #e5e5e5 solid;
    color: #4d4d4d;
    background-color: #fff;
  `,
  outlineGray: css`
    border: 2px #e5e5e5 solid;
    color: #808080;
    background-color: #fff;
  `,
  outlineLightGray: css`
    border: 1px #f5f5f5 solid;
    color: #808080;
    background-color: #fff;
  `,
  ghost: css`
    border: none;
    color: #ff3600;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
  `,
};

const width = {
  auto: css`
    width: auto;
  `,
  spam: css`
    width: 100%;
  `,
  share: css`
    width: 49%;
  `,
};

const returnIcon = (iconType: string) => {
  switch (iconType) {
    case "share":
      return <MdShare />;
    case "add":
      return <MdAdd />;
    case "sync":
      return <MdLoop />;
    case "next":
      return <HiArrowRight />;
    case "edit":
      return <HiOutlinePencil />;
    case "lock":
      return <MdLockOutline />;
    case "google":
      return <FcGoogle />;
    case "filter":
      return <MdTune />;
    case "trash":
      return <MdOutlineDeleteOutline />;
    case "external":
      return <ExternalLinkIcon />;
    case "check":
      return <MdCheckCircle />;
    case "bolt":
      return <SylaBolt />;
    default:
      return undefined;
  }
};

export const ButtonVariant = ({
  content,
  outlineType = "solid",
  onClick,
  leftIcon = "null",
  rightIcon = "null",
  color = "default",
  spam = "auto",
  ref = undefined,
  textTransform = "capitalize",
  cursor = "pointer",
  disabled,
  ...props
}: ButtonVariantProps) => {
  const theme = useTheme();

  const selectedOutlineType = outlineType;

  return (
    <Button
      h="40px"
      px="20px"
      cursor={disabled ? "not-allowed" : cursor}
      onClick={onClick}
      isDisabled={disabled}
      leftIcon={returnIcon(leftIcon)}
      rightIcon={returnIcon(rightIcon)}
      ref={ref}
      textTransform={textTransform}
      _hover={{
        bgColor: "black.200",
      }}
      _active={{
        bgColor: "black.200",
      }}
      css={[colors(theme)[color], btnType[selectedOutlineType], width[spam]]}
      whiteSpace={"normal"}
      {...props}
    >
      <Text fontSize="0.875rem" fontWeight="600">
        {content}
      </Text>
    </Button>
  );
};
