import { DeleteIcon } from "@chakra-ui/icons";
import {
  GridItem,
  IconButton,
  ButtonProps,
  Tooltip,
  TextProps,
  Text,
} from "@chakra-ui/react";
import {
  OperationBase,
  SwitchLedgerOperation,
  SwitchAssetOperation,
} from "@syla/shared/types/requests/BulkEditRequest";
import { produce } from "immer";
import React from "react";
import { ImReply } from "react-icons/im";
import { SetOptional } from "type-fest";

export const operationDefs: Record<
  Operation["type"],
  {
    label: string;
    maxAmount?: number;
  }
> = {
  ledger: {
    label: "Switch Ledger Type",
  },
  asset: {
    label: "Switch Asset",
  },
  wallet: {
    label: "Switch Data Source",
  },
  time: {
    label: "Adjust Times",
    maxAmount: 1,
  },
};

export const warningBorderColor = "orange";
export const borderColor = undefined;

export type SwitchLedgerOperationEntry = SetOptional<
  SwitchLedgerOperation,
  "from" | "to"
>;
export type SwitchAssetOperationEntry = SetOptional<
  SwitchAssetOperation,
  "from" | "to"
>;
export type ChangeWalletOperationEntry = SetOptional<
  ChangeWalletOperation,
  "from" | "to"
>;
export type AdjustTimeOperationEntry = SetOptional<
  AdjustTimeOperation,
  "direction" | "hours" | "minutes" | "seconds"
>;

export type OperationEntry =
  | SwitchLedgerOperationEntry
  | SwitchAssetOperationEntry
  | ChangeWalletOperationEntry
  | AdjustTimeOperationEntry;

export interface ChangeWalletOperation extends OperationBase {
  type: "wallet";
  from: string;
  to: string;
}

export interface AdjustTimeOperation extends OperationBase {
  type: "time";
  direction: "forward" | "back";
  hours: number;
  minutes: number;
  seconds: number;
}

export type Operation =
  | SwitchLedgerOperation
  | SwitchAssetOperation
  | ChangeWalletOperation
  | AdjustTimeOperation;

const DeleteGridItem = (props) => <GridItem justifySelf="center" {...props} />;

export const ArrowGridItem = (props) => (
  <GridItem
    verticalAlign="center"
    justifySelf="center"
    w="fit-content"
    {...props}
  />
);
export const DeleteButtonGridItem = <T extends OperationBase>({
  operation,
  operations,
  setOperations,
  ...buttonProps
}: {
  operation: T;
  operations: T[];
  setOperations: React.Dispatch<React.SetStateAction<T[]>>;
} & ButtonProps) => (
  <DeleteGridItem>
    <Tooltip label="Delete">
      <IconButton
        color="red"
        variant="ghost"
        aria-label={"Delete"}
        icon={<DeleteIcon />}
        isDisabled={operations.length < 2}
        onClick={() => {
          setOperations((operations) =>
            operations.filter((op) => op.id != operation.id)
          );
        }}
        {...buttonProps}
      />
    </Tooltip>
  </DeleteGridItem>
);

export function ApplyAllButton<T extends OperationBase & { to?: string }>({
  operation,
  setOperations,
  operations,
}: {
  operation: T;
  operations: T[];
  setOperations: React.Dispatch<React.SetStateAction<T[]>>;
}) {
  return (
    <Tooltip label="Apply to all">
      <IconButton
        isDisabled={!operation.to || operations.length == 1}
        icon={<ImReply />}
        aria-label={"Apply to all"}
        onClick={() => {
          const to = operation.to;
          setOperations((ops) =>
            ops.map((op) => ({
              ...op,
              to,
            }))
          );
        }}
      />
    </Tooltip>
  );
}

export const setOperation =
  <T extends OperationBase>(
    operation: T,
    setOperations: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (valueOrAction) => {
    setOperations(
      produce((operations) => {
        const opIndex = operations.findIndex((op) => op.id == operation.id);

        operations[opIndex] =
          typeof valueOrAction == "function"
            ? valueOrAction(operations[opIndex])
            : valueOrAction;
      })
    );
  };

export const OperationTitle = (props: TextProps) => (
  <Text fontWeight="bold" {...props} />
);
