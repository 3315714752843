import { TextProps, Text } from "@chakra-ui/react";
import {
  SyncState,
  WalletImportMethod,
} from "@syla/shared/types/models/WalletBase";
import { formatDistanceStrict } from "date-fns";
import React from "react";
import { DataSourceWalletResponse } from "./dashboardDataSourceTable/DataSourceTable";
import { getEstimatedSyncTime } from "./getEstimatedSyncTime";

export const LastSyncedTime = ({
  lastUpdated,
  walletResponse,
  ...props
}: {
  lastUpdated: Date | undefined;
  walletResponse: DataSourceWalletResponse;
} & TextProps) => {
  const syncText =
    walletResponse.importMethod == WalletImportMethod.API
      ? "synced"
      : "imported";

  const distanceText = lastUpdated
    ? `${syncText} successfully ${formatDistanceStrict(
        lastUpdated,
        new Date()
      )} ago`
    : `never ${syncText}`;

  const estSyncTime = getEstimatedSyncTime(walletResponse.dataSource);

  const lastSyncedText =
    walletResponse.syncState == SyncState.InProgress && estSyncTime
      ? `estimated ${estSyncTime}`
      : distanceText;

  return (
    <Text color="black.500" {...props}>
      {lastSyncedText}
    </Text>
  );
};
