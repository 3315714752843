import { GetWalletsResponse, Wallet } from "../../types/wallet/wallet";
import { queryClient } from "../transactionsStore";
import { getWalletsQueryCacheKey } from "./wallet";

// Optimistically update getWallets state
export function updateCachedWalletState(
  accountId: string,
  walletIds: string[],
  props: Partial<Wallet>
) {
  {
    const getWalletsState = queryClient.getQueryData<GetWalletsResponse>([
      ...getWalletsQueryCacheKey(accountId),
    ]);

    if (getWalletsState) {
      const newState: GetWalletsResponse = {
        ...getWalletsState,
        wallets: getWalletsState.wallets.map((walletState) => ({
          ...walletState,
          ...(walletIds.includes(walletState._id) && {
            ...props,
          }),
        })),
      };
      queryClient.setQueryData(
        [...getWalletsQueryCacheKey(accountId)],
        newState
      );
    }
  }
}
