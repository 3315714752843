import { Flex } from "@chakra-ui/react";
import React, { Dispatch, SetStateAction } from "react";

import { DataSourceTypeFilter } from "../../../../types/dataSource/dataSource";
import { ButtonVariant } from "../../../atoms/ButtonVariant";

const FilterButton = ({
  content,
  onClick,
  selected,
}: {
  content: string;
  onClick: () => void;
  selected: string;
}) => {
  const formattedContent = `${content}${
    (content as DataSourceTypeFilter) !== "all" ? "s" : ""
  }`;

  if (content === selected) {
    return (
      <ButtonVariant
        content={formattedContent}
        color="selectedRed"
        outlineType="solid"
        onClick={onClick}
        mb="10px"
        mr="10px"
      />
    );
  }
  return (
    <ButtonVariant
      content={formattedContent}
      color="lightGray"
      outlineType="solid"
      onClick={onClick}
      mb="10px"
      mr="10px"
    />
  );
};

export const DataSourceFilterButtons = ({
  options,
  selected,
  setSelected,
}: {
  options: string[];
  selected: DataSourceTypeFilter;
  setSelected: Dispatch<SetStateAction<DataSourceTypeFilter>>;
}) => (
  <Flex my="10px" flexWrap="wrap">
    {options.map((option) => (
      <FilterButton
        key={option}
        content={option}
        onClick={() => setSelected(option as DataSourceTypeFilter)}
        selected={selected}
      />
    ))}
  </Flex>
);
