import {
  Flex,
  Spinner,
  Divider,
  VStack,
  GridItem,
  GridProps,
  Grid,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { isBrowser } from "react-device-detect";
import { PageContainer } from "../../components/atoms/Containers";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useQueryGetAccount } from "../../store/useQueryGetAccount";

import { useQuerySubscriptions } from "../../store/useQuerySubscriptions";
import { SectionContainer, SectionHeading } from "./AccountSettingsCommon";
import { BillingOwnerDetails } from "./BillingOwnerDetails";
import { SubscriptionStatus } from "./SubscriptionStatus";

export function AccountSubscriptionSettings() {
  const { data: subscriptionsResponse, isLoading: subscriptionsLoading } =
    useQuerySubscriptions();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: account, isLoading: accountLoading } =
    useQueryGetAccount(accountId);

  const [redirecting, setRedirecting] = useState<boolean>(false);

  if (
    subscriptionsLoading ||
    !subscriptionsResponse ||
    accountLoading ||
    !account
  )
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center" my="40px">
          <Spinner />
        </Flex>
      </PageContainer>
    );

  return (
    <SectionContainer>
      <SectionHeading>Subscription</SectionHeading>
      <VStack alignItems="flex-start" w="100%" fontSize="sm">
        <SubscriptionDetailsGrid>
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <SubscriptionStatus
            subscriptions={subscriptionsResponse.subscriptions ?? []}
            account={account}
            isRedirecting={redirecting}
            setIsRedirecting={setRedirecting}
          />
          <GridItem colSpan={2}>
            <Divider />
          </GridItem>
          <BillingOwnerDetails
            subscriptions={subscriptionsResponse.subscriptions ?? []}
            account={account}
            isRedirecting={redirecting}
            setIsRedirecting={setRedirecting}
          />
        </SubscriptionDetailsGrid>
      </VStack>
    </SectionContainer>
  );
}

export const SubscriptionDetailsGrid = (props: GridProps) => (
  <Grid w="100%" templateColumns="auto auto" gap={8} {...props} />
);
