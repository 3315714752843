import {
  Divider,
  Flex,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Box,
  Th,
  Td,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import { orderBy } from "lodash";
import React, { useMemo } from "react";
import { getTransactionIconAndText } from "../../../helper/transaction/getTransactionIconAndText";
import { useNavigator, Route } from "../../../routers/navigator";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { FinancialYearOption } from "../../../types/user/financialYearOption";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { ImageWithMissingSrc } from "../../atoms/ImageWithMissingSrc";
import { SkeletonRect } from "../../atoms/Skeletons";
import { useTaxReportSummaryQuery } from "./useTaxReportSummaryQuery";

export const TransactionSummary = ({
  selectedFinancialYear,
}: {
  selectedFinancialYear?: FinancialYearOption;
}): React.ReactElement => {
  const navigate = useNavigator();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const {
    data: taxReportSummary,
    isLoading,
    isStale,
  } = useTaxReportSummaryQuery(selectedFinancialYear);

  const transactionTypeCounts = useMemo(() => {
    return orderBy(
      (
        taxReportSummary ?? {
          // dummy rows while loading
          transactionTypeCounts: [
            {
              type: TransactionType.Transaction,
              numTransactions: 10000,
              toReview: 0,
            },
          ],
        }
      ).transactionTypeCounts,
      [
        // total transactions always first
        (t) => (t.type == TransactionType.Transaction ? 0 : 1),
        // then by numTransactions desc
        (t) => t.numTransactions,
      ],
      ["asc", "desc"]
    );
  }, [taxReportSummary]);

  const showLoading = isLoading || isStale || !selectedFinancialYear;

  return (
    <CardContentContainer>
      <ContentHeadingContainer>
        <TaxReportHeading>Transaction Summary</TaxReportHeading>
        <ButtonVariant
          content="View all"
          outlineType="outlineLightGray"
          onClick={() => navigate({ route: Route.Transactions, accountId })}
          fontWeight="normal"
          leftIcon="external"
        />
      </ContentHeadingContainer>
      <Divider mt="20px" />
      <Box overflowX={"auto"}>
        <Table>
          <Thead>
            <Tr>
              <THeaderCell>Type</THeaderCell>
              <THeaderCell textAlign="center">Transaction Count</THeaderCell>
              {/*<THeaderCell textAlign="center">*/}
              {/*  {sylaSuggestionsText}*/}
              {/*</THeaderCell>*/}
            </Tr>
          </Thead>
          <Tbody>
            {transactionTypeCounts.map((item) => (
              <Tr key={item.type} p="0">
                <TCell>
                  <Flex direction="row" alignItems="center">
                    <ImageWithMissingSrc
                      src={getTransactionIconAndText(item).icon}
                      alt=""
                      mr="20px"
                      h="24px"
                      isLoading={showLoading}
                    />
                    <SkeletonRect isLoaded={!showLoading}>
                      <Text fontSize="0.875rem" color="black.900">
                        {item.type == TransactionType.Transaction
                          ? "Total Transactions"
                          : `${getTransactionIconAndText(item).text}`}
                      </Text>
                    </SkeletonRect>
                  </Flex>
                </TCell>
                <TCell textAlign="center" fontSize="0.875rem" color="black.700">
                  <SkeletonRect isLoaded={!showLoading}>
                    {item.numTransactions}
                  </SkeletonRect>
                </TCell>
                {/*{showLoading ? (*/}
                {/*  <TCell textAlign="center">*/}
                {/*    <SkeletonRect>10</SkeletonRect>*/}
                {/*  </TCell>*/}
                {/*) : (*/}
                {/*  <TCell*/}
                {/*    color="red.500"*/}
                {/*    fontWeight="500"*/}
                {/*    textDecor="underline"*/}
                {/*    textAlign="center"*/}
                {/*    cursor="pointer"*/}
                {/*    onClick={() => {*/}
                {/*      const state: MainTransactionTableHistoryState = {*/}
                {/*        filter: {*/}
                {/*          // transactionType: [item.type],*/}
                {/*          ledgerType: [item.type],*/}
                {/*          status: [StatusOption.NeedsReview],*/}
                {/*          date: selectedFinancialYear && {*/}
                {/*            financialYear: new FinancialYear({*/}
                {/*              value: selectedFinancialYear.value,*/}
                {/*            }),*/}
                {/*          },*/}
                {/*        },*/}
                {/*      };*/}
                {/*      // navigate to the transactions screen*/}
                {/*      // pre-filter by the transaction type and needs review state*/}
                {/*      navigate({*/}
                {/*        route: Route.Transactions,*/}
                {/*        accountId,*/}
                {/*        state,*/}
                {/*      });*/}
                {/*    }}*/}
                {/*  >*/}
                {/*    <SkeletonRect isLoaded={!showLoading}>*/}
                {/*      {item.toReview}*/}
                {/*    </SkeletonRect>*/}
                {/*  </TCell>*/}
                {/*)}*/}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </CardContentContainer>
  );
};

const THeaderCell = styled(Th)`
  color: #666;
  font-size: 0.75rem;
  font-weight: 500;
`;

const TCell = styled(Td)`
  padding: 10px 20px;
`;
