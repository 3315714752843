import { Flex, Image, Input, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";

import { ModalContext } from "../../../contexts/ModalContext";
import {
  IconExtractorType,
  iconExtractor,
} from "../../../helper/iconExtractor";
import { ConfirmationDetails } from "../../../react-app-env";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { ModalContainer } from "../../atoms/Containers";
import { ModalHeading } from "../../atoms/Headings";
import { ModalText } from "../../atoms/Texts";

interface confirmationBoxProps {
  icon: IconExtractorType;
  heading: string;
  contents?: string[];
  RHSBtnContent: string;
  RHSBtnOnClick: () => void;
  RHSBtnLoading: boolean;
  confirmation?: ConfirmationDetails;
}
export const ConfirmationBox = ({
  icon,
  heading,
  contents,
  RHSBtnContent,
  RHSBtnOnClick,
  RHSBtnLoading,
  confirmation,
}: confirmationBoxProps) => {
  const { closeModal } = useContext(ModalContext);

  const { register, watch } = useForm<{ answer: string }>();

  return (
    <ModalContainer>
      <Image src={iconExtractor(icon)} alt={icon} w="60px" />
      <ModalHeading>{heading}</ModalHeading>
      <VStack w="100%">
        {contents &&
          contents.map((content) => (
            <ModalText key={content} w="70%">
              {content}
            </ModalText>
          ))}
        {confirmation && (
          <VStack w="100%">
            <ModalText fontWeight="bold">{confirmation.prompt}</ModalText>
            <Input {...register("answer")} />
          </VStack>
        )}
        <Flex w="100%" justifyContent="space-between" mt="30px">
          <ButtonVariant
            content="Cancel"
            disabled={RHSBtnLoading}
            outlineType="outlineBlack"
            spam="share"
            onClick={() => {
              closeModal();
            }}
          />
          <ButtonVariant
            content={RHSBtnContent}
            outlineType="solid"
            color={RHSBtnContent === "Delete" ? "darkRed" : "red"}
            spam="share"
            onClick={() => {
              RHSBtnOnClick();
            }}
            isLoading={RHSBtnLoading}
            isDisabled={confirmation && watch("answer") != confirmation.answer}
          />
        </Flex>
      </VStack>
    </ModalContainer>
  );
};
