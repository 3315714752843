import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  HStack,
  ButtonGroup,
  IconButton,
  Box,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useOutsideClick,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import React, { useState, useCallback, useRef } from "react";
import { ReactComponent as EditIcon } from "../../images/icons/editPencil.svg";

export type EditableFieldProps = {
  displayElement: JSX.Element;
  editElement: JSX.Element;
  isDirty: boolean | null | undefined;
  isLoading: boolean;
  onBeforeSave?: () => Promise<boolean>;
  onSave: () => Promise<void> | void;
  onCancel: () => void;
  onError: (error) => void;
  validate?: () => string | undefined;
  canEdit?: boolean;
  zIndex?: number;
};

/**
 * Wrapper component for switching between display and edit state for a given field.
 *
 */
export function EditableField({
  displayElement,
  editElement,
  isDirty,
  isLoading,
  onError,
  onCancel,
  onSave,
  onBeforeSave,
  validate,
  canEdit = true,
  zIndex,
}: EditableFieldProps) {
  const [editState, setEditState] = useState(false);
  const [validateError, setValidateError] = useState<string | undefined>();

  const onCancelHandler = useCallback(() => {
    if (!editState) return;

    setValidateError(undefined);
    onCancel();
    setEditState(false);
  }, [editState, onCancel]);

  const onSaveHandler = useCallback(async () => {
    setValidateError(undefined);
    // check beforeSave handler before proceding
    if (onBeforeSave && !(await onBeforeSave())) {
      return;
    }

    // validate
    if (validate && validate()) {
      setValidateError(validate());
      return;
    }

    setEditState(false);
    if (isDirty)
      try {
        await onSave();
      } catch (error) {
        onError(error);
      }
  }, [isDirty, onBeforeSave, onError, onSave, validate]);

  const EditButton = () => (
    <IconButton
      variant="ghost"
      size="sm"
      icon={<EditIcon width="1rem" />}
      onClick={() => {
        setEditState(true);
      }}
      aria-label={"Edit"}
      isLoading={isLoading}
    />
  );

  const SaveCancelButtons = () => {
    const styles = {
      variant: "solid",
      boxShadow: "md",
      backgroundColor: "white",
      _focus: {
        boxShadow: "md",
        border: "1px",
        borderColor: "gray.700",
      },
    };

    return (
      <ButtonGroup size="sm">
        <IconButton
          {...styles}
          icon={<CheckIcon />}
          onClick={onSaveHandler}
          aria-label="Save"
        />
        <IconButton
          {...styles}
          icon={<CloseIcon />}
          onClick={onCancelHandler}
          aria-label="Cancel"
        />
      </ButtonGroup>
    );
  };

  const EditComponent = () => {
    if (!editState || isLoading) {
      return (
        <HStack>
          {displayElement}
          {canEdit ? EditButton() : null}
        </HStack>
      );
    } else {
      return (
        <Tooltip label={validateError} hasArrow defaultIsOpen placement="top">
          <Box
            width="100%"
            tabIndex={0}
            onKeyUp={(e) => {
              setValidateError(undefined);
              if (e.key === "Escape") {
                onCancelHandler();
              } else if (e.key == "Enter") {
                onSaveHandler();
              }
            }}
          >
            {editElement}
          </Box>
        </Tooltip>
      );
    }
  };

  const containerRef = useRef<any>(null);

  // avoid setting up a click handler per element until it's open
  const PopoverContentWrapper = () => {
    useOutsideClick({ ref: containerRef, handler: onCancelHandler });

    return <SaveCancelButtons />;
  };

  return (
    <Box
      ref={containerRef} // used to detect outside clicks
      css={css`
        // hide buttons when field is out of view
        .chakra-popover__popper[data-popper-reference-hidden] * {
          visibility: hidden;
          pointer-events: none;
        }

        // override z-index if needed
        ${zIndex != null
          ? css`
              .chakra-popover__popper {
                z-index: ${zIndex};
              }
            `
          : ``}
      `}
    >
      <Popover
        placement="bottom-end"
        isOpen={editState}
        autoFocus={false}
        modifiers={[{ name: "hide" }]}
        isLazy // avoid creating click handler till needed
      >
        <PopoverTrigger>
          <Box position="relative">{EditComponent()}</Box>
        </PopoverTrigger>
        <PopoverContent
          width="auto"
          maxWidth="auto"
          border="none"
          boxShadow="none"
          borderRadius="0px"
          _focus={{
            boxShadow: "none",
          }}
        >
          <PopoverContentWrapper />
        </PopoverContent>
      </Popover>
    </Box>
  );
}
