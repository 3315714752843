import { Decimal } from "../../decimal";
import { LedgerBase } from "../models/LedgerBase";
import { TaxOutcomeBase } from "../models/TaxOutcomeBase";
import { parseOptional, parseDecimal, parseDate } from "./parsing";
import { TransportType } from "./TransportType";
import { WithModelIds } from "./WithModelIds";

type LedgerType = WithModelIds<LedgerBase<string>>;
export function parseLedger(ledger: TransportType<LedgerType>): LedgerType {
  return {
    ...ledger,
    amount: Decimal.from(ledger.amount),
    marketValue: parseOptional(ledger.marketValue, parseDecimal),
    customMarketValue: ledger.customMarketValue
      ? {
          ...ledger.customMarketValue,
          value: Decimal.from(ledger.customMarketValue.value),
        }
      : undefined,
    balances: ledger.balances
      ? {
          balance: Decimal.from(ledger.balances.balance),
          walletBalance: Decimal.from(ledger.balances.walletBalance),
        }
      : undefined,
    taxOutcomes: ledger.taxOutcomes?.map((taxOutcomes) =>
      parseTaxOutcomes(taxOutcomes)
    ),
  };
}

type TaxOutcomeType = WithModelIds<TaxOutcomeBase<string>>;

function parseTaxOutcomes(
  taxOutcome: TransportType<TaxOutcomeType>
): TaxOutcomeType {
  return {
    ...taxOutcome,
    gain: parseDecimal(taxOutcome.gain),

    acquisitionDate: parseOptional(taxOutcome.acquisitionDate, parseDate),
    amount: parseOptional(taxOutcome.amount, parseDecimal),
    cost: parseOptional(taxOutcome.cost, parseDecimal),
    proceeds: parseOptional(taxOutcome.proceeds, parseDecimal),
  };
}
