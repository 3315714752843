import { Text, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useReportError } from "../../../helpers/useReportError";
import { useMutationUpdateAccount } from "../../../store/useMutationUpdateAccount";
import {
  EditableFieldProps,
  EditableField,
} from "../../molecules/EditableField";

export function NicknameEditable({
  account: { _id: accountId },
  ...props
}: { account: { _id: string } } & Partial<EditableFieldProps>) {
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(accountId);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const reportError = useReportError();

  const { watch, register, formState, resetField } = useFormContext<{
    nickname: string;
  }>();
  return (
    <EditableField
      displayElement={<Text>{watch("nickname")}</Text>}
      editElement={
        <Input placeholder="Nickname" autoFocus {...register("nickname")} />
      }
      isDirty={formState.dirtyFields.nickname}
      isLoading={updateInProgress}
      onSave={async () => {
        try {
          setUpdateInProgress(true);
          await updateAccount({
            nickname: watch("nickname"),
          });
        } finally {
          setUpdateInProgress(false);
        }
      }}
      onCancel={() => resetField("nickname")}
      onError={reportError}
      {...props}
    />
  );
}
