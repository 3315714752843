import { DrawerBody, DrawerFooter, Flex } from "@chakra-ui/react";
import React from "react";
import { DataSource } from "../../../../types/dataSource/dataSource";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { DrawerHeading } from "../../../atoms/Headings";
import { SelectDataSource } from "../addDataSource/SelectDataSource";
import { useAddDataSourceContext } from "../AddDataSourceContext";

export const SelectDataSourceBody = ({
  onClose,
  onClickDataSource,
}: {
  onClose: () => void;
  onClickDataSource: (source: DataSource) => void;
}) => {
  const {
    state: { dataSourcesSearchQuery },
    actions: { setDataSourcesSearchQuery },
  } = useAddDataSourceContext();

  return (
    <>
      <DrawerHeading>Add Data Source</DrawerHeading>
      <DrawerBody>
        <SelectDataSource
          dataSourcesSearchQuery={dataSourcesSearchQuery}
          dataSourceSearchQueryChanged={setDataSourcesSearchQuery}
          onClickDataSource={onClickDataSource}
        />
      </DrawerBody>
      <DrawerFooter>
        <Flex direction="column" w="100%">
          <ButtonVariant
            content="Close"
            spam="spam"
            outlineType="outlineGray"
            onClick={onClose}
          />
        </Flex>
      </DrawerFooter>
    </>
  );
};
