import { Flex, PopoverBody, FlexProps, Spinner } from "@chakra-ui/react";

import { groupBy } from "lodash";
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  RefObject,
  forwardRef,
} from "react";
import { useFuseSearch } from "../../../hooks/useFuseSearch";
import { SelectorProps, KeyedOption } from "./Selector";
import { SelectorSearch } from "./SelectorSearch";

export function SelectorBody<TOption, TKey extends React.Key>({
  options,
  selectedOptions,
  onChangeSelection,
  renderOptionContent,
  searchSettings,
  multiSelect,
  allowDeselect,
  initialFocusRef,
  setFetchOptions,
  onClose,
  isLoading,
  autoFocus = true,
  maxOptionsToDisplay,
}: Pick<
  SelectorProps<TOption, TKey>,
  | "options"
  | "selectedOptions"
  | "onChangeSelection"
  | "renderOptionContent"
  | "searchSettings"
  | "multiSelect"
  | "allowDeselect"
  | "isLoading"
  | "setFetchOptions"
  | "autoFocus"
  | "maxOptionsToDisplay"
> & {
  initialFocusRef: React.MutableRefObject<null>;
  onClose: () => void;
}) {
  const [searchValue, setSearchValue] = useState<string>("");

  let searchFilteredOptions = useFuseSearch(
    options ?? [],
    (searchSettings?.searchKeys ?? []).map(
      (searchKey) => `value.${searchKey}`
    ) as any,
    searchValue
  );

  if (maxOptionsToDisplay) {
    searchFilteredOptions = searchFilteredOptions.slice(0, maxOptionsToDisplay);
  }

  const optionsByKey = useMemo(
    () => groupBy<KeyedOption<TOption, TKey>>(options, (o) => o.key),
    [options]
  );

  const handleSelectToggle = useCallback(
    (option: KeyedOption<TOption, TKey>) => {
      const selected = selectedOptions.map((s) => optionsByKey[s.key][0]);
      // not already selected
      if (!selectedOptions.map((o) => o.key).includes(option.key)) {
        onChangeSelection(multiSelect ? [...selected, option] : [option]);
      }
      // already selected
      else {
        if (allowDeselect)
          onChangeSelection(selected.filter((o) => o.key != option.key));
      }
      if (!multiSelect) onClose();
    },
    [
      allowDeselect,
      multiSelect,
      onChangeSelection,
      onClose,
      optionsByKey,
      selectedOptions,
    ]
  );

  const itemRefs = useMemo(
    () =>
      [initialFocusRef as RefObject<HTMLDivElement>].concat(
        Array.from({
          length: searchFilteredOptions.length - 1 + (searchSettings ? 1 : 0),
        }).map(() => React.createRef<HTMLDivElement>())
      ),
    [initialFocusRef, searchFilteredOptions, searchSettings]
  );

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    autoFocus ? 0 : undefined
  );

  // set focus
  useEffect(() => {
    // console.debug("Set focus to ", selectedIndex);
    selectedIndex != null && itemRefs[selectedIndex].current?.focus();
  }, [itemRefs, selectedIndex]);

  const handleKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case "ArrowUp":
          setSelectedIndex((prevIndex) =>
            prevIndex == null ? 0 : Math.max(prevIndex - 1, 0)
          );
          break;
        case "ArrowDown":
          setSelectedIndex((prevIndex) =>
            Math.min(prevIndex == null ? 0 : prevIndex + 1, itemRefs.length - 1)
          );
          break;
        default:
          break;
      }
    },
    [itemRefs.length]
  );

  useEffect(() => {
    setFetchOptions && setFetchOptions(true);
  }, [setFetchOptions]);

  return (
    <PopoverBody backgroundColor="white" p="0" onKeyDown={handleKeyDown}>
      {searchSettings && (
        <SelectorSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          focusRef={itemRefs[0]}
          autoFocus={false}
        />
      )}
      {isLoading && (
        <OptionBox justifyContent="center">
          <Spinner />
        </OptionBox>
      )}
      {!isLoading &&
        searchFilteredOptions.map((option, index) => {
          const focusIndex = index + (searchSettings ? 1 : 0);

          return (
            <OptionBox
              key={option.key}
              onClick={() => {
                setSelectedIndex(undefined);
                handleSelectToggle(option);
              }}
              onKeyDown={(e) => {
                if (["Enter", " "].includes(e.key)) {
                  e.preventDefault();
                  handleSelectToggle(option);
                }
              }}
              ref={itemRefs[focusIndex]}
              tabIndex={0}
            >
              {renderOptionContent({
                option: option.value,
                isSelected: selectedOptions
                  .map((o) => o.key)
                  .includes(option.key),
              })}
            </OptionBox>
          );
        })}
      {!isLoading && !searchFilteredOptions.length && (
        <OptionBox justifyContent="center">No matching items</OptionBox>
      )}
    </PopoverBody>
  );
}

const OptionBox = forwardRef(
  (props: FlexProps, ref: React.ForwardedRef<any>) => (
    <Flex
      userSelect="none"
      cursor="pointer"
      padding="10px"
      borderWidth="1px"
      borderColor="#fff #fff #f5f5f5"
      justifyContent="space-between"
      fontSize="0.875rem"
      _hover={{
        backgroundColor: "#f5f5f5",
        borderColor: "#f5f5f5",
      }}
      _focus={{
        backgroundColor: "#f5f5f5",
        borderColor: "#f5f5f5",
      }}
      ref={ref}
      {...props}
    />
  )
);
