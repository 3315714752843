import { sortBy } from "lodash";

import {
  DataSource,
  DataSourceTypeFilter,
} from "../types/dataSource/dataSource";

export const useFilterDataSources = (
  data: DataSource[],
  filter: DataSourceTypeFilter
) => {
  const orderedData = sortBy(data, "name");

  if (filter === "all") return orderedData;
  return orderedData.filter((source) => source.type === filter);
};
