import { uniqueId } from "lodash";
import React, { useEffect } from "react";
import { Input, HStack, Text, InputProps } from "@chakra-ui/react";
import { StdVStack } from "../../../../../atoms/Containers";
import { ItemSelector } from "../../../../../molecules/ItemSelector";
import {
  AdjustTimeOperationEntry,
  operationDefs,
  OperationTitle,
} from "../common";

export function TimeBulkEdit({
  operations,
  setOperations,
}: {
  operations: AdjustTimeOperationEntry[];
  setOperations: React.Dispatch<
    React.SetStateAction<AdjustTimeOperationEntry[]>
  >;
}): JSX.Element {
  // initial value
  useEffect(() => {
    setOperations([{ id: uniqueId(), type: "time" }]);
  }, [setOperations]);

  return (
    <StdVStack gap={4}>
      <OperationTitle>{operationDefs["time"].label}</OperationTitle>
      {/* Direction */}
      {operations.map((operation) => (
        <HStack key={operation.id} w="100%" wrap="wrap" gridGap={2}>
          <ItemSelector
            currentValue={operation.direction ?? "forward"}
            onValueSelected={(value) =>
              setOperations(([op]) => [
                {
                  ...op,
                  direction: value,
                },
              ])
            }
            allValues={["forward", "back"]}
            labels={{
              forward: "Forward",
              back: "Back",
            }}
            buttonProps={{ width: "10rem" }}
            contentProps={{ width: "10rem", minWidth: "10rem" }}
          />
          <HStack>
            <TimeInput
              value={operation.hours}
              onChange={(event) =>
                setOperations(([op]) => [
                  {
                    ...op,
                    hours: Number(event.target.value),
                  },
                ])
              }
            />
            <Text>Hours</Text>
          </HStack>
          <HStack>
            <TimeInput
              value={operation.minutes}
              onChange={(event) =>
                setOperations(([op]) => [
                  {
                    ...op,
                    minutes: Number(event.target.value),
                  },
                ])
              }
            />
            <Text>Mins</Text>
          </HStack>
          <HStack>
            <TimeInput
              value={operation.seconds}
              onChange={(event) =>
                setOperations(([op]) => [
                  {
                    ...op,
                    seconds: Number(event.target.value),
                  },
                ])
              }
            />
            <Text>Seconds</Text>
          </HStack>
        </HStack>
      ))}
    </StdVStack>
  );
}

const TimeInput = (props: InputProps) => (
  <Input
    type="number"
    // borderColor="#f5f5f5"
    w="5rem"
    h="50px"
    _focus={{ boxShadow: "none", borderColor: "#000" }}
    {...props}
  />
);

export function validateTimeOperation(op: AdjustTimeOperationEntry) {
  return op.hours || op.minutes || op.seconds;
}
