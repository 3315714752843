import axios from "axios";

export const downloadHoldingStatementCsv = async ({
  accountId,
  byAsset,
  marketValue,
  balanceDate,
}: {
  accountId: string;
  byAsset: boolean;
  marketValue: boolean;
  balanceDate?: Date;
}): Promise<void> => {
  const { data, headers } = await axios.get(
    `/accounts/${accountId}/download-holding-statements-csv`,
    {
      params: { byAsset, marketValue, balanceDate },
      responseType: "blob",
    }
  );

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(data);
  link.setAttribute(
    "download",
    headers["content-disposition"]?.match(/filename="(.*)"/)?.at(1) ??
      `Syla Holding Statement.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};
