import axios from "axios";

export const importWalletCsv = async (
  accountId: string,
  walletId: string,
  files: File[]
) => {
  const formData = new FormData();
  files.forEach((file) => formData.append("files", file));

  await axios.post(`/accounts/${accountId}/wallets/${walletId}/csv`, formData);
};
