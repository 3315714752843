import { useMutation } from "react-query";
import { updateUser } from "../api/user/updateUser";
import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";
import { USER_DETAILS_QUERY_KEY } from "./useQueryGetUser";

export const useMutationUpdateUser = () =>
  useMutation(ACCOUNT_META_QUERY_KEY, updateUser, {
    onSettled: () => queryClient.invalidateQueries([...USER_DETAILS_QUERY_KEY]),
  });
