import axios from "axios";

export const getAccountTransactionCount = async (
  accountId: string,
  financialYear?: string
): Promise<number | undefined> =>
  (
    await axios.get(`/accounts/${accountId}/transactions/count`, {
      params: { year: financialYear },
    })
  ).data.count;
