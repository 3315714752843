"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = void 0;
var AssetType;
(function (AssetType) {
    AssetType["Currency"] = "currency";
    AssetType["Custom"] = "custom";
    AssetType["Token"] = "token";
    AssetType["NFT"] = "nft";
})(AssetType = exports.AssetType || (exports.AssetType = {}));
