/**
 * This file could probably be split up, but I don't know where to put the sub components
 */
import {
  Flex,
  Text,
  Heading,
  Box,
  Stack,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { createContext, useContext, useMemo } from "react";
import { FaCheckCircle } from "react-icons/fa";

import { ButtonVariant } from "../atoms/ButtonVariant";
import { TooltipWrapper } from "../atoms/TooltipWrapper";

export type Feature = {
  text: string;
  subText?: string;
  included: boolean | undefined;
};

interface PlanCardProps {
  heading: string;
  description: string;
  price: number;
  featureList: Feature[];
  action?: () => void;
  headerText?: string;
  status?:
    | "normal"
    | "recommended"
    | "purchased"
    | "cancelled"
    | "expired"
    | "actionRequired";
  purchased?: boolean;
  purchasedPrice?: number;
  purchasable?: boolean;
  notPurchasableReason?: string;
  upgrading?: boolean;
  previousPrice?: number;
  isBusy?: boolean;
  canManage?: boolean;
}

// context used to share props data between all the below sub-components
const PlanCardContext = createContext<PlanCardProps>(undefined!);

const HEADER_SIZE = 12;
/**
 * Heading for the PlanCard component
 */
const CardHeader = () => {
  const { headerText, status } = useContext(PlanCardContext);

  const purchased = status == "purchased";
  const recommended = status == "recommended";
  const cancelled = status == "cancelled";
  const expired = status == "expired";
  const actionRequired = status == "actionRequired";

  // work out what color to use in the header background
  const headerBackgroundColor = useMemo(() => {
    if (expired || actionRequired) return "orange.400";
    if (cancelled) return "yellow.400";
    if (purchased) return "green.400";
    if (recommended) return "red.500";
    else return "";
  }, [expired, actionRequired, cancelled, purchased, recommended]);

  const color = !(recommended || purchased) ? "black.700" : "white.0";

  const show = headerText;

  return (
    <Flex
      bgColor={headerBackgroundColor}
      justifyContent="center"
      alignItems="center"
      h={show ? HEADER_SIZE : 0}
      mb="3"
    >
      {headerText && (
        <Heading
          css={css`
            font-size: 1.5rem !important;
            text-align: center;
            font-family: GintoNormal;
            margin: auto;
          `}
          color={color}
        >
          {headerText}
        </Heading>
      )}
    </Flex>
  );
};

/**
 * Container for the PlanCard components
 */
const CardContainer = ({ children }: { children: React.ReactNode }) => (
  <Flex
    direction="column"
    m="10px 20px"
    w="240px"
    minH="350px"
    bgColor="white.0"
    borderWidth="1px"
    borderColor="white.500"
    borderRadius="10px"
    overflow="clip"
    justifyContent="space-between"
  >
    {children}
  </Flex>
);

/**
 * Price component for the PlanCard component
 */
const CardPrice = () => {
  const { price } = useContext(PlanCardContext);

  // work out what price to show as the main price
  const displayPrice = useMemo(() => {
    if (price <= 0) return "Free";

    return price / 100;
  }, [price]);

  return (
    <VStack spacing={1}>
      <Flex mx="auto" alignContent="center">
        <Text fontSize="1.25rem" color="black.800" fontWeight="bold">
          {price === 0 ? undefined : "A$"}{" "}
        </Text>
        <Text
          fontSize={price === 0 ? "2.7rem" : "3.5rem"}
          fontWeight="bold"
          color="black.800"
          lineHeight="100%"
        >
          {displayPrice}
        </Text>
      </Flex>
      {price !== 0 && (
        <>
          <Text color="black.800" textAlign="center">
            annual subscription
          </Text>
          <Tooltip label="*when incurred as an expense related to managing your own tax affairs">
            <Text
              as="u"
              color="black.550"
              fontSize="0.75rem"
              textAlign="center"
              mt="10px"
            >
              tax-deductible*
            </Text>
          </Tooltip>
        </>
      )}
    </VStack>
  );
};

/**
 * Feature list component for the PlanCard component
 */
const CardFeatureList = () => {
  const { featureList, purchased } = useContext(PlanCardContext);

  return (
    <Flex direction="column" mt="5px">
      {featureList.map(({ text, included }) => (
        <RowContainer key={text}>
          <Box flexShrink={0}>
            <FaCheckCircle
              color={
                included
                  ? purchased
                    ? "var(--chakra-colors-green-400)"
                    : "var(--chakra-colors-red-500)"
                  : "#DADADA"
              }
              size="15px"
            />
          </Box>
          <IncludeListText>{text}</IncludeListText>
        </RowContainer>
      ))}
    </Flex>
  );
};

const IncludeListText = styled(Text)`
  text-align: left;
  color: #4d4d4d;
  font-size: 0.75rem;
  margin-left: 10px;
`;

const RowContainer = styled(Flex)`
  flex-direction: row;
  margin: 3px auto;
  width: 180px;
`;

/**
 * Button section for the PlanCard component
 */
const CardButton = () => {
  const {
    isBusy,
    purchasable,
    notPurchasableReason,
    purchasedPrice,
    price,
    upgrading,
    status,
    action,
    canManage,
  } = useContext(PlanCardContext);

  const expired = status == "expired";
  const recommended = status == "recommended";
  const purchased = status == "purchased";
  const cancelled = status == "cancelled";
  const actionRequired = status == "actionRequired";

  let buttonText: string;
  if (cancelled || expired) {
    buttonText = "Renew";
  } else if (purchased || actionRequired) {
    buttonText = "Manage";
  } else if (!purchasable) {
    buttonText = "N/A";
  } else if (upgrading) {
    buttonText = price < (purchasedPrice ?? 0) ? "Downgrade" : "Upgrade";
  } else {
    buttonText = "Get Started";
  }

  // display a purchase or upgrade button
  const button = (
    <TooltipWrapper
      width="fit-content"
      m="20px"
      tooltip={!purchased && !purchasable ? notPurchasableReason : undefined}
    >
      <ButtonVariant
        content={buttonText}
        outlineType={
          purchased ? "outlineBlack" : recommended ? "solid" : "outlineRed"
        }
        color={purchased ? "green" : "red"}
        onClick={action}
        spam="auto"
        disabled={(!purchased && !purchasable) || isBusy || !canManage}
      />
    </TooltipWrapper>
  );

  // set a min height so that there is empty space even when a button doesn't render
  return (
    <Stack minHeight="80px" alignItems="center">
      {button}
    </Stack>
  );
};

const CardTitle = () => {
  const { heading } = useContext(PlanCardContext);

  return (
    <Heading
      m="5px"
      mt="20px"
      css={css`
        font-size: 1.5rem !important;
        text-align: center;
        font-family: GintoNormal;
      `}
    >
      {heading}
    </Heading>
  );
};

const CardDescription = () => {
  const { description } = useContext(PlanCardContext);

  return (
    <Box m="10px" height="4rem">
      <Text textAlign="center">{description}</Text>
    </Box>
  );
};

export const PlanCard = (props: PlanCardProps) => {
  return (
    <PlanCardContext.Provider value={props}>
      <Box mt={!props.headerText ? HEADER_SIZE : 0}>
        <CardContainer>
          <CardHeader />
          <CardPrice />
          <CardTitle />
          <CardDescription />
          <CardFeatureList />
          <CardButton />
        </CardContainer>
      </Box>
    </PlanCardContext.Provider>
  );
};
