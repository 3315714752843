import { Image, ImageProps, Box } from "@chakra-ui/react";
import React from "react";
import missingImageIcon from "../../images/thumbnails/missingImageIcon.svg";
import noPaddingMissingImageIcon from "../../images/thumbnails/noPaddingMissingImageIcon.svg";
import { SkeletonCircle } from "./Skeletons";

export const ImageWithMissingSrc = ({
  src,
  isLoading,
  noPaddingIcon = false,
  ...props
}: ImageProps & {
  isLoading?: boolean;
  noPaddingIcon?: boolean;
}): JSX.Element => {
  if (isLoading)
    return (
      <Box {...props}>
        <SkeletonCircle />
      </Box>
    );

  if (!src) {
    return (
      <Image
        {...props}
        src={noPaddingIcon ? noPaddingMissingImageIcon : missingImageIcon}
      />
    );
  }

  return <Image {...props} src={src} />;
};
