import {
  ButtonProps,
  HStack,
  Icon,
  Spinner,
  PopoverContent,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContentProps,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import { Path } from "react-hook-form";
import { FaChevronDown } from "react-icons/fa";
import { SelectBtn } from "../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleSelectBtn";
import { SelectorBody } from "./SelectorBody";

export type KeyedOption<TOption, TKey extends React.Key> = {
  key: TKey;
  value: TOption;
};

export type SelectorProps<TOption, TKey extends React.Key> = {
  renderOptionContent: (props: {
    option: TOption;
    isSelected: boolean;
  }) => React.ReactNode;
  SelectorContent: React.ReactNode;
  options: KeyedOption<TOption, TKey>[] | undefined;
  selectedOptions: { key: TKey }[];
  onChangeSelection: (options: KeyedOption<TOption, TKey>[]) => void;
  onClose?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  isBusy?: boolean;
  searchSettings?: { searchKeys: Path<TOption>[] };
  multiSelect?: boolean;
  allowDeselect?: boolean;
  buttonProps?: ButtonProps;
  contentProps?: PopoverContentProps;
  expandIcon?: React.ReactNode;
  setFetchOptions?: (enabled: boolean) => void;
  selectedIndicator?: boolean;
  autoFocus?: boolean;
  /* Total max options to show without searching. Useful for a large list of options that is slow to render */
  maxOptionsToDisplay?: number;
  /* Automatically select the first item if only one option */
  autoSelectOnly?: boolean;
};

export function Selector<TOption, TKey extends React.Key>({
  SelectorContent,
  renderOptionContent,
  options,
  selectedOptions,
  onChangeSelection,
  onClose: parentOnClose,
  isDisabled,
  isBusy,
  isLoading,
  multiSelect,
  allowDeselect = multiSelect,
  buttonProps,
  contentProps,
  expandIcon,
  searchSettings,
  setFetchOptions,
  selectedIndicator,
  maxOptionsToDisplay,
  autoFocus = isDesktop,
  autoSelectOnly,
}: SelectorProps<TOption, TKey>) {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const initialFocusRef = React.useRef(null);

  useEffect(() => {
    if (autoSelectOnly && options?.length == 1 && selectedOptions.length == 0) {
      onChangeSelection([options[0]]);
    }
  }, [autoSelectOnly, onChangeSelection, options, selectedOptions.length]);

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={() => {
        // console.debug("onOpen");
        // setSearchValue("");
      }}
      onClose={() => {
        // console.debug("onClose");

        onClose();
        parentOnClose && parentOnClose();
      }}
      autoFocus={autoFocus}
      initialFocusRef={(autoFocus && initialFocusRef) || undefined}
      closeOnBlur
      closeOnEsc
      isLazy
      // to prevent popover being trapped within modals that scroll
      strategy="fixed"
    >
      <PopoverTrigger>
        <SelectBtn
          onClick={(e) => {
            onToggle();
          }}
          tabIndex={0}
          isDisabled={isBusy || isDisabled}
          selectbtnprops={buttonProps}
          isOpen={isOpen}
          selectedIndication={selectedIndicator}
          selectedOption={selectedOptions.length}
        >
          <HStack w="100%" justify="space-between">
            {SelectorContent}
            {!isBusy ? (
              expandIcon ?? <Icon as={FaChevronDown} />
            ) : (
              <Icon as={Spinner} />
            )}
          </HStack>
        </SelectBtn>
      </PopoverTrigger>
      <PopoverContent
        overflowX="hidden"
        minWidth="2xs"
        width="max-content"
        maxH="50vh"
        {...contentProps}
      >
        <SelectorBody
          options={options}
          selectedOptions={selectedOptions}
          searchSettings={searchSettings}
          onChangeSelection={onChangeSelection}
          renderOptionContent={renderOptionContent}
          allowDeselect={allowDeselect}
          multiSelect={multiSelect}
          initialFocusRef={initialFocusRef}
          onClose={onClose}
          isLoading={isLoading}
          setFetchOptions={setFetchOptions}
          autoFocus={autoFocus}
          maxOptionsToDisplay={maxOptionsToDisplay}
        />
      </PopoverContent>
    </Popover>
  );
}
