import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Text, List, HStack, Link } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import { ImportAssistButton } from "../../atoms/ImportAssistButton";
import { ActionCard } from "../../molecules/ActionCard";
import { TickListItem } from "../../molecules/TickListItem";

export const AssistedFileImportTab = () => <AssistedFileImportCard />;

const AssistedFileImportCard = () => (
  <ActionCard
    heading={"Assisted File Import"}
    subheading={"This platform is eligible for an Assisted File Import."}
    leftIcon={<FaCheckCircle color="green" fontSize="25px" />}
    content={
      <>
        <Text>
          Once our team receives your transaction data, we’ll add customised
          support for your file.
        </Text>
        <List spacing={1}>
          <TickListItem>built specific to your transaction file</TickListItem>
          <TickListItem>any CSV file</TickListItem>
          <TickListItem>
            wait time may vary based on current demand
          </TickListItem>
        </List>
      </>
    }
    actions={
      <>
        <Link
          href="https://help.syla.com.au/en/articles/8328641-assisted-file-import"
          isExternal={true}
        >
          <HStack>
            <Text>Learn more</Text>
            <ExternalLinkIcon />
          </HStack>
        </Link>
        <ImportAssistButton flex={1} minW={"fit-content"} />
      </>
    }
  />
);
