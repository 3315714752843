import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";
import { OrderResponse } from "../../types/order/orderRespond";
import { Transaction } from "../../types/transaction";
import { getLedgerTypeIcon } from "./getLedgerType";
import { getTransactionTypeIcon } from "./getTransactionTypeOptions";

export const getOrderIconAndText = (
  orderResponse: OrderResponse
): { icon: string; text: string } => {
  // if more than one transaction in the order, return the order icon
  if (orderResponse.transactions.length > 1)
    return {
      icon: getTransactionTypeIcon("group"),
      text: "Group",
    };

  return getTransactionIconAndText(orderResponse.transactions[0]);
};

export const getTransactionIconAndText = (transaction: {
  type: Transaction["type"];
}): { icon: string; text: string } => {
  return {
    icon:
      transaction.type in TransactionType
        ? getTransactionTypeIcon(transaction.type as TransactionType)
        : getLedgerTypeIcon(transaction.type as LedgerType),
    text: transaction.type,
  };
};
