import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { CreateStripeBillingPortalRequestBody } from "@syla/shared/types/requests/CreateStripeBillingPortalRequest";
import { CreateStripeBillingPortalResponse } from "@syla/shared/types/responses/CreateStripeBillingPortalResponse";
import axios from "axios";

export type CreateStripeBillingPortalProps =
  CreateStripeBillingPortalRequestBody;

export const createStripeBillingPortalSession = async (
  accountId: string,
  props: CreateStripeBillingPortalProps
): Promise<TransportType<CreateStripeBillingPortalResponse>> => {
  const { data } = await axios.post(
    `/accounts/${accountId}/subscriptions/create-stripe-billing-portal-session`,
    props
  );
  return data;
};
