import { minutesToMilliseconds } from "date-fns";
import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { valueHoldings } from "../api/wallet/valueHoldings";
import { HoldingsValue } from "../helper/wallet/HoldingsValue";
import { getWalletsQueryCacheKey } from "./actions/wallet";
import { useCurrentAccountStore } from "./currentAccountStore";

export const useValueHoldings = (
  options?: Omit<UseQueryOptions<HoldingsValue>, "queryKey" | "queryFn">
) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  return useQuery<HoldingsValue>(
    [...getWalletsQueryCacheKey(accountId), "valuations"],
    async () => new HoldingsValue(await valueHoldings(accountId)),
    {
      staleTime: minutesToMilliseconds(15), // refresh valuations after
      ...options,
    }
  );
};
