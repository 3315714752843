/* eslint-disable react/display-name */
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  InputRightElement,
  Text,
  Button,
  InputProps,
  FormErrorMessage,
  VStack,
  HStack,
  Tag,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { REFERRAL_DISCOUNT } from "@syla/shared/types/helpers/constants";
import React, { forwardRef, ChangeEvent } from "react";
import { isDesktop } from "react-device-detect";
// eslint-disable-next-line import/named
import { FieldError } from "react-hook-form";
import { BiSearchAlt2 } from "react-icons/bi";
import { MdError, MdLockOutline } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { useGetReferralCode } from "../../../helper/useGetReferralCode";
import { InfoToolTip } from "../../atoms/InfoToolTip";
import { SylaLogo } from "./SylaFormHeader";

export const Form = styled.form`
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
`;

export const StyledFormLabel = ({
  content,
  optional = false,
  lockIcon = false,
  tooltip,
}: {
  content: string;
  optional?: boolean;
  lockIcon?: boolean;
  tooltip?: string;
}) => (
  <FormLabel display="flex" flexDirection="row" alignItems="center">
    <Text color="black.600" fontSize="0.875rem" mr="5px">
      {content}
    </Text>
    {optional && (
      <Text as="span" fontSize="0.875rem" color="black.500">
        (Optional)
      </Text>
    )}
    {lockIcon && <MdLockOutline color="#666" />}
    {tooltip && <InfoToolTip label="tooltip" />}
  </FormLabel>
);

export const StyledInput = styled(Input)`
  color: #4d4d4d;
  margin-bottom: 15px;
  font-size: 0.875rem;
  &:focus {
    border-color: #000;
    box-shadow: none;
  }
`;
export const FormHeading = styled(Heading)``;

export const Label = ({ content }: { content: string }) => (
  <FormLabel color="black.600" fontSize="0.75rem">
    {content}
  </FormLabel>
);

interface InputFieldProps extends InputProps {
  leftAddon?: string;
  fieldError?: FieldError | undefined;
  isSubmitting: boolean;
  isRequired?: boolean;
  label?: string;
}

export const GeneralInput = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      leftAddon,
      fieldError,
      isSubmitting,
      isRequired = false,
      label,
      ...props
    }: InputFieldProps,
    ref
  ) => (
    <FormControl
      isDisabled={isSubmitting}
      isInvalid={fieldError !== undefined}
      isRequired={isRequired}
      mb="10px"
    >
      {label && <Label content={label} />}
      <InputGroup>
        {leftAddon && <InputLeftAddon>{leftAddon}</InputLeftAddon>}
        <Input
          isRequired={isRequired}
          {...props}
          ref={ref}
          _focus={{ borderColor: "#000" }}
          borderColor="black.200"
        />
        {fieldError && (
          <InputRightElement>
            <MdError color="#FF3600" />
          </InputRightElement>
        )}
      </InputGroup>
      {fieldError && <FormErrorMessage>{fieldError.message}</FormErrorMessage>}
    </FormControl>
  )
);

export const SearchBar = ({
  placeholder = "Search ...",
  value,
  onChange,
}: {
  placeholder?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <InputGroup w="100%">
    <InputLeftElement pointerEvents="none">
      <BiSearchAlt2 color="gray.300" />
    </InputLeftElement>
    <Input
      type="text"
      placeholder={placeholder}
      value={value}
      bgColor="white"
      onChange={onChange}
      autoFocus={isDesktop}
    />
  </InputGroup>
);

export const SignUpToSyla = () => {
  const referralCodeParam = useGetReferralCode();
  return (
    <VStack alignItems="flex-start">
      <HStack>
        <SylaLogo />
        <FormHeading>Sign up</FormHeading>
      </HStack>
      {referralCodeParam ? (
        <HStack
          bgColor="green.100"
          p="0.3rem"
          color="green.800"
          borderRadius={"16px"}
        >
          <Tag
            bgColor="white"
            color="green.800"
            fontWeight="bold"
            borderRadius={"16px"}
          >
            ${REFERRAL_DISCOUNT}
          </Tag>
          <Text bgColor="green.100">
            {"You'll"} get a ${REFERRAL_DISCOUNT} discount on your plan 🎉
          </Text>
        </HStack>
      ) : (
        <Text>to Syla</Text>
      )}
    </VStack>
  );
};

export const SignInAndTermsLinks = ({ disabled }: { disabled?: boolean }) => {
  const history = useHistory();

  return (
    <>
      <Text mt="10px" mb="20px">
        {"Already have an account? "}
        <Button
          color="red.500"
          disabled={disabled}
          onClick={() => history.push("/sign-in")}
          variant="link"
        >
          Sign in
        </Button>
      </Text>
      <Text fontSize="0.8rem" color="black.500">
        {"By signing up with Syla, you agree to our "}
        <Button
          color="red.500"
          disabled={disabled}
          fontSize="0.8rem"
          fontWeight="normal"
          onClick={() =>
            window.open("https://www.syla.com.au/terms-of-service")
          }
          variant="link"
        >
          Terms of Service
        </Button>
      </Text>
    </>
  );
};
