import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { WalletImportMethod } from "@syla/shared/types/models/WalletBase";
import React, { useMemo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { EditDataSourceForm } from "../../../../types/dataSource/dataSource";
import { WalletResponse } from "../../../../types/wallet/wallet";
import { EditDataSource } from "./editDataSourceVariant/EditDataSource";

import { EditFileDataSource } from "./editDataSourceVariant/EditFileDataSource";
import { getDefaultEditValues } from "./GetDefaultEditValues";

interface EditDataSourceDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  wallet: WalletResponse;
  fileImport: boolean;
}

// render the appropriate form based of the wallet
const returnDrawerContent = (
  wallet: WalletResponse,
  onClose: () => void,
  fileImport: boolean
) => {
  const { importMethod } = wallet;

  if ([WalletImportMethod.API].includes(importMethod))
    return (
      <EditDataSource
        wallet={wallet}
        onClose={onClose}
        fileImport={fileImport}
      />
    );

  return (
    <EditFileDataSource
      wallet={wallet}
      onClose={onClose}
      fileImport={fileImport}
    />
  );
};

// actual edit data source drawer component
export const EditDataSourceDrawer = ({
  isOpen: isDrawerOpen,
  onClose: onCloseDrawer,
  wallet,
  fileImport,
}: EditDataSourceDrawerProps): JSX.Element => {
  // update defaultValues on change
  const defaultValues = useMemo(
    () => getDefaultEditValues({ wallet }),
    [wallet]
  );

  // hook form
  const formMethods = useForm<EditDataSourceForm>({
    defaultValues,
  });

  // get everything out of form methods
  const {
    formState: { isSubmitting },
    reset,
  } = formMethods;

  // reset defaulValues if they change or on drawer toggle (clear edits)
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset, isDrawerOpen]);

  return (
    <Drawer
      isOpen={isDrawerOpen}
      placement="right"
      onClose={onCloseDrawer}
      size="sm"
      isFullHeight
      closeOnOverlayClick={!isSubmitting}
    >
      <DrawerOverlay />
      <DrawerContent>
        <FormProvider {...formMethods}>
          {returnDrawerContent(wallet, onCloseDrawer, fileImport)}
        </FormProvider>
      </DrawerContent>
    </Drawer>
  );
};
