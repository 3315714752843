import { Text, Button } from "@chakra-ui/react";
import { throwError } from "@syla/shared/types/helpers/errors";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { forgotPassword } from "../../../api/authentication/forgotPassword";
import { FormContainer } from "../../atoms/Containers";
import { InvalidPage } from "../../atoms/InvalidPage";
import { UnexpectedError } from "../../atoms/UnexpectedError";
import { SylaFormHeader } from "../forms/SylaFormHeader";

export type ForgotPasswordSentNavState = {
  email: string;
};

export const ForgotPasswordSent = (): JSX.Element => {
  const { state } = useLocation<ForgotPasswordSentNavState | undefined>();
  const email = state?.email;

  const [error, setError] = useState<boolean>(false);

  const resend = async () => {
    try {
      await forgotPassword(email ?? throwError(new Error("Email must be set")));
      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  return email ? (
    <FormContainer>
      <SylaFormHeader heading={"Check your email"} />
      <Text>
        <p>We just sent an email to {email}.</p>
        <p>
          Please check your inbox and click the link to reset your password.
        </p>
      </Text>

      <Text mt="40px">
        {"No email received? "}
        <Button
          color="red.500"
          disabled={false}
          onClick={() => resend()}
          variant="link"
        >
          Resend
        </Button>
      </Text>
      {error && <Text mt="20px">{UnexpectedError()}</Text>}
    </FormContainer>
  ) : (
    <InvalidPage />
  );
};
