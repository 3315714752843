import { parseOptional, parseDate } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import axios from "axios";

import { User } from "../../types/user/user";

export const getUser = async (): Promise<User> => {
  const { data }: { data: TransportType<User> } = await axios.get("/users");

  return {
    ...data,
    createdAt: parseOptional(data.createdAt, parseDate),
    updatedAt: parseOptional(data.updatedAt, parseDate),
  };
};
