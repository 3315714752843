import { Text } from "@chakra-ui/react";
import React from "react";
import { ContactSupportLink } from "../molecules/ContactSupportLink";

export const UnexpectedErrorString =
  "An unexpected error has occurred. Please try again later.";

export const UnexpectedError = (): JSX.Element => (
  <Text color="red.500">
    {UnexpectedErrorString}
    <p>
      If issues persist, please{" "}
      <ContactSupportLink supportPrompt={"I need some help"} />.
    </p>
  </Text>
);
