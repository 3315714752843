import { Text, Flex, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const SectionHeading = styled(Text)`
  color: #666;
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 20px;
`;
export const SettingLabel = styled(Text)`
  color: #4d4d4d;
  font-size: 1rem;
`;
const CurrentValueText = styled(Text)`
  color: #808080;
  font-size: 1rem;
`;
export const SectionContainer = styled(Flex)`
  flex-direction: column;
  margin-bottom: 20px;
`;
export const Btn = styled(Button)`
  color: #808080;
  background-color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  height: 32px;
  border: 1px solid #e5e5e5;

  &:hover {
    background-color: #f5f5f5;
  }
`;
