import {
  Flex,
  useDisclosure,
  IconButton,
  Collapse,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useContext } from "react";
import { isBrowser } from "react-device-detect";
import { IoMenu, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { UserContext } from "../../../contexts/UserContext";

import { ReactComponent as HomeButton } from "../../../images/icons/navbar/homeButton.svg";
import { buildDashboardLink } from "../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { Device } from "../../../types/user/device";
import { NavbarLinks } from "./NavbarLinks";

// zindex of main navigation elements. Should be on top of everything else.
export const TOP_NAV_ZINDEX = 11;

export const Navbar = () => {
  const { userDevice } = useContext(UserContext);
  const { isOpen, onToggle, onClose } = useDisclosure();
  useEffect(() => {
    onClose();
  }, [userDevice === Device.Mobile, onClose]);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const isMobileView = userDevice === Device.Mobile || !isBrowser;

  return (
    <Flex
      bgColor="black"
      maxH="100%"
      overflowY="auto"
      minW="200px"
      w={
        isMobileView
          ? "100%"
          : { sm: "10%", md: "10%", lg: "15%", xl: "10%", "2xl": "10%" }
      }
      shrink={0}
      direction="column"
      zIndex={TOP_NAV_ZINDEX}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px="0.5rem"
        py="0.5rem"
      >
        <Link to={buildDashboardLink({ accountId })}>
          <Box py="0.25rem" px="0.25rem" w="7rem">
            <HomeButton />
          </Box>
        </Link>
        {isMobileView && (
          <IconButton
            aria-label="Navbar button"
            bgColor="transparent"
            _hover={{ bgColor: "transparent" }}
            _active={{ bgColor: "transparent" }}
            onClick={() => onToggle()}
          >
            {isOpen ? (
              <IoClose color="#fff" size="2rem" />
            ) : (
              <IoMenu color="#fff" size="2rem" />
            )}
          </IconButton>
        )}
      </Flex>

      {isMobileView ? (
        <Collapse in={isOpen}>
          <NavbarLinks onClose={() => onClose()} />
        </Collapse>
      ) : (
        <NavbarLinks onClose={() => undefined} />
      )}
    </Flex>
  );
};
