export interface ReferralBase<TId> {
  _id?: TId;
  code: string;
  referredUsers: ReferredUserBase<TId>[];
}

export enum ReferredUserStatus {
  SignedUp = "signed-up",
  Purchased = "purchased",
}

export interface ReferredUserBase<TId> {
  id?: TId;
  status: ReferredUserStatus;
}
