import { Decimal } from "../decimal";
import { AssetType } from "../types/models/AssetBase";

export type SortableMarketValueHolding = {
  marketValue?: Decimal;
  asset: { type: AssetType };
};

export function sortHoldingsMarketValueCompareFn(
  a: SortableMarketValueHolding,
  b: SortableMarketValueHolding
) {
  // some market value before no market value
  if (a.marketValue && !b.marketValue) return -1;
  if (!a.marketValue && b.marketValue) return 1;

  // when neither has market value
  if (!a.marketValue && !b.marketValue) {
    // custom assets after non-custom assets
    if (a.asset.type == AssetType.Custom && !(b.asset.type == AssetType.Custom))
      return 1;
    if (!(a.asset.type == AssetType.Custom) && b.asset.type == AssetType.Custom)
      return -1;

    return 0;
  }

  // otherwise sort by market value, descending
  return a.marketValue!.comparedTo(b.marketValue!) * -1;
}
