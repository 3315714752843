import { ButtonProps } from "@chakra-ui/react";
import { AccountType } from "@syla/shared/types/models/AccountBase";
import React from "react";
import { ItemSelector } from "../../components/molecules/ItemSelector";

export function AccountTypeSelector({
  currentValue,
  onSelected,
  isLoading,
  isDisabled,
  ...props
}: {
  currentValue: AccountType | undefined;
  onSelected: (value: AccountType) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
} & ButtonProps) {
  const values = Object.values(AccountType);

  return (
    <ItemSelector
      currentValue={currentValue}
      onValueSelected={onSelected}
      allValues={values}
      labels={AccountType}
      isLoading={isLoading}
      isDisabled={isDisabled}
      {...props}
    />
  );
}
