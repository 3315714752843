import { TagProps, Tag, Flex, StackProps } from "@chakra-ui/react";
import React from "react";

export function PageHeadingTag(props: TagProps) {
  return (
    <Tag
      size="lg"
      bgColor="transparent"
      border="1px solid"
      borderRadius="12px"
      {...props}
    />
  );
}

export function PageHeadingTagContainer(props: StackProps) {
  return (
    <Flex
      alignItems="center"
      wrap="wrap"
      gridGap={{ base: 2, sm: 6 }}
      {...props}
    />
  );
}
