import { Decimal } from "@syla/shared/decimal";
import { HoldingsValue } from "../../../../helper/wallet/HoldingsValue";
import { WalletResponse } from "../../../../types/wallet/wallet";

export function getWalletHoldingsValue({
  holdingsValue,
  walletWithHoldings,
}: {
  holdingsValue: HoldingsValue | undefined;
  walletWithHoldings: { _id: string } & Pick<WalletResponse, "holdings">;
}): Decimal | undefined {
  return walletWithHoldings.holdings && walletWithHoldings.holdings.length == 0
    ? Decimal.from("0")
    : holdingsValue?.getWalletTotals({ walletId: walletWithHoldings._id })
        ?.marketValue;
}
