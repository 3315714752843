import { useToast } from "@chakra-ui/react";
import { getAccountShortName } from "@syla/shared/types/models/AccountAccessBase";
import { useHistory, useParams } from "react-router-dom";
import { AccountRouteParams } from "../helper/accountRouteParams";
import { getPageUrl } from "../helper/getFullPageUrl";
import { buildDashboardLink } from "./routePaths";

export function useSwitchAccount() {
  const toast = useToast();
  const history = useHistory();
  const { accountId: routeAccountId } = useParams<AccountRouteParams>();

  return (newAccount: {
    _id: string;
    fullName?: string;
    nickname?: string;
  }) => {
    const newAccountId = newAccount._id;

    if (newAccountId != routeAccountId) {
      const currentLocation = getPageUrl(location, {
        path: true,
        search: true,
        hash: true,
      });

      const newLocation = routeAccountId
        ? currentLocation.replace(routeAccountId, newAccountId)
        : buildDashboardLink({ accountId: newAccountId });

      history.replace(newLocation);
      if (newAccount.fullName) {
        toast({
          status: "info",
          title: `Using account ${getAccountShortName({
            fullName: newAccount.fullName,
            nickname: newAccount.nickname,
          })}`,
          position: "top",
        });
      }
    }
  };
}
