import { AddWalletRequestBody } from "@syla/shared/types/requests/AddWalletRequest";
import axios from "axios";

import { Wallet } from "../../types/wallet/wallet";

export const addWallet = async (
  accountId: string,
  wallet: AddWalletRequestBody
): Promise<Wallet> => {
  const { data } = await axios.post(`/accounts/${accountId}/wallets`, wallet);
  return data;
};
