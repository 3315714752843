import { useHistory } from "react-router-dom";
import { EmailVerificationNavState } from "../components/molecules/signUpAndLoginPages/EmailVerification";
import { ForgotPasswordNavState } from "../components/molecules/signUpAndLoginPages/ForgotPasswordForm";
import { ForgotPasswordSentNavState } from "../components/molecules/signUpAndLoginPages/ForgotPasswordSent";
import { MainTransactionTableHistoryState } from "../components/organisms/transaction/mainTransactionTable/TransactionsView";
import { DataSourceHistoryState } from "../pages/DataSources";
import { PlansNavState } from "../pages/Plans";
import {
  buildDashboardLink,
  buildPlansLink,
  buildTransactionsLink,
  buildDataSourcesLink,
  buildFirstDataSourceLink,
} from "./routePaths";

export enum Route {
  Home,
  Plans,
  EmailVerification,
  ForgotPassword,
  ForgotPasswordSent,
  DataSources,
  Transactions,
  FirstLogin,
}

/**
 * Hook used to navigate between routes that expect a certain state, in a type-safe way
 */
export const useNavigator: () => (props: NavProps) => void = () => {
  const history = useHistory();

  return (props: NavProps): void => {
    const { replace, state } = props;
    const pathname = buildRoute(props);
    if (replace) history.replace({ pathname, state });
    else history.push({ pathname, state });
  };
};

export type NavProps = { replace?: boolean } & (
  | HomeNavProps
  | ForgotPasswordNavProps
  | ForgotPasswordSentNavProps
  | EmailVerificationNavProps
  | PlansNavProps
  | TransactionsNavProps
  | DataSourcesNavProps
  | FirstLoginNavProps
);

type ForgotPasswordNavProps = {
  route: Route.ForgotPassword;
  state?: ForgotPasswordNavState;
};

type ForgotPasswordSentNavProps = {
  route: Route.ForgotPasswordSent;
  state: ForgotPasswordSentNavState;
};

type EmailVerificationNavProps = {
  route: Route.EmailVerification;
  state: EmailVerificationNavState;
};

type PlansNavProps = {
  route: Route.Plans;
  accountId: string;
  state: PlansNavState;
};

type HomeNavProps = {
  route: Route.Home;
  accountId: string;
  state?: {};
};

type TransactionsNavProps = {
  route: Route.Transactions;
  accountId: string;
  state?: MainTransactionTableHistoryState;
};

type DataSourcesNavProps = {
  route: Route.DataSources;
  accountId: string;
  state?: DataSourceHistoryState;
};

type FirstLoginNavProps = {
  route: Route.FirstLogin;
  accountId: string;
  state?: {};
};

function buildRoute(props: NavProps): string {
  switch (props.route) {
    case Route.FirstLogin:
      return buildFirstDataSourceLink({ accountId: props.accountId });
    case Route.DataSources:
      return buildDataSourcesLink({ accountId: props.accountId });
    case Route.Transactions:
      return buildTransactionsLink({ accountId: props.accountId });
    case Route.ForgotPassword:
      return "/forgot-password";
    case Route.Home:
      return props.accountId
        ? buildDashboardLink({ accountId: props.accountId })
        : "/";
    case Route.ForgotPasswordSent:
      return "/forgot-password-sent";
    case Route.EmailVerification:
      return "/email-verification";
    case Route.Plans:
      return buildPlansLink({ accountId: props.accountId });
  }
}
