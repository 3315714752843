import { getWalletName } from "@syla/shared/types/models/WalletBase";
import { EditDataSourceForm } from "../../../../types/dataSource/dataSource";
import { Wallet } from "../../../../types/wallet/wallet";

export const getDefaultEditValues = ({
  wallet,
}: {
  wallet: Wallet;
}): EditDataSourceForm => ({
  apiKey: wallet.apiKey || "",
  secretKey: wallet.secretKey || "",
  passphrase: wallet.passphrase || "",
  customName: getWalletName({ wallet, dataSource: wallet.dataSource }),
  storedFiles: [],
});
