import { GetTaxReportSummaryResponse } from "@syla/shared/types/responses/TaxReport";
import { useQuery, UseQueryResult, UseQueryOptions } from "react-query";
import { returnTaxReportSummary } from "../../../api/user/returnTaxReportSummary";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { getAccountTransactionsCacheKey } from "../../../store/transactionsStore";
import { FinancialYearOption } from "../../../types/user/financialYearOption";

export const useTaxReportSummaryQuery = (
  selectedFinancialYear: FinancialYearOption | undefined,
  options?: Omit<UseQueryOptions<any>, "queryKey" | "queryFn">
): UseQueryResult<GetTaxReportSummaryResponse> => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  return useQuery(
    [
      ...getAccountTransactionsCacheKey(accountId),
      "tax-report-summary",
      selectedFinancialYear,
    ],
    () => {
      if (!selectedFinancialYear) return undefined;

      return returnTaxReportSummary(accountId, selectedFinancialYear.value);
    },
    options
  );
};
