import { getWalletName } from "@syla/shared/types/models/WalletBase";
import { useGetWallets } from "../../store/actions/wallet";
import { Wallet } from "../../types/wallet/wallet";

export const useGetWalletOptions = (accountId: string) =>
  useGetWallets(accountId, undefined, {
    select: (data) => data.wallets.map(formatWalletOption),
  });

export const formatWalletOption = (
  wallet: Pick<Wallet, "_id" | "customName" | "dataSource">
): SelectOptionType => ({
  value: wallet._id,
  label: getWalletName({ wallet, dataSource: wallet.dataSource }),
  icon: wallet.dataSource.image,
});
