import React, { createContext, useContext, useState } from "react";

export type AddDataSourceContextProps = {
  state: {
    dataSourcesSearchQuery: string;
  };
  actions: {
    setDataSourcesSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  };
};

const AddDataSourceContext = createContext<AddDataSourceContextProps>(
  undefined as any
);

export const useAddDataSourceContext = () => useContext(AddDataSourceContext);

export const AddDataSourceContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dataSourcesSearchQuery, setDataSourcesSearchQuery] = useState("");

  return (
    <AddDataSourceContext.Provider
      value={{
        state: {
          dataSourcesSearchQuery,
        },
        actions: {
          setDataSourcesSearchQuery,
        },
      }}
    >
      {children}
    </AddDataSourceContext.Provider>
  );
};
