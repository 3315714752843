import { Text, HStack, Icon, Switch } from "@chakra-ui/react";
import { VisibilityState } from "@tanstack/react-table";
import React from "react";
import { TooltipWrapper } from "../../atoms/TooltipWrapper";
import { Selector, KeyedOption } from "../../molecules/selector/Selector";
import { ReactComponent as HelpCircleIcon } from "../../../images/icons/helpCircle.svg";
import { ReactComponent as FilterIcon } from "../../../images/icons/filterLines.svg";
import { columnDefs, ColumnDef } from "./getBalancesTableColumns";

const managedColumns = Object.fromEntries(
  Object.entries(columnDefs).filter(([, def]) => def.optional)
);

const options: KeyedOption<ColumnDef, string>[] = Object.entries(
  managedColumns
).map(([id, props]) => ({
  key: id,
  value: props,
}));

export function ColumnSelector({
  setState,
  state,
}: {
  state: VisibilityState;
  setState: React.Dispatch<React.SetStateAction<VisibilityState>>;
}) {
  return (
    <Selector
      renderOptionContent={({ isSelected, option: { name, description } }) => (
        <Item name={name} selected={isSelected} description={description} />
      )}
      SelectorContent={
        <Text fontWeight="500" fontSize="0.875rem" color="black.550">
          Customise
        </Text>
      }
      expandIcon={<FilterIcon />}
      options={options}
      multiSelect={true}
      // Use subset of state that is part of managedColumns
      selectedOptions={Object.keys(managedColumns)
        .filter((id) => state[id])
        .map((id) => ({ key: id }))}
      onChangeSelection={(selected) => {
        const newState = Object.fromEntries(
          Object.keys(managedColumns).map((id) => [
            id,
            selected.some((s) => s.key == id),
          ])
        );
        console.debug({ newState });
        setState((oldState) => ({ ...oldState, ...newState }));
      }}
      buttonProps={{ height: "30px", backgroundColor: "white" }}
    />
  );
}

const Item = ({
  name,
  description,
  selected,
}: {
  name: string;
  description?: string;
  selected: boolean;
}) => {
  return (
    <HStack w="100%" position="relative" bg="transparent" py="0.25rem">
      <Switch size="sm" isChecked={selected} />
      <Text color="gray.700" fontSize="sm">
        {name}
      </Text>
      {description && (
        <TooltipWrapper tooltip={description}>
          <Icon as={HelpCircleIcon} color="gray.400" boxSize="1.2em" />{" "}
        </TooltipWrapper>
      )}
    </HStack>
  );
};
