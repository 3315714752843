import { GetPendingWorkResponse } from "@syla/shared/types/responses/GetPendingWorkResponse";
import axios from "axios";
import { secondsToMilliseconds } from "date-fns";
import {
  doPendingWork,
  AlreadyInProgressError,
} from "../api/status/doPendingWork";
import { getPendingWork } from "../api/status/getPendingWork";
import { captureRequestError } from "../helper/captureRequestError";
import {
  refreshGroupList,
  invalidateTransactionQueryCache,
} from "./actions/groupList";
import { useTransactionsStore, queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";

export async function checkStatusAndDoPendingWork() {
  const { accountId } = useTransactionsStore.getState();
  console.debug("checkStatusAndDoPendingWork", { accountId });
  if (!accountId) return;

  let pendingWorkResponse: GetPendingWorkResponse;
  try {
    pendingWorkResponse = await getPendingWork(accountId);
  } catch (error) {
    // if we get a 403 or 404 back, we may have lost access to the account or it's been deleted.
    // refresh the accounts list in that case so that we can switch
    if (
      axios.isAxiosError(error) &&
      [403, 404].includes(error.response?.status ?? 0)
    ) {
      queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
    } else {
      captureRequestError(error);
    }
    return;
  }

  const previouslyUpdating = useTransactionsStore.getState().updating;
  const updating =
    pendingWorkResponse.processing || pendingWorkResponse.updateFrom != null;

  if (updating != previouslyUpdating)
    useTransactionsStore.setState({
      updating,
    });

  const previouslySyncing = useTransactionsStore.getState().syncing;
  const syncing = pendingWorkResponse.syncing;

  if (syncing != previouslySyncing)
    useTransactionsStore.setState({
      syncing,
    });

  // refresh view once we have settled
  if (previouslyUpdating && !updating) {
    invalidateTransactionQueryCache(accountId);
    refreshGroupList();
  }

  if (pendingWorkResponse.processing || !pendingWorkResponse.updateFrom) return; // nothing to do

  try {
    await doPendingWork(accountId);

    // update status and do any subsequent work
    checkStatusAndDoPendingWork();
  } catch (error) {
    if (error instanceof AlreadyInProgressError)
      useTransactionsStore.setState({
        updating: true,
      });
    else {
      captureRequestError(error);
    }
  }
}
let statusUpdateTimer;

export function pollPendingWork() {
  if (statusUpdateTimer) return;
  statusUpdateTimer = setInterval(
    checkStatusAndDoPendingWork,
    secondsToMilliseconds(10)
  );
  checkStatusAndDoPendingWork();
}

export function stopPollingPendingWork() {
  if (statusUpdateTimer) {
    clearInterval(statusUpdateTimer);
    statusUpdateTimer = null;
  }
}
