import { Decimal, sumWithDecimal } from "@syla/shared/decimal";
import { groupBy } from "lodash";
import { ValueHoldingsResponse } from "../../types/wallet/wallet";

export type HoldingsStat = { marketValue: Decimal };

export class HoldingsValue {
  private readonly _assetLookup: Record<string, HoldingsStat[]>;
  private readonly _walletLookup: Record<string, HoldingsStat[]>;
  private readonly _walletAssetLookup: Record<string, HoldingsStat[]>;

  public getWalletAssetValue({
    walletId,
    assetId,
  }: {
    walletId: string;
    assetId: string;
  }): HoldingsStat | undefined {
    return this._walletAssetLookup[`${walletId}/${assetId}`]?.at(0);
  }

  public getAccountAssetValue({ assetId }: { assetId: string }):
    | {
        marketValue: Decimal;
      }
    | undefined {
    return this._assetLookup[`${assetId}`]?.at(0);
  }

  public getWalletTotals = ({
    walletId,
    skipNegative,
  }: {
    walletId: string;
    // remove negative holdings from total
    skipNegative?: boolean;
  }): HoldingsStat | undefined => {
    // console.debug("getWalletTotals");
    return (
      (this._walletLookup[walletId] && {
        marketValue: sumWithDecimal(
          this._walletLookup[walletId].filter(
            (h) => !skipNegative || h.marketValue.isPositive()
          ),
          "marketValue"
        ),
      }) ||
      undefined
    );
  };

  public getAccountTotals = ({
    skipNegative,
  }: {
    skipNegative?: boolean;
  } = {}): HoldingsStat => {
    // console.debug("getAccountTotals");

    return {
      marketValue: sumWithDecimal(
        Object.values(this._assetLookup).flatMap((assetHoldings) =>
          assetHoldings.filter(
            (holding) => !skipNegative || holding.marketValue.isPositive()
          )
        ),
        "marketValue"
      ),
    };
  };

  constructor(data: ValueHoldingsResponse) {
    this._assetLookup = groupBy(data.accountHoldings, ({ assetId }) => assetId);
    this._walletLookup = groupBy(
      data.walletHoldings,
      ({ walletId }) => walletId
    );
    this._walletAssetLookup = groupBy(
      data.walletHoldings,
      ({ assetId, walletId }) => `${walletId}/${assetId}`
    );
  }
}
