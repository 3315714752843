import React, { useMemo } from "react";
import { uniqBy } from "lodash";
import { Flex } from "@chakra-ui/react";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import { ImageWithMissingSrc } from "./ImageWithMissingSrc";

export type Size = "sm" | "md" | "xs";

export const RenderIcons = ({
  assets,
  qty,
  isLoading,
  size = "md",
  noPaddingIcon,
}: {
  assets: {
    image?: string;
    type?: AssetType;
  }[];
  qty: number;
  isLoading?: boolean;
  size?: Size;
  noPaddingIcon?: boolean;
}) => {
  const nonDuplicateAssets = useMemo(() => uniqBy(assets, "image"), [assets]);
  return (
    <Flex
      minW="160px"
      alignItems="center"
      gridGap={sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].gap}
    >
      {nonDuplicateAssets.slice(0, qty).map((asset, index) => (
        <ImageWithMissingSrc
          key={index}
          src={asset.image}
          alt=""
          // ml="5px"
          h={sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].xy}
          w={sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].xy}
          p={
            asset.type === AssetType.Currency
              ? sizeProps[size][noPaddingIcon ? "noPadding" : "padding"]
                  .currencyPadding
              : "0"
          }
          isLoading={isLoading}
          noPaddingIcon={noPaddingIcon}
        />
      ))}
      {!isLoading && nonDuplicateAssets.length > qty && (
        <Flex
          fontSize={
            sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].fontSize
          }
          justifyContent="center"
          alignItems="center"
          bgColor="white.500"
          color="black.600"
          h={sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].xy}
          minW={sizeProps[size][noPaddingIcon ? "noPadding" : "padding"].xy}
          borderRadius="30px"
          fontWeight="bold"
          p="4px"
        >
          +{nonDuplicateAssets.length - qty}
        </Flex>
      )}
    </Flex>
  );
};

type SizeProps = {
  xy: string;
  fontSize: string;
  currencyPadding: string;
  gap: string;
};
const sizeProps: Record<Size, { padding: SizeProps; noPadding: SizeProps }> = {
  md: {
    padding: {
      xy: "45px",
      fontSize: "1rem",
      currencyPadding: "2x",
      gap: "5px",
    },
    noPadding: {
      xy: "45px",
      fontSize: "1rem",
      currencyPadding: "2x",
      gap: "5px",
    },
  },
  sm: {
    padding: {
      xy: "30px",
      fontSize: "0.9rem",
      currencyPadding: "1px",
      gap: "5px",
    },
    noPadding: {
      xy: "30px",
      fontSize: "0.9rem",
      currencyPadding: "1px",
      gap: "5px",
    },
  },
  xs: {
    padding: {
      xy: "22.5px",
      fontSize: "0.8rem",
      currencyPadding: "1px",
      gap: "3px",
    },
    noPadding: {
      xy: "18px",
      fontSize: "0.8rem",
      currencyPadding: "1px",
      gap: "3px",
    },
  },
};
