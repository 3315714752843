import { parseDate } from "@syla/shared/types/helpers/parsing";
import {
  GetAccountImportsResponse,
  AccountImportDetails,
} from "@syla/shared/types/responses/GetAccountImportsResponse";
import axios from "axios";
import { TransportType } from "@syla/shared/types/helpers/TransportType";

export type AccountImport = AccountImportDetails<string>;

export const getAccountImports = async (
  accountId: string
): Promise<GetAccountImportsResponse<string>> => {
  const {
    data: { imports },
  } = await axios.get<TransportType<GetAccountImportsResponse<string>>>(
    `/accounts/${accountId}/transactions/imports`
  );
  return {
    imports: imports.map((i) => ({
      ...i,
      import: {
        ...i.import,
        date: parseDate(i.import.date),
      },
    })),
  };
};
