import { Spinner, Text, Heading } from "@chakra-ui/react";
import { VerifyAccountInviteErrorCode } from "@syla/shared/types/responses/VerifyAccountInviteResponse";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { VerifyAccountInviteError } from "../../../api/account/verifyAccountInvite";

import { UserContext } from "../../../contexts/UserContext";
import { Route, useNavigator } from "../../../routers/navigator";
import { useMutationVerifyAccountInvite } from "../../../store/useMutationVerifyAccountInvite";
import { FormContainer, StdVStack } from "../../atoms/Containers";
import { UnexpectedError } from "../../atoms/UnexpectedError";
import { UserPasswordForm } from "../forms/settingForms/UserPasswordForm";
import { SylaFormHeader } from "../forms/SylaFormHeader";

export const VerifyAccountInvite = (): JSX.Element => {
  const { setUserDetails, setFirstLogin } = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigator();

  const [errorState, setErrorState] = useState<ErrorState>();

  const { mutateAsync: verifyAccountInvite, isLoading } =
    useMutationVerifyAccountInvite();

  const verifyAccountInviteAction = (password?: string) => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (!token) {
      setErrorState(ErrorState.Invalid);
      return;
    }

    verifyAccountInvite({ token, password })
      .then(
        ({
          auth: { accountId, email, refreshToken, token: authToken, userId },
        }) => {
          setFirstLogin(true); // needed for correct transition to authenticated state

          setUserDetails({
            accessToken: authToken,
            refreshToken,
            email,
            id: userId,
            defaultAccountId: accountId,
          });

          navigate({
            route: Route.Home,
            accountId,
            replace: true,
          });
        }
      )
      .catch((error) => {
        if (error instanceof VerifyAccountInviteError) {
          const code = error.response.code;
          setErrorState(
            code == VerifyAccountInviteErrorCode.Invalid
              ? ErrorState.Invalid
              : code == VerifyAccountInviteErrorCode.Expired
              ? ErrorState.Expired
              : code == VerifyAccountInviteErrorCode.PasswordRequired
              ? ErrorState.CapturePassword
              : ErrorState.Unknown
          );
        } else {
          setErrorState(ErrorState.Unknown);
        }
      });
  };

  // do initial verify on load
  useEffect(() => {
    verifyAccountInviteAction();
  }, []);

  const errorColor = "red.500";
  return (
    <FormContainer>
      <SylaFormHeader
        heading={!isLoading ? "Account Invite" : "Please wait..."}
      />

      {isLoading && (
        <Spinner
          color="red.500"
          emptyColor="red.200"
          my="10px"
          thickness="5px"
          size="lg"
        />
      )}
      {errorState == ErrorState.Invalid && (
        <Text color={errorColor}>
          This link is no-longer valid. Please check if you already have access
          to this account!
        </Text>
      )}
      {errorState == ErrorState.Expired && (
        <Text color={errorColor}>
          {
            "This link has expired. We've sent another link to your email address."
          }
        </Text>
      )}
      {errorState == ErrorState.CapturePassword && (
        <StdVStack spacing={8}>
          <Heading size="sm">
            Please set a password for your new user profile
          </Heading>
          <UserPasswordForm
            currentPassword={false}
            confirmPassword={false}
            passwordLabel="Password"
            onPasswordChangeRequest={({ newPassword }) => {
              return verifyAccountInviteAction(newPassword);
            }}
          />
        </StdVStack>
      )}
      {errorState == ErrorState.Unknown && UnexpectedError()}
    </FormContainer>
  );
};

enum ErrorState {
  Invalid,
  Expired,
  CapturePassword,
  Unknown,
}
