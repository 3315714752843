/* eslint-disable react/jsx-no-target-blank */
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Flex, Text, Heading, Spinner, useToast } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Decimal } from "@syla/shared/decimal";
import { getLastFinancialYear } from "@syla/shared/helpers/financialYear";
import { throwError } from "@syla/shared/types/helpers/errors";
import {
  premiumAccountTypeRequirement,
  premiumAccountType,
  premiumAccountSubType,
} from "@syla/shared/types/helpers/permissions";
import {
  planHasCapacity,
  getSubscriptionStatus,
  getCurrentSubscription,
} from "@syla/shared/types/helpers/subscriptions";
import {
  AccountType,
  AccountSubType,
  ActivityTypeName,
} from "@syla/shared/types/models/AccountBase";
import { PlanId } from "@syla/shared/types/models/PlanBase";
import React, { useEffect, useState, useCallback } from "react";
import { isBrowser } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { PageContainer } from "../components/atoms/Containers";
import { errorToastOptions } from "../components/molecules/errorToastOptions";
import { PlanCard, Feature } from "../components/molecules/PlanCard";
import { getFullPageUrl } from "../helper/getFullPageUrl";
import {
  canPurchaseSubscription,
  canUpgradeSubscription,
} from "../helper/permissions";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { Plan } from "../types/plans/plan";
import { Subscription } from "../types/plans/subscription";
import { stripePurchaseOrUpgrade } from "./stripePurchaseOrUpgrade";
import { useGetAccountPlanProps, findPlan } from "./useGetAccountPlanProps";

export type PlansNavState = {
  referringUrl: string;
  selectedFinancialYear?: string;
};

export const Plans = () => {
  const { state } = useLocation<PlansNavState | undefined>();
  const [redirecting, setRedirecting] = useState<boolean>(false);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const errorToast = useToast(errorToastOptions);

  useEffect(() => {
    console.debug({ state });
  });

  const selectedFinancialYear =
    state?.selectedFinancialYear ?? getLastFinancialYear();

  const {
    plans,
    subscriptions,
    validPlans,
    currentPlan,
    recommendedPlan,
    txCount,
    isLoading: planPropsLoading,
    account,
  } = useGetAccountPlanProps();

  const txCountDisplay = txCount
    ? Decimal.from(txCount.value.toString()).format({
        separateThousands: true,
      }).text
    : undefined;

  // loading state for all the queries
  const queriesLoading = planPropsLoading;

  const currentSubscription = getCurrentSubscription(subscriptions);

  const canPurchase =
    canPurchaseSubscription({ account, subscriptions }) ||
    canUpgradeSubscription({ subscriptions });

  const isValidSubscription = (sub) => {
    const { valid, expired } = getSubscriptionStatus(sub);
    return valid && !expired;
  };
  const actionNeeded = !subscriptions.every(isValidSubscription);

  const openCheckoutSession = useCallback(
    async (plan: Pick<Plan, "_id">, subscription?: Subscription) => {
      try {
        setRedirecting(true);
        await stripePurchaseOrUpgrade({
          accountId,
          successUrl: state?.referringUrl ?? getFullPageUrl(location),
          cancelUrl: getFullPageUrl(location),
          financialYear: selectedFinancialYear,
          stripeSubscriptionId: subscription?.stripeSubscriptionId,
          plan,
          currentPlan: currentSubscription?.plan,
        });
      } catch (error) {
        errorToast({
          title: "Error selecting plan",
          description: `An unexpected error occurred. Please try again later.`,
        });
        return;
      } finally {
        setRedirecting(false);
      }
    },
    [
      accountId,
      currentSubscription?.plan,
      errorToast,
      selectedFinancialYear,
      state?.referringUrl,
    ]
  );

  if (queriesLoading)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center">
          <Spinner
            color="red.500"
            emptyColor="red.200"
            my="50px"
            thickness="5px"
            size="xl"
          />
        </Flex>
      </PageContainer>
    );

  return (
    <PageContainer isBrowser={isBrowser}>
      <YearlyPlanHeading textAlign="center" fontSize="2.25rem" color="">
        Tax Plans
      </YearlyPlanHeading>

      <Flex direction="row" w="fit-content" mx="auto" alignItems="center">
        <InfoOutlineIcon mr="5px" />
        <Text
          textAlign="center"
          fontSize="0.875rem"
          color="black.500"
          fontWeight="500"
          // mt="20px"
        >
          You currently have{" "}
          {planPropsLoading || txCount == undefined ? (
            <Spinner size="xs" />
          ) : (
            txCountDisplay
          )}{" "}
          transactions on your account.
        </Text>
      </Flex>

      <Flex
        w="100%"
        justifyContent="center"
        // px="10px"
        flexWrap="wrap"
        mt="20px"
      >
        {plans.map((plan) => {
          const current = currentPlan?._id === plan._id;

          let expiredDate: string | undefined = undefined;
          let cancelDate: string | undefined = undefined;
          let purchased = false;
          let invalid = false;

          if (current && currentSubscription) {
            const { expired, cancelled, expiryDate, valid } =
              getSubscriptionStatus(currentSubscription);
            const endDateFormatted = expiryDate.toLocaleDateString();
            if (expired) expiredDate = endDateFormatted;
            if (cancelled) cancelDate = endDateFormatted;
            if (valid) purchased = true;
            else invalid = true;
          }

          const recommended = plan._id == recommendedPlan?._id;

          const invalidPlanType = !premiumAccountTypeRequirement({
            account,
            plan,
          });

          const invalidPlanMessage =
            invalidPlanType && account
              ? `This plan is not suitable for ` +
                (premiumAccountType(account.accountType)
                  ? `${account.accountType == AccountType.SMSF ? "an" : "a"} ${
                      account.accountType
                    } account. You can update your Account Type under Settings or choose a more suitable plan`
                  : premiumAccountSubType(account.accountSubType)
                  ? `a ${
                      ActivityTypeName[AccountSubType.Trader]
                    } account. You can update your Activity Type under Settings or choose a more suitable plan`
                  : throwError("Invalid case"))
              : undefined;

          return (
            <PlanCard
              key={plan._id}
              price={plan.price}
              heading={plan.name}
              description={plan.description}
              featureList={getFeatureList(plan)}
              action={() => openCheckoutSession(plan, currentSubscription)}
              purchasable={
                canPurchase && // has purchase permission
                // only allow interaction with current plan when action is needed
                // otherwise only if plan is valid
                ((actionNeeded && current) ||
                  (!actionNeeded &&
                    !!findPlan(validPlans, plan._id) &&
                    !invalidPlanType))
              }
              canManage={canPurchase}
              notPurchasableReason={
                !canPurchase
                  ? "Please contact the billing owner for this account to purchase a plan"
                  : invalidPlanType
                  ? invalidPlanMessage
                  : !txCount
                  ? "Please wait for your tax calculations to complete"
                  : !planHasCapacity({ plan, transactionCount: txCount.value })
                  ? "The number of transactions on your account exceed the plan limits"
                  : undefined
              }
              headerText={
                expiredDate
                  ? `Expired ${expiredDate}`
                  : cancelDate
                  ? `Expires ${cancelDate}`
                  : invalid
                  ? "Action Required"
                  : purchased
                  ? "Subscribed"
                  : recommended && !actionNeeded
                  ? "Recommended"
                  : undefined
              }
              status={
                expiredDate
                  ? "expired"
                  : cancelDate
                  ? "cancelled"
                  : invalid
                  ? "actionRequired"
                  : purchased
                  ? "purchased"
                  : recommended && !actionNeeded
                  ? "recommended"
                  : "normal"
              }
              purchased={current}
              purchasedPrice={currentPlan?.price}
              upgrading={actionNeeded}
              previousPrice={0}
              isBusy={redirecting}
            />
          );
        })}
      </Flex>
    </PageContainer>
  );
};

const YearlyPlanHeading = styled(Heading)`
  font-size: 2.25rem !important;
  color: #333333;
  text-align: center;
  font-family: GintoNormal;
  margin-bottom: 30px;
`;

function getFeatureList(plan: Plan): Feature[] {
  const formattedTxLimit = Decimal.from(
    plan.transactionLimit.toString()
  ).format({
    separateThousands: true,
  }).text;

  return [
    {
      text: "All financial years",
      included: true,
    },
    {
      text: `${
        plan.transactionLimit == -1 ? "Unlimited" : formattedTxLimit
      } transactions`,
      included: true,
    },
    {
      text: "Crypto tax reports",
      included: plan._id != PlanId.Free,
    },
    {
      text: "Tax assurance reports",
      included: plan.assuranceFeatures,
    },
    {
      text: "LTFO tax saving",
      included: plan.taxOptimisationFeatures,
    },
    {
      text: "Trading Stock",
      included: plan.premiumFeatures,
    },
    {
      text: "Companies and Trusts",
      included: plan.premiumFeatures,
    },
    {
      text: "SMSF support",
      included: plan.premiumFeatures,
    },
    {
      text: "BGL sync",
      included: plan.premiumFeatures,
    },
  ];
}
