import { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { UserContext } from "../../contexts/UserContext";

export const IntercomIntegration = () => {
  const location = useLocation();
  const { userDetails } = useContext(UserContext);

  // Intercom integration
  const { update, shutdown, boot } = useIntercom();

  // handle log in and log out
  useEffect(() => {
    if (userDetails?.id) {
      boot({
        userId: userDetails?.id,
        email: userDetails?.email,
      });
    } else {
      shutdown();
      boot();
    }
  }, [boot, shutdown, update, userDetails?.email, userDetails?.id]);

  // handle route changes
  useEffect(() => {
    update({
      userId: userDetails?.id,
      email: userDetails?.email,
    });
  }, [location.pathname, update, userDetails?.email, userDetails?.id]);

  return null;
};
