import { useQuery } from "react-query";
import { getAccountTransactionCount } from "../api/transactions/getAccountTransactionCount";
import { useCurrentAccountStore } from "./currentAccountStore";
import { getAccountTransactionsCacheKey } from "./transactionsStore";

export const useQueryGetAccountTransactionCount = () => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  return useQuery(
    [...getAccountTransactionsCacheKey(accountId), "tx-count"],
    () => getAccountTransactionCount(accountId)
  );
};
