import isStrongPassword from "validator/lib/isStrongPassword";

export const validatePassword = (password: string): boolean =>
  isStrongPassword(password, {
    minLength: 8,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 0,
    minSymbols: 0,
  });

export const validatePasswordInput = (password?: string): undefined | string =>
  validatePassword(password || "")
    ? undefined
    : "Password does not meet requirements";
