import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import React from "react";
import { DataSourceInputType } from "../../../helper/dataSource/DataSourceInputType";

import {
  iconExtractor,
  IconExtractorType,
} from "../../../helper/iconExtractor";
import {
  DataSource,
  DataSourceType,
} from "../../../types/dataSource/dataSource";
import { getEstimatedSyncTime } from "./getEstimatedSyncTime";
import { ImportState } from "./ImportContext";

export const ImportStatusIndicator = ({
  selectedDataSource,
  importState,
  importType,
}: {
  selectedDataSource: DataSource;
  importState: NonNullable<ImportState>;
  importType: DataSourceInputType;
}) => {
  const estSyncTime = getEstimatedSyncTime(selectedDataSource);

  let text: {
    mainText: string;
    subText?: string;
    icon?: IconExtractorType;
    spinner?: boolean;
  };
  switch (importState) {
    case "complete":
      text = {
        mainText: "Import Successful",
        icon: "complete",
      };
      break;
    case "backgroundImporting":
      text = {
        mainText: `We're currently importing your transactions.
          ${estSyncTime ? `The estimated sync time ${estSyncTime}.` : ""}
          The sync will continue to run in the background.`,
        icon: "refresh",
      };
      break;
    case "initialising":
      text = {
        mainText: `Hold on while we check some things...`,
        subText: `Exciting stuff! You are well on your way to having your crypto taxes
        under control.`,
        spinner: true,
      };
      break;
    case "importing":
      text = {
        mainText: `Your ${
          selectedDataSource.type === DataSourceType.Custom
            ? ""
            : selectedDataSource.name
        } data is being imported as we speak`,
        subText: `Exciting stuff! You are well on your way to having your crypto taxes
        under control.`,
        spinner: true,
      };
      break;
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      h="100%"
      m="10px 25px"
    >
      {text.icon && <Image src={iconExtractor(text.icon)} h="70px" />}
      {text.spinner && (
        <Spinner
          color="red.500"
          emptyColor="red.200"
          my="10px"
          thickness="5px"
          size="xl"
        />
      )}
      <Text
        fontSize="1.375rem"
        fontWeight="bold"
        color="black.900"
        my="30px"
        textAlign="center"
      >
        {text.mainText}
      </Text>
      {text.subText && (
        <Text
          fontSize="1rem"
          color="black.600"
          mb="20px"
          textAlign="center"
          whiteSpace="pre-line"
        >
          {text.subText}
        </Text>
      )}
    </Flex>
  );
};
