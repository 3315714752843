import React from "react";
import { Flex } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";

import { getRecentOrders } from "../../../../api/order/getRecentOrders";
import { buildTransactionsLink } from "../../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { getAccountTransactionsCacheKey } from "../../../../store/transactionsStore";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { ContentContainer } from "../../../atoms/Containers";
import { EmptyDataMessage } from "../../../atoms/EmptyDataMessage";
import { SectionHeading } from "../../../atoms/Headings";
import { SkeletonRect } from "../../../atoms/Skeletons";

import { RecentTransactionTableHeader } from "./RecentTransactionTableHeader";
import { RecentTransactionTableRow } from "./RecentTransactionTableRow";

export const RecentTransactionTable = () => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // useQuery to load recent orders
  const { isLoading: isLoadingOrders, data: orderResponses } = useQuery(
    [...getAccountTransactionsCacheKey(accountId), "recent-orders"],
    () => getRecentOrders(accountId),
    {}
  );

  return (
    <ContentContainer>
      <Flex
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        pr="30px"
      >
        <SectionHeading>Recent Transactions</SectionHeading>
        <NavLink to={buildTransactionsLink({ accountId })}>
          <ButtonVariant content="View All" outlineType="ghost" />
        </NavLink>
      </Flex>
      <SkeletonRect isLoaded={!isLoadingOrders}>
        <Flex overflowX="auto" direction="column" w="auto">
          <RecentTransactionTableHeader />
          {orderResponses ? (
            orderResponses.map((orderResponse) => (
              <RecentTransactionTableRow
                key={orderResponse.order._id}
                orderResponse={orderResponse}
              />
            ))
          ) : (
            <Flex justifyContent="center" alignItems="center" h="60%">
              <EmptyDataMessage content="Add a data source to see your recent transactions" />
            </Flex>
          )}
        </Flex>
      </SkeletonRect>
    </ContentContainer>
  );
};
