export const colors = {
  gray: {
    25: "#FCFCFC",
    50: "#FAFAFA",
    100: "#F4F4F5",
    200: "#E4E4E7",
    300: "#D1D1D6",
    400: "#A0A0AB",
    500: "#70707B",
    600: "#51525C",
    700: "#3F3F46",
    800: "#26272B",
    900: "#18181B",
  },
  primary: {
    25: "#FFF7F5",
    50: "#FFEFEB",
    100: "#FFDFD6",
    200: "#FFCDBF",
    300: "#FF9F85",
    400: "#FF7752",
    500: "#FF5426",
    600: "#FF3600",
    700: "#DE2F00",
    800: "#C12900",
    900: "#A82400",
  },
  error: {
    25: "#FEF6F6",
    50: "#FDF2F2",
    100: "#FCEDED",
    200: "#F8D3D3",
    300: "#F1A7A7",
    400: "#E44E4E",
    500: "#DF3030",
    600: "#B11B1B",
    700: "#851414",
    800: "#580E0E",
    900: "#2C0707",
  },
  warning: {
    25: "#FFFCF6",
    50: "#FEFAF2",
    100: "#FEF8EC",
    200: "#FCEDCF",
    300: "#F9DB9F",
    400: "#F4B63E",
    500: "#F2A818",
    600: "#C1830B",
    700: "#916208",
    800: "#614205",
    900: "#302103",
  },
  success: {
    25: "#F5FEFE",
    50: "#F1FEFE",
    100: "#EBFDFD",
    200: "#CFFBF2",
    300: "#A0F7E6",
    400: "#68F4C9",
    500: "#0EF1BC",
    600: "#0BC196",
    700: "#089171",
    800: "#06614B",
    900: "#033026",
  },

  // TODO: the following color usages are decprecated and should be migrated
  //  away from the codebase
  red: {
    50: "#FFF6F4",
    75: "#FFEFEB",
    100: "#FCEDED",
    200: "#FFD7CC",
    300: "#FFAF99",
    500: "#FF3600",
    700: "#E03100",
    900: "#DF3030",
    1000: "#CC2B00",
  },
  blue: {
    500: "#2A84FF",
  },
  yellow: {
    100: "#FEF8EC",
    500: "#F9DB9F",
    900: "#916208",
  },
  green: {
    100: "#EBFDFD",
    500: "#0BC196",
    700: "#089171",
    800: "#089710",
    900: "#06614B",
  },
  black: {
    1000: "#000000",
    900: "#1A1A1A",
    800: "#333333",
    700: "#4D4D4D",
    750: "#4C4C4C",
    600: "#666666",
    550: "#808080",
    500: "#999999",
    400: "#CBD5E0",
    300: "#CCCCCC",
    200: "#E5E5E5",
  },
  white: {
    0: "#FFFFFF",
    100: "#FBFBFB",
    200: "#FAFAFA",
    500: "#F5F5F5",
    700: "#F7F7F7",
    1000: "#F0F0F0",
  },
};
