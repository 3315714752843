import { Text, TextProps, Tooltip, ResponsiveValue } from "@chakra-ui/react";
import { Decimal } from "@syla/shared/decimal";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import * as CSS from "csstype";
import React, { useRef, useEffect, useState } from "react";

interface NumberStylerProps extends TextProps {
  num?: number | string | Decimal;
  unit?: "unit" | "currency" | "percentage";
  assetInfo?: { code: string; type: AssetType };
  signed?: boolean;
  hidePositiveSign?: boolean;
  decimal?: number;
  colored?: boolean;
  negativeColored?: boolean;
  bgColored?: boolean;
  textAlign?: ResponsiveValue<CSS.Property.TextAlign>;
  rightUnit?: string;
  positive?: boolean;
  // Whether to set the font color to green when the value is equal to 0
  greenZeroValue?: boolean;
  smartRound?: boolean;
  maxLength?: number; // ellipsis by exceed this number
}

const returnValueStylingProps = ({
  num,
  bgColored,
  unit,
  signed,
  hidePositiveSign,
  greenZeroValue,
  smartRound,
  assetInfo,
  maxLength,
}: {
  num: number | Decimal | string | undefined;
  bgColored: boolean;
  unit: NumberStylerProps["unit"];
  signed: boolean;
  hidePositiveSign: boolean;
  greenZeroValue: boolean | undefined;
  smartRound?: boolean;
  assetInfo?: NumberStylerProps["assetInfo"];
  maxLength?: number;
}) => {
  if (num == null) {
    return {
      color: "black.750",
      bgColor: "",
      content: "---",
    };
  }

  if (!(num instanceof Decimal)) {
    num = Decimal.from(num.toString());
  }

  const isPositive = num.isNonZeroPositive();

  let color = "black.750";
  let bgColor = "";

  if (isPositive || (greenZeroValue && num.isZero())) {
    bgColor = "green.100";
    if (bgColored) {
      color = "green.800";
    } else {
      color = "green.500";
    }
  } else if (!num.eq("0")) {
    bgColor = "red.100";
    color = "red.900";
  }

  let { text: formattedNumber, isSummarised } = num.format({
    currencyCode: unit == "currency" ? "AUD" : undefined,
    fixedPointDigits:
      assetInfo?.type == "currency" ||
      unit == "currency" ||
      unit == "percentage"
        ? 2
        : undefined,
    hideNegativeSign: !signed,
    showPositiveSign: signed && !hidePositiveSign,
    separateThousands: true,
    indicateNonZero: true,
    smartRound,
  });

  const { text: fullNumber } = num.format({
    currencyCode: unit == "currency" ? "AUD" : undefined,
    separateThousands: true,
    smartRound: {
      minSignificantDigits: 4,
    },
  });

  const numberSuffix = unit == "percentage" ? "%" : "";

  if (maxLength && formattedNumber.length > maxLength) {
    formattedNumber = `${formattedNumber.substring(0, maxLength)}..`;
    isSummarised = true;
  }

  const content = `${formattedNumber}${numberSuffix}`;
  const fullContent = `${fullNumber}${numberSuffix}`;

  return { color, bgColor, content, fullContent, isSummarised };
};

export const NumberStyler = ({
  num,
  unit = "unit",
  signed = true,
  hidePositiveSign = true,
  colored = false,
  negativeColored = false,
  bgColored = false,
  textAlign,
  rightUnit = "",
  greenZeroValue,
  smartRound,
  assetInfo,
  maxLength = undefined,
  ...props
}: NumberStylerProps): JSX.Element => {
  const { color, content, fullContent, bgColor, isSummarised } =
    returnValueStylingProps({
      num,
      bgColored,
      unit,
      signed,
      hidePositiveSign,
      greenZeroValue,
      smartRound,
      assetInfo,
      maxLength,
    });

  const numberElement = useRef<HTMLParagraphElement>(null);
  const [overflowed, setOverflowed] = useState(false);

  useEffect(() => {
    const calculateOverflow = () => {
      const overflowed =
        numberElement.current?.scrollHeight !=
        numberElement.current?.clientHeight;
      setOverflowed(overflowed);
    };
    calculateOverflow();

    // overflow status may change when window is resized.
    window.addEventListener("resize", calculateOverflow);

    // clean up event listener
    return () => {
      window.removeEventListener("resize", calculateOverflow);
    };
  });

  return (
    <Tooltip
      label={
        isSummarised
          ? fullContent
          : overflowed
          ? `${content} ${rightUnit}`
          : undefined
      }
    >
      <Text
        ref={numberElement}
        color={
          colored || (negativeColored && content.match(/^-/))
            ? color
            : "black.750"
        }
        textAlign={textAlign}
        bgColor={bgColored ? bgColor : "transparent"}
        px={bgColored ? "10px" : "0"}
        borderRadius="5px"
        noOfLines={1}
        fontSize="0.875rem"
        {...props}
      >
        {`${content} ${rightUnit}`}
      </Text>
    </Tooltip>
  );
};
