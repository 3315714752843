import axios from "axios";

import { FinancialYearOption } from "../../types/user/financialYearOption";

export const getAccountFinancialYears = async (
  accountId: string
): Promise<FinancialYearOption[]> => {
  const { data } = await axios.get(
    `/accounts/${accountId}/get-financial-years`
  );

  // need to parse the financial year dates again for some reason, backend sends them as Date objects but front end receives them as strings (weird)
  return (data as FinancialYearOption[]).map((financialYear) => ({
    label: financialYear.label,
    value: financialYear.value,
    startDate: new Date(financialYear.startDate),
    endDate: new Date(financialYear.endDate),
  }));
};
