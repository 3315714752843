import { Button, Text, Flex, Spacer, ButtonProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { FaCaretDown } from "react-icons/fa";

interface MultiSelectBtnProps {
  selectedOptions: string[];
  isOpen: boolean;
  onClickBtn: () => void;
  placeholder: string;
  disabled: boolean;
  selectBtnProps?: ButtonProps;
}

export const MultiSelectBtn = ({
  selectedOptions,
  isOpen,
  onClickBtn,
  placeholder,
  disabled,
  selectBtnProps,
}: MultiSelectBtnProps) => (
  <SelectBtn
    selectedOptions={selectedOptions}
    isOpen={isOpen}
    disabled={disabled}
    onClick={onClickBtn}
    selectBtnProps={selectBtnProps}
  >
    <Flex alignItems="center">
      <Text mr="10px" fontWeight="500" fontSize="0.875rem" color="black.550">
        {placeholder}
      </Text>
      <Spacer />
      {!disabled && <FaCaretDown color="#808080" />}
    </Flex>
  </SelectBtn>
);

const SelectBtn = styled(Button, {
  shouldForwardProp: (prop) =>
    ![
      "isOpen",
      "selectedOptions",
      "selectedIndication",
      "selectBtnProps",
    ].includes(prop as string),
})((props) => ({
  height: "50px",
  backgroundColor: props.selectedOptions.length === 0 ? "#fff" : "#f5f5f5",
  borderWidth: "1px",
  borderColor:
    props.isOpen || props.selectedOptions.length !== 0 ? "#000" : "gray.300",
  textAlign: "left",
  borderRadius: "5px",
  padding: "0 15px",
  display: "inline-block",
  cursor: props.disabled ? "not-allowed" : "pointer",
  alignItems: "center",
  "&:hover": {
    backgroundColor: props.isOpen && !props.disabled ? "#fff" : "#f5f5f5",
  },
  "&:active": { backgroundColor: "#e5e5e5" },
  "&:focus": {
    borderColor: !props.disabled ? "#000" : "#808080",
  },
  "&:visited": {
    backgroundColor: "#fff !important",
  },
  ...props.selectBtnProps,
}));
