import { Text, Input } from "@chakra-ui/react";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { hasAccountPermission } from "../../../helper/permissions";
import { useReportError } from "../../../helpers/useReportError";
import { useMutationUpdateAccount } from "../../../store/useMutationUpdateAccount";
import { Account } from "../../../types/Account";
import {
  EditableFieldProps,
  EditableField,
} from "../../molecules/EditableField";

export function AccountEmailEditable({
  account,
  ...props
}: { account: Account } & Partial<EditableFieldProps>) {
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(account._id);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const reportError = useReportError();

  const canChangeAccountDetails = hasAccountPermission(
    account,
    AccountPermission.ChangeAccountDetails
  );

  const { watch, register, formState, resetField } = useFormContext<{
    contactEmail: string;
  }>();
  return (
    <EditableField
      displayElement={<Text>{watch("contactEmail")}</Text>}
      editElement={<Input autoFocus {...register("contactEmail")} />}
      isDirty={formState.dirtyFields.contactEmail}
      isLoading={updateInProgress}
      onSave={async () => {
        try {
          setUpdateInProgress(true);
          await updateAccount({
            contactEmail: watch("contactEmail"),
          });
        } finally {
          setUpdateInProgress(false);
        }
      }}
      validate={() =>
        !watch("contactEmail") || isEmail(watch("contactEmail").trim())
          ? undefined
          : "Invalid email address"
      }
      onCancel={() => resetField("contactEmail")}
      onError={reportError}
      canEdit={canChangeAccountDetails}
      {...props}
    />
  );
}
