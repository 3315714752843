import {
  Text,
  Input,
  FormControl,
  FormErrorMessage,
  Button,
  Box,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import { UserContext } from "../../../contexts/UserContext";
import { useNavigator, Route } from "../../../routers/navigator";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { SignInFormContainer } from "../../atoms/Containers";
import { GoogleSignInForm } from "../GoogleSignInForm";

import { Form, Label } from "./FormComponents";
import { SylaFormHeader } from "./SylaFormHeader";

interface SignInHookForm {
  userEmail: string;
  userPassword: string;
}

export const SignInForm = (): JSX.Element => {
  // get the login function from the user context
  const { login } = useContext(UserContext);
  const navigate = useNavigator();

  // state for sign in error
  const [signInError, setSignInError] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<SignInHookForm>();

  const onSubmit = async (data: SignInHookForm) => {
    if (!data.userEmail || !data.userPassword) return;
    // reset sign in error
    setSignInError(undefined);
    const { userEmail, userPassword } = data;

    try {
      const { defaultAccountId } = await login(userEmail, userPassword);
      navigate({
        route: Route.Home,
        accountId: defaultAccountId,
        replace: true,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (
        axiosError.response?.status === 404 ||
        axiosError.response?.status === 401
      ) {
        setSignInError("Incorrect email or password");
        return;
      }
      setSignInError("Unexpected error occurred, please try again later");
    }
  };

  const history = useHistory();

  return (
    <SignInFormContainer>
      <Box px="8px">
        <SylaFormHeader heading={"Sign in"} subText={"to your Syla Account"} />
      </Box>
      {/* Google sign-in support */}
      <GoogleSignInForm />
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box px="8px">
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors?.userEmail !== undefined}
            mb="20px"
          >
            <Label content="Email address" />
            <Input
              type="email"
              placeholder="Type your email"
              {...register("userEmail", {
                required: "Email is missing",
                validate: (email?: string) =>
                  isEmail(email || "") ? undefined : "Invalid email address",
              })}
              bgColor="white.0"
            />
            {errors.userEmail && (
              <FormErrorMessage>{errors.userEmail.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors?.userPassword !== undefined}
            mb="30px"
          >
            <Label content="Password" />
            <Input
              type="password"
              placeholder="Type your password"
              {...register("userPassword", { required: "Password is missing" })}
              bgColor="white.0"
            />
            {errors.userPassword && (
              <FormErrorMessage>{errors.userPassword.message}</FormErrorMessage>
            )}
          </FormControl>
          <ButtonVariant
            content="Sign in"
            color="red"
            onClick={() => {}}
            disabled={isSubmitting}
            isLoading={isSubmitting}
            spam="spam"
            type="submit"
          />
          {/* display sign in error message if an error occurred */}
          {signInError && (
            <FormControl isInvalid={signInError !== undefined}>
              <FormErrorMessage>{signInError}</FormErrorMessage>
            </FormControl>
          )}
          <Text mt="20px">
            <Button
              color="red.500"
              disabled={isSubmitting}
              onClick={() =>
                navigate({
                  route: Route.ForgotPassword,
                  state: {
                    email: getValues("userEmail"),
                  },
                })
              }
              variant="link"
            >
              Forgot your password?
            </Button>
          </Text>
          <Text mt="20px">
            {"Are you new here? "}
            <Button
              color="red.500"
              disabled={isSubmitting}
              onClick={() => history.push("/sign-up")}
              variant="link"
            >
              Sign up today
            </Button>
          </Text>
        </Box>
      </Form>
    </SignInFormContainer>
  );
};
