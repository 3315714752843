import axios from "axios";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetAccountAssetsResponse } from "@syla/shared/types/responses/GetAccountAssetsResponse";

export type GetAccountAssetsData = TransportType<
  GetAccountAssetsResponse<string>
>;

export const getAccountAssets = async (
  accountId: string
): Promise<GetAccountAssetsData> => {
  const { data } = await axios.get(`/accounts/${accountId}/assets`);
  return data;
};
