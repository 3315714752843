import { UpdateAccountAccessRequestBody } from "@syla/shared/types/requests/UpdateAccountAccessRequest";
import axios from "axios";

export type UpdateAccountAccess = UpdateAccountAccessRequestBody<string>;
export const updateAccountAccess = async (
  accountId: string,
  props: UpdateAccountAccess
): Promise<void> => {
  const { data } = await axios.patch(`/accounts/${accountId}/access`, props);
  return data;
};
