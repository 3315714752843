import { Thead, TableHeadProps } from "@chakra-ui/react";
import React from "react";

export const HEADER_Z_INDEX = 2;

export const StickyThead = (props: TableHeadProps) => (
  <Thead
    position="sticky"
    top={0}
    zIndex={HEADER_Z_INDEX} // high enough to render over table rows for sticky header
    bgColor="white"
    boxShadow="sm"
    {...props}
  />
);
