import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { BulkEditRequestBody } from "@syla/shared/types/requests/BulkEditRequest";
import { BulkEditResponse } from "@syla/shared/types/responses/BulkEditResponse";
import axios from "axios";

export type BulkEditRequestProps = {
  accountId: string;
} & BulkEditRequestBody;

export const bulkEdit = async ({
  accountId,
  ...requestProps
}: BulkEditRequestProps): Promise<BulkEditResponse> => {
  const { data: responseData }: { data: TransportType<BulkEditResponse> } =
    await axios.post(
      `/accounts/${accountId}/transactions/bulk-edit`,
      requestProps
    );

  return responseData;
};
