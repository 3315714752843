import { Decimal } from "../../decimal";
import { Id } from "../../types/helpers/Id";
import { ValueAssetsResponseBody } from "../../types/responses/ValueAssetsResponse";

export class AssetValueService<TId extends { toString: () => string }> {
  public readonly baseAsset: Id<TId>;
  public readonly valueDate: Date;
  private readonly _rateLookup: Map<string, Decimal>;

  constructor({ baseAsset, valueDate, rates }: ValueAssetsResponseBody<TId>) {
    this.baseAsset = baseAsset;
    this.valueDate = valueDate;
    this._rateLookup = new Map(
      rates.map(({ asset: { _id }, rate }) => [_id.toString(), rate])
    );
  }

  public getValue(props: {
    asset: Id<TId>;
    amount: Decimal;
  }): Decimal | undefined {
    const rate = this._rateLookup.get(props.asset._id.toString());
    if (!rate) return undefined;

    return props.amount.times(rate);
  }
}
