import {
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
  FormHelperText,
} from "@chakra-ui/react";
import { AccountAccessRole } from "@syla/shared/types/models/AccountAccessBase";
import React, { useCallback, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { Form } from "../../components/molecules/forms/FormComponents";
import { ModalContext } from "../../contexts/ModalContext";
import { useReportError } from "../../helpers/useReportError";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useMutationInviteAccountMember } from "../../store/useMutationInviteAccountMember";
import { AccountAccessRoleSelector } from "./AccountAccessRoleSelector";

export type InviteMemberForm = {
  email: string;
  role: AccountAccessRole;
};
export function InviteMemberModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { openModal, closeModal, modalIsOpen } = useContext(ModalContext);

  // wrap modal state
  useEffect(() => {
    console.log({ closeModal, isOpen, onClose, openModal });
    if (isOpen)
      openModal({
        type: "component",
        heading: "Invite Member",
        component: <InviteMemberForm onClose={onClose} />,
      });
    else {
      closeModal();
    }
  }, [isOpen, closeModal, onClose, openModal]);

  // set parent state when closing
  useEffect(() => {
    if (!modalIsOpen) onClose();
  }, [modalIsOpen, onClose]);

  return null;
}

function InviteMemberForm({ onClose }: { onClose: () => void }) {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
  } = useForm<InviteMemberForm>({
    defaultValues: {
      role: AccountAccessRole.Editor,
    },
  });

  const { mutateAsync: inviteMember, isLoading: inviteIsLoading } =
    useMutationInviteAccountMember(accountId);

  const reportError = useReportError();

  const onSubmit = useCallback(
    async ({ email, role }: InviteMemberForm) => {
      try {
        await inviteMember({
          members: [{ role, email }],
        });
        onClose();
      } catch (error) {
        reportError(error);
      }
    },
    [inviteMember, onClose, reportError]
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={6}>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            borderColor="#f5f5f5"
            _focus={{ boxShadow: "none", borderColor: "#000" }}
            type="email"
            placeholder="Enter email..."
            h="50px"
            w="100%"
            {...register("email", {
              required: true,
              validate: (value) =>
                !isEmail(value) ? "Invalid Email" : undefined,
            })}
          />
          {!errors.email?.message ? (
            <FormHelperText>The email of the user account</FormHelperText>
          ) : (
            <FormErrorMessage>{errors.email.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Role</FormLabel>
          <AccountAccessRoleSelector
            currentRole={watch("role")}
            onSelected={(value) =>
              setValue("role", value, { shouldDirty: true })
            }
            width="100%"
          />
          <FormHelperText>
            Determines the access level on the account
          </FormHelperText>
        </FormControl>

        <VStack w="100%">
          <ButtonVariant
            content="Invite"
            type="submit"
            spam="spam"
            onClick={handleSubmit(onSubmit)}
            color="red"
            isLoading={inviteIsLoading}
          />
        </VStack>
      </VStack>
    </Form>
  );
}
