import { useContext } from "react";
import { useReportError } from "../../../helpers/useReportError";
import { ModalContext } from "../../../contexts/ModalContext";
import { useMutationDeleteAccount } from "../../../store/useMutationDeleteAccount";
import { Account } from "../../../types/Account";

export function useDeleteAccountDialog({ account }: { account: Account }) {
  const { openModal, setBtn2Loading, closeModal } = useContext(ModalContext);
  const { mutateAsync: deleteAccount } = useMutationDeleteAccount(account._id);

  // error reporting
  const reportError = useReportError();

  return {
    openDeleteAccountDialog: () =>
      openModal({
        type: "confirmation",
        icon: "delete",
        heading: `Are you sure you want to delete the account "${account.fullName}"?`,
        contents: [
          "This action is irreversible and will delete all of your transaction and tax history for this account.",
          "Any active subscriptions will also be terminated.",
        ],
        confirmation: {
          prompt: "Type the name of the account you want to delete",
          answer: account.fullName,
        },
        btn2Content: "Delete",
        btn2Action: async () => {
          try {
            setBtn2Loading(true);
            await deleteAccount();
            openModal({
              type: "action",
              icon: "delete",
              heading: "Account Deleted",
              btn1Content: "Done",
              hideCloseButton: true,
              dontCloseOnOverlayClick: true,
            });
            setBtn2Loading(false);
          } catch (error) {
            reportError(error);
            closeModal();
            console.log(error);
          }
        },
      }),
  };
}
