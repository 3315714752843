import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Text,
  Link,
  Box,
  HStack,
  VStack,
  Button,
  Stack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { parseDate, parseOptional } from "@syla/shared/types/helpers/parsing";
import {
  WalletImportMethod,
  SyncState,
} from "@syla/shared/types/models/WalletBase";
import React from "react";
import { MdLoop } from "react-icons/md";
import {
  PageContainer,
  PageHeadingContainer,
  StdVStack,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { TooltipWrapper } from "../components/atoms/TooltipWrapper";
import {
  BalancesTable,
  ViewBy,
} from "../components/organisms/balances/BalancesTable";
import { useLocalStorage } from "../hooks/localStorage/useLocalStorage";
import { ReactComponent as AssetIcon } from "../images/icons/asset.svg";
import { ReactComponent as WalletIcon } from "../images/icons/wallet.svg";
import { reSyncWallets } from "../store/actions/reSyncWallets";
import { useGetWallets } from "../store/actions/wallet";
import { useCurrentAccountStore } from "../store/currentAccountStore";
import { BalancesDatePicker } from "../components/organisms/balances/BalancesDatePicker";

export function Balances() {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const [date, setDate] = useLocalStorage<string | undefined>(
    "balancesDate",
    undefined
  );
  const [viewBy, setViewBy] = useLocalStorage<ViewBy>(
    "balancesViewBy",
    "dataSource"
  );

  const { data: walletHoldingsResponse } = useGetWallets(accountId);

  const smallScreen = useBreakpointValue({
    base: true,
    "2xs": true,
    xs: true,
    sm: true,
    md: false,
  });

  return (
    <PageContainer>
      <PageHeadingContainer>
        <StdVStack spacing={6}>
          <StdVStack>
            <PageHeading>Balances</PageHeading>
            <Text color="gray.800">
              Analyse your current and historical balances across all data
              sources.
            </Text>
          </StdVStack>
          <Link
            href="https://help.syla.com.au/en/articles/9488227-balances-guide"
            fontWeight="bold"
            color="primary.500"
            isExternal
          >
            Guide to balances <ExternalLinkIcon />
          </Link>
        </StdVStack>
        <Box>
          <TooltipWrapper tooltip="Get latest data from all eligible data sources">
            <Button
              variant="outline"
              leftIcon={<MdLoop />}
              isDisabled={!walletHoldingsResponse}
              isLoading={walletHoldingsResponse?.wallets.some(
                (wallet) => wallet.syncState == SyncState.InProgress
              )}
              onClick={(event) => {
                event.stopPropagation();
                reSyncWallets(
                  accountId,
                  walletHoldingsResponse!.wallets.filter(
                    (wallet) => wallet.importMethod == WalletImportMethod.API
                  )
                );
              }}
            >
              Sync All
            </Button>
          </TooltipWrapper>
        </Box>
      </PageHeadingContainer>

      <StdVStack spacing={6}>
        <Stack
          direction={smallScreen ? "column" : "row"}
          gridGap={smallScreen ? 5 : 20}
        >
          <BalancesDatePicker
            date={parseOptional(date, parseDate)}
            onChange={(value) => {
              setDate(value?.toISOString());
            }}
          />
          <VStack alignItems="flex-start">
            <Text>View by</Text>
            <HStack>
              <Button
                variant="outline"
                leftIcon={<WalletIcon />}
                isActive={viewBy == "dataSource"}
                onClick={() => setViewBy("dataSource")}
              >
                Data Source
              </Button>
              <Button
                variant="outline"
                leftIcon={<AssetIcon />}
                isActive={viewBy == "asset"}
                onClick={() => setViewBy("asset")}
              >
                Asset
              </Button>
            </HStack>
          </VStack>
        </Stack>
        <BalancesTable
          balanceDate={date ? parseDate(date) : undefined}
          viewBy={viewBy!}
          smallScreen={smallScreen}
        />
      </StdVStack>
    </PageContainer>
  );
}
