import { Flex, Text } from "@chakra-ui/react";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { groupBy, map } from "lodash";
import React, { useMemo } from "react";

import type { Decimal } from "@syla/shared/decimal";
import { sumWithDecimal } from "@syla/shared/decimal";
import { NumberStyler } from "../../../../helper/NumberStyler";
import { Asset } from "../../../../types/asset/asset";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";

export const TradeSummaryList = <T extends { asset: Asset; amount: Decimal }>({
  allLedgers,
}: {
  allLedgers: T[];
}): JSX.Element => {
  const groupedAssetLedgers = useMemo(() => {
    // Group by asset Id's so they can be summed
    const groupedLedgers = groupBy(allLedgers, (to) => to.asset._id);

    // Loop over each asset and calculate the total
    return map(groupedLedgers, (assetLedgers) => {
      const totalAmount = sumWithDecimal(assetLedgers, "amount");

      return { amount: totalAmount, asset: assetLedgers[0].asset };
    });
  }, [allLedgers]);

  return (
    <Flex direction="row" mt="10px" overflow="hidden">
      {map(groupedAssetLedgers, (ledger) => (
        <Flex
          key={ledger.asset._id}
          alignItems="center"
          mb="10px"
          overflow="hidden"
        >
          <ImageWithMissingSrc
            src={ledger.asset.image}
            alt=""
            height="21px"
            mx="10px"
          />
          <NumberStyler
            num={ledger.amount}
            color="black.700"
            fontSize="0.875rem"
            mr="3px"
            assetInfo={ledger.asset}
            smartRound
          />
          <Text color="black.700" fontSize="0.875rem">
            {getAssetShortName(ledger.asset)}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};
