import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetAffiliateAccountStatusResponseBody } from "@syla/shared/types/responses/GetAffiliateAccountStatus";
import axios from "axios";
import { useQuery } from "react-query";

export const useQueryGetAffiliateAccountStatus = () =>
  useQuery(["affiliate-account"], getAffiliateAccountStatus);

export const getAffiliateAccountStatus = async (): Promise<
  GetAffiliateAccountStatusResponseBody<string>
> => {
  const { data } = await axios.get<
    TransportType<GetAffiliateAccountStatusResponseBody<string>>
  >("/users/affiliate-account-status");

  return data;
};
