import { Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { validatePassword } from "@syla/shared/helpers/validatePassword";
import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { PasswordRequirements } from "../../../atoms/PasswordRequirements";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { Form, GeneralInput } from "../FormComponents";

export type NewPasswordProps = {
  currentPassword?: string;
  newPassword: string;
};

interface PasswordChangeForm {
  currentPassword?: string;
  newPassword: string;
  confirmPassword: string;
}

export const UserPasswordForm = ({
  currentPassword,
  onPasswordChangeRequest,
  confirmPassword,
  passwordLabel = "New password",
}: {
  currentPassword: boolean;
  confirmPassword: boolean;
  onPasswordChangeRequest: (props: NewPasswordProps) => Promise<void> | void;
  passwordLabel?: string;
}) => {
  const [inputError, setInputError] = useState<string | undefined>();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<PasswordChangeForm>();

  const onSubmit = async (data: PasswordChangeForm) => {
    const { newPassword, currentPassword } = data;
    setInputError(undefined);

    // check if current password is correct and check if new password is the same as current password
    try {
      if (currentPassword && !currentPassword)
        throw new Error("Current password required");

      await onPasswordChangeRequest({ newPassword, currentPassword });
      return;
    } catch (error) {
      const status = axios.isAxiosError(error) && error.response?.status;
      const data = axios.isAxiosError(error) && error.response?.data;

      setInputError(
        (status && status >= 400 && status < 500 && data) ||
          "Unexpected error occurred, please try again later"
      );
    }
  };
  return (
    <Flex direction="column" w="100%">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {currentPassword && (
          <GeneralInput
            label="Current password"
            type="password"
            isSubmitting={isSubmitting}
            {...register("currentPassword", {
              required: "Current password is missing",
            })}
            fieldError={errors.currentPassword}
            isRequired
          />
        )}
        <GeneralInput
          label={passwordLabel}
          type="password"
          isSubmitting={isSubmitting}
          {...register("newPassword", {
            required: "New password is missing",
            validate: (password?: string) =>
              validatePassword(password || "")
                ? undefined
                : "Password does not meet requirements",
          })}
          fieldError={errors.newPassword}
          isRequired
        />
        {errors.newPassword && PasswordRequirements()}
        {confirmPassword && (
          <GeneralInput
            label="Confirm password"
            type="password"
            isSubmitting={isSubmitting}
            {...register("confirmPassword", {
              required: "Confirm password is missing",
              validate: (password?: string) =>
                password === getValues("newPassword")
                  ? undefined
                  : "Password does not match",
            })}
            fieldError={errors.confirmPassword}
            isRequired
          />
        )}

        {inputError && (
          <FormControl isInvalid={inputError !== undefined}>
            <FormErrorMessage>{inputError}</FormErrorMessage>
          </FormControl>
        )}

        <ButtonVariant
          content="Save changes"
          outlineType="solid"
          color="red"
          onClick={() => undefined}
          isLoading={isSubmitting}
          type="submit"
          spam="spam"
          mt="15px"
          mb="10px"
        />
      </Form>
    </Flex>
  );
};
