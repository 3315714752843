import { minutesToMilliseconds } from "date-fns";
import { useQuery } from "react-query";
import { getDataSources } from "../api/dataSource/getDataSources";

const DATA_SOURCES_CACHE_KEY = "data-sources";

export const useQueryDataSources = ({ enabled }: { enabled?: boolean } = {}) =>
  useQuery([DATA_SOURCES_CACHE_KEY], () => getDataSources(), {
    staleTime: minutesToMilliseconds(30),
    enabled,
  });
