import { InfoIcon } from "@chakra-ui/icons";
import { ListProps } from "@chakra-ui/react";

export const needsReviewIconProps = {
  as: InfoIcon,
  color: "yellow.500",
};

export const needsReviewListProps: ListProps = {
  w: "100%",
  spacing: 3,
  backgroundColor: "yellow.100",
  padding: "5px",
  borderRadius: "5px",
};

export const transactionTooltipText =
  "Syla has some suggestions to help improve your tax outcome";

export const sylaSuggestionsText = "Syla Suggestions";
