import { Id } from "@syla/shared/types/helpers/Id";
import { StripeBillingPortalAction } from "@syla/shared/types/requests/CreateStripeBillingPortalRequest";
import { createStripeBillingPortalSession } from "../api/plans/createStripeBillingPortalSession";
import { createSubscription } from "../api/plans/createSubscription";
import { captureRequestError } from "../helper/captureRequestError";
import { redirectTo } from "../helper/RedirectTo";

/**
 * Opens a checkout session with the selected plan
 */
export const stripePurchaseOrUpgrade = async ({
  accountId,
  cancelUrl,
  financialYear,
  plan,
  currentPlan,
  stripeSubscriptionId,
  successUrl,
}: {
  accountId: string;
  successUrl: string;
  plan: Id<string>;
  currentPlan?: Id<string>;
  stripeSubscriptionId?: string;
  financialYear?: string;
  cancelUrl?: string;
}) => {
  let redirectUrl: string;
  // existing subscription
  try {
    if (stripeSubscriptionId) {
      const response = await createStripeBillingPortalSession(accountId, {
        action:
          plan._id == currentPlan?._id
            ? StripeBillingPortalAction.Renew
            : StripeBillingPortalAction.Upgrade,
        stripeSubscriptionId: stripeSubscriptionId,
        financialYear,
        selectedPlanId: plan._id,
        returnUrl: successUrl,
      });
      redirectUrl = response.portalUrl;
    } else {
      const response = await createSubscription(accountId, {
        planId: plan._id,
        successUrl: successUrl,
        cancelUrl: cancelUrl ?? successUrl,
        financialYear,
      });
      redirectUrl = response.checkoutUrl;
    }
  } catch (error) {
    captureRequestError(error);
    throw error;
  }

  // navigate to complete checkout
  redirectTo(redirectUrl);
};
