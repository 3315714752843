import {
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useToast,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import { WalletImportMethod } from "@syla/shared/types/models/WalletBase";
import React, { useContext, useState, useMemo } from "react";

import { ModalContext } from "../../../../contexts/ModalContext";
import { NumberStyler } from "../../../../helper/NumberStyler";

import { sortAssetHoldings } from "../../../../helper/wallet/sortAssetHoldings";
import { walletStateProps } from "../../../../helper/walletProps";
import { useNavigator, Route } from "../../../../routers/navigator";
import { reSyncWallets } from "../../../../store/actions/reSyncWallets";
import { deleteWallet } from "../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useValueHoldings } from "../../../../store/useValueHoldings";
import { DataSourceType } from "../../../../types/dataSource/dataSource";
import { WalletResponse } from "../../../../types/wallet/wallet";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";
import { RenderIcons } from "../../../atoms/RenderIcons";
import { SkeletonRect } from "../../../atoms/Skeletons";
import { DUMMY_HOLDINGS } from "../dummyData";
import { getDataSourceStatusProps } from "../getDataSourceStatusProps";
import { getEditDataSourceText } from "../getEditDataSourceText";
import { LastSyncedTime } from "../LastSyncedTime";
import { DataSourceExpanded } from "./dataSourceExpanded/DataSourceExpanded";
import { DataSourceMenuButton } from "./DataSourceMenuButton";
import { EditDataSourceDrawer } from "./EditDataSourceDrawer";
import { getWalletHoldingsValue } from "./getWalletHoldingsValue";

export const MainDataSourceTableRow = ({
  walletResponse,
}: {
  walletResponse: WalletResponse;
}): JSX.Element => {
  const toast = useToast();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const navigate = useNavigator();

  const { txCountLoading, holdingsLoading, syncInProgress } =
    walletStateProps(walletResponse);

  // state for the edit data source drawer
  const {
    isOpen: isEditDrawerOpen,
    onOpen: openEditDrawer,
    onClose: closeEditDrawer,
  } = useDisclosure();

  // state for file import in the edit drawer
  const [fileImport, setFileImport] = useState(false);

  // open edit drawer as file import handler
  const openEditDrawerFileImportHandler = () => {
    setFileImport(true);
    openEditDrawer();
  };

  // close edit drawer handler
  const closeEditDrawerHandler = () => {
    closeEditDrawer();
    setFileImport(false);
  };

  // get everything from the modal context
  const { openModal, closeModal, setBtn2Loading } = useContext(ModalContext);

  // delete wallet handler
  const deleteWalletHandler = async () => {
    try {
      setBtn2Loading(true);
      await deleteWallet(accountId, walletResponse._id);
      closeModal();
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error);
      toast({
        title: "Error deleting wallet",
        description: error?.response?.data || "Unexpected error.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    } finally {
      setBtn2Loading(false);
    }
  };

  // confirm delete handler
  const confirmDeleteWalletHandler = () =>
    openModal({
      type: "confirmation",
      icon: "delete",
      heading: "Delete Wallet",
      contents: [
        "This action is irreversible.",
        "This will also delete all associated transactions.",
      ],
      btn2Content: "Delete",
      btn2Action: deleteWalletHandler,
    });

  // boolean if this wallet was imported via api
  const allowApiSync = walletResponse.allowSync;

  const isCustomWallet =
    walletResponse.dataSource.type === DataSourceType.Custom;

  const allowEdit =
    (isCustomWallet || walletResponse.importMethod == WalletImportMethod.API) &&
    (walletResponse.dataSource.syncAuthType != "oauth" ||
      !walletResponse.authorised);

  const isBackgroundSync = syncInProgress && !walletResponse.authorised;

  // re-sync wallet handler
  const reSyncWalletHandler = async () => {
    // check if the wallet was imported via api
    if (!allowApiSync) return;

    reSyncWallets(accountId, [walletResponse]);
  };

  const statusProps = getDataSourceStatusProps(walletResponse);
  const { data: holdingsValue, isLoading: holdingsValueLoading } =
    useValueHoldings();

  const totalHoldingValue = useMemo(
    () =>
      getWalletHoldingsValue({
        walletWithHoldings: walletResponse,
        holdingsValue,
      }),
    [holdingsValue, walletResponse]
  );

  return (
    <>
      <Accordion
        allowToggle
        display="flex"
        flexDirection="row"
        w="auto"
        flexShrink={0}
        flexGrow={1}
        maxW="100%"
      >
        <AccordionItem
          border="none"
          mb="10px"
          display="flex"
          flexDirection="column"
          w="auto"
          flexShrink={0}
          flexGrow={1}
        >
          {/* ------------------------ DataSource Row ------------------------*/}
          <Flex
            justifyContent="space-between"
            borderWidth="1px"
            borderColor="white.500"
            flexShrink={0}
            flexGrow={1}
            boxShadow="md"
          >
            <AccordionButton
              bgColor="white.0"
              h="70px"
              display="flex"
              w="auto"
              flexShrink={0}
              flexGrow={1}
              _hover={{ backgroundColor: "white.0" }}
            >
              {/* ------------ Wallet column -----------------*/}
              <Flex flexGrow={3} alignItems="center" minW="250px">
                <ImageWithMissingSrc
                  src={walletResponse.dataSource.image}
                  alt=""
                  mr="10px"
                  w="50px"
                  minW="50px"
                  noPaddingIcon
                />
                <Flex direction="column" alignItems="flex-start">
                  <Flex direction="row" alignItems="center">
                    <Text mr="10px">
                      {walletResponse?.customName ||
                        walletResponse.dataSource?.name}
                    </Text>
                    {/* only show the green tick if the api data source has been synced in the last 24 hours */}
                    {statusProps.icon}
                  </Flex>
                  <Flex>
                    <Text color={statusProps.color} pr="5px" fontSize="0.75rem">
                      {statusProps.status}
                    </Text>
                    <LastSyncedTime
                      walletResponse={walletResponse}
                      lastUpdated={statusProps.lastUpdated}
                      whiteSpace="nowrap"
                      fontSize="0.75rem"
                    />
                  </Flex>
                </Flex>
              </Flex>
              {/* ------------ Transaction Number column -----------------*/}
              <Flex flexGrow={2} minW="150px">
                <SkeletonRect isLoaded={!txCountLoading}>
                  <Text
                    whiteSpace="nowrap"
                    color="red.500"
                    fontWeight="500"
                    cursor="pointer"
                    as="u"
                    onClick={() =>
                      // navigate to the transactions screen with the walletId as a location prop
                      navigate({
                        route: Route.Transactions,
                        accountId,
                        state: {
                          filter: {
                            dataSource: [walletResponse._id],
                          },
                        },
                      })
                    }
                  >
                    {walletResponse.transactionCount || 0} transactions
                  </Text>
                </SkeletonRect>
              </Flex>
              {/* ------------ Token icons column -----------------*/}
              <Flex flexGrow={3} minW="150px">
                <RenderIcons
                  assets={sortAssetHoldings(
                    walletResponse.holdings ?? DUMMY_HOLDINGS
                  ).map((holding) => holding.asset)}
                  qty={3}
                  isLoading={holdingsLoading}
                />
              </Flex>
              {/* ------------ Market Value column -----------------*/}
              <SkeletonRect
                isLoaded={!(holdingsValueLoading || holdingsLoading)}
              >
                <Flex
                  flexGrow={2}
                  minW="150px"
                  justifyContent="flex-end"
                  pr={{ base: "0", lx: "100px" }}
                >
                  <NumberStyler
                    num={totalHoldingValue}
                    unit="currency"
                    mr="15px"
                  />
                </Flex>
              </SkeletonRect>
            </AccordionButton>
            {/* ------------ 3 Dots Menu column -----------------*/}
            <Box>
              <DataSourceMenuButton
                allowApiSync={allowApiSync}
                syncDisabled={syncInProgress && !walletResponse.vezgoAccountId}
                allowEdit={!isBackgroundSync}
                editText={getEditDataSourceText(walletResponse)}
                syncOnClick={reSyncWalletHandler}
                editOnClick={openEditDrawer}
                importOnClick={openEditDrawerFileImportHandler}
                deleteOnClick={confirmDeleteWalletHandler}
                walletAddress={
                  [DataSourceType.Blockchain, DataSourceType.Wallet].includes(
                    walletResponse.dataSource.type
                  )
                    ? walletResponse.apiKey
                    : undefined
                }
              />
            </Box>
          </Flex>
          {/* ------------------ DataSource Expanded section ------------------------*/}
          <AccordionPanel p="0" display="flex" flexShrink={0} flexGrow={1}>
            <DataSourceExpanded
              walletResponse={walletResponse}
              openEditDrawer={openEditDrawer}
              allowSync={allowApiSync}
              allowEdit={allowEdit}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* ------------------ Edit Data Source Drawer ------------------ */}
      <EditDataSourceDrawer
        isOpen={isEditDrawerOpen}
        onClose={closeEditDrawerHandler}
        wallet={walletResponse}
        fileImport={fileImport}
      />
    </>
  );
};
