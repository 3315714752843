import {
  ActivityTypeName,
  AccountSubType,
  AccountType,
} from "@syla/shared/types/models/AccountBase";

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: AccountType.Individual,
    label: "Individual",
  },
  {
    value: AccountType.Trust,
    label: "Trust",
  },
  {
    value: AccountType.Company,
    label: "Company",
  },
  {
    value: AccountType.SMSF,
    label: "Self-Managed Super Fund (SMSF)",
  },
];
export const ACTIVITY_TYPE_OPTIONS = [
  {
    value: AccountSubType.Investor,
    label: ActivityTypeName[AccountSubType.Investor],
  },
  {
    value: AccountSubType.Trader,
    label: ActivityTypeName[AccountSubType.Trader],
  },
];
