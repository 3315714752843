import { Text, Flex, Box } from "@chakra-ui/react";
import { DataSourceType } from "@syla/shared/types/models/DataSourceBase";
import React, { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { DataSourceInputType } from "../../../../helper/dataSource/DataSourceInputType";
import { AddDataSourceForm } from "../../../../types/dataSource/dataSource";
import { AdvancedOptionsContainer } from "../../../atoms/AdvancedOptionsContainer";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { useImportContext } from "../ImportContext";
import { DataSourceNameInput } from "../DataSourceNameInput";
import { SyncFromDateInput } from "./SyncFromDateInput";

export const AdvancedOptions = ({
  disabled,
  captureName,
}: {
  captureName: boolean;
  disabled?: boolean;
}): JSX.Element | null => {
  const {
    state: { importType, importInProgress, dataSource },
    actions: { createEmptyWallet },
  } = useImportContext();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<AddDataSourceForm>();

  const items = useMemo(
    () =>
      [
        captureName && (
          <DataSourceNameInput
            registerProps={register("customName")}
            isDisabled={importInProgress}
            error={errors["customName"]?.message}
          />
        ),
        importType == DataSourceInputType.Api && <SyncFromDateInput />,
      ].filter(Boolean),
    [errors, importInProgress, importType, captureName, register]
  );

  const show =
    // hide Advanced options for Alpha import
    (importType != "Csv" || dataSource?.fileImportStatus != "alpha") &&
    // don't show for Other DS type
    dataSource?.type != DataSourceType.Import;

  return (
    (show && (
      <AdvancedOptionsContainer isDisabled={disabled}>
        {items}
        {/* Create Empty Wallet button */}
        <Flex justifyContent="space-between" alignItems="center" my="20px">
          <Box width="100%" h="1px" bgColor="black.300" />
        </Flex>
        <ButtonVariant
          content="Create an empty wallet"
          outlineType="outlineRed"
          isLoading={importInProgress}
          onClick={() =>
            createEmptyWallet({
              customName: watch("customName"),
            })
          }
          mb="10px"
          borderWidth="1px !important"
          spam="spam"
        />
        <Text fontSize="0.75rem" color="black.550" textAlign="center">
          You can manually enter transactions later
        </Text>
      </AdvancedOptionsContainer>
    )) ||
    null
  );
};
