import { WithId } from "../helpers/Id";
import { Populatable } from "../helpers/Populatable";
import { ReplaceProps } from "../helpers/ReplaceProps";
import { AssetBase } from "./AssetBase";
import {
  SubscriptionBase,
  SubscriptionBaseWithPlans,
} from "./SubscriptionBase";

// account type enum
export enum AccountType {
  Individual = "Individual",
  Trust = "Trust",
  Company = "Company",
  SMSF = "SMSF",
}

// account sub type enum
export enum AccountSubType {
  Investor = "Investor",
  Trader = "Trader",
}

export const ActivityTypeName: Record<AccountSubType, string> = {
  [AccountSubType.Investor]: "Investment",
  [AccountSubType.Trader]: "Trading",
};

export const AccountTypeCGTDiscount: Record<AccountType, boolean> = {
  Company: false,
  Individual: true,
  SMSF: true,
  Trust: true,
};

export enum ParcelMethodType {
  FIFO = "FIFO",
  LIFO = "LIFO",
  HIFO = "HIFO",
  LTFO = "LTFO",
}

export interface ParcelMethod {
  startYear: string;
  endYear: string;
  method: ParcelMethodType;
}

export interface Address {
  street: string;
  suite: string;
  suburb: string;
  state: string;
  postcode: string;
}

export interface PhoneNumber {
  countryCode: string;
  number: string;
}

export interface TaxSettingsBase<TId> {
  homeCountry: string;
  baseCurrency: Populatable<TId, AssetBase<TId>>;
  taxYear: string;
  gstRegistered: boolean;
  parcelMethods: ParcelMethod[];
  timeZone: string;
  defaultParcelMethod?: ParcelMethodType;
}

export type TaxSettingsWithBaseCurrency<TId> = ReplaceProps<
  TaxSettingsBase<TId>,
  "baseCurrency",
  { baseCurrency: WithId<AssetBase<TId>> }
>;

export interface AccountBase<TId> {
  _id: TId;
  accountType: AccountType;
  accountSubType: AccountSubType;
  contactEmail: string;
  fullName: string;
  entityName: string;
  abn: string;
  contactNumber: PhoneNumber;
  address: Address;
  taxSettings: TaxSettingsBase<TId>;
  // holdings?: AssetHoldingBase<TId>[];
  // transactionCount: number;
  subscriptions: SubscriptionBase<TId>[];
  // targetUpgradeFinancialYear?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export type AccountBaseWithTaxSettingsCurrency<TId> = ReplaceProps<
  AccountBase<TId>,
  "taxSettings",
  {
    taxSettings: TaxSettingsWithBaseCurrency<TId>;
  }
>;

export type AccountBaseWithSubscriptionPlans<TId> = ReplaceProps<
  AccountBase<TId>,
  "subscriptions",
  {
    subscriptions: SubscriptionBaseWithPlans<TId>[];
  }
>;
