import React from "react";

import { Flex, Text } from "@chakra-ui/react";

export const SliceWords = ({
  dataSources,
  maxTotalCharacters = 30,
}: // maxDataSourcesQty = 3,
{
  dataSources: string[];
  maxTotalCharacters?: number;
  maxDataSourcesQty?: number;
}): JSX.Element => {
  // Add
  let dataSourcesResult = dataSources.join(", ");

  if (dataSourcesResult.length > maxTotalCharacters) {
    dataSourcesResult = `${dataSourcesResult.slice(
      0,
      maxTotalCharacters - 3
    )}...`;
  }

  return (
    <Flex flexWrap="nowrap">
      <Text color="black.600" fontSize="0.75rem" fontWeight="500">
        {dataSourcesResult}
      </Text>
    </Flex>
  );
};
