"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParcelMethodType = exports.AccountTypeCGTDiscount = exports.ActivityTypeName = exports.AccountSubType = exports.AccountType = void 0;
// account type enum
var AccountType;
(function (AccountType) {
    AccountType["Individual"] = "Individual";
    AccountType["Trust"] = "Trust";
    AccountType["Company"] = "Company";
    AccountType["SMSF"] = "SMSF";
})(AccountType = exports.AccountType || (exports.AccountType = {}));
// account sub type enum
var AccountSubType;
(function (AccountSubType) {
    AccountSubType["Investor"] = "Investor";
    AccountSubType["Trader"] = "Trader";
})(AccountSubType = exports.AccountSubType || (exports.AccountSubType = {}));
exports.ActivityTypeName = {
    [AccountSubType.Investor]: "Investment",
    [AccountSubType.Trader]: "Trading",
};
exports.AccountTypeCGTDiscount = {
    Company: false,
    Individual: true,
    SMSF: true,
    Trust: true,
};
var ParcelMethodType;
(function (ParcelMethodType) {
    ParcelMethodType["FIFO"] = "FIFO";
    ParcelMethodType["LIFO"] = "LIFO";
    ParcelMethodType["HIFO"] = "HIFO";
    ParcelMethodType["LTFO"] = "LTFO";
})(ParcelMethodType = exports.ParcelMethodType || (exports.ParcelMethodType = {}));
