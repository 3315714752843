import { get_VEZGO_CLIENT_ID } from "@syla/shared/types/helpers/constants";
import { SyncState } from "@syla/shared/types/models/WalletBase";
import { throwError } from "@syla/shared/types/helpers/errors";
import { AddWalletType } from "@syla/shared/types/requests/AddWalletRequest";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { isProduction } from "../../../helper/environment";
import { getVezgoUserToken } from "../../../api/account/getVezgoUserToken";
import { captureRequestError } from "../../../helper/captureRequestError";
import { getAuthoriseUrl } from "../../../oAuth/getAuthoriseUrl";
import { syncWallet } from "../../../store/actions/syncWallet";
import { addWallet, updateWallet } from "../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { DataSource } from "../../../types/dataSource/dataSource";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { ModalContext } from "../../../contexts/ModalContext";
import { StdVStack } from "../../atoms/Containers";

declare const Vezgo;

type vezgoError = {
  error_type: number;
  message: string;
  account?: string;
};

export const AuthoriseModule = ({
  authorised,
  authInProgress,
  setAuthInProgress,
  dataSource,
  walletId,
  setWalletId,
  setAuthorised,
  setError,
  setSyncStarted,
  reconnectId,
  fromDate,
  customName,
}: {
  authorised: boolean | undefined;
  setAuthorised(value: boolean | undefined): void;
  authInProgress: boolean | undefined;
  setAuthInProgress: (value: boolean | undefined) => void;
  dataSource: DataSource;
  walletId?: string;
  setWalletId?(walletId: string): void;
  setError(error: Error | undefined);
  setSyncStarted?(value: boolean): void;
  reconnectId?: string;
  fromDate?: Date;
  customName?: string;
}) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { openModal, closeModal } = useContext(ModalContext);

  const [authoriseWindow, setAuthoriseWindow] = useState<Window>();

  // use ref to avoid triggering cleanup on window change
  const authoriseWindowRef = useRef(authoriseWindow);
  useEffect(() => {
    authoriseWindowRef.current = authoriseWindow;
  }, [authoriseWindow]);

  // clean up any open window on component unmount
  useEffect(() => {
    return () => {
      if (authoriseWindowRef.current) authoriseWindowRef.current.close();
    };
  }, []);

  const handleMessageFromWindow = useCallback(
    (event: { origin: unknown; data: AuthoriseWindowEventData }) => {
      // ignore events from other origins
      if (event.origin != window.location.origin) return;

      const data = event.data;
      console.debug("Received:", data);
      if (data.status == "success") {
        setAuthorised(true);
        setError(undefined);
      }
      if (data.status == "error") {
        setError(
          new Error(
            data.errorReason ?? "Something went wrong. Please try again."
          )
        );
      }
      if (data.status == "denied") {
        setError(undefined);
      }
    },
    [setAuthorised, setError]
  );

  // listen for events from open window
  useEffect(() => {
    window.addEventListener("message", handleMessageFromWindow);
    return () => {
      window.removeEventListener("message", handleMessageFromWindow);
    };
  }, [handleMessageFromWindow]);

  const onAuthorise = useCallback(async () => {
    setAuthInProgress(true);
    setError(undefined);
    try {
      let currentWalletId = walletId;
      if (!currentWalletId) {
        console.debug("Creating wallet");
        const newWallet = await addWallet(accountId, {
          dataSourceId: dataSource._id,
          type: AddWalletType.Sync,
          customName,
        });
        currentWalletId = newWallet._id;
        setWalletId && setWalletId(currentWalletId);
      }

      if (dataSource.syncAuthType == "oauth") {
        const authoriseUrl =
          getAuthoriseUrl({
            accountId,
            dataSourceCode: dataSource.code,
            walletId: currentWalletId,
          }) ?? throwError("No OAuth URL");

        // setAuthoriseUrl(authoriseUrl);
        const { windowOpen, authoriseWindow } = openOAuthWindow(
          authoriseUrl,
          "OAuth",
          500,
          600
        );

        setAuthoriseWindow(authoriseWindow);

        // use modal so that the user can focus the window again or cancel the process,
        // in case they lost the window
        openModal({
          type: "component",
          heading: "Connect Data Source",
          component: (
            <StdVStack>
              <ButtonVariant
                color="red"
                spam="spam"
                content="Continue"
                onClick={() => {
                  authoriseWindow?.focus();
                }}
              />
              <ButtonVariant
                content="Cancel"
                spam="spam"
                onClick={() => {
                  authoriseWindow?.close();
                  closeModal();
                  setAuthoriseWindow(undefined);
                  setAuthInProgress(false);
                }}
              />
            </StdVStack>
          ),
          hideCloseButton: true,
        });
        await windowOpen;
        closeModal();
        setAuthoriseWindow(undefined);
        setAuthInProgress(false);
      } else if (dataSource.syncAuthType == "vezgo") {
        const vezgo = Vezgo.init({
          clientId: get_VEZGO_CLIENT_ID(isProduction()),
          authorizer: async (callback) => {
            try {
              const { token } = await getVezgoUserToken(accountId);
              callback(null, { token });
            } catch (error) {
              callback(error);
            }
          },
        });
        const user = vezgo.login(accountId);
        (!reconnectId
          ? user
              .connect({ providers: ["binance"] })
              .onConnection(async (vezgoAccountId: string) => {
                console.log("Vezgo account connected", {
                  accountId: vezgoAccountId,
                });
                setAuthInProgress(false);
                setAuthorised(true);
                if (setSyncStarted) setSyncStarted(true);

                // update sync state
                syncWallet(
                  accountId,
                  { _id: currentWalletId!, dataSource },
                  fromDate,
                  vezgoAccountId
                );
              })
          : user.reconnect(reconnectId)
        )
          .onError(async (error: vezgoError) => {
            console.error("Vezgo error", { error });

            if (error.account) {
              await updateWallet(accountId, currentWalletId!, {
                vezgoAccountId: error.account,
                syncState: SyncState.Error,
              });
            }

            // don't show error on user close connection
            if (!/Connection closed/i.test(error.message))
              setError(new Error(error.message));

            setAuthInProgress(false);
          })
          .onEvent((event) => {
            console.debug("Vezgo event", { event });
          });
      }
    } catch (error: any) {
      console.error(error);
      // don't capture known errors
      if (!(error instanceof WindowOpenFailedError)) {
        captureRequestError(error);
      }
      setAuthInProgress(false);
      setError(error);
    }
  }, [
    setAuthInProgress,
    setError,
    walletId,
    dataSource,
    accountId,
    customName,
    setWalletId,
    openModal,
    closeModal,
    reconnectId,
    setAuthorised,
    setSyncStarted,
    fromDate,
  ]);

  return (
    <>
      <ButtonVariant
        content={!authorised ? "Connect" : "Connected"}
        onClick={onAuthorise}
        isLoading={authInProgress}
        isDisabled={authorised}
        rightIcon={authorised ? "check" : undefined}
        color={authorised ? "green" : "red"}
      />
    </>
  );
};

const openOAuthWindow = (url, windowName, width, height) => {
  // center the window
  // logic is more complicated due to multi-monitor context
  const screenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const screenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;
  const screenWidth =
    window.innerWidth || document.documentElement.clientWidth || screen.width;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    screen.height;

  const left = screenLeft + (screenWidth - width) / 2;
  const top = screenTop + (screenHeight - height) / 2;

  // Open the new window
  const authoriseWindow = window.open(
    url,
    windowName,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no` +
      `, width=${width}, height=${height}, top=${top}, left=${left}`
  );
  if (!authoriseWindow) throw new WindowOpenFailedError();

  const windowOpen = new Promise<void>((resolve) => {
    // Check whether the window is still open periodically
    const timer = setInterval(function () {
      if (!authoriseWindow || authoriseWindow.closed) {
        clearInterval(timer);
        resolve();
      }
    }, 500);
  });

  return { authoriseWindow, windowOpen };
};

export class WindowOpenFailedError extends Error {
  constructor() {
    super();
    this.name = "WindowOpenFailedError";
  }
}

export type AuthoriseWindowEventData = {
  status: "success" | "denied" | "error";
  errorReason?: string;
};
