import { Decimal } from "../../decimal";
import { ModelBase } from "./ModelBase";

export enum TaxOutcomeType {
  CapitalGainLongTerm = "CapitalGainLongTerm",
  CapitalGainShortTerm = "CapitalGainShortTerm",
  CapitalLoss = "CapitalLoss",
  CapitalGainExempt = "CapitalGainExempt",
  CapitalLossExempt = "CapitalLossExempt",
  ForeignCurrencyGain = "ForeignCurrencyGain",
  ForeignCurrencyLoss = "ForeignCurrencyLoss",
  OtherIncome = "OtherIncome",
  OtherExpense = "OtherExpense",
  GSTPaid = "GSTPaid",
  GSTCollected = "GSTCollected",
  NonAssessableIncome = "NonAssessableIncome",
  NonDeductibleExpense = "NonDeductibleExpense",
  TradingStockPurchaseAndOtherCost = "TradingStockPurchaseAndOtherCost",
  TradingStockSales = "TradingStockSales",
  Donations = "Donations",
}

export enum TaxClassification {
  PersonalUseAssetExempt = "PersonalUseAssetExempt",
  TradingStock = "TradingStock",
  FiatCurrency = "FiatCurrency",
  CGTAsset = "CGTAsset",
  DomesticCurrency = "DomesticCurrency",
}

export interface TaxOutcomeBase<TId> extends ModelBase<TId> {
  type: TaxOutcomeType;

  /** The gain, valued in Domestic currency. Will be negative in case of a loss. */
  gain: Decimal;

  // Disposal Related
  amount?: Decimal;
  cost?: Decimal;
  proceeds?: Decimal;
  acquisitionDate?: Date;
  sourceLedger?: { _id: TId };
}

export type IncomeExpenseTaxOutcomeType =
  | TaxOutcomeType.OtherIncome
  | TaxOutcomeType.OtherExpense
  | TaxOutcomeType.NonAssessableIncome
  | TaxOutcomeType.NonDeductibleExpense;

export function isCgtTaxOutcomeType({
  type,
}: {
  type: TaxOutcomeType;
}): boolean {
  switch (type) {
    case TaxOutcomeType.CapitalGainLongTerm:
    case TaxOutcomeType.CapitalGainShortTerm:
    case TaxOutcomeType.CapitalLoss:
    case TaxOutcomeType.CapitalGainExempt:
    case TaxOutcomeType.CapitalLossExempt:
    case TaxOutcomeType.ForeignCurrencyGain:
    case TaxOutcomeType.ForeignCurrencyLoss:
      return true;
    case TaxOutcomeType.OtherIncome:
    case TaxOutcomeType.OtherExpense:
    case TaxOutcomeType.GSTPaid:
    case TaxOutcomeType.GSTCollected:
    case TaxOutcomeType.NonAssessableIncome:
    case TaxOutcomeType.NonDeductibleExpense:
    case TaxOutcomeType.TradingStockPurchaseAndOtherCost:
    case TaxOutcomeType.TradingStockSales:
    case TaxOutcomeType.Donations:
      return false;
  }
}
