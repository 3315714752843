import { partition } from "lodash";
import { useMemo } from "react";
import {
  getBalanceDetailsFromLedgers,
  BalanceDetail,
} from "../helper/ledger/aggregateBalanceInLedgers";

import { splitTransactionLedgers } from "../helper/ledger/splitTransactionLedgers";
import { calculateTransactionTotals } from "../helper/transaction/calculateTransactionTotals";
import { Transaction } from "../types/transaction";

export const useTransactionValues = <TTransaction extends Transaction>(
  transactions: TTransaction[]
): {
  inLedgers: TTransaction["ledgers"];
  outLedgers: TTransaction["ledgers"];
  allLedgers: TTransaction["ledgers"];
  feeLedgers: TTransaction["ledgers"];
  allInLedgers: TTransaction["ledgers"];
  allOutLedgers: TTransaction["ledgers"];
  allInAssets: TTransaction["ledgers"][0]["asset"][];
  allOutAssets: TTransaction["ledgers"][0]["asset"][];
  totals: {
    totalFiatValue: string;
    totalFees: string;
    totalCost: string;
    totalGain: string;
  };
  inSources: string[];
  outSources: string[];
  negativeChanges: BalanceDetail[];
  positiveChanges: BalanceDetail[];
} => {
  const {
    inLedgers,
    outLedgers,
    feeLedgers,
    allInLedgers,
    allOutLedgers,
    allLedgers,
    allInAssets,
    allOutAssets,
    inSources,
    outSources,
  } = useMemo(() => splitTransactionLedgers(transactions), [transactions]);

  const [negativeChanges, positiveChanges] = useMemo(
    () =>
      partition(
        getBalanceDetailsFromLedgers(allLedgers).filter(
          (bd) => !bd.amount.isZero()
        ),
        (bd) => bd.amount.isNegative()
      ),
    [allLedgers]
  );

  const totals = useMemo(
    () => calculateTransactionTotals(transactions),
    [transactions]
  );

  return {
    inLedgers,
    outLedgers,
    feeLedgers,
    allInLedgers,
    allOutLedgers,
    allLedgers,
    allInAssets,
    allOutAssets,
    totals,
    inSources,
    outSources,
    negativeChanges,
    positiveChanges,
  };
};
