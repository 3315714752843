import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { UseQueryOptions } from "react-query/types/react/types";
import { GetAccountAssetsData } from "../api/asset/getAccountAssets";
import { Asset } from "../types/asset/asset";
import { useQueryAccountAssets } from "./useQueryAccountAssets";

export const useQueryAccountAssetOptions = <TQueryFnData, TError = unknown>(
  accountId: string,
  options?: Omit<
    UseQueryOptions<GetAccountAssetsData, TError, SelectOptionType[]>,
    "queryKey" | "queryFn"
  >
) =>
  useQueryAccountAssets<TQueryFnData, TError, SelectOptionType[]>(accountId, {
    select: (assets) => assets.map(formatAssetOption),
    ...options,
  });

export const formatAssetOption = (asset: Asset): SelectOptionType => ({
  value: asset._id,
  label: getAssetShortName(asset),
  icon: asset.image,
  description: getAssetLongName(asset),
});
