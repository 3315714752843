import loadingIcon from "../images/icons/apiSync.svg";
import briefcaseIcon from "../images/icons/briefcase.svg";
import bullseyeIcon from "../images/icons/bullseye.svg";
import completeIcon from "../images/icons/complete.svg";
import deleteIcon from "../images/icons/delete.svg";
import downloadIcon from "../images/icons/reportPdf.svg";
import infoIcon from "../images/icons/Info.svg";
import researchIcon from "../images/icons/research.svg";
import sentIcon from "../images/icons/send.svg";
import refreshIcon from "../images/icons/refresh.svg";
import warningIcon from "../images/icons/warning.svg";

export type IconExtractorType =
  | "delete"
  | "loading"
  | "briefcase"
  | "bullseye"
  | "complete"
  | "sent"
  | "download"
  | "research"
  | "warning"
  | "refresh"
  // | "withdraw"
  | "info";

export const iconExtractor = (icon: IconExtractorType) => {
  switch (icon) {
    case "delete":
      return deleteIcon;
    case "loading":
      return loadingIcon;
    case "sent":
      return sentIcon;
    case "briefcase":
      return briefcaseIcon;
    case "bullseye":
      return bullseyeIcon;
    case "complete":
      return completeIcon;
    case "refresh":
      return refreshIcon;
    case "download":
      return downloadIcon;
    case "research":
      return researchIcon;
    case "warning":
      return warningIcon;
    case "info":
      return infoIcon;
    default:
      return undefined;
  }
};
