import { Flex, Text, Tooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { TaxOutcomeDetail } from "../../../../helper/aggregateLedgerTaxOutcomes";
import { NumberStyler } from "../../../../helper/NumberStyler";
import { useTransactionsStore } from "../../../../store/transactionsStore";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";
import { SkeletonRect } from "../../../atoms/Skeletons";
import { rightAlignContent } from "../../dataSource/mainDataSourceTable/dataSourceExpanded/styles";
import { TCell, TRow } from "../../../atoms/Table";

export const TaxDetailsHeaders = (): JSX.Element => (
  <Flex
    borderWidth="1px 0 1px 0"
    w="100%"
    m="0 auto"
    backgroundColor="black.200"
  >
    <THeaderCell>
      <THeaderText>Classification</THeaderText>
    </THeaderCell>
    <THeaderCell>
      <THeaderText>Amount</THeaderText>
    </THeaderCell>
    <THeaderCell>
      <THeaderText>Asset</THeaderText>
    </THeaderCell>
    <TRightAlignHeadingCell>
      <THeaderText>Outcome</THeaderText>
    </TRightAlignHeadingCell>
    <TSpacer />
  </Flex>
);

export const TaxDetailsRow = (
  taxOutcomeDetail: TaxOutcomeDetail
): JSX.Element => {
  const isInvalid = useTransactionsStore((state) => state.refreshing);
  return (
    <TRow>
      {/* ----------------------------- Classification -----------------------------  */}
      <TCell>
        <Flex direction="column">
          <SkeletonRect isLoaded={!isInvalid}>
            <Text noOfLines={2}>{taxOutcomeDetail.classification}</Text>
            <Text noOfLines={1} color="black.500">
              {taxOutcomeDetail.subClassification}
            </Text>
          </SkeletonRect>
        </Flex>
      </TCell>
      {/* ----------------------------- Amount  ------------------------------------  */}
      <TCell>
        <SkeletonRect isLoaded={!isInvalid}>
          <NumberStyler num={taxOutcomeDetail.amount} smartRound />
        </SkeletonRect>
      </TCell>
      {/* ----------------------------- Asset --------------------------------------  */}
      <TCell>
        <ImageWithMissingSrc
          src={taxOutcomeDetail.asset.image}
          alt=""
          w="30px"
          h="30px"
          mr="10px"
          isLoading={isInvalid}
        />
        <SkeletonRect isLoaded={!isInvalid}>
          <Tooltip label={getAssetLongName(taxOutcomeDetail.asset)}>
            <Text noOfLines={1}>
              {getAssetShortName(taxOutcomeDetail.asset)}
            </Text>
          </Tooltip>
        </SkeletonRect>
      </TCell>
      {/* ----------------------------- Outcome -----------------------------------  */}
      <TRightAlignCell>
        <SkeletonRect isLoaded={!isInvalid}>
          <NumberStyler
            num={taxOutcomeDetail.outcome}
            unit="currency"
            colored
          />
        </SkeletonRect>
      </TRightAlignCell>
      <TSpacer />
    </TRow>
  );
};

const THeaderCell = styled(Flex)`
  width: 25%;
  font-size: 0.75rem;
  padding: 5px 0 5px 25px;
`;

const THeaderText = styled(Text)`
  text-align: center;
`;

const TRightAlignCell = styled(TCell)`
  ${rightAlignContent}
`;

const TRightAlignHeadingCell = styled(THeaderCell)`
  ${rightAlignContent}
`;

const TSpacer = styled(Flex)`
  margin: 10px 10px;
  width: 7%;
  flex-shrink: 0;
`;
