"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataSourceSupportedInputs = exports.ImportStatus = exports.DataSourceCode = exports.DataSourceType = void 0;
var DataSourceType;
(function (DataSourceType) {
    DataSourceType["Exchange"] = "exchange";
    DataSourceType["Blockchain"] = "blockchain";
    DataSourceType["Wallet"] = "wallet";
    DataSourceType["Custom"] = "custom";
    DataSourceType["Import"] = "import";
})(DataSourceType = exports.DataSourceType || (exports.DataSourceType = {}));
// data source names
var DataSourceCode;
(function (DataSourceCode) {
    // exchanges
    DataSourceCode["AmberApp"] = "amberapp";
    DataSourceCode["Ascendex"] = "ascendex";
    DataSourceCode["Bamboo"] = "bamboo";
    DataSourceCode["Binance"] = "Binance";
    DataSourceCode["BingX"] = "bingx";
    DataSourceCode["Bitaroo"] = "Bitaroo";
    DataSourceCode["Bitbns"] = "Bitbns";
    DataSourceCode["Bitfinex"] = "Bitfinex";
    DataSourceCode["Bitget"] = "bitget";
    DataSourceCode["Bitkub"] = "Bitkub";
    DataSourceCode["BitMart"] = "BitMart";
    DataSourceCode["BitMEX"] = "Bitmex";
    DataSourceCode["Bitrue"] = "bitrue";
    DataSourceCode["Bitstamp"] = "Bitstamp";
    DataSourceCode["BitTrade"] = "bit_trade";
    DataSourceCode["BitTrex"] = "BitTrex";
    DataSourceCode["Bitunix"] = "bitunix";
    DataSourceCode["BlockFi"] = "BlockFi";
    DataSourceCode["BTCE"] = "BTCE";
    DataSourceCode["BtcMarkets"] = "BTCMarkets";
    DataSourceCode["Bybit"] = "bybit";
    DataSourceCode["CalebBrown"] = "caleb_and_brown";
    DataSourceCode["Celsius"] = "Celsius";
    DataSourceCode["Coinbase"] = "Coinbase";
    DataSourceCode["CoinbasePro"] = "coinbase_pro";
    DataSourceCode["CoinEx"] = "CoinEx";
    DataSourceCode["CoinHarbour"] = "CoinHarbour";
    DataSourceCode["CoinJar"] = "CoinJar";
    DataSourceCode["CoinJarExchange"] = "CoinJarExchange";
    DataSourceCode["Cointree"] = "Cointree";
    DataSourceCode["CoinSpot"] = "CoinSpot";
    DataSourceCode["CoinStash"] = "CoinStash";
    DataSourceCode["CryptoDotCom"] = "Crypto.com";
    DataSourceCode["CryptoDotComExchange"] = "crypto_com_exchange";
    DataSourceCode["Cryptohopper"] = "cryptohopper";
    DataSourceCode["CryptoSpend"] = "cryptospend";
    DataSourceCode["DeltaExchange"] = "delta_spot";
    DataSourceCode["DigitalSurge"] = "DigitalSurge";
    DataSourceCode["Elbaite"] = "elbaite";
    DataSourceCode["Ember"] = "emberfund";
    DataSourceCode["EToro"] = "etoro";
    DataSourceCode["Finblox"] = "finblox";
    DataSourceCode["FinderEarn"] = "finder_earn";
    DataSourceCode["FTX"] = "FTX";
    DataSourceCode["Gateio"] = "Gateio";
    DataSourceCode["Gemini"] = "Gemini";
    DataSourceCode["GPIB"] = "gpib";
    DataSourceCode["HardBlock"] = "hardblock";
    DataSourceCode["HitBTC"] = "HitBTC";
    DataSourceCode["HTX"] = "huobi";
    DataSourceCode["IGMarkets"] = "ig_markets";
    DataSourceCode["IndependentReserve"] = "IndependentReserve";
    DataSourceCode["KinesisMoney"] = "kinesis_money";
    DataSourceCode["Kraken"] = "Kraken";
    DataSourceCode["KuCoin"] = "KuCoin";
    DataSourceCode["Ledn"] = "ledn";
    DataSourceCode["LROS"] = "living_room_of_satoshi";
    DataSourceCode["Luno"] = "Luno";
    DataSourceCode["MEXC"] = "mexc";
    DataSourceCode["MoonPay"] = "moonpay";
    DataSourceCode["Nexo"] = "nexo";
    DataSourceCode["NiceHash"] = "NiceHash";
    DataSourceCode["OKX"] = "OKEX";
    DataSourceCode["Paxful"] = "paxful";
    DataSourceCode["Phemex"] = "phemex";
    DataSourceCode["Pionex"] = "pionex";
    DataSourceCode["Poloniex"] = "Poloniex";
    DataSourceCode["PrimeXBT"] = "primexbt";
    DataSourceCode["SouthXChange"] = "south_xchange";
    DataSourceCode["Swyftx"] = "Swyftx";
    DataSourceCode["TradeOgre"] = "trade_ogre";
    DataSourceCode["Uphold"] = "uphold";
    DataSourceCode["Virgo"] = "virgo";
    DataSourceCode["Wealth99"] = "wealth99";
    DataSourceCode["WooX"] = "wootrade";
    DataSourceCode["XTDotCom"] = "xtpub";
    DataSourceCode["YieldApp"] = "yield_app";
    DataSourceCode["YouHodler"] = "you_hodler";
    // wallets
    DataSourceCode["Atomic"] = "atomic_wallet";
    DataSourceCode["Daedalus"] = "daedalus";
    DataSourceCode["Etherscan"] = "Etherscan";
    DataSourceCode["Exodus"] = "Exodus";
    DataSourceCode["Ledger"] = "Ledger";
    DataSourceCode["Metamask"] = "Metamask";
    DataSourceCode["Mintscan"] = "mintscan";
    DataSourceCode["MyEtherWallet"] = "MyEtherWallet";
    DataSourceCode["Qoin"] = "qoin";
    DataSourceCode["Trezor"] = "trezor";
    DataSourceCode["Vera"] = "Vera";
    DataSourceCode["Xaman"] = "xaman";
    DataSourceCode["Yoroi"] = "yoroi";
    // blockchains
    DataSourceCode["Algorand"] = "algorand";
    DataSourceCode["Arbitrum"] = "arbitrum";
    DataSourceCode["Avalanche"] = "avalanche";
    DataSourceCode["Beam"] = "beam";
    DataSourceCode["Bitcoin"] = "Bitcoin";
    DataSourceCode["BitcoinCash"] = "bitcoinCash";
    DataSourceCode["Blast"] = "blast";
    DataSourceCode["Chiliz"] = "chiliz_chain";
    DataSourceCode["Cyber"] = "cyber";
    DataSourceCode["Elastos"] = "elastos";
    DataSourceCode["ECash"] = "eCash";
    DataSourceCode["Litecoin"] = "litecoin";
    DataSourceCode["Dash"] = "dash";
    DataSourceCode["DFK"] = "defi_kingdoms_chain";
    DataSourceCode["Dogecoin"] = "dogecoin";
    DataSourceCode["Base"] = "base";
    DataSourceCode["BSC"] = "binance_smart_chain";
    DataSourceCode["Cardano"] = "Cardano";
    DataSourceCode["Celo"] = "celo";
    DataSourceCode["Cronos"] = "cronos_evm";
    DataSourceCode["Ethereum"] = "Ethereum";
    DataSourceCode["Flare"] = "flare";
    DataSourceCode["Fraxtal"] = "fraxtal";
    DataSourceCode["FTM"] = "fantom";
    DataSourceCode["Gnosis"] = "gnosis_chain";
    DataSourceCode["Helium"] = "Helium";
    DataSourceCode["Mantle"] = "mantle";
    DataSourceCode["Metis"] = "metis";
    DataSourceCode["Mint"] = "mint_chain";
    DataSourceCode["Mode"] = "mode_network";
    DataSourceCode["Moonbeam"] = "moonbeam";
    DataSourceCode["MultiversX"] = "multiversx";
    DataSourceCode["Optimism"] = "optimism";
    DataSourceCode["Polkadot"] = "polkadot";
    DataSourceCode["Polygon"] = "polygon";
    DataSourceCode["Solana"] = "solana";
    DataSourceCode["Scroll"] = "scroll";
    DataSourceCode["Songbird"] = "songbird";
    DataSourceCode["Stacks"] = "stacks";
    DataSourceCode["Stellar"] = "stellar";
    DataSourceCode["Sui"] = "sui";
    DataSourceCode["Taiko"] = "taiko";
    DataSourceCode["Tezos"] = "tezos";
    DataSourceCode["Tron"] = "tron";
    DataSourceCode["XRP"] = "xrp";
    DataSourceCode["ZkSyncEra"] = "zksync_era";
    DataSourceCode["Zora"] = "zora_chain";
    DataSourceCode["BnbBeaconChain"] = "bnb_beacon_chain";
    // others
    DataSourceCode["OpeningBalances"] = "sylaOpeningBalances";
    DataSourceCode["Koinly"] = "koinly";
    DataSourceCode["CoinTracking"] = "cointracking";
})(DataSourceCode = exports.DataSourceCode || (exports.DataSourceCode = {}));
var ImportStatus;
(function (ImportStatus) {
    ImportStatus["Off"] = "off";
    ImportStatus["Alpha"] = "alpha";
    ImportStatus["Beta"] = "beta";
    ImportStatus["Gold"] = "gold";
})(ImportStatus = exports.ImportStatus || (exports.ImportStatus = {}));
const getDataSourceSupportedInputs = ({ fileImportStatus, syncImportStatus, }) => ({
    file: (fileImportStatus !== null && fileImportStatus !== void 0 ? fileImportStatus : "off") == "off" ? undefined : fileImportStatus,
    sync: (syncImportStatus !== null && syncImportStatus !== void 0 ? syncImportStatus : "off") == "off" ? undefined : syncImportStatus,
});
exports.getDataSourceSupportedInputs = getDataSourceSupportedInputs;
