import { Flex, Text, FlexProps, useBreakpointValue } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { InfoToolTip } from "../../atoms/InfoToolTip";
import { SkeletonRect } from "../../atoms/Skeletons";

interface StatBoxProps extends FlexProps {
  label: string;
  content: ReactNode;
  isLoading: boolean;
  toolTip: string;
}

export const StatBox = ({
  label,
  content,
  isLoading,
  toolTip,
  ...props
}: StatBoxProps) => {
  const showFullWidth = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    xs: true,
    "2xs": true,
  });
  return (
    <Flex
      w={showFullWidth ? "100%" : "50%"}
      h="120px"
      px="20px"
      mb="15px"
      borderRadius="5px"
      direction="column"
      justifyContent="center"
      bgColor="white.0"
      borderWidth="1px"
      borderColor="white.500"
      overflowX="hidden"
      boxShadow="md"
      {...props}
    >
      <Flex alignItems="center" flexWrap="nowrap">
        <Text fontSize="0.75rem" color="black.600" pr="3px" noOfLines={1}>
          {label}
        </Text>
        <InfoToolTip label={toolTip} wrap />
      </Flex>
      <SkeletonRect isLoaded={!isLoading}>{content}</SkeletonRect>
    </Flex>
  );
};
