import { GetUserLedgerTypesResponse } from "@syla/shared/types/responses/GetUserLedgerTypesResponse";
import axios from "axios";

import { TransportType } from "@syla/shared/types/helpers/TransportType";

export const getAccountLedgerTypes = async (
  accountId: string
): Promise<TransportType<GetUserLedgerTypesResponse>> => {
  const { data } = await axios.get(
    `/accounts/${accountId}/transactions/ledger-types`
  );
  return data;
};
