import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { SyncWalletSuccessResponse } from "@syla/shared/types/responses/SyncWalletResponse";
import axios from "axios";

export const syncWallet = async (
  accountId: string,
  walletId: string,
  fromDate?: Date,
  vezgoAccountId?: string
) => {
  const { data } = await axios.post<
    TransportType<SyncWalletSuccessResponse<string>>
  >(`/accounts/${accountId}/wallets/sync/${walletId}`, {
    fromDate,
    vezgoAccountId,
  });
  return data;
};
