import React from "react";
import { BoxProps, Tooltip, Box, TooltipProps } from "@chakra-ui/react";

export interface TooltipWrapperProps extends BoxProps {
  tooltip?: string;
  tooltipProps?: Omit<TooltipProps, "children">;
}

/**
 * Tooltip even for disabled content
 */
export const TooltipWrapper = ({
  tooltip,
  tooltipProps,
  ...boxProps
}: TooltipWrapperProps) => (
  <Tooltip label={tooltip} {...tooltipProps}>
    <Box {...boxProps} />
  </Tooltip>
);
