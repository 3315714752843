import { DeleteIcon } from "@chakra-ui/icons";
import {
  Flex,
  Input,
  Spacer,
  Text,
  FormControl,
  IconButton,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { isReceiveType } from "@syla/shared/types/models/LedgerBase";
import { get } from "lodash";
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { amountInvalid } from "../../../../../helper/forms/stringAsNumberInputField";
import { LedgerTypeOptions } from "../../../../../helper/transaction/LedgerTypeOptions";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import { useQueryAssetOptions } from "../../../../../store/useQueryAssets";
import { TCell, TRow } from "../../../../atoms/Table";
import { SingleThumbnailSelectBox } from "../../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import { maxAssetsToDisplay } from "../../ledgerInputConstants";
import { editModeFirstColSize } from "./LedgerTableHeaders";

export const EditModeLedgerTableRow = ({
  name,
  isDisabled,
  validTypes,
  onDelete,
  deleteDisabled,
}: {
  name: string;
  validTypes: LedgerTypeOptions;
  isDisabled?: boolean;
  deleteDisabled: boolean;
  onDelete: () => void;
}): JSX.Element => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const inLedger =
    !watch(`${name}.type`) ||
    isReceiveType({
      type: watch(`${name}.type`),
    });

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: assetOptionsResult } = useQueryAssetOptions(accountId);
  const assetOptions = assetOptionsResult ?? [];

  return (
    <TRow>
      {/* ----------------------------- Classification ---------------------------  */}
      <TCell minW={editModeFirstColSize}>
        <Flex direction="row" alignItems="center">
          <FormControl
            isRequired
            isDisabled={isDisabled}
            isInvalid={get(errors, `${name}.type.type`) !== undefined}
          >
            <Controller
              control={control}
              name={`${name}.type`}
              rules={{ required: "Type is missing" }}
              render={({ field: { onChange, value } }) => (
                <SingleThumbnailSelectBox
                  searchEnable
                  selectedOption={value}
                  onChangeSelection={(selection) => onChange(selection)}
                  options={validTypes}
                  maxOptionsInView={4}
                  allowDeSelected={false}
                  selectBtnProps={{
                    minWidth: "180px",
                    border: get(errors, `${name}.type.type`)
                      ? "2px solid red"
                      : "#f5f5f5",
                  }}
                />
              )}
            />
          </FormControl>
        </Flex>
        <Spacer />
      </TCell>
      {/* ----------------------------- Change -----------------------------  */}
      <TCell>
        {inLedger ? (
          <Text color="green.500">+</Text>
        ) : (
          <Text color="red.900">-</Text>
        )}
        <FormControl
          isRequired
          isDisabled={isDisabled}
          isInvalid={get(errors, `${name}.amount.type`) !== undefined}
        >
          <Controller
            control={control}
            name={`${name}.amount`}
            rules={{
              required: "Amount is missing",
              validate: (value) => !amountInvalid({ value }),
            }}
            render={({ field: { onChange, value } }) => (
              <NumberInputField
                value={value}
                onChange={(event) => onChange(event.target.value)}
                highlight={get(errors, `${name}.amount`)}
                type="string"
              />
            )}
          />
        </FormControl>
      </TCell>
      {/* ----------------------------- Asset -----------------------------  */}
      <TCell>
        <FormControl
          isRequired
          isDisabled={isDisabled}
          isInvalid={get(errors, `${name}.assetId.assetId`) !== undefined}
        >
          <Controller
            control={control}
            name={`${name}.assetId`}
            rules={{ required: "Asset is missing" }}
            render={({ field: { onChange, value } }) => (
              <SingleThumbnailSelectBox
                selectedOption={value}
                onChangeSelection={(selection) =>
                  selection && onChange(selection)
                }
                options={assetOptions}
                maxOptionsToDisplay={maxAssetsToDisplay}
                placeholder="Set Asset"
                searchEnable
                doubleRows
                optionContainerProps={{ width: "410px", left: "-205px" }}
                disabled={isDisabled}
                selectBtnProps={{
                  border: get(errors, `${name}.assetId.type`)
                    ? "2px solid red"
                    : "#f5f5f5",
                }}
                isLoading={!assetOptionsResult}
              />
            )}
          />
        </FormControl>
      </TCell>
      {/* ----------------------------- MarketValue -----------------------------  */}
      <TCell justifyContent="flex-end">
        <FormControl
          isDisabled={isDisabled}
          isInvalid={get(errors, `${name}.marketValue.type`) !== undefined}
        >
          <Controller
            control={control}
            name={`${name}.marketValue`}
            rules={{
              validate: (value) =>
                !value || !amountInvalid({ value, allowZero: true }),
            }}
            render={({ field: { onChange, value } }) => (
              <NumberInputField
                value={value}
                placeholder="Optional"
                onChange={(event) => onChange(event.target.value)}
                highlight={get(errors, `${name}.marketValue`)}
                type="string"
              />
            )}
          />
        </FormControl>
      </TCell>
      <Flex w="7%" ml="10px" px="10px" align="center">
        <IconButton
          icon={<DeleteIcon />}
          aria-label={"Delete"}
          onClick={onDelete}
          isDisabled={isDisabled || deleteDisabled}
          color="red"
          variant="ghost"
          p={2}
          height="2em"
          cursor="pointer"
        />
      </Flex>
    </TRow>
  );
};

const NumberInputField = styled(Input)((props) => ({
  borderColor: props.highlight ? "FF0000" : "#f5f5f5",
  color: "#999",
  marginLeft: "5px",
  width: "80%",
  height: "34px",
  "&:focus": {
    borderColor: "#808080",
    boxShadow: "none",
  },
}));
