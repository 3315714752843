import axios from "axios";

import { User } from "../../types/user/user";

export const updateUser = async (newProps: UpdateUser): Promise<User> => {
  const { data } = await axios.patch("/users", newProps);
  return data;
};

export type UpdateUser = Partial<User> & { password?: string };
