export const tabsTheme = {
  variants: {
    line: {
      tablist: {
        borderBottom: "none",
        overflowY: "hidden",
        overflowX: "auto",
      },
      tab: {
        color: "gray.500",
        borderColor: "gray.200",
        marginBottom: "0px",
        _hover: {
          color: "gray.700",
          borderColor: "gray.400",
        },
        _selected: {
          color: "gray.900",
          borderColor: "primary.500",
          _hover: {
            color: "gray.900",
            borderColor: "primary.500",
          },
        },
      },
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
};
