import { Decimal } from "@syla/shared/decimal";
import { useMemo } from "react";

export const useHoldingStats = ({
  cost,
  marketValue,
}: {
  marketValue: Decimal | undefined;
  cost: Decimal | undefined;
}) => {
  return useMemo(() => {
    const roi =
      (cost &&
        !cost.eq("0") &&
        marketValue?.minus(cost).div(cost).times("100")) ||
      undefined;

    return {
      gain: (cost && marketValue?.minus(cost)) || undefined,
      roi,
      roiInfinity:
        (cost && marketValue && (cost.eq("0") || roi?.gt("999.99"))) ||
        undefined,
    };
  }, [cost, marketValue]);
};
