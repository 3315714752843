"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNonZeroValue = exports.hasValue = exports.sumDecimalWithFunction = exports.sumWithDecimal = exports.sumDecimals = void 0;
const decimal_1 = require("./decimal");
/**
 * Sums a list of Decimal's.
 * Returns Decimal("0") if there are none supplied.
 * @param items
 */
const sumDecimals = (items) => {
    return items.reduce((total, item) => {
        return item ? total.plus(item) : total;
    }, decimal_1.Decimal.from("0"));
};
exports.sumDecimals = sumDecimals;
/**
 * This function takes in an array of objects, that can be anything. It sums the objects together by using the provided fieldName
 * @param items The items to sum for
 * @param fieldName The fieldName that will be used to sum the items together
 * @returns Returns the sum of the items at the given fieldName
 */
const sumWithDecimal = (items, fieldName) => !items
    ? decimal_1.Decimal.from("0")
    : items.reduce((total, item) => {
        try {
            return (item === null || item === void 0 ? void 0 : item[fieldName]) ? total.plus(item[fieldName]) : total;
        }
        catch (e) {
            return total;
        }
    }, decimal_1.Decimal.from("0"));
exports.sumWithDecimal = sumWithDecimal;
/**
 * This function calculates the sum of the provided items with the sumFunction provided
 * @param items The items to sum
 * @param sumFunction The function that will sum the items together
 * @returns Returns the sum of all items
 */
const sumDecimalWithFunction = (items, sumFunction) => items.reduce((total, item) => {
    try {
        return sumFunction(item)
            ? total.plus(sumFunction(item))
            : total;
    }
    catch (e) {
        return total;
    }
}, decimal_1.Decimal.from("0"));
exports.sumDecimalWithFunction = sumDecimalWithFunction;
// Is value defined and non-zero?
function hasValue(value) {
    return !!value && !value.isZero();
}
exports.hasValue = hasValue;
// returns non-zero value or undefined
function getNonZeroValue(value) {
    return hasValue(value) ? value : undefined;
}
exports.getNonZeroValue = getNonZeroValue;
