import { AssetType, AssetBase } from "../../types/models/AssetBase";

const contract = (text: string, maxLength: number) =>
  text.length <= maxLength
    ? text
    : `${text.slice(0, (maxLength - 3) / 3)}..${text.slice(
        -((maxLength - 3) / 3)
      )}`;

export const getAssetShortName = ({
  code,
  name,
  address,
  tokenId,
  type,
}: Pick<
  AssetBase<never>,
  "type" | "code" | "name" | "address" | "tokenId"
>) => {
  const codeName = code.trim() || name.trim() || address?.trim() || "<unknown>";

  return type == AssetType.NFT && tokenId
    ? `${contract(codeName, 15)} #${contract(tokenId, 10)}`
    : contract(codeName, 26);
};
