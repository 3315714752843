import { UpdateAccountRequestBody } from "@syla/shared/types/requests/UpdateAccountRequest";
import axios from "axios";
import { checkStatusAndDoPendingWork } from "../../store/pendingWork";
import { Account } from "../../types/Account";

export type UpdateAccountProps = UpdateAccountRequestBody<string>;

export const updateAccount = async (
  accountId: string,
  newProps: UpdateAccountProps
): Promise<Account> => {
  const { data } = await axios.patch(`/accounts/${accountId}`, newProps);
  checkStatusAndDoPendingWork();
  return data;
};
