import {
  LoginWithGoogleResponse,
  LoginWithGoogleResponseStatusCodes,
} from "@syla/shared/types/responses/LoginWithGoogleResponse";
import axios from "axios";

export const loginWithGoogle = async ({
  credential,
}: {
  credential: string;
}): Promise<LoginWithGoogleResponse> => {
  try {
    const { data } = await axios.post("/users/login/google", {
      credential,
    });

    return data;
  } catch (error) {
    if (!axios.isAxiosError(error)) throw new LoginWithGoogleError();

    throw new LoginWithGoogleError(error.response?.status);
  }
};

export class LoginWithGoogleError extends Error {
  constructor(statusCode?: LoginWithGoogleResponseStatusCodes) {
    super();
    switch (statusCode) {
      case LoginWithGoogleResponseStatusCodes.BadRequest:
        this.type = "BadRequest";
        break;
      case LoginWithGoogleResponseStatusCodes.Failed:
        this.type = "Failed";
        break;
      case LoginWithGoogleResponseStatusCodes.Forbidden:
        this.type = "Forbidden";
        break;
      default:
        this.type = "Unknown";
    }
  }

  type: "Unknown" | "BadRequest" | "Failed" | "Forbidden";
}
