import { CheckIcon } from "@chakra-ui/icons";
import { Text, HStack, VStack, Icon, ButtonProps } from "@chakra-ui/react";
import { throwError } from "@syla/shared/types/helpers/errors";
import React from "react";
import { Selector, KeyedOption, SelectorProps } from "./selector/Selector";

export function ItemSelector<T extends string>({
  currentValue,
  onValueSelected,
  isLoading,
  allValues,
  labels,
  descriptions,
  placeholder,
  isDisabled,
  contentProps,
  buttonProps,
}: {
  currentValue: T | undefined;
  onValueSelected: (value: T) => void;
  allValues: T[];
  labels: Record<T, string>;
  descriptions?: Record<T, string>;
  placeholder?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  contentProps?: SelectorProps<never, never>["contentProps"];
  buttonProps?: ButtonProps;
}) {
  type Option = {
    value: T;
    name: string;
  };
  const buildOption = (value: T): Option => ({
    name: labels[value] ?? throwError(`Missing label for ${value}`),
    value: value,
  });

  const options = Object.fromEntries(
    allValues.map((value) => [value, buildOption(value)])
  );

  const addKey = (value: Option): KeyedOption<Option, T> => ({
    key: value.value,
    value: options[value.value],
  });

  return (
    <Selector
      renderOptionContent={({ isSelected, option: { name, value } }) => (
        <Item
          name={name}
          hasCheck={isSelected}
          description={descriptions?.[value]}
        />
      )}
      SelectorContent={
        <Text textAlign="left" w="100%">
          {(currentValue && labels[currentValue]) ?? placeholder}
        </Text>
      }
      options={allValues.map(buildOption).map(addKey)}
      selectedOptions={currentValue ? [addKey(buildOption(currentValue))] : []}
      onChangeSelection={([option]) => onValueSelected(option.value.value)}
      isBusy={isLoading}
      isDisabled={isDisabled}
      contentProps={contentProps}
      buttonProps={buttonProps}
    />
  );
}

const Item = ({
  name,
  description,
  hasCheck,
}: {
  name: string;
  description: string | undefined;
  hasCheck: boolean;
}) => {
  return (
    <HStack w="100%" position="relative" bg="transparent" py="0.25rem">
      <VStack w="100%" alignItems="flex-start" spacing={1}>
        <Text
          color="gray.700"
          fontSize="sm"
          fontWeight={description ? "bold" : "normal"}
        >
          {name}
        </Text>
        {description && (
          <Text color="gray.500" fontSize="sm">
            {description}
          </Text>
        )}
      </VStack>
      <Icon
        as={CheckIcon}
        color="primary.500"
        visibility={hasCheck ? "visible" : "hidden"}
      />
    </HStack>
  );
};
