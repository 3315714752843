import {
  ResetPasswordResponse,
  ResetPasswordResponseStatusCodes,
} from "@syla/shared/types/responses/ResetPasswordResponse";
import axios from "axios";

export enum ResetPasswordErrorReason {
  UnknownError,
  BadRequest,
  Invalid,
}

export class ResetPasswordError extends Error {
  constructor(
    public errorReason: ResetPasswordErrorReason,
    public errorString?: string
  ) {
    super();
  }
}

export const resetPassword = async (
  token: string | null,
  password: string
): Promise<ResetPasswordResponse> => {
  try {
    const { data } = await axios.post("/users/reset-password", {
      token,
      password,
    });
    return data;
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      throw new ResetPasswordError(ResetPasswordErrorReason.UnknownError);
    }

    // handle known errors
    if (error.response.status == ResetPasswordResponseStatusCodes.Invalid) {
      throw new ResetPasswordError(ResetPasswordErrorReason.Invalid);
    }

    if (error.response.status == ResetPasswordResponseStatusCodes.BadRequest) {
      throw new ResetPasswordError(
        ResetPasswordErrorReason.BadRequest,
        error.response.data
      );
    }

    // default for other error
    throw new ResetPasswordError(ResetPasswordErrorReason.UnknownError);
  }
};
