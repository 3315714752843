import axios from "axios";

export const downloadTaxReportForFinancialYear = async (
  accountId: string,
  financialYearValue: string
): Promise<void> => {
  const { data } = await axios.get(
    `/accounts/${accountId}/download-financial-year-pdf`,
    {
      params: { financialYearValue },
      responseType: "blob",
    }
  );

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(data);
  link.setAttribute(
    "download",
    `Syla Tax Report ${financialYearValue.replace("/", "-")}.pdf`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return data;
};
