import Fuse from "fuse.js";
import { useMemo } from "react";
import { Path } from "react-hook-form";

export const useFuseSearch = <T>(
  data: T[],
  searchKeys: Path<T>[],
  searchTerm: string,
  options?: Fuse.IFuseOptions<T>
): T[] => {
  const fuse = useMemo(
    () =>
      new Fuse(data, {
        threshold: 0.1, // makes it more strict
        keys: searchKeys,
        ignoreLocation: true,
        ...options,
      }),
    [data, options, searchKeys]
  );

  const fuseSearchResults = useMemo(() => {
    if (searchTerm) {
      return fuse.search(searchTerm).map((i: any) => i.item);
    }
    return data;
  }, [data, fuse, searchTerm]);

  return fuseSearchResults;
};
