import { useState, useCallback, useMemo, useEffect } from "react";
import { useTransactionsStore } from "../store/transactionsStore";

export type SelectedTxProps = {
  selectedCount: number | undefined;
  setAllSelected: (value: ((prevState: boolean) => boolean) | boolean) => void;
  isSelected: (id: string) => boolean;
  selectedIds: string[];
  setSelectedIds: (
    value: ((prevState: string[]) => string[]) | string[]
  ) => void;
  clearSelected: () => void;
  allSelected: boolean;
  onSelect: (id: string) => void;
};

export type SelectedTxInputProps = {
  availableTransactionCount: number | undefined;
};
export const useSelectedList = ({
  availableTransactionCount,
}: SelectedTxInputProps): SelectedTxProps => {
  const [allSelected, setAllSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const groups = useTransactionsStore((state) => state.groups);

  // clear selected ids when allSelected is set
  useEffect(() => {
    if (allSelected) {
      setSelectedIds([]);
    }
  }, [allSelected]);

  const onSelect = useCallback(
    (id: string) => {
      // if all selected
      // set current page except clicked as selected
      // TODO this doesn't work properly?
      if (allSelected) {
        setAllSelected(false);
        if (groups) {
          setSelectedIds(Object.keys(groups).filter((gid) => gid != id));
        }
        return;
      }

      setSelectedIds((selected) => {
        if (selected.includes(id)) {
          return [...selected.filter((o) => o !== id)];
        }
        return [...selected, id];
      });
    },
    [allSelected, groups]
  );

  const clearSelected = useCallback(() => {
    setSelectedIds([]);
    setAllSelected(false);
  }, [setSelectedIds]);

  const isSelected = useCallback(
    (id: string) => (allSelected ? true : selectedIds.includes(id)),
    [allSelected, selectedIds]
  );

  const selectedCount = useMemo(
    () => (allSelected ? availableTransactionCount : selectedIds.length),
    [allSelected, availableTransactionCount, selectedIds.length]
  );

  return {
    onSelect,
    selectedIds,
    isSelected,
    clearSelected,
    setSelectedIds,
    allSelected,
    setAllSelected,
    selectedCount,
  };
};
