"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_VEZGO_CLIENT_ID = exports.REFERRAL_DISCOUNT = exports.REFERRAL_CODE_SEARCH_PARAM = exports.REFERRAL_CODE_COOKIE = exports.COINBASE_CLIENT_ID = exports.get_COINJAR_CLIENT_ID = exports.COINJAR_USE_SANDBOX = exports.get_HEAP_APP_ID = exports.DEFAULT_PARCEL_METHOD = exports.SYLA_EARLIEST_EVENT_DATE = void 0;
const AccountBase_1 = require("../models/AccountBase");
exports.SYLA_EARLIEST_EVENT_DATE = new Date(Date.UTC(2000, 6, 2));
exports.DEFAULT_PARCEL_METHOD = AccountBase_1.ParcelMethodType.FIFO;
const get_HEAP_APP_ID = (isProduction) => isProduction ? "3195749883" : "3311327584";
exports.get_HEAP_APP_ID = get_HEAP_APP_ID;
exports.COINJAR_USE_SANDBOX = false;
// other CoinJar props also set at
// packages/express-app/src/helpers/datasources/coinJar/coinJarConstants.ts
// packages/react-app/src/oAuth/getCoinJarAuthoriseUrl.ts
const get_COINJAR_CLIENT_ID = (isProduction) => isProduction || !exports.COINJAR_USE_SANDBOX
    ? "9k6xki1kiDEO9063174rudVLkPLVTkt3WnXIajGKR9U"
    : "AsLeEChN1gEvuISl9pgcikdLQxpjxxzjsBYJlxOxTVk";
exports.get_COINJAR_CLIENT_ID = get_COINJAR_CLIENT_ID;
// one used for both staging and prod
exports.COINBASE_CLIENT_ID = "f6e62a4000d0b5497062560c57ac913784c81f684aaa58bacae5ec63890c034c";
exports.REFERRAL_CODE_COOKIE = "syla_referral_code";
exports.REFERRAL_CODE_SEARCH_PARAM = "code";
exports.REFERRAL_DISCOUNT = "10%";
const get_VEZGO_CLIENT_ID = (isProduction) => isProduction ? "5004tbfqag3ckilnnr487mn1t0" : "2qcse39tkr9thd067c0v91rsvf";
exports.get_VEZGO_CLIENT_ID = get_VEZGO_CLIENT_ID;
