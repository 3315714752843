import { createStandaloneToast } from "@chakra-ui/react";
import { getWalletName, SyncState } from "@syla/shared/types/models/WalletBase";
import { secondsToMilliseconds } from "date-fns";
import { reSyncWallet as reSyncWalletRequest } from "../../api/wallet/reSyncWallet";
import { buildSyncErrorToastProps } from "../../helper/wallet/buildSyncErrorToastProps";
import { theme } from "../../theme";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import {
  queryClient,
  getAccountTransactionsCacheKey,
} from "../transactionsStore";
import { getAllAssetsCacheKey } from "../useQueryAssets";
import { buildSyncSuccessToastProps } from "./buildSyncSuccessToastProps";
import { WalletToSync } from "./syncWallet";
import { updateCachedWalletState } from "./updateCachedWalletState";

export const reSyncWallets = async (
  accountId: string,
  wallets: WalletToSync[]
) => {
  let singleWalletName: string | undefined = undefined;
  if (wallets.length == 1) {
    const [wallet] = wallets;
    singleWalletName = getWalletName({ wallet, dataSource: wallet.dataSource });
  }

  const walletIds = wallets.map((w) => w._id);

  updateCachedWalletState(accountId, walletIds, {
    syncState: SyncState.InProgress,
  });

  const toast = createStandaloneToast({ theme });
  toast({
    status: "info",
    title: `Started syncing ${singleWalletName || "Data Sources"}`,
    duration: secondsToMilliseconds(3),
    isClosable: true,
  });

  reSyncWalletRequest(
    accountId,
    wallets.map((w) => w._id)
  )
    .then(() => {
      // don't report success for just vezgo wallet sync
      // it will finish later
      if (wallets.length == 1 && wallets[0].dataSource.syncAuthType == "vezgo")
        return;

      checkStatusAndDoPendingWork();
      toast(buildSyncSuccessToastProps(wallets));
    })
    .catch((error) => {
      toast(buildSyncErrorToastProps(error));
    })
    .finally(() => {
      queryClient.invalidateQueries([
        ...getAccountTransactionsCacheKey(accountId),
      ]);
      queryClient.invalidateQueries(getAllAssetsCacheKey());
    });
};
