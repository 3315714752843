import { AccountSettingsPanel } from "../pages/AccountSettings";

export const buildDashboardLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/dashboard`;
export const buildDataSourcesLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/data-sources`;
export const buildTransactionsLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/transactions`;
export const buildTaxReportsLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/tax-reports`;
export const buildBalancesLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/balances`;

export const buildAccountSettingsLink = ({
  accountId,
  panel,
}: {
  accountId: string;
  panel?: AccountSettingsPanel;
}) => `/${accountId}/account-settings${panel ? `/${panel}` : ""}`;

export const buildUserSettingsLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/user-profile`;

export const buildReferralsLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/affiliate`;

export const buildPlansLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/plans`;

export const buildAccountsLink = ({ accountId }: { accountId: string }) =>
  `/${accountId}/accounts`;

export const buildFirstDataSourceLink = ({
  accountId,
  dataSourceId,
}: {
  accountId: string;
  dataSourceId?: string;
}) =>
  `/${accountId}/first-data-source` + (dataSourceId ? `/${dataSourceId}` : "");

export const buildFirstCustomDataSourceLink = ({
  accountId,
  customName,
}: {
  accountId: string;
  customName?: string;
}) =>
  `/${accountId}/first-custom-data-source` +
  (customName ? `/${customName}` : "");
