import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  EmailIcon,
  TelegramIcon,
  RedditIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import {
  HStack,
  VStack,
  Spinner,
  useToast,
  useClipboard,
  Text,
  Button,
  Link,
  TextProps,
  BoxProps,
  Grid,
  Divider,
  GridItem,
  GridProps,
  Box,
  useBreakpointValue,
  StackProps,
} from "@chakra-ui/react";
import {
  REFERRAL_CODE_SEARCH_PARAM,
  REFERRAL_DISCOUNT,
} from "@syla/shared/types/helpers/constants";
import { WithModelIds } from "@syla/shared/types/helpers/WithModelIds";
import {
  ReferralBase,
  ReferredUserStatus,
} from "@syla/shared/types/models/ReferralBase";
import { sumBy } from "lodash";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { createReferral } from "../api/user/createReferral";
import { manageAffiliateAccount } from "../api/user/manageAffiliateAccount";
import { setupAffiliateAccount } from "../api/user/setupAffiliateAccount";
import { ButtonVariant } from "../components/atoms/ButtonVariant";
import {
  PageContainer,
  PageHeadingContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { SkeletonRect } from "../components/atoms/Skeletons";
import { errorToastOptions } from "../components/molecules/errorToastOptions";
import { getPageUrl } from "../helper/getFullPageUrl";
import { redirectTo } from "../helper/RedirectTo";
import { useQueryGetAffiliateAccountStatus } from "../store/useQueryGetAffiliateAccountStatus";
import {
  useQueryGetReferrals,
  REFERRALS_QUERY_KEY,
} from "../store/useQueryReferrals";

const REFERRAL_COMMISSION_PERCENTAGE = "50%";
const HELP_ARTICLE =
  "https://help.syla.com.au/en/articles/8591016-refer-and-earn-program";

export const Affiliate = () => {
  const { singleColumn, wideAspect } = useLayoutSettings();

  return (
    <PageContainer>
      <PageHeadingContainer>
        <VStack alignItems="flex-start">
          <PageHeading>Refer and Earn</PageHeading>
          <Text color="gray.800">
            Share Syla to{" "}
            <b>earn {REFERRAL_COMMISSION_PERCENTAGE} commission</b> on
            purchases.
          </Text>
        </VStack>
      </PageHeadingContainer>

      <VStack alignItems={"flex-start"} spacing={10}>
        <Link
          href={HELP_ARTICLE}
          fontWeight="bold"
          color="red.700"
          isExternal={true}
        >
          About Refer and Earn <ExternalLinkIcon />
        </Link>
        <Grid
          gap={10}
          templateColumns={wideAspect ? "1fr 1fr" : "1fr"}
          w={wideAspect ? "70vw" : "100%"}
        >
          <ReferralLinkCardWrapper />
          {!singleColumn ? (
            <HStack spacing={5} h="100%" w="100%">
              <EarnInfoCard />
              <DiscountInfoCard />
            </HStack>
          ) : (
            <>
              <EarnInfoCard />
              <DiscountInfoCard />
            </>
          )}
          <ShareCard />
          {!singleColumn ? (
            <HStack spacing={5} h="100%" w="100%">
              <SignUpsCard />
              <PurchasesCard />
            </HStack>
          ) : (
            <>
              <SignUpsCard />
              <PurchasesCard />
            </>
          )}
          <CompleteOrManageCardWrapper />
        </Grid>
      </VStack>
    </PageContainer>
  );
};

function CompleteOrManageCardWrapper() {
  const { data: accountStatusResponse, isLoading: accountStatusLoading } =
    useQueryGetAffiliateAccountStatus();

  const isLoading = accountStatusLoading || !accountStatusResponse;
  const configured = !!accountStatusResponse?.usable;

  if (isLoading) return <Spinner />;

  return !configured ? <CompleteAccountCard /> : <ManageAccountCard />;
}

function ReferralLinkCardWrapper() {
  const { data, isStale } = useQueryGetReferrals();
  const { referrals } = data ?? {};

  return !referrals || isStale ? (
    <Spinner />
  ) : (
    <>
      {!referrals.length && <CreateLinkCard />}
      {!!referrals.length &&
        referrals
          .slice(0, 1)
          .map(({ _id, code }) => <ReferralLinkCard key={_id} code={code} />)}
    </>
  );
}

function ReferralLinkCard(props: {
  code: WithModelIds<ReferralBase<string>["code"]>;
}) {
  const link = `https://www.syla.com.au/?${REFERRAL_CODE_SEARCH_PARAM}=${encodeURIComponent(
    props.code
  )}`;

  const { onCopy: onCopyLink, hasCopied: hasCopiedLink } = useClipboard(link);

  return (
    <Card h="100%">
      <Grid templateRows="1fr auto" h="100%" w="100%">
        <GridItem>
          <CardContainer>
            <CardHeading>Referral link</CardHeading>
            {/* Link and Copy */}
            <HStack gridGap={2} justify="space-between" w="100%" wrap="wrap">
              <ReferralText>{link}</ReferralText>
              <Button leftIcon={<CopyIcon />} onClick={onCopyLink}>
                {!hasCopiedLink ? "Copy" : "Copied"}
              </Button>
            </HStack>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />
          <CardContainer>
            <CardSubtext>
              Share your unique referral link to earn{" "}
              {REFERRAL_COMMISSION_PERCENTAGE} commission on purchases.
            </CardSubtext>
          </CardContainer>
        </GridItem>
      </Grid>
    </Card>
  );
}

function ReferralText({ children }: React.PropsWithChildren<{}>) {
  return <Text fontSize="larger">{children}</Text>;
}

function CreateLinkCard() {
  const queryClient = useQueryClient();
  const {
    mutateAsync: create,
    isLoading: createRunning,
    isError: createLinkError,
  } = useMutation(createReferral, {
    onSuccess: () => queryClient.invalidateQueries([REFERRALS_QUERY_KEY]),
  });

  // handle errors
  const toast = useToast();
  useEffect(() => {
    if (createLinkError)
      toast({ ...errorToastOptions, title: "Unexpected error" });
  }, [createLinkError, toast]);

  return (
    <Card h="100%" w="100%">
      <CardContainer>
        <CardHeading>Create Link</CardHeading>
        <ButtonVariant
          color="red"
          content="Create Link"
          onClick={() => create()}
          isLoading={createRunning}
        />
      </CardContainer>
      <CardDivider />
      <CardContainer>
        <CardSubtext>
          Create your unique referral link to earn{" "}
          {REFERRAL_COMMISSION_PERCENTAGE} commission on purchases.
        </CardSubtext>
      </CardContainer>
    </Card>
  );
}

function ShareCard() {
  const { data, isStale } = useQueryGetReferrals();
  const isLoading = !data || isStale;

  const link = `https://www.syla.com.au/?code=${
    data?.referrals?.at(0)?.code ?? "XXX"
  }`;
  const message =
    "Hey, have you done your crypto tax yet? Syla is the most affordable way to get it sorted, " +
    "and you’ll also save on tax. Sign up below and you’ll get a 10% discount on any plan.";

  const plainTextMessage = `${message}\n${link}`;
  const { onCopy: onCopyLink, hasCopied: hasCopiedLink } =
    useClipboard(plainTextMessage);

  const ICON_PROPS = {
    size: 32,
    round: true,
  };

  return (
    <Card>
      <Grid templateRows="1fr auto" h="100%" w="100%">
        <GridItem>
          <CardContainer>
            <CardHeading>Share</CardHeading>
            <SkeletonRect isLoaded={!isLoading}>
              <CardContainer p={0} spacing={4}>
                {/* Social icons */}
                <HStack spacing={0} gridGap={1} wrap="wrap">
                  <EmailShareButton
                    url={link}
                    subject={"Save on crypto tax"}
                    body={message}
                  >
                    <EmailIcon {...ICON_PROPS} />
                  </EmailShareButton>
                  <TwitterShareButton url={link} title={message}>
                    <TwitterIcon {...ICON_PROPS} />
                  </TwitterShareButton>
                  <FacebookShareButton url={link} hashtag="#crypto">
                    <FacebookIcon {...ICON_PROPS} />
                  </FacebookShareButton>
                  <RedditShareButton url={link} title={message}>
                    <RedditIcon {...ICON_PROPS} />
                  </RedditShareButton>
                  <LinkedinShareButton url={link}>
                    <LinkedinIcon {...ICON_PROPS} />
                  </LinkedinShareButton>
                  <FacebookMessengerShareButton
                    url={link}
                    appId="316088524039462"
                  >
                    <FacebookMessengerIcon {...ICON_PROPS} />
                  </FacebookMessengerShareButton>
                  <TelegramShareButton url={link} title={message}>
                    <TelegramIcon {...ICON_PROPS} />
                  </TelegramShareButton>
                  <WhatsappShareButton url={link} title={message}>
                    <WhatsappIcon {...ICON_PROPS} />
                  </WhatsappShareButton>
                </HStack>

                {/* Plain text message */}
                <HStack spacing={3} alignItems="stretch">
                  <Box w="3px" bgColor="red.100" flexShrink={0} />
                  <Box color="gray.700">{plainTextMessage}</Box>
                </HStack>

                {/* Copy button */}
                <Button leftIcon={<CopyIcon />} onClick={onCopyLink}>
                  {!hasCopiedLink ? "Copy" : "Copied"}
                </Button>
              </CardContainer>
            </SkeletonRect>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />

          <CardContainer>
            <CardSubtext>Share more to earn more.</CardSubtext>
          </CardContainer>
        </GridItem>
      </Grid>
    </Card>
  );
}

function SignUpsCard() {
  const { data, isStale } = useQueryGetReferrals();

  const { referrals } = data ?? {};
  const count = !referrals
    ? 10
    : sumBy(referrals, (ref) => ref.referredUsers.length);

  return (
    <SmallCard>
      <SmallCardGrid>
        <GridItem>
          <CardContainer>
            <CardHeading>Sign ups</CardHeading>
            <SkeletonRect isLoaded={data && !isStale}>
              <BigNumber>{count}</BigNumber>
            </SkeletonRect>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />
          <CardContainer>
            <CardSubtext>
              Total sign ups who have used your unique link.
            </CardSubtext>
          </CardContainer>
        </GridItem>
      </SmallCardGrid>
    </SmallCard>
  );
}

function PurchasesCard() {
  const { data, isStale } = useQueryGetReferrals();

  const { referrals } = data ?? {};
  const count = !referrals
    ? 10
    : sumBy(
        referrals,
        (ref) =>
          ref.referredUsers.filter(
            (referredUser) =>
              referredUser.status == ReferredUserStatus.Purchased
          ).length
      );

  return (
    <SmallCard>
      <SmallCardGrid>
        <GridItem>
          <CardContainer>
            <CardHeading>Purchases</CardHeading>
            <SkeletonRect isLoaded={data && !isStale}>
              <BigNumber>{count}</BigNumber>
            </SkeletonRect>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />
          <CardContainer>
            <CardSubtext>
              Total sign ups that have purchased a plan.
            </CardSubtext>
          </CardContainer>
        </GridItem>
      </SmallCardGrid>
    </SmallCard>
  );
}

function EarnInfoCard() {
  return (
    <SmallCard>
      <SmallCardGrid>
        <GridItem>
          <CardContainer>
            <CardHeading>Earn commission</CardHeading>
            <BigNumber color="green.500">
              {REFERRAL_COMMISSION_PERCENTAGE}
            </BigNumber>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />
          <CardContainer>
            <CardSubtext>
              Earn {REFERRAL_COMMISSION_PERCENTAGE} on purchases.
            </CardSubtext>
          </CardContainer>
        </GridItem>
      </SmallCardGrid>
    </SmallCard>
  );
}

function DiscountInfoCard() {
  return (
    <SmallCard>
      <SmallCardGrid>
        <GridItem>
          <CardContainer>
            <CardHeading>Share discount</CardHeading>
            <BigNumber color="green.500">{REFERRAL_DISCOUNT}</BigNumber>
          </CardContainer>
        </GridItem>
        <GridItem>
          <CardDivider />
          <CardContainer>
            <CardSubtext>
              Anyone who uses your link will get a {REFERRAL_DISCOUNT} discount.
            </CardSubtext>
          </CardContainer>
        </GridItem>
      </SmallCardGrid>
    </SmallCard>
  );
}

function CompleteAccountCard() {
  const location = useLocation();
  const currentUrl = getPageUrl(location, {
    origin: true,
    path: true,
    search: true,
  });

  const {
    mutateAsync: setupAccount,
    isLoading: setupAccountRunning,
    error: setupAccountError,
  } = useMutation(setupAffiliateAccount);

  // handle errors
  const toast = useToast();
  useEffect(() => {
    if (setupAccountError)
      toast({ ...errorToastOptions, title: "Unexpected error" });
  }, [setupAccountError, toast]);

  return (
    <Card h="100%" w="100%">
      <CardContainer>
        <CardHeading>Complete account setup</CardHeading>
        <ButtonVariant
          color="red"
          leftIcon="external"
          content="Complete setup"
          isLoading={setupAccountRunning}
          onClick={() => {
            setupAccount({
              returnUrl: currentUrl,
              retryUrl: currentUrl,
            }).then(({ url }) => {
              redirectTo(url);
            });
          }}
        />
      </CardContainer>
      <CardDivider />
      <CardContainer>
        <CardSubtext>
          Complete your account setup so you can receive commission payouts.
        </CardSubtext>
      </CardContainer>
    </Card>
  );
}

function ManageAccountCard() {
  const {
    mutateAsync: manageAccount,
    isLoading,
    error: manageAccountError,
  } = useMutation(manageAffiliateAccount);
  // handle errors
  const toast = useToast();
  useEffect(() => {
    if (manageAccountError)
      toast({ ...errorToastOptions, title: "Unexpected error" });
  }, [manageAccountError, toast]);

  return (
    <Card h="100%" w="100%">
      <CardContainer>
        <CardHeading>Manage your payments</CardHeading>
        <ButtonVariant
          // color="default"
          leftIcon="external"
          content="Manage payments"
          isLoading={isLoading}
          onClick={() =>
            manageAccount().then(({ url }) => {
              window.open(url, "_blank");
            })
          }
        />
      </CardContainer>
      <CardDivider />
      <CardContainer>
        <CardSubtext>Manage your account and view your payouts.</CardSubtext>
      </CardContainer>
    </Card>
  );
}

function SmallCardGrid(props: GridProps) {
  return (
    <Grid templateRows="1fr auto" h="100%" w="100%">
      {props.children}
    </Grid>
  );
}

function BigNumber(props: TextProps) {
  return (
    <Text fontWeight="bold" fontSize="4xl" {...props}>
      {props.children}
    </Text>
  );
}

function Card(props: BoxProps) {
  return (
    <VStack shadow="md" borderWidth="1px" borderRadius="10px" {...props}>
      {props.children}
    </VStack>
  );
}

function CardDivider() {
  return <Divider h="1px" bgColor="black.300" />;
}

function CardSubtext(props: TextProps) {
  return (
    <Text color="gray.500" {...props}>
      {props.children}
    </Text>
  );
}

function CardHeading(props: TextProps) {
  return (
    <Text fontSize="larger" fontWeight="bold" {...props}>
      {props.children}
    </Text>
  );
}

function CardContainer(props: StackProps) {
  return (
    <VStack
      w="100%"
      padding={"20px"}
      spacing={2}
      alignItems="flex-start"
      {...props}
    >
      {props.children}
    </VStack>
  );
}

function SmallCard(props: Parameters<typeof Card>[0]) {
  return <Card w="100%" h="100%" {...props} />;
}

function useLayoutSettings() {
  const wideAspect = useBreakpointValue({
    base: false,
    "2xs": false,
    xs: false,
    md: false,
    lg: false,
    xl: true,
  });

  const singleColumn = useBreakpointValue({
    base: true,
    "2xs": true,
    xs: true,
    md: false,
    lg: false,
  });

  return {
    wideAspect,
    singleColumn,
  };
}
