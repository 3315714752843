import { DataSourceCode } from "./DataSourceBase";
import { LedgerBase, LedgerType } from "./LedgerBase";
import { ModelBase } from "./ModelBase";

export const MAX_TRANSACTION_DESCRIPTION_LENGTH = 1024;

export type TransactionTagType = TransactionType | LedgerType;

export interface TransactionBase<TId> extends ModelBase<TId> {
  type: TransactionTagType;
  ledgers: LedgerBase<TId>[];
  description?: string;
  needsReview?: NeedsReviewReasonBase[];

  /**
   * On an exchange data source this will be the hash of any on-chain deposit or withdrawal.
   * On a "chain" data source, this will be the transaction hash on that chain.
   */
  sourceIds?: TransactionSourceIdBase[];
  metadata?: TransactionMetadataBase[];
}

export enum TransactionType {
  Trade = "Trade",
  Mint = "Mint",
  Receive = "Receive",
  Send = "Send",
  Rebase = "Rebase",
  Wrap = "Wrap",
  AddLiquidity = "Add Liquidity",
  RemoveLiquidity = "Remove Liquidity",
  Transaction = "Transaction",
}

export enum NeedsReviewType {
  MissingAcquisitionParcel = "MissingAcquisitionParcel",
  MissingData = "MissingData",
  MissingMarketValue = "MissingMarketValue",
  MissingAssetCode = "MissingAssetCode",
  UnclassifiedLedgers = "UnclassifiedLedgers",
  WithdrawalInclusiveOfFee = "WithdrawalInclusiveOfFee",
  UnmatchedTransfer = "UnmatchedTransfer",
  IncompleteRebase = "IncompleteRebase",
}
export interface NeedsReviewReasonBase {
  type: NeedsReviewType;
}

/** Determine whether a given item should be actioned or not */
export function includeNeedsReviewItem({
  type,
}: NeedsReviewReasonBase): boolean {
  // exclude these for now since user cannot action them
  return ![
    NeedsReviewType.MissingData,
    NeedsReviewType.WithdrawalInclusiveOfFee,
  ].includes(type);
}

export enum MetadataKey {
  OrderSide = "Order Side",
  BankAccount = "Bank Account",
  Contract = "Contract",
  ContractAddress = "Contract Address",
  ContractMethod = "Contract Method",
  ContractMethodSignature = "Contract Method Signature",
  ContractMethodId = "Contract Method Id",
  LinkedDataSource = "Linked DataSource",

  // Temporary data storage, used for deriving pnl from
  // derivatives contracts. Should not be stored in DB.
  PositionAmount = "Position Amount",
  PositionValue = "Position Value",
  PositionContract = "Position Contract",
  PositionSettledIn = "Position Settled In",
}

export interface TransactionMetadataBase {
  name: MetadataKey;
  value: string;
}

export interface TransactionSourceIdBase {
  type: TransactionSourceIdType;
  id: string;
  dataSourceCode?: DataSourceCode;
  dataSource?: any; // TODO: Remove later (deprecated)
}

export enum TransactionSourceIdType {
  EventId = "EventId",
  OrderId = "OrderId",
  TransactionHash = "TransactionHash",
  BlockHash = "BlockHash",
  TransferId = "TransferId",
  TradeId = "TradeId",
  SentFromAddress = "SentFromAddress",
  SentToAddress = "SentToAddress",
  ReceivedFromAddress = "ReceivedFromAddress",
  ReceivedAtAddress = "ReceivedAtAddress",
  DepositId = "DepositId",
  WithdrawalId = "WithdrawalId",
  CorrelationId = "CorrelationId",
}
