import React, { useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { REFERRAL_CODE_COOKIE } from "@syla/shared/types/helpers/constants";
import { hoursToSeconds } from "date-fns";

import Cookies from "js-cookie";
import { Redirect, Route, Switch } from "react-router-dom";
import { SignUpPageContainer } from "../components/atoms/SignUpPageContainer";

import { CompleteVerificationComponent } from "../components/molecules/signUpAndLoginPages/CompleteVerificationComponent";
import { EmailVerification } from "../components/molecules/signUpAndLoginPages/EmailVerification";
import { ForgotPasswordForm } from "../components/molecules/signUpAndLoginPages/ForgotPasswordForm";
import { ForgotPasswordSent } from "../components/molecules/signUpAndLoginPages/ForgotPasswordSent";
import { ResetPasswordForm } from "../components/molecules/signUpAndLoginPages/ResetPasswordForm";
import { VerifyAccountInvite } from "../components/molecules/signUpAndLoginPages/VerifyAccountInvite";

import { SignUpContextProvider } from "../contexts/SignUpContext";
import { isDevelopment } from "../helper/environment";
import { SignIn } from "../pages/SignIn";
import { SignUp } from "../pages/SignUp";
import { useGetReferralCode } from "../helper/useGetReferralCode";

const SentryRoute = Sentry.withSentryRouting(Route);

// these routes should load even if the user is already logged in
export const UNAUTHED_PATHS = {
  ResetPassword: "/reset-password",
  ForgotPassword: "/forgot-password",
  ForgotPasswordSent: "/forgot-password-sent",
  Verify: "/verify",
  VerifyAccountInvite: "/verify-account-invite",
};

export const LoggedOutPageContainer = ({
  children,
}: React.PropsWithChildren<{}>) => (
  <Flex direction="row" w="100%" h="100%" overflowY="auto">
    {children}
  </Flex>
);

export const Unauthenticated = (): JSX.Element => {
  const referralCodeParam = useGetReferralCode();

  // set referral code cookie if URL param provided
  useEffect(() => {
    if (referralCodeParam) {
      const currentReferralCode = Cookies.get(REFERRAL_CODE_COOKIE);
      if (!currentReferralCode) {
        Cookies.set(REFERRAL_CODE_COOKIE, referralCodeParam, {
          domain: isDevelopment() ? undefined : ".syla.com.au",
          secure: true,
          sameSite: isDevelopment() ? "None" : "Strict",
          expires: hoursToSeconds(30 * 24), // n.b. 400 days is maximum max-age
        });
      }
    }
  }, [referralCodeParam]);

  return (
    <LoggedOutPageContainer>
      <Switch>
        <SentryRoute exact path="/sign-in">
          <SignIn />
        </SentryRoute>
        <SentryRoute exact path="/sign-up">
          <SignUpContextProvider>
            <SignUp />
          </SignUpContextProvider>
        </SentryRoute>
        <SentryRoute exact path={UNAUTHED_PATHS.ForgotPassword}>
          <SignUpPageContainer>
            <ForgotPasswordForm />
          </SignUpPageContainer>
        </SentryRoute>
        <SentryRoute exact path={UNAUTHED_PATHS.ForgotPasswordSent}>
          <SignUpPageContainer>
            <ForgotPasswordSent />
          </SignUpPageContainer>
        </SentryRoute>
        <SentryRoute exact path={UNAUTHED_PATHS.ResetPassword}>
          <SignUpPageContainer>
            <ResetPasswordForm />
          </SignUpPageContainer>
        </SentryRoute>
        <SentryRoute exact path="/email-verification">
          <SignUpPageContainer>
            <EmailVerification />
          </SignUpPageContainer>
        </SentryRoute>
        <SentryRoute exact path={UNAUTHED_PATHS.Verify}>
          <SignUpPageContainer>
            <CompleteVerificationComponent />
          </SignUpPageContainer>
        </SentryRoute>
        <SentryRoute exact path={UNAUTHED_PATHS.VerifyAccountInvite}>
          <SignUpPageContainer>
            <VerifyAccountInvite />
          </SignUpPageContainer>
        </SentryRoute>

        <SentryRoute>
          <Redirect to="/sign-in" />
        </SentryRoute>
      </Switch>
    </LoggedOutPageContainer>
  );
};
