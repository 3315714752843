import { withoutNullable } from "@syla/shared/types/helpers/withoutNullable";
import axios from "axios";

import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetRecentOrdersResponse } from "@syla/shared/types/responses/GetRecentOrdersResponse";
import { parseOrderResponseItem } from "@syla/shared/types/helpers/parseOrderResponseItem";
import { OrderResponse } from "../../types/order/orderRespond";

export const getRecentOrders = async (
  accountId: string
): Promise<OrderResponse[]> => {
  const { data }: { data: TransportType<GetRecentOrdersResponse<string>> } =
    await axios.get(`/accounts/${accountId}/orders/recent-orders`);

  return withoutNullable(data.map((item) => parseOrderResponseItem(item)));
};
