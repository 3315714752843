import { Input, FormControl, FormErrorMessage, Box } from "@chakra-ui/react";
import { validatePasswordInput } from "@syla/shared/helpers/validatePassword";
import {
  AccountSubType,
  AccountType,
} from "@syla/shared/types/models/AccountBase";
import { AxiosError } from "axios";
import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router";
import isEmail from "validator/lib/isEmail";
import { UserContext } from "../../../contexts/UserContext";

import { useNavigator, Route } from "../../../routers/navigator";
import { ButtonVariant } from "../../atoms/ButtonVariant";
import { SignInFormContainer } from "../../atoms/Containers";
import { PasswordRequirements } from "../../atoms/PasswordRequirements";
import { SingleSelectBox } from "../../atoms/singleSelectBox";
import { GoogleSignInForm } from "../GoogleSignInForm";
import { ACTIVITY_TYPE_OPTIONS, ACCOUNT_TYPE_OPTIONS } from "./accountOptions";
import {
  Form,
  Label,
  SignInAndTermsLinks,
  SignUpToSyla,
} from "./FormComponents";

interface SignUpHookForm {
  userEmail: string;
  userPassword: string;
  userAccountType: string;
  userAccountSubType: string;
}

export const SignUpForm = () => {
  // get the register function from the user context
  const { register: contextRegister } = useContext(UserContext);
  const location = useLocation();
  const prefillEmail = new URLSearchParams(location.search).get("email") || "";

  // state for sign up error
  const [signUpError, setSignUpError] = useState<string | undefined>();

  const navigate = useNavigator();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<SignUpHookForm>({
    defaultValues: {
      userAccountType: AccountType.Individual,
      userAccountSubType: AccountSubType.Investor,
      userEmail: isEmail(prefillEmail) ? prefillEmail : undefined,
    },
  });

  const onSubmit = async (data: SignUpHookForm) => {
    if (
      !data.userEmail ||
      !data.userPassword ||
      !data.userAccountType ||
      !data.userAccountSubType
    )
      return;
    // reset sign up error
    setSignUpError(undefined);
    const { userEmail, userPassword, userAccountType, userAccountSubType } =
      data;

    try {
      await contextRegister({
        email: userEmail,
        password: userPassword,
        accountType: userAccountType as AccountType,
        accountSubType: userAccountSubType as AccountSubType,
      });

      // proceed to email verification screen
      navigate({
        route: Route.EmailVerification,
        state: {
          email: userEmail,
        },
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 403) {
        setSignUpError(
          "The email provided is not eligible for an account with Syla"
        );
        return;
      }
      setSignUpError("Unexpected error occurred, please try again later");
    }
  };

  return (
    <SignInFormContainer>
      <Box px="8px" mb="30px">
        <SignUpToSyla />
      </Box>
      {/* Google sign-up support */}
      <GoogleSignInForm />

      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box px="8px">
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors.userAccountType !== undefined}
            mb="30px"
          >
            <Label content="Account Type" />
            <Controller
              control={control}
              name="userAccountType"
              render={({ field: { onChange, value } }) => (
                <SingleSelectBox
                  options={ACCOUNT_TYPE_OPTIONS}
                  selectedOption={value}
                  placeholder="Select an account type"
                  onChangeSelection={(selection) => onChange(selection)}
                  selectBtnProps={{ height: "40px" }}
                />
              )}
            />
          </FormControl>
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors.userAccountSubType !== undefined}
            mb="30px"
          >
            <Label content="Activity Type" />
            <Controller
              control={control}
              name="userAccountSubType"
              render={({ field: { onChange, value } }) => (
                <SingleSelectBox
                  options={ACTIVITY_TYPE_OPTIONS}
                  selectedOption={value}
                  placeholder="Select an activity type"
                  onChangeSelection={(selection) => onChange(selection)}
                  selectBtnProps={{ height: "40px" }}
                />
              )}
            />
          </FormControl>
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors.userEmail !== undefined}
            mb="20px"
          >
            <Label content="Email address" />
            <Input
              type="email"
              placeholder="Type your email"
              {...register("userEmail", {
                required: "Email is missing",
                validate: (email?: string) =>
                  isEmail(email || "") ? undefined : "Invalid email address",
              })}
              bgColor="white.0"
            />
            {errors.userEmail && (
              <FormErrorMessage>{errors.userEmail.message}</FormErrorMessage>
            )}
          </FormControl>
          <FormControl
            isDisabled={isSubmitting}
            isInvalid={errors.userPassword !== undefined}
            mb="30px"
          >
            <Label content="Password" />
            <Input
              type="password"
              placeholder="Type your password"
              {...register("userPassword", {
                required: "Password is missing",
                validate: validatePasswordInput,
              })}
              bgColor="white.0"
            />
            {errors.userPassword && (
              <>
                <FormErrorMessage>
                  {errors.userPassword.message}
                </FormErrorMessage>
                {PasswordRequirements()}
              </>
            )}
          </FormControl>
          <ButtonVariant
            content="Sign Up"
            color="red"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => {}}
            spam="spam"
            type="submit"
          />
          {/*
          <ButtonVariant
            content="Back"
            disabled={isSubmitting}
            spam="spam"
            my="10px"
            onClick={goToPreviousTab}
          />
        */}
          {/* display sign up error message if an error occurred */}
          {signUpError && (
            <FormControl isInvalid={signUpError !== undefined}>
              <FormErrorMessage>{signUpError}</FormErrorMessage>
            </FormControl>
          )}
          <SignInAndTermsLinks disabled={isSubmitting} />
        </Box>
      </Form>
    </SignInFormContainer>
  );
};
