import { PatchLedger } from "@syla/shared/types/requests/TransactionRequests";
import { Ledger } from "../../types/ledger";

export const formatLedgerToPatchLedger = (
  ledger: Ledger | undefined
): PatchLedger | undefined =>
  ledger && {
    _id: ledger._id,
    assetId: ledger.asset._id,
    amount: ledger.amount.abs().format().text,
    marketValue: ledger.marketValue ? ledger.marketValue.format().text : "",
    type: ledger.type,
    delete: false,
  };
