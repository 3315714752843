import { Box, Flex, FlexProps, Spacer, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AiFillFile } from "react-icons/ai";
import { GoCloudUpload } from "react-icons/go";
import { MdErrorOutline, MdOutlineDeleteOutline } from "react-icons/md";

interface FileDropZoneProps extends FlexProps {
  storedFiles: File[];
  onAddStoreFiles: (file: File[]) => void;
  onRemoveStoreFiles: (index: number) => void;
  maxFiles?: number;
}

export const FileDropZone = ({
  storedFiles,
  onAddStoreFiles,
  onRemoveStoreFiles,
  maxFiles,
  ...props
}: FileDropZoneProps): JSX.Element => {
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      onAddStoreFiles(acceptedFiles);
    },
    [onAddStoreFiles]
  );

  const duplicateFileValidator = (newFile: File) => {
    if (storedFiles.filter((file) => file.name === newFile.name).length !== 0) {
      return {
        code: "duplicate-file-name",
        message: `File with name ${newFile.name} is already existed.`,
      };
    }
    return null;
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ".csv",
    validator: duplicateFileValidator,
    onDropAccepted,
    maxFiles,
  });

  return (
    <Flex direction="column" {...props}>
      {/* ----------------------- Display stored files -----------------------  */}
      {storedFiles.length !== 0 && (
        <Flex direction="column" mb="20px">
          <Header>Uploaded Files</Header>
          {storedFiles.map((file, index) => (
            <FileRow key={file.name}>
              <Box w="20px">
                <AiFillFile color="#4d4d4d" size="20px" />
              </Box>
              <FileName>
                {file.name} - {file.size / 1000} KB
              </FileName>
              <Spacer />
              <RemoveBtn onClick={() => onRemoveStoreFiles(index)}>
                <MdOutlineDeleteOutline size="20px" color="#ff3600" />
                Remove
              </RemoveBtn>
            </FileRow>
          ))}
        </Flex>
      )}
      {/* ----------------------- Drop Zone area -----------------------  */}
      {/* only display the upload dropzone if the max files prop is undefined or if stored files is less than max files */}
      {(!maxFiles || storedFiles.length < maxFiles) && (
        <DropZoneContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <GoCloudUpload size="40px" color="#ccc" />
          <InstructionText>
            <Text as="span" color="red.500">
              Upload files
            </Text>{" "}
            or drag and drop
          </InstructionText>
          <InstructionText>CSV files only</InstructionText>
        </DropZoneContainer>
      )}
      {/* ----------------------- Display rejected files -----------------------  */}
      <Flex direction="column" mt="30px">
        {fileRejections.map((rejectedFile) => (
          <Flex key={rejectedFile.file.name} direction="column">
            <Flex alignItems="center">
              <MdErrorOutline color="#ff3600" fontSize="16px" />
              <Text fontSize="0.875rem" ml="5px">
                {rejectedFile.file.name}
              </Text>
            </Flex>
            {rejectedFile.errors.map((error) => (
              <Text
                key={error.code}
                fontSize="0.875rem"
                pl="30px"
                color="#ff3600"
              >{`-  ${error.message}`}</Text>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

const DropZoneContainer = styled(Flex)((props) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  height: "170px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "#e5e5e5",
  borderStyle: "dashed",
  backgroundColor: "#fff",
  color: "#4d4d4d",
  outline: "none",
  transition: "border 0.24s ease-in-out",
  cursor: "pointer",
}));

const InstructionText = styled(Text)`
  font-size: 1rem;
  color: #666;
`;

const FileRow = styled(Flex)`
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  padding: 10px;
`;

const Header = styled(Text)`
  font-size: 0.875rem;
  font-weight: bold;
  color: #4d4d4d;
  margin-bottom: 10px;
`;

const RemoveBtn = styled(Flex)`
  font-size: 0.875rem;
  color: #ff3600;
  cursor: pointer;
`;

const FileName = styled(Text)`
  font-size: 0.875rem;
  color: #4d4d4d;
  margin-left: 10px;
  max-width: 200px;
`;
