import { Decimal } from "@syla/shared/decimal";
import { AssetType } from "@syla/shared/types/models/AssetBase";
import { AssetHolding } from "../../../types/wallet/wallet";

export const DUMMY_HOLDINGS: AssetHolding[] = [
  {
    balance: Decimal.from("100"),
    asset: {
      _id: "1",
      type: AssetType.Token,
      name: "Bitcoin",
      code: "BTC",
      image: "btc.png",
    },
  },
  {
    balance: Decimal.from("100"),
    asset: {
      _id: "1",
      type: AssetType.Token,
      name: "Ethereum",
      code: "ETH",
      image: "eth.png",
    },
  },
  {
    balance: Decimal.from("100"),
    asset: {
      _id: "1",
      type: AssetType.Token,
      name: "DogeCoin",
      code: "DOGE",
      image: "doge.png",
    },
  },
];
