import { Flex } from "@chakra-ui/react";
import {
  DataSourceBase,
  DataSourceType,
} from "@syla/shared/types/models/DataSourceBase";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  buildFirstDataSourceLink,
  buildFirstCustomDataSourceLink,
} from "../../../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";

import { SelectDataSource } from "../../addDataSource/SelectDataSource";
import { SkipForNowBtns } from "../SkipForNowBtns";

export const SelectFirstDataSource = () => {
  const history = useHistory();
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // state for the data sources search query
  const [dataSourcesSearchQuery, setDataSourcesSearchQuery] = useState("");

  return (
    <Flex direction="column">
      <SkipForNowBtns />

      <Flex
        direction="column"
        bgColor="white.0"
        borderWidth="2px"
        borderRadius="5px 5px 0 0"
        borderColor="white.500"
        p="20px 15px 10px 15px"
      >
        <SelectDataSource
          dataSourcesSearchQuery={dataSourcesSearchQuery}
          dataSourceSearchQueryChanged={setDataSourcesSearchQuery}
          onClickDataSource={(source: DataSourceBase<never>) => {
            if (source.type == DataSourceType.Custom) {
              history.push(
                buildFirstCustomDataSourceLink({
                  accountId,
                  customName: dataSourcesSearchQuery,
                })
              );
            } else {
              history.push(
                buildFirstDataSourceLink({
                  accountId,
                  dataSourceId: source._id,
                })
              );
            }
          }}
        />
      </Flex>
    </Flex>
  );
};
