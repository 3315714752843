import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { CreateAccountRequestBody } from "@syla/shared/types/requests/CreateAccountRequest";
import { CreateAccountResponseBody } from "@syla/shared/types/responses/CreateAccountResponse";
import axios from "axios";

export type CreateAccountResult = TransportType<
  CreateAccountResponseBody<string>
>;

export const createAccount = async (
  props: CreateAccountRequestBody<string>
): Promise<CreateAccountResult> => {
  const response = await axios.post<
    TransportType<CreateAccountResponseBody<string>>
  >(`/accounts`, props);
  return response.data;
};
