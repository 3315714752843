import { DataSourceSupportedInputs } from "@syla/shared/types/models/DataSourceBase";
import { DataSourceInputType } from "../../../helper/dataSource/DataSourceInputType";

/** Get the `DataSourceInputType` corresponding to a given import tab based on DataSourceSupportedInputs */
export function getInputTypesPerTabIndex(
  supportedInputs: DataSourceSupportedInputs,
  fileImport = false
): DataSourceInputType[] {
  return [
    ...(supportedInputs.sync && !fileImport ? [DataSourceInputType.Api] : []),
    ...(supportedInputs.file || fileImport ? [DataSourceInputType.Csv] : []),
    DataSourceInputType.Custom,
  ];
}
