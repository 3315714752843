import { Decimal } from "@syla/shared/decimal";
import { HoldingsValue } from "../../../../helper/wallet/HoldingsValue";
import { AssetHolding } from "../../../../types/wallet/wallet";

export function getAccountHoldingsValue({
  holdingsValue,
  assetHoldings,
}: {
  holdingsValue: HoldingsValue | undefined;
  assetHoldings: AssetHolding[] | undefined;
}): Decimal | undefined {
  return assetHoldings && assetHoldings.length == 0
    ? Decimal.from("0")
    : holdingsValue?.getAccountTotals().marketValue;
}
