import {
  Text,
  Flex,
  Button,
  Spinner,
  Grid,
  useBreakpointValue,
} from "@chakra-ui/react";
import {
  DataSourceType,
  ImportStatus,
} from "@syla/shared/types/models/DataSourceBase";
import { orderBy, min } from "lodash";
import React, { useMemo } from "react";

import { DataSource } from "../../../../types/dataSource/dataSource";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";

export const DataSourceOptions = ({
  dataSourcesSearchQuery,
  options,
  loading,
  onClickDataSource,
}: {
  dataSourcesSearchQuery: string;
  options?: DataSource[];
  loading: boolean;
  onClickDataSource: (source: DataSource) => void;
}) => {
  const sortedOptions = useMemo(
    () =>
      orderBy(options, [
        // sort by support status
        (dataSource) =>
          min([
            getImportStatusRank(dataSource.fileImportStatus),
            getImportStatusRank(dataSource.syncImportStatus),
          ]),
        // then by name
        (dataSource) => dataSource.name,
      ]),
    [options]
  );

  const twoColumn = useBreakpointValue({
    base: false,
    "2xs": true,
    sm: true,
  });

  return (
    <Flex bg="white.500" p="5px" w="100%">
      {sortedOptions && !loading ? (
        <Grid
          w="100%"
          templateColumns={twoColumn ? "1fr 1fr" : "1fr"}
          gap={"0.2rem"}
        >
          {sortedOptions.map((source) => (
            <DataSourceOption
              key={source._id}
              onClick={() => onClickDataSource(source)}
              name={source.name}
              image={source.image}
            />
          ))}
          <DataSourceOption
            key={"customDataSource"}
            onClick={() =>
              onClickDataSource({ type: DataSourceType.Custom } as any)
            }
            name={"Custom Data Source"}
            subtitle={dataSourcesSearchQuery}
          />
        </Grid>
      ) : (
        <Spinner my="10px" />
      )}
    </Flex>
  );
};

const DataSourceOption = ({
  image,
  name,
  subtitle,
  onClick,
}: {
  name: string;
  subtitle?: string;
  image?: string;
  onClick: () => void;
}) => (
  <Button
    justifyContent="flex-start"
    alignItems="center"
    border="1px"
    borderColor="white.500"
    bgColor="white.0"
    px="15px"
    h="60px"
    w="100%"
    onClick={onClick}
  >
    <ImageWithMissingSrc
      src={image}
      alt={`${name} icon`}
      w="30px"
      mr="5px"
      borderRadius="6px"
    />
    <Flex direction={"column"} alignItems="flex-start">
      <Text
        fontSize="0.8rem"
        whiteSpace="normal"
        textAlign="left"
        wordBreak={"break-word"}
      >
        {name}
      </Text>
      <Text whiteSpace={"normal"} wordBreak={"break-word"}>
        {subtitle}
      </Text>
    </Flex>
  </Button>
);

const getImportStatusRank = (status: ImportStatus | undefined) => {
  switch (status) {
    case ImportStatus.Off:
      return 4;
    case ImportStatus.Alpha:
      return 3;
    case ImportStatus.Beta:
      return 2;
    case ImportStatus.Gold:
      return 1;
  }
  return 4;
};
