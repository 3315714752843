import React from "react";
import { ButtonVariant, ButtonVariantProps } from "./ButtonVariant";

export const ImportAssistButton = ({
  ...props
}: Omit<ButtonVariantProps, "content">) => (
  <ButtonVariant
    id="importAssistTrigger"
    content="Request assisted import"
    onClick={() => {
      /* !!important - clear the focused element so as not to interfere with typing in Intercom */
      (document.activeElement as HTMLElement | null)?.blur();
    }}
    color="red"
    {...props}
  />
);
