import { ListItemProps, ListItem, ListIcon } from "@chakra-ui/react";
import React from "react";
import { FaCheck } from "react-icons/fa";

export const TickListItem = ({
  children,
  ...rest
}: { children: React.ReactNode } & ListItemProps) => (
  <ListItem {...rest}>
    <ListIcon as={FaCheck} color="green.500" />
    {children}
  </ListItem>
);
