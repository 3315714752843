import { Text, InputGroup, InputLeftElement, Input } from "@chakra-ui/react";
import { groupBy } from "lodash";
import React, { useMemo, useState, ChangeEvent } from "react";
import { isDesktop } from "react-device-detect";
import { BiSearchAlt2 } from "react-icons/bi";
import { useFilterDataSources } from "../../../../hooks/useFilterDataSources";
import { useFuseSearch } from "../../../../hooks/useFuseSearch";
import { useQueryDataSources } from "../../../../store/useQueryDataSources";
import { DataSourceTypeFilter } from "../../../../types/dataSource/dataSource";
import { DataSourceOptions } from "./DataSourceOptions";
import { DataSourceFilterButtons } from "./DataSourceFilterButtons";

export const SelectDataSource = ({
  dataSourcesSearchQuery,
  dataSourceSearchQueryChanged,
  onClickDataSource,
}: {
  dataSourcesSearchQuery: string;
  dataSourceSearchQueryChanged: React.Dispatch<React.SetStateAction<string>>;
  onClickDataSource: (source) => void;
}) => {
  // query for data sources data
  const { data: dataSourcesData, isLoading: dataSourcesIsLoading } =
    useQueryDataSources();

  // get all the unique types from data sources data, will be used to dynamically display available filters
  const dataSourceTypes = useMemo(() => {
    if (!dataSourcesData) return ["all"];
    const uniqueTypes = groupBy(dataSourcesData, (to) => to.type);
    return ["all", ...Object.keys(uniqueTypes).map((type) => type)];
  }, [dataSourcesData]);

  // state for the data source type filter
  const [selectedType, setSelectedType] = useState<DataSourceTypeFilter>("all");

  // filter the data sources by type
  const typeFilteredDataSources = useFilterDataSources(
    dataSourcesData || [],
    selectedType
  );

  // filter the data sources by search query
  const searchFilteredDataSources = useFuseSearch(
    typeFilteredDataSources,
    ["name"],
    dataSourcesSearchQuery
  );

  return (
    <>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <BiSearchAlt2 color="#999999" />
        </InputLeftElement>
        <Input
          _focus={{ boxShadow: "none", borderColor: "#000" }}
          type="text"
          placeholder="Search for your data source"
          value={dataSourcesSearchQuery}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            dataSourceSearchQueryChanged(event.target.value)
          }
          autoFocus={isDesktop}
        />
      </InputGroup>
      <DataSourceFilterButtons
        options={dataSourceTypes}
        selected={selectedType}
        setSelected={setSelectedType}
      />
      <Text fontSize="0.9rem" color="black.700">
        Results
      </Text>
      <DataSourceOptions
        dataSourcesSearchQuery={dataSourcesSearchQuery}
        options={searchFilteredDataSources}
        loading={dataSourcesIsLoading}
        onClickDataSource={onClickDataSource}
      />
    </>
  );
};
