import { Decimal } from "@syla/shared/decimal";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import {
  GetTaxReportSummaryResponse,
  TaxReportSummary,
} from "@syla/shared/types/responses/TaxReport";
import axios from "axios";

export const returnTaxReportSummary = async (
  accountId: string,
  financialYearValue: string
): Promise<GetTaxReportSummaryResponse> => {
  const { data }: { data: TransportType<GetTaxReportSummaryResponse> } =
    await axios.post(`/accounts/${accountId}/tax-report-summary`, {
      financialYearValue,
    });

  let summary: TaxReportSummary | undefined;

  if (data.summary) {
    summary = {
      capitalGainOrLoss: Decimal.from(data.summary.capitalGainOrLoss),
      foreignCurrencyGainOrLoss: Decimal.from(
        data.summary.foreignCurrencyGainOrLoss
      ),
      exemptGainOrLoss: Decimal.from(data.summary.exemptGainOrLoss),
      otherIncome: Decimal.from(data.summary.otherIncome),
      nonAssessableIncome: Decimal.from(data.summary.nonAssessableIncome),
      nonDeductibleExpenses: Decimal.from(data.summary.nonDeductibleExpenses),
      otherExpenses: Decimal.from(data.summary.otherExpenses),
      salesOfTradingStock: Decimal.from(data.summary.salesOfTradingStock),
      costOfSales: Decimal.from(data.summary.costOfSales),
      netNonTaxableIncome: Decimal.from(data.summary.netNonTaxableIncome),
      total: Decimal.from(data.summary.total),
      transactionTypeCounts: data.transactionTypeCounts,
    };
  }

  return {
    transactionTypeCounts: data.transactionTypeCounts,
    summaryDeniedReason: data.summaryDeniedReason,
    summary,
  };
};
