import { getAccountShortName } from "@syla/shared/types/models/AccountAccessBase";
import { AccountAccessDetails } from "@syla/shared/types/responses/GetAccountsResponse";
import create from "zustand";
import { immer } from "zustand/middleware/immer";

export type CurrentAccountProps = {
  accountId: string;
  contactEmail: string | undefined;
  nickname: string | undefined;
  shortName: string | undefined;
  setAccountProps: (
    account: Pick<
      AccountAccessDetails<any>,
      "_id" | "fullName" | "contactEmail" | "nickname"
    >
  ) => void;
  setAccountId: (accountId: string) => void;
};
export const NO_ACCOUNT = "";

export const useCurrentAccountStore = create(
  immer<CurrentAccountProps>((set) => ({
    accountId: NO_ACCOUNT,
    contactEmail: undefined,
    nickname: undefined,
    shortName: undefined,
    setAccountId: (accountId) =>
      set((state) => {
        state.accountId = accountId;
      }),
    setAccountProps: (account) =>
      set((state) => {
        state.accountId = account._id;
        state.shortName = getAccountShortName(account);
        state.nickname = account.nickname;
        state.contactEmail = account.contactEmail;
      }),
  }))
);
