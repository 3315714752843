import { throwError } from "@syla/shared/types/helpers/errors";
import React, { useMemo } from "react";
import { groupBy, sortBy } from "lodash";
import { Flex, Button, Text, Image } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { AiOutlineLeft } from "react-icons/ai";
import { useTransactionsStore } from "../../../../../store/transactionsStore";
import { InfoToolTip } from "../../../../atoms/InfoToolTip";
import { getTransactionTypeIcon } from "../../../../../helper/transaction/getTransactionTypeOptions";
import { useTransactionValues } from "../../../../../hooks/useTransactionValues";
import { Transaction } from "../../../../../types/transaction";
import { TradeSummaryList } from "../TradeSummaryList";
import { BalanceTableHeaders, BalanceTableRow } from "../BalanceDetails";
import {
  BalanceDetail,
  getBalanceDetailsFromLedgers,
} from "../../../../../helper/ledger/aggregateBalanceInLedgers";
import { formatDateTime } from "../../../../../helper/formatDateTime";
import { SubTransactionRow } from "./SubTransactionRow";

export const OrderOverview = ({
  groupId,
  onClickBack,
  onClickTransaction,
}: {
  groupId: string;
  onClickBack: () => void;
  onClickTransaction: (transaction: Transaction) => void;
}): JSX.Element => {
  const orderResponse =
    useTransactionsStore((state) => state.groups?.[groupId]?.data) ??
    throwError(new Error("Group should be set"));

  const { allLedgers } = useTransactionValues(orderResponse.transactions);
  const transactionsGroupByDate = groupBy(
    sortBy(orderResponse.transactions, (to) => to.date),
    (to) => to.date
  );

  const balanceDetails: BalanceDetail[] = useMemo(() => {
    return getBalanceDetailsFromLedgers(allLedgers);
  }, [allLedgers]);

  return (
    <Flex direction="column" bgColor="white.0" w="100%" maxW="896px" zIndex={2}>
      {/* ------------------------------- Header ------------------------------- */}
      <Flex borderWidth="0 0 1px 0" alignItems="center" p="8px 20px 8px 3px">
        <Button
          mt="8px"
          w="200px"
          onClick={onClickBack}
          bgColor="transparent"
          fontWeight="normal"
          _hover={{ bgColor: "transparent" }}
          _active={{ bgColor: "transparent" }}
          justifyContent="flex-start"
        >
          <AiOutlineLeft fontSize="1.5rem" />
        </Button>
      </Flex>
      <Flex
        borderWidth="0 0 1px 0"
        direction="row"
        alignItems="center"
        p="8px 30px"
      >
        <Image src={getTransactionTypeIcon("group")} alt="" h="24px" mr="5px" />
        <Flex mr="50px">
          <Title>Group</Title>
        </Flex>
        <Flex w="80%">
          <TradeSummaryList allLedgers={allLedgers} />
        </Flex>
        <Flex ml="20px" mr="20px">
          <Text>
            {`contains ${orderResponse.transactions.length} transactions`}
          </Text>
        </Flex>
      </Flex>
      {/* ------------------------------- Description ------------------------------- */}
      {/*
        <Flex direction="column" minH="100px" p="20px">
          <Title>Description</Title>
          <Text fontSize="0.875rem" color="black.550">
            {orderResponse.order.description === ""
              ? "No description specified"
              : orderResponse.order.description}
          </Text>
        </Flex>
      */}
      {/* ------------------------------- Sub transactions ------------------------------- */}
      <Flex justifyContent="space-between" p="0 20px 20px 20px">
        <Title
          m="20px"
          pb="10px"
          w="100%"
          borderWidth="0 0 1px 0"
          borderColor="white.500"
          mb="0"
        >
          Sub-transactions
        </Title>
        <Flex mr="20px">
          <InfoToolTip
            label="This is a group containing multiple sub-transactions. You can click on any sub-transaction to view detailed information and tax outcomes."
            wrap
            size="20px"
          />
        </Flex>
      </Flex>
      {Object.keys(transactionsGroupByDate).map((date) => (
        <>
          <Flex p="15px 30px">
            <Text color="black.550" fontSize="0.875rem">
              {formatDateTime(new Date(date), { subSeconds: false })}
            </Text>
          </Flex>
          <Flex direction="column" mb="24px">
            {transactionsGroupByDate[date].map((transaction) => (
              <SubTransactionRow
                transaction={transaction}
                key={transaction._id.toString()}
                onClick={() => onClickTransaction(transaction)}
              />
            ))}
          </Flex>
        </>
      ))}
      {/* ------------------------------ Balances ------------------------------ */}
      <Flex direction="column" mb="24px">
        <Flex justifyContent="space-between" p="0 20px 20px 20px">
          <Title>Balances</Title>
          <Flex mr="20px">
            <InfoToolTip
              label="This transaction resulted in the following final balances in the location where the transaction occured and the following final portfolio balances"
              wrap
              size="20px"
            />
          </Flex>
        </Flex>
        <BalanceTableHeaders showChange />
        {balanceDetails.map((balanceDetail, index) => {
          return (
            <BalanceTableRow
              balanceDetail={balanceDetail}
              key={index}
              showChange
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

const Title = styled(Text)`
  color: #333333;
  font-size: 1rem;
  font-weight: 400;
`;
