import { Box, Flex, VStack, HStack, StackProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { SCREEN_SIZE } from "../../constants/common";
import background from "../../images/background.svg";

export const PageContainer = styled(Flex, {
  shouldForwardProp: (props) => props !== "isBrowser",
})((props) => ({
  flexDirection: "column",
  backgroundColor: "#fbfbfb",
  padding: "20px 15px 20px 15px",
  "@media(min-width: 501px)": {
    padding: "20px 30px 20px 30px",
  },
  maxWidth: SCREEN_SIZE.XXL,
  height: "100%",
}));

export const PageHeadingContainer = styled(HStack)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  @media (min-width: 501px) {
    align-items: center;
  }
`;

export const SignInParentContainer = styled(Flex)`
  background-color: red;
  width: 100%;
  min-height: 100vh;
  flex-direction: row;
`;

export const SignInLHSContainer = styled(Flex)`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  background-image: url(${background});
  background-size: cover;
  padding: 50px 0;
  @media (max-width: ${SCREEN_SIZE.LG}) {
    display: none;
  }
`;

export const SignInRHSContainer = styled(Flex)`
  flex: 1;
  background-color: #fbfbfb;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  overflow-y: auto;
`;

export const ContentContainer = styled(Box)`
  background: #ffffff;
  margin-bottom: 30px;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-color: #f5f5f5;
  overflow-x: hidden;
  //min-height: 300px;
  // This box shadow style is copying the chakra ui 'md' boxShadow style
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

export const CardContentContainer = styled(ContentContainer)`
  margin-bottom: 0;
`;

export const ContentHeadingContainer = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

export const FormContainer = styled(Flex)`
  background: #fbfbfb;
  width: 100%;
  flex-direction: column;
  max-width: 500px;
  padding: 30px;
  margin: auto;
`;

export const SignInFormContainer = styled(FormContainer)`
  max-width: 451px;
  padding: 30px;
  margin: auto;
`;

export const InfoContainer = styled(Flex)`
  background: #ffffff;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  // height: 100%;
  // max-height: 400px;
  padding: 30px 30px;
  flex-direction: column;
`;

export const ModalContainer = styled(Flex)`
  background: #ffffff;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 30px;
  border-radius: 5px;
  flex-direction: column;
`;
export const StdVStack = (props: StackProps) => (
  <VStack alignItems="flex-start" w="100%" {...props} />
);
