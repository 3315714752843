import { primaryOrSecondary } from "../../helpers/primaryOrSecondary";
import { Id } from "../helpers/Id";
import { Populatable } from "../helpers/Populatable";
import { AccountAccessDetails } from "../responses/GetAccountsResponse";
import { UserBase } from "./UserBase";

export enum AccountAccessRole {
  Owner = "owner",
  Administrator = "admin",
  Accountant = "accountant",
  Editor = "editor",
}

export enum AccountPermission {
  ChangeOwnership = "ChangeOwnership",
  DeleteAccount = "DeleteAccount",
  ChangeAccess = "ChangeAccess",
  PurchaseSubscription = "PurchaseSubscription",
  ManageSubscription = "ManageSubscription",
  ChangeAccountDetails = "ChangeAccountDetails",
  ChangeTaxSettings = "ChangeTaxSettings",
}

export const AccountRolePermissions: Record<
  AccountAccessRole,
  AccountPermission[]
> = {
  [AccountAccessRole.Owner]: Object.values(AccountPermission), // all access
  [AccountAccessRole.Administrator]: [
    AccountPermission.ChangeAccess,
    AccountPermission.PurchaseSubscription,
    AccountPermission.ManageSubscription,
    AccountPermission.ChangeAccountDetails,
    AccountPermission.ChangeTaxSettings,
  ],
  [AccountAccessRole.Accountant]: [
    AccountPermission.ChangeAccess,
    AccountPermission.PurchaseSubscription,
    AccountPermission.ManageSubscription,
    AccountPermission.ChangeAccountDetails,
    AccountPermission.ChangeTaxSettings,
  ],
  [AccountAccessRole.Editor]: [],
};

export interface AccountAccessBase<TId> extends Id<TId> {
  role: AccountAccessRole;
  user: Populatable<TId, UserBase<TId>>;
  nickname?: string;
}

export const AccountAccessRoleName: Record<AccountAccessRole, string> = {
  [AccountAccessRole.Owner]: "Owner",
  [AccountAccessRole.Administrator]: "Administrator",
  [AccountAccessRole.Accountant]: "Accountant",
  [AccountAccessRole.Editor]: "Editor",
};

export function roleHasPermission(
  role: AccountAccessRole,
  permission: AccountPermission
): boolean {
  return AccountRolePermissions[role].includes(permission);
}

export function getAccountShortName({
  nickname,
  fullName,
}: Pick<AccountAccessDetails<any>, "fullName" | "nickname">): string {
  return primaryOrSecondary(fullName, nickname) ?? "Unknown";
}

const MAX_INITIALS = 3;

export function getAccountInitials({
  fullName,
}: Pick<AccountAccessDetails<any>, "fullName">) {
  return fullName
    .toUpperCase()
    .split(" ") // Split the string into an array of words
    .map((word) => word.replaceAll(/[^a-zA-Z0-9]/g, "")) //remove special characters
    .map((word) => word[0]) // Map each word to its first character
    .join("") // Join the array of first characters into a string
    .substring(0, MAX_INITIALS);
}
