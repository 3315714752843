import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Flex,
  Divider as RowDivider,
  Image,
  Text,
  Box,
  Spinner,
  useToast,
  Link,
} from "@chakra-ui/react";
import { captureException } from "@sentry/react";
import { permissionFor } from "@syla/shared/types/helpers/permissions";
import { AccountSubType } from "@syla/shared/types/models/AccountBase";
import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { downloadBglXml } from "../../../api/user/downloadBglXml";
import { sendBglXml, SendBglXmlError } from "../../../api/user/sendBglXml";
import { getRelatedBglProduct } from "../../../constants/bglProduct";
import { isProduction } from "../../../helper/environment";
import { getFullPageUrl } from "../../../helper/getFullPageUrl";
import {
  canPurchaseSubscription,
  canUpgradeSubscription,
} from "../../../helper/permissions";

import { TaxReportsContext } from "../../../pages/TaxReports";
import { useNavigator, Route } from "../../../routers/navigator";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useGlobalVariablesStore } from "../../../store/globalVariablesStore";
import { useQueryGetAccount } from "../../../store/useQueryGetAccount";

import {
  ContentHeadingContainer,
  CardContentContainer,
} from "../../atoms/Containers";
import { TaxReportHeading } from "../../atoms/Headings";
import { UnexpectedErrorString } from "../../atoms/UnexpectedError";
import { ActionOrPurchaseButton } from "./ActionOrPurchaseButton";

export const BglXmlSummary = (): JSX.Element => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigator();

  const selectedFinancialYear = useGlobalVariablesStore(
    (state) => state.selectedFinancialYear
  );

  const [syncing, setSyncing] = useState(false);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  const relatedBglProduct = getRelatedBglProduct({
    accountType: account?.accountType,
  });

  const {
    isLoading: contextLoading,
    isCalculating,
    subscriptions,
    txCount,
  } = useContext(TaxReportsContext);

  const { hasPermission: canSync } = permissionFor({
    action: "write-bgl-sync",
    subscriptions,
    transactionCount: txCount,
    account: account,
  });

  const { hasPermission: canDownload } = permissionFor({
    action: "get-bgl-sync-xml",
    subscriptions,
    transactionCount: txCount,
    account: account,
  });

  const canPurchase =
    canPurchaseSubscription({ account, subscriptions }) ||
    canUpgradeSubscription({ subscriptions });

  const isLoading = contextLoading || accountIsLoading;
  const blockSync = isCalculating;

  // don't show for traders
  if (account?.accountSubType == AccountSubType.Trader) return <></>;

  const handlePurchase = () =>
    navigate({
      route: Route.Plans,
      accountId,
      state: {
        referringUrl: getFullPageUrl(location),
      },
    });

  // handle download click
  const handleDownload = async () => {
    if (!selectedFinancialYear) {
      toast({
        title: "Financial year not selected",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }

    try {
      setSyncing(true);
      await downloadBglXml(accountId, selectedFinancialYear.value);
    } catch (error: any) {
      toast({
        title: "Error downloading bql xml",
        description: "Please contact support.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }

    setSyncing(false);
  };

  // handle sync click
  const handleSync = async () => {
    if (!selectedFinancialYear) {
      toast({
        title: "Financial year not selected",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });

      return;
    }

    try {
      setSyncing(true);
      await sendBglXml(accountId, selectedFinancialYear.value);
      toast({
        title: "Sync Data to BGL Successful",
        description: "Your data sync to BGL was successful.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      captureException(error);
      toast({
        title: "Error sync bgl data",
        description:
          error instanceof SendBglXmlError
            ? error.errorString
            : UnexpectedErrorString,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
    setSyncing(false);
  };

  return (
    <CardContentContainer>
      <ContentHeadingContainer>
        <Flex direction="row" alignItems="center">
          <TaxReportHeading>Premium Integrations</TaxReportHeading>
        </Flex>
      </ContentHeadingContainer>
      <RowDivider mt="20px" />
      <Flex
        overflowY="auto"
        direction="column"
        maxH="220px"
        my="10px"
        flexDirection="row"
        width="100%"
        px="30px"
        justifyContent="space-between"
      >
        <Flex direction="row" alignItems="center" mr="10px">
          <Image src={relatedBglProduct.icon} alt="" h="24px" />
          <Text ml="20px" fontSize="0.875rem" color="black.700">
            {relatedBglProduct.name}
          </Text>
          <Link
            ml="20px"
            fontSize="0.875rem"
            color="#B11B1B"
            whiteSpace="nowrap"
            isExternal
            href={relatedBglProduct.url}
          >
            learn more <ExternalLinkIcon mx="2px" />
          </Link>
        </Flex>
        {isLoading ? (
          <Box flex={1} justifyContent="center" display="flex">
            <Spinner
              color="red.500"
              emptyColor="red.200"
              thickness="2.5px"
              size="md"
            />
          </Box>
        ) : (
          <>
            {
              // keep download button in dev for testing
              !isProduction() && (
                <ActionOrPurchaseButton
                  canPurchase={canPurchase}
                  hasActionPermission={canDownload}
                  blockAction={blockSync}
                  blockedText="Please wait"
                  isLoading={isLoading}
                  handleAction={handleDownload}
                  handlePurchase={handlePurchase}
                  inProgress={syncing}
                />
              )
            }
            <ActionOrPurchaseButton
              actionText="Sync"
              canPurchase={canPurchase}
              hasActionPermission={canSync}
              blockAction={blockSync}
              blockedText="Please wait for your tax calculations to complete before syncing data"
              isLoading={isLoading}
              handleAction={handleSync}
              handlePurchase={handlePurchase}
              inProgress={syncing}
            />
          </>
        )}
      </Flex>
    </CardContentContainer>
  );
};
