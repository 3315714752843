import React from "react";
import { SignUpInfo } from "../molecules/overlayBoxes/SignUpInfo";
import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "./Containers";

export const SignUpPageContainer = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => (
  <SignInParentContainer>
    <SignInLHSContainer>
      <SignUpInfo />
    </SignInLHSContainer>
    <SignInRHSContainer>{children}</SignInRHSContainer>
  </SignInParentContainer>
);
