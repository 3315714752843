import { CheckIcon } from "@chakra-ui/icons";
import {
  Flex,
  Text,
  useDisclosure,
  BoxProps,
  Box,
  useOutsideClick,
  FlexProps,
  TextProps,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { find } from "lodash";
import React, { useRef } from "react";
import { FaCaretDown } from "react-icons/fa";

interface SingleSelectBoxProps extends BoxProps {
  options: { value: string; label: string }[];
  selectedOption: string | undefined;
  onChangeSelection: (selection: string | undefined) => void;
  required?: boolean;
  placeholder: string;
  selectBtnProps?: FlexProps;
  selectBtnTextProps?: TextProps;
  optionTextProps?: TextProps;
  optionContainerProps?: FlexProps;
  optionProps?: FlexProps;
  selectedIndication?: boolean;
  alignRight?: boolean;
  allowUnSelect?: boolean;
}

export const SingleSelectBox = ({
  options,
  selectedOption,
  onChangeSelection,
  required,
  placeholder,
  selectBtnProps,
  selectBtnTextProps,
  optionContainerProps,
  optionProps,
  optionTextProps,
  selectedIndication = false,
  allowUnSelect = false,
  ...props
}: SingleSelectBoxProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref, handler: () => onClose() });

  const handleOnClick = (option: { value: string; label: string }) => {
    if (selectedOption === option.value && allowUnSelect) {
      onChangeSelection("");
    } else {
      onChangeSelection(option.value);
    }
    onClose();
  };
  return (
    <Flex ref={ref} {...props} position="relative">
      <SelectBtn
        onClick={() => onToggle()}
        onKeyPress={(e) => {
          if (!(e.key === "Enter" || e.key === " ")) return;
          onToggle();
        }}
        tabIndex={0}
        required={required}
        selectedOption={selectedOption}
        selectedIndication={selectedIndication}
        selectBtnProps={selectBtnProps}
      >
        <SelectBtnText selectBtnTextProps={selectBtnTextProps}>
          {selectedOption
            ? find(options, { value: selectedOption })?.label
            : placeholder}
        </SelectBtnText>

        <FaCaretDown color="#808080" />
      </SelectBtn>
      {isOpen && (
        <OptionContainer optionContainerProps={optionContainerProps}>
          {options.map((option) => (
            <OptionBox
              key={option.value}
              onClick={() => {
                handleOnClick(option);
              }}
              onKeyPress={(e) => {
                if (!(e.key === "Enter" || e.key === " ")) return;
                handleOnClick(option);
              }}
              tabIndex={0}
              optionProps={optionProps}
            >
              <OptionText optionTextProps={optionTextProps}>
                {option.label}
              </OptionText>
              {option.value === selectedOption && (
                <Box w="3px" pr="20px">
                  <CheckIcon w={3.5} color="#ff3600" />
                </Box>
              )}
            </OptionBox>
          ))}
        </OptionContainer>
      )}
    </Flex>
  );
};

const SelectBtn = styled(Flex, {
  shouldForwardProp: (prop) =>
    prop !== "selectedIndication" &&
    prop !== "selectedOption" &&
    prop !== "selectBtnProps",
})((props) => {
  let borderWidth = "1px";
  let borderColor = "gray.300";
  let backgroundColor = "#fff";
  if (props.selectedOption && props.selectedIndication) {
    borderColor = "#000";
    backgroundColor = "#f5f5f5";
  }
  if (props.required) {
    borderWidth = "2px";
    borderColor = "#ff3600";
  }
  return {
    backgroundColor,
    borderWidth,
    borderColor,
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 10px",
    cursor: "pointer",
    position: "relative",
    "&:focus": {
      borderColor: "#000",
      borderWidth: "1px",
      boxShadow: "none !important",
    },
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "&:active": {
      backgroundColor: "#e5e5e5",
    },
    ...props.selectBtnProps,
  };
});

const SelectBtnText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "selectBtnTextProps",
})((props) => ({
  color: "#4d4d4d",
  fontSize: "0.875rem",
  fontWeight: "bold",
  marginRight: "5px",
  whiteSpace: "nowrap",
  ...props.selectBtnTextProps,
}));

const OptionContainer = styled(Flex, {
  shouldForwardProp: (prop) => prop !== "optionContainerProps",
})((props) => ({
  flexDirection: "column",
  position: "absolute",
  top: "50px",
  width: "100%",
  zIndex: "2",
  backgroundColor: "#fff",
  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.08)",
  borderRadius: "5px",
  ...props.optionContainerProps,
}));

const OptionBox = styled(Flex, {
  shouldForwardProp: (prop) => prop !== "optionProps",
})((props) => ({
  cursor: "pointer",
  width: "auto",
  padding: "10px 0 10px 10px",
  borderWidth: "1px",
  borderColor: "#fff #fff #f5f5f5",
  justifyContent: "space-between",
  fontSize: "0.875rem",
  "&:hover": {
    backgroundColor: "#f5f5f5",
    borderColor: "#f5f5f5",
  },
  "&:focus": {
    borderColor: "#000",
  },
  ...props.optionProps,
}));

const OptionText = styled(Text, {
  shouldForwardProp: (prop) => prop !== "optionTextProps",
})((props) => ({
  color: "#4d4d4d",
  fontSize: "0.875rem",
  whiteSpace: "nowrap",
  marginRight: "5px",
  ...props.optionTextProps,
}));
