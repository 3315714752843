import { useMutation } from "react-query";
import {
  updateAccount,
  UpdateAccountProps,
} from "../api/account/updateAccount";
import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";

export const useMutationUpdateAccount = (accountId: string) =>
  useMutation((props: UpdateAccountProps) => updateAccount(accountId, props), {
    mutationKey: [...ACCOUNT_META_QUERY_KEY, accountId],
    onSettled: () => {
      queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
    },
  });
