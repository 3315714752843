import axios from "axios";

import { GetAssetsResponse } from "@syla/shared/types/responses/GetAssetsResponse";
import { Asset } from "../../types/asset/asset";

export const getAssets = async (filter?: string): Promise<Asset[]> => {
  const { data }: { data: GetAssetsResponse<string> } = await axios.get(
    `/assets`,
    {
      params: {
        filter,
      },
    }
  );
  return data.assets;
};
