import { Flex, Text, useDisclosure, Spacer } from "@chakra-ui/react";

import React, { Dispatch, useContext, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UserContext } from "../../../../contexts/UserContext";
import { DataSourceInputType } from "../../../../helper/dataSource/DataSourceInputType";
import { getDataSourceInstruction } from "../../../../helper/dataSource/getDataSourceInstructions";

import {
  AddDataSourceForm,
  DataSource,
} from "../../../../types/dataSource/dataSource";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { DrawerHeading } from "../../../atoms/Headings";
import { ImportError } from "../../../atoms/ImportError";
import { Form } from "../../../molecules/forms/FormComponents";
import { InstructionBox } from "../../../molecules/overlayBoxes/InstructionBox";
import { useAddDataSourceContext } from "../AddDataSourceContext";
import { useImportContext } from "../ImportContext";
import { ImportDataSource } from "../ImportDataSource";
import { Device } from "../../../../types/user/device";
import { AdvancedOptions } from "./AdvancedOptions";

export const DataSourceImportDrawerContent = ({
  onClose,
  selectedDataSource,
  setSelectedDataSource,
  setPreventClose,
}: {
  onClose: () => void;
  selectedDataSource: DataSource;
  setSelectedDataSource: (value: DataSource | undefined) => void;
  setPreventClose: Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const { userDevice } = useContext(UserContext);
  // Instruction Box state
  const { isOpen: showInstruction, onToggle: onToggleInstruction } =
    useDisclosure({
      defaultIsOpen: userDevice === Device.Desktop ? true : false,
    });

  const {
    state: {
      importComplete,
      importInProgress,
      authInProgress,
      importType,
      importStatus,
      importError,
      importReady,
    },
    actions: { beginImport },
  } = useImportContext();

  const showInstructionsOption = !importComplete && !importInProgress;

  const {
    state: { dataSourcesSearchQuery },
  } = useAddDataSourceContext();

  const customNameInitial = selectedDataSource.name ?? dataSourcesSearchQuery;
  // hook form
  const formMethods = useForm<AddDataSourceForm>({
    defaultValues: {
      storedFiles: [],
      customName: customNameInitial,
    },
  });

  // get the submitting state from form methods
  const { handleSubmit, watch } = formMethods;

  // Allow easy close once import is in completed state
  useEffect(() => {
    setPreventClose(!importComplete);
  }, [setPreventClose, importComplete]);

  const nameRequired = !selectedDataSource.name;

  const importInProgressText = useMemo(() => {
    if (importInProgress) return "Syncing...";

    if (importType == DataSourceInputType.Api) return "Secure Sync";

    return "Secure Import";
  }, [importType, importInProgress]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(beginImport)} noValidate>
        <DrawerHeading>Add Data Source</DrawerHeading>
        <Flex
          direction="column"
          h="calc(100% - 70px)"
          overflowY="auto"
          overflowX="hidden"
        >
          {/* ---------------------- Drawer Body ----------------------- */}
          <Flex direction="column" p="15px">
            {showInstructionsOption && (
              <Text
                onClick={() => onToggleInstruction()}
                fontSize="0.875rem"
                textDecoration="underline"
                fontWeight="500"
                color="red.500"
                cursor="pointer"
              >
                {showInstruction ? "Hide instructions" : "View instructions"}
              </Text>
            )}
            {showInstructionsOption && showInstruction && (
              <InstructionBox
                instructions={
                  getDataSourceInstruction({
                    dataSource: selectedDataSource,
                    inputType: importType,
                    importStatus,
                  }) ?? ""
                }
                isFloat
              />
            )}
            <ImportDataSource
              selectedDataSource={selectedDataSource}
              nameRequired={nameRequired}
            />
          </Flex>
          <Spacer />
          {/* ---------------------- Drawer Footer ----------------------- */}
          <Flex
            direction="column"
            w="100%"
            pb="16px"
            px="24px"
            position="sticky"
            bottom="0px"
            bgColor="white.0"
          >
            {/* ---------------------- Add wallet error ----------------------- */}
            {importError && (
              <ImportError
                error={importError}
                customFormat={importType == DataSourceInputType.Custom}
              />
            )}
            {!importInProgress && !importComplete && (
              <AdvancedOptions
                captureName={!nameRequired}
                disabled={importInProgress || authInProgress}
              />
            )}
            {!importComplete && (
              <ButtonVariant
                content={importInProgressText}
                outlineType="solid"
                color="red"
                spam="spam"
                mb="10px"
                onClick={() => undefined}
                disabled={
                  authInProgress ||
                  importInProgress ||
                  !importReady ||
                  // disable submit when no files selected
                  (importType != DataSourceInputType.Api &&
                    !watch("storedFiles")?.length)
                }
                type="submit"
              />
            )}
            {selectedDataSource && !importComplete && (
              <ButtonVariant
                content="Back"
                spam="spam"
                outlineType="outlineGray"
                onClick={() => setSelectedDataSource(undefined)}
                disabled={importInProgress || authInProgress}
              />
            )}
            {(!selectedDataSource || importComplete) && (
              <ButtonVariant
                content="Close"
                spam="spam"
                outlineType="outlineGray"
                onClick={() => {
                  onClose();
                }}
                disabled={importInProgress || authInProgress}
              />
            )}
          </Flex>
        </Flex>
      </Form>
    </FormProvider>
  );
};
