"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWalletName = exports.WalletImportMethod = exports.getRateLimitErrorMessage = exports.SyncWalletLimitType = exports.SyncWalletAuthErrorReason = exports.SyncWalletErrorDetailCode = exports.FileImportState = exports.SyncState = void 0;
const date_fns_1 = require("date-fns");
var SyncState;
(function (SyncState) {
    SyncState["InProgress"] = "InProgress";
    SyncState["Error"] = "Error";
    SyncState["Init"] = "Init";
})(SyncState = exports.SyncState || (exports.SyncState = {}));
var FileImportState;
(function (FileImportState) {
    FileImportState["InProgress"] = "InProgress";
    FileImportState["Error"] = "Error";
    FileImportState["Init"] = "Init";
})(FileImportState = exports.FileImportState || (exports.FileImportState = {}));
var SyncWalletErrorDetailCode;
(function (SyncWalletErrorDetailCode) {
    SyncWalletErrorDetailCode["INVALID_AUTH"] = "INVALID_AUTH";
    SyncWalletErrorDetailCode["RATE_LIMIT"] = "RATE_LIMIT";
    SyncWalletErrorDetailCode["CUSTOM"] = "CUSTOM";
    SyncWalletErrorDetailCode["INVALID_ADDRESS"] = "INVALID_ADDRESS";
    SyncWalletErrorDetailCode["OUTAGE"] = "OUTAGE";
    SyncWalletErrorDetailCode["LIMIT_EXCEEDED"] = "LIMIT_EXCEEDED";
    SyncWalletErrorDetailCode["UNKNOWN"] = "UNKNOWN";
})(SyncWalletErrorDetailCode = exports.SyncWalletErrorDetailCode || (exports.SyncWalletErrorDetailCode = {}));
var SyncWalletAuthErrorReason;
(function (SyncWalletAuthErrorReason) {
    SyncWalletAuthErrorReason["InvalidKey"] = "InvalidKey";
    SyncWalletAuthErrorReason["InsufficientPermission"] = "InsufficientPermission";
    SyncWalletAuthErrorReason["ExcessivePermission"] = "ExcessivePermission";
    SyncWalletAuthErrorReason["Expired"] = "Expired";
})(SyncWalletAuthErrorReason = exports.SyncWalletAuthErrorReason || (exports.SyncWalletAuthErrorReason = {}));
var SyncWalletLimitType;
(function (SyncWalletLimitType) {
    SyncWalletLimitType["RECORD"] = "RECORD";
    SyncWalletLimitType["TRANSACTION"] = "TRANSACTION";
    SyncWalletLimitType["DATA_SOURCE"] = "DATA_SOURCE";
})(SyncWalletLimitType = exports.SyncWalletLimitType || (exports.SyncWalletLimitType = {}));
function getRateLimitErrorMessage({ createdMs, timespanMs, vendor, }) {
    // time may have already elapsed since error was created
    const waitRemaining = timespanMs != null ? timespanMs - (Date.now() - createdMs) : undefined;
    const tryAgainMessage = waitRemaining == null
        ? `Please give it another try in **a while**`
        : waitRemaining > 0
            ? `Please give it another try in **${(0, date_fns_1.formatDuration)((0, date_fns_1.intervalToDuration)({ start: 0, end: waitRemaining }))}**`
            : `You can try it again now`;
    return {
        title: "Connection Temporarily Paused",
        message: `${vendor
            ? `**${vendor.trim()}** paused your connection`
            : `The connection was paused`} for syncing too often. 
    ${tryAgainMessage}.`,
    };
}
exports.getRateLimitErrorMessage = getRateLimitErrorMessage;
var WalletImportMethod;
(function (WalletImportMethod) {
    WalletImportMethod["API"] = "API";
    WalletImportMethod["CSV"] = "CSV";
})(WalletImportMethod = exports.WalletImportMethod || (exports.WalletImportMethod = {}));
const getWalletName = ({ wallet, dataSource, }) => (wallet === null || wallet === void 0 ? void 0 : wallet.customName) || dataSource.name;
exports.getWalletName = getWalletName;
