export const getFullPageUrl = (location) =>
  `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

export const getPageUrl = (
  location,
  components: {
    origin?: boolean;
    path?: boolean;
    search?: boolean;
    hash?: boolean;
  }
) =>
  `${(components.origin && window.location.origin) || ""}${
    (components.path && location.pathname) || ""
  }${(components.search && location.search) || ""}${
    (components.hash && location.hash) || ""
  }`;
