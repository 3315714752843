import { Box, Divider, Text, useToast, HStack } from "@chakra-ui/react";
import { GoogleLogin } from "@react-oauth/google";
import React, { useContext } from "react";
import { LoginWithGoogleError } from "../../api/authentication/loginWithGoogle";
import { UserContext } from "../../contexts/UserContext";
import { Route, useNavigator } from "../../routers/navigator";
import { errorToastOptions } from "./errorToastOptions";
// import { ButtonVariant } from "../atoms/ButtonVariant";

// const window = global as any;

export const GoogleSignInForm = (): JSX.Element => {
  const { loginWithGoogle } = useContext(UserContext);
  const toast = useToast();
  const navigate = useNavigator();

  async function handleSuccessfulAuth(credentialResponse: {
    clientId?: string;
    credential?: string;
  }) {
    console.debug({ credentialResponse });
    try {
      const { credential } = credentialResponse;
      if (!credential)
        throw new Error("credential missing in credentialResponse");

      // router will handle initial redirect
      const { defaultAccountId, newUser } = await loginWithGoogle({
        credential,
      });
      navigate({
        route: newUser ? Route.FirstLogin : Route.Home,
        accountId: defaultAccountId,
        replace: true,
      });
    } catch (error) {
      // handle errors
      console.debug("handleSuccessfulAuth error", error);

      if (!(error instanceof LoginWithGoogleError) || error.type == "Unknown") {
        toast({
          ...errorToastOptions,
          title: "Google Sign-In failed. Please try again later",
        });
        return;
      }

      switch (error.type) {
        case "BadRequest":
        case "Failed":
          toast({
            ...errorToastOptions,
            title: "Google Sign-In failed",
          });
          return;
        case "Forbidden":
          toast({
            ...errorToastOptions,
            title:
              "Google Sign-In failed. The email associated with this Google account is not eligible for an account with Syla",
          });
          return;
      }
    }
  }

  return (
    /* n.b. Use position=relative to anchor the position=absolute child */
    <Box pl={"8px"}>
      <Box display="inline">
        {/* Anchor for google sign-in button */}
        {/* maxW=185 is used to prevent button from being super wide before it fully loads */}
        {/* n.b. Use position=absolute to prevent divs below from jumping as button loads */}
        <Box maxW="185px">
          <GoogleLogin
            useOneTap
            use_fedcm_for_prompt
            onSuccess={handleSuccessfulAuth}
            onError={() => {
              toast({
                ...errorToastOptions,
                title: "Google Sign-In failed.",
              });
            }}
          />
        </Box>

        {/* Revoke button, used for debugging */}
        {
          // <ButtonVariant
          //   // display="none"
          //   content={"Revoke"}
          //   onClick={() => {
          //     window.google.accounts.id.revoke(
          //       "bogdan@syla.com.au",
          //       ({ successful }: { successful: boolean }) => {
          //         window.google.accounts.id.disableAutoSelect();
          //         window.alert(successful ? "Revoke success" : "Revoke failed");
          //       }
          //     );
          //   }}
          // />
        }
        {/* n.b. the google button size is 44px */}
        <HStack mt="20px" mb="20px">
          <Divider color="white.1000" />
          <Text>OR</Text>
          <Divider color="white.1000" />
        </HStack>
      </Box>
    </Box>
  );
};
