import React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

interface IAlertBar {
  status?: "success" | "warning" | "error" | undefined;
  content: string;
  onClose: () => void;
}

const bgColor = {
  success: "#EBFDFD",
  warning: "#FEF8EC",
  error: "#FCEDED",
};

const fontColor = {
  success: "#089171",
  warning: "#916208",
  error: "#B11B1B",
};

/**
 * @description The default alert bar has no icon(status) with the success color theme
 */
export const AlertBar = ({
  status = undefined,
  content,
  onClose,
}: IAlertBar) => (
  <Alert status={status} bgColor={bgColor[status ?? "success"]} mb="20px">
    {status && <AlertIcon />}
    <AlertDescription color={fontColor[status ?? "success"]}>
      {content}
    </AlertDescription>
    <CloseButton position="absolute" right="8px" top="8px" onClick={onClose} />
  </Alert>
);
