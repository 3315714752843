export enum PlanId {
  Free = "62bd45b3b8e1deb4fca46030",
  Budget = "62bd45b3b8e1deb4fca4603b",
  TaxSaving = "62bd45b2b8e1deb4fca4601a",
  Assurance = "62bd45b2b8e1deb4fca46025",
  Wealth = "62bd45b3b8e1deb4fca46046",
  WealthHighCap = "645b26719d83c69cf2e4d7ae",
  Development = "63212134ae3c3df40b8aa5cd",
}

export interface PlanBase<TId> {
  _id: TId;
  name: string;
  description: string;
  price: number;
  transactionLimit: number;
  taxOptimisationFeatures?: boolean;
  assuranceFeatures?: boolean;
  premiumFeatures?: boolean;
  active: boolean;
  stripeProductId?: string;
  stripePriceId?: string;
}
