import { parseDataSource } from "@syla/shared/types/helpers/parseDataSource";
import { parseOptional, parseDate } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { PopulatedWallet } from "@syla/shared/types/models/WalletBase";
import { parseAssetHolding } from "./parseAssetHolding";

export function parseWallet(
  wallet: TransportType<PopulatedWallet<string>>
): PopulatedWallet<string> {
  return {
    ...wallet,
    holdings: wallet.holdings?.map(parseAssetHolding),
    dataSource: parseDataSource(wallet.dataSource),
    createdAt: parseOptional(wallet.createdAt, parseDate),
    fromDate: parseOptional(wallet.fromDate, parseDate),
    lastSynced: parseOptional(wallet.lastSynced, parseDate),
    lastUploaded: parseOptional(wallet.lastUploaded, parseDate),
    lastFileImportStarted: parseOptional(
      wallet.lastFileImportStarted,
      parseDate
    ),
    updatedAt: parseOptional(wallet.updatedAt, parseDate),
  };
}
