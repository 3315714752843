import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import {
  getAccountAssets,
  GetAccountAssetsData,
} from "../api/asset/getAccountAssets";
import { getAccountTransactionsCacheKey } from "./transactionsStore";

export const useQueryAccountAssets = <
  TQueryFnData = GetAccountAssetsData,
  TError = unknown,
  TData = TQueryFnData
>(
  accountId: string,
  options?: Omit<
    UseQueryOptions<GetAccountAssetsData, TError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery(
    [...getAccountTransactionsCacheKey(accountId), "assets"],
    () => getAccountAssets(accountId),
    options
  );
};
