import { Id } from "../helpers/Id";
import { Populatable } from "../helpers/Populatable";
import { DataSourceBase } from "./DataSourceBase";

export enum ImportType {
  file = "file",
  sync = "sync",
  manual = "manual",
}

export interface ImportBase<TId> extends Id<TId> {
  type: ImportType;
  date: Date;
  batchId: TId;
  fileName?: string;
  dataSource: Populatable<TId, DataSourceBase<TId>>;
}
