import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { CreateSubscriptionRequestBody } from "@syla/shared/types/requests/CreateSubscriptionRequest";
import { CreateSubscriptionResponse } from "@syla/shared/types/responses/CreateSubscriptionResponse";
import axios from "axios";

export const createSubscription = async (
  accountId: string,
  props: CreateSubscriptionRequestBody
): Promise<TransportType<CreateSubscriptionResponse>> => {
  const { data } = await axios.post(
    `/accounts/${accountId}/subscriptions/create-subscription`,
    props
  );
  return data;
};
