import { parseOptional, parseDate } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetAccountResponseBody } from "@syla/shared/types/responses/GetAccountResponse";
import axios from "axios";

import { parseSubscription } from "../plans/parseSubscription";

export const getAccount = async (
  accountId: string
): Promise<GetAccountResponseBody<string>> => {
  const { data }: { data: TransportType<GetAccountResponseBody<string>> } =
    await axios.get(`/accounts/${accountId}`);

  return {
    ...data,
    createdAt: parseOptional(data.createdAt, parseDate),
    updatedAt: parseOptional(data.updatedAt, parseDate),
    subscriptions: data.subscriptions.map(parseSubscription),
  };
};
