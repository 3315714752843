import { Drawer, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { AddDataSourceContextProvider } from "../AddDataSourceContext";

import { ImportContextProvider, useImportContext } from "../ImportContext";
import { DataSourceImportDrawerContent } from "./DataSourceImportDrawerContent";
import { SelectDataSourceBody } from "./SelectDataSourceBody";

interface AddDataDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const SelectOrImport = (props: {
  onClose: () => void;
  setPreventClose: (value: ((prevState: boolean) => boolean) | boolean) => void;
}) => {
  const {
    state: { dataSource },
    actions: { setDataSource },
  } = useImportContext();
  return (
    <>
      {dataSource ? (
        <DataSourceImportDrawerContent
          onClose={props.onClose}
          selectedDataSource={dataSource}
          setSelectedDataSource={setDataSource}
          setPreventClose={props.setPreventClose}
        />
      ) : (
        <SelectDataSourceBody
          onClose={props.onClose}
          onClickDataSource={setDataSource}
        />
      )}
    </>
  );
};

const preventCloseDefault = true;

export const AddDataSourceDrawer = ({
  isOpen: isDrawerOpen,
  onClose: onCloseDrawer,
}: AddDataDrawerProps) => {
  // is submitting state to disable close on overlay click, need to pass down the setter function to the individual forms
  const [preventClose, setPreventClose] = useState(preventCloseDefault);

  // reset default
  useEffect(() => {
    if (!isDrawerOpen) {
      setPreventClose(preventCloseDefault);
    }
  }, [isDrawerOpen]);

  return (
    <Drawer
      isOpen={isDrawerOpen}
      placement="right"
      onClose={onCloseDrawer}
      size="sm"
      isFullHeight
      closeOnOverlayClick={!preventClose}
      closeOnEsc={!preventClose}
      trapFocus={false} // prevent drawer interfering with other modals
    >
      <DrawerOverlay />
      <DrawerContent h="100%" position="fixed">
        <AddDataSourceContextProvider>
          <ImportContextProvider>
            <SelectOrImport
              onClose={onCloseDrawer}
              setPreventClose={setPreventClose}
            />
          </ImportContextProvider>
        </AddDataSourceContextProvider>
      </DrawerContent>
    </Drawer>
  );
};
