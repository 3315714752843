import axios from "axios";
import { UnexpectedErrorString } from "../../components/atoms/UnexpectedError";

export class SendBglXmlError extends Error {
  constructor(
    public errorReason: SendBglXmlErrorReason,
    public errorString: string
  ) {
    super();
  }
}

export const sendBglXml = async (
  accountId: string,
  financialYearValue: string
): Promise<void> => {
  try {
    await axios.post(`/accounts/${accountId}/send-epi-xml`, {
      financialYearValue,
    });
    return;
  } catch (error) {
    if (!axios.isAxiosError(error) || !error.response) {
      throw new SendBglXmlError(
        SendBglXmlErrorReason.UnknownError,
        UnexpectedErrorString
      );
    }

    // handle known errors
    if (error.response.status == SendBglXmlResponseStatusCodes.Invalid) {
      throw new SendBglXmlError(
        SendBglXmlErrorReason.Invalid,
        error.response.data
      );
    }

    // default for other error
    throw new SendBglXmlError(
      SendBglXmlErrorReason.UnknownError,
      UnexpectedErrorString
    );
  }
};

enum SendBglXmlResponseStatusCodes {
  Invalid = 403,
}

enum SendBglXmlErrorReason {
  UnknownError,
  Invalid,
}
