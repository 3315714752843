/* eslint-disable react/no-array-index-key */
import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { range } from "lodash";
import React, { useMemo } from "react";
import {
  MdChevronLeft,
  MdChevronRight,
  MdFirstPage,
  MdLastPage,
} from "react-icons/md";

interface IPaginationBtns {
  totalNumberOfItems: number;
  itemPerPage?: number;
  currentPageIndex: number;
  siblingPages?: number;
  onClickPage: (page: number) => void;
}

export const PaginationBtns = ({
  totalNumberOfItems,
  itemPerPage = 8,
  currentPageIndex,
  siblingPages = 2,
  onClickPage,
}: IPaginationBtns) => {
  const maxPages = Math.ceil(totalNumberOfItems / itemPerPage);

  // memo of the button indexes to render
  const pageButtonIndexes: number[] = useMemo(() => {
    const maxPageButtons = siblingPages * 2 + 1; // max number of page buttons to display

    if (maxPages <= maxPageButtons) return range(maxPages);

    // if current page minus sibling pages is less than 0, set it to 0
    let rangeStartIndex = Math.max(currentPageIndex - siblingPages, 0);

    // if current page plus sibling pages is greater than the last page, set start index to max pages minus max page buttons
    if (currentPageIndex + siblingPages >= maxPages) {
      rangeStartIndex = maxPages - maxPageButtons;
    }

    return range(rangeStartIndex, rangeStartIndex + maxPageButtons);
  }, [currentPageIndex, maxPages, siblingPages]);

  return (
    <ButtonGroup isAttached variant="outline">
      {/* ----------------------- First Page ----------------------- */}
      <IconBtn
        onClick={() => onClickPage(0)}
        icon={<MdFirstPage />}
        aria-label="First Page"
      />
      {/* ----------------------- Previous Page ----------------------- */}
      <IconBtn
        onClick={() => {
          if (currentPageIndex >= 1) {
            onClickPage(currentPageIndex - 1);
          }
        }}
        icon={<MdChevronLeft />}
        aria-label="Previous Page"
      />
      {/* ----------------------- Pages Mapping ----------------------- */}
      {pageButtonIndexes.map((value) => (
        <Btn
          key={value}
          color={value === currentPageIndex ? "red.500" : "black.700"}
          onClick={() => onClickPage(value)}
        >
          {value + 1}
        </Btn>
      ))}
      {/* ----------------------- Next Page ----------------------- */}
      <IconBtn
        onClick={() => {
          if (currentPageIndex + 1 >= maxPages) return;
          onClickPage(currentPageIndex + 1);
        }}
        icon={<MdChevronRight />}
        aria-label="Next Page"
      />
      {/* ----------------------- Last Page ----------------------- */}
      <IconBtn
        onClick={() => onClickPage(maxPages - 1)}
        icon={<MdLastPage />}
        aria-label="Last Page"
      />
    </ButtonGroup>
  );
};

const IconBtn = styled(IconButton)`
  background-color: #fff;
  width: 42px;
  height: 42px;
`;

const Btn = styled(Button)`
  background-color: #fff;
  width: 42px;
  height: 42px;
  font-size: 0.875rem;
`;
