import { useQuery } from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";
import { getAssets } from "../api/asset/getAssets";
import { Asset } from "../types/asset/asset";
import { queryClient } from "./transactionsStore";
import { formatAssetOption } from "./useQueryAccountAssetOptions";

export const getAllAssetsCacheKey = () => ["allAssets"];

export const useQueryAssets = <TError = unknown, TData = Asset[]>(
  accountId: string,
  options?: Omit<
    UseQueryOptions<Asset[], TError, TData>,
    "queryKey" | "queryFn"
  >
) => {
  return useQuery<Asset[], TError, TData>(
    getAllAssetsCacheKey(),
    () => getAssets(),
    {
      staleTime: 60 * 60 * 1000, // time before data may be refetched
      ...options,
    }
  );
};

export const useQueryAssetOptions = <TError = any>(
  accountId: string,
  options?: Omit<
    UseQueryOptions<Asset[], TError, SelectOptionType[]>,
    "queryKey" | "queryFn"
  >
) =>
  useQueryAssets(accountId, {
    select: (assets) => assets.map(formatAssetOption),
    ...options,
  });

export const prefetchAssets = async () => {
  // The results of this query will be cached like a normal query
  await queryClient.prefetchQuery({
    queryKey: getAllAssetsCacheKey(),
    queryFn: () => getAssets(),
  });
};
