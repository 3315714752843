import React from "react";
import { Flex, Image, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { isReceiveType } from "@syla/shared/types/models/LedgerBase";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { NumberStyler } from "../../../../../helper/NumberStyler";
import { getLedgerTypeIcon } from "../../../../../helper/transaction/getLedgerType";
import { ImageWithMissingSrc } from "../../../../atoms/ImageWithMissingSrc";
import { Ledger } from "../../../../../types/ledger";
import { TCell, TRow } from "../../../../atoms/Table";

export const StaticLedgerTableRow = ({
  ledger,
}: {
  ledger: Ledger;
}): JSX.Element => (
  <TRow>
    {/* ----------------------------- Classification ---------------------------  */}
    <TCell minW="auto">
      <Flex direction="row" alignItems="center">
        <>
          <Image
            src={getLedgerTypeIcon(ledger.type)}
            alt=""
            w="30px"
            mr="5px"
          />
          <Text>{ledger.type}</Text>
        </>
      </Flex>
      <Spacer />
    </TCell>
    {/* ----------------------------- Change -----------------------------  */}
    <TCell>
      {isReceiveType({ type: ledger.type }) ? (
        <Text color="green.500">+</Text>
      ) : (
        <Text color="red.900">-</Text>
      )}
      <NumberStyler num={ledger.amount} colored signed={false} />
    </TCell>
    {/* ----------------------------- Asset -----------------------------  */}
    <TCell>
      <>
        <ImageWithMissingSrc
          src={ledger.asset.image}
          alt=""
          w="30px"
          mr="10px"
        />
        <Tooltip label={getAssetLongName(ledger.asset)}>
          <Text noOfLines={1}>{getAssetShortName(ledger.asset)}</Text>
        </Tooltip>
      </>
    </TCell>
    {/* ----------------------------- MarketValue -----------------------------  */}
    <TCell justifyContent="flex-end">
      <NumberStyler
        num={ledger.marketValue}
        textAlign="right"
        pr="10px"
        unit="currency"
      />
    </TCell>
    <Flex w="7%" mt="20px" ml="10px" px="10px" />
  </TRow>
);
