import { useQuery } from "react-query";
import { useCurrentAccountStore } from "../currentAccountStore";
import { useGlobalVariablesStore } from "../globalVariablesStore";
import { getAccountTransactionsCacheKey } from "../transactionsStore";
import { FinancialYearOption } from "../../types/user/financialYearOption";
import { getAccountFinancialYears } from "../../api/user/getAccountFinancialYears";

export const setSelectedFinancialYear = (
  financialYear: FinancialYearOption | undefined
) =>
  useGlobalVariablesStore.setState({
    selectedFinancialYear: financialYear,
  });

export const setDefaultFinancialYear = (
  originalSelectedFinancialYear: FinancialYearOption | undefined,
  userFinancialYearsData: FinancialYearOption[] | undefined
) => {
  if (!userFinancialYearsData?.length) return;

  if (
    !originalSelectedFinancialYear ||
    !userFinancialYearsData.find(
      (financialYear) =>
        financialYear.value === originalSelectedFinancialYear.value
    )
  )
    setSelectedFinancialYear(
      userFinancialYearsData.at(1) ?? userFinancialYearsData.at(0)
    ); // last financial year, or current if last has no data
};

export const useGetAccountFinancialYearsData = () => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  return useQuery(
    [...getAccountTransactionsCacheKey(accountId), "user-financial-years"],
    () => getAccountFinancialYears(accountId)
  );
};
