import { throwError } from "@syla/shared/types/helpers/errors";
import { GetGroupsResponse } from "@syla/shared/types/responses/GetGroupsResponse";
import { produce } from "immer";
import { WritableDraft } from "immer/dist/types/types-external";
import { GetGroupsProps, getGroups } from "../../api/order/getGroups";
import {
  OrderSortOption,
  OrderFilterSetting,
} from "../../types/order/orderQuery";
import {
  useTransactionsStore,
  queryClient,
  TransactionStoreState,
  getAccountTransactionsCacheKey,
} from "../transactionsStore";
import { deleteGroups } from "./deleteGroups";

export const refreshGroupList = () => {
  const state = useTransactionsStore.getState();
  if (!state.groupListProps) return;
  queryClient.invalidateQueries([
    ...getAccountTransactionsCacheKey(state.accountId),
    GROUPS_CACHE_KEY,
  ]);
  updateGroupList(state.groupListProps);
};

export const deleteMatchingGroups = async () => {
  const { accountId, groupListProps } = useTransactionsStore.getState();
  if (!groupListProps || !accountId) return;

  return deleteGroups({
    accountId,
    filter: groupListProps?.filter,
  });
};

export const updateGroupList = async (props: GetGroupsProps) => {
  console.debug("refreshGroupList");

  if (props == useTransactionsStore.getState().groupListProps) {
    useTransactionsStore.setState({ refreshing: true });
  } else {
    useTransactionsStore.setState({
      groupList: undefined,
      groupListProps: props,
    });
  }
  const { accountId, filter, limit, pageParam, sort } = props;

  let result: GetGroupsResponse<string>;
  try {
    result = await queryClient.fetchQuery(getPageQueryKey(props), () =>
      getGroups({
        accountId,
        pageParam,
        limit,
        sort,
        filter,
      })
    );
  } catch (error) {
    useTransactionsStore.setState({
      refreshing: false,
    });
    throw error;
  }

  console.debug("refreshGroupList done");

  const groupMap = Object.fromEntries(
    result.data.map((group) => [
      group.order._id,
      {
        data: group,
      },
    ])
  );

  const transactionMap = Object.fromEntries(
    result.data.flatMap((group) =>
      group.transactions.map((transaction) => [
        transaction._id,
        {
          data: transaction,
        },
      ])
    )
  );

  const transactionGroupMap = Object.fromEntries(
    result.data.flatMap((group) =>
      group.transactions.map((transaction) => [
        transaction._id,
        group.order._id,
      ])
    )
  );

  useTransactionsStore.setState((state) => ({
    groups: {
      // store prior list too in case any components look up old reference
      ...state.groups,
      ...groupMap,
    },
    transactions: {
      // store prior list too in case any components look up old reference
      ...state.transactions,
      ...transactionMap,
    },
    transactionToGroup: {
      ...state.transactionToGroup,
      ...transactionGroupMap,
    },
    groupList: result.data.map((g) => g.order._id),
    groupListTotal: result.total,
    groupListProps: props,
    refreshing: false,
  }));
};

export const resetGroupList = () => {
  useTransactionsStore.setState({
    groupList: undefined,
    groupListTotal: undefined,
    groupListProps: undefined,
    groups: undefined,
    transactions: undefined,
    transactionToGroup: undefined,
  });
};

export function updateTransactionInQueryCache(transactionId: string) {
  // optimistically update query cache
  const state = useTransactionsStore.getState();
  const { groupId, groupState } = getGroupByTransactionId(state, transactionId);

  const updatedGroup = groupState.data;

  if (state.groupListProps) {
    queryClient.setQueryData<GetGroupsResponse<string>>(
      getPageQueryKey(state.groupListProps),
      (response) =>
        response
          ? produce(response, (draft) => {
              const index = draft.data.findIndex(
                (item) => item.order._id == groupId
              );
              if (index > -1) draft.data[index] = updatedGroup;
            })
          : (undefined as any)
    );
  }
}

export function getGroupByTransactionId(
  state: Pick<
    WritableDraft<TransactionStoreState>,
    "transactionToGroup" | "groups"
  >,
  transactionId: string
) {
  const groupId =
    state.transactionToGroup?.[transactionId] ??
    throwError(new Error(`Transaction ${transactionId} missing group mapping`));

  const groupState =
    state.groups?.[groupId] ??
    throwError(
      new Error(`Transaction ${transactionId} missing group ${groupId}`)
    );
  return { groupId, groupState };
}

const GROUPS_CACHE_KEY = "groups";

export function getPageQueryKey({
  accountId,
  pageParam,
  limit,
  sort,
  filter,
}: {
  accountId: string;
  pageParam: number;
  limit: number;
  sort: OrderSortOption | undefined;
  filter: OrderFilterSetting;
}) {
  return [
    ...getAccountTransactionsCacheKey(accountId),
    GROUPS_CACHE_KEY,
    pageParam,
    limit,
    sort,
    filter,
  ];
}

export const invalidateTransactionQueryCache = (accountId: string) => {
  queryClient.invalidateQueries([...getAccountTransactionsCacheKey(accountId)]);
};
