import { formatDuration, intervalToDuration } from "date-fns";
import { Id } from "../helpers/Id";
import { Populatable } from "../helpers/Populatable";
import { WithModelIds } from "../helpers/WithModelIds";
import { PopulatedAssetHolding } from "./AssetHoldingBase";
import { DataSourceBase } from "./DataSourceBase";
import { ModelBase } from "./ModelBase";
import { UserBase } from "./UserBase";

export enum SyncState {
  InProgress = "InProgress",
  Error = "Error",
  Init = "Init",
}

export enum FileImportState {
  InProgress = "InProgress",
  Error = "Error",
  Init = "Init",
}
export enum SyncWalletErrorDetailCode {
  INVALID_AUTH = "INVALID_AUTH",
  RATE_LIMIT = "RATE_LIMIT",
  CUSTOM = "CUSTOM",
  INVALID_ADDRESS = "INVALID_ADDRESS",
  OUTAGE = "OUTAGE",
  LIMIT_EXCEEDED = "LIMIT_EXCEEDED",
  UNKNOWN = "UNKNOWN",
}

export enum SyncWalletAuthErrorReason {
  InvalidKey = "InvalidKey",
  InsufficientPermission = "InsufficientPermission",
  ExcessivePermission = "ExcessivePermission",
  Expired = "Expired",
}

export type SyncWalletAuthErrorDetails = {
  code: SyncWalletErrorDetailCode.INVALID_AUTH;
  subReason: SyncWalletAuthErrorReason;
};
export type SyncWalletCustomErrorDetails = {
  code: SyncWalletErrorDetailCode.CUSTOM;
  title: string;
  message: string;
};
export type SyncWalletRateLimitErrorDetails = {
  code: SyncWalletErrorDetailCode.RATE_LIMIT;
  createdMs: number;
  vendor?: string;
  retryAfterMs?: number;
};
export type SyncWalletUnknownError = {
  code: SyncWalletErrorDetailCode.UNKNOWN;
};
export type SyncWalletOutageError = { code: SyncWalletErrorDetailCode.OUTAGE };
export type SyncWalletInvalidAddressError = {
  code: SyncWalletErrorDetailCode.INVALID_ADDRESS;
};

export enum SyncWalletLimitType {
  RECORD = "RECORD",
  TRANSACTION = "TRANSACTION",
  DATA_SOURCE = "DATA_SOURCE",
}

export type SyncWalletLimitError = {
  code: SyncWalletErrorDetailCode.LIMIT_EXCEEDED;
  limitType: SyncWalletLimitType;
  attemptedCount: number;
};
export type SyncWalletErrorDetails =
  | SyncWalletAuthErrorDetails
  | SyncWalletRateLimitErrorDetails
  | SyncWalletCustomErrorDetails
  | SyncWalletInvalidAddressError
  | SyncWalletLimitError
  | SyncWalletOutageError
  | SyncWalletUnknownError;

export function getRateLimitErrorMessage({
  createdMs,
  timespanMs,
  vendor,
}: {
  vendor: string | undefined;
  createdMs: number;
  timespanMs: number | undefined;
}) {
  // time may have already elapsed since error was created
  const waitRemaining =
    timespanMs != null ? timespanMs - (Date.now() - createdMs) : undefined;

  const tryAgainMessage =
    waitRemaining == null
      ? `Please give it another try in **a while**`
      : waitRemaining > 0
      ? `Please give it another try in **${formatDuration(
          intervalToDuration({ start: 0, end: waitRemaining })
        )}**`
      : `You can try it again now`;

  return {
    title: "Connection Temporarily Paused",
    message: `${
      vendor
        ? `**${vendor.trim()}** paused your connection`
        : `The connection was paused`
    } for syncing too often. 
    ${tryAgainMessage}.`,
  };
}

export interface WalletBase<TId> extends ModelBase<TId> {
  owner: Populatable<TId, UserBase<TId>>;
  dataSource: Id<TId> | DataSourceBase<TId>;
  apiKey?: string;
  secretKey?: string;
  passphrase?: string;
  addresses?: string[];
  customName?: string;
  fromDate?: Date;

  authorised?: boolean;

  allowSync: boolean;

  transactionCount?: number;

  // last sync date
  lastSynced?: Date;

  // last file upload date
  lastUploaded?: Date;
  lastFileImportStarted?: Date;
  fileImportState?: FileImportState;

  importMethod: WalletImportMethod;

  /**
   * This prop stores something to reference the most recent api imported ledger (e.g. BTC Markets ledger id, etc)
   * Typed string as we don't know the possible formats of ledger id/reference from the other data sources
   */
  lastImportedLedgerReference?: string;
  createdAt?: Date;
  updatedAt?: Date;

  vezgoAccountId?: string;
  syncState?: SyncState;
  syncError?: SyncWalletErrorDetails;
}

export interface WalletWithHoldings<TId> extends WithModelIds<WalletBase<TId>> {
  holdings?: PopulatedAssetHolding<TId>[];
}

export interface PopulatedWallet<TId> extends WithModelIds<WalletBase<TId>> {
  dataSource: WithModelIds<DataSourceBase<TId>>;
  holdings?: PopulatedAssetHolding<TId>[];
  isLocked: boolean;
}

export enum WalletImportMethod {
  API = "API",
  CSV = "CSV",
}

export const getWalletName = ({
  wallet,
  dataSource,
}: {
  wallet: Pick<WalletBase<never>, "customName">;
  dataSource: Pick<DataSourceBase<string>, "name">;
}): string => wallet?.customName || dataSource.name;
