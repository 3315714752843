import { addMilliseconds } from "date-fns";
import {
  getFinancialYearForDate,
  financialYearToDateRange,
  financialYearRegex,
} from "../../helpers/financialYear";

export class FinancialYear {
  constructor(
    params:
      | {
          /**
           *  financial year string value. e.g. "FY2021/22"
           */
          value: string;
          date?: undefined;
        }
      | {
          /**
           * a datetime falling within a financial year
           * e.g. 2021-06-01 -> 2022-06-30
           * (2022-07-01 would be in the next year)
           */
          date: Date;

          value?: undefined;
        }
  ) {
    if (params.date) {
      this.value = getFinancialYearForDate(params.date);
    } else if (params.value) {
      if (!financialYearRegex.test(params.value)) {
        throw Error(
          "Invalid financial year, expecting string of format `FY20XX/XX`"
        );
      }
      this.value = params.value;
    }

    const [startDate, endDate] = financialYearToDateRange(this.value);
    this.startDate = startDate;
    this.nextYearStartDate = endDate;
    this.endDate = addMilliseconds(endDate, -1);
  }

  // financial year string value. e.g. "FY2021/22"
  public readonly value: string;

  /**
   * the first datetime in the financial year.
   * e.g. 2021-06-01 00:00:00
   */
  public readonly startDate: Date;

  /**
   * the last datetime in the financial year
   *  e.g. 2022-06-30 23:59:59.999
   */
  public readonly endDate: Date;

  /**
   *  The first datetime in the next financial year.
   *  Used for `lt` queries.
   *  e.g. 2022-07-01
   */
  public readonly nextYearStartDate: Date;

  // override valueOf
  public valueOf() {
    return this.value;
  }
}
