import { VStack, Text, Link, Spinner } from "@chakra-ui/react";
import { startOfDay } from "date-fns";
import { PanelMode } from "rc-picker/es/interface";
import React, { useState } from "react";
import { StdVStack } from "../../atoms/Containers";
import { StyledDatePicker } from "../../atoms/DatePicker";
import { useGetAccountFinancialYearsData } from "../../../store/actions/userFinancialYear";

export function BalancesDatePicker({
  date,
  onChange,
}: {
  date: Date | null | undefined;
  onChange: (value: Date | null | undefined) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <VStack alignItems="flex-start">
      <Text>Date</Text>
      <StyledDatePicker
        open={open}
        onOpenChange={setOpen}
        placeholder="Now"
        showTime={false}
        showToday={false}
        value={date}
        onChange={onChange}
        containerProps={{ minW: "15rem", boxShadow: "base" }}
        renderExtraFooter={(panelMode) => (
          <FySelector
            panelMode={panelMode}
            onSelect={(date) => {
              onChange(date);
              setOpen(false);
            }}
          />
        )}
      />
    </VStack>
  );
}

const FySelector = ({
  onSelect,
}: {
  panelMode: PanelMode;
  onSelect: React.Dispatch<Date | undefined>;
}) => {
  const { data: financialYears } = useGetAccountFinancialYearsData();

  return (
    <StdVStack alignItems="center" maxH="10em" overflowY="auto">
      <Link
        onClick={() => {
          onSelect(undefined);
        }}
      >
        Now
      </Link>
      {!financialYears && <Spinner />}
      {financialYears &&
        financialYears
          .filter((fy) => fy.endDate < new Date()) // no future dates
          .map((fy) => (
            <Link
              key={fy.value}
              onClick={() => {
                onSelect(startOfDay(fy.endDate));
              }}
            >
              End of {fy.value}
            </Link>
          ))}
    </StdVStack>
  );
};
