/**
 * Shallow copy an object without the given key
 *
 * @param obj
 * @param key
 */
export const objectRemoveKey = <T, K extends keyof T>(
  obj: T,
  key: K
): Omit<T, K> => {
  const { [key]: _, ...rest } = obj;
  return { ...rest };
};
