import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { uniq } from "lodash";
import { ImageWithMissingSrc } from "../../../atoms/ImageWithMissingSrc";

export const RenderAssetsIcon = ({
  icons,
}: {
  icons: (string | undefined)[];
}): JSX.Element => {
  // don't show duplicates
  icons = uniq(icons);

  // return spacer
  if (icons.length == 0)
    return <Flex w="50px" h="42px" mr="5px" justifyContent="center" />;

  if (icons.length === 1) {
    return (
      <Flex w="50px" h="42px" mr="5px" justifyContent="center">
        <ImageWithMissingSrc src={icons[0]} alt="" h="36px" w="36px" />
      </Flex>
    );
  }
  if (icons.length === 2) {
    return (
      <Flex
        w="50px"
        h="42px"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        mr="5px"
      >
        {icons.map((icon) => (
          <ImageWithMissingSrc key={icon} src={icon} alt="" h="20px" w="20px" />
        ))}
      </Flex>
    );
  }
  if (icons.length === 3 || icons.length === 4) {
    return (
      <Flex
        h="42px"
        w="50px"
        direction="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        mr="5px"
      >
        {icons.map((icon) => (
          <ImageWithMissingSrc
            key={icon}
            src={icon}
            alt=""
            h="20px"
            w="20px"
            mx="1px"
          />
        ))}
      </Flex>
    );
  }
  return (
    <Flex
      w="50px"
      h="42px"
      direction="row"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      mr="5px"
    >
      {icons.slice(0, 3).map((icon) => (
        <ImageWithMissingSrc
          key={icon}
          src={icon}
          alt=""
          h="20px"
          w="20px"
          mx="1px"
        />
      ))}
      <Flex
        w="20px"
        h="20px"
        bgColor="white.500"
        borderRadius="10px"
        justifyContent="center"
        alignItems="center"
        mx="1px"
      >
        <Text
          fontSize="0.625rem"
          color="black.550"
          pb="2px"
          fontWeight="500"
          pr="1px"
        >
          +
        </Text>
        <Text
          fontSize="0.625rem"
          color="black.550"
          textAlign="center"
          fontWeight="500"
        >
          {icons.length - 4}
        </Text>
      </Flex>
    </Flex>
  );
};
