import { EmailIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Tr,
  Td,
  VStack,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import {
  AccountAccessRole,
  AccountPermission,
  AccountAccessRoleName,
} from "@syla/shared/types/models/AccountAccessBase";
import { AccountAccessInvite } from "@syla/shared/types/responses/GetAccountsResponse";
import React, { useContext, useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { HiDotsVertical } from "react-icons/hi";
import { ModalContext } from "../../../contexts/ModalContext";
import { hasAccountPermission } from "../../../helper/permissions";
import { useReportError } from "../../../helpers/useReportError";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useMutationInviteAccountMember } from "../../../store/useMutationInviteAccountMember";
import { useMutationUpdateAccountInvites } from "../../../store/useMutationUpdateAccountInvites";
import { Account } from "../../../types/Account";
import { AccountAccessRoleSelector } from "../AccountAccessRoleSelector";

export function AccountInviteRow({
  account,
  accountInvite,
}: {
  account: Account;
  accountInvite: AccountAccessInvite<string>;
}) {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { mutateAsync: inviteAccountMember } =
    useMutationInviteAccountMember(accountId);

  const { mutateAsync: updateAccountInvites } =
    useMutationUpdateAccountInvites(accountId);

  const reportError = useReportError();

  // remove access
  const { openModal, closeModal, setBtn2Loading } = useContext(ModalContext);
  // form state
  const formMethods = useForm<AccountAccessInvite<string>>({
    defaultValues: accountInvite,
  });
  const { watch, setValue, reset } = formMethods;

  // reset form on data changes
  useEffect(() => {
    reset(accountInvite);
  }, [accountInvite, reset, account]);

  const openRemoveInviteDialog = useCallback(() => {
    openModal({
      type: "confirmation",
      icon: "delete",
      heading: `Cancel invite?`,
      contents: [`This will cancel the invite for "${accountInvite.email}"`],
      btn2Content: "Cancel invite",
      btn2Action: async () => {
        setBtn2Loading(true);
        try {
          await updateAccountInvites({
            ops: [
              {
                action: "delete",
                email: accountInvite.email,
              },
            ],
          });
          closeModal();
        } catch (error) {
          reportError(error);
        } finally {
          setBtn2Loading(false);
        }
      },
    });
  }, [
    accountInvite.email,
    closeModal,
    openModal,
    reportError,
    setBtn2Loading,
    updateAccountInvites,
  ]);

  const [isSaving, setIsSaving] = useState(false);

  const hasPermissionToCancelInvite =
    (accountInvite.role != AccountAccessRole.Owner &&
      hasAccountPermission(account, AccountPermission.ChangeAccess)) ||
    (accountInvite.role == AccountAccessRole.Owner &&
      hasAccountPermission(account, AccountPermission.ChangeOwnership));

  const hasPermissionToResend = hasAccountPermission(
    account,
    AccountPermission.ChangeAccess
  );

  return (
    <Tr key={accountInvite._id}>
      {/* Account Member */}
      <Td>
        <VStack w="100%" alignItems="flex-start">
          <Text>{accountInvite.email}</Text>
        </VStack>
      </Td>

      {/* Billing Owner */}
      <Td></Td>

      {/* Role */}
      <Td width="15rem">
        {/* Check permissions */}
        {(accountInvite.role != AccountAccessRole.Owner &&
          hasAccountPermission(account, AccountPermission.ChangeAccess)) ||
        (accountInvite.role == AccountAccessRole.Owner &&
          hasAccountPermission(account, AccountPermission.ChangeOwnership)) ? (
          <AccountAccessRoleSelector
            currentRole={watch("role")}
            onSelected={async (role) => {
              setValue("role", role);

              try {
                setIsSaving(true);
                // update role on invite
                await updateAccountInvites({
                  ops: [
                    {
                      email: accountInvite.email,
                      action: "set",
                      role: watch("role"),
                    },
                  ],
                });
              } catch (error) {
                reportError(error);
              } finally {
                setIsSaving(false);
              }
            }}
            isLoading={isSaving}
            width="100%"
          />
        ) : (
          // otherwise just display
          <Text>{AccountAccessRoleName[watch("role")]}</Text>
        )}
      </Td>

      {/* Invite Status */}
      <Td>
        <Text
          textColor={accountInvite.status == "expired" ? "#851414" : "#916208"}
        >
          {accountInvite.status == "sent"
            ? "Sent"
            : accountInvite.status == "expired"
            ? "Expired"
            : "Unknown"}
        </Text>
      </Td>

      {/* Menu */}
      <Td textAlign="right" width="5rem">
        <Menu>
          <MenuButton
            as={IconButton}
            icon={<HiDotsVertical />}
            backgroundColor="transparent"
            isDisabled={!(hasPermissionToCancelInvite || hasPermissionToResend)}
          />
          <MenuList p="0">
            {hasPermissionToResend && (
              <MenuItem
                onClick={async () => {
                  try {
                    setIsSaving(true);

                    // resend
                    await inviteAccountMember({
                      members: [
                        {
                          email: accountInvite.email,
                          role: watch("role"),
                        },
                      ],
                    });
                  } catch (error) {
                    reportError(error);
                  } finally {
                    setIsSaving(false);
                  }
                }}
                icon={<EmailIcon />}
              >
                Resend Invite
              </MenuItem>
            )}
            {hasPermissionToCancelInvite && (
              <MenuItem
                textColor="red.600"
                onClick={() => openRemoveInviteDialog()}
                icon={<DeleteIcon />}
              >
                Cancel Invite
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      </Td>
    </Tr>
  );
}
