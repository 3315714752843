import { ButtonProps } from "@chakra-ui/react";
import {
  AccountSubType,
  ActivityTypeName,
} from "@syla/shared/types/models/AccountBase";
import React from "react";
import { ItemSelector } from "../../components/molecules/ItemSelector";

export function AccountSubtypeSelector({
  currentValue,
  onSelected,
  isLoading,
  isDisabled,
  ...props
}: {
  currentValue: AccountSubType | undefined;
  onSelected: (value: AccountSubType) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
} & ButtonProps) {
  const values = Object.values(AccountSubType);

  return (
    <ItemSelector
      currentValue={currentValue}
      onValueSelected={onSelected}
      allValues={values}
      labels={ActivityTypeName}
      isLoading={isLoading}
      isDisabled={isDisabled}
      {...props}
    />
  );
}
