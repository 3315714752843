import { Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";
import { FormContainer } from "../components/atoms/Containers";
import { SylaFormHeader } from "../components/molecules/forms/SylaFormHeader";
import { AuthoriseWindowEventData } from "../components/organisms/dataSource/AuthoriseModule";
import { getPageUrl } from "../helper/getFullPageUrl";
import { authoriseOauthWallet } from "../store/actions/authoriseOauthWallet";

export const OauthCallback = ({
  dataSourceCode,
}: {
  dataSourceCode: DataSourceCode;
}) => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const [code, state] = [params.get("code"), params.get("state")];

    // these come from CoinJar in case of error
    const [error, errorDescription] = [
      params.get("error"),
      params.get("error_description"),
    ];
    if (!code || !state) {
      postMessage({
        status: error == "access_denied" ? "denied" : "error",
        errorReason:
          error && errorDescription
            ? `${error}: ${errorDescription}`
            : undefined,
      });
      window.close();
      return;
    } else {
      const stateParams = new URLSearchParams(decodeURIComponent(state));
      const accountId = stateParams.get("accountId");
      if (!accountId) {
        postMessage({
          status: "error",
          errorReason: "accountId state missing",
        });
        window.close();
        return;
      }

      const walletId = stateParams.get("walletId");
      if (!walletId) {
        postMessage({
          status: "error",
          errorReason: "walletId state missing",
        });
        window.close();
        return;
      }

      // now call the backend to get the access key and save it against the wallet
      authoriseOauthWallet(accountId, {
        accessGrantCode: code,
        walletId,
        redirectUrl: getPageUrl(location, { origin: true, path: true }),
        dataSourceCode,
      })
        .then(() => {
          // tell parent we're done
          postMessage({
            status: "success",
          });
          window.close();
        })
        .catch((error) => {
          postMessage({
            status: "error",
            errorReason: error?.toString(),
          });
          window.close();
        });
    }
  }, [location]);

  return (
    <FormContainer>
      <SylaFormHeader
        heading={"Please wait"}
        subText={"while we complete authorisation"}
      />
      <Spinner />
    </FormContainer>
  );
};

function postMessage(data: AuthoriseWindowEventData) {
  try {
    window.opener.postMessage(data, "*");
  } catch (error) {
    console.error("Error postMessage", error);
  }
}
