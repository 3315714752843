import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { FinancialYearOption } from "../types/user/financialYearOption";

const INIT_STATE = {
  selectedFinancialYear: undefined,
};

export const useGlobalVariablesStore = create(
  immer<GlobalVariablesStore>(() => {
    return INIT_STATE;
  })
);

export interface GlobalVariablesStore {
  selectedFinancialYear: FinancialYearOption | undefined;
}
