import { AddTransactionRequest } from "@syla/shared/types/requests/TransactionRequests";
import { addOrder } from "../../api/order/addOrder";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import { refreshGroupList, invalidateTransactionQueryCache } from "./groupList";

export const addTransaction = async (props: AddTransactionRequest) => {
  try {
    await addOrder(props);
    checkStatusAndDoPendingWork();
  } finally {
    // order can be added in any page
    invalidateTransactionQueryCache(props.accountId);
    refreshGroupList();
  }
};
