import { Flex, Text } from "@chakra-ui/react";
import { Decimal } from "@syla/shared/decimal";
import React from "react";
import { NavLink } from "react-router-dom";
import { buildDataSourcesLink } from "../../../../routers/routePaths";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";

import { WalletResponse } from "../../../../types/wallet/wallet";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { ContentContainer } from "../../../atoms/Containers";
import { EmptyDataMessage } from "../../../atoms/EmptyDataMessage";
import { SectionHeading } from "../../../atoms/Headings";

import { DataSourceTableRow } from "./DataSourceTableRow";

export type DataSourceWalletResponse = Pick<
  WalletResponse,
  | "_id"
  | "dataSource"
  | "allowSync"
  | "lastSynced"
  | "updatedAt"
  | "importMethod"
  | "transactionCount"
  | "holdings"
  | "customName"
  | "isLocked"
  | "syncState"
>;

export const DataSourceTable = ({
  walletResponses: rawResponse,
  isLoading,
}: {
  walletResponses: DataSourceWalletResponse[] | undefined;
  isLoading: boolean;
}) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const dummyWalletResponse = (id) => ({
    _id: id.toString(),
    lastSynced: new Date(),
    allowSync: false,
    totalTransactions: 1000,
    dataSource: {
      name: "DataSource",
    },
    holdingStats: {
      assetHoldings: [
        {
          asset: {
            code: "DOGE",
            name: "DogeCoin",
          },
        },
      ],
      totalStats: {
        marketValue: Decimal.from("10000"),
      },
    },
  });
  const dummyResponses = [
    dummyWalletResponse(1),
    dummyWalletResponse(2),
    dummyWalletResponse(3),
  ];

  const walletResponses = isLoading
    ? dummyResponses
    : rawResponse ?? dummyResponses;

  return (
    <ContentContainer>
      <Flex
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        pr="30px"
      >
        <SectionHeading>Data Sources</SectionHeading>
        <NavLink to={buildDataSourcesLink({ accountId })}>
          <ButtonVariant content="View All" outlineType="ghost" />
        </NavLink>
      </Flex>

      <Flex overflow="auto" position="relative" direction="column">
        <Flex
          w="100%"
          justifyContent="space-between"
          alignItems="center"
          borderWidth="1px"
          borderColor="white.500"
          h="36px"
          px="16px"
          position="sticky"
          top="0"
        >
          <HeaderCell heading="Data Source" width="30%" minW="200px" />
          <HeaderCell heading="No. Transactions" width="20%" minW="220px" />
          <HeaderCell heading="Assets" width="30%" minW="200px" />
          <HeaderCell heading="Holding" width="20%" minW="150px" mergeRight />
        </Flex>
        <Flex direction="column" w="100%">
          {walletResponses.map((walletResponse) => (
            <DataSourceTableRow
              key={walletResponse._id}
              walletResponse={walletResponse}
              isLoading={isLoading}
            />
          ))}
        </Flex>

        {walletResponses.length === 0 && (
          <Flex justifyContent="center" alignItems="center" h="60%">
            <EmptyDataMessage content="Add a data source to see your data sources" />
          </Flex>
        )}
      </Flex>
    </ContentContainer>
  );
};

const HeaderCell = ({
  heading,
  width,
  minW,
  mergeRight,
}: {
  heading: string;
  width: string;
  minW: string;
  mergeRight?: boolean;
}) => (
  <Flex
    w={width}
    minW={minW}
    justifyContent={mergeRight ? "flex-end" : "flex-start"}
    pr={mergeRight ? "60px" : "0"}
  >
    <Text fontSize="0.75rem" fontWeight="700" color="black.600">
      {heading}
    </Text>
  </Flex>
);
