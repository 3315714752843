import { OrderResponseItem } from "@syla/shared/types/responses/OrderResponseItem";
import { minutesToMilliseconds } from "date-fns";
import { QueryClient } from "react-query";
import create from "zustand";
import { immer } from "zustand/middleware/immer";
import { GetGroupsProps } from "../api/order/getGroups";
import { Transaction } from "../types/transaction";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: minutesToMilliseconds(60),
      cacheTime: minutesToMilliseconds(60),
    },
  },
});

const INIT_STATE = {
  accountId: undefined,
  updating: false,
  syncing: false,
  queueing: false,
  refreshing: false,
  groupList: undefined,
  groupListTotal: undefined,
  groupListProps: undefined,
  groups: undefined,
  transactions: undefined,
  transactionToGroup: undefined,
  filtering: false,
};

export const useTransactionsStore = create(
  immer<TransactionStoreState>(() => {
    return INIT_STATE;
  })
);

export interface TransactionStoreState {
  accountId: string | undefined;
  updating: boolean;
  syncing: boolean;
  queueing: boolean;
  refreshing: boolean;
  filtering: boolean;
  groups:
    | {
        [groupId: string]: GroupInfo;
      }
    | undefined;
  transactions:
    | {
        [id: string]: TransactionState;
      }
    | undefined;
  transactionToGroup:
    | {
        [id: string]: string;
      }
    | undefined;
  groupList: string[] | undefined;
  groupListProps: GetGroupsProps | undefined;
  groupListTotal: number | undefined;
}

export const getAccountTransactionsCacheKey = (
  accountId: string | undefined
) => [accountId, "transactions"];

export const resetTransactionStore = ({
  initAccountId,
}: { initAccountId?: string } = {}) => {
  console.debug("resetTransactionStore", { initAccountId });
  // queryClient.invalidateQueries();
  useTransactionsStore.setState(
    {
      ...INIT_STATE,
      accountId: initAccountId,
    },
    true
  );
};

type GroupInfo = {
  data: OrderResponseItem<string>;
  updating?: boolean;
};

type TransactionState = {
  data: Transaction;
  updating?: boolean;
  error?: string;
};
