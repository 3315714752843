import React from "react";
import { Button, Text, Flex, Spacer, ButtonProps } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { find } from "lodash";
import { FaCaretDown } from "react-icons/fa";

import { ImageWithMissingSrc } from "../../ImageWithMissingSrc";

interface SingleSelectBtnProps {
  selectedOption: string | [];
  options: SelectOptionType[];
  isOpen: boolean;
  onClickBtn: () => void;
  selectedIndication: boolean;
  placeholder: string;
  disabled: boolean;
  selectBtnProps?: ButtonProps;
}

export const SingleSelectBtn = ({
  selectedOption,
  options,
  isOpen,
  onClickBtn,
  selectedIndication,
  placeholder,
  disabled,
  selectBtnProps,
}: SingleSelectBtnProps) => (
  <SelectBtn
    selectedIndication={selectedIndication}
    selectedOption={selectedOption}
    isOpen={isOpen}
    disabled={disabled}
    onClick={onClickBtn}
    selectbtnprops={selectBtnProps}
  >
    <Flex alignItems="center">
      {selectedOption ? (
        <>
          <ImageWithMissingSrc
            src={
              find(options, (option) => option.value === selectedOption)?.icon
            }
            alt=""
            h="30px"
            mr="5px"
          />
          <Text mr="10px" fontSize="1rem" color="black.550" fontWeight="500">
            {find(options, (option) => option.value === selectedOption)?.label}
          </Text>
        </>
      ) : (
        <Text mr="10px" fontSize="0.875rem" color="black.550" fontWeight="500">
          {placeholder}
        </Text>
      )}
      <Spacer />
      {!disabled && <FaCaretDown color="#808080" />}
    </Flex>
  </SelectBtn>
);

export const SelectBtn = styled(Button, {
  shouldForwardProp: (prop) =>
    ![
      "isOpen",
      "selectedOption",
      "selectedIndication",
      "selectbtnprops",
    ].includes(prop as string),
})((props) => ({
  width: "auto",
  height: "50px",
  backgroundColor:
    props.selectedOption && props.selectedIndication
      ? "#f5f5f5"
      : "transparent",
  borderWidth: "1px",
  borderColor:
    (props.isOpen || props.selectedOption) && props.selectedIndication
      ? "#000"
      : "gray.200",
  textAlign: "left",
  borderRadius: "md",
  padding: "0 15px",
  display: "inline-block",
  cursor: props.disabled ? "not-allowed" : "pointer",
  alignItems: "center",
  "&:hover": {
    backgroundColor: props.isOpen && !props.disabled ? "#fff" : "#f5f5f5",
  },
  "&:active": { backgroundColor: "#e5e5e5" },
  "&:focus": {
    borderColor: !props.disabled ? "#000" : "#808080",
  },
  "&:visited": {
    backgroundColor: "#fff !important",
  },
  ...props.selectbtnprops,
}));
