import { Decimal } from "@syla/shared/decimal";

export const adjustAmount = (input: any) =>
  input?.toString().replace(/[$,]/g, "").trim() || "";

export const amountInvalid = ({
  value,
  allowZero = false,
}: {
  value: any;
  allowZero?: boolean;
}): boolean => {
  const adjustedValue = adjustAmount(value);
  if (!adjustedValue) return true;
  try {
    return allowZero
      ? !Decimal.from(adjustedValue)
      : Decimal.from(adjustedValue).eq("0");
  } catch (error) {
    return true;
  }
};
