import React, { useContext } from "react";
import { Flex } from "@chakra-ui/react";

import { WalletResponse } from "../../../../types/wallet/wallet";
import { EmptyDataMessage } from "../../../atoms/EmptyDataMessage";
import { LoadingIndicator } from "../../../molecules/LoadingIndicator";
import { DataSourceLoadingContext } from "./MainDataSourceTable";

import { MainDataSourceTableRow } from "./MainDataSourceTableRow";

interface RenderWalletsProps {
  walletResponses: WalletResponse[];
}

export const RenderWallets = ({
  walletResponses,
}: RenderWalletsProps): JSX.Element => {
  const { isLoading } = useContext(DataSourceLoadingContext);

  if (isLoading) {
    return <LoadingIndicator content="Loading Data Sources" />;
  }

  if (walletResponses.length) {
    return (
      <Flex overflowX="auto" h="100%" mb="20px" direction="column" maxW="100%">
        {walletResponses.map((walletResponse) => (
          <MainDataSourceTableRow
            key={walletResponse._id}
            walletResponse={walletResponse}
          />
        ))}
      </Flex>
    );
  }
  return (
    <Flex w="100%" justifyContent="center" h="85%">
      <EmptyDataMessage content="Add a data source to make the most of your Syla account" />
    </Flex>
  );
};
