import {
  Flex,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";

import {
  getDataSourceSupportedInputs,
  DataSourceType,
} from "@syla/shared/types/models/DataSourceBase";
import axios from "axios";
import React, { useState } from "react";
import { FormProvider, useFormContext } from "react-hook-form";
import { DataSourceInputType } from "../../../../../helper/dataSource/DataSourceInputType";
import { getDirtyValues } from "../../../../../helper/forms/getDirtyValues";
import {
  importWalletCsv,
  updateWallet,
} from "../../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../../store/currentAccountStore";
import { EditDataSourceForm } from "../../../../../types/dataSource/dataSource";
import { WalletResponse } from "../../../../../types/wallet/wallet";
import { ButtonVariant } from "../../../../atoms/ButtonVariant";
import { FileImportTab } from "../../../../atoms/FileImportTab";
import { DrawerHeading } from "../../../../atoms/Headings";
import { ImageWithMissingSrc } from "../../../../atoms/ImageWithMissingSrc";
import { ImportError } from "../../../../atoms/ImportError";
import { Form } from "../../../../molecules/forms/FormComponents";
import { getInputTypesPerTabIndex } from "../../getInputTypesPerTabIndex";
import { getDefaultEditValues } from "../GetDefaultEditValues";
import { EditApiTab } from "./EditApiTab";

interface EditExchangeProps {
  wallet: WalletResponse;
  onClose: () => void;
  defaultTab?: DataSourceInputType;
  fileImport: boolean;
}

export const EditDataSource = ({
  wallet,
  onClose: onCloseDrawer,
  fileImport,
}: EditExchangeProps): React.ReactElement => {
  const { dataSource } = wallet;

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // state for importing
  const [isImporting, setIsImporting] = useState(false);
  const [importError, setImportError] = useState<Error | undefined>();

  const supportedInputs = getDataSourceSupportedInputs(wallet.dataSource);
  const tabInputType = getInputTypesPerTabIndex(supportedInputs, fileImport);

  // tab index state
  const [tabIndex, setTabIndex] = useState(0);

  // handle tab change
  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  // get form methods from form context
  const formMethods = useFormContext<EditDataSourceForm>();

  // get everything needed from form methods
  const {
    handleSubmit,
    formState: { isSubmitting, dirtyFields },
    reset,
  } = formMethods;

  // submit handler
  const onSubmit = async (data: EditDataSourceForm) => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    const { apiKey, secretKey, passphrase, storedFiles, customName } =
      dirtyValues;
    console.debug("editDataSource.onSubmit", {
      data,
      dirtyFields,
      dirtyValues,
    });
    setImportError(undefined);

    if (!Object.keys(dirtyValues).length) {
      // nothing to save
      onCloseDrawer();
      return;
    }

    // -------------------- API Tab --------------------
    if (tabInputType[tabIndex] == DataSourceInputType.Api) {
      // early return if api key or secret key is missing

      try {
        const updatedWallet = await updateWallet(accountId, wallet._id, {
          apiKey,
          secretKey,
          passphrase,
          customName: dirtyFields.customName ? customName : undefined,
        });

        // invalidate wallets query and reset the hook form with the new updated wallet props if the wallet was updated
        reset(getDefaultEditValues({ wallet: updatedWallet }));

        // close the drawer (close in here and not at the end of the function so that it doesn't close on an error)
        onCloseDrawer();
      } catch (error: any) {
        if (!axios.isAxiosError(error)) Sentry.captureException(error);
        setImportError(error);
      }
    }

    // -------------------- File Tab --------------------
    else if (tabInputType[tabIndex] == DataSourceInputType.Csv) {
      // early return if stored files is an empty array
      if (!storedFiles || !storedFiles.length) return;

      try {
        setIsImporting(true);
        await importWalletCsv(accountId, wallet._id, storedFiles as File[]);

        // close the drawer (close in here and not at the end of the function so that it doesn't close on an error)
        onCloseDrawer();

        // invalidate wallets query and reset the hook form
        reset();
      } catch (error: any) {
        if (!axios.isAxiosError(error)) Sentry.captureException(error);
        setImportError(error);
      }
    }
    setIsImporting(false);
  };

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DrawerHeading>
          {fileImport ? "Import Files" : "Edit Data Source"}
        </DrawerHeading>
        <Flex direction="column" h="calc(100% - 70px)">
          <Flex justifyContent="center" alignItems="center" my="30px">
            <ImageWithMissingSrc
              src={wallet.dataSource.image}
              alt=""
              mr="10px"
              h="40px"
              borderRadius="20px"
            />
            <Text fontSize="1.1rem" fontWeight="bold">
              {wallet.dataSource.name}
            </Text>
          </Flex>
          <Flex direction="column" p="15px" overflowY="auto">
            {/* --------------- Form Tabs --------------- */}
            <Tabs
              w="99%"
              mx="auto"
              defaultIndex={tabIndex}
              tabIndex={tabIndex}
              onChange={handleTabChange}
            >
              <TabList>
                {supportedInputs.sync && !fileImport ? (
                  <Tab w="50%" isDisabled={isImporting}>
                    Sync
                  </Tab>
                ) : undefined}
                {fileImport ? (
                  <Tab w="50%" isDisabled={isImporting}>
                    File Import
                  </Tab>
                ) : undefined}
              </TabList>
              <TabPanels>
                {supportedInputs.sync && !fileImport ? (
                  <TabPanel>
                    {tabInputType[tabIndex] == DataSourceInputType.Api && (
                      <EditApiTab
                        dataSource={dataSource}
                        wallet={wallet}
                        setError={setImportError}
                      />
                    )}
                  </TabPanel>
                ) : undefined}
                {fileImport ? (
                  <TabPanel>
                    {/* show spinner when importing, else show the file drop zone */}
                    {isImporting ? (
                      <Flex
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        h="100%"
                        m="10px 25px"
                      >
                        <Spinner
                          color="red.500"
                          emptyColor="red.200"
                          my="10px"
                          thickness="5px"
                          size="xl"
                        />
                      </Flex>
                    ) : (
                      <FileImportTab />
                    )}
                  </TabPanel>
                ) : undefined}
              </TabPanels>
            </Tabs>
          </Flex>
          <Spacer />
          {/* ---------------------- Import Error ----------------------- */}
          <ImportError
            error={importError}
            customFormat={dataSource.type == DataSourceType.Custom}
          ></ImportError>
          {/* --------------- Sticky Buttons --------------- */}
          <Flex
            direction="column"
            w="100%"
            p="16px 24px"
            position="sticky"
            bottom="0px"
            bgColor="white.0"
          >
            <ButtonVariant
              content={
                tabInputType[tabIndex] == DataSourceInputType.Api
                  ? "Save"
                  : "Import"
              }
              outlineType="solid"
              color="red"
              spam="spam"
              mb="10px"
              onClick={() => undefined}
              disabled={isSubmitting}
              type="submit"
            />
            <ButtonVariant
              content="Close"
              spam="spam"
              outlineType="outlineGray"
              onClick={onCloseDrawer}
              disabled={isSubmitting}
            />
          </Flex>
        </Flex>
      </Form>
    </FormProvider>
  );
};
