import React, { useEffect } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { HiDotsVertical } from "react-icons/hi";

export const DataSourceMenuButton = ({
  allowApiSync,
  syncDisabled,
  allowEdit,
  editText = "Edit",
  syncOnClick,
  editOnClick,
  importOnClick,
  deleteOnClick,
  walletAddress,
}: {
  allowApiSync: boolean;
  syncDisabled: boolean;
  allowEdit: boolean;
  editText?: string;
  syncOnClick: () => void;
  editOnClick: () => void;
  importOnClick: () => void;
  deleteOnClick: () => void;
  walletAddress: string | undefined;
}): React.ReactElement => {
  const { onCopy, hasCopied } = useClipboard(walletAddress ?? "");

  const toast = useToast();

  useEffect(() => {
    if (hasCopied) {
      toast({
        status: "info",
        title: "Copied Wallet Address",
      });
    }
  }, [hasCopied, toast]);

  return (
    <Menu>
      <MenuButton
        w="50px"
        h="70px"
        bgColor="white.0"
        as={IconButton}
        borderRadius="0"
        icon={<HiDotsVertical />}
        _hover={{ backgroundColor: "white.0" }}
        _active={{ backgroundColor: "white.0" }}
      />
      <MenuList p="0">
        {allowApiSync && (
          <MenuItem isDisabled={syncDisabled} pl="20px" onClick={syncOnClick}>
            Sync
          </MenuItem>
        )}
        {allowEdit && (
          <MenuItem pl="20px" onClick={editOnClick}>
            {editText}
          </MenuItem>
        )}
        {/*
          <MenuItem pl="20px">View transactions</MenuItem>
        */}
        <MenuItem pl="20px" onClick={importOnClick}>
          Import files
        </MenuItem>
        <MenuItem pl="20px" onClick={deleteOnClick}>
          Delete
        </MenuItem>
        {walletAddress && (
          <MenuItem pl="20px" onClick={onCopy}>
            Copy Wallet Address
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
};
