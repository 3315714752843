import React from "react";

import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "../components/atoms/Containers";
import { SignInForm } from "../components/molecules/forms/SignInForm";
import { SignUpInfo } from "../components/molecules/overlayBoxes/SignUpInfo";

export const SignIn = () => (
  <SignInParentContainer>
    <SignInLHSContainer>
      <SignUpInfo />
    </SignInLHSContainer>
    <SignInRHSContainer>
      <SignInForm />
    </SignInRHSContainer>
  </SignInParentContainer>
);
