import { UpdateAccountInvitesRequestBody } from "@syla/shared/types/requests/UpdateAccountInvitesRequest";
import axios from "axios";

export type UpdateAccountInvites = UpdateAccountInvitesRequestBody;
export const updateAccountInvites = async (
  accountId: string,
  props: UpdateAccountInvites
): Promise<void> => {
  const { data } = await axios.patch(
    `/accounts/${accountId}/access/invite`,
    props
  );
  return data;
};
