import { Flex, Image, Text, FlexProps } from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";

import { NumberStyler } from "../../../../helper/NumberStyler";
import { getOrderIconAndText } from "../../../../helper/transaction/getTransactionIconAndText";
import { useTransactionValues } from "../../../../hooks/useTransactionValues";
import { OrderResponse } from "../../../../types/order/orderRespond";
import { SkeletonRect } from "../../../atoms/Skeletons";
import { SliceWords } from "../SliceWords";
import { RenderAssetsIcon } from "../transactionDrawer/RenderAssetsIcon";
import {
  recentTransactionPropsColumnDate,
  recentTransactionPropsColumnIn,
  recentTransactionPropsColumnOut,
  recentTransactionPropsColumnOutcome,
  recentTransactionPropsColumnType,
} from "./RecentTransactionTableHeader";

export const RecentTransactionTableRow = ({
  orderResponse,
}: {
  orderResponse: OrderResponse;
}): JSX.Element => {
  const { negativeChanges, positiveChanges, outSources, inSources } =
    useTransactionValues(orderResponse.transactions);

  const textColor = "black.700";
  const { icon, text } = getOrderIconAndText(orderResponse);

  return (
    <Flex
      direction="row"
      alignItems="center"
      py="10px"
      borderRadius="0"
      borderWidth="0 0 1px 0"
      borderColor="white.500"
      bgColor="white.0"
      h="70px"
      w="100%"
    >
      {/* ------------------- Type ------------------- */}
      <Flex
        direction="row"
        alignItems="center"
        {...recentTransactionPropsColumnType}
      >
        <Image src={icon} alt="" mr="5px" maxH="100%" />
        <Text
          textTransform="capitalize"
          fontSize="0.75rem"
          fontWeight="500"
          color={textColor}
        >
          {text}
        </Text>
      </Flex>
      {/* ------------------- Date ------------------- */}
      <Flex {...recentTransactionPropsColumnDate}>
        <Text
          textAlign="left"
          fontSize="0.75rem"
          color={textColor}
          fontWeight="500"
        >
          {format(new Date(orderResponse.order.date), "dd MMM yyyy")}
        </Text>
      </Flex>
      {/* ------------------- Out ------------------- */}
      <Flex {...recentTransactionPropsColumnOut}>
        {negativeChanges.length > 0 && (
          <>
            <RenderAssetsIcon
              icons={negativeChanges.map((nc) => nc.asset.image)}
            />
            <Flex direction="column" {...inOutColStyleProps}>
              <Flex alignItems="center">
                <SliceWords dataSources={outSources} />
              </Flex>
              <Flex>
                <Text color="red.500" fontSize="0.75rem" fontWeight="500">
                  -
                </Text>
                <NumberStyler
                  num={negativeChanges[0]?.amount}
                  mx="5px"
                  color={textColor}
                  fontSize="0.75rem"
                  fontWeight="500"
                  assetInfo={negativeChanges[0]?.asset}
                  signed={false}
                />
                <Text
                  color={textColor}
                  fontSize="0.75rem"
                  fontWeight="500"
                  noOfLines={2}
                >
                  {getAssetShortName(negativeChanges[0]?.asset)}
                </Text>
                {negativeChanges.length > 1 && (
                  <Text color={textColor} fontSize="0.75rem" pl="5px">
                    + more
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {/* ------------------- In ------------------- */}
      <Flex {...recentTransactionPropsColumnIn}>
        {positiveChanges.length > 0 && (
          <>
            <RenderAssetsIcon
              icons={positiveChanges.map((pc) => pc.asset.image)}
            />
            <Flex direction="column" {...inOutColStyleProps}>
              <Flex alignItems="center">
                <SliceWords dataSources={inSources} />
              </Flex>
              <Flex>
                <Text color="green.500" fontSize="0.75rem" fontWeight="500">
                  +
                </Text>
                <NumberStyler
                  num={positiveChanges[0]?.amount}
                  mx="5px"
                  color={textColor}
                  fontSize="0.75rem"
                  fontWeight="500"
                  assetInfo={positiveChanges[0]?.asset}
                  signed={false}
                />
                <Text
                  color={textColor}
                  fontSize="0.75rem"
                  fontWeight="500"
                  noOfLines={1}
                >
                  {getAssetShortName(positiveChanges[0]?.asset)}
                </Text>
                {positiveChanges.length > 1 && (
                  <Text color={textColor} fontSize="0.75rem" pl="5px">
                    + more
                  </Text>
                )}
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
      {/* ------------------- Gain ------------------- */}
      <Flex {...recentTransactionPropsColumnOutcome}>
        {orderResponse.taxOutcome && (
          <SkeletonRect isLoaded={true}>
            <NumberStyler
              num={orderResponse.taxOutcome}
              colored
              bgColored
              unit="currency"
              fontSize="0.75rem"
              fontWeight="500"
              greenZeroValue
            />
          </SkeletonRect>
        )}
      </Flex>
    </Flex>
  );
};

const inOutColStyleProps: FlexProps = {
  w: "100%",
  pr: "20px",
};
