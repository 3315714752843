import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { AddDataSourceForm } from "../../../types/dataSource/dataSource";
import {
  StyledFormLabel,
  StyledInput,
} from "../../molecules/forms/FormComponents";

export const ParamInput = ({
  isDisabled,
  text,
  error,
  register,
  showLock = false,
}: {
  isDisabled: boolean;
  text: string;
  error: any;
  showLock?: boolean;
  register: UseFormRegisterReturn<keyof AddDataSourceForm>;
}) => (
  <FormControl
    isDisabled={isDisabled}
    isInvalid={error !== undefined}
    isRequired
  >
    <StyledFormLabel content={text} lockIcon={showLock} />
    <StyledInput
      // make this input required if this is the api tab
      {...register}
      placeholder={`Enter ${text} here`}
    />
    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
  </FormControl>
);
