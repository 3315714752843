import { UseToastOptions } from "@chakra-ui/react";
import { ReplaceProps } from "@syla/shared/types/helpers/ReplaceProps";
import { getWalletName } from "@syla/shared/types/models/WalletBase";
import { secondsToMilliseconds } from "date-fns";
import { Wallet } from "../../types/wallet/wallet";

export function buildSyncSuccessToastProps(
  wallets: Pick<
    ReplaceProps<Wallet, "dataSource", { dataSource: { name: string } }>,
    "customName" | "dataSource"
  >[]
): UseToastOptions {
  return {
    status: "success",
    title: `${
      wallets.length == 1
        ? getWalletName({
            wallet: wallets[0],
            dataSource: wallets[0].dataSource,
          })
        : "Data Sources"
    } synced successfully`,
    duration: secondsToMilliseconds(5),
    isClosable: true,
  };
}
