import {
  Flex,
  useDisclosure,
  BoxProps,
  useOutsideClick,
  FlexProps,
  ButtonProps,
} from "@chakra-ui/react";
import { intersection } from "lodash";
import React, { useRef, useState, useEffect } from "react";

import { OptionsContainer } from "../OptionsContainer";

import { MultiSelectBtn } from "./MultiSelectBtn";

interface MultiThumbnailSelectBoxPros extends BoxProps {
  // Must define
  options: SelectOptionType[];
  selectedOptions: string[];
  onChangeSelection: (selectedOptions: string[]) => void;
  // Optional features
  placeholder?: string;
  disabled?: boolean;
  doubleRows?: boolean;
  searchEnable?: boolean;
  /* Determines the height of the options container based on the options to view at one time without scrolling */
  maxOptionsInView?: number;
  /* Total max options to show without searching. Useful for a large list of options that is slow to render */
  maxOptionsToDisplay?: number;
  description?: boolean;
  exclusive?: boolean;
  // Styling
  optionHeight?: number;
  // Custom props
  selectBtnProps?: ButtonProps;
  optionContainerProps?: FlexProps;
  showIcon?: boolean;
}

export const MultiThumbnailSelectBox = ({
  options,
  selectedOptions,
  onChangeSelection,
  placeholder = "Please select ...",
  disabled = false,
  doubleRows = false,
  searchEnable = false,
  maxOptionsInView,
  maxOptionsToDisplay,
  description = false,
  exclusive = false,
  optionHeight = 45,
  selectBtnProps,
  optionContainerProps,
  showIcon,
  ...props
}: MultiThumbnailSelectBoxPros): JSX.Element => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick({ ref, handler: () => onClose() });

  // if some options aren't valid anymore, clear them from selection
  useEffect(() => {
    // ignore if still loading
    if (!options.length) return;

    const validSelectedOptions = intersection(
      options.map((o) => o.value),
      selectedOptions
    );
    if (validSelectedOptions.length != selectedOptions.length) {
      onChangeSelection(validSelectedOptions);
    }
  }, [onChangeSelection, options, selectedOptions]);

  return (
    <Flex
      direction="column"
      position="relative"
      overflowX="visible"
      {...props}
      ref={ref}
    >
      <MultiSelectBtn
        selectedOptions={selectedOptions}
        isOpen={isOpen}
        onClickBtn={() => {
          if (disabled) return;
          onToggle();
          setSearchValue("");
        }}
        placeholder={placeholder}
        disabled={disabled}
        selectBtnProps={selectBtnProps}
      />

      {isOpen && (
        <OptionsContainer
          options={options}
          selectedOptions={selectedOptions ?? []}
          doubleRows={doubleRows}
          searchEnable={searchEnable}
          description={description}
          onClose={() => onClose()}
          searchValue={searchValue}
          maxOptionsInView={maxOptionsInView}
          maxOptionsToDisplay={maxOptionsToDisplay}
          exclusive={exclusive}
          onChangeSearchValue={(e) => setSearchValue(e)}
          onClick={(selection) => {
            onChangeSelection(
              selectedOptions.includes(selection)
                ? selectedOptions.filter(
                    (selectedOption) => selectedOption !== selection
                  )
                : [...selectedOptions, selection]
            );
          }}
          optionHeight={optionHeight}
          optionContainerProps={optionContainerProps}
          showIcon={showIcon}
        />
      )}
    </Flex>
  );
};
