import { Flex, Text, useDisclosure, Spacer } from "@chakra-ui/react";
import {
  WalletImportMethod,
  getWalletName,
} from "@syla/shared/types/models/WalletBase";
import { orderBy, some } from "lodash";
import React, { useMemo, useState, createContext } from "react";
import { isBrowser } from "react-device-detect";
import { Path } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { getDataSourceSortOptions } from "../../../../helper/dataSource/getDataSourceSortOptions";
import { useLocalStorage } from "../../../../hooks/localStorage/useLocalStorage";
import { useFuseSearch } from "../../../../hooks/useFuseSearch";
import { buildTransactionsLink } from "../../../../routers/routePaths";
import { reSyncWallets } from "../../../../store/actions/reSyncWallets";
import { useGetWallets } from "../../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useValueHoldings } from "../../../../store/useValueHoldings";
import { DataSourceSortOption } from "../../../../types/dataSource/dataSource";
import { WalletResponse } from "../../../../types/wallet/wallet";
import { AlertBar } from "../../../atoms/AlertBar";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { PageHeadingContainer } from "../../../atoms/Containers";
import { PageHeading } from "../../../atoms/Headings";
import { SingleSelectBox } from "../../../atoms/singleSelectBox";
import { SearchBar } from "../../../molecules/forms/FormComponents";
import {
  PageHeadingTag,
  PageHeadingTagContainer,
} from "../../../molecules/PageHeadingTag";
import { getWalletHoldingsValue } from "./getWalletHoldingsValue";
import { RenderWallets } from "./RenderWallets";

interface MainDataSourceTableProps {
  addDataSourceButtonRef: any;
  openDrawer: () => void;
}

export type LoadingState = {
  isLoading: boolean;
  isStale: boolean;
};
export const DataSourceLoadingContext = createContext<LoadingState>({
  isLoading: false,
  isStale: false,
});

export const MainDataSourceTable = ({
  addDataSourceButtonRef,
  openDrawer,
}: MainDataSourceTableProps): JSX.Element => {
  // Alert state
  const { isOpen: showAlert, onClose: onCloseAlert } = useDisclosure();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  // query for wallets data
  const {
    data: walletResponses,
    isLoading,
    isStale,
  } = useGetWallets(accountId);

  // value holdings
  const { data: holdingsValue } = useValueHoldings();

  // populate wallet holding market values
  for (const { _id: walletId, holdings } of walletResponses?.wallets ?? []) {
    for (const holding of holdings ?? []) {
      holding.marketValue = holdingsValue?.getWalletAssetValue({
        walletId,
        assetId: holding.asset._id,
      })?.marketValue;
    }
  }

  // Filter the wallets by search query
  const [walletsSearchQuery, setWalletsSearchQuery] = useState("");

  // Sort the user wallets by selection
  const [sortOption, setSortOption] = useLocalStorage<
    DataSourceSortOption | undefined
  >("dataSourcesSortBy", undefined);

  // filter the user wallets by search query
  const searchFilteredWallets: WalletResponse[] = useFuseSearch(
    walletResponses?.wallets || [],
    ["dataSource.name", "dataSource.type", "dataSource.code", "customName"],
    walletsSearchQuery,
    {
      threshold: 0.4,
    }
  );

  // wallets memo, returns search filtered wallets if no sort options selected
  const wallets = useMemo(() => {
    if (!sortOption) return searchFilteredWallets;

    const sortKeys: (Path<WalletResponse> | ((w: WalletResponse) => any))[] =
      [];
    const sortOrder: (boolean | "desc" | "asc")[] = [];

    if (sortOption === DataSourceSortOption.NameAscending) {
      sortKeys.push((w) =>
        getWalletName({ wallet: w, dataSource: w.dataSource })
      );
      sortOrder.push("asc");
    } else if (sortOption === DataSourceSortOption.NameDescending) {
      sortKeys.push((w) =>
        getWalletName({ wallet: w, dataSource: w.dataSource })
      );
      sortOrder.push("desc");
    } else if (sortOption === DataSourceSortOption.MostTransactions) {
      sortKeys.push("transactionCount");
      sortOrder.push("desc");
    } else if (sortOption == DataSourceSortOption.HighestValue) {
      sortKeys.push((walletWithHoldings) => {
        const value = getWalletHoldingsValue({
          holdingsValue,
          walletWithHoldings,
        })?.toNumber(); // use toNumber to enable regular sort

        return value ?? Number.NEGATIVE_INFINITY; // null values should be treated as less than all other values
      });
      sortOrder.push("desc");
    } else {
      // default is date added
      sortKeys.push("createdAt");
      sortOrder.push("asc");
    }

    return orderBy(searchFilteredWallets, sortKeys, sortOrder);
  }, [holdingsValue, searchFilteredWallets, sortOption]);

  // boolean if a an api imported wallet is in the wallets array
  const apiImportedWalletExists = some(
    wallets,
    (wallet) => wallet.importMethod == WalletImportMethod.API
  );

  // re-sync all wallets handler
  const reSyncAllWalletsHandler = async () => {
    // verify there is an api imported wallet in the wallets array
    if (!apiImportedWalletExists) return;

    // filtered array of only api imported wallets
    const apiWallets = wallets.filter(
      (wallet) => wallet.importMethod == WalletImportMethod.API
    );
    await reSyncWallets(accountId, apiWallets);
  };

  return (
    <>
      <PageHeadingContainer>
        <PageHeadingTagContainer>
          <PageHeading>Data Sources</PageHeading>
          {walletResponses != null && (
            <PageHeadingTag>
              {walletResponses.wallets.length.toLocaleString()}
            </PageHeadingTag>
          )}
        </PageHeadingTagContainer>

        <Flex direction={isBrowser ? { base: "column", xs: "row" } : "column"}>
          {/* only show the sync button if a wallet imported from api exists */}
          {apiImportedWalletExists && (
            <ButtonVariant
              content="Sync"
              outlineType="outlineRed"
              leftIcon="sync"
              onClick={reSyncAllWalletsHandler}
              mb={isBrowser ? { base: "10px", xs: "0" } : "10%"}
            />
          )}
          <ButtonVariant
            ref={addDataSourceButtonRef}
            content="Add Data Source"
            color="red"
            leftIcon="add"
            ml={isBrowser ? { base: "0", xs: "20px" } : "0"}
            onClick={openDrawer}
          />
        </Flex>
      </PageHeadingContainer>

      <Flex direction="column" w="100%">
        <Text color="black.700" mb="20px">
          Your exchanges, wallets, blockchains and other services.
        </Text>
        {showAlert && (
          <AlertBar
            content="Your API Synced Data Sources are all up to date."
            onClose={() => onCloseAlert()}
          />
        )}

        {/* ------------------- Search Bar -------------------*/}
        <Flex mb="30px" w="100%">
          <SearchBar
            value={walletsSearchQuery}
            placeholder="Search your data sources"
            onChange={(e) => setWalletsSearchQuery(e.target.value)}
          />
          <SingleSelectBox
            allowUnSelect
            placeholder="Sort By"
            options={getDataSourceSortOptions()}
            selectedOption={sortOption}
            onChangeSelection={(selection) =>
              setSortOption(selection as DataSourceSortOption)
            }
            ml="10px"
            selectBtnProps={{ height: "40px" }}
            selectBtnTextProps={{ color: "#808080" }}
            optionContainerProps={{
              width: "160px",
              right: "0px",
            }}
          />
        </Flex>
        {/* ----------------------- data source table row -----------------------  */}
        <DataSourceLoadingContext.Provider
          value={{
            isLoading,
            isStale,
          }}
        >
          <RenderWallets walletResponses={wallets} />
        </DataSourceLoadingContext.Provider>
        <Spacer />
        <Flex justifyContent="flex-end">
          <NavLink to={buildTransactionsLink({ accountId })}>
            <ButtonVariant
              content="Review Transactions"
              outlineType="solid"
              color="darkGray"
              spam="auto"
              rightIcon="next"
              mb="20px"
            />
          </NavLink>
        </Flex>
      </Flex>
    </>
  );
};
