"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCgtTaxOutcomeType = exports.TaxClassification = exports.TaxOutcomeType = void 0;
var TaxOutcomeType;
(function (TaxOutcomeType) {
    TaxOutcomeType["CapitalGainLongTerm"] = "CapitalGainLongTerm";
    TaxOutcomeType["CapitalGainShortTerm"] = "CapitalGainShortTerm";
    TaxOutcomeType["CapitalLoss"] = "CapitalLoss";
    TaxOutcomeType["CapitalGainExempt"] = "CapitalGainExempt";
    TaxOutcomeType["CapitalLossExempt"] = "CapitalLossExempt";
    TaxOutcomeType["ForeignCurrencyGain"] = "ForeignCurrencyGain";
    TaxOutcomeType["ForeignCurrencyLoss"] = "ForeignCurrencyLoss";
    TaxOutcomeType["OtherIncome"] = "OtherIncome";
    TaxOutcomeType["OtherExpense"] = "OtherExpense";
    TaxOutcomeType["GSTPaid"] = "GSTPaid";
    TaxOutcomeType["GSTCollected"] = "GSTCollected";
    TaxOutcomeType["NonAssessableIncome"] = "NonAssessableIncome";
    TaxOutcomeType["NonDeductibleExpense"] = "NonDeductibleExpense";
    TaxOutcomeType["TradingStockPurchaseAndOtherCost"] = "TradingStockPurchaseAndOtherCost";
    TaxOutcomeType["TradingStockSales"] = "TradingStockSales";
    TaxOutcomeType["Donations"] = "Donations";
})(TaxOutcomeType = exports.TaxOutcomeType || (exports.TaxOutcomeType = {}));
var TaxClassification;
(function (TaxClassification) {
    TaxClassification["PersonalUseAssetExempt"] = "PersonalUseAssetExempt";
    TaxClassification["TradingStock"] = "TradingStock";
    TaxClassification["FiatCurrency"] = "FiatCurrency";
    TaxClassification["CGTAsset"] = "CGTAsset";
    TaxClassification["DomesticCurrency"] = "DomesticCurrency";
})(TaxClassification = exports.TaxClassification || (exports.TaxClassification = {}));
function isCgtTaxOutcomeType({ type, }) {
    switch (type) {
        case TaxOutcomeType.CapitalGainLongTerm:
        case TaxOutcomeType.CapitalGainShortTerm:
        case TaxOutcomeType.CapitalLoss:
        case TaxOutcomeType.CapitalGainExempt:
        case TaxOutcomeType.CapitalLossExempt:
        case TaxOutcomeType.ForeignCurrencyGain:
        case TaxOutcomeType.ForeignCurrencyLoss:
            return true;
        case TaxOutcomeType.OtherIncome:
        case TaxOutcomeType.OtherExpense:
        case TaxOutcomeType.GSTPaid:
        case TaxOutcomeType.GSTCollected:
        case TaxOutcomeType.NonAssessableIncome:
        case TaxOutcomeType.NonDeductibleExpense:
        case TaxOutcomeType.TradingStockPurchaseAndOtherCost:
        case TaxOutcomeType.TradingStockSales:
        case TaxOutcomeType.Donations:
            return false;
    }
}
exports.isCgtTaxOutcomeType = isCgtTaxOutcomeType;
