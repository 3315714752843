"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.formatStringWithCommas = void 0;
/*
 * format string with comma on each 3 number chars
 * it will format the digits before the . with commas, but not the digits after the .
 * for example 10000.0001 will convert to 10,000.0001
 * there is a more simple way with regex: replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
 * but safari currently not supporting regexp lookbehind
 * https://caniuse.com/js-regexp-lookbehind
 */
const date_fns_1 = require("date-fns");
const formatStringWithCommas = (stringValue) => {
    const parts = stringValue.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
};
exports.formatStringWithCommas = formatStringWithCommas;
// format date like '30 June 2022'
const formatDate = (date) => (0, date_fns_1.format)(date, "d MMMM yyyy");
exports.formatDate = formatDate;
