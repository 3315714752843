import * as Sentry from "@sentry/react";
import React, { ReactNode } from "react";
import { withErrorBoundary } from "react-error-boundary";
import { RouteComponentProps } from "react-router-dom";
import { ErrorFallBack } from "../components/atoms/ErrorFallBack";

/**
 * Wrap a routed component within an error boundary that will handle any errors from that component
 * and render a fallback error component instead.
 * The error state will be reset if the route changes.
 *
 * @param component Component to render in error boundary
 * @returns Function that can be called by Route.render
 */
export const renderRoutedComponentWithFallback =
  (component: React.ComponentType) =>
  (routeProps: RouteComponentProps): ReactNode => {
    const ErrorBounded = withErrorBoundary(component, {
      fallback: ErrorFallBack,
      resetKeys: [routeProps.location.pathname], // reset error state on route change
      onError: (error, { componentStack }) => {
        Sentry.captureException(error, (scope) =>
          // add componentStack as attachment
          // n.b. this doesn't always show up in Sentry for some reason
          scope.addAttachment({
            filename: "componentStack.txt",
            data: componentStack,
          })
        );
      },
    });

    return <ErrorBounded />;
  };
