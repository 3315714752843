import type { PartialDeep } from "type-fest";

/**
 * Given a react hook forms `dirtyFields` object, extract the corresponding fields from the data object
 *
 * Based on https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-2766887
 *
 * @param dirtyFields The modified form fields from `formState`
 * @param data The corresponding data object
 * @returns A subset of the data object with values populated based on `true` values in the `dirtyFields` object.
 */
export function getDirtyValues<TData extends object>(
  dirtyFields: any | boolean,
  data: TData
): PartialDeep<TData> {
  return getDirtyValuesRecursive(dirtyFields, data);
}

export function getDirtyValuesRecursive(
  dirtyFields: any | boolean,
  data: any
): any {
  // console.debug({ dirtyFields, data });
  if (isPrimitive(data) || data instanceof Date || data instanceof File)
    return data;

  if (Array.isArray(data) && dirtyFields === true) return data;

  if (Array.isArray(dirtyFields)) {
    const dirtyArray = dirtyFields
      .map((el, index) => {
        // console.debug({ index, el });
        return getDirtyValuesRecursive(el, data[index]);
      })
      .filter((_, index) => index in dirtyFields);
    return dirtyArray;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields)
      .filter((key) => dirtyFields[key] !== false)
      .map((key) => [key, getDirtyValuesRecursive(dirtyFields[key], data[key])])
  );
}

function isPrimitive(test: any) {
  return test !== Object(test);
}
