import axios from "axios";
import { SupportCsvDocument } from "../../constants/SupportCsvDocument";

export const downloadSupportCsv = async ({
  accountId,
  financialYearValue,
  csvDocument,
}: {
  accountId: string;
  financialYearValue: string;
  csvDocument: SupportCsvDocument;
}): Promise<void> => {
  const { data, headers } = await axios.get(
    `/accounts/${accountId}/${csvDocument.url}`,
    {
      params: { financialYearValue },
      responseType: "blob",
    }
  );

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(data);
  link.setAttribute(
    "download",
    headers["content-disposition"]?.match(/filename="(.*)"/)?.at(1) ??
      `Syla ${csvDocument.name} ${financialYearValue.replace("/", "-")}.csv`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};
