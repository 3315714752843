import React from "react";
import { Flex } from "@chakra-ui/react";
import { isBrowser } from "react-device-detect";
import {
  PageContainer,
  PageHeadingContainer,
} from "../components/atoms/Containers";
import { PageHeading } from "../components/atoms/Headings";
import { DataSourceTable } from "../components/organisms/dataSource/dashboardDataSourceTable/DataSourceTable";
import { HoldingTable } from "../components/organisms/holding/HoldingTable";
import { StatBoxes } from "../components/organisms/statBoxes/StatBoxes";
import { TaxReportsTable } from "../components/organisms/taxReport/TaxReportsTable";
import { RecentTransactionTable } from "../components/organisms/transaction/recentTransactionTable/RecentTransactionTable";
import { useGetWallets } from "../store/actions/wallet";
import { useCurrentAccountStore } from "../store/currentAccountStore";

export const Dashboard = () => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: walletResponses, isLoading } = useGetWallets(accountId);

  const isWalletsLoading = isLoading;

  return (
    <PageContainer isBrowser={isBrowser}>
      <PageHeadingContainer>
        <PageHeading>Dashboard</PageHeading>
        {/* Removing these for now, will be required in future */}
        {/* <ButtonVariant
          content="Share your portfolio"
          color="red"
          leftIcon="share"
          onClick={() => alert("share your portfolio")}
        />
        <ButtonVariant
          content="Create PDF"
          color="red"
          onClick={() =>
            // Leaving for testing
            downloadTaxReportForFinancialYear("FY2021/22")
          }
        /> */}
      </PageHeadingContainer>

      <Flex direction={isBrowser ? { base: "column", xl: "row" } : "column"}>
        {/* ----------------- LHS -----------------  */}
        <Flex
          direction="column"
          w="100%"
          maxW={isBrowser ? { base: "100%", xl: "1000px" } : "100%"}
          mr={isBrowser ? { base: "0", xl: "20px" } : "0"}
          basis={"60%"}
          grow={1}
        >
          <StatBoxes
            assetHoldings={walletResponses?.accountHoldings}
            isLoading={isWalletsLoading}
          />
          {/* TODO: integrate real performance graph data, hide for now */}
          {/* <PerformanceGraph /> */}
          <HoldingTable
            holdings={walletResponses?.accountHoldings}
            wallets={walletResponses?.wallets}
            isLoading={isWalletsLoading}
          />
          <DataSourceTable
            walletResponses={walletResponses?.wallets}
            isLoading={isWalletsLoading}
          />
          <RecentTransactionTable />
        </Flex>
        {/* ----------------- RHS -----------------  */}
        <Flex
          basis={1}
          grow={1}
          direction={{ base: "column", xs: "row", xl: "row", "2xl": "column" }}
          w="100%"
          justifyContent={
            isBrowser
              ? {
                  base: "flex-start",
                  xs: "space-between",
                  xl: "flex-start",
                }
              : "flex-start"
          }
        >
          <TaxReportsTable />
        </Flex>
      </Flex>
    </PageContainer>
  );
};
