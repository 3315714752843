import { groupBy, transform } from "lodash";
import {
  TaxOutcomeType,
  isCgtTaxOutcomeType,
} from "@syla/shared/types/models/TaxOutcomeBase";
import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import { Decimal, sumWithDecimal } from "@syla/shared/decimal";
import { Asset } from "../types/asset/asset";
import { TaxOutcome } from "../types/taxOutcome";
import { Ledger } from "../types/ledger";

interface ExtendedTaxOutcome extends TaxOutcome {
  ledgerType: LedgerType;
  asset: Asset;
}

export interface TaxOutcomeDetail {
  classification: string;
  subClassification: string;
  amount: Decimal;
  asset: Asset;
  outcome: Decimal;
  parcels?: TaxOutcome[];
}

export const getAggregatedLedgerTaxOutcomes = (
  ledgers: Ledger[]
): TaxOutcomeDetail[] => {
  // gather all taxOutcomes from each ledger while extents with asset && ledgerType
  const taxOutcomes = ledgers.reduce(
    (total: ExtendedTaxOutcome[], ledger: Ledger) => {
      const taxOutcomesExtended: ExtendedTaxOutcome[] = (
        ledger.taxOutcomes ?? []
      ).map((taxOutcome) => ({
        ...taxOutcome,
        ledgerType: ledger.type,
        asset: ledger.asset,
        // TODO - workaround solution
        amount: taxOutcome.amount ?? ledger.amount.abs(),
      }));
      return [...total, ...taxOutcomesExtended];
    },
    []
  );

  const taxOutcomesGroupedByType = objectSortByTaxOutcomeTypeOrder(
    groupBy(taxOutcomes, (to) => to.type)
  );

  // transfer ExtendedTaxOutcome to taxOutcome columns array by applying group and sort
  // for cgt tax outcome type: group and sort with asset code alphabetical order
  // for non  cgt tax outcome type: first group and sort with ledgerType and then group and sort with asset code
  return transform(
    taxOutcomesGroupedByType,
    (result: TaxOutcomeDetail[], value: ExtendedTaxOutcome[], key) => {
      if (isCgtTaxOutcomeType({ type: key as TaxOutcomeType })) {
        const cgtTypeGroupedByAsset = groupBy(value, (to) => to.asset.code);
        // sort asset code by alphabetical order
        Object.keys(cgtTypeGroupedByAsset)
          .sort()
          .forEach((assetCode) => {
            result.push({
              classification: getTaxClassification(key as TaxOutcomeType),
              subClassification: getTaxSubClassificationByCgtType(
                key as TaxOutcomeType
              ),
              amount: sumWithDecimal(
                cgtTypeGroupedByAsset[assetCode],
                "amount"
              ),
              asset: cgtTypeGroupedByAsset[assetCode][0].asset,
              outcome: sumWithDecimal(cgtTypeGroupedByAsset[assetCode], "gain"),
              parcels: cgtTypeGroupedByAsset[assetCode],
            });
          });
      } else {
        const noCgtTypeGroupedByLedgerType = groupBy(
          value,
          (to) => to.ledgerType
        );
        // sort ledger type by alphabetical order
        Object.keys(noCgtTypeGroupedByLedgerType)
          .sort()
          .forEach((ledgerType) => {
            const noCgtTypeGroupedByAssetCode = groupBy(
              noCgtTypeGroupedByLedgerType[ledgerType],
              (to) => to.asset.code
            );
            // sort asset code by alphabetical order
            Object.keys(noCgtTypeGroupedByAssetCode)
              .sort()
              .forEach((assetCode) => {
                result.push({
                  classification: getTaxClassification(key as TaxOutcomeType),
                  subClassification: ledgerType,
                  amount: sumWithDecimal(
                    noCgtTypeGroupedByAssetCode[assetCode],
                    "amount"
                  ),
                  asset: noCgtTypeGroupedByAssetCode[assetCode][0].asset,
                  outcome: sumWithDecimal(
                    noCgtTypeGroupedByAssetCode[assetCode],
                    "gain"
                  ),
                });
              });
          });
      }
    },
    []
  );
};

// sort tax outcome type by certain order
const objectSortByTaxOutcomeTypeOrder = (input: {
  [key in TaxOutcomeType]?: ExtendedTaxOutcome[];
}) => {
  const result: { [key in TaxOutcomeType]?: ExtendedTaxOutcome[] } = {};
  [
    TaxOutcomeType.CapitalGainShortTerm,
    TaxOutcomeType.CapitalGainLongTerm,
    TaxOutcomeType.CapitalLoss,
    TaxOutcomeType.CapitalGainExempt,
    TaxOutcomeType.CapitalLossExempt,
    TaxOutcomeType.ForeignCurrencyGain,
    TaxOutcomeType.ForeignCurrencyLoss,
    TaxOutcomeType.OtherIncome,
    TaxOutcomeType.OtherExpense,
    TaxOutcomeType.NonAssessableIncome,
    TaxOutcomeType.NonDeductibleExpense,
    TaxOutcomeType.Donations,
    TaxOutcomeType.TradingStockSales,
    TaxOutcomeType.TradingStockPurchaseAndOtherCost,
    TaxOutcomeType.GSTCollected,
    TaxOutcomeType.GSTPaid,
  ].forEach((taxOutcomeType) => {
    if (input[taxOutcomeType]) {
      result[taxOutcomeType] = input[taxOutcomeType];
    }
  });
  return result;
};

const getTaxClassification = (taxOutcomeType: TaxOutcomeType) => {
  switch (taxOutcomeType) {
    case TaxOutcomeType.CapitalGainShortTerm:
    case TaxOutcomeType.CapitalGainLongTerm:
    case TaxOutcomeType.CapitalGainExempt:
      return "Capital Gain";
    case TaxOutcomeType.CapitalLoss:
    case TaxOutcomeType.CapitalLossExempt:
      return "Capital Loss";
    case TaxOutcomeType.ForeignCurrencyGain:
    case TaxOutcomeType.ForeignCurrencyLoss:
      return "Foreign Currency";
    case TaxOutcomeType.OtherIncome:
      return "Other Income";
    case TaxOutcomeType.OtherExpense:
      return "Other Expense";
    case TaxOutcomeType.NonAssessableIncome:
      return "Non Assessable Income";
    case TaxOutcomeType.NonDeductibleExpense:
      return "Non Deductible Expense";
    case TaxOutcomeType.Donations:
      return "Donations";
    case TaxOutcomeType.TradingStockSales:
      return "Sales of Trading Stock";
    case TaxOutcomeType.TradingStockPurchaseAndOtherCost:
      return "Purchase and Other Costs";
    case TaxOutcomeType.GSTCollected:
      return "GST Collected";
    case TaxOutcomeType.GSTPaid:
      return "GST Paid";
  }
};

const getTaxSubClassificationByCgtType = (taxOutcomeType: TaxOutcomeType) => {
  switch (taxOutcomeType) {
    case TaxOutcomeType.CapitalGainShortTerm:
      return "Short-Term";
    case TaxOutcomeType.CapitalGainLongTerm:
      return "Long-Term";
    case TaxOutcomeType.CapitalGainExempt:
    case TaxOutcomeType.CapitalLossExempt:
      return "Exempt";
    case TaxOutcomeType.CapitalLoss:
      return "";
    case TaxOutcomeType.ForeignCurrencyGain:
      return "Gain";
    case TaxOutcomeType.ForeignCurrencyLoss:
      return "Loss";
  }
  return "";
};
