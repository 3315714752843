import { Text, Flex, Spinner, Image } from "@chakra-ui/react";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import React, { useContext } from "react";
import { isBrowser } from "react-device-detect";
import { resetAccount } from "../../api/account/resetAccount";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { PageContainer } from "../../components/atoms/Containers";
import { useDeleteAccountDialog } from "../../components/organisms/accounts/useDeleteAccountDialog";
import { ModalContext } from "../../contexts/ModalContext";
import { isProduction } from "../../helper/environment";
import { hasAccountPermission } from "../../helper/permissions";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import {
  resetTransactionStore,
  queryClient,
  getAccountTransactionsCacheKey,
} from "../../store/transactionsStore";
import { useQueryGetAccount } from "../../store/useQueryGetAccount";
import { Account } from "../../types/Account";
import {
  SectionContainer,
  SectionHeading,
  SettingLabel,
  Btn,
} from "./AccountSettingsCommon";

export function AccountOtherSettings() {
  const { openModal, setBtn2Loading, closeModal } = useContext(ModalContext);

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  if (accountIsLoading || !account)
    return (
      <PageContainer isBrowser={isBrowser}>
        <Flex justifyContent="center" my="40px">
          <Spinner />
        </Flex>
      </PageContainer>
    );

  return (
    <SectionContainer>
      <SectionHeading>Other</SectionHeading>
      {!isProduction() && (
        <Flex direction="row" mt="30px" justifyContent="space-between">
          <SettingLabel mb="20px">Reset Account (Testing)</SettingLabel>
          <Btn
            onClick={() =>
              openModal({
                type: "confirmation",
                icon: "delete",
                heading: "Are you sure you want to reset the account?",
                contents: ["This action is irreversible."],
                btn2Content: "Reset",
                btn2Action: async () => {
                  try {
                    setBtn2Loading(true);
                    await resetAccount(accountId);
                    resetTransactionStore({ initAccountId: accountId });
                    queryClient.invalidateQueries(
                      ...getAccountTransactionsCacheKey(accountId)
                    );
                    setBtn2Loading(false);
                    closeModal();
                  } catch (error) {
                    // TODO: implement error toast here
                    console.log(error);
                  }
                },
              })
            }
          >
            <Image
              height="25px"
              mr="10px"
              src="https://cdn1.iconfinder.com/data/icons/editing/60/cell-12-2-480.png"
            />
            RESET ACCOUNT
          </Btn>
        </Flex>
      )}
      {/* ---------------------- Delete Account ---------------------- */}
      {hasAccountPermission(account, AccountPermission.DeleteAccount) && (
        <DeleteAccountButton account={account} />
      )}
    </SectionContainer>
  );
}

function DeleteAccountButton({ account }: { account: Account }) {
  const { openDeleteAccountDialog } = useDeleteAccountDialog({
    account,
  });

  return (
    <Flex direction="row" mt="30px" justifyContent="space-between">
      <Flex direction="column" mb="20px" w="70%">
        <SettingLabel mb="20px">Delete Account</SettingLabel>
        <Text fontSize="0.875rem" color="black.500">
          This deletes all of the data for this account, including exchanges,
          wallets, transactions and trade history. Any active subscriptions will
          also be terminated. This action is irreversible.
        </Text>
      </Flex>
      <ButtonVariant
        content={"Delete Account"}
        color="darkRed"
        onClick={openDeleteAccountDialog}
      />
    </Flex>
  );
}
