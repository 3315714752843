import { Flex, useBreakpointValue, FlexProps } from "@chakra-ui/react";
import React from "react";

import { OrderSortOption } from "../../../../types/order/orderQuery";
import { SingleSelectBox } from "../../../atoms/singleSelectBox";
import { SearchBar } from "../../../molecules/forms/FormComponents";

export const PageSizeOptions = [20, 50, 100];

interface SearchSectionProps {
  orderSortOption: OrderSortOption | undefined;
  setOrderSortOption: (selection: OrderSortOption) => void;
  ordersSearchQuery: string;
  onChangeOrderSearchQuery: (content: string) => void;
  pageSizeOption: number;
  setPageSizeOption: (pageSize: number) => void;
}

export const SearchSection = ({
  orderSortOption,
  setOrderSortOption,
  ordersSearchQuery,
  onChangeOrderSearchQuery,
  pageSizeOption,
  setPageSizeOption,
  ...flexProps
}: SearchSectionProps & FlexProps): JSX.Element => {
  const searchBarFullWidth = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    xs: true,
    "2xs": true,
  });
  return (
    <Flex
      w="100%"
      direction="row"
      alignItems="center"
      p="15px"
      flexWrap={searchBarFullWidth ? "wrap" : "nowrap"}
      gridGap="6px"
      {...flexProps}
    >
      <SearchBar
        placeholder="Search for a transaction"
        onChange={(e) => onChangeOrderSearchQuery(e.target.value)}
        value={ordersSearchQuery}
      />
      <SingleSelectBox
        placeholder="Sort By"
        selectedOption={orderSortOption}
        onChangeSelection={(selection) =>
          setOrderSortOption(
            (selection as OrderSortOption) ?? OrderSortOption.DateAscending
          )
        }
        options={Object.entries(OrderSortOption).map(([value, label]) => ({
          value,
          label,
        }))}
        allowUnSelect
        ml="10px"
        selectBtnProps={{ height: "40px" }}
        selectBtnTextProps={{ color: "#808080" }}
        optionContainerProps={{
          width: "160px",
          right: "0px",
        }}
      />
      <SingleSelectBox
        placeholder="ITEMS PER PAGE"
        selectedOption={pageSizeOption.toString()}
        onChangeSelection={(selection) =>
          setPageSizeOption(
            selection != null ? parseInt(selection) : PageSizeOptions["20"]
          )
        }
        options={PageSizeOptions.map((value) => ({
          label: `Show ${value} Items`,
          value: value.toString(),
        }))}
        ml="10px"
        selectBtnProps={{ height: "40px" }}
        selectBtnTextProps={{ color: "#808080" }}
        optionContainerProps={{
          width: "160px",
          right: "0px",
        }}
      />
    </Flex>
  );
};
