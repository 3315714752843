import { Decimal } from "./decimal";

/**
 * Sums a list of Decimal's.
 * Returns Decimal("0") if there are none supplied.
 * @param items
 */
export const sumDecimals = (items: (Decimal | undefined | null)[]): Decimal => {
  return <Decimal>items.reduce((total: Decimal, item) => {
    return item ? total.plus(item) : total;
  }, Decimal.from("0"));
};

/**
 * This function takes in an array of objects, that can be anything. It sums the objects together by using the provided fieldName
 * @param items The items to sum for
 * @param fieldName The fieldName that will be used to sum the items together
 * @returns Returns the sum of the items at the given fieldName
 */
export const sumWithDecimal = <T extends Record<string, any>>(
  items: (T | undefined | null)[] | undefined | null,
  fieldName: keyof T
): Decimal =>
  !items
    ? Decimal.from("0")
    : items.reduce((total: Decimal, item: T | undefined | null) => {
        try {
          return item?.[fieldName] ? total.plus(item[fieldName]) : total;
        } catch (e) {
          return total;
        }
      }, Decimal.from("0"));

/**
 * This function calculates the sum of the provided items with the sumFunction provided
 * @param items The items to sum
 * @param sumFunction The function that will sum the items together
 * @returns Returns the sum of all items
 */
export const sumDecimalWithFunction = <T>(
  items: T[],
  sumFunction: (item: T) => Decimal | undefined
): Decimal =>
  items.reduce((total: Decimal, item: T) => {
    try {
      return sumFunction(item)
        ? total.plus(sumFunction(item) as string | Decimal)
        : total;
    } catch (e) {
      return total;
    }
  }, Decimal.from("0"));

// Is value defined and non-zero?
export function hasValue(value: Decimal | undefined): value is Decimal {
  return !!value && !value.isZero();
}

// returns non-zero value or undefined
export function getNonZeroValue(value: Decimal | undefined) {
  return hasValue(value) ? value : undefined;
}
