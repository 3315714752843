import React, { memo, useMemo } from "react";
import { Flex, StyleProps, Text, FlexProps, Checkbox } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useTransactionsStore } from "../../../../store/transactionsStore";
import { useQueryAccountAssetOptions } from "../../../../store/useQueryAccountAssetOptions";
import { OrderFilterSetting } from "../../../../types/order/orderQuery";
import { StdVStack } from "../../../atoms/Containers";
import { EmptyDataMessage } from "../../../atoms/EmptyDataMessage";
import { HEADER_Z_INDEX } from "../../../atoms/StickyThead";
import { TooltipWrapper } from "../../../atoms/TooltipWrapper";
import { LoadingIndicator } from "../../../molecules/LoadingIndicator";
import { MainTransactionTableRow } from "./MainTransactionTableRow";
import { SelectedTransactionsActions } from "./SelectedTransactionsActions";
import { useSelectedTxContext } from "./SelectedTxContext";

export const mainTransactionTableStyleProps: Record<
  "NeedsReviewColumn" | "Row",
  StyleProps
> = {
  NeedsReviewColumn: {
    minW: "50px",
    h: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  Row: {
    justifyContent: "flex-start",
    alignItems: "center",
    // overflow: "hidden",
    w: "100%",
    borderColor: "white.500",
    minW: "fit-content",
  },
};

export const MainTransactionsTable = ({
  onClickOrder,
  filters,
}: {
  onClickOrder: (id: string) => void;
  filters: OrderFilterSetting;
}) => {
  const { isSelected, onSelect } = useSelectedTxContext();

  return (
    <>
      {/* -------------------- Table Header --------------------- */}
      <MainTransactionsTableHeader filters={filters} />
      {/* -------------------- Table Rows ----------------------- */}
      <MainTransactionTableRows
        onClickOrder={onClickOrder}
        onSelect={onSelect}
        isSelected={isSelected}
        filters={filters}
      />
    </>
  );
};

interface MainTransactionsTableHeaderProps {
  filters: OrderFilterSetting;
}

export const MainTransactionsTableHeader = ({
  filters,
}: MainTransactionsTableHeaderProps) => {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: accountAssets } = useQueryAccountAssetOptions(accountId);

  const { allSelected, setAllSelected, selectedCount } = useSelectedTxContext();

  const filteredAsset =
    filters.asset.length == 1
      ? accountAssets?.find((asset) => asset.value == filters.asset[0])
      : undefined;

  return (
    <StdVStack
      position="sticky"
      top={0}
      zIndex={HEADER_Z_INDEX} // high enough to render over table rows for sticky header
      bgColor="white"
      boxShadow="sm"
      px="20px"
      pt="2px"
      // overflowY="hidden"
    >
      <Flex {...mainTransactionTableStyleProps.Row} borderWidth="1px 0 1px 0">
        {/* ------------------- Checkbox ------------------- */}
        <TooltipWrapper tooltip="Select All">
          <CheckboxColumn my={2}>
            <Checkbox
              onChange={() => {
                setAllSelected((value) => !value);
              }}
              isChecked={allSelected}
              colorScheme="red"
            />
          </CheckboxColumn>
        </TooltipWrapper>
        {/* ------------ Table Header Row ---------------------------*/}
        <Flex w="100%">
          <Flex minW="100px" w="15%">
            <THeader>Type</THeader>
          </Flex>
          <Flex minW="180px" w="10%">
            <THeader>Date</THeader>
          </Flex>
          <Flex minW="300px" w="30%">
            <THeader>Out</THeader>
          </Flex>
          <Flex minW="300px" w="30%">
            <THeader>In</THeader>
          </Flex>
          {filters.asset.length == 1 && (
            <Flex minW="150px" w="15%" mr="50px">
              <THeader>
                Running {filters.dataSource.length == 1 ? "Location" : ""}{" "}
                Balance
                {filteredAsset ? ` (${filteredAsset.label})` : ""}
              </THeader>
            </Flex>
          )}
          <Flex minW="150px" w="15%" justifyContent="flex-end" mr="50px">
            <THeader>Outcome</THeader>
          </Flex>
          <Flex {...mainTransactionTableStyleProps.NeedsReviewColumn}>
            {/* Needs Review Indicator -- doesn't need a header */}
          </Flex>
        </Flex>
        {/* ------------ 3 dots menu -----------------*/}
        {/*<Flex w="50px" />*/}
      </Flex>
      {selectedCount && (
        <SelectedTransactionsActions pb={2} filterSetting={filters} />
      )}
    </StdVStack>
  );
};

export const MainTransactionTableRows = memo(
  ({
    onSelect,
    isSelected,
    onClickOrder,
    filters,
  }: {
    onSelect: (id: string) => void;
    isSelected: (id: string) => boolean;
    onClickOrder: (id: string) => void;
    filters: OrderFilterSetting;
  }) => {
    const groupList = useTransactionsStore((state) => state.groupList);

    const rows = useMemo(
      () =>
        groupList?.map((groupId) => (
          <MainTransactionTableRow
            key={groupId}
            groupId={groupId}
            onSelect={onSelect}
            isSelected={isSelected(groupId)}
            onClickOrder={onClickOrder}
            filters={filters}
          />
        )),
      [filters, groupList, isSelected, onClickOrder, onSelect]
    );

    if (!groupList) {
      return <LoadingIndicator content="Loading Transactions" />;
    }
    if (groupList && groupList.length === 0) {
      return (
        <Flex w="100%" justifyContent="center" h="85%">
          <EmptyDataMessage content="Add a data source to make the most of your Syla account" />
        </Flex>
      );
    }

    return <>{rows}</>;
  }
);

const THeader = styled(Text)`
  color: #666;
  font-size: 0.75rem;
  font-weight: 500;
  align-items: center;
`;

export const CheckboxColumn = ({
  children,
  ...props
}: { children?: React.ReactNode } & FlexProps) => (
  <Flex
    w="50px"
    h="100%"
    bgColor="transparent"
    justifyContent="center"
    {...props}
  >
    {children}
  </Flex>
);
