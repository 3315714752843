import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { GetSubscriptionsResponse } from "@syla/shared/types/responses/GetSubscriptionsResponse";
import axios from "axios";
import { parseSubscription } from "./parseSubscription";

export const getSubscriptions = async (
  accountId: string
): Promise<GetSubscriptionsResponse<string>> => {
  const response: TransportType<GetSubscriptionsResponse<string>> = (
    await axios.get(`/accounts/${accountId}/subscriptions`)
  ).data;

  return {
    subscriptions: response.subscriptions.map(parseSubscription),
  };
};
