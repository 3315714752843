import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

import {
  SignInLHSContainer,
  SignInParentContainer,
  SignInRHSContainer,
} from "../components/atoms/Containers";
import { InstructionBox } from "../components/molecules/overlayBoxes/InstructionBox";
import { AddDataSourceForm as DataSourceForm } from "../components/organisms/dataSource/addFirstDataSourceForm/AddDataSourceForm";
import { AddFirstDataSource } from "../components/organisms/dataSource/addFirstDataSourceForm/addFirstDataSource/AddFirstDataSource";
import { ImportContextProvider } from "../components/organisms/dataSource/ImportContext";
import { DataSourceInputType } from "../helper/dataSource/DataSourceInputType";
import { getDataSourceInstruction } from "../helper/dataSource/getDataSourceInstructions";
import { DataSource, DataSourceType } from "../types/dataSource/dataSource";

export const FirstDataSourceCustom = () => {
  // get the data source from the url param
  const { customSourceName } = useParams<{ customSourceName?: string }>();

  const customDataSource: DataSource = useMemo(
    () => ({
      name: customSourceName ?? "",
      type: DataSourceType.Custom,
    }),
    [customSourceName]
  );

  const instructions =
    getDataSourceInstruction({
      dataSource: customDataSource,
      inputType: DataSourceInputType.Custom,
      importStatus: undefined,
    }) ?? "";

  return (
    <SignInParentContainer>
      <ImportContextProvider dataSource={customDataSource}>
        <SignInLHSContainer>
          <InstructionBox instructions={instructions} />
        </SignInLHSContainer>
        <SignInRHSContainer>
          <DataSourceForm>
            <AddFirstDataSource
              source={customDataSource}
              instructions={instructions}
            />
          </DataSourceForm>
        </SignInRHSContainer>
      </ImportContextProvider>
    </SignInParentContainer>
  );
};
