import { Image } from "@chakra-ui/react";
import React from "react";

import {
  iconExtractor,
  IconExtractorType,
} from "../../../helper/iconExtractor";
import { ModalContainer } from "../../atoms/Containers";
import { ModalHeading } from "../../atoms/Headings";
import { ModalText } from "../../atoms/Texts";

export const AlertBox = ({
  icon,
  heading,
  contents,
}: {
  icon: IconExtractorType;
  heading: string;
  contents?: string[];
}): JSX.Element => {
  return (
    <ModalContainer>
      <Image src={iconExtractor(icon)} alt={icon} w="60px" />
      <ModalHeading mt="10px">{heading}</ModalHeading>
      {contents &&
        contents.map((content) => (
          <ModalText key={content} mb="15px">
            {content}
          </ModalText>
        ))}
    </ModalContainer>
  );
};
