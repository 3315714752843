import { Button } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useIntercom } from "react-use-intercom";
import { useUserContext } from "../../contexts/UserContext";
import { getRandomId } from "../../helper/GetRandomId";

export function ContactSupportLink({
  supportPrompt,
}: {
  supportPrompt: string;
}) {
  const { userDetails } = useUserContext();
  const { showNewMessages, update } = useIntercom();

  const contactSupport = useCallback(() => {
    // set email to avoid the "which email should we contact you on?" prompt.
    update({
      userId: userDetails?.id ?? getRandomId(),
      email: userDetails?.email,
    });
    showNewMessages(supportPrompt);
  }, [
    supportPrompt,
    showNewMessages,
    update,
    userDetails?.email,
    userDetails?.id,
  ]);

  return (
    <Button
      fontSize="revert"
      color="red.500"
      disabled={false}
      onClick={contactSupport}
      variant="link"
    >
      Request Support
    </Button>
  );
}
