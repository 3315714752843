import { Alert, AlertIcon } from "@chakra-ui/react";
import { HTMLChakraProps } from "@chakra-ui/system";
import React from "react";

export const TaxReportsWarning = ({ ...props }: HTMLChakraProps<"div">) => (
  <Alert status="warning" {...props}>
    <AlertIcon />
    Please wait for your tax calculations to complete to get the correct tax
    outcomes.
  </Alert>
);
