import { Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { ModalContainer } from "../../atoms/Containers";
import { ModalFormHeading } from "../../atoms/Headings";

export const ComponentBox = ({
  heading,
  contents,
  component,
}: {
  heading: string;
  contents?: string[];
  component: ReactNode;
  hideBottomButton?: boolean;
}) => {
  return (
    <ModalContainer>
      <ModalFormHeading>{heading}</ModalFormHeading>
      {contents &&
        contents.map((content) => (
          <Text
            key={content}
            color="black.550"
            fontSize="0.875rem"
            textAlign="center"
            mb="20px"
          >
            {content}
          </Text>
        ))}
      {component}
    </ModalContainer>
  );
};
