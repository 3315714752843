import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import FIFO from "../images/icons/FIFO.svg";
import HIFO from "../images/icons/HIFO.svg";
import LIFO from "../images/icons/LIFO.svg";
import LTFO from "../images/icons/LTFO.svg";
import Group from "../images/icons/Transactions/Group.svg";
import Receive from "../images/icons/Transactions/Receive.svg";
import Send from "../images/icons/Transactions/Send.svg";
import Rebase from "../images/icons/Transactions/Rebase.svg";
import Trade from "../images/icons/Transactions/Trade.svg";
import ignoreGreen from "../images/icons/Transactions/transactionIncrease/ignoreGreen.svg";
// import Transfer from "../images/icons/Transactions/Transfer.svg";
import Withdraw from "../images/icons/Transactions/Withdraw.svg";
import AUD from "../images/thumbnails/AUD.svg";
import BNB from "../images/thumbnails/Binance.svg";
import BTC from "../images/thumbnails/BTC.svg";
import ETH from "../images/thumbnails/ETH.svg";
import unknownIcon from "../images/thumbnails/unknown.svg";
import USD from "../images/thumbnails/USD.svg";
import USDT from "../images/thumbnails/USDT.svg";
import { getOptionForThumbnailExtractor } from "./transaction/getLedgerType";

export const thumbnailExtractor = (code?: string) => {
  if (!code) return { name: "?", code: "?", icon: undefined };

  // ------------------ Transaction Ledger Type ------------------
  if (Object.keys(LedgerType).includes(code.replace(/\s+/g, ""))) {
    return getOptionForThumbnailExtractor(code as LedgerType);
  }

  switch (code.toLowerCase().replace(/\s+/g, "")) {
    // -------------------- Crypto Token Type --------------------
    case "eth":
    case "ethereum":
      return {
        code: "ETH",
        name: "Ethereum",
        icon: ETH,
        minDecimal: 0,
        maxDecimal: 18,
      };
    case "btc":
    case "bitcoin":
      return {
        code: "BTC",
        name: "Bitcoin",
        icon: BTC,
        minDecimal: 0,
        maxDecimal: 18,
      };
    case "aud":
    case "australiandollar":
    case "audollar":
      return {
        code: "AUD",
        name: "Australian Dollar",
        icon: AUD,
        minDecimal: 2,
        maxDecimal: 2,
      };
    case "bnb":
    case "binancetoken":
    case "binancecoin":
      return {
        code: "BNB",
        name: "Binance Coin",
        icon: BNB,
        minDecimal: 0,
        maxDecimal: 18,
      };
    case "usd":
    case "usdollar":
      return {
        code: "USD",
        name: "US Dollar",
        icon: USD,
        minDecimal: 2,
        maxDecimal: 2,
      };
    case "usdt":
      return {
        code: "USDT",
        name: "USD Token",
        icon: USDT,
        minDecimal: 0,
        maxDecimal: 18,
      };
    // -------------------- Wallet Type --------------------
    case "independentreserve":
      return {
        code: "Independent Reserve",
        name: "Independent Reserve",
        description: "Exchange",
      };
    case "binance":
      return {
        code: "Binance",
        name: "Binance",
        icon: BNB,
        description: "Exchange",
      };
    case "btcmarkets":
    case "btcmarket":
      return {
        code: "BTC Markets",
        name: "BTC Markets",
        description: "Exchange",
      };
    case "coinjar":
      return {
        code: "CoinJar",
        name: "CoinJar",
        description: "Exchange",
      };
    case "swyftx":
      return {
        code: "Swyftx",
        name: "Swyftx",
        description: "Exchange",
      };
    case "digitalsurge":
      return {
        code: "Digitial Surge",
        name: "Digital Surge",
        icon: unknownIcon,
        description: "Exchange",
      };
    case "ftx":
      return {
        code: "FTX",
        name: "FTX",
        description: "Exchange",
      };
    // case "blockchaincom":
    //   return { code: "N/A", name: "Blockchain.com", icon: BlockchainCom, description: "" };
    // case "opensea":
    //   return { code: "N/A", name: "Open Sea", icon: OpenSea, description: "" };
    // case "coinbase":
    //   return { code: "N/A", name: "Coinbase", icon: Coinbase, description: "" };
    case "coinspot":
    case "CoinSpot":
      return {
        code: "N/A",
        name: "Coinspot",
        description: "Exchange",
      };
    // -------------------- Transaction Type --------------------
    case "group":
      return { code: "Group", name: "Group", icon: Group, description: "" };
    case "receive":
      return {
        code: "Receive",
        name: "Receive",
        icon: Receive,
        description: "Receive an asset",
      };
    case "send":
      return {
        code: "Send",
        name: "Send",
        icon: Send,
        description: "Send assets to another wallet",
      };
    case "rebase":
      return {
        code: "Rebase",
        name: "Rebase",
        icon: Rebase,
        description: "Change the ticker name or quantity of an asset",
      };
    case "trade":
      return {
        code: "Trade",
        name: "Trade",
        icon: Trade,
        description: "Exchange any asset for any another asset",
      };
    // case "transfer":
    //   return {
    //     code: "Transfer",
    //     name: "Transfer",
    //     icon: Transfer,
    //     description: "",
    //   };
    case "withdraw":
      return {
        code: "Withdraw",
        name: "Withdraw",
        icon: Withdraw,
        description: "",
      };

    // -------------------- Parcel method --------------------
    case "ltfo":
    case "lowesttaxfirstout(ltfo)":
      return {
        name: "Lowest Tax First Out (LTFO)",
        code: "LTFO",
        icon: LTFO,
        description:
          "will intelligently select the parcel that will produce the lowest tax outcome to be disposed.",
        exclusive: true,
      };
    case "fifo":
    case "firstinfirstout(fifo)":
      return {
        name: "First In First Out (FIFO)",
        code: "FIFO",
        icon: FIFO,
        description:
          "The oldest asset acquired is the first asset that is disposed.",
      };
    case "lifo":
    case "lastinfirstout(lifo)":
      return {
        name: "Last In First Out (LIFO)",
        code: "LIFO",
        icon: LIFO,
        description:
          "The most recent asset acquired will be the first to disposed.",
      };
    case "hifo":
    case "hightestinfirstout(hifo)":
      return {
        name: "Highest In First Out (HIFO)",
        code: "HIFO",
        icon: HIFO,
        description:
          "The asset with the highest cost basis will be the first to be disposed.",
      };
    // -------------------- Other options --------------------
    case "dateascending":
      return { code: "Date Ascending", name: "Date (Ascending)" };
    case "datedescending":
      return { code: "Date Descending", name: "Date (Descending)" };
    case "gainsascending":
      return { code: "Gains Ascending", name: "Gains (Ascending)" };
    case "gainsdescending":
      return { code: "Gains Descending", name: "Gains (Descending)" };

    case "ignoregreen":
      return { code: "ignore", name: "Ignore", icon: ignoreGreen };

    default:
      return { code: "?", name: "?", icon: unknownIcon };
  }
};
