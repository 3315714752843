import { WalletDataSourceOptional } from "../responses/TransactionEventResponseItem";
import { parseDate, parseOptional } from "./parsing";
import { TransportType } from "./TransportType";
import { WithModelIds } from "./WithModelIds";

export function parseWalletResponse(
  wallet: TransportType<WithModelIds<WalletDataSourceOptional<string>>>
): WithModelIds<WalletDataSourceOptional<string>> {
  return {
    ...wallet,
    createdAt: parseOptional(wallet.createdAt, parseDate),
    fromDate: parseOptional(wallet.fromDate, parseDate),
    lastSynced: parseOptional(wallet.lastSynced, parseDate),
    lastUploaded: parseOptional(wallet.lastUploaded, parseDate),
    lastFileImportStarted: parseOptional(
      wallet.lastFileImportStarted,
      parseDate
    ),
    updatedAt: parseOptional(wallet.updatedAt, parseDate),
  };
}
