import {
  useToast,
  Spinner,
  VStack,
  Text,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";
import { getAccountShortName } from "@syla/shared/types/models/AccountAccessBase";
import React, { useState, useContext } from "react";
import { deleteUser } from "../../api/user/deleteUser";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { ModalContent } from "../../components/atoms/ModalContent";
import { errorToastOptions } from "../../components/molecules/errorToastOptions";
import { ModalContext } from "../../contexts/ModalContext";
import { useUserContext } from "../../contexts/UserContext";
import { useQueryGetAccounts } from "../../store/useQueryGetAccounts";

export function DeleteUserDialog() {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext);
  const { logout } = useUserContext();

  const toast = useToast();

  const { data } = useQueryGetAccounts();
  if (!data) return <Spinner />;

  // where I am the only owner
  const myAccounts = Object.values(data.accounts).filter(
    (account) => account.onlyOwner
  );

  return (
    <VStack>
      <ModalContent spacing={6}>
        <Text>
          This will delete your user profile, as well as any transaction data,
          tax history, and purchases for your accounts. This action is
          irreversible. The following accounts will be deleted:
        </Text>
        <Table>
          <Thead>
            <Tr>
              <Th>Account</Th>
            </Tr>
          </Thead>
          <Tbody>
            {myAccounts.map((account) => (
              <Tr key={account._id}>
                <Td>{getAccountShortName(account)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </ModalContent>
      <VStack w="100%">
        <ButtonVariant
          content="Delete User"
          color="red"
          disabled={deleteInProgress}
          isLoading={deleteInProgress}
          onClick={async () => {
            setDeleteInProgress(true);
            try {
              await deleteUser();
              openModal({
                type: "action",
                icon: "delete",
                heading: "User Deleted",
                btn1Content: "Done",
                btn1Action: () => logout(),
                hideCloseButton: true,
                dontCloseOnOverlayClick: true,
              });
            } catch (error) {
              toast({
                ...errorToastOptions,
                position: "top",
                title: "Unexpected problem",
                description:
                  "Please try again later or contact support if problems persist",
              });
            } finally {
              setDeleteInProgress(false);
            }
          }}
          spam="spam"
        />
        <ButtonVariant
          content="Cancel"
          spam="spam"
          my="10px"
          onClick={closeModal}
          isLoading={deleteInProgress}
        />
      </VStack>
    </VStack>
  );
}
