import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { JsonEditor } from "json-edit-react";
import React, { useState } from "react";
import { JsonToTable } from "react-json-to-table";
import { StdVStack } from "../../../../atoms/Containers";
import { ModalContent } from "../../../../atoms/ModalContent";
import { SearchBar } from "../../../../molecules/forms/FormComponents";

export function SourceDataViewer(props: { sourceData: any[] }) {
  return (
    <StdVStack alignItems="flex-start">
      <Tabs isLazy width="100%">
        <TabList>
          <Tab>Table</Tab>
          <Tab>Object</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <SourceDataTable sourceData={props.sourceData} />
          </TabPanel>
          <TabPanel>
            <SourceDataObject sourceData={props.sourceData} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </StdVStack>
  );
}

function SourceDataTable({ sourceData }: { sourceData: any[] }) {
  return (
    <Flex
      p="10px"
      mb="20px"
      maxW="100%"
      // apply bold to the first row
      css={`
        .json-to-table tr:first-child td {
          font-weight: bold;
        }
        .json-to-table td {
          vertical-align: top;
        }
      `}
    >
      <div style={{ overflowY: "auto", maxHeight: "60vh", width: "100%" }}>
        <JsonToTable json={sourceData} />
      </div>
    </Flex>
  );
}

function SourceDataObject({ sourceData }: { sourceData: any[] }) {
  const [searchText, setSearchText] = useState<string>("");
  return (
    <ModalContent align="flex-start">
      <SearchBar
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
      <JsonEditor
        restrictAdd
        restrictDelete
        restrictEdit
        restrictDrag
        showArrayIndices={false}
        // collapse={1}
        searchText={searchText}
        searchFilter="all"
        rootName="Source Data"
        maxWidth="100%"
        data={sourceData}
      />
    </ModalContent>
  );
}
