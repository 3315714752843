import { parseTransactionResponseItem } from "@syla/shared/types/helpers/parseTransactionResponseItem";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { UpdateTransactionRequest } from "@syla/shared/types/requests/TransactionRequests";
import axios from "axios";

import { Transaction } from "../../types/transaction";

export interface UpdateTransaction {
  accountId: string;
  id: string;
  updates: UpdateTransactionRequest;
}

export const updateTransaction = async ({
  accountId,
  id,
  updates,
}: UpdateTransaction): Promise<Transaction> => {
  const { data: updatedTransaction }: { data: TransportType<Transaction> } =
    await axios.patch(`/accounts/${accountId}/transactions/${id}`, updates);
  return parseTransactionResponseItem(updatedTransaction);
};
