import { Box, BoxProps } from "@chakra-ui/react";
import React from "react";
import { css, Global } from "@emotion/react";
import generatePicker, {
  PickerDateProps,
} from "antd/es/date-picker/generatePicker";
import dateFnsGenerateConfig from "rc-picker/lib/generate/dateFns";
import "antd/es/date-picker/style/index";

import { SYLA_EARLIEST_EVENT_DATE } from "@syla/shared/types/helpers/constants";

// This is Ant Design date-picker component but uses Date-fns config instead of moment js config
export const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const rangePickerStyles = css`
  // date picker button focused
  .ant-picker-focused {
    border-color: #000;
    box-shadow: none;
  }

  // date picker button hover
  .ant-picker:hover {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
  }

  // today date
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid #ff3600;
  }

  // active bar
  .ant-picker-range .ant-picker-active-bar {
    background: #ff3600;
  }

  // selected date
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #ff3600;
  }

  // select date in range style
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: #ffd7cc;
  }

  // select date in range hover style
  .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-hover {
    border: 1px dotted #ff3600;
    background: #ff3600;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start
    .ant-picker-cell-in-view.ant-picker-cell-in-range:hover {
    background: #ff3600;
  }

  // submit button
  .ant-btn-primary {
    background: #ff3600;
    border: none;
  }
  .ant-btn-primary:hover {
    background: #df3030;
  }
`;

export const StyledDatePicker = ({
  value,
  onChange,
  left = null,
  disableNowAndOnward = true,
  earliestSupportedDate = SYLA_EARLIEST_EVENT_DATE,
  showTime = true,
  containerProps,
  ...pickerProps
}: {
  value: Date | undefined | null;
  onChange: (date: Date | null) => void;
  left?: number | null;
  disableNowAndOnward?: boolean;
  earliestSupportedDate?: Date;
  showTime?: boolean;
  containerProps?: BoxProps;
} & Omit<PickerDateProps<Date>, "showTime">): JSX.Element => {
  return (
    // required div for Ant Design date-picker
    <Box id="datePickerDiv" {...containerProps}>
      <Global
        styles={css`
          .ant-picker-dropdown {
            ${left != null ? `left: ${left}px !important;` : ""}
          }
          // date picker button focused
          .ant-picker-focused {
            border-color: #000;
            box-shadow: none;
          }

          // date picker button hover
          .ant-picker:hover {
            border-color: #f5f5f5;
            background-color: #f5f5f5;
          }

          // today date
          .ant-picker-cell-in-view.ant-picker-cell-today
            .ant-picker-cell-inner::before {
            border: 1px solid #ff3600;
          }

          // make AM/PM column not scrollable
          .ant-picker-time-panel-column:nth-child(3) {
            overflow-y: visible;
          }

          // selected date cell and time cell
          .ant-picker-cell-in-view.ant-picker-cell-selected
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-start
            .ant-picker-cell-inner,
          .ant-picker-cell-in-view.ant-picker-cell-range-end
            .ant-picker-cell-inner,
          .ant-picker-time-panel-column
            > li.ant-picker-time-panel-cell-selected
            .ant-picker-time-panel-cell-inner {
            background: #ff5e33;
            color: #fff;
          }

          // submit button
          .ant-btn-primary {
            background: #ff3600;
            border: none;
          }
          .ant-btn-primary:hover {
            background: #df3030;
          }

          // input
          .ant-picker-input > input {
            color: #4d4d4d !important;
            ::placeholder {
              color: #a1aebf !important;
            }
            cursor: pointer;
          }
        `}
      />
      <DatePicker
        showTime={showTime}
        value={value}
        onChange={(date) => {
          date?.setMilliseconds(0);
          date?.setSeconds(0);
          if (!showTime) {
            date?.setMinutes(0);
            date?.setHours(0);
          }
          onChange(date);
        }}
        format={showTime ? "dd MMM yyyy HH':'mm aaa" : "dd MMM yyyy"}
        disabledDate={(current) =>
          (disableNowAndOnward && current > new Date()) ||
          current < earliestSupportedDate
        }
        getPopupContainer={() =>
          document.getElementById("datePickerDiv") as HTMLElement
        }
        inputReadOnly
        style={{
          width: "100%",
          padding: "8px 15px 8px",
          borderRadius: "5px",
          borderColor: "#E2E8F0",
          cursor: "pointer",
        }}
        {...pickerProps}
      />
    </Box>
  );
};
