/* eslint-disable import/no-duplicates */
import { camelCase } from "lodash";
import {
  LedgerType,
  isFeeLedger,
  isReceiveType,
  validLedgerTypesByTransactionType,
} from "@syla/shared/types/models/LedgerBase";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";

import acquisition from "../../images/icons/Transactions/transactionIncrease/acquisition.svg";
import acquisitionMarketValue from "../../images/icons/Transactions/transactionIncrease/acquisitionMarketValue.svg";
import acquisitionZeroCost from "../../images/icons/Transactions/transactionIncrease/acquisitionZeroCost.svg";
import airdrop from "../../images/icons/Transactions/transactionIncrease/airdrop.svg";
import brokerageFee from "../../images/icons/Transactions/transactionDecrease/brokerageFee.svg";
import borrow from "../../images/icons/Transactions/transactionIncrease/borrow.svg";
import borrowFee from "../../images/icons/Transactions/transactionDecrease/borrowFee.svg";
import borrowInterestPaid from "../../images/icons/Transactions/transactionDecrease/borrowInterestPaid.svg";
import borrowRepaid from "../../images/icons/Transactions/transactionDecrease/borrowRepaid.svg";
import capitalInvested from "../../images/icons/Transactions/transactionIncrease/capitalInvested.svg";
import capitalReturned from "../../images/icons/Transactions/transactionDecrease/capitalReturned.svg";
import cashback from "../../images/icons/Transactions/transactionIncrease/cashback.svg";
import chainsplit from "../../images/icons/Transactions/transactionIncrease/chainsplit.svg";
import commission from "../../images/icons/Transactions/transactionIncrease/commission.svg";
import deposit from "../../images/icons/Transactions/transactionIncrease/deposit.svg";
import depositFee from "../../images/icons/Transactions/transactionDecrease/depositFee.svg";
import derivativesFee from "../../images/icons/Transactions/transactionDecrease/derivativesFee.svg";
import derivativesLoss from "../../images/icons/Transactions/transactionDecrease/derivativesLoss.svg";
import derivativesProfit from "../../images/icons/Transactions/transactionIncrease/derivativesProfit.svg";
import disposal from "../../images/icons/Transactions/transactionDecrease/disposal.svg";
import disposalMarketValue from "../../images/icons/Transactions/transactionDecrease/disposalMarketValue.svg";
import disposalZeroValue from "../../images/icons/Transactions/transactionDecrease/disposalZeroValue.svg";
import distribution from "../../images/icons/Transactions/transactionIncrease/distribution.svg";
import dust from "../../images/icons/Transactions/transactionIncrease/dust.svg";
import feeRefund from "../../images/icons/Transactions/transactionIncrease/feeRefund.svg";
import futuresFee from "../../images/icons/Transactions/transactionDecrease/futuresFee.svg";
import futuresFundingRateLoss from "../../images/icons/Transactions/transactionDecrease/futuresFundingRateLoss.svg";
import futuresFundingRateProfit from "../../images/icons/Transactions/transactionIncrease/futuresFundingRateProfit.svg";
import futuresLoss from "../../images/icons/Transactions/transactionDecrease/futuresLoss.svg";
import futuresProfit from "../../images/icons/Transactions/transactionIncrease/futuresProfit.svg";
import gamblingLoss from "../../images/icons/Transactions/transactionDecrease/gamblingLoss.svg";
import gamblingProfit from "../../images/icons/Transactions/transactionIncrease/gamblingProfit.svg";
import giftReceived from "../../images/icons/Transactions/transactionIncrease/giftReceived.svg";
import giftSent from "../../images/icons/Transactions/transactionDecrease/giftSent.svg";
import lend from "../../images/icons/Transactions/transactionDecrease/lend.svg";
import lendFee from "../../images/icons/Transactions/transactionDecrease/lendFee.svg";
import lendingInterest from "../../images/icons/Transactions/transactionIncrease/lendingInterest.svg";
import lendRepaid from "../../images/icons/Transactions/transactionIncrease/lendRepaid.svg";
import liquidationFee from "../../images/icons/Transactions/transactionDecrease/liquidationFee.svg";
import interest from "../../images/icons/Transactions/transactionIncrease/interest.svg";
import miningIncome from "../../images/icons/Transactions/transactionIncrease/miningIncome.svg";
import networkFee from "../../images/icons/Transactions/transactionDecrease/networkFee.svg";
import otherFee from "../../images/icons/Transactions/transactionDecrease/otherFee.svg";
import otherLoss from "../../images/icons/Transactions/transactionDecrease/otherLoss.svg";
import paymentFee from "../../images/icons/Transactions/transactionDecrease/paymentfee.svg";
import paymentSent from "../../images/icons/Transactions/transactionDecrease/paymentSent.svg";
import paymentReceived from "../../images/icons/Transactions/transactionIncrease/paymentReceived.svg";
import paymentReverted from "../../images/icons/Transactions/transactionDecrease/paymentReverted.svg";
import paymentReversal from "../../images/icons/Transactions/transactionIncrease/paymentReversal.svg";
import promotions from "../../images/icons/Transactions/transactionIncrease/promotions.svg";
import serviceFee from "../../images/icons/Transactions/transactionDecrease/serviceFee.svg";
import slashingPenalty from "../../images/icons/Transactions/transactionDecrease/slashingPenalty.svg";
import stakingIncome from "../../images/icons/Transactions/transactionIncrease/stakingIncome.svg";
import statementFee from "../../images/icons/Transactions/transactionDecrease/statementFee.svg";
import tradeRebate from "../../images/icons/Transactions/transactionIncrease/tradeRebate.svg";
import transferIn from "../../images/icons/Transactions/transactionIncrease/transferIn.svg";
import transferOut from "../../images/icons/Transactions/transactionDecrease/transferOut.svg";
import rebaseIn from "../../images/icons/Transactions/transactionIncrease/rebaseIn.svg";
import rebaseOut from "../../images/icons/Transactions/transactionDecrease/rebaseOut.svg";
import reward from "../../images/icons/Transactions/transactionIncrease/reward.svg";
import unrecoverable from "../../images/icons/Transactions/transactionDecrease/unrecoverable.svg";
import withdrawal from "../../images/icons/Transactions/transactionDecrease/withdrawal.svg";
import withdrawalFee from "../../images/icons/Transactions/transactionDecrease/withdrawalFee.svg";
import mint from "../../images/icons/Transactions/transactionIncrease/mint.svg";
import mintCost from "../../images/icons/Transactions/transactionDecrease/mintCost.svg";
import AddLiquidity from "../../images/icons/Transactions/Add Liquidity.svg";
import RemoveLiquidity from "../../images/icons/Transactions/Remove Liquidity.svg";
import TakeLPToken from "../../images/icons/Transactions/Take LP Token.svg";
import ReturnLPToken from "../../images/icons/Transactions/Return LP Token.svg";
import WrapIn from "../../images/icons/Transactions/Wrap In.svg";
import WrapOut from "../../images/icons/Transactions/Wrap Out.svg";
import { LedgerTypeOptions } from "./LedgerTypeOptions";

export const getLedgerTypeIcon = (type: LedgerType): string => {
  switch (type) {
    case LedgerType.TakeLPToken:
      return TakeLPToken;
    case LedgerType.ReturnLPToken:
      return ReturnLPToken;
    case LedgerType.RemoveLiquidity:
      return RemoveLiquidity;
    case LedgerType.AddLiquidity:
      return AddLiquidity;
    case LedgerType.WrapIn:
      return WrapIn;
    case LedgerType.WrapOut:
      return WrapOut;
    case LedgerType.Mint:
      return mint;
    case LedgerType.MintCost:
      return mintCost;
    case LedgerType.Acquisition:
      return acquisition;
    case LedgerType.AcquisitionMarketValue:
      return acquisitionMarketValue;
    case LedgerType.AcquisitionZeroCost:
      return acquisitionZeroCost;
    case LedgerType.Airdrop:
      return airdrop;
    case LedgerType.Borrow:
      return borrow;
    case LedgerType.BorrowFee:
      return borrowFee;
    case LedgerType.BorrowRepaid:
      return borrowRepaid;
    case LedgerType.BorrowInterestPaid:
      return borrowInterestPaid;
    case LedgerType.BrokerageFee:
      return brokerageFee;
    case LedgerType.CapitalInvested:
      return capitalInvested;
    case LedgerType.CapitalReturned:
      return capitalReturned;
    case LedgerType.Cashback:
      return cashback;
    case LedgerType.Chainsplit:
      return chainsplit;
    case LedgerType.Commission:
      return commission;
    case LedgerType.Deposit:
      return deposit;
    case LedgerType.DepositFee:
      return depositFee;
    case LedgerType.DerivativesFee:
      return derivativesFee;
    case LedgerType.DerivativesLoss:
      return derivativesLoss;
    case LedgerType.DerivativesProfit:
      return derivativesProfit;
    case LedgerType.Disposal:
      return disposal;
    case LedgerType.DisposalMarketValue:
      return disposalMarketValue;
    case LedgerType.DisposalZeroValue:
      return disposalZeroValue;
    case LedgerType.Distribution:
      return distribution;
    case LedgerType.Dust:
      return dust;
    case LedgerType.FeeRefund:
      return feeRefund;
    case LedgerType.FuturesFee:
      return futuresFee;
    case LedgerType.FuturesFundingRateLoss:
      return futuresFundingRateLoss;
    case LedgerType.FuturesFundingRateProfit:
      return futuresFundingRateProfit;
    case LedgerType.FuturesLoss:
      return futuresLoss;
    case LedgerType.FuturesProfit:
      return futuresProfit;
    case LedgerType.GamblingLoss:
      return gamblingLoss;
    case LedgerType.GamblingProfit:
      return gamblingProfit;
    case LedgerType.GiftReceived:
      return giftReceived;
    case LedgerType.GiftSent:
      return giftSent;
    case LedgerType.Lend:
      return lend;
    case LedgerType.LendFee:
      return lendFee;
    case LedgerType.LendingInterest:
      return lendingInterest;
    case LedgerType.LendRepaid:
      return lendRepaid;
    case LedgerType.LiquidationFee:
      return liquidationFee;
    case LedgerType.Interest:
      return interest;
    case LedgerType.MiningIncome:
      return miningIncome;
    case LedgerType.NetworkFee:
      return networkFee;
    case LedgerType.OtherFee:
      return otherFee;
    case LedgerType.OtherLoss:
      return otherLoss;
    case LedgerType.PaymentFee:
      return paymentFee;
    case LedgerType.PaymentSent:
      return paymentSent;
    case LedgerType.PaymentReceived:
      return paymentReceived;
    case LedgerType.PaymentReversal:
      return paymentReversal;
    case LedgerType.PaymentReverted:
      return paymentReverted;
    case LedgerType.Promotions:
      return promotions;
    case LedgerType.RebaseIn:
      return rebaseIn;
    case LedgerType.RebaseOut:
      return rebaseOut;
    case LedgerType.Reward:
      return reward;
    case LedgerType.ServiceFee:
      return serviceFee;
    case LedgerType.SlashingPenalty:
      return slashingPenalty;
    case LedgerType.StakingIncome:
      return stakingIncome;
    case LedgerType.StatementFee:
      return statementFee;
    case LedgerType.TradeRebate:
      return tradeRebate;
    case LedgerType.TransferIn:
      return transferIn;
    case LedgerType.TransferOut:
      return transferOut;
    case LedgerType.Unrecoverable:
      return unrecoverable;
    case LedgerType.Withdrawal:
      return withdrawal;
    case LedgerType.WithdrawalFee:
      return withdrawalFee;
  }
};

export const getFeeLedgerTypeOptionsByTransactionType = (
  transactionType: TransactionType
): LedgerTypeOptions => {
  return validLedgerTypesByTransactionType[transactionType]
    .filter((type) => isFeeLedger({ type }))
    .map(getOptionForLedgerType);
};

export const getInLedgerTypeOptionsByTransactionType = (
  transactionType: TransactionType
): LedgerTypeOptions => {
  return validLedgerTypesByTransactionType[transactionType]
    .filter((type) => isReceiveType({ type }) && !isFeeLedger({ type }))
    .map(getOptionForLedgerType);
};

export const getOutLedgerTypeOptionsByTransactionType = (
  transactionType: TransactionType
): LedgerTypeOptions => {
  return validLedgerTypesByTransactionType[transactionType]
    .filter((type) => !isReceiveType({ type }) && !isFeeLedger({ type }))
    .map(getOptionForLedgerType);
};

export const getOptionForThumbnailExtractor = (type: LedgerType) => ({
  code: camelCase(type),
  name: type,
  icon: getLedgerTypeIcon(type),
});

export const getOptionForLedgerType = (type: LedgerType) => ({
  value: type,
  label: type,
  icon: getLedgerTypeIcon(type),
});

export const allLedgerTypeOptions: LedgerTypeOptions = Object.values(
  LedgerType
).map(getOptionForLedgerType);
