/* eslint-disable react/prop-types */
import * as Sentry from "@sentry/browser";
import {
  DataSourceType,
  ImportStatus,
  getDataSourceSupportedInputs,
} from "@syla/shared/types/models/DataSourceBase";
import { AddWalletType } from "@syla/shared/types/requests/AddWalletRequest";
import axios from "axios";
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { updateWallet } from "../../../api/wallet/updateWallet";
import { captureRequestError } from "../../../helper/captureRequestError";
import { DataSourceInputType } from "../../../helper/dataSource/DataSourceInputType";
import { syncWallet } from "../../../store/actions/syncWallet";
import {
  deleteWallet,
  addWallet,
  importWalletCsv,
  importDataSources,
} from "../../../store/actions/wallet";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import {
  DataSource,
  AddDataSourceForm,
} from "../../../types/dataSource/dataSource";

export type CreateEmptyWalletProps = { customName?: string };

export type ImportState =
  | "initialising"
  | "importing"
  | "backgroundImporting"
  | "complete"
  | undefined;

export type ImportContextProps = {
  state: {
    dataSource: DataSource | undefined;
    authorised: boolean | undefined;
    authInProgress: boolean | undefined;
    newWalletId?: string;
    importError: Error | undefined;
    importState: ImportState;
    importComplete: boolean;
    importInProgress: boolean;
    importType: DataSourceInputType;
    importStatus: ImportStatus | undefined;
    importReady: boolean;
    network?: string;
  };
  actions: {
    setDataSource: (value: DataSource | undefined) => void;
    setAuthInProgress: (value: boolean | undefined) => void;
    setAuthorised: (value: boolean | undefined) => void;
    setNewWalletId: (walletId: string) => void;
    setImportError: (error: Error | undefined) => void;
    setImportState: (state: ImportState) => void;
    setImportType: (type: DataSourceInputType) => void;
    beginImport: (props: AddDataSourceForm) => void;
    createEmptyWallet: (props: CreateEmptyWalletProps) => void;
  };
};

const ImportContext = createContext<ImportContextProps>(undefined as any);

export const useImportContext = () => useContext(ImportContext);

export const ImportContextProvider = ({
  children,
  ...defaults
}: Partial<Pick<ImportContextProps["state"], "dataSource">> & {
  children: React.ReactNode;
}) => {
  useEffect(() => {
    console.debug("ImportContext render", defaults);
  });

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const [dataSource, setDataSource] = useState<DataSource | undefined>(
    defaults?.dataSource
  );
  const [authorised, setAuthorised] = useState<boolean>();
  const [authInProgress, setAuthInProgress] = useState<boolean>();
  const [importError, setImportError] = useState<Error>();

  const [importState, setImportState] = useState<ImportState>(undefined);

  const [newWalletId, setNewWalletId] = useState<string>();

  const resetNewWallet = useCallback(() => {
    if (
      newWalletId &&
      importState != "complete" &&
      importState != "backgroundImporting"
    ) {
      deleteWallet(accountId, newWalletId).catch((error) =>
        captureRequestError(error)
      );
      setNewWalletId(undefined);
      setAuthorised(false);
    }
  }, [accountId, importState, newWalletId]);

  // useRef for latest reset function without triggering cleanup useEffect on change
  const resetRef = useRef(resetNewWallet);
  // keep ref updated
  useEffect(() => {
    resetRef.current = resetNewWallet;
  }, [resetNewWallet]);

  // clear related fields on dataSource change
  useEffect(() => {
    setImportError(undefined);
  }, [dataSource]);

  // delete new wallet if import is abandoned on component unmount or dataSource change
  useEffect(() => {
    return () => {
      console.log("ImportContext cleanup");
      resetRef.current();
    };
  }, [dataSource]);

  const [importType, setImportType] = useState<DataSourceInputType>(
    DataSourceInputType.Api
  );

  const [importStatus, setImportStatus] = useState<ImportStatus>();

  const supportedInputs = useMemo(
    () => dataSource && getDataSourceSupportedInputs(dataSource),
    [dataSource]
  );

  // clear import error on import type change
  useEffect(() => {
    const supportStatus =
      importType == DataSourceInputType.Api
        ? supportedInputs?.sync
        : supportedInputs?.file;
    setImportStatus(supportStatus);
    setImportError(undefined);
  }, [importType, supportedInputs]);

  // // clear any temp wallet when inputType changes
  // useEffect(() => {
  //   resetRef.current();
  // }, [importType]);

  // vezgo gets handled directly in the AuthoriseModule
  const externalAuth = dataSource?.syncAuthType == "oauth";

  const importReady = useMemo(
    () =>
      !!(
        importType !== DataSourceInputType.Api ||
        !externalAuth ||
        (externalAuth && authorised)
      ),
    [authorised, importType, externalAuth]
  );

  const beginImport = useCallback(
    async (props: AddDataSourceForm) => {
      const {
        apiKey,
        secretKey,
        passphrase,
        fromDate,
        storedFiles,
        customName,
        network,
      } = props;

      console.debug("beginImport", props);

      if (!dataSource) throw new Error("dataSource expected");

      setImportError(undefined);

      let tempWalletId: string | undefined;
      try {
        if (importType == DataSourceInputType.Api) {
          // ------------------------ OAuth --------------------------
          if (externalAuth) {
            if (!authorised || !newWalletId) return;

            setImportState("backgroundImporting");
            // update any custom name that was set after wallet creation
            if (customName != null) {
              await updateWallet(accountId, newWalletId, {
                customName,
              });
            }
            syncWallet(
              accountId,
              { _id: newWalletId, customName, dataSource },
              fromDate
            )
              .then(() => {
                setImportState("complete");
              })
              .catch((error) => {
                setImportError(error);
              });
          } else {
            // ------------------------ API Import --------------------------
            if (!apiKey) return;
            if (dataSource?.syncImportParams?.secret && !secretKey) return;
            if (dataSource?.syncImportParams?.passphrase && !passphrase) return;
            if (dataSource.type == DataSourceType.Wallet && !network) return;

            setImportState("initialising");
            tempWalletId = (
              await addWallet(accountId, {
                dataSourceId: dataSource._id,
                apiKey,
                secretKey,
                passphrase,
                fromDate,
                customName,
                type: AddWalletType.Sync,
                network,
              })
            )._id;

            setImportState("backgroundImporting");

            syncWallet(
              accountId,
              { _id: tempWalletId, customName, dataSource },
              fromDate
            )
              .then(() => {
                setImportState("complete");
              })
              .catch((error) => {
                setImportError(error);
              });
          }
        }
        // ------------------------ CSV Import --------------------------
        else {
          if (!storedFiles.length) throw new Error("Files to import expected");

          setImportState("importing");

          if (dataSource.type == DataSourceType.Import) {
            if (!dataSource._id) throw new Error("DataSource Id expected");

            await importDataSources({
              accountId,
              dataSourceId: dataSource._id,
              files: storedFiles,
            });
          } else {
            tempWalletId = (
              await addWallet(accountId, {
                dataSourceId: dataSource._id,
                customName,
                type: AddWalletType.CSV,
              })
            )._id;

            await importWalletCsv(accountId, tempWalletId, storedFiles);
          }

          setImportState("complete");
        }
      } catch (error: any) {
        if (!axios.isAxiosError(error)) Sentry.captureException(error);
        setImportState(undefined);
        setImportError(error);

        if (tempWalletId) {
          await deleteWallet(accountId, tempWalletId);
          setAuthorised(false);
        }
      }
    },
    [accountId, authorised, dataSource, importType, newWalletId, externalAuth]
  );

  const createEmptyWallet = useCallback(
    async (props: CreateEmptyWalletProps) => {
      const { customName } = props;
      console.debug("createEmptyWallet", props);

      setImportError(undefined);
      setImportState("importing");

      try {
        await addWallet(accountId, {
          dataSourceId: dataSource?._id,
          customName,
          type: AddWalletType.Empty,
        });

        setImportState("complete");
      } catch (error) {
        if (!axios.isAxiosError(error)) Sentry.captureException(error);
        setImportError(error as any);
        setImportState(undefined);
      }
    },
    [accountId, dataSource?._id]
  );

  return (
    <ImportContext.Provider
      value={{
        state: {
          dataSource,
          authInProgress,
          authorised,
          importType,
          importStatus,
          importState,
          importComplete: isCompleted(importState),
          importInProgress: isBusy(importState),
          importError,
          newWalletId,
          importReady,
        },
        actions: {
          setDataSource,
          setAuthInProgress,
          setAuthorised,
          setNewWalletId,
          setImportError,
          setImportState,
          beginImport,
          createEmptyWallet,
          setImportType,
        },
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

export const isCompleted = (importState: ImportState) =>
  importState == "complete" || importState == "backgroundImporting";

export const isBusy = (importState: ImportState) =>
  importState == "initialising" || importState == "importing";
