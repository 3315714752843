import { createStandaloneToast } from "@chakra-ui/react";
import { ReplaceProps } from "@syla/shared/types/helpers/ReplaceProps";
import { DataSourceBase } from "@syla/shared/types/models/DataSourceBase";
import { SyncState } from "@syla/shared/types/models/WalletBase";
import { secondsToMilliseconds } from "date-fns";
import { syncWallet as syncWalletRequest } from "../../api/wallet/syncWallet";
import { buildSyncErrorToastProps } from "../../helper/wallet/buildSyncErrorToastProps";
import { theme } from "../../theme";
import { Wallet } from "../../types/wallet/wallet";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import {
  queryClient,
  getAccountTransactionsCacheKey,
} from "../transactionsStore";
import { getAllAssetsCacheKey } from "../useQueryAssets";
import { buildSyncSuccessToastProps } from "./buildSyncSuccessToastProps";
import { updateCachedWalletState } from "./updateCachedWalletState";
import { getWalletsQueryCacheKey } from "./wallet";

export type WalletToSync = ReplaceProps<
  Pick<Wallet, "_id" | "customName" | "dataSource">,
  "dataSource",
  {
    dataSource: Pick<DataSourceBase<string>, "name" | "syncAuthType">;
  }
>;

export const syncWallet = async (
  accountId: string,
  wallet: WalletToSync,
  fromDate?: Date,
  vezgoAccountId?: string
) => {
  const toast = createStandaloneToast({ theme });

  updateCachedWalletState(accountId, [wallet._id], {
    syncState: SyncState.InProgress,
  });

  // trigger a refresh of wallet state after a while
  // give it time to initialise the state
  setTimeout(() => {
    queryClient.invalidateQueries([...getWalletsQueryCacheKey(accountId)]);
  }, secondsToMilliseconds(3));

  return syncWalletRequest(accountId, wallet._id, fromDate, vezgoAccountId)
    .then(() => {
      if (wallet.dataSource.syncAuthType != "vezgo") {
        toast(buildSyncSuccessToastProps([wallet]));
        checkStatusAndDoPendingWork();
      }
    })
    .catch((error) => {
      toast(buildSyncErrorToastProps(error));
    })
    .finally(() => {
      queryClient.invalidateQueries([
        ...getAccountTransactionsCacheKey(accountId),
      ]);
      queryClient.invalidateQueries(getAllAssetsCacheKey());
    });
};
