import { useMutation } from "react-query";
import {
  InviteAccountMemberProps,
  inviteAccountMember,
} from "../api/account/inviteAccountMember";

import { queryClient } from "./transactionsStore";
import { ACCOUNT_META_QUERY_KEY } from "./useQueryGetAccounts";

export const useMutationInviteAccountMember = (accountId: string) =>
  useMutation(
    (props: InviteAccountMemberProps) => inviteAccountMember(accountId, props),
    {
      mutationKey: [...ACCOUNT_META_QUERY_KEY, accountId],
      onSettled: () => {
        queryClient.invalidateQueries([...ACCOUNT_META_QUERY_KEY]);
      },
    }
  );
