/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

// Define props that can also be shared with table rows to ensure we actually
// get columns. A better solution might be to just use grid or table for this
// component.
export const recentTransactionPropsColumnType = {
  minW: "100px",
  grow: 0,
  shrink: 1,
  basis: "20%",
  ml: "15px",
};

export const recentTransactionPropsColumnDate = {
  grow: 0,
  shrink: 0,
  basis: "15%",
};

export const recentTransactionPropsColumnOut = {
  minW: "200px",
  grow: 25,
  shrink: 0,
  basis: "25%",
  width: "100%",
};

export const recentTransactionPropsColumnIn = {
  minW: "200px",
  grow: 25,
  shrink: 0,
  basis: "25%",
  width: "100%",
};
export const recentTransactionPropsColumnOutcome = {
  minW: "100px",
  grow: 0,
  shrink: 1,
  basis: "15%",
  justifyContent: "flex-end",
  pr: "50px",
};

export const RecentTransactionTableHeader = (): React.ReactElement => (
  <Flex
    borderWidth="1px 0 1px 0"
    borderColor="white.500"
    bgColor="white.0"
    h="38px"
    w="100%"
    alignContent="center"
  >
    {/* ------------ Table Header Row ---------------------------*/}
    <Flex {...recentTransactionPropsColumnType}>
      <THeader>Type</THeader>
    </Flex>
    <Flex {...recentTransactionPropsColumnDate}>
      <THeader>Date</THeader>
    </Flex>
    <Flex {...recentTransactionPropsColumnOut}>
      <THeader>Out</THeader>
    </Flex>
    <Flex {...recentTransactionPropsColumnIn}>
      <THeader>In</THeader>
    </Flex>
    <Flex {...recentTransactionPropsColumnOutcome}>
      <THeader>Outcome</THeader>
    </Flex>
  </Flex>
);

const THeader = styled(Text)`
  color: #666;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
`;
