import { AccountType } from "@syla/shared/types/models/AccountBase";
import simpleFund from "../images/icons/bglSimpleFund360.svg";
import simpleInvest from "../images/icons/bglSimpleInvest360.svg";

export interface BGLProduct {
  name: string;
  icon: string;
  url: string;
}

const SimpleFund: BGLProduct = {
  name: "BGL Simple Fund 360",
  icon: simpleFund,
  url: "https://www.syla.com.au/integrations/bgl-simple-fund",
};

const SimpleInvest: BGLProduct = {
  name: "BGL Simple Invest 360",
  icon: simpleInvest,
  url: "https://www.syla.com.au/integrations/bgl-simple-invest",
};

export const getRelatedBglProduct = ({
  accountType,
}: {
  accountType: AccountType | undefined;
}) => (accountType == AccountType.SMSF ? SimpleFund : SimpleInvest);
