import React from "react";

import {
  SkeletonCircle as Circle,
  Skeleton,
  SkeletonProps as ChakraSkeletonProps,
  SkeletonOptions,
} from "@chakra-ui/react";

export interface SkeletonProps extends ChakraSkeletonProps {
  isLoaded?: boolean;
}

const commonProps: SkeletonOptions = {
  startColor: "gray.200",
  endColor: "gray.300",
};

export const SkeletonCircle = ({
  isLoaded,
  children,
}: SkeletonProps): JSX.Element => {
  return (
    <Circle {...commonProps} isLoaded={isLoaded}>
      {children}
    </Circle>
  );
};

export const SkeletonRect = (props: SkeletonProps): JSX.Element => {
  return <Skeleton {...commonProps} {...props} isLoaded={props.isLoaded} />;
};
