import { GroupBase } from "../models/GroupBase";
import { TransportType } from "./TransportType";
import { WithModelIds } from "./WithModelIds";

type OrderType = WithModelIds<GroupBase<string>>;
export function parseOrder(order: TransportType<OrderType>): OrderType {
  return {
    ...order,
    date: new Date(order.date),
  };
}
