import { ButtonProps } from "@chakra-ui/react";
import {
  AccountAccessRole,
  AccountAccessRoleName,
} from "@syla/shared/types/models/AccountAccessBase";
import React from "react";
import { ItemSelector } from "../../components/molecules/ItemSelector";

export const AccountAccessRoleDescription: Record<AccountAccessRole, string> = {
  [AccountAccessRole.Owner]: "Full control of account.",
  [AccountAccessRole.Administrator]:
    "Can change account settings and invite new members.",
  [AccountAccessRole.Accountant]:
    "Can change account settings and invite new members.",
  [AccountAccessRole.Editor]:
    "Cannot invite new members or manage the subscription.",
};

export function AccountAccessRoleSelector({
  currentRole,
  onSelected,
  isLoading,
  ...props
}: {
  currentRole: AccountAccessRole;
  onSelected: (role: AccountAccessRole) => void;
  isLoading?: boolean;
} & ButtonProps) {
  const roles = Object.values(AccountAccessRole);

  return (
    <ItemSelector
      currentValue={currentRole}
      onValueSelected={onSelected}
      allValues={roles}
      labels={AccountAccessRoleName}
      descriptions={AccountAccessRoleDescription}
      isLoading={isLoading}
      {...props}
    />
  );
}
