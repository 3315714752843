import { ParcelMethodType } from "../models/AccountBase";

export const SYLA_EARLIEST_EVENT_DATE = new Date(Date.UTC(2000, 6, 2));

export const DEFAULT_PARCEL_METHOD = ParcelMethodType.FIFO;

export const get_HEAP_APP_ID = (isProduction) =>
  isProduction ? "3195749883" : "3311327584";

export const COINJAR_USE_SANDBOX = false;

// other CoinJar props also set at
// packages/express-app/src/helpers/datasources/coinJar/coinJarConstants.ts
// packages/react-app/src/oAuth/getCoinJarAuthoriseUrl.ts
export const get_COINJAR_CLIENT_ID = (isProduction: boolean) =>
  isProduction || !COINJAR_USE_SANDBOX
    ? "9k6xki1kiDEO9063174rudVLkPLVTkt3WnXIajGKR9U"
    : "AsLeEChN1gEvuISl9pgcikdLQxpjxxzjsBYJlxOxTVk";

// one used for both staging and prod
export const COINBASE_CLIENT_ID =
  "f6e62a4000d0b5497062560c57ac913784c81f684aaa58bacae5ec63890c034c";

export const REFERRAL_CODE_COOKIE = "syla_referral_code";
export const REFERRAL_CODE_SEARCH_PARAM = "code";

export const REFERRAL_DISCOUNT = "10%";

export const get_VEZGO_CLIENT_ID = (isProduction: boolean) =>
  isProduction ? "5004tbfqag3ckilnnr487mn1t0" : "2qcse39tkr9thd067c0v91rsvf";
