import React from "react";
import styled from "@emotion/styled";

const StyledDiv = styled.div`
  margin: 0 auto;
  font-size: 1rem;
  padding-top: 20px;
`;

export const ErrorFallBack = <StyledDiv>Oops, Something Went Wrong</StyledDiv>;
