import {
  Flex,
  Spinner,
  Thead,
  Tr,
  Th,
  Table,
  Tbody,
  HStack,
  Spacer,
  useDisclosure,
  TableContainer,
  Box,
} from "@chakra-ui/react";
import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import { sortBy } from "lodash";
import React from "react";
import { isBrowser } from "react-device-detect";
import { ButtonVariant } from "../../components/atoms/ButtonVariant";
import { tableFrameProps } from "../../components/atoms/tableFrameProps";
import { hasAccountPermission } from "../../helper/permissions";
import { useCurrentAccountStore } from "../../store/currentAccountStore";
import { useQueryGetAccount } from "../../store/useQueryGetAccount";
import { AccountAccessRow } from "./accountMemberSettings/AccountAccessRow";
import { AccountInviteRow } from "./accountMemberSettings/AccountInviteRow";
import { SectionContainer, SectionHeading } from "./AccountSettingsCommon";
import { InviteMemberModal } from "./InviteMemberModal";

export function AccountMembersSettings() {
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { data: account, isLoading: accountIsLoading } =
    useQueryGetAccount(accountId);

  const {
    isOpen: addMemberIsOpen,
    onClose: addMemberOnClose,
    onOpen: addMemberOnOpen,
  } = useDisclosure();

  if (accountIsLoading || !account)
    return (
      <Flex justifyContent="center" my="40px">
        <Spinner />
      </Flex>
    );

  return (
    <SectionContainer>
      <HStack w="100%" alignItems="flex-start">
        <SectionHeading>Account Members</SectionHeading>
        <Spacer />
        {hasAccountPermission(account, AccountPermission.ChangeAccess) && (
          <ButtonVariant
            content="Invite Member"
            color="red"
            leftIcon="add"
            ml={isBrowser ? { base: "0", xs: "20px" } : "0"}
            onClick={addMemberOnOpen}
          />
        )}
      </HStack>
      <Box {...tableFrameProps}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Account Member</Th>
                <Th>Billing Owner</Th>
                <Th>Role</Th>
                <Th>Invite Status</Th>
                <Th w="auto"></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortBy(account.access, (access) => access.user._id).map(
                (accountAccess) => (
                  <AccountAccessRow
                    key={accountAccess._id}
                    account={account}
                    accountAccess={accountAccess}
                  />
                )
              )}
              {sortBy(account.accessInvites, (invite) => invite._id).map(
                (invite) => (
                  <AccountInviteRow
                    key={invite._id}
                    account={account}
                    accountInvite={invite}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <InviteMemberModal isOpen={addMemberIsOpen} onClose={addMemberOnClose} />
    </SectionContainer>
  );
}
