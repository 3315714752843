import {
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { OrderResponseItem } from "@syla/shared/types/responses/OrderResponseItem";
import React from "react";
import { needsReviewIconProps } from "../../../atoms/NeedsReview";
import { renderTransactionNeedsReviewItems } from "../transactionDrawer/transactionOverview/renderTransactionNeedsReviewItems";
import { TransactionsNeedsReviewList } from "./TransactionsNeedsReviewList";

export function OrderNeedsReviewSummary({
  order,
}: {
  order: OrderResponseItem<string>;
}) {
  const needsReviewItems = renderTransactionNeedsReviewItems({
    transactions: order.transactions,
  });

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <a>
          <Icon {...needsReviewIconProps} />
        </a>
      </PopoverTrigger>
      <PopoverContent>
        {/*<PopoverArrow />*/}
        {/*<PopoverHeader>Confirmation!</PopoverHeader>*/}
        <PopoverBody backgroundColor={needsReviewIconProps.color}>
          <TransactionsNeedsReviewList items={needsReviewItems} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
