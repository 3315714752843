import { GetGroupsResponse } from "@syla/shared/types/responses/GetGroupsResponse";
import { deleteOrders } from "../../api/order/deleteOrders";
import { OrderFilterSetting } from "../../types/order/orderQuery";
import { checkStatusAndDoPendingWork } from "../pendingWork";
import { useTransactionsStore, queryClient } from "../transactionsStore";
import {
  refreshGroupList,
  getPageQueryKey,
  invalidateTransactionQueryCache,
} from "./groupList";

export const deleteGroups = async ({
  accountId,
  groupIds,
  filter,
}: {
  accountId: string;
  groupIds?: string[];
  filter?: OrderFilterSetting;
}) => {
  try {
    await deleteOrders({ accountId, orders: groupIds, filter });
    invalidateTransactionQueryCache(accountId);
    checkStatusAndDoPendingWork();
  } catch (error) {
    // update from server state
    refreshGroupList();
    throw error;
  }

  if (groupIds) {
    // optimistically update state
    useTransactionsStore.setState((state) => {
      return {
        groupList: state.groupList?.filter(
          (group) => !groupIds.includes(group)
        ),
      };
    });

    // optimistically update query cache
    const { groupListProps } = useTransactionsStore.getState();
    if (groupListProps) {
      queryClient.setQueryData<GetGroupsResponse<string>>(
        getPageQueryKey(groupListProps),
        (response) =>
          response
            ? {
                ...response,
                data: response.data.filter(
                  (group) => !groupIds.includes(group.order._id)
                ),
              }
            : (undefined as any)
      );
    }
  } else if (filter) {
    useTransactionsStore.setState((state) => {
      return {
        groupList: [],
        groupListTotal: 0,
      };
    });
  }
};
