import React from "react";
import { Flex, Text, Tooltip as ChakraTooltip } from "@chakra-ui/react";
import styled from "@emotion/styled";

import { Decimal } from "@syla/shared/decimal";
import { getAssetLongName } from "@syla/shared/helpers/assets/getAssetLongName";
import { getAssetShortName } from "@syla/shared/helpers/assets/getAssetShortName";
import { NumberStyler } from "../../../../../helper/NumberStyler";
import { AssetHolding } from "../../../../../types/wallet/wallet";
import { ImageWithMissingSrc } from "../../../../atoms/ImageWithMissingSrc";
import { InfoToolTip } from "../../../../atoms/InfoToolTip";
import { SkeletonRect } from "../../../../atoms/Skeletons";
import { rightAlignContent } from "./styles";

export const HoldingTableRow = ({
  holding,
  totalHoldingValue,
  lastRow,
  marketValueLoading,
}: {
  holding: AssetHolding;
  totalHoldingValue: Decimal | undefined;
  lastRow: boolean;
  marketValueLoading: boolean;
}): JSX.Element => {
  const marketValue = holding.marketValue;

  const percentageShare =
    marketValue &&
    marketValue.gte("0") &&
    totalHoldingValue &&
    !totalHoldingValue.eq("0")
      ? marketValue.div(totalHoldingValue).times("100")
      : undefined;

  return (
    <Flex direction="row">
      {/* ---------------- Asset -------------------- */}
      <TCell
        borderRadius={lastRow ? "0 0 0 10px" : "0"}
        direction="row"
        alignItems="center"
      >
        <ImageWithMissingSrc
          src={holding.asset.image}
          alt="asset"
          pr="10px"
          w="35px"
          isLoading={false}
        />
        <SkeletonRect isLoaded={true}>
          <ChakraTooltip label={getAssetLongName(holding.asset)}>
            <TText>{getAssetShortName(holding.asset)}</TText>
          </ChakraTooltip>
        </SkeletonRect>
      </TCell>
      {/* ---------------- Balance -------------------- */}
      <TCell>
        <SkeletonRect isLoaded={true}>
          <NumberStyler
            assetInfo={holding.asset}
            num={holding.balance}
            fontSize="0.75rem"
            mr="50px"
            negativeColored
          />
        </SkeletonRect>
      </TCell>
      {/* ---------------- ALLOCATION % -------------------- */}
      <TRightAlignCell>
        <SkeletonRect isLoaded={!marketValueLoading}>
          <NumberStyler
            unit="percentage"
            num={percentageShare}
            color="black.600"
            fontSize="0.75rem"
          />
        </SkeletonRect>
      </TRightAlignCell>
      {/* ---------------- HOLDING -------------------- */}
      <TRightAlignCell>
        <SkeletonRect isLoaded={!marketValueLoading}>
          <NumberStyler
            num={marketValue}
            fontSize="0.75rem"
            unit="currency"
            colored
          />
        </SkeletonRect>
      </TRightAlignCell>
      <Tooltip
        borderRadius={lastRow ? "0 0 10px 0" : "0"}
        justifyContent="flex-end"
      >
        {!marketValueLoading && holding.balance.lt("0") && (
          <InfoToolTip
            label="A negative balance indicates missing transactions. Please review that you have added your entire transaction history including all deposits and withdrawals."
            wrap
          />
        )}
      </Tooltip>
    </Flex>
  );
};

const TCell = styled(Flex)`
  background-color: #fff;
  width: 24%;
  padding: 8px 20px;
  margin-bottom: 1px;
  align-items: center;
  min-width: 115px;
`;

const TRightAlignCell = styled(TCell)`
  ${rightAlignContent}
`;

const Tooltip = styled(Flex)`
  background-color: #fff;
  width: 4%;
  padding-right: 15px;
  margin-bottom: 1px;
  align-items: center;
  min-width: 20px;
`;

const TText = styled(Text)`
  color: #666;
  font-size: 0.75rem;
`;
