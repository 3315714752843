import {
  SortableMarketValueHolding,
  sortHoldingsMarketValueCompareFn,
} from "@syla/shared/helpers/sortHoldingsMarketValueCompareFn";
import { sortBy } from "lodash";

export type SortableHolding = SortableMarketValueHolding & {
  asset: { code: string };
};

export const sortHoldings = <THolding extends SortableHolding>(
  holdings: THolding[]
) => {
  const sortedAlphabetic = sortBy(holdings, (holding) => holding.asset.code);

  // then by market value
  return sortedAlphabetic.sort(sortHoldingsMarketValueCompareFn);
};
