import {
  Flex,
  Button,
  Text,
  Image,
  Accordion,
  Textarea,
  Input,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerFooter,
  DrawerBody,
  FormControl,
  FormErrorMessage,
  useDisclosure,
  useToast,
  ExpandedIndex,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Decimal } from "@syla/shared/decimal";
import { LedgerType } from "@syla/shared/types/models/LedgerBase";
import { TransactionType } from "@syla/shared/types/models/TransactionBase";

import { NewLedger } from "@syla/shared/types/requests/TransactionRequests";
import { AxiosError } from "axios";
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { isDesktop } from "react-device-detect";
import {
  useForm,
  FormProvider,
  useFieldArray,
  Controller,
  FieldError,
} from "react-hook-form";
import { adjustAmount } from "../../../../helper/forms/stringAsNumberInputField";
import { thumbnailExtractor } from "../../../../helper/thumbnailExtractor";
import {
  getInLedgerTypeOptionsByTransactionType,
  getOutLedgerTypeOptionsByTransactionType,
  getFeeLedgerTypeOptionsByTransactionType,
} from "../../../../helper/transaction/getLedgerType";
import { getTransactionTypeOptions } from "../../../../helper/transaction/getTransactionTypeOptions";
import { useGetWalletOptions } from "../../../../hooks/wallet/useGetWalletOptions";
import completeIcon from "../../../../images/icons/complete.svg";
import { addTransaction } from "../../../../store/actions/addTransaction";
import { useCurrentAccountStore } from "../../../../store/currentAccountStore";
import { useQueryAssetOptions } from "../../../../store/useQueryAssets";
import { NewTransactionForm } from "../../../../types/order/addOrderForm";
import { ButtonVariant } from "../../../atoms/ButtonVariant";
import { StyledDatePicker } from "../../../atoms/DatePicker";
import { DrawerHeading } from "../../../atoms/Headings";
import { SingleThumbnailSelectBox } from "../../../atoms/thumbnailSelectBoxVariant/singleThumbnailSelectBox/SingleThumbnailSelectBox";
import { StyledFormLabel } from "../../../molecules/forms/FormComponents";
import { LedgerInputs } from "./LedgerInputs";
import { TransactionAccordionItem } from "./TransactionAccordionItem";

interface AddTransactionDrawerProps {
  isAddTransactionDrawerOpen: boolean;
  onCloseAddTransactionDrawer: () => void;
}

export const AddTransactionDrawer = ({
  isAddTransactionDrawerOpen,
  onCloseAddTransactionDrawer,
}: AddTransactionDrawerProps): JSX.Element => {
  // Add transaction form state
  const { isOpen: isAddTransactionCompleted, onOpen: completeAddTransaction } =
    useDisclosure();

  // generate assets list
  const accountId = useCurrentAccountStore(({ accountId }) => accountId);
  const { data: assetOptionsResult } = useQueryAssetOptions(accountId);
  const assetOptions = assetOptionsResult ?? [];

  const toast = useToast();

  const setAddTransactionError = (message: string) =>
    toast({
      title: "Error adding transaction",
      description: message,
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

  const defaultTransactionType = TransactionType.Trade;

  // hook form setup
  const methods = useForm<NewTransactionForm>({
    defaultValues: {
      type: defaultTransactionType,
      outLedgers: [
        {
          type: getOutLedgerTypeOptionsByTransactionType(
            defaultTransactionType
          )[0].value,
        },
      ],
      inLedgers: [
        {
          type: getInLedgerTypeOptionsByTransactionType(
            defaultTransactionType
          )[0].value,
        },
      ],
      feeLedgers: [{}],
    },
  });

  // hook form methods
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  /** Set first applicable ledgerType as default for each mandatory ledger, or clear if not applicable */
  const setDefaultLedgerTypes = useCallback(
    (transactionType: TransactionType) => {
      if (transactionType == TransactionType.Transaction) {
        setValue(`inLedgers.0.type`, null as any);
      } else {
        setValue(
          `inLedgers.0.type`,
          getInLedgerTypeOptionsByTransactionType(transactionType)?.at(0)
            ?.value as LedgerType
        );
      }

      if (transactionType == TransactionType.Transaction) {
        setValue(`outLedgers.0.type`, null as any);
      } else {
        setValue(
          `outLedgers.0.type`,
          getOutLedgerTypeOptionsByTransactionType(transactionType)?.at(0)
            ?.value as LedgerType
        );
      }

      // clear fee
      setValue(`feeLedgers.0.type`, null as any);
    },
    [setValue]
  );

  // hook form fieldArray for outLedgers
  const { fields: outLedgerFields } = useFieldArray({
    name: "outLedgers",
    control,
  });

  // hook form fieldArray for inLedgers
  const { fields: inLedgerFields } = useFieldArray({
    name: "inLedgers",
    control,
  });

  // hook form fieldArray for feeLedgers, NOTE: transactions no longer have the fee ledgers array, the hook form will keep it to maintain a single form for fees and just build the alt in ledgers and alt out ledgers array in the on submit handler
  const { fields: feeLedgerFields } = useFieldArray({
    name: "feeLedgers",
    control,
  });

  // hook form watch over transaction type
  const transactionType = watch("type");
  const inLedger = watch("inLedgers.0");
  const outLedger = watch("outLedgers.0");

  const eitherOrRule = transactionType == TransactionType.Transaction;

  // reset ledger types when changing tx type
  useEffect(() => {
    setDefaultLedgerTypes(transactionType);
  }, [transactionType, setDefaultLedgerTypes]);

  // hook form onSubmit
  const onSubmit = async (data: NewTransactionForm) => {
    if (!data.type || !data.walletId || !data.date) {
      setAddTransactionError("Form is incomplete");
      return;
    }

    const {
      type,
      walletId,
      date,
      description,
      transactionHash,
      outLedgers,
      inLedgers,
      feeLedgers,
    } = data;

    // check fee ledgers

    const ledgerHasRequiredValues = (ledger: NewLedger | undefined) =>
      ledger?.type && ledger?.assetId && ledger?.amount;

    // if fee ledgers doesn't have any real values, remove the default empty object to make it an empty array
    if (feeLedgers.length === 1 && !ledgerHasRequiredValues(feeLedgers[0])) {
      feeLedgers.pop();
    }

    if (
      eitherOrRule &&
      !ledgerHasRequiredValues(inLedgers.at(0)) &&
      !ledgerHasRequiredValues(outLedgers.at(0))
    ) {
      setAddTransactionError("Please fill in all required In or Out values");
      return;
    }

    // convert to absolute amounts
    const mapLedger = (ledger: NewLedger): NewLedger => ({
      ...ledger,
      amount: ledger.amount
        ? Decimal.from(adjustAmount(ledger.amount)).abs().toString()
        : ledger.amount,
      customMarketValue: ledger.customMarketValue
        ? {
            assetId: ledger.customMarketValue.assetId,
            marketValue: adjustAmount(ledger.customMarketValue.marketValue),
          }
        : undefined,
    });

    try {
      await addTransaction({
        accountId,
        date,
        description: "",
        transaction: {
          walletId,
          type,
          date,
          description,
          transactionHash,
          outLedgers: outLedgers.filter(ledgerHasRequiredValues).map(mapLedger),
          inLedgers: inLedgers.filter(ledgerHasRequiredValues).map(mapLedger),
          feeLedgers: feeLedgers.map(mapLedger),
        },
      });
      completeAddTransaction();
    } catch (error) {
      setAddTransactionError(
        (error as AxiosError).response?.data ??
          "Unexpected error occurred, please try again later"
      );
    }
  };

  // query for user wallets data
  const walletOptions = useGetWalletOptions(accountId)?.data;

  const transactionTypeOptions = useMemo(
    () =>
      getTransactionTypeOptions.filter(
        (transactionType) =>
          ![TransactionType.AddLiquidity, TransactionType.RemoveLiquidity]
            .map((t) => t.toString())
            .includes(transactionType.value)
      ),
    []
  );

  const [expandedIndexes, setExpandedIndexes] = useState<ExpandedIndex>([]);

  // set default expand when tx type changes
  useEffect(() => {
    [TransactionType.Send, TransactionType.Receive].includes(transactionType)
      ? setExpandedIndexes([0])
      : // : transactionType == TransactionType.Transaction
        // ? setExpandedIndexes([])
        setExpandedIndexes([0, 1]);
  }, [transactionType]);

  // at least one must be selected when either or, otherwise each is required

  const ledgerTypeRequiredRule = useCallback(
    (targetValue) =>
      (eitherOrRule && (inLedger.type || outLedger.type)) || targetValue
        ? undefined
        : eitherOrRule
        ? "Select at least one ledger type"
        : "Please select a ledger type",
    [eitherOrRule, inLedger.type, outLedger.type]
  );

  return (
    <Drawer
      isOpen={isAddTransactionDrawerOpen}
      placement="right"
      onClose={onCloseAddTransactionDrawer}
      size="sm"
      closeOnOverlayClick={!isSubmitting}
    >
      {/* -------------------- Drawer Overlay ------------------------- */}
      <DrawerOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DrawerContent>
            {/* ----------------- Drawer Header --------------------- */}
            {!isAddTransactionCompleted && (
              <DrawerHeading>Add Transaction</DrawerHeading>
            )}
            {/* -------------------- Drawer Body ------------------------- */}
            <DrawerBody p="0">
              {/* -------------------- When add transaction is completed ------------------------- */}
              {isAddTransactionCompleted ? (
                <Flex
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  h="100%"
                >
                  <Image src={completeIcon} alt="Complete" h="70px" />
                  <Text
                    fontSize="2rem"
                    w="80%"
                    fontWeight="bold"
                    color="black.900"
                    mb="30px"
                    textAlign="center"
                  >
                    Your transaction has been imported
                  </Text>
                </Flex>
              ) : (
                <>
                  <Flex direction="column" px="24px" w="100%" mb="20px">
                    <FormControl
                      mb="10px"
                      isRequired
                      isDisabled={isSubmitting}
                      isInvalid={errors.type !== undefined}
                    >
                      <StyledFormLabel content="Type of transaction" />
                      {/* -------------------- Transaction Type ------------------------- */}
                      <Controller
                        control={control}
                        name="type"
                        rules={{ required: "Please select a transaction type" }}
                        render={({ field: { onChange, value } }) => (
                          <SingleThumbnailSelectBox
                            selectedOption={value}
                            allowDeSelected={false}
                            onChangeSelection={(selection) => {
                              onChange(selection);
                            }}
                            options={transactionTypeOptions}
                            description
                            placeholder="Choose transaction type"
                            selectBtnProps={{
                              width: "100%",
                            }}
                          />
                        )}
                      />
                      {errors.type && (
                        <FormErrorMessage>
                          {errors.type.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    {/* -------------------- Wallet ------------------------- */}
                    <FormControl
                      mb="10px"
                      isRequired
                      isDisabled={isSubmitting}
                      isInvalid={errors.walletId !== undefined}
                    >
                      <StyledFormLabel content="Wallet" />
                      <Controller
                        control={control}
                        name="walletId"
                        rules={{ required: "Please select a wallet" }}
                        render={({ field: { onChange, value } }) => (
                          <SingleThumbnailSelectBox
                            selectedOption={value}
                            onChangeSelection={(selection) =>
                              onChange(selection)
                            }
                            options={walletOptions || []}
                            maxOptionsInView={4}
                            description
                            searchEnable
                            placeholder="Choose wallet"
                            selectBtnProps={{
                              width: "100%",
                            }}
                            autoSelectOnly
                          />
                        )}
                      />
                      {errors.walletId && (
                        <FormErrorMessage>
                          {errors.walletId.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                    {/* -------------------- Date and time ------------------------- */}
                    <FormControl
                      mb="10px"
                      isRequired
                      isDisabled={isSubmitting}
                      isInvalid={errors.date !== undefined}
                    >
                      <StyledFormLabel content="Date and time" />
                      <Controller
                        control={control}
                        name="date"
                        rules={{ required: "Please specify date and time" }}
                        render={({ field: { onChange, value } }) => (
                          <StyledDatePicker
                            value={value}
                            onChange={(date) => onChange(date)}
                            left={-25}
                          />
                        )}
                      />
                      {errors.date && (
                        <FormErrorMessage>
                          {errors.date.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>
                  </Flex>
                  <Accordion
                    allowMultiple
                    allowToggle
                    borderColor="transparent"
                    // auto expand mandatory parts
                    index={expandedIndexes}
                    onChange={setExpandedIndexes}
                  >
                    {/* -------------------- Out ------------------------- */}
                    {transactionType &&
                      transactionType !== TransactionType.Receive &&
                      outLedgerFields.map((field, index) => (
                        <TransactionAccordionItem title="Out" key={field.id}>
                          <Controller
                            control={control}
                            name={`outLedgers.${index}.type`}
                            rules={{
                              required: ledgerTypeRequiredRule(outLedger.type),
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <SingleThumbnailSelectBox
                                selectedOption={value as LedgerType}
                                onChangeSelection={(selection) =>
                                  onChange(selection)
                                }
                                options={getOutLedgerTypeOptionsByTransactionType(
                                  transactionType
                                )}
                                searchEnable
                                selectBtnProps={getLedgerTypeProps({ error })}
                                allowDeSelected={eitherOrRule}
                                placeholder={error?.message ?? "N/A"}
                              />
                            )}
                          />
                          <LedgerInputs
                            name={`outLedgers[${index}]`}
                            list={assetOptions}
                            isRequired={!eitherOrRule || !!outLedger.type}
                          />
                        </TransactionAccordionItem>
                      ))}
                    {/* -------------------- In ------------------------- */}
                    {transactionType &&
                      transactionType !== TransactionType.Send &&
                      inLedgerFields.map((field, index) => (
                        <TransactionAccordionItem title="In" key={field.id}>
                          <Controller
                            control={control}
                            name={`inLedgers.${index}.type`}
                            rules={{
                              required: ledgerTypeRequiredRule(inLedger.type),
                            }}
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => (
                              <SingleThumbnailSelectBox
                                selectedOption={value as LedgerType}
                                onChangeSelection={(selection) =>
                                  onChange(selection)
                                }
                                options={getInLedgerTypeOptionsByTransactionType(
                                  transactionType
                                )}
                                searchEnable
                                selectBtnProps={getLedgerTypeProps({ error })}
                                allowDeSelected={eitherOrRule}
                                placeholder={error?.message ?? "N/A"}
                              />
                            )}
                          />
                          {/* </FormControl> */}
                          <LedgerInputs
                            name={`inLedgers[${index}]`}
                            list={assetOptions}
                            isRequired={!eitherOrRule || !!inLedger.type}
                          />
                        </TransactionAccordionItem>
                      ))}
                    {/* -------------------- Fees ------------------------- */}
                    {transactionType &&
                      // any ledger options available
                      getFeeLedgerTypeOptionsByTransactionType(transactionType)
                        .length > 0 &&
                      feeLedgerFields.map((field, index) => (
                        <TransactionAccordionItem
                          title={
                            watch(`feeLedgers.${index}.type`)
                              ? thumbnailExtractor(
                                  watch(`feeLedgers.${index}.type`)
                                ).name
                              : index === 0
                              ? "Fee"
                              : "New Entry"
                          }
                          key={field.id}
                          optional
                        >
                          {/* {index !== 0 && (
                            <Flex
                              justifyContent="flex-end"
                              alignContent="center"
                              mb="5px"
                            >
                              <RedGhostBtn
                                content="Remove Fee"
                                icon="-"
                                optional={false}
                                onClick={() => removeTradeFee(index)}
                                w="120px"
                                trash
                              />
                            </Flex>
                          )} */}
                          <Flex w="100%" direction="column">
                            <Controller
                              control={control}
                              name={`feeLedgers.${index}.type`}
                              render={({ field: { onChange, value } }) => (
                                <SingleThumbnailSelectBox
                                  selectedOption={value}
                                  onChangeSelection={(selection) =>
                                    onChange(selection)
                                  }
                                  options={getFeeLedgerTypeOptionsByTransactionType(
                                    transactionType
                                  )}
                                  searchEnable
                                  w="100%"
                                  mb="15px"
                                />
                              )}
                            />
                            <LedgerInputs
                              name={`feeLedgers[${index}]`}
                              list={assetOptions}
                              isRequired={false}
                            />
                          </Flex>
                        </TransactionAccordionItem>
                      ))}
                    {/* -------------------- Add Entry ------------------------- */}
                    {/* <AddEntryButton onClick={() => appendTradeFee({})}>
                      <AccordionLabel>
                        <Text as="span" fontWeight="700">
                          +
                        </Text>{" "}
                        Add entry
                        <OptionLabel as="span">(Optional)</OptionLabel>
                      </AccordionLabel>
                    </AddEntryButton> */}
                    {/* -------------------- Description ------------------------- */}
                    <TransactionAccordionItem title="Description" optional>
                      <Textarea
                        {...register("description")}
                        placeholder="Type your description in here"
                      />
                    </TransactionAccordionItem>
                    {/* -------------------- Transaction Hash ------------------------- */}
                    <TransactionAccordionItem title="Transaction hash" optional>
                      <Input
                        {...register("transactionHash")}
                        type="text"
                        placeholder="Paste transaction hash here"
                        autoFocus={isDesktop}
                      />
                    </TransactionAccordionItem>
                  </Accordion>
                </>
              )}
            </DrawerBody>
            {/* -------------------- Drawer Footer ------------------------- */}
            <DrawerFooter>
              <Flex direction="column" w="100%">
                <ButtonVariant
                  content={
                    isAddTransactionCompleted
                      ? "Back to Transactions"
                      : "Add Transaction"
                  }
                  type={isAddTransactionCompleted ? "button" : "submit"}
                  onClick={() => {
                    if (!isAddTransactionCompleted) return;
                    onCloseAddTransactionDrawer();
                  }}
                  color="red"
                  mb="10px"
                  isLoading={isSubmitting}
                />
                <ButtonVariant
                  content="close"
                  outlineType="outlineGray"
                  spam="spam"
                  onClick={onCloseAddTransactionDrawer}
                  isDisabled={isSubmitting}
                />
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        </form>
      </FormProvider>
    </Drawer>
  );
};

const AccordionLabel = styled(Text)`
  width: 100%;
  font-size: 0.875rem;
  color: #ff3600;
  font-weight: 500;
  text-transform: capitalize;
`;
const OptionLabel = styled(Text)`
  text-transform: capitalize;
  font-size: 0.875rem;
  color: #999;
  margin-left: 5px;
`;

const AddEntryButton = styled(Button)`
  padding: 5px 24px;
  background-color: #fff;
  width: 100%;
  border-radius: 0;
  margin-bottom: 10px;
  cursor: pointer;
  :hover {
    background: transparent;
  }
`;

function getLedgerTypeProps({ error }: { error: FieldError | undefined }) {
  return {
    width: "100%",
    marginBottom: "15px",
    borderWidth: error ? "2px" : "0.8px",
    borderColor: error ? "#FF3600" : undefined,
  };
}
