import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useCallback } from "react";
import { UnexpectedErrorString } from "../components/atoms/UnexpectedError";
import { errorToastOptions } from "../components/molecules/errorToastOptions";

/**
 * Raise a toast with the response.data on error or a generic message if not provided
 */
export function useReportError({
  fallbackMessage,
}: {
  fallbackMessage?: string;
} = {}) {
  const errorToast = useToast({ ...errorToastOptions });
  return useCallback(
    (error) => {
      errorToast({
        title:
          (axios.isAxiosError(error) && error.response?.data?.toString()) ||
          fallbackMessage ||
          UnexpectedErrorString,
      });
    },
    [errorToast, fallbackMessage]
  );
}
