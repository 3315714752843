import { DataSourceCode } from "@syla/shared/types/models/DataSourceBase";
import { AuthoriseOauthWalletRequestBody } from "@syla/shared/types/requests/AuthoriseOauthWalletRequestBody";
import axios from "axios";
import { queryClient } from "../transactionsStore";
import { getWalletsQueryCacheKey } from "./wallet";

export type AuthoriseOauthWalletRequestProps = {
  walletId: string;
  accessGrantCode: string;
  redirectUrl: string;
  dataSourceCode: DataSourceCode;
};
export const authoriseOauthWallet = async (
  accountId: string,
  props: AuthoriseOauthWalletRequestProps
): Promise<void> => {
  try {
    await authoriseOauthWalletRequest(accountId, props);
  } finally {
    queryClient.invalidateQueries([...getWalletsQueryCacheKey(accountId)]);
  }
};

const authoriseOauthWalletRequest = async (
  accountId: string,
  {
    walletId,
    redirectUrl,
    accessGrantCode,
    dataSourceCode,
  }: AuthoriseOauthWalletRequestProps
) => {
  const body: AuthoriseOauthWalletRequestBody = {
    accessGrantCode,
    walletId,
    redirectUrl,
    dataSourceCode,
  };
  return axios.post(
    `/accounts/${accountId}/wallets/authorise-oauth-wallet`,
    body
  );
};
