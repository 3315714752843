import { ChangePasswordRequestBody } from "@syla/shared/types/requests/ChangePasswordRequest";
import axios from "axios";

export type ChangePasswordProps = ChangePasswordRequestBody;
export const changePassword = async (
  props: ChangePasswordProps
): Promise<boolean> => {
  const { data } = await axios.post<any, any, ChangePasswordProps>(
    "/users/change-password",
    {
      currentPassword: props.currentPassword,
      newPassword: props.newPassword,
    }
  );
  return data;
};
