import { parseDate, parseOptional } from "@syla/shared/types/helpers/parsing";
import { TransportType } from "@syla/shared/types/helpers/TransportType";
import { Subscription } from "../../types/plans/subscription";

export const parseSubscription = (
  subscription: TransportType<Subscription>
): Subscription => ({
  ...subscription,
  begins: parseDate(subscription.begins),
  ends: parseDate(subscription.ends),
  createdAt: parseOptional(subscription.createdAt, parseDate),
  updatedAt: parseOptional(subscription.updatedAt, parseDate),
});
