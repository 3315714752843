import { DownloadIcon } from "@chakra-ui/icons";
import { Text, Image, Link, HStack, Box } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { getFullPageUrl } from "../../../helper/getFullPageUrl";
import { TaxReportsContext } from "../../../pages/TaxReports";
import { useNavigator, Route } from "../../../routers/navigator";
import { useCurrentAccountStore } from "../../../store/currentAccountStore";
import { useTransactionsStore } from "../../../store/transactionsStore";
import { SkeletonRect } from "../../atoms/Skeletons";
import { ActionOrPurchaseButton } from "./ActionOrPurchaseButton";

interface DocumentRowProps {
  isLoading?: boolean;
  downloading?: boolean;
  canDownload: boolean;
  canPurchase: boolean;
  image: string;
  sampleUrl?: string;
  documentName: string;
  handleDownload: () => void;
}

export const DocumentRow = ({
  isLoading,
  downloading,
  canDownload,
  image,
  sampleUrl,
  documentName,
  handleDownload,
  canPurchase,
}: DocumentRowProps): JSX.Element => {
  const isCalculating = useTransactionsStore((state) => state.updating);
  const blockDownload = canDownload && isCalculating;
  const location = useLocation();
  const navigate = useNavigator();

  const accountId = useCurrentAccountStore(({ accountId }) => accountId);

  const { selectedFinancialYear } = useContext(TaxReportsContext);

  // handle purchase click
  const handlePurchase = () =>
    navigate({
      route: Route.Plans,
      accountId,
      state: {
        referringUrl: getFullPageUrl(location),
        selectedFinancialYear,
      },
    });

  return (
    <>
      <SkeletonRect isLoaded={!isLoading}>
        <HStack alignItems="center" mr="10px">
          <Image src={image} alt="" h="24px" />
          <Text fontSize="0.875rem" color="black.700">
            {documentName}
          </Text>
        </HStack>
      </SkeletonRect>

      {sampleUrl && !canDownload ? (
        <Box textAlign={"center"}>
          <Link
            ml="20px"
            mr="40px"
            mt="2px"
            fontSize="0.875rem"
            color="#B11B1B"
            whiteSpace="nowrap"
            isExternal
            href={sampleUrl}
          >
            view sample <DownloadIcon mx="2px" />
          </Link>
        </Box>
      ) : (
        // dummy div to work with Grid
        <Box />
      )}

      <ActionOrPurchaseButton
        canPurchase={canPurchase}
        hasActionPermission={canDownload}
        blockAction={blockDownload}
        blockedText={`Please wait for your tax calculations to complete before downloading your ${documentName}`}
        isLoading={isLoading}
        handleAction={handleDownload}
        handlePurchase={handlePurchase}
        inProgress={downloading}
      />
    </>
  );
};
