import { LoginResponse } from "./LoginResponse";

export interface LoginWithGoogleResponse extends LoginResponse {
  newUser: boolean;
}

export enum LoginWithGoogleResponseStatusCodes {
  BadRequest = 400,
  Failed = 401,
  Forbidden = 403,
}
