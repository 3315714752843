import { AccountPermission } from "@syla/shared/types/models/AccountAccessBase";
import { AccountType } from "@syla/shared/types/models/AccountBase";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { hasAccountPermission } from "../../../helper/permissions";
import { useReportError } from "../../../helpers/useReportError";
import { AccountTypeSelector } from "../../../pages/accountSettings/AccountTypeSelector";
import { useMutationUpdateAccount } from "../../../store/useMutationUpdateAccount";
import { Account } from "../../../types/Account";
import { EditableFieldProps } from "../../molecules/EditableField";

export function AccountTypeEditable({
  account,
  ...props
}: { account: Account } & Partial<EditableFieldProps>) {
  const { mutateAsync: updateAccount } = useMutationUpdateAccount(account._id);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const reportError = useReportError();

  const canChangeTaxSettings = hasAccountPermission(
    account,
    AccountPermission.ChangeTaxSettings
  );

  const { watch, setValue } = useFormContext<Partial<Account>>();
  return (
    <AccountTypeSelector
      currentValue={watch("accountType")}
      onSelected={async (accountType) => {
        try {
          setUpdateInProgress(true);
          setValue("accountType", accountType);
          await updateAccount({
            accountType,
          });
        } catch (error) {
          reportError(error);
        } finally {
          setUpdateInProgress(false);
        }
      }}
      isDisabled={!canChangeTaxSettings}
      isLoading={updateInProgress}
      {...props}
    />
  );
}
